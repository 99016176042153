import React from "react";
import Notification from "./notification/Notification";
import styles from "./NotificationList.module.scss";

const NotificationList = ({ notifications }) => {
  return (
    <div className={`p-t-20 ${styles.notificationList}`}>
      {notifications?.map((no) => (
        <React.Fragment key={no.id}>
          <Notification notification={no} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default NotificationList;
