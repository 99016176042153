const HospitalSubSvg = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_876_430)">
        <path
          d="M9 18.001C6.59605 18.001 4.33596 17.0648 2.63605 15.3649C0.936176 13.665 0 11.4049 0 9.00098C0 6.59703 0.936176 4.33694 2.63605 2.63703C4.33596 0.937152 6.59605 0.000976562 9 0.000976562C9.57333 0.000976562 10.1479 0.0554336 10.7076 0.162836C10.8507 0.190293 10.9444 0.328457 10.9169 0.471473C10.8895 0.614523 10.7511 0.707969 10.6083 0.680723C10.0812 0.579613 9.54004 0.52832 9 0.52832C6.73692 0.52832 4.60923 1.40965 3.00895 3.00993C1.40868 4.61021 0.527344 6.7379 0.527344 9.00098C0.527344 11.2641 1.40864 13.3917 3.00895 14.992C4.60927 16.5923 6.73692 17.4736 9 17.4736C11.2631 17.4736 13.3908 16.5923 14.991 14.992C16.5913 13.3917 17.4727 11.2641 17.4727 9.00098C17.4727 5.38424 15.1764 2.16404 11.7587 0.987883C11.621 0.940492 11.5478 0.790445 11.5952 0.652773C11.6426 0.515102 11.7926 0.441836 11.9303 0.489262C15.5608 1.73861 18 5.15924 18 9.00098C18 11.4049 17.0638 13.665 15.3639 15.3649C13.664 17.0648 11.4039 18.001 9 18.001Z"
          fill="currentColor"
        />
        <path
          d="M9 16.5947C4.81279 16.5947 1.40625 13.1882 1.40625 9.00098C1.40625 4.81376 4.81279 1.40723 9 1.40723C13.1872 1.40723 16.5938 4.81376 16.5938 9.00098C16.5938 10.4227 16.1968 11.8096 15.446 13.0117C15.3688 13.1352 15.2062 13.1728 15.0826 13.0957C14.9591 13.0185 14.9215 12.8559 14.9987 12.7324C15.6972 11.6141 16.0664 10.3238 16.0664 9.00098C16.0664 5.10454 12.8964 1.93457 9 1.93457C5.10356 1.93457 1.93359 5.10454 1.93359 9.00098C1.93359 12.8974 5.10356 16.0674 9 16.0674C11.0195 16.0674 12.9479 15.1971 14.2908 13.6796C14.3872 13.5705 14.5539 13.5604 14.663 13.6569C14.7721 13.7534 14.7822 13.92 14.6857 14.0291C13.2428 15.6596 11.1704 16.5947 9 16.5947Z"
          fill="currentColor"
        />
        <path
          d="M9.56865 13.9974H8.4317C7.79875 13.9974 7.28385 13.4825 7.28385 12.8496V10.7173H6.4742C6.32855 10.7173 6.21053 10.5993 6.21053 10.4537C6.21053 10.3081 6.32855 10.19 6.4742 10.19H7.54748C7.69314 10.19 7.81116 10.3081 7.81116 10.4537V12.8496C7.81116 13.1917 8.08952 13.4701 8.43166 13.4701H9.56862C9.91097 13.4701 10.1895 13.1917 10.1895 12.8496V10.4537C10.1895 10.3081 10.3075 10.19 10.4531 10.19H12.849C13.1912 10.19 13.4695 9.91149 13.4695 9.56914V8.43219C13.4695 8.09005 13.1912 7.81168 12.849 7.81168H10.4531C10.3075 7.81168 10.1895 7.69362 10.1895 7.54801V5.15176C10.1895 4.80962 9.91097 4.53125 9.56862 4.53125H8.43166C8.08952 4.53125 7.81116 4.80962 7.81116 5.15176V7.54801C7.81116 7.69362 7.69314 7.81168 7.54748 7.81168H5.15123C4.80913 7.81168 4.53076 8.09001 4.53076 8.43219V9.56914C4.53076 9.91149 4.80913 10.19 5.15123 10.19H5.24373C5.38938 10.19 5.5074 10.3081 5.5074 10.4537C5.5074 10.5993 5.38938 10.7173 5.24373 10.7173H5.15123C4.51832 10.7173 4.00342 10.2023 4.00342 9.56914V8.43219C4.00342 7.79927 4.51832 7.28434 5.15123 7.28434H7.28381V5.15176C7.28381 4.51884 7.79871 4.00391 8.43166 4.00391H9.56862C10.2017 4.00391 10.7168 4.5188 10.7168 5.15176V7.28434H12.849C13.482 7.28434 13.9969 7.79923 13.9969 8.43219V9.56914C13.9969 10.2023 13.482 10.7173 12.849 10.7173H10.7168V12.8496C10.7169 13.4825 10.2018 13.9974 9.56865 13.9974Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_876_430">
          <rect width="18" height="18" fill="white" transform="translate(0 0.000976562)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HospitalSubSvg;
