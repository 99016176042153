import { useState } from "react";

const ExpandPopupSVG = (props) => {
  const [fill, setFill] = useState("#fff");
  const [stroke, setStroke] = useState("#636578");

  return (
    <svg
      onMouseEnter={() => {
        setFill("#E7B400");
        setStroke("#fff");
      }}
      onMouseLeave={() => {
        setFill("#fff"); 
        setStroke("#636578");
      }}
      width="29"
      height="29"
      viewBox="0 0 30 30"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="29" height="29" rx="5.5" stroke="#E7B400" />
      <path
        d="M12.8717 21C12.9869 21.0006 13.1011 20.9789 13.2078 20.9361C13.3144 20.8933 13.4114 20.8301 13.4932 20.7504L18.7454 15.5855C18.9085 15.4243 19 15.2061 19 14.9787C19 14.7513 18.9085 14.5331 18.7454 14.3718L13.4932 9.20699C13.3258 9.06596 13.1103 8.99227 12.89 9.00064C12.6697 9.00901 12.4607 9.09882 12.3048 9.25212C12.1489 9.40543 12.0576 9.61094 12.0491 9.82758C12.0406 10.0442 12.1155 10.256 12.2589 10.4207L16.8897 14.9744L12.2589 19.528C12.136 19.6479 12.052 19.8009 12.0175 19.9678C11.9831 20.1346 11.9997 20.3077 12.0652 20.4653C12.1308 20.6229 12.2425 20.7577 12.386 20.8529C12.5296 20.9481 12.6986 20.9993 12.8717 21Z"
        fill={stroke || "#636578"}
      />
    </svg>
  );
};

export default ExpandPopupSVG;
