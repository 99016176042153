import { store } from "..";
import { LOGOUT } from "../store/types";

export async function signOut(navigate) {
  await localStorage.removeItem("x-auth");
  await localStorage.removeItem("facilityId");
  await localStorage.removeItem("accountId");
  await store.dispatch({ type: LOGOUT, payload: null });
  window.localStorage.clear();
  await navigate("/login", { replace: true });
  await navigate(0);
}
