// ----------------------------------------------------------------------

export default function Select(theme) {
    return {
        MuiSelect: {
            styleOverrides: {
                root: {
                    fontFamily: theme.palette.fontFamily.secondary,
                    background : theme.palette.grey[0]
                }                
            },
        },
    };
}
