import * as Yup from 'yup';

export const ChangePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old Password is required'),
    password: Yup.string().required('New Password is required')
        .min(6, 'Password must be at least 6 characters')
        .matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character')
        .matches(/^(?=.*[0-9])/, 'Must contain at least one number')
        .matches(/^(?=.*[" !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"])/, 'Must contain at least one special character')
    ,
    confirmNewPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
});