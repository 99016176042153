export const CHART_TAB_BUTTON = {
    CHART: "Chart",
    TABLE: "Table",
    BUILDING: "Building",
};

export const CHART_POINT_TYPE = {
    PERCENTAGE: "Percentage",
    TOTAL: "Total",
};


export const PATIENT_RELATION = {
    UNIT: "unit",
    INSURANCE: "insurance",
    DX: "dx",
    DOCTOR: "doctor",
    NURSE: "nurse",
    HOSPITAL: "hospital",
    SNF: "snf",
    PAYER_INSURANCE: "payerSourceInsurance",
    TRANSFER_TO_WHICH_ASSISTED_LIVING: "transferToWhichAssistedLiving",
}
