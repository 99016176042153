import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, Grid, FormLabel, FormControl, Avatar, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { UpdateProfileSchema } from '../../validations/UpdateProfileSchema';
import { useDispatch, useSelector } from 'react-redux';
import { saveUserProfile } from '../../services/user.service';
import { LOGIN } from '../../store/types';
import { useCallback, useState } from 'react';
import AvatarDialog from './AvatarDialog';

export default function UpdateProfile() {
    const { auth } = useSelector(({ auth }) => ({ auth }));
    const dispatch = useDispatch();
    const [avatarModalOpen, setAvatarModalOpen] = useState(false)
    const formik = useFormik({
        initialValues: {
            id: auth?._id || '',
            fullName: auth?.fullName || '',
            jobTitle: auth?.jobTitle || '',
            avatar: auth?.avatar || '',
        },
        validationSchema: UpdateProfileSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            await saveUserProfile(values).then(async (res) => {
                setSubmitting(false)
                if (res && res.data) {
                    await dispatch({ type: LOGIN, payload: res.data });
                }
            }).catch((err) => {
                setSubmitting(false)
            })
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, values, setFieldValue } = formik;

    const handleChangeAvatar = useCallback(() => {
        setAvatarModalOpen(true);
    }, []);

    const handleCloseAvatar = useCallback(() => {
        setAvatarModalOpen(false);
    }, []);

    const handleClickAvatar = useCallback((name) => {
        setFieldValue("avatar", name)
        setAvatarModalOpen(false);
    }, [setFieldValue]);


    return (
        <Grid container>
            <Grid item xs={12} md={8}>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Stack spacing={2} maxWidth="sm">
                            <Stack spacing={3}>
                                <Stack direction="row" spacing={2} alignItems={"center"}>
                                    <Avatar sx={{ width: 60, height: 60 }} alt={values.fullName} src={`/assets/avatars/${values.avatar}.png`} />
                                    <Link onClick={handleChangeAvatar}>Change Avatar</Link>
                                </Stack>
                                <FormControl>
                                    <FormLabel>Full Name</FormLabel>
                                    <TextField
                                        {...getFieldProps('fullName')}
                                        fullWidth
                                        autoComplete="off"
                                        type={"text"}
                                        error={Boolean(touched.fullName && errors.fullName)}
                                        helperText={(touched.fullName && errors.fullName)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Job Title</FormLabel>
                                    <TextField
                                        {...getFieldProps('jobTitle')}
                                        fullWidth
                                        autoComplete="off"
                                        type={"text"}
                                    />
                                </FormControl>
                                {/* <FormControl>
                                    <FormLabel>TimeZone {values.timeZone.value}</FormLabel>
                                    <Field
                                        component={TimeZoneField}
                                        name="timeZone"
                                        defaultValue={values.timeZone}
                                        {...getFieldProps('timeZone')}
                                    />
                                </FormControl> */}
                            </Stack>
                            <Stack>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                    loadingIndicator="Loading..."
                                >
                                    Update Profile
                                </LoadingButton>
                            </Stack>
                        </Stack>
                    </Form>
                </FormikProvider>
            </Grid>
            {avatarModalOpen && (
                <AvatarDialog
                    handleClose={handleCloseAvatar}
                    handleClickAvatar={handleClickAvatar}
                />)
            }
        </Grid>
    );
}
