import {
  getReportSubscriptionData,
  removeReportSubscriptionData,
  saveReportSubscriptionData,
  updateReportSubscriptionData,
} from "./api/report-subscription.api";

const getReportSubscription = async (type) => {
  try {
    const res = await getReportSubscriptionData(type);
    return res;
  } catch (e) {
    console.log(e);
  }
};

const saveReportSubscription = async (body) => {
  try {
    const res = await saveReportSubscriptionData(body);
    return res;
  } catch (e) {
    console.log(e.message, "error here");
  }
};

const removeReportSubscription = async (id) => {
  try {
    const res = await removeReportSubscriptionData(id);
    return res;
  } catch (e) {
    console.log(e);
  }
};

const updateReportSubscription = async (id, body) => {
  try {
    const res = await updateReportSubscriptionData(id, body);
    return res;
  } catch (e) {
    console.log(e);
  }
};

export {
  getReportSubscription,
  saveReportSubscription,
  removeReportSubscription,
  updateReportSubscription,
};
