import * as React from 'react';
import { Stack } from '@mui/material';
import { dateFormat } from '../../../utilis/date-formats';

export default function SelectedDateContainer({ queryFilters }) {
    return (
        <Stack item direction={"row"} alignItems="center" sx={{ mr: 3 }}>
        <span className="ffmar-bold">Selected Date:&nbsp;</span>
        {dateFormat(queryFilters?.startDate, "MM/DD/YYYY")} -{" "}
        {dateFormat(queryFilters?.endDate, "MM/DD/YYYY")}
    </Stack>
    );
}
