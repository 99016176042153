const MinimizeSVG = (props) => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.25 4.50001H6.49997C6.49997 4.50001 6.414 4.5 6 4.5L5.5 4.50001C5.086 4.50001 5 4.50001 4.5 4.50001H0.75C0.335999 4.50001 0 4.83601 0 5.25001V5.74998C0 6.16398 0.335999 6.49998 0.75 6.49998H4.5H5.25C5.5 6.49998 5.586 6.49998 6 6.49998C6.414 6.49998 6.5 6.50001 6.5 6.50001L10.25 6.49998C10.664 6.49998 11 6.16398 11 5.74998V5.25001C11 4.83601 10.664 4.50001 10.25 4.50001Z"
      fill={props.fill || "#B3B3B3"}
    />
  </svg>
);

export default MinimizeSVG;
