import { useCallback } from "react";
import { useNavigate } from "react-router";
import styles from "./NoFacilityDialog.module.scss";
import NewCloseSVG from "../../../assets/svgs/new-close.svg";
import Button from "../../ui/button/Button";

const NoFacilityDialog = (props) => {
  const navigate = useNavigate();

  const handleAddNewFacility = useCallback(() => {
    navigate(`/settings/facilities?showAdd=true`);
  }, [navigate]);

  return (
    <>
      <div className={styles.overlay}></div>
      <div className={styles.noFacilityDialog}>
        <div className={`df aic ${styles.hdr}`}>
          <p className={`aic ffmar fw600 fs18`}>
            <span className={`m-r-8 ${styles.lightText}`}>
              No Facility found
            </span>{" "}
          </p>
          <div className={`mla`}>
            <div
              className={`df acc ${styles.closeBtn}`}
              onClick={() => props.close()}
            >
              <NewCloseSVG />
            </div>
          </div>
        </div>
        <div className={`p-t-20 p-r-20 p-b-20 p-l-20 df ffc ${styles.stepTwo}`}>
          You have not added any facilities to this account. Please add at least
          one facility before adding any data.
        </div>
        <div className={`df ffc aic p-r-20 p-b-20 p-l-20`}>
          <Button
            text="Click here to Add Facility"
            buttonStyle="theme"
            size="medium"
            action={handleAddNewFacility}
          />
        </div>
      </div>
    </>
  );
};

export default NoFacilityDialog;
