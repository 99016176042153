import { useLocation, useNavigate, useSearchParams, createSearchParams } from "react-router-dom";

const useSetParamToURL = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams()

	const params = new URLSearchParams(location.search);

	const setPathWithParams = async (pathname, paramsData = null) => {
		var removeKeys = ["token"];

		if (removeKeys && removeKeys.length > 0) {
			for (const key of removeKeys) {
				searchParams.delete(key);
				params.delete(key);
			}
		}

		if (paramsData && paramsData.length > 0) {
			for await (const paramVal of paramsData) {
				const { key, value } = paramVal;
				await searchParams.set(key, value);
			}
			setSearchParams(searchParams);
		}
		const queryParams = paramsData ? createSearchParams(searchParams) : createSearchParams(params);		
		
		if (pathname.includes("hospital") || pathname === "/") {
			navigate(`${pathname}?${queryParams}`, { replace: true });
		} else {
			navigate(`${pathname}?${queryParams}`);
		}
	}

	const setPreviousParams = (pathname) => {
		return `${pathname}?${createSearchParams(params)}`;
	}

	const setParamToURL = async (paramsData) => {
		if (paramsData && paramsData.length > 0) {
			for await (const paramVal of paramsData) {
				const { key, value } = paramVal;
				if (params.has(key)) {
					searchParams.set(key, value);
				} else {
					searchParams.append(key, value);
				}
			}
		}
		setSearchParams(searchParams);
	}

	const getAccountId = () => {
		return params.get("accountId");
	}

	return {
		getAccountId,
		setPreviousParams,
		setParamToURL,
		setPathWithParams,
	};
};

export default useSetParamToURL;
