import * as React from "react";
import styles from "../dashboard/card/Card.module.scss";
import { UTCToLocalDate } from "../../utilis/date-formats";

export default function ActivityLogTooltip({ logs = [] }) {
    return (
        <div className={styles.tooltipContentContainerLog}>
            <h6 styles={{ marginBottom: "2px" }}>Activity Log</h6>

            {logs.length > 0 ? (
                <table>
                    <tr>
                        <th>Name</th>
                        <th>Action</th>
                        <th>Date</th>
                    </tr>
                    {logs.map((ele, idx) => {
                        return (
                            <>
                                <tr>
                                    <td>{ele?.userName}</td>
                                    <td>{ele?.description}</td>
                                    <td>{ele?.date ? UTCToLocalDate(ele?.date, "MM/DD/YYYY hh:mm A") : ""}</td>
                                </tr>
                            </>
                        )
                    })}
                </table>
            ) : <p>No activity log found</p>}
        </div>
    );
}