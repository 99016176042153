const InfoSVG = (props) => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 0C2.4673 0 0 2.46733 0 5.50003C0 8.53273 2.4673 11 5.5 11C8.5327 11 11 8.53273 11 5.50003C11 2.46733 8.5327 0 5.5 0ZM5.5 10C3.01867 10 1 7.98133 1 5.50003C1 3.01873 3.01867 1 5.5 1C7.98133 1 10 3.01873 10 5.50003C10 7.98133 7.9813 10 5.5 10Z"
      fill={props.fill || "#4879F5"}
    />
    <path
      d="M5.50003 2.33333C5.1325 2.33333 4.8335 2.63253 4.8335 3.00029C4.8335 3.36773 5.1325 3.66666 5.50003 3.66666C5.86756 3.66666 6.16656 3.36773 6.16656 3.00029C6.16656 2.63253 5.86756 2.33333 5.50003 2.33333Z"
      fill={props.fill || "#4879F5"}
    />
    <path
      d="M5.5 4.66667C5.22387 4.66667 5 4.89054 5 5.16667V8.16667C5 8.44281 5.22387 8.66667 5.5 8.66667C5.77613 8.66667 6 8.44281 6 8.16667V5.16667C6 4.89054 5.77613 4.66667 5.5 4.66667Z"
      fill={props.fill || "#4879F5"}
    />
  </svg>
);

export default InfoSVG;
