/* eslint-disable array-callback-return */
import _ from "lodash";
import { store } from "..";
import { TYPES } from "../data/hospital.data";
import {
	setFacilityPercentage as setFacilityPercentageComparison,
	setFilterTotal as setFilterTotalComparison,
	setIsCensusTotalLocked as setIsCensusTotalLockedComparison,
	setLockedByFacility as setLockedByFacilityComparison,
	setLockedTotalBy as setLockedTotalByComparison,
	setLockTotal as setLockTotalComparison,
} from "../store/reducers/comparisonReducers/deceasedComparison.slice";
import {
	setFacilityPercentage,
	setFilterTotal,
	setIsCensusTotalLocked,
	setLockedByFacility,
	setLockedTotalBy,
	setLockTotal,
} from "../store/reducers/deceased.slice";
import { TOTAL_TYPE } from "../types/common.type";
import { DECEASED_CARDS_TYPE } from "../types/deceased.type";
import { filterListDataItems, getDynamicPercentageBy, updateListTotalValue } from "./common";

export async function plannedFilter(patientData, oldFilter, type = null) {
	patientData = _.filter(patientData, ({ floorId, doctorId, insuranceId }) => {
		let filterArr = [];

		if (oldFilter.insuranceData.length > 0 && type !== "insuranceData") {
			filterArr.push(_.includes(oldFilter.insuranceData, insuranceId));
		}
		if (oldFilter.doctorData.length > 0 && type !== "doctorData") {
			filterArr.push(_.includes(oldFilter.doctorData, doctorId));
		}
		if (oldFilter.floorsData.length > 0 && type !== "floorsData") {
			filterArr.push(_.includes(oldFilter.floorsData, floorId));
		}
		return _.every(filterArr);
	});
	return patientData;
}

// * added comparison
export async function updateFacilityPercentageTotal(data, forComparison) {
	let facilityWiseTotal = _(data)
		.groupBy("facilityId")
		.sortBy((group) => data.indexOf(group[0]))
		.map((product) => {
			return {
				id: product[0]?.facilityId,
				total: product.length || 0,
			};
		})
		.value();
	forComparison
		? store.dispatch(setFacilityPercentageComparison(facilityWiseTotal))
		: store.dispatch(setFacilityPercentage(facilityWiseTotal));
}

// * added comparison
export async function updateFilterListData(cardFilter, patientList, priorityData = [], forComparison) {
	let objectCustom = Object();
	let newSavedFilters = [];
	let patientFilterData = patientList.list;
	const {
		dbData,
		lockedTotal,
		transferType,
		lockedTotalBy		
	} = forComparison ? store.getState().deceasedComparison : store.getState().deceased;

	let censusAverage = dbData?.censusAverage;

	if (!forComparison) {
		censusAverage = await getDynamicPercentageBy(dbData);
	}

	let lockedTotalModified = lockedTotal;

	let isComparingAgainstAvgCensus = true;

	let totalFilterData = {
		originalData: patientList.list,
		totalType: TOTAL_TYPE.FILTER,
		totalForPercentage: !transferType ? censusAverage : patientFilterData.length,
	};
	let mainNumPercentage = censusAverage;
	if (transferType) {
		mainNumPercentage = null;
		isComparingAgainstAvgCensus = false;
		totalFilterData.totalForPercentage = patientFilterData.length;
	}
	forComparison ? store.dispatch(setFacilityPercentageComparison([])) : store.dispatch(setFacilityPercentage([]));
	if (!lockedTotalModified) {
		forComparison
			? store.dispatch(setIsCensusTotalLockedComparison(false))
			: store.dispatch(setIsCensusTotalLocked(false));
		forComparison ? store.dispatch(setLockedTotalByComparison(null)) : store.dispatch(setLockedTotalBy(null));
	}
	if (lockedTotalBy) {
		if (lockedTotalBy === TYPES.ALL || lockedTotalBy === "census") {
			if (lockedTotalModified && transferType !== lockedTotalBy && lockedTotalBy !== "census") {
				forComparison ? store.dispatch(setLockedTotalByComparison(null)) : store.dispatch(setLockedTotalBy(null));
				forComparison ? store.dispatch(setLockTotalComparison(null)) : store.dispatch(setLockTotal(null));
				lockedTotalModified = null;
			}
		} else {
			const lockedFilterRemoved = _.find(priorityData, { type: lockedTotalBy });
			if (!lockedFilterRemoved) {
				forComparison ? store.dispatch(setLockedTotalByComparison(null)) : store.dispatch(setLockedTotalBy(null));
				forComparison ? store.dispatch(setLockTotalComparison(null)) : store.dispatch(setLockTotal(null));
				lockedTotalModified = null;
			}
		}
	}

	if (lockedTotalModified) {
		//mainNumPercentage = null;
		totalFilterData.totalForPercentage = lockedTotalModified;
	}
	if (lockedTotalModified && priorityData.length === 1 && !transferType) {
		totalFilterData.totalForPercentage = lockedTotalModified;
	}

	if (priorityData?.length > 0) {
		isComparingAgainstAvgCensus = false;
	}

	if (
		(priorityData?.length > 0 && (!lockedTotalBy || !lockedTotal)) ||
		(priorityData?.length > 0 && lockedTotalBy !== "census" && lockedTotalBy !== TYPES.ALL)
	) {
		totalFilterData.totalForPercentage = null;
	}

	if (priorityData?.length > 0) {
		let i = 0;
		for await (const ele of priorityData) {
			i++;
			if (priorityData?.length === i && lockedTotalModified && !lockedTotalBy) {
				forComparison
					? store.dispatch(setLockedTotalByComparison(ele.type))
					: store.dispatch(setLockedTotalBy(ele.type));
			}
			if (ele.type === DECEASED_CARDS_TYPE.INSURANCE_DATA) {
				objectCustom.insuranceData = await insuranceData(patientFilterData, {
					...totalFilterData,
					totalType: TOTAL_TYPE.MAIN,
					totalForPercentage: mainNumPercentage && i === 1 ? mainNumPercentage : totalFilterData.totalForPercentage,
				});

				patientFilterData = _.filter(patientFilterData, ({ insuranceId }) =>
					_.every([_.includes(cardFilter.insuranceData, insuranceId)])
				);
			}

			if (ele.type === DECEASED_CARDS_TYPE.FLOORS_DATA) {
				objectCustom.floorsData = await floorsData(patientFilterData, {
					...totalFilterData,
					totalType: TOTAL_TYPE.MAIN,
					totalForPercentage: mainNumPercentage && i === 1 ? mainNumPercentage : totalFilterData.totalForPercentage,
				});

				patientFilterData = _.filter(patientFilterData, ({ floorId }) =>
					_.every([_.includes(cardFilter.floorsData, floorId)])
				);
			}

			if (ele.type === DECEASED_CARDS_TYPE.DOCTOR_DATA) {
				objectCustom.doctorData = await doctorData(patientFilterData, {
					...totalFilterData,
					totalType: TOTAL_TYPE.MAIN,
					totalForPercentage: mainNumPercentage && i === 1 ? mainNumPercentage : totalFilterData.totalForPercentage,
				});

				patientFilterData = _.filter(patientFilterData, ({ doctorId }) =>
					_.every([_.includes(cardFilter.doctorData, doctorId)])
				);
			}

			if (ele.type === DECEASED_CARDS_TYPE.NINETY_DAYS_DATA) {
				objectCustom.ninetyDaysData = await ninetyDaysDataList(patientList.ninetyDaysData, patientFilterData, {
					...totalFilterData,
					totalType: TOTAL_TYPE.MAIN,
					totalForPercentage: mainNumPercentage && i === 1 ? mainNumPercentage : totalFilterData.totalForPercentage,
				});
				if (i === priorityData.length) {
					updateFacilityPercentageTotal(patientFilterData, forComparison);
				}
				patientFilterData = await ninetyDaysDataFilter(
					cardFilter.ninetyDaysData,
					patientFilterData,
					patientList.ninetyDaysData
				);
			}
			newSavedFilters = [...newSavedFilters, ele.type];
		}

		const totalCount = lockedTotalModified ? lockedTotalModified : patientFilterData.length;
		forComparison ? store.dispatch(setFilterTotalComparison(totalCount)) : store.dispatch(setFilterTotal(totalCount));

		if (!_.includes(newSavedFilters, DECEASED_CARDS_TYPE.INSURANCE_DATA)) {
			objectCustom.insuranceData = await insuranceData(patientFilterData, {
				...totalFilterData,
				totalForPercentage: totalCount,
			});
		}
		if (!_.includes(newSavedFilters, DECEASED_CARDS_TYPE.FLOORS_DATA)) {
			objectCustom.floorsData = await floorsData(patientFilterData, {
				...totalFilterData,
				totalForPercentage: totalCount,
			});
		}
		if (!_.includes(newSavedFilters, DECEASED_CARDS_TYPE.DOCTOR_DATA)) {
			objectCustom.doctorData = await doctorData(patientFilterData, {
				...totalFilterData,
				totalForPercentage: totalCount,
			});
		}
		if (!_.includes(newSavedFilters, DECEASED_CARDS_TYPE.NINETY_DAYS_DATA)) {
			objectCustom.ninetyDaysData = await ninetyDaysDataList(patientList.ninetyDaysData, patientFilterData, {
				...totalFilterData,
				totalForPercentage: totalCount,
			});
		}
		if (!lockedTotalModified) {
			forComparison
				? store.dispatch(setLockedByFacilityComparison(patientFilterData))
				: store.dispatch(setLockedByFacility(patientFilterData));
		}
	} else {
		let totalCount;

		if (mainNumPercentage) {
			totalCount = mainNumPercentage;
		} else if (transferType && transferType.length > 0) {
			totalCount = patientFilterData.length;
		} else {
			totalCount = censusAverage;
		}
		forComparison
			? store.dispatch(setFilterTotalComparison(lockedTotalModified ? lockedTotalModified : totalCount))
			: store.dispatch(setFilterTotal(lockedTotalModified ? lockedTotalModified : totalCount));
		if (lockedTotalModified && !lockedTotalBy) {
			if (transferType) {
				forComparison
					? store.dispatch(setLockedTotalByComparison(transferType))
					: store.dispatch(setLockedTotalBy(transferType));
			} else {
				forComparison
					? store.dispatch(setLockedTotalByComparison("census"))
					: store.dispatch(setLockedTotalBy("census"));
			}
		}
		if (!transferType && lockedTotalModified) {
			forComparison
				? store.dispatch(setIsCensusTotalLockedComparison(true))
				: store.dispatch(setIsCensusTotalLocked(true));
		}
		if (!lockedTotalModified) {
			forComparison
				? store.dispatch(setLockedByFacilityComparison(patientFilterData))
				: store.dispatch(setLockedByFacility(patientFilterData));
		}
		updateFacilityPercentageTotal(patientFilterData, forComparison);
		[objectCustom.insuranceData, objectCustom.floorsData, objectCustom.doctorData, objectCustom.ninetyDaysData] =
			await Promise.all([
				insuranceData(patientFilterData, totalFilterData),
				floorsData(patientFilterData, totalFilterData),
				doctorData(patientFilterData, totalFilterData),
				ninetyDaysDataList(patientList.ninetyDaysData, patientFilterData, totalFilterData),
			]);
	}

	objectCustom.isComparingAgainstAvgCensus = isComparingAgainstAvgCensus;

	return objectCustom;
}

async function ninetyDaysDataFilter(cardFilter, patientData, ninetyDaysData) {
	let ninetyDaysDataIds = [];
	let ninetyDaysDataFilter = _.filter(ninetyDaysData, ({ _id }) => _.every([_.includes(cardFilter, _id)]));
	if (ninetyDaysDataFilter && ninetyDaysDataFilter.length > 0) {
		ninetyDaysDataFilter.map((item) => (ninetyDaysDataIds = [...ninetyDaysDataIds, ...item.ids]));
	}
	patientData = _.filter(patientData, ({ _id }) => _.every([_.includes(ninetyDaysDataIds, _id)]));
	return patientData;
}

async function ninetyDaysDataList(data, patients, totalFilter) {
	const patientIds = patients.length > 0 ? patients.map((item) => item.id) : [];
	let percentageTotal = totalFilter?.totalForPercentage ? totalFilter.totalForPercentage : patients?.length;

	const res = await updateListTotalValue(data, patientIds, "value", percentageTotal, totalFilter);
	return res;
}

async function insuranceData(data, totalFilter) {
	//insuranceData Cal
	let insuranceDBDataGroup = [];
	if (data && data.length > 0) {
		const insuranceDBData = _.groupBy(data, "insuranceId");
		const originalData = _.groupBy(totalFilter.originalData, "insuranceId");
		let percentageTotal = totalFilter?.totalForPercentage ? totalFilter.totalForPercentage : data?.length;

		if (insuranceDBData) {
			insuranceDBDataGroup = await filterListDataItems(insuranceDBData, "insurance", percentageTotal, {
				...totalFilter,
				originalData,
			});
		}
	}

	return insuranceDBDataGroup;
}

async function doctorData(data, totalFilter) {
	//Doctor list update Cal
	let doctorDBDataGroup = [];
	if (data && data.length > 0) {
		const doctorDBData = await _.groupBy(data, "doctorId");
		const originalData = _.groupBy(totalFilter.originalData, "doctorId");
		let percentageTotal = totalFilter?.totalForPercentage ? totalFilter.totalForPercentage : data?.length;

		if (doctorDBData) {
			doctorDBDataGroup = await filterListDataItems(doctorDBData, "doctor", percentageTotal, {
				...totalFilter,
				originalData,
			});
		}
	}
	return doctorDBDataGroup;
}

async function floorsData(data, totalFilter) {
	//FloorsData Cal
	let unitDBDataGroup = [];
	if (data && data.length > 0) {
		const floorDBData = await _.groupBy(data, "floorId");
		const originalData = _.groupBy(totalFilter.originalData, "floorId");
		let percentageTotal = totalFilter?.totalForPercentage ? totalFilter.totalForPercentage : data?.length;

		if (floorDBData) {
			unitDBDataGroup = await filterListDataItems(floorDBData, "unit", percentageTotal, {
				...totalFilter,
				originalData,
			});
		}
	}
	return unitDBDataGroup;
}
