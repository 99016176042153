import {
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Avatar,
} from "@mui/material";

export default function AvatarDialog({ handleClose, handleClickAvatar }) {
    return (
        <Dialog open={true}>
            <DialogTitle>Choose avatar</DialogTitle>
            <DialogContent>
                <Grid container direction={"row"} spacing={2}>
                    {[...Array(10)].map((ele, index) => (
                        <Grid
                            item
                            xs={2}
                            sx={{ ":hover": { opacity: 0.5, cursor: "pointer" } }}
                            onClick={() => handleClickAvatar(`avatar_${index + 1}`)}
                        >
                            <Avatar
                                sx={{ width: 60, height: 60 }}
                                alt={`avatar_${index}`}
                                src={`/assets/avatars/avatar_${index + 1}.png`}
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant="contained">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
