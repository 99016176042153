const CompareIconCircle = () => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="21" cy="21" r="21" fill="#DEEEFF" />
      <g clipPath="url(#clip0_2107_6059)">
        <path
          d="M19.182 12.8182H14.6366C13.632 12.8182 12.8184 13.6318 12.8184 14.6364V27.3636C12.8184 28.3682 13.632 29.1818 14.6366 29.1818H19.182V31H21.0002V11H19.182V12.8182ZM19.182 26.4546H14.6366L19.182 21V26.4546ZM27.3639 12.8182H22.8184V14.6364H27.3639V26.4546L22.8184 21V29.1818H27.3639C28.3684 29.1818 29.182 28.3682 29.182 27.3636V14.6364C29.182 13.6318 28.3684 12.8182 27.3639 12.8182Z"
          fill="#636578"
        />
      </g>
      <defs>
        <clipPath id="clip0_2107_6059">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(11 11)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CompareIconCircle;
