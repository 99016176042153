import { Typography } from "@mui/material";
import { useCallback, useState } from "react";
import ExpandPopupSVG from "../../../assets/svgs/expand-popup.svg";
import MinimizeSVG from "../../../assets/svgs/minimize.svg";
import PopupPlusSVG from "../../../assets/svgs/popup-plus.svg";
import styles from "./SelectableCard.module.scss";
import InfoIcon from "../../icon/InfoIcon";
import HtmlTooltip from "../../shared/HtmlTooltip";
import CardTooltipContent from "../card/CardTooltipContent";
import { IconButton } from "@mui/material";
import ReportButton from "../../shared/ReportButton";
import classNames from "classnames";

const SelectableCard = ({
	children,
	flex,
	title,
	minimizable,
	disabled,
	handleOnclickCard,
	selected,
	page = "",
	tooltipContent,
	handleOnClickAction,
	loading = false,
	handleOnClickReport,
}) => {
	const [showDetails, setShowDetails] = useState(true);

	const handleClickOnDetails = useCallback(
		(event, totalTitle) => {
			if (!loading) {
				event.stopPropagation();
				handleOnClickAction(totalTitle);
			}
		},
		[handleOnClickAction, loading]
	);

	const handleOnclickCardSelect = useCallback(
		(e) => {
			e.stopPropagation();
			if (!loading) {
				handleOnclickCard();
			}
		},
		[handleOnclickCard, loading]
	);

	return (
		<div
			className={classNames(
				styles.selectableCard,
				disabled && styles.disabledCard,
				selected && styles.selected,
				page && styles[page],
				loading && styles.skeleton,
				title === "Total +/-" &&  styles.overallTotalCard
			)}
			style={{ flex, height: `${showDetails ? "initial" : "fit-content"}` }}
			onClick={handleOnclickCardSelect}
		>
			<div className={`df aic ${styles.cardHeader}`}>
				<Typography className={`${styles.title}`} variant="subtitle1" component="p">
					{title}
				</Typography>
				<div className={`df aic mla`}>
					<div className={styles.tooltipWrapper}>
						{tooltipContent && (
							<div className={styles.htmlTooltip}>
								<HtmlTooltip content={!loading && <CardTooltipContent content={tooltipContent} />}>
									<IconButton
										disableFocusRipple={true}
										disableRipple={true}
										sx={{ cursor: loading ? "default" : "pointer" }}
									>
										<InfoIcon />
									</IconButton>
								</HtmlTooltip>
							</div>
						)}
						{loading ? (
							title !== "Total +/-" && (
								<div
									style={{ width: "29px", height: "29px", borderRadius: "5px", marginRight: "7px" }}
									className="skeleton"
								/>
							)
						) : (
							<ReportButton
								handleOnClickReport={handleOnClickReport}
								loading={loading}
								title={title}
								sx={{
									height: 28.5,
								}}
							/>
						)}
						{handleOnClickAction && (
							<div className={!loading && "cp"} onClick={(e) => handleClickOnDetails(e, title)}>
								{loading ? (
									<div style={{ width: "29px", height: "28px", borderRadius: "5px" }} className="skeleton"></div>
								) : (
									<div style={{ width: "29px", height: "29px", borderRadius: "5px", marginTop: "0px" }}>
										<ExpandPopupSVG style={{ cursor: "pointer" }} fill="#4879F5" />
									</div>
								)}
							</div>
						)}
					</div>
					{minimizable ? (
						<div
							className={`p-l-9 p-r-5 p-t-5 p-b-5 m-l-15`}
							style={{ cursor: "pointer" }}
							onClick={() => setShowDetails(!showDetails)}
						>
							{showDetails ? <MinimizeSVG /> : <PopupPlusSVG />}
						</div>
					) : undefined}
				</div>
			</div>
			{showDetails ? <div className={`df aic ${styles.cardContent}`}>{children}</div> : undefined}
		</div>
	);
};

export default SelectableCard;
