import * as React from "react";
import { Button, Stack } from "@mui/material";

const HospitalizationsProgressBarSkeleton = ({ sx, progressBarSx }) => {
	return (
		<Stack direction={"row"} sx={{ width: "62%", margin: "4px", ...sx }}>
			<Stack direction={"column"}>
				<Button variant="contained" size="small" className="skeleton" sx={{ cursor: "default" }}></Button>
			</Stack>
			<Stack direction={"column"} sx={{ width: "100%", ml: 2, mb: 0.7, ...progressBarSx }} justifyContent="flex-end">
				<div style={{ height: 10, width: "100%" }} className="skeleton"></div>
			</Stack>
		</Stack>
	);
};

export default HospitalizationsProgressBarSkeleton;
