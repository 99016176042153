const ThreeDots = props => (
  <svg
    width={props.width || "67px"}
    height={props.height || "66px"}
    viewBox="0 0 67 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_883_5547)">
      <rect x="8" y="5" width="51" height="50" rx="8" fill="white" />
    </g>
    <circle cx="33" cy="21" r="3" fill="#4879F5" />
    <circle cx="33" cy="31" r="3" fill="#4879F5" />
    <circle cx="33" cy="41" r="3" fill="#4879F5" />
    <defs>
      <filter
        id="filter0_d_883_5547"
        x="0"
        y="0"
        width="67"
        height="66"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_883_5547" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_883_5547"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default ThreeDots;
