import { useState } from "react";
import { NavLink } from "react-router-dom";
import AddNewSvg from "../../../assets/svgs/AddNewSvg.svg";
import styles from "./NavWrpr.module.scss";
import DataInputArrow from "../../../assets/svgs/DataInputArrow.svg";
import classNames from "classnames";
import { IconButton, Tooltip } from "@mui/material";

const NavWrpr = ({ to, label, icon, subs = [], expanded }) => {
  const [showSubs, setShowSubs] = useState(true);
  return (
    <div className={styles.navWrpr}>
      <NavLink
        replace={true}
        exact
        onClick={e => {
          if (subs.length > 0) {
            e.stopPropagation();
            e.preventDefault();
            setShowSubs(!showSubs);
          }
        }}
        isActive={(match, location) => {
          if (!match) {
            return false;
          }
          // only consider an event active if its event id is an odd number
          const eventID = parseInt(match.params.eventID);
          return !isNaN(eventID) && eventID % 2 === 1;
        }}
        className={({ isActive }) =>
          isActive
            ? `df aic ${styles.active} ${styles.main} ${icon ? styles.hasIcon : ""}`
            : `df aic ${styles.main}  ${icon ? styles.hasIcon : ""}`
        }
        to={to}
      // {...to && subs.length === 0 && { to: to }}
      >
        <div className={styles.iconsWrapper}>
          {icon && expanded && <><span className={styles.iconWrpr}>{icon}</span>  {label}</>}
          {icon && !expanded && <Tooltip title={label}>
            <IconButton
              sx={{
                backgroundColor: "transparent",
                "&:hover": {backgroundColor: "transparent", color: "#4879F5"}
              }}
              className={`${styles.iconWrpr} ${!expanded ? styles.iconWrprWithoutExpand : null}`}>
              {icon}
            </IconButton>
          </Tooltip>}
          {expanded && (
            <span
              className={`df mla p-t-10 p-b-10 p-r-7 p-l-7 c-pointer ${expanded ? styles.openSubNav : null} ${showSubs ? styles.subsActive : ""
                }`}
            >
              <span
                onClick={e => {
                  if (subs.length > 0) {
                    e.stopPropagation();
                    e.preventDefault();
                  }
                  setShowSubs(!showSubs);
                }}
                className={classNames(styles.iconWrpr, styles.subIconWrpr)}
              >
                {subs?.length ? <AddNewSvg /> : <DataInputArrow />}
              </span>
            </span>
          )}
        </div>
      </NavLink>
      {showSubs && subs.length > 0 && (
        <div className={styles.subsWrpr}>
          {subs.map((s, index) => (
            <NavLink
              exact
              replace={true}
              key={index}
              className={({ isActive }) =>
                isActive ? `df aic ${styles.navLink} ${styles.active}` : `${styles.navLink} df aic`
              }
              to={`${to}/${s.to}`}
            >
              <div className={styles.subItemWrapper}>
                <div className={classNames(styles.subIconActiveSquare)}>
                  <span className={styles.subIconWrapper}>{s.icon}</span>
                </div>
                <span>{s.label}</span>
              </div>
              <div className={styles.activeCircle}></div>
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};

export default NavWrpr;
