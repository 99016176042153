const DeleteSVG = (props) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.2302 0.000143657C6.17175 0.000143657 5.30188 0.869827 5.30188 1.92847V2.56962H0.646016C0.288595 2.56819 -0.00142897 2.85837 5.29689e-06 3.21563C0.00144185 3.57104 0.290622 3.85808 0.646016 3.85679H1.79873L2.56544 15.2214C2.6683 16.7269 3.84256 18.0194 5.40625 18.0194H12.5938C14.1575 18.0194 15.3317 16.7268 15.4346 15.2214L16.2039 3.85679H17.354C17.7094 3.85822 17.9987 3.5712 18 3.21563C18.0014 2.85821 17.7112 2.56819 17.354 2.56962L12.6985 2.56948V1.92832C12.6985 0.869867 11.8288 0 10.7702 0L7.2302 0.000143657ZM7.2302 1.28731H10.7699C11.1375 1.28731 11.4111 1.56069 11.4111 1.92847V2.56962H6.58419L6.58405 1.92832C6.58405 1.5607 6.86245 1.28716 7.23006 1.28716L7.2302 1.28731ZM3.08075 3.8566H14.9142L14.1524 15.1307C14.0893 16.054 13.4646 16.7321 12.5937 16.7321H5.40654C4.53568 16.7321 3.91093 16.0539 3.84786 15.1307L3.08075 3.8566Z"
      fill={props.fill || "#737373"}
    />
    <path
      d="M7.06915 6.43165C6.89834 6.43222 6.73486 6.50075 6.61463 6.62185C6.49425 6.7431 6.42716 6.90701 6.42774 7.0778V13.5103C6.42716 13.681 6.49425 13.845 6.61463 13.9663C6.73487 14.0874 6.89835 14.1558 7.06915 14.1563C7.24068 14.1571 7.40545 14.0892 7.52668 13.9679C7.64807 13.8466 7.71588 13.6818 7.71516 13.5103V7.0778C7.71588 6.90628 7.64807 6.7415 7.52668 6.62012C7.40544 6.49888 7.24066 6.43093 7.06915 6.43165V6.43165Z"
      fill={props.fill || "#737373"}
    />
    <path
      d="M10.9331 6.43165C10.7616 6.43093 10.5968 6.49888 10.4756 6.62012C10.3542 6.74151 10.2864 6.90629 10.2871 7.0778V13.5103C10.2864 13.6819 10.3542 13.8466 10.4756 13.9679C10.5968 14.0892 10.7616 14.1571 10.9331 14.1563C11.1039 14.1558 11.2674 14.0874 11.3878 13.9663C11.508 13.845 11.5753 13.681 11.5745 13.5103V7.0778C11.5753 6.907 11.508 6.74308 11.3878 6.62185C11.2674 6.50075 11.1039 6.43223 10.9331 6.43165V6.43165Z"
      fill={props.fill || "#737373"}
    />
  </svg>
);
export default DeleteSVG;
