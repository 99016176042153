import { Stack, Typography } from "@mui/material";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OfAvgCensusSVG from "../../../../assets/svgs/of-avc-census.svg";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../../utilis/percentage-comparison";
import ColorBox from "../../../shared/dashboard/ColorBox";
import CheckboxButton from "../../shared/checkboxButton/CheckboxButton";
import styles from "./TotalCoTransferCard.module.scss";

const TotalCoTransferCard = ({
	total,
	totalComparison,
	totalProjection,
	totalProjectionComparison,
	totalProjectionPercentage,
	totalComparisonPercentage = 0,
	totalProjectionComparisonPercentage = 0,
	totalPercentage,
	secondaryTitle,
	title,
	secondaryText,
	handleSelectCard,
	type,
	selected,
	filter,
	filterComparison,
	loading,
	projectionDays,
}) => {
	const [comparisonDiffColor, setComparisonDiffColor] = useState(null);
	const [, setProjectionComparisonDiffColor] = useState(null);
	const [comparingAgainstScaled, setComparingAgainstScaled] = useState(null);
	const [numberOfDays, setNumberOfDays] = useState(null);
	const [, setComparingAgainstScaledPro] = useState(null);
	const [, setNumberOfDaysPro] = useState(null);
	const percentageThresholds = useSelector((state) => state.comparison.percentageThresholds);
	const comparingAgainst = totalComparison;
	// const comparingAgainstProjection = totalProjectionComparison;

	useEffect(() => {
		const {
			percentageDiff,
			itemTotalComparisonScaled,
			numberOfDays: numberOfDaysTemp,
		} = calculateDiffBetweenPercentages(
			totalPercentage,
			totalComparisonPercentage,
			false,
			filter,
			filterComparison,
			totalComparison,
			projectionDays
		);
		const percentageChange = pickComparisonColor(percentageDiff);
		setComparisonDiffColor(percentageChange);
		setComparingAgainstScaled(itemTotalComparisonScaled);
		setNumberOfDays(numberOfDaysTemp);

		const {
			percentageDiff: percentageDiffPro,
			itemTotalComparisonScaled: itemTotalComparisonScaledPro,
			numberOfDays: numberOfDaysTempPro,
		} = calculateDiffBetweenPercentages(
			totalProjectionPercentage,
			totalProjectionComparisonPercentage,
			false,
			filter,
			filterComparison,
			totalProjectionComparison,
			projectionDays
		);
		const proPercentageChange = pickComparisonColor(percentageDiffPro);
		setProjectionComparisonDiffColor(proPercentageChange);
		setComparingAgainstScaledPro(itemTotalComparisonScaledPro);
		setNumberOfDaysPro(numberOfDaysTempPro);
	}, [
		projectionDays,
		totalPercentage,
		totalComparisonPercentage,
		totalProjectionPercentage,
		totalProjectionComparisonPercentage,
		percentageThresholds,
		filter,
		filterComparison,
		totalComparison,
		totalProjectionComparison,
	]);

	return (
		<div className={`df aic p-t-20 p-b-25 ${styles.totalCoTransferCard}`}>
			<div className={`${styles.leftSec}`}>
				<Typography textAlign={"center"} variant="subtitle2">{title}</Typography>
				<div className={`df`}>
					<div className={`m-t-4`}>
						<CheckboxButton
							className={`${styles.totalCoTransferCheckBox} ${selected ? styles.selected : null}`}
							handleToggle={(e) => {
								e.preventDefault();
								handleSelectCard(e.target.checked, type);
							}}
							checked={selected}
						/>
					</div>
					<div className={classNames("ffmar-bold", "fw700", "fs49", styles.totalTextMain, loading && "skeleton")}>
						<span className={`df aic ${styles.gradientText}`}>
							{!loading && Math.round(projectionDays === null ? total : totalProjection)}
							{!loading && (
								<ColorBox
									color={comparisonDiffColor}
									comparingAgainst={comparingAgainst}
									comparingAgainstScaled={comparingAgainstScaled}
									numberOfDays={numberOfDays}
								/>
							)}
						</span>
					</div>
				</div>
			</div>
			<div className={`${styles.rightSec} `}>
				<Typography textAlign={"center"} variant="subtitle2">{secondaryTitle}</Typography>
				<div className={`m-t-10 df jcc`}>
					<div
						className={classNames(`m-t-4`, loading && "skeleton")}
					>
						{loading ? "" :
							<Stack
								className={styles.ofAvcCensus}
							>
								<OfAvgCensusSVG />
							</Stack>
						}
					</div>
					<div
						className={`ffmar-bold fw700 fs49 df aic ${styles.gradientTextPercentage} ${loading && styles.skeleton} ${styles.totalText
							}`}
					>
						{loading ? (
							<span className="skeleton-text">0%</span>
						) : (
							`${projectionDays === null ? totalPercentage : totalProjectionPercentage}%`
						)}
						{/* {!loading && <ColorBox color={comparisonDiffColor} comparingAgainst={comparingAgainstProjection} />} */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default TotalCoTransferCard;
