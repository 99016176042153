import styles from "./Button.module.scss";

const Button = ({ size, buttonStyle, action, text, Icon, w100, disabled, className }) => {
  return (
    <button
      disabled={disabled}
      className={`ffmar fw700 c-pointer ${styles.btn} ${styles[size]} ${
        styles[buttonStyle]
      } ${w100 ? "w100" : ""} ${className}`}
      onClick={action}
      dangerouslySetInnerHTML={{
        __html: `${text}`,
      }}
    >
      {/* {Icon ? <Icon></Icon> : undefined} */}
    </button>
  );
};

export default Button;
