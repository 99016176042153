// types
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    facilities: [],
    defaultFacilities: [],
    prevStateFacilities: []
};

// ==============================|| SLICE - ActiveFacilities ||============================== //

const activeFacilities = createSlice({
    name: "activeFacilities",
    initialState,
    reducers: {
        addActiveFacility(state, action) {
            state.facilities = [...state.facilities, action.payload]
        },
        removeActiveFacility(state, action) {
            const index = state.facilities.findIndex(x => x === action.payload)
            const facilitiesCopy = [...state.facilities]

            if (index !== -1) {
                facilitiesCopy.splice(index, 1)
                state.facilities = facilitiesCopy
            }
        },
        removeAllActiveFacilities(state, action) {
            state.facilities = []
            state.defaultFacilities = []
        },
        setActiveFacilities(state, action) {
            state.prevStateFacilities = [...state.facilities]
            state.facilities = action.payload
        },
        setDefaultFacilities(state, action) {
            state.defaultFacilities = action.payload
        },
        getActiveFacilities(state, action) {
            return state.facilities
        }
    },
});

export default activeFacilities.reducer;

export const {
    setDefaultFacilities,
    addActiveFacility,
    removeActiveFacility,
    getActiveFacilities,
    setActiveFacilities,
    removeAllActiveFacilities,
} = activeFacilities.actions;
