// types
import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_FILTER } from "../../data/common.data";

const initialState = {
    filter: DEFAULT_FILTER,
    dbSelectedFilters: null
};

// ==============================|| SLICE - QUICK GLACE PAGE ||============================== //

const admission = createSlice({
    name: "quickGlace",
    initialState,
    reducers: {
        setDBSelectedFilters(state, action) {
            state.dbSelectedFilters = action.payload;
        },
        setFilterDateRange(state, action) {
            state.filter = { ...state.filter, ...action.payload };
        },
    },
});

export default admission.reducer;

export const {
    setDBSelectedFilters,
    setFilterDateRange
} = admission.actions;
