import { store } from "..";
import { ADD_NOTIFICATION } from "../store/types";
import { accountFacility, createFacility,editFacility } from "./api/facility.api";


const addFacility = async (body) => {
    try {
        const res = await createFacility(body);
        if (res && res?._id) {
            store.dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "success",
                    label: "Facility added successfully",
                    id: "addFacilityError",
                },
            });
        }
        return res;
    } catch (e) {
        store.dispatch({
            type: ADD_NOTIFICATION,
            payload: {
                type: "error",
                label: e?.response?.data?.message || "Error while add Facility.",
                id: "addFacilityError",
            },
        });
    }
};

const updateFacility = async (id, body) => {
    try {
        const res = await editFacility(id, body);
        if (res && res?._id) {
            store.dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "success",
                    label: "Facility saved successfully",
                    id: "deleteADTError",
                },
            });
        }
        return res;
    } catch (e) {
        store.dispatch({
            type: ADD_NOTIFICATION,
            payload: {
                type: "error",
                label: e?.response?.data?.message || "Error while trying to update facility.",
                id: "deleteADTError",
            },
        });
    }
};

const getAccountFacility = async (accountId = null) => {
    try {
        const res = await accountFacility(accountId);
        return res;
    } catch (e) {
        console.log(e);
    }
};

export {
    getAccountFacility,
    updateFacility,
    addFacility
}
