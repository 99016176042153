import styles from "./ShiftCard.module.scss";
import CheckboxButton from "../../shared/checkboxButton/CheckboxButton";
import React from "react";
import CardItemTooltip from "../../../shared/CardItemTooltip";
import { TotalTextWithBar } from "../dischargeEmergencyList/DischargeEmergencyList";

const ShiftCardSkeleton = () => {
	const fakeData = [
		{ label: "Morning", height: "10%" },
		{ label: "Evening", height: "30%" },
		{ label: "Night", height: "20%" },
	];
	return (
		<div className={`w100 ${styles.shiftsSec}`}>
			<div style={{ height: "230px", width: "100%", paddingTop: "40px" }}>
				<div className={`ffmar df jcc ${styles.leftSec}`}>
					{fakeData.map((item, index) => {
						return (
							<div className={`df ffc aic ${styles.barWrpr}`} key={item.height} id={`DC${index}`}>
								<TotalTextWithBar className="skeleton" />
								<div
									className={`df ffc acc ${styles.bar} skeleton`}
									style={{
										height: item.height,
									}}
								>
									<TotalTextWithBar className="skeleton" />
								</div>
								<p className={`fs14 m-t-8 ${styles.label}`}>{item.label}</p>
							</div>
						);
					})}
				</div>
			</div>

			<div className={`${styles.footerWrapper}`}>
				<p className={`p-l-20 p-r-20 p-t-15 p-b-15 ${styles.title}`}>Choose shift to show</p>

				<div className={`ffmar fs14 fw500 ${styles.itemsWrpr}`}>
					{fakeData.map((item, index) => {
						return (
							<CardItemTooltip item={item} key={item.height}>
								<div key={`${index}shiftList`} className={`df aic ${styles.sec}`}>
									<CheckboxButton
										disabled
										sx={{ p: 0, m: "8px", ml: "0", borderRadius: "5px" }}
										size="small"
										className="skeleton"
										buttonText={`${item.label}`}
									/>
								</div>
							</CardItemTooltip>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default ShiftCardSkeleton;
