import api from './api';

const API_PREFIX = "api/facility";

const createFacility = async (body) => {
    const response = await api.post(`${API_PREFIX}`, body);
    return response;
};

const editFacility = async (id, body) => {
    const response = await api.put(`${API_PREFIX}/${id}`, body);
    return response;
};

const accountFacility = async (accountId) => {
    const response = await api.get(`${API_PREFIX}/list/${accountId}`);
    return response;
};


export {
    createFacility,
    editFacility,
    accountFacility
};
