import * as React from 'react';
import { adtOverallAdtOptions, communityTransferOptions } from '../../../../data/common.data';
import DropDown from '../../../ui/drop-down/DropDown';

export default function AdtTransferViewFilter({ filters, tableFilterType, setOption }) {
    if (filters) {
        if (filters.type.parentId === "communityTransfer") {
            return <DropDown
                options={communityTransferOptions}
                selected={tableFilterType}
                setOption={setOption}
                width={`275`}
                size={`medium`}
                style={{ zIndex: 100 }}
                className={"table-filter-dropdown"}
            />
        }
        if (filters.type.parentId === "overall") {
            return <DropDown
                options={adtOverallAdtOptions}
                selected={tableFilterType}
                setOption={setOption}
                width={`275`}
                style={{ zIndex: 100 }}
                size={`medium`}
                className={"table-filter-dropdown"}
            />
        }
    }
    return null;
}
