const OverallSubSvg = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3828 12.3398C16.577 12.3398 16.7344 12.1824 16.7344 11.9883C16.7344 11.7941 16.577 11.6367 16.3828 11.6367C16.1886 11.6367 16.0312 11.7941 16.0312 11.9883C16.0312 12.1824 16.1886 12.3398 16.3828 12.3398Z"
        fill="currentColor"
      />
      <path
        d="M13.3242 4.42969C11.3773 4.42969 9.82818 5.86294 9.05899 6.74561C8.02083 5.60805 6.61001 4.42969 4.67578 4.42969C2.16949 4.42969 0 6.45567 0 9C0 11.5462 2.17129 13.5703 4.67578 13.5703C6.62119 13.5703 8.16933 12.1394 8.93813 11.2581C9.97502 12.3939 11.3853 13.5703 13.3242 13.5703C13.9718 13.5703 14.603 13.4378 15.2004 13.1766C15.3783 13.0988 15.4594 12.8916 15.3816 12.7136C15.3039 12.5357 15.0966 12.4546 14.9187 12.5324C14.4107 12.7545 13.8742 12.8672 13.3242 12.8672C11.5895 12.8672 10.3267 11.7914 9.19498 10.4889C8.56192 9.75864 7.82174 8.77254 7.77681 8.73805C6.83589 7.57121 5.88635 6.53906 4.67578 6.53906C3.28465 6.53906 2.10938 7.66603 2.10938 9C2.10938 10.334 3.28465 11.4609 4.67578 11.4609C5.80802 11.4609 6.78941 10.5235 7.55638 9.58918C7.85992 9.97242 8.1534 10.345 8.47136 10.723C7.80405 11.5072 6.39802 12.8672 4.67578 12.8672C2.52239 12.8672 0.703125 11.0962 0.703125 9C0.703125 6.90377 2.52239 5.13281 4.67578 5.13281C6.41359 5.13281 7.67809 6.2215 8.80228 7.51511C9.28174 8.06776 9.73002 8.65456 10.1675 9.19828C11.127 10.3906 12.0904 11.4609 13.3242 11.4609C14.7154 11.4609 15.8906 10.334 15.8906 9C15.8906 7.66603 14.7154 6.53906 13.3242 6.53906C12.1916 6.53906 11.2092 7.47809 10.4413 8.41384C10.1374 8.03074 9.84403 7.65865 9.52583 7.28072C10.1935 6.49529 11.6006 5.13281 13.3242 5.13281C15.4776 5.13281 17.2969 6.90377 17.2969 9C17.2969 9.5336 17.1838 10.0518 16.9607 10.5403C16.8801 10.7169 16.9579 10.9254 17.1345 11.0061C17.3115 11.0869 17.5199 11.0085 17.6003 10.8323C17.8655 10.2516 18 9.63506 18 9C18 6.45381 15.8287 4.42969 13.3242 4.42969ZM4.67578 10.7578C3.68318 10.7578 2.8125 9.93642 2.8125 9C2.8125 8.06358 3.68318 7.24219 4.67578 7.24219C5.53426 7.24219 6.31881 8.06309 7.10666 9.02791C6.4272 9.87564 5.56727 10.7578 4.67578 10.7578ZM13.3242 7.24219C14.3168 7.24219 15.1875 8.06358 15.1875 9C15.1875 9.93642 14.3168 10.7578 13.3242 10.7578C12.4653 10.7578 11.68 9.93811 10.8914 8.97462C11.5718 8.12563 12.4326 7.24219 13.3242 7.24219Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default OverallSubSvg;