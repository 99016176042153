import api from './api';

const API_PREFIX = "api/filter";

const getFiltersData = async (params) => {
    const response = await api.get(`${API_PREFIX}`, { params });
    return response;
};

const saveFilterData = async (body) => {
    const response = await api.post(`${API_PREFIX}`, body);
    return response;
};

const getPercentageAgainstData = async (params) => {
    const response = await api.get(`api/percentage-against/all`, { params });
    return response;
};

const savePercentageAgainstData = async (body) => {
    const response = await api.post(`api/percentage-against`, body);
    return response;
};

const removePercentageAgainstData = async (id) => {
    const response = await api.delete(`api/percentage-against/${id}`);
    return response;
};

const removeFilterData = async (id) => {
    const response = await api.delete(`${API_PREFIX}/${id}`);
    return response;
};

export {
    removePercentageAgainstData,
    getPercentageAgainstData,
    getFiltersData,
    saveFilterData,
    removeFilterData,
    savePercentageAgainstData
};
