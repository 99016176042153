import React, { useCallback, useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  FormControlLabel,
  Checkbox,
  Box,
  Button,
  Divider,
} from "@mui/material";
import MinimizeSVG from "../../../../assets/svgs/minimize.svg";
import PopupPlusSVG from "../../../../assets/svgs/popup-plus.svg";
import styles from "./ViewTransferStatusOfAdmission.module.scss";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import { setCardFilter } from "../../../../store/reducers/admission.slice";
import { setCardFilter as setCardFilterComparison } from "../../../../store/reducers/comparisonReducers/admissionComparison.slice";
import { OTHER_ADT_OPTIONS } from "../../../../data/admission.data";
import _ from "lodash";
import update from "immutability-helper";
import { LoadingButton } from "@mui/lab";
import HtmlTooltip from "../../../shared/HtmlTooltip";
import InfoIcon from "../../../icon/InfoIcon";
import CardTooltipContent from "../../card/CardTooltipContent";
import { Stack } from "@mui/system";

const ViewTransferStatusOfAdmission = ({ isDetailsOpen = true, handleOnClickRun, loading }) => {
  const [showDetails, setShowDetails] = useState(isDetailsOpen);
  const dispatch = useDispatch();
  const {
    cardFilter: { adtData },
    isAdtLoading,
    selectedADTTableData,
  } = useSelector((state) => state.admission);
  const {
    cardFilter: { adtData: adtDataComparison },
  } = useSelector((state) => state.admissionComparison);
  const handleChangeButton = useCallback(
    (newItem) => {
      const selectedItem = _.find(adtData, { id: newItem.id });

      if (!selectedItem) {
        const latestData = update(adtData, { $push: [newItem] });
        dispatch(setCardFilter({ adtData: latestData }));

        const latestDataComparison = update(adtDataComparison, { $push: [newItem] });
        dispatch(setCardFilterComparison({ adtData: latestDataComparison }));
      } else {
        const spliceIndex = adtData.findIndex(function (o) {
          return o.id === newItem.id;
        });
        const latestFacilityList = update(adtData, { $splice: [[spliceIndex, 1]] });
        dispatch(setCardFilter({ adtData: latestFacilityList }));

        const spliceIndexComparison = adtDataComparison.findIndex(function (o) {
          return o.id === newItem.id;
        });
        const latestFacilityListComparison = update(adtDataComparison, { $splice: [[spliceIndexComparison, 1]] });
        dispatch(setCardFilterComparison({ adtData: latestFacilityListComparison }));
      }
    },
    [adtData, adtDataComparison, dispatch]
  );

  const checkSelectedVal = (selectedADTTableData, childItem) => {
    if (selectedADTTableData && selectedADTTableData.length > 0) {
      const selectedData = _.find(selectedADTTableData, { childId: childItem.id });
      if (selectedData) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  const handleOnclickChildButton = useCallback(
    (type, childItem) => {
      const dataArray = adtData;
      const dataArrayComparison = adtDataComparison;

      const latestSelectedData = dataArray.map((item) => {
        if (item.id === type) {
          const childrenData = item.children.map((p) =>
            p.id === childItem.id
              ? { ...p, isSelected: checkSelectedVal(selectedADTTableData, childItem) ? true : !p.isSelected }
              : p
          );
          return Object.assign({}, item, { children: childrenData });
        } else {
          return item;
        }
      });
      dispatch(setCardFilter({ adtData: latestSelectedData }));

      const latestSelectedDataComparison = dataArrayComparison.map((item) => {
        if (item.id === type) {
          const childrenData = item.children.map((p) =>
            p.id === childItem.id
              ? { ...p, isSelected: checkSelectedVal(selectedADTTableData, childItem) ? true : !p.isSelected }
              : p
          );
          return Object.assign({}, item, { children: childrenData });
        } else {
          return item;
        }
      });
      dispatch(setCardFilterComparison({ adtData: latestSelectedDataComparison }));
    },
    [adtData, adtDataComparison, dispatch, selectedADTTableData]
  );

  const checkSelectedButtons = (item) => {
    const ids = _.map(adtData, "id");
    return _.includes(ids, item.id);
  };

  return (
    <div
      className={`${styles.fifty} ${styles.ViewThroughADTCard} ${styles.unplannedPlanned}`}
      style={{ height: `${showDetails ? "initial" : "fit-content"}` }}
    >
      <div className={`df aic ffmar-bold fs15 ${styles.cardHeader}`}>
        <Stack sx={{ mt: "10px", mr: "-10px" }}>
          <HtmlTooltip
            content={
              <CardTooltipContent
                content={`This section is designed to help users easily track and analyze transferred admissions. By connecting the admissions page with other related transfer tabs, you can seamlessly monitor the number of transferred admissions in one place.

                        To make your analysis even more detailed, you can use checkboxes for filtering. These checkboxes are available in both the admissions tab and the transfer status of admissions section. By selecting or deselecting these checkboxes, you can customize the displayed information according to your preferences.
                        
                        The best part is that this filtering process is bidirectional, meaning you can filter the admissions tab based on the transfer status of admissions and vice versa. This two-way filtering ensures that you have complete control over the data you're viewing and analyzing, making it easier to understand and interpret the information at hand.`}
              />
            }
          >
            <IconButton disableFocusRipple={true} disableRipple={true}>
              <InfoIcon />
            </IconButton>
          </HtmlTooltip>
        </Stack>
        <div className={`${styles.cardAction}`} onClick={() => setShowDetails(!showDetails)}>
          {showDetails ? <MinimizeSVG /> : <PopupPlusSVG />}
        </div>
        <p className={`${styles.title}`}>View transfer status of Admissions</p>
      </div>
      {showDetails && !loading && (
        <div className={`${styles.cardContent}'}`}>
          <Grid container direction={"row"} spacing={2}>
            <Grid item xs={3}>
              <List className={`${styles.listContainer}`}>
                {OTHER_ADT_OPTIONS.map((item, index) => {
                  const labelId = `checkbox-ADT-label-${index}`;
                  const labelName = item.label;
                  return (
                    <ListItem
                      key={index}
                      className={`${styles.listItem} ${checkSelectedButtons(item) ? styles.selectedItem : null}`}
                      secondaryAction={
                        <IconButton disableRipple={true} edge="end">
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                disableRipple
                                checked={checkSelectedButtons(item)}
                                indeterminate={checkSelectedButtons(item)}
                                onChange={() => handleChangeButton(item)}
                              />
                            }
                          />
                        </IconButton>
                      }
                    >
                      <ListItemButton
                        edge="start"
                        onClick={() => handleChangeButton(item)}
                        id={labelId}
                        className={styles.listIconButton}
                      >
                        <div className={`${styles.listItemHelperText}`}>{item.helperText}</div>
                        <div>
                          <ListItemText primary={labelName} />
                        </div>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
            <Grid item xs={9} sx={{ mt: 3, p: 3 }}>
              <Box className={styles.boxContainer}>
                {adtData && adtData.length > 0 && (
                  <List>
                    {adtData.map((item) => {
                      return (
                        <>
                          <ListItem>
                            <ListItemText
                              className={styles.primaryText}
                              primary={item.label}
                              secondary={
                                <ChildButtonList
                                  options={item.children}
                                  styles={styles}
                                  handleOnclickChildButton={handleOnclickChildButton}
                                  type={item.id}
                                />
                              }
                            />
                          </ListItem>
                          <Divider variant="middle" component="li" />
                        </>
                      );
                    })}
                  </List>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid container justifyContent={"center"} spacing={4} sx={{ padding: 3 }}>
            <Grid item>
              <LoadingButton
                variant="outlined"
                size="large"
                loading={loading}
                disabled={isAdtLoading}
                endIcon={<ChevronRightIcon />}
                loadingIndicator="Loading…"
                onClick={handleOnClickRun}
              >
                Run
              </LoadingButton>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default ViewTransferStatusOfAdmission;

const ChildButtonList = ({ styles, options, handleOnclickChildButton, type }) => {
  return (
    <Grid spacing={2} container sx={{ mt: 0.3, mb: 0.5 }}>
      {options.map((element) => (
        <Grid item key={element.id}>
          <Button
            key={element.label}
            className={`${styles.itemButton} ${element.isSelected ? styles.selected : null}`}
            variant={element.isSelected ? "outlined" : "contained"}
            onClick={() => handleOnclickChildButton(type, element)}
          >
            {element.label}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};
