import { toast } from "react-toastify";

export const clearToasts = () => {
  toast.dismiss();
};

export const successMessage = (text) => {
  toast.success(text, {
    theme: "light",
    position: "top-right",
    autoClose: 3000,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: true,
    pauseOnFocusLoss: false,
    bodyStyle: {
      borderRadius: "30",
      textAlign: "left",
      fontSize: 14,
    },
  });
};
export const errorMessage = (text) => {
  toast.error(text, {
    theme: "light",
    position: "top-right",
    autoClose: 3000,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: true,
    pauseOnFocusLoss: false,
    bodyStyle: {
      borderRadius: "30",
      textAlign: "left",
      fontSize: 14,
    },
  });
};

export const infoMessage = (text) => {
  toast.info(text, {
    theme: "light",
    position: "top-right",
    autoClose: 3000,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: true,
    pauseOnFocusLoss: false,
    bodyStyle: {
      borderRadius: "30",
      textAlign: "left",
      fontSize: 14,
    },
  });
};
export const warnMessage = (text) => {
  toast.warn(text, {
    theme: "light",
    position: "top-right",
    autoClose: 3000,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: true,
    pauseOnFocusLoss: false,
    bodyStyle: {
      borderRadius: "30",
      textAlign: "left",
      fontSize: 14,
    },
  });
};

export const loadingMessage = () => {
  const id = toast.loading("Please wait...", {
    theme: "light",
    position: "top-right",
    autoClose: 3000,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: true,
    pauseOnFocusLoss: false,
    bodyStyle: {
      borderRadius: "30",
      textAlign: "left",
      fontSize: 14,
    },
  });
  return id;
};

export const updateMessage = (id, message, type) => {
  toast.update(id, {
    render: message,
    type,
    isLoading: false,
    theme: "light",
    position: "top-right",
    autoClose: 3000,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: true,
    pauseOnFocusLoss: false,
    bodyStyle: {
      borderRadius: "30",
      textAlign: "left",
      fontSize: 14,
    },
  });
};
