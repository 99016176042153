import { useEffect, useRef } from "react";
import { useIdleTimer } from "react-idle-timer";
import axios from "../../axios";

const useTimeLogs = ({ accountId }) => {
	const intervalRef = useRef(null);

	useEffect(() => {
		intervalRef.current = setInterval(() => {
			axios.post("/api/log/timelog", { accountId });			
		}, 60000);

		return () => clearInterval(intervalRef.current);
	}, [accountId]);

	useIdleTimer({
		onIdle: () => {
			clearInterval(intervalRef.current);
		},
		timeout: 600000,
	});
};

export default useTimeLogs;
