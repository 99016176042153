import * as React from "react";
import InfoIcon from "../../icon/InfoIcon";
import styles from "./Card.module.scss";
import classNames from "classnames";

export default function CardTooltipContent({ content }) {
  return (
    <div className={styles.tooltipContentContainer}>
      <InfoIcon />
      <div className={classNames("ffint", styles.tooltipContent)}>
        {content ||
          `This PieChart showing you the same info as a graphs on the left side, but here you can see
        which part of total hospital transfers taken by each floor.`}
      </div>
    </div>
  );
}
