import api from "./api";

const API_PREFIX = "api/report-subscription";

const getReportSubscriptionData = async (params) => {
	const response = await api.get(`${API_PREFIX}/all`, { params });
	return response;
};

const saveReportSubscriptionData = async (body) => {
	const response = await api.post(`${API_PREFIX}`, body);
	return response;
};

const removeReportSubscriptionData = async (id) => {
	const response = await api.delete(`${API_PREFIX}/${id}`);
	return response;
};

const updateReportSubscriptionData = async (id, body) => {
	const response = await api.put(`${API_PREFIX}/${id}`, body);
	return response;
};

export {
	updateReportSubscriptionData,
	getReportSubscriptionData,
	saveReportSubscriptionData,
	removeReportSubscriptionData,
};
