import React from "react";
import { Button, Stack } from "@mui/material";
import styles from "../ChartDialogCommon.module.scss";
import { CHART_FILTER_DAY_OF, FILTER_TYPES } from "../../../../types/common.type";
import _ from "lodash";


export default function ChartFilterButtons({
    handleClickFilter,
    selectedFilter,
    filterButtons,
}) {
    return (
        <div className={styles.filterContainer}>
            <Stack direction="row" spacing={2}>
                <Stack item className={styles.filterTitle}>
                    Filter By:
                </Stack>
                {_.includes(filterButtons, FILTER_TYPES.DAILY) && (
                    <Stack item>
                        <Button
                            variant={
                                selectedFilter === FILTER_TYPES.DAILY ? "contained" : "outlined"
                            }
                            size="small"
                            onClick={() =>
                                handleClickFilter(FILTER_TYPES.DAILY, CHART_FILTER_DAY_OF.DAY)
                            }
                        >
                            Daily
                        </Button>
                    </Stack>
                )}
                {_.includes(filterButtons, FILTER_TYPES.WEEKLY) && (
                    <Stack item>
                        <Button
                            variant={
                                selectedFilter === FILTER_TYPES.WEEKLY
                                    ? "contained"
                                    : "outlined"
                            }
                            size="small"
                            onClick={() =>
                                handleClickFilter(FILTER_TYPES.WEEKLY, CHART_FILTER_DAY_OF.WEEK)
                            }
                        >
                            Weekly
                        </Button>
                    </Stack>
                )}
                {_.includes(filterButtons, FILTER_TYPES.SEVEN_DAYS) && (
                    <Stack item>
                        <Button
                            variant={
                                selectedFilter === FILTER_TYPES.SEVEN_DAYS
                                    ? "contained"
                                    : "outlined"
                            }
                            size="small"
                            onClick={() =>
                                handleClickFilter(
                                    FILTER_TYPES.SEVEN_DAYS,
                                    CHART_FILTER_DAY_OF.SEVEN
                                )
                            }
                        >
                            7 Days
                        </Button>
                    </Stack>
                )}
                {_.includes(filterButtons, FILTER_TYPES.MONTHLY) && (
                    <Stack item>
                        <Button
                            variant={
                                selectedFilter === FILTER_TYPES.MONTHLY
                                    ? "contained"
                                    : "outlined"
                            }
                            size="small"
                            onClick={() =>
                                handleClickFilter(
                                    FILTER_TYPES.MONTHLY,
                                    CHART_FILTER_DAY_OF.MONTH
                                )
                            }
                        >
                            Monthly
                        </Button>
                    </Stack>
                )}
                {_.includes(filterButtons, FILTER_TYPES.THIRTY_DAYS) && (
                    <Stack item>
                        <Button
                            variant={
                                selectedFilter === FILTER_TYPES.THIRTY_DAYS
                                    ? "contained"
                                    : "outlined"
                            }
                            size="small"
                            onClick={() =>
                                handleClickFilter(
                                    FILTER_TYPES.THIRTY_DAYS,
                                    CHART_FILTER_DAY_OF.THIRTY
                                )
                            }
                        >
                            30 Days
                        </Button>
                    </Stack>
                )}
                {_.includes(filterButtons, FILTER_TYPES.QUARTERLY) && (
                    <Stack item>
                        <Button
                            variant={
                                selectedFilter === FILTER_TYPES.QUARTERLY
                                    ? "contained"
                                    : "outlined"
                            }
                            size="small"
                            onClick={() =>
                                handleClickFilter(
                                    FILTER_TYPES.QUARTERLY,
                                    CHART_FILTER_DAY_OF.QUARTER
                                )
                            }
                        >
                            Quarterly
                        </Button>
                    </Stack>
                )}
                {_.includes(filterButtons, FILTER_TYPES.YEARLY) && (
                    <Stack item>
                        <Button
                            variant={
                                selectedFilter === FILTER_TYPES.YEARLY
                                    ? "contained"
                                    : "outlined"
                            }
                            size="small"
                            onClick={() =>
                                handleClickFilter(FILTER_TYPES.YEARLY, CHART_FILTER_DAY_OF.YEAR)
                            }
                        >
                            Yearly
                        </Button>
                    </Stack>
                )}
            </Stack>
        </div>
    );
}
