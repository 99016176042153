import React, { useCallback } from "react";
import { Button } from "@mui/material";
import styles from "./../ChartDialogCommon.module.scss";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import moment from "moment";
import { chartFilterPermission } from "../../../../utilis/charts-common";
import { FILTER_TYPES } from "../../../../types/common.type";
import _ from "lodash";

export default function ChartArrowButton({
	type,
	queryFilters,
	setQueryFilters,
	queryFiltersComparison,
	setQueryFiltersComparison,
	filters,
	reloadChartData,
	selectedFilter,
}) {
	const handleBackClickButton = useCallback(
		async (buttonType) => {
			//check selected date is exact month or not;
			let latestStartDate;
			let latestEndDate;
			let latestStartDateComparison;
			let latestEndDateComparison;
			let daysFilter;
			let daysFilterComparison;
			let isCompleteMonth = false;
			let isCompleteMonthComparison = false;
			let startOfMonthFormat = moment(queryFilters.startDate).startOf("month").format("YYYY-MM-DD");
			let endOfMonthFormat = moment(queryFilters.endDate).endOf("month").endOf("day").format("YYYY-MM-DD");
			let startOfMonthFormatComparison = moment(queryFiltersComparison?.startDate)
				.startOf("month")
				.format("YYYY-MM-DD");
			let endOfMonthFormatComparison = moment(queryFiltersComparison?.endDate)
				.endOf("month")
				.endOf("day")
				.format("YYYY-MM-DD");
			let startFormatted = moment(queryFilters.startDate).format("YYYY-MM-DD");
			let endDateFormatted = moment(queryFilters.endDate).format("YYYY-MM-DD");
			let startFormattedComparison = moment(queryFiltersComparison?.startDate).format("YYYY-MM-DD");
			let endDateFormattedComparison = moment(queryFiltersComparison?.endDate).format("YYYY-MM-DD");

			if (moment(startOfMonthFormat).isSame(startFormatted) && moment(endOfMonthFormat).isSame(endDateFormatted)) {
				isCompleteMonth = true;
			}

			// -----> START OF COMPARISON SECTION
			if (
				moment(startOfMonthFormatComparison).isSame(startFormattedComparison) &&
				moment(endOfMonthFormatComparison).isSame(endDateFormattedComparison)
			) {
				isCompleteMonthComparison = true;
			}
			// -----> END OF COMPARISON SECTION

			let days = parseInt(queryFilters.days);
			if (
				_.includes(queryFilters.filterButtons, FILTER_TYPES.MONTHLY) ||
				_.includes(queryFilters.filterButtons, FILTER_TYPES.THIRTY_DAYS) ||
				_.includes(queryFilters.filterButtons, FILTER_TYPES.WEEKLY)
			) {
				days = days + 1;
			}

			// -----> START OF COMPARISON SECTION
			let daysComparison = parseInt(queryFiltersComparison?.days);
			if (
				_.includes(queryFiltersComparison?.filterButtons, FILTER_TYPES.MONTHLY) ||
				_.includes(queryFiltersComparison?.filterButtons, FILTER_TYPES.THIRTY_DAYS) ||
				_.includes(queryFiltersComparison?.filterButtons, FILTER_TYPES.WEEKLY)
			) {
				daysComparison = daysComparison + 1;
			}
			// -----> END OF COMPARISON SECTION

			if (type === "left") {
				if (buttonType === "back") {
					latestStartDate = isCompleteMonth
						? moment(startOfMonthFormat).subtract(1, "month").toDate()
						: moment(queryFilters.startDate, "DD-MM-YYYY").subtract(days, "days").toDate();

					// -----> START OF COMPARISON SECTION
					latestStartDateComparison = isCompleteMonthComparison
						? moment(startOfMonthFormatComparison).subtract(1, "month").toDate()
						: moment(queryFiltersComparison?.startDate, "DD-MM-YYYY").subtract(daysComparison, "days").toDate();
					// -----> END OF COMPARISON SECTION
				}
				if (buttonType === "forward") {
					latestStartDate = isCompleteMonth
						? moment(startOfMonthFormat).add(1, "month").toDate()
						: moment(queryFilters.startDate, "DD-MM-YYYY").add(days, "days").toDate();

					// -----> START OF COMPARISON SECTION
					latestStartDateComparison = isCompleteMonthComparison
						? moment(startOfMonthFormatComparison).add(1, "month").toDate()
						: moment(queryFiltersComparison?.startDate, "DD-MM-YYYY").add(daysComparison, "days").toDate();
					// -----> END OF COMPARISON SECTION
				}
				daysFilter = chartFilterPermission({ startDate: latestStartDate, endDate: queryFilters.endDate });

				// -----> START OF COMPARISON SECTION
				daysFilterComparison = chartFilterPermission({
					startDate: latestStartDateComparison,
					endDate: queryFiltersComparison?.endDate,
				});
				// -----> END OF COMPARISON SECTION

				setQueryFilters((prevState) => ({
					...prevState,
					startDate: latestStartDate,
					filterButtons: daysFilter,
				}));

				// -----> START OF COMPARISON SECTION
				setQueryFiltersComparison((prevState) => ({
					...prevState,
					startDate: latestStartDateComparison,
					filterButtons: daysFilterComparison,
				}));
				// -----> END OF COMPARISON SECTION

				reloadChartData(latestStartDate, queryFilters.endDate, latestStartDateComparison, queryFilters?.endDate);
			}

			if (type === "right") {
				if (buttonType === "back") {
					latestEndDate = isCompleteMonth
						? moment(endOfMonthFormat).subtract(1, "month").endOf("month").startOf("day").toDate()
						: moment(queryFilters.endDate, "DD-MM-YYYY").subtract(days, "days").toDate();

					// -----> START OF COMPARISON SECTION
					latestEndDateComparison = isCompleteMonthComparison
						? moment(endOfMonthFormatComparison).subtract(1, "month").endOf("month").startOf("day").toDate()
						: moment(queryFiltersComparison?.endDate, "DD-MM-YYYY").subtract(daysComparison, "days").toDate();
					// -----> END  OF COMPARISON SECTION
				}
				if (buttonType === "forward") {
					latestEndDate = isCompleteMonth
						? moment(endOfMonthFormat).add(1, "month").endOf("month").startOf("day").toDate()
						: moment(queryFilters.endDate, "DD-MM-YYYY").add(days, "days").toDate();

					// -----> START OF COMPARISON SECTION
					latestEndDateComparison = isCompleteMonthComparison
						? moment(endOfMonthFormatComparison).add(1, "month").endOf("month").startOf("day").toDate()
						: moment(queryFiltersComparison?.endDate, "DD-MM-YYYY").add(daysComparison, "days").toDate();
					// -----> END OF COMPARISON SECTION
				}

				daysFilter = chartFilterPermission({ startDate: queryFilters.startDate, endDate: latestEndDate });

				// -----> START OF COMPARISON SECTION
				daysFilterComparison = chartFilterPermission({
					startDate: queryFiltersComparison?.startDate,
					endDate: latestEndDateComparison,
				});
				// -----> END OF COMPARISON SECTION

				setQueryFilters((prevState) => ({
					...prevState,
					endDate: latestEndDate,
					filterButtons: daysFilter,
				}));

				// -----> START OF COMPARISON SECTION
				setQueryFiltersComparison((prevState) => ({
					...prevState,
					endDate: latestEndDateComparison,
					filterButtons: daysFilterComparison,
				}));
				// -----> END OF COMPARISON SECTION

				reloadChartData(queryFilters.startDate, latestEndDate, queryFiltersComparison?.startDate, latestEndDate);
			}
		},
		[
			queryFilters.days,
			queryFilters.endDate,
			queryFilters.filterButtons,
			queryFilters.startDate,
			queryFiltersComparison?.days,
			queryFiltersComparison?.endDate,
			queryFiltersComparison?.filterButtons,
			queryFiltersComparison?.startDate,
			reloadChartData,
			setQueryFilters,
			setQueryFiltersComparison,
			type,
		]
	);

	const isButtonEnabled = useCallback(
		(buttonType) => {
			const { startDate = null, endDate = null } = filters?.filter;
			const latStartDate = moment(queryFilters.startDate, "YYYY-MM-DD");
			const latEndDate = moment(queryFilters.endDate, "YYYY-MM-DD");
			if (type === "left" && buttonType === "forward" && moment(startDate, "YYYY-MM-DD").isSame(latStartDate)) {
				return true;
			}
			if (type === "right" && buttonType === "back" && moment(endDate, "YYYY-MM-DD").isSame(latEndDate)) {
				return true;
			}
			return false;
		},
		[filters?.filter, queryFilters.endDate, queryFilters.startDate, type]
	);

	return (
		<>
			<Button
				className={styles.actionButton + " " + styles.prevBUtton}
				variant="outlined"
				onClick={() => handleBackClickButton("back")}
				disabled={isButtonEnabled("back")}
				disableElevation={true}
				disableFocusRipple={true}
				disableRipple={true}
			>
				<ArrowBackIosNewIcon sx={{ fontSize: 12, color: "#444652" }} />
			</Button>

			<Button
				className={styles.actionButton + " " + styles.nextBUtton}
				variant="outlined"
				onClick={() => handleBackClickButton("forward")}
				disabled={isButtonEnabled("forward")}
				disableRipple={true}
			>
				{" "}
				<ArrowForwardIosIcon sx={{ fontSize: 12, color: "#444652" }} />
			</Button>
		</>
	);
}
