import { Typography } from "@mui/material";
import { useCallback, useState } from "react";
import ExpandPopupSVG from "../../../../assets/svgs/expand-popup.svg";
import MinimizeSVG from "../../../../assets/svgs/minimize.svg";
import PopupPlusSVG from "../../../../assets/svgs/popup-plus.svg";
import styles from "./SelectableCardCoTransfer.module.scss";
import classNames from "classnames";
import CardTooltipContent from "../../card/CardTooltipContent";
import HtmlTooltip from "../../../shared/HtmlTooltip";
import InfoIcon from "../../../icon/InfoIcon";
import { IconButton } from "@mui/material";
import ReportButton from "../../../shared/ReportButton";

const SelectableCardCoTransfer = ({
	children,
	flex,
	title,
	minimizable,
	disabled,
	handleOnclickCard,
	selected,
	tooltipContent,
	handleOnClickAction,
	handleOnClickReport,
	loading = false,
}) => {
	const [showDetails, setShowDetails] = useState(true);

	const handleClickOnDetails = useCallback(
		(event, totalTitle) => {
			handleOnClickAction(totalTitle);
			event.preventDefault();
			event.stopPropagation();
		},
		[handleOnClickAction]
	);

	return (
		<div
			className={classNames(
				styles.selectableCardCoTransfer,
				disabled && styles.disabledCard,
				selected && styles.selected,
				loading && styles.skeleton
			)}
			style={{ flex, height: `${showDetails ? "initial" : "fit-content"}` }}
			onClick={() => !loading && handleOnclickCard(!selected)}
		>
			<div className={classNames("df aic", styles.cardHeader)}>
				<Typography className={styles.title} variant="subtitle1" component="p">
					{title}
				</Typography>
				<div className="df aic mla">
					<div style={{ display: "flex" }}>
						{tooltipContent && (
							<div className={styles.htmlTooltip}>
								<HtmlTooltip content={<CardTooltipContent content={tooltipContent} />}>
									<IconButton disableFocusRipple={true} disableRipple={true}>
										<InfoIcon />
									</IconButton>
								</HtmlTooltip>
							</div>
						)}
					</div>
					{loading ? (
						<div
							style={{ width: "29px", height: "29px", borderRadius: "5px", marginRight: "7px" }}
							className="skeleton"
						/>
					) : (
						<ReportButton handleOnClickReport={handleOnClickReport} loading={loading} title={title} />
					)}

					<div className={!loading && "cp"} onClick={(e) => handleClickOnDetails(e, title)}>
						{loading ? (
							<div style={{ width: "29px", height: "29px", borderRadius: "5px" }} className="skeleton"></div>
						) : (
							<div style={{ width: "29px", height: "29px", borderRadius: "5px" }}>
								<ExpandPopupSVG style={{ cursor: "pointer" }} fill="#4879F5" />
							</div>
						)}
					</div>
					{minimizable && (
						<div
							className="p-l-9 p-r-5 p-t-5 p-b-5 m-l-15"
							style={{ cursor: "pointer" }}
							onClick={() => setShowDetails(!showDetails)}
						>
							{showDetails ? <MinimizeSVG /> : <PopupPlusSVG />}
						</div>
					)}
				</div>
			</div>
			{showDetails && <div className={classNames("df aic", styles.cardContent)}>{children}</div>}
		</div>
	);
};

export default SelectableCardCoTransfer;
