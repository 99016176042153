import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import CardItemTooltip from "../../shared/CardItemTooltip";
import { InsuranceListItem } from "./InsuranceCard";
import HospitalizationsProgressBarSkeleton from "../hospital/hospitalizationsList/HospitalizationsProgressBarSkeleton";

const InsuranceCardSkeleton = () => {
	const fakeData = [1, 2];

	return fakeData.map((x) => {
		return (
			<InsuranceListItem key={x}>
				<ListItemIcon>
					<Checkbox
						className="skeleton"
						size="small"
						disabled
						style={{ borderRadius: 5, marginLeft: "-10px", padding: "0" }}
					/>
				</ListItemIcon>
				<CardItemTooltip>
					<div className="skeleton" style={{ width: "30%", height: "12px", marginLeft: "8px" }}></div>
				</CardItemTooltip>
				<CardItemTooltip item={x}>
					<ListItemText
						primaryTypographyProps={{
							variant: "subtitle3",
							noWrap: true,
							sx: {
								minWidth: "150px",
								maxWidth: "170px",
								width: "170px",
							},
						}}
					/>
				</CardItemTooltip>

				<HospitalizationsProgressBarSkeleton />
			</InsuranceListItem>
		);
	});
};

export default InsuranceCardSkeleton;
