import * as React from "react";
import {
	Box,
	Grid,
	IconButton,
	Popover,
	Stack,
	Switch,
	Typography,
} from "@mui/material";
import InfoSVG from "../../../../assets/svgs/info.svg";
import styles from "./CensusAverageBox.module.scss";
import CardTooltipContent from "../../card/CardTooltipContent";
import HtmlTooltip from "../../../shared/HtmlTooltip";
import { toDisplayTime } from "../../../../utilis/date-formats";
import classNames from "classnames";
import { ACCOUNT_PERCENTAGE_BY } from "../../../../types/pages.type";
import { getPercentageByType } from "../../../../utilis/common";
import { useDispatch, useSelector } from "react-redux";
import { setCustomPercentageLabel, setCustomePercentages, setPercentageBy } from "../../../../store/reducers/common.slice";
import { percentageByEnum } from "../../../../data/common.data";
import AddIcon from '@mui/icons-material/Add';

import {
	usePopupState,
	bindTrigger,
	bindPopover,
} from 'material-ui-popup-state/hooks'
import CustomPercentageInput from "./CustomPercentageInput";
import { getPercentageAgainstAll } from "../../../../services/filter.service";


const CensusAverageBox = ({
	loading,
	censusAverage,
	dbData,
	handlePercentage,
	selected = false,
	isOnlyHospitalDashboard = false,
	percentageAgainst
}) => {
	const dispatch = useDispatch();
	const {
		bedCapacity = null,
		censusAsOfNow = null,
		censusEndDate = null,
	} = dbData;
	const { percentageBy, customPercentageLabel, customePercentages = [] } = useSelector((state) => state.common);

	React.useEffect(() => {
		if (isOnlyHospitalDashboard) {
			dispatch(setPercentageBy(percentageByEnum.bedCapacity));
		}
	}, [isOnlyHospitalDashboard, dispatch]);

	const handleParentClick = (event) => {
		event.preventDefault();
		if (event.target === event.currentTarget && handlePercentage) {
			handlePercentage && handlePercentage();
		}
	};

	const handleChangePercentage = React.useCallback((e, type, isCustom = false) => {
		if (e.target.checked) {
			isCustom && dispatch(setCustomPercentageLabel(type))
			dispatch(setPercentageBy(type));
		} else {
			isCustom && dispatch(setCustomPercentageLabel(type))
			dispatch(setPercentageBy(type));
		}
	}, [dispatch]);

	const popupState = usePopupState({
		variant: 'popper',
		popupId: 'demoPopper',
	})

	const getAllPercentageAgainst = () => {
		getPercentageAgainstAll().then(async (res) => {
			if (res.length > 0) {
				let resUpdated = res.map((item, index) => { return { ...item, index } })
				dispatch(setCustomePercentages(resUpdated))
			} else {
				dispatch(setCustomePercentages([]))
			}
		})
	}
	React.useMemo(() => {
		if (!popupState?.isOpen) {
			getAllPercentageAgainst()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [popupState?.isOpen])

	return (
		<>
			<Box
				type="button"
				onClick={handleParentClick}
				sx={{
					border: "1px dashed #D9D9D9",
					borderRadius: 1,
					height: "60px",
					padding: 1,
					display: "flex",
					justifyItems: "center",
					alignItems: "center",
					...(handlePercentage && {
						":hover": {
							border: "1px solid #4879F5",
							cursor: "pointer",
						},
					}),
					...(selected && {
						border: "1px solid #4879F5",
					}),
				}}
			>
				<Typography
					variant="body2"
					sx={{ mt: 0, fontSize: { xs: 10, md: 12, lg: 13, xl: 15 } }}
					noWrap={false}
				>
					{getPercentageByType("y") === ACCOUNT_PERCENTAGE_BY.BED
						? "Bed capacity: "
						: `${percentageBy} of time frame selected: `}
				</Typography>

				<div
					className={classNames(
						"ffint",
						"fw700",
						"fs13",
						"df",
						"acc",
						"mla",
						"p-l-55",
						"p-r-55",
						loading && "skeleton",
						styles.infoContainer,
						!loading && "cp",
						loading && styles.skeleton
					)}
					{...bindTrigger(popupState)}
				>
					{!loading && (
						<>
							<Typography variant="h6">{percentageAgainst}</Typography>
							{getPercentageByType("y") === ACCOUNT_PERCENTAGE_BY.CENSUS && (
								<span className={styles.infoWrapper}>
									<HtmlTooltip
										content={
											<CardTooltipContent content="The number here displays the average number of residents in the facility during the user-selected time frame." />
										}
									>
										<IconButton disableFocusRipple={true} disableRipple={true}>
											<InfoSVG fill="#4879F5" />
										</IconButton>
									</HtmlTooltip>
								</span>
							)}
						</>
					)}
				</div>
			</Box>
			<Popover
				{...bindPopover(popupState)}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				PaperProps={{
					elevation: 3,
					variant: "outlined",
					rounded: true,
					sx: {
						pointerEvents: "auto",
						overflow: "visible",
						filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
						mt: 0.5,
						"&::before": {
							content: '""',
							display: "block",
							position: "absolute",
							top: 0,
							left: "56%",
							width: 20,
							height: 20,
							bgcolor: "background.paper",
							transform: "translateY(-50%) rotate(45deg)",
							zIndex: 0,
						},
					},
				}}
			>
				<Grid
					direction={"row"}
					container
					spacing={1}
					sx={{
						p: 2,
						width: "450px",
					}}
				>
					<Grid item xs={12} container>
						<Switch
							checked={percentageBy === percentageByEnum.bedCapacity}
							onChange={(e) => handleChangePercentage(e, percentageByEnum.bedCapacity)}
							inputProps={{ "aria-label": "updatedPercentage" }}
							size="small"
						/>
						<PopoverItem title={"Bed Capacity"} value={bedCapacity} />
					</Grid>
					{!isOnlyHospitalDashboard && (
						<>
							<Grid item xs={12} container>
								<Switch
									checked={percentageBy === percentageByEnum.censusAsOfNow}
									onChange={(e) => handleChangePercentage(e, percentageByEnum.censusAsOfNow)}
									inputProps={{ "aria-label": "updatedPercentage" }}
									size="small"
								/>
								<PopoverItem
									title={`census as of ${toDisplayTime(censusEndDate)}`}
									value={censusAsOfNow}
								/>
							</Grid>
							<Grid item xs={12} container>
								<Switch
									checked={percentageBy === percentageByEnum.censusAverage}
									onChange={(e) => handleChangePercentage(e, percentageByEnum.censusAverage)}
									inputProps={{ "aria-label": "updatedPercentageCensusAverage" }}
									size="small"
								/>
								<PopoverItem
									title={"Average census of time frame selected"}
									value={censusAverage}
								/>
							</Grid>
						</>
					)}

					<Grid item xs={12} container>
						{customePercentages && customePercentages.length > 0 && customePercentages.map((item, index) => (
							<CustomPercentageInput
								percentageBy={percentageBy}
								customPercentageLabel={customPercentageLabel}
								handleChangePercentage={handleChangePercentage}
								item={item}
								index={index}
								customePercentages={customePercentages}
							/>)
						)}
						<IconButton
							onClick={() =>
								dispatch(setCustomePercentages([
									...customePercentages, {
										index: customePercentages.length,
										customPercentage: null,
										label: ""
									}
								]))
							}
							aria-label="add"
							size="small"
							sx={{ ml: 1, mt: 0.5 }}
							color="primary"
						>
							<AddIcon fontSize="inherit" />
						</IconButton>
						{/* {(customPercentage || isAddCustomNumber) && (
							<Stack direction={"row"} spacing={2}>
								{customPercentage && (
									<Switch
										checked={percentageBy === customPercentageLabel}
										onChange={(e) => {
											handleChangePercentage(e, customPercentageLabel)
										}}
										inputProps={{ "aria-label": "updatedCustomNumber" }}
										size="small"
									/>
								)}
								<OutlinedInput
									size="small"
									type={"number"}
									value={customPercentageBy}
									onChange={(e) => setCustomPercentageBy(e.target.value)}
									sx={{ width: "80px" }}
								/>
								<OutlinedInput
									size="small"
									type={"text"}
									value={customPercentageLabelData}
									inputProps={{ maxLength: 20 }}
									onChange={(e) => setCustomPercentageLabelData(e.target.value)}
								/>
								<Button
									disabled={(!customPercentageBy || !customPercentageLabelData)}
									size="small"
									variant="contained"
									onClick={handleCustomFilter}
									sx={{ lineHeight: "11px" }}
								>
									Custom Number percentage
								</Button>
							</Stack>
						)} */}
						{/* {!customPercentage && !isAddCustomNumber && ( */}

						{/* )} */}
						{/* {isAddCustomNumber && !customPercentage && (
							<Button
								size="small"
								sx={{ mt: 1 }}
								variant="outlined"
								onClick={() => setIsAddCustomNumber(!isAddCustomNumber)}>
								Cancel
							</Button>
						)} */}
					</Grid>
				</Grid>
			</Popover>
		</>
	);
};

export default CensusAverageBox;

const PopoverItem = ({ title, value }) => {
	return (
		<Stack direction={"row"} justifyContent={"end"} alignContent={"center"}>
			<Stack item sx={{ marginRight: "auto", textAlign: "center" }}>
				<Typography variant="subtitle1">{title} :</Typography>
			</Stack>
			<Stack item>
				<Typography textAlign={"end"} variant="subtitle1">
					{value}
				</Typography>
			</Stack>
		</Stack>
	);
};
