import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Stack } from '@mui/material';
import { itemPercentage } from '../../utilis/common';


const CardItemTooltipADTContainer = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: "manrope",
        fontSize: "12px",
        border: '1px solid #dadde9',
        background: "#FFFFFF",
        boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.13)",
        borderRadius: "8px",
        padding: "5px",
        top:"-20px"
    },
}));

export default function CardItemTooltipADT({ children, item }) {
    if (item?.isTooltip) {
        return (
            <CardItemTooltipADTContainer
                title={
                    <Stack direction={"column"} sx={{ padding: 0.2 }} spacing={1}>
                        <Stack item>Original total : {item?.originalTotal}</Stack>
                        <Stack item>Filtered total : {item?.total || item?.value || 0}</Stack>
                        <Stack item>Percentage : {itemPercentage(item?.total || item?.value, item?.originalTotal, "percentage")}</Stack>
                    </Stack>
                }
            >
                {children}
            </CardItemTooltipADTContainer>
        );
    } else {
        return children
    }
}
