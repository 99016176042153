import { useCallback, useMemo } from "react";
import Button from "../../../../ui/button/Button";
import styles from "./AddPatient.module.scss";
import { isOnlyHospitalTabAccess } from "../../../../../utilis/common";

const SelectType = ({
    allFields,
    setAllFieldsValue,
    gotToDetails,
    disabledTypes,
    selectedItem
}) => {

    const isOnlyHospitalDashboard = useMemo(() => {
        return isOnlyHospitalTabAccess();
    }, []);

    const types = useMemo(() => {
        if (!isOnlyHospitalDashboard) {
            return [
                { label: "Admission/Readmission", value: "admissionOrReadmission" },
                { label: "Hospital Return", value: "return" },
                { label: "Transfer", value: "transfer" },
            ]
        } else {
            return [
                { label: "Hospital Return", value: "return" },
                { label: "Transfer", value: "transfer" },
            ]
        }
    }, [isOnlyHospitalDashboard]);

    const transferTypes = useMemo(() => {
        if (!isOnlyHospitalDashboard) {
            return [
                { label: "Hospital Transfer", value: "hospitalTransfer" },
                // { label: "Unplanned Hospital Transfer", value: "unplannedHospitalTransfer" },
                // { label: "Planned Hospital Transfer", value: "plannedHospitalTransfer" },
                { label: "Safe Discharge", value: "safeDischarge" },
                { label: "SNF", value: "SNF" },
                { label: "AMA", value: "AMA" },
                { label: "Deceased", value: "deceased" },
            ]
        } else {
            return [
                { label: "Hospital Transfer", value: "hospitalTransfer" },
                // { label: "Unplanned Hospital Transfer", value: "unplannedHospitalTransfer" },
                // { label: "Planned Hospital Transfer", value: "plannedHospitalTransfer" },
            ]
        }
    }, [isOnlyHospitalDashboard]);

    const handleOnClickButton = useCallback((type, value) => {
        setAllFieldsValue(type, value)
    }, [setAllFieldsValue]);

    return (
        <div className={`p-t-20 p-r-20 p-b-20 p-l-20 df ffc ${styles.stepOne}`}>
            {types.map((thisType) => (
                <div
                    key={thisType.value}
                    style={{ userSelect: "none" }}
                    className={`ffmr fs16 tac ${styles.selectionButton} ${thisType.value === "admissionOrReadmission" && (allFields.type === "admission" || allFields.type === "readmission") ? styles.selected : ""} ${allFields.type === thisType.value ? styles.selected : ""
                        } ${disabledTypes?.includes(thisType.value) ? styles.disabled : ""}
          `}
                    onClick={() => {
                        if (disabledTypes?.includes(thisType.value)) return;
                        return handleOnClickButton("type", thisType.value);
                    }}
                    onDoubleClick={() => {
                        if (disabledTypes?.includes(thisType.value)) return;
                        if (thisType.value !== "transfer") {
                            gotToDetails();
                        }
                    }}
                >
                    {thisType.label}
                </div>
            ))}
            {allFields.type === "transfer" ? (
                <div className={` inputWrpr`}>
                    <label className={`ffmr fs12`}>Transfer Type</label>
                    <div
                        className={`df ffc ffml fs14 ${styles.toggleWrpr} ${styles.vertical}`}
                    >
                        {transferTypes.map((type) => (
                            <div
                                key={type.value}
                                style={{ userSelect: "none" }}
                                className={`${styles.sec} ${(allFields.transferType === type.value || (type.value === "hospitalTransfer" && (allFields.transferType === "unplannedHospitalTransfer" || allFields.transferType === "plannedHospitalTransfer"))) ? styles.selected : ""
                                    }`}
                                onClick={() => handleOnClickButton("transferType", type.value)
                                    //setAllFieldsValue("transferType", type.value)
                                }
                                onDoubleClick={() => {
                                    gotToDetails();
                                }}
                            >
                                {type.label}
                            </div>
                        ))}
                    </div>
                </div>
            ) : undefined}
            <div className={`df aic ${styles.popupFtr}`} style={{ paddingBottom: 0, bottom: '-20px' }}>
                <div className={`mla`} style={{ marginBottom: '20px', marginTop: '20px' }}>
                    <Button
                        text={`Next`}
                        buttonStyle="theme"
                        size="medium"
                        action={() => {
                            gotToDetails();
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default SelectType;
