/* eslint-disable array-callback-return */
import _ from "lodash";
import { store } from "..";
import {
	setFacilityPercentage as setFacilityPercentageComparison,
	setFilterTotal as setFilterTotalComparison,
	setIsCensusTotalLocked as setIsCensusTotalLockedComparison,
	setLockedByFacility as setLockedByFacilityComparison,
	setLockedTotalBy as setLockedTotalByComparison,
	setLockTotal as setLockTotalComparison,
} from "../store/reducers/comparisonReducers/overallComparison.slice";
import {
	setFacilityPercentage,
	setFilterTotal,
	setIsCensusTotalLocked,
	setLockedByFacility,
	setLockedTotalBy,
	setLockTotal,
} from "../store/reducers/overall.slice";
import { TOTAL_TYPE } from "../types/common.type";
import { OVERALL_CARDS_TYPE } from "../types/overall.type";
import { OVERALL_PAGE_SUB_TYPE } from "../types/pages.type";
import { getDynamicPercentageBy, itemPercentage, updateListTotalValue } from "./common";

export async function getTotalNumber(data) {
	if (data.length > 0) {
		const transferTypeTotal = _.countBy(data, "isOutgoing");
		let totalOutgoing = 0;
		let totalIncoming = 0;
		totalOutgoing = transferTypeTotal?.true || 0;
		totalIncoming = transferTypeTotal?.false || 0;
		const n = totalIncoming - totalOutgoing;
		return n;
	}
	return 0;
}

export async function getListData(dataGroupBy, type, transferType, totalPatient, totalFilter) {
	let listGroup = [];
	const { originalData = [], totalType = null } = totalFilter;

	if (dataGroupBy) {
		for (const [key, value] of Object.entries(dataGroupBy)) {
			const valueArr = value[0];
			let totalCountValue = value.length;
			let isTotalPercentage = false;
			if (!transferType || transferType === OVERALL_PAGE_SUB_TYPE.TOTAL) {
				totalCountValue = await getTotalNumber(value);
			}
			let original = originalData[key] ? originalData[key]?.length : 0;
			if (!transferType || transferType === OVERALL_PAGE_SUB_TYPE.TOTAL) {
				original = await getTotalNumber(originalData[key] ? originalData[key] : []);
				isTotalPercentage = true;
			}

			if (key && valueArr && valueArr[type]) {
				let object = Object();
				object._id = key;
				object.id = key;
				object.isTotalPercentage = isTotalPercentage;
				object.label = valueArr[type].label;
				object.name = valueArr[type].label;
				object.total = totalCountValue;
				object.value = value.length;
				object.originalTotal = original;
				object.isTooltip = totalType && totalType === TOTAL_TYPE.MAIN ? false : true;
				object.percentage = itemPercentage(value.length, totalPatient, "number");
				listGroup.push(object);
			}
		}
		if (listGroup.length > 0) {
			if (!transferType || transferType === OVERALL_PAGE_SUB_TYPE.TOTAL) {
				listGroup = _.orderBy(listGroup, "total", "desc").map((item) => ({
					...item,
					total: new Intl.NumberFormat("en-US", { signDisplay: "exceptZero" }).format(item.total),
				}));
			} else {
				listGroup = _.orderBy(listGroup, "total", "desc");
			}
		}
	}
	return listGroup;
}

export async function plannedFilter(patientData, oldFilter, type = null) {
	patientData = _.filter(patientData, ({ floorId, doctorId, insuranceId }) => {
		let filterArr = [];

		if (oldFilter.insuranceData.length > 0 && type !== "insuranceData") {
			filterArr.push(_.includes(oldFilter.insuranceData, insuranceId));
		}
		if (oldFilter.doctorData.length > 0 && type !== "doctorData") {
			filterArr.push(_.includes(oldFilter.doctorData, doctorId));
		}
		if (oldFilter.floorsData.length > 0 && type !== "floorsData") {
			filterArr.push(_.includes(oldFilter.floorsData, floorId));
		}
		return _.every(filterArr);
	});
	return patientData;
}

// * added comparison
export async function updateFacilityPercentageTotal(data, forComparison) {
	let facilityWiseTotal = _(data)
		.groupBy("facilityId")
		.sortBy((group) => data.indexOf(group[0]))
		.map((product) => {
			return {
				id: product[0]?.facilityId,
				total: product.length || 0,
			};
		})
		.value();
	forComparison
		? store.dispatch(setFacilityPercentageComparison(facilityWiseTotal))
		: store.dispatch(setFacilityPercentage(facilityWiseTotal));
}

// * added comparison
export async function updateFilterListData(cardFilter, patientList, priorityData = [], transferType, forComparison) {
	const { list, ninetyDaysData } = patientList;
	const {
		dbData,
		lockedTotal,
		mainNumPercentage,
		lockedTotalBy,
	} = forComparison ? store.getState().overallComparison : store.getState().overall;

	let censusAverage = dbData?.censusAverage;

	if (!forComparison) {
		censusAverage = await getDynamicPercentageBy(dbData);
	}

	let mainNumPercentageSelected = mainNumPercentage;
	let objectCustom = Object();
	let newSavedFilters = [];
	let patientFilterData = list;
	let lockedTotalModified = lockedTotal;
	let isComparingAgainstAvgCensus = true;

	if (transferType === OVERALL_PAGE_SUB_TYPE.TOTAL_INCOMING) {
		patientFilterData = _.filter(patientFilterData, { isOutgoing: false });
	}
	if (transferType === OVERALL_PAGE_SUB_TYPE.TOTAL_OUTGOING) {
		patientFilterData = _.filter(patientFilterData, {
			isOutgoing: true,
		});
	}
	let totalFilterData = {
		originalData: patientFilterData,
		totalType: TOTAL_TYPE.FILTER,
		transferType,
		totalForPercentage: censusAverage,
	};
	if (transferType) {
		totalFilterData.totalForPercentage = patientFilterData?.length;
		isComparingAgainstAvgCensus = false;
	}
	// if (mainNumPercentageSelected) {
	//     totalFilterData.totalForPercentage = mainNumPercentageSelected
	// }
	if (lockedTotalBy) {
		if (
			lockedTotalBy === OVERALL_PAGE_SUB_TYPE.TOTAL_INCOMING ||
			lockedTotalBy === OVERALL_PAGE_SUB_TYPE.TOTAL_OUTGOING ||
			lockedTotalBy === OVERALL_PAGE_SUB_TYPE.TOTAL ||
			lockedTotalBy === "census"
		) {
			if (lockedTotalModified && transferType !== lockedTotalBy && lockedTotalBy !== "census") {
				forComparison ? store.dispatch(setLockedTotalByComparison(null)) : store.dispatch(setLockedTotalBy(null));
				forComparison ? store.dispatch(setLockTotalComparison(null)) : store.dispatch(setLockTotal(null));
				lockedTotalModified = null;
			}
		} else {
			const lockedFilterRemoved = _.find(priorityData, { type: lockedTotalBy });
			if (!lockedFilterRemoved) {
				forComparison ? store.dispatch(setLockedTotalByComparison(null)) : store.dispatch(setLockedTotalBy(null));
				forComparison ? store.dispatch(setLockTotalComparison(null)) : store.dispatch(setLockTotal(null));
				lockedTotalModified = null;
			}
		}
	}
	forComparison ? store.dispatch(setFacilityPercentageComparison([])) : store.dispatch(setFacilityPercentage([]));
	if (!lockedTotalModified) {
		forComparison
			? store.dispatch(setIsCensusTotalLockedComparison(false))
			: store.dispatch(setIsCensusTotalLocked(false));
		forComparison ? store.dispatch(setLockedTotalByComparison(null)) : store.dispatch(setLockedTotalBy(null));
	}

	if (lockedTotalModified) {
		totalFilterData.totalForPercentage = lockedTotalModified;
	}

	if (lockedTotalModified && priorityData.length === 1 && !transferType) {
		totalFilterData.totalForPercentage = lockedTotalModified;
	}

	if (priorityData?.length > 0) {
		isComparingAgainstAvgCensus = false;
	}

	if (
		(priorityData?.length > 0 && (!lockedTotalBy || !lockedTotal)) ||
		(priorityData?.length > 0 &&
			lockedTotalBy !== "census" &&
			lockedTotalBy !== OVERALL_PAGE_SUB_TYPE.TOTAL_INCOMING &&
			lockedTotalBy !== OVERALL_PAGE_SUB_TYPE.TOTAL_OUTGOING &&
			lockedTotalBy !== OVERALL_PAGE_SUB_TYPE.TOTAL)
	) {
		totalFilterData.totalForPercentage = null;
	}

	if (priorityData?.length > 0) {
		let i = 0;
		for await (const ele of priorityData) {
			i++;
			if (priorityData?.length === i && lockedTotalModified && !lockedTotalBy) {
				forComparison
					? store.dispatch(setLockedTotalByComparison(ele.type))
					: store.dispatch(setLockedTotalBy(ele.type));
			}
			if (ele.type === OVERALL_CARDS_TYPE.DOCTOR_DATA) {
				objectCustom.doctorData = await doctorData(patientFilterData, {
					...totalFilterData,
					totalType: TOTAL_TYPE.MAIN,
					totalForPercentage:
						mainNumPercentageSelected && i === 1 ? mainNumPercentageSelected : totalFilterData.totalForPercentage,
				});

				patientFilterData = _.filter(patientFilterData, ({ doctorId }) =>
					_.every([_.includes(cardFilter.doctorData, doctorId)])
				);
			}

			if (ele.type === OVERALL_CARDS_TYPE.FLOORS_DATA) {
				objectCustom.floorsData = await floorsData(patientFilterData, {
					...totalFilterData,
					totalType: TOTAL_TYPE.MAIN,
					totalForPercentage:
						mainNumPercentageSelected && i === 1 ? mainNumPercentageSelected : totalFilterData.totalForPercentage,
				});

				patientFilterData = _.filter(patientFilterData, ({ floorId }) =>
					_.every([_.includes(cardFilter.floorsData, floorId)])
				);
			}

			if (ele.type === OVERALL_CARDS_TYPE.INSURANCE_DATA) {
				objectCustom.insuranceData = await insuranceData(patientFilterData, {
					...totalFilterData,
					totalType: TOTAL_TYPE.MAIN,
					totalForPercentage:
						mainNumPercentageSelected && i === 1 ? mainNumPercentageSelected : totalFilterData.totalForPercentage,
				});

				patientFilterData = _.filter(patientFilterData, ({ insuranceId }) =>
					_.every([_.includes(cardFilter.insuranceData, insuranceId)])
				);
			}

			if (ele.type === OVERALL_CARDS_TYPE.NINETY_DAYS_DATA) {
				objectCustom.ninetyDaysData = await ninetyDaysDataList(ninetyDaysData, patientFilterData, {
					...totalFilterData,
					totalType: TOTAL_TYPE.MAIN,
					totalForPercentage:
						mainNumPercentageSelected && i === 1 ? mainNumPercentageSelected : totalFilterData.totalForPercentage,
				});

				patientFilterData = await ninetyDaysDataFilter(cardFilter.ninetyDaysData, patientFilterData, ninetyDaysData);
			}
			newSavedFilters = [...newSavedFilters, ele.type];
		}

		if (transferType && transferType !== "total") {
			forComparison
				? store.dispatch(
					setFilterTotalComparison(
						lockedTotalModified
							? lockedTotalModified
							: mainNumPercentageSelected
								? mainNumPercentageSelected
								: patientFilterData.length
					)
				)
				: store.dispatch(
					setFilterTotal(
						lockedTotalModified
							? lockedTotalModified
							: mainNumPercentageSelected
								? mainNumPercentageSelected
								: patientFilterData.length
					)
				);
		}

		const totalPercentageCount = lockedTotalModified ? lockedTotalModified : patientFilterData.length; //transferType ? patientFilterData?.length : censusAverage

		if (!_.includes(newSavedFilters, OVERALL_CARDS_TYPE.INSURANCE_DATA)) {
			objectCustom.insuranceData = await insuranceData(patientFilterData, {
				...totalFilterData,
				totalForPercentage: totalPercentageCount,
			});
		}
		if (!_.includes(newSavedFilters, OVERALL_CARDS_TYPE.FLOORS_DATA)) {
			objectCustom.floorsData = await floorsData(patientFilterData, {
				...totalFilterData,
				totalForPercentage: totalPercentageCount,
			});
		}
		if (!_.includes(newSavedFilters, OVERALL_CARDS_TYPE.DOCTOR_DATA)) {
			objectCustom.doctorData = await doctorData(patientFilterData, {
				...totalFilterData,
				totalForPercentage: totalPercentageCount,
			});
		}
		if (!_.includes(newSavedFilters, OVERALL_CARDS_TYPE.NINETY_DAYS_DATA)) {
			objectCustom.ninetyDaysData = await ninetyDaysDataList(ninetyDaysData, patientFilterData, {
				...totalFilterData,
				totalForPercentage: totalPercentageCount,
			});
		}
		if (!lockedTotalModified) {
			forComparison
				? store.dispatch(setLockedByFacilityComparison(patientFilterData))
				: store.dispatch(setLockedByFacility(patientFilterData));
		}
	} else {
		totalFilterData.totalType = TOTAL_TYPE.MAIN;
		const totalCount = mainNumPercentageSelected
			? mainNumPercentageSelected
			: transferType && transferType !== "total"
				? patientFilterData.length
				: null;

		let totalCountPer = lockedTotalModified ? lockedTotalModified : transferType ? totalCount : censusAverage;
		forComparison
			? store.dispatch(setFilterTotalComparison(totalCountPer))
			: store.dispatch(setFilterTotal(totalCountPer));
		updateFacilityPercentageTotal(patientFilterData, forComparison);
		if (lockedTotalModified && !lockedTotalBy) {
			if (transferType) {
				forComparison
					? store.dispatch(setLockedTotalByComparison(transferType))
					: store.dispatch(setLockedTotalBy(transferType));
			} else {
				forComparison
					? store.dispatch(setLockedTotalByComparison("census"))
					: store.dispatch(setLockedTotalBy("census"));
			}
		}
		if (transferType !== "total" && lockedTotalModified && mainNumPercentage) {
			forComparison
				? store.dispatch(setIsCensusTotalLockedComparison(true))
				: store.dispatch(setIsCensusTotalLocked(true));
		}
		if (!lockedTotalModified) {
			forComparison
				? store.dispatch(setLockedByFacilityComparison(patientFilterData))
				: store.dispatch(setLockedByFacility(patientFilterData));
		}
		totalFilterData.totalForPercentage = totalCountPer;
		[objectCustom.insuranceData, objectCustom.floorsData, objectCustom.doctorData, objectCustom.ninetyDaysData] =
			await Promise.all([
				insuranceData(patientFilterData, totalFilterData),
				floorsData(patientFilterData, totalFilterData),
				doctorData(patientFilterData, totalFilterData),
				ninetyDaysDataList(ninetyDaysData, patientFilterData, totalFilterData),
			]);
	}
	objectCustom.isComparingAgainstAvgCensus = isComparingAgainstAvgCensus;

	return objectCustom;
}

async function ninetyDaysDataFilter(cardFilter, patientData, ninetyDaysData) {
	let ninetyDaysDataIds = [];
	let ninetyDaysDataFilter = _.filter(ninetyDaysData, ({ _id }) => _.every([_.includes(cardFilter, _id)]));
	if (ninetyDaysDataFilter && ninetyDaysDataFilter.length > 0) {
		ninetyDaysDataFilter.map((item) => (ninetyDaysDataIds = [...ninetyDaysDataIds, ...item.ids]));
	}
	patientData = _.filter(patientData, ({ _id }) => _.every([_.includes(ninetyDaysDataIds, _id)]));
	return patientData;
}

async function ninetyDaysDataList(data, patients, totalFilterData) {
	const patientIds = patients && patients.length > 0 ? patients.map((item) => item.id) : [];
	let percentageTotal = totalFilterData?.totalForPercentage ? totalFilterData.totalForPercentage : patients?.length;
	const res = await updateListTotalValue(data, patientIds, "value", percentageTotal, totalFilterData);
	return res;
}

async function insuranceData(data, totalFilter) {
	const { transferType = null } = totalFilter;
	//insuranceData Cal
	let insuranceDBDataGroup = [];
	if (data && data.length > 0) {
		const insuranceDBData = _.groupBy(data, "insuranceId");
		const originalData = _.groupBy(totalFilter.originalData, "insuranceId");
		let percentageTotal = totalFilter?.totalForPercentage ? totalFilter.totalForPercentage : data?.length;

		if (insuranceDBData) {
			insuranceDBDataGroup = await getListData(insuranceDBData, "insurance", transferType, percentageTotal, {
				...totalFilter,
				originalData,
			});
		}
	}

	return insuranceDBDataGroup;
}

async function doctorData(data, totalFilter) {
	//Doctor list update Cal
	const { transferType = null } = totalFilter;

	let doctorDBDataGroup = [];
	if (data && data.length > 0) {
		const doctorDBData = _.groupBy(data, "doctorId");
		const originalData = _.groupBy(totalFilter.originalData, "doctorId");
		let percentageTotal = totalFilter?.totalForPercentage ? totalFilter.totalForPercentage : data?.length;

		if (doctorDBData) {
			doctorDBDataGroup = await getListData(doctorDBData, "doctor", transferType, percentageTotal, {
				...totalFilter,
				originalData,
			});
		}
	}
	return doctorDBDataGroup;
}

async function floorsData(data, totalFilter) {
	//FloorsData Cal
	const { transferType = null } = totalFilter;

	let unitDBDataGroup = [];
	if (data && data.length > 0) {
		const floorDBData = _.groupBy(data, "floorId");
		const originalData = _.groupBy(totalFilter.originalData, "floorId");
		let percentageTotal = totalFilter?.totalForPercentage ? totalFilter.totalForPercentage : data?.length;

		if (floorDBData) {
			unitDBDataGroup = await getListData(floorDBData, "unit", transferType, percentageTotal, {
				...totalFilter,
				originalData,
			});
		}
	}
	return unitDBDataGroup;
}
