import styles from "./DxCardList.module.scss";
import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import classNames from "classnames";

const DxCardListSkeleton = () => {
	const fakeData = [1, 2, 3, 4, 5];
	return fakeData.map((x) => {
		return (
			<ListItem sx={{ padding: "5px" }} key={x} disablePadding className={classNames(styles.listItem)}>
				<ListItemButton
					sx={{
						borderRadius: "8px",
						height: "48px",
						cursor: "default",
					}}
					role={undefined}
					dense
					className={classNames("skeleton")}
				></ListItemButton>
			</ListItem>
		);
	});
};

export default DxCardListSkeleton;
