// ----------------------------------------------------------------------

export default function FormLabel() {
    return {
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    marginBottom: "6px",
                    letterSpacing: "0.3px",
                    fontSize: "12px",
                    fontFamily: "mont reg"
                },
            },
        },
    };
}
