// types

import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
	deceasedCards: [],
	communityTransferCards: [],
	hospitalCards: [],
	admissionCards: [],
	overallCards: [],
};

// ==============================|| SLICE - customCard ||============================== //

const customCard = createSlice({
	name: "customCard",
	initialState,
	reducers: {
		setDeceasedCards(state, action) {
			state.deceasedCards = action.payload;
		},
		setOverallCards(state, action) {
			state.overallCards = action.payload;
		},
		setAdmissionCards(state, action) {
			state.admissionCards = action.payload;
		},
		setCommunityTransferCards(state, action) {
			state.communityTransferCards = action.payload;
		},
		setHospitalCards(state, action) {
			state.hospitalCards = action.payload;
		},
		
	},
});

export default customCard.reducer;

export const {
	setOverallCards,
	setAdmissionCards,
	setHospitalCards,
	setDeceasedCards,
	setCommunityTransferCards
} = customCard.actions;
