import {
    Typography,
    Button,
    Stack,
} from "@mui/material";
import { useCallback, useState } from "react";
import Popover from "@mui/material/Popover";
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from "react-redux";
import { setCardFilter, setDefaultADTData, setSelectedADTTableData } from "../../../store/reducers/admission.slice";
import update from "immutability-helper";
import _ from "lodash";

const RemoveCardButton = ({ item, cardFilter, filters, reloadAdmissionFilter, defaultADTData }) => {
    const [anchorDeleteEl, setAnchorDeleteEl] = useState(null);
    const [isDeleteFilter, setIsDeleteFilter] = useState(null);
    const dispatch = useDispatch();
    const handleDeleteFilter = useCallback((event, value) => {
        event.stopPropagation();
        setAnchorDeleteEl(event.currentTarget);
        setIsDeleteFilter(value);
    }, []);

    const handleCloseConfirmation = useCallback(() => {
        setAnchorDeleteEl(null);
        setIsDeleteFilter(null);
    }, []);

    const handleDeleteDBFilter = useCallback(async () => {
        if (cardFilter.adtData && cardFilter.adtData.length > 0) {
            const { cardId, childId } = item;
            const latestAdtData = cardFilter.adtData.map((ele) => {
                const childrenData = ele.children.map((item) => (item.id === childId && item.parentId === cardId) ? { ...item, isSelected: false } : item);
                return Object.assign({}, ele, { children: childrenData });
            });
            const isFilter = _.find(cardFilter.adtAdmitPatientIds, { id: childId, cardId: cardId });
            const latestAdtAdmitPatientIds = update(filters, {
                $splice: [[filters.indexOf(item), 1]],
            });

            let defaultADTDataLatest = defaultADTData;
            const idxDefault = defaultADTDataLatest.findIndex(p => (p.childId === childId && p.cardId === cardId));
            if (idxDefault !== -1) {
                defaultADTDataLatest = update(defaultADTData, {
                    $splice: [[idxDefault, 1]],
                });
                await dispatch(setDefaultADTData(defaultADTDataLatest));
            }

            if (isFilter) {
                let priorityDataFilter = cardFilter.mainPriorityData;
                const idx = priorityDataFilter.findIndex(p => (p.childId === childId && p.cardId === cardId));
                if (idx !== -1) {
                    priorityDataFilter = update(priorityDataFilter, {
                        $splice: [[idx, 1]],
                    });
                }
                const latestAdtFilterList = update(cardFilter.adtAdmitPatientIds, {
                    $splice: [[cardFilter.adtAdmitPatientIds.indexOf(isFilter), 1]],
                });
                await dispatch(setCardFilter({ adtData: latestAdtData, adtAdmitPatientIds: latestAdtFilterList, mainPriorityData: priorityDataFilter }));
                reloadAdmissionFilter(priorityDataFilter, defaultADTDataLatest);
            } else {
                await dispatch(setSelectedADTTableData(latestAdtAdmitPatientIds));
                await dispatch(setCardFilter({ adtData: latestAdtData }));
            }
        }
        setAnchorDeleteEl(null);
        setIsDeleteFilter(null);
    }, [cardFilter.adtAdmitPatientIds, cardFilter.adtData, cardFilter.mainPriorityData, defaultADTData, dispatch, filters, item, reloadAdmissionFilter]);

    return (
        <>
            <DeleteIcon
                sx={{ color: "#FF5B62", cursor: "pointer" }}
                onClick={(e) => handleDeleteFilter(e, item)}
            />
            {isDeleteFilter && (
                <Popover
                    anchorEl={anchorDeleteEl}
                    open={!!isDeleteFilter}
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    onClose={handleCloseConfirmation}
                >
                    <Stack display={"flex"} sx={{ padding: 2 }}>
                        <Stack item direction="row">
                            <Typography variant="subtitle2">
                                Are you sure want to delete ?
                            </Typography>
                        </Stack>
                        <Stack item direction="row" spacing={2} sx={{ mt: 1 }}>
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={handleCloseConfirmation}
                            >
                                Cancel
                            </Button>
                            <Button
                                size="small"
                                variant="contained"
                                color="error"
                                onClick={handleDeleteDBFilter}
                            >
                                Delete
                            </Button>
                        </Stack>
                    </Stack>
                </Popover>
            )}
        </>

    );
};

export default RemoveCardButton;
