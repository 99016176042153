import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import RefreshIcon from "@mui/icons-material/Refresh";

export default function CustomTooltipContentWithAction({ text }) {
	const defaultSize = 14;
	const [tooltipSize, setTooltipSize] = useState(defaultSize);

	const increaseSize = () => setTooltipSize(prev => prev + 2);
	const decreaseSize = () => setTooltipSize(prev => (prev > 10 ? prev - 2 : prev)); // Prevent too small
	const resetSize = () => setTooltipSize(defaultSize);
	const handleStopPropagation = (e) => {
		e.preventDefault();
		e.stopPropagation()
	};

	return (
		<Box
			onclick={handleStopPropagation}
			sx={{
				fontSize: `${tooltipSize}px`,
				position: "relative",
				display: "inline-block", // Ensure that it wraps around content properly
				width: "100%", // Makes sure the icons stay within the box
				padding: "5px",
				// border: "1px solid #ccc", // Optional: for better visibility of the box
				borderRadius: "5px", // Optional: rounding the corners
			}}
			key={text?.substring(0, 5) + "tooltip"}
		>
			{/* Text content */}
			<div
				onclick={handleStopPropagation}
				style={{ marginTop: "20px" }}
			>
				{text}
			</div>

			{/* Icon buttons positioned in the top-right corner */}
			<Box
				onclick={handleStopPropagation}
				sx={{
					position: "absolute",
					top: 0,
					right: 0,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<IconButton
					aria-label="Increase"
					onClick={(e) => {
						e.stopPropagation();
						increaseSize();
					}}
					size="small"
				>
					<ZoomInIcon fontSize="small" sx={{ color: "#fff" }} />
				</IconButton>

				<IconButton
					aria-label="Decrease"
					onClick={(e) => {
						e.stopPropagation();
						decreaseSize();
					}}
					size="small"
				>
					<ZoomOutIcon fontSize="small" sx={{ color: "#fff" }} />
				</IconButton>

				<IconButton
					aria-label="Refresh"
					onClick={(e) => {
						e.stopPropagation();
						resetSize();
					}}
					size="small"
				>
					<RefreshIcon fontSize="small" sx={{ color: "#fff" }} />
				</IconButton>
			</Box>
		</Box>
	);
}
