const DataInputArrow = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_876_359)">
        <path
          d="M4.00002 11.0595C3.73852 11.0613 3.48246 10.9849 3.26466 10.8402C3.04686 10.6955 2.87726 10.489 2.7776 10.2472C2.67794 10.0055 2.65277 9.73944 2.70531 9.48328C2.75786 9.22712 2.88573 8.99249 3.07252 8.80949L5.89752 5.99949L3.07252 3.17699C2.84068 2.92818 2.71446 2.5991 2.72046 2.25907C2.72646 1.91905 2.8642 1.59462 3.10468 1.35415C3.34515 1.11368 3.66957 0.97593 4.0096 0.969931C4.34963 0.963932 4.67871 1.09015 4.92752 1.32199L9.42752 5.82199C9.45095 5.84523 9.46955 5.87288 9.48224 5.90334C9.49493 5.93381 9.50146 5.96648 9.50146 5.99949C9.50146 6.03249 9.49493 6.06517 9.48224 6.09563C9.46955 6.1261 9.45095 6.15375 9.42752 6.17699L4.92752 10.677C4.68105 10.922 4.34758 11.0596 4.00002 11.0595Z"
          fill="currentColor"
        />
        <path
          d="M2.18182 5.99814C2.18182 6.60063 1.6934 7.08904 1.09091 7.08904C0.488417 7.08904 0 6.60063 0 5.99814C0 5.39564 0.488417 4.90723 1.09091 4.90723C1.6934 4.90723 2.18182 5.39564 2.18182 5.99814Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_876_359">
          <rect width="12" height="12" fill="white" transform="matrix(-1 0 0 1 12 -0.000976562)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DataInputArrow;
