const SearchSVG = (props) => (
  <svg
    width={props.width || "20"}
    height={props.height || "20"}
    viewBox="0 0 337 337"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M142.736 0.00455237C97.0285 0.0240834 54.0946 21.9225 27.2456 58.9065C0.393564 95.8945 -7.13344 143.497 6.99956 186.957C21.1366 230.422 55.2186 264.496 98.6906 278.617C142.16 292.734 189.761 285.191 226.741 258.328L297.862 329.449C303.522 335.109 311.776 337.32 319.51 335.249C327.244 333.175 333.283 327.136 335.358 319.401C337.428 311.667 335.217 303.413 329.557 297.753L258.436 226.632C279.209 198.081 288.655 162.843 284.956 127.73C281.253 92.6174 264.667 60.1253 238.397 36.5313C212.131 12.9373 178.045 -0.0776514 142.741 0.000348573L142.736 0.00455237ZM142.736 240.805C116.744 240.805 91.8176 230.481 73.4386 212.102C55.0596 193.723 44.7356 168.797 44.7356 142.805C44.7356 116.813 55.0596 91.8866 73.4386 73.5076C91.8176 55.1286 116.744 44.8046 142.736 44.8046C168.728 44.8046 193.654 55.1286 212.033 73.5076C230.412 91.8866 240.736 116.813 240.736 142.805C240.704 168.785 230.373 193.696 211.998 212.067C193.627 230.442 168.717 240.774 142.736 240.805V240.805Z"
      fill={props.fill || "black"}
    />
  </svg>
);

export default SearchSVG;
