import styles from "./DateRangePickerComponent.module.scss";
import { DateRangePicker } from "react-date-range";
import { Button } from "@mui/material";
import { useState } from "react";
import { useCallback } from "react";


const DateRangePickerComponent = ({ onChange, handleCancel, selectedDate }) => {
    const [selectedDateState, setSelectedDateState] = useState(selectedDate);

    const handleChange = useCallback(({ selection }) => {
        setSelectedDateState([selection]);
    }, []);

    const handleSave = useCallback(() => {
        onChange && onChange(selectedDateState)        
    }, [onChange, selectedDateState]);

    return (
        <>
            <DateRangePicker
                onChange={handleChange}
                ranges={selectedDateState}
                showSelectionPreview={false}
                showDateDisplay={false}
                showMonthAndYearPickers={false}
                retainEndDateOnFirstSelection={true}
                months={2}
                inputRanges={[]}
                direction="horizontal"
                className={styles.dateRangePickerClass}
            />
            <div className={styles.buttonContainer}>
                <div className={styles.saveButton}>
                    <Button
                        onClick={handleSave}
                        variant="contained"
                        size="small"
                    >
                        Save
                    </Button>
                </div>
                <div className={styles.cancelButton}>
                    <Button
                        onClick={handleCancel}
                        className={styles.saveButton}
                        variant="outlined"
                        size="small"
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </>
    );
};

export default DateRangePickerComponent;
