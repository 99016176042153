// types
import { createSlice } from "@reduxjs/toolkit";

const DEFAULT_FACILITY_ACCESS = {
    read: true,
    write: true
}
// initial state
const initialState = {
    role: null,
    facilityAccess: DEFAULT_FACILITY_ACCESS,
    isMultipleFacility : false,
    facilityIds: []
};

// ==============================|| SLICE - PERMISSION ||============================== //

const permission = createSlice({
    name: "permission",
    initialState,
    reducers: {
        setFacilityAccess(state, action) {
            state.facilityAccess = action.payload;
        },
        setRoleName(state, action) {
            state.role = action.payload;
        },
        setFacilityIds(state, action) {
            state.facilityIds = action.payload;
        },
        setMultipleFacility(state, action) {
            state.isMultipleFacility = action.payload;
        }
    },
});

export default permission.reducer;

export const {
    setRoleName,
    setFacilityAccess,
    setMultipleFacility,
    setFacilityIds
} = permission.actions;
