import * as React from "react";
import { Dialog, Grid } from "@mui/material";
import ChartDialogTitle from "./ChartDialogTitle";

export default function ChartDialogContainer({ handleClose, isOpen, children }) {
  return (
    <Dialog
      disableEscapeKeyDown={false}
      fullWidth={true}
      maxWidth={"xlg"}
      open={isOpen}
      onClose={handleClose}
      sx={{
        ".MuiDialog-paper": {
          height: "98%",
          overflow: "hidden"
        },
      }}
    >
      <ChartDialogTitle handleClose={handleClose} />
      <Grid container direction={"row"}>
        {children}
      </Grid>
    </Dialog>
  );
}
