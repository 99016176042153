import { getCensusFirstDateData, getFirstAndLastDateOfInput, updateBedCapacityData, updateCensusData, updateEndDateOfADTData } from "./api/data-input.api";

const updateCensus = async (bodyData, facilityId) => {
    try {
        return await updateCensusData(bodyData, facilityId);
    } catch (e) {
        console.log(e);
    }
};

const updateBedCapacity = async (bodyData) => {
    try {
        return await updateBedCapacityData(bodyData);
    } catch (e) {
        console.log(e);
    }
};

const updateEndDateOfADT = async (bodyData) => {
    try {
        return await updateEndDateOfADTData(bodyData);
    } catch (e) {
        console.log(e);
    }
};

const getCensusFirstDate = async (facilityId) => {
    try {
        return await getCensusFirstDateData({ facilityId });
    } catch (e) {
        console.log(e);
    }
};

const firstAndLastDateOfInput = async (facilityId) => {
    try {
        return await getFirstAndLastDateOfInput(facilityId);
    } catch (e) {
        console.log(e);
    }
};

export {
    firstAndLastDateOfInput,
    getCensusFirstDate,
    updateBedCapacity,
    updateCensus,
    updateEndDateOfADT
};
