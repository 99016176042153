import api from './api';

const API_PREFIX = "api/validation";

const getValidationsData = async (params) => {
    const response = await api.get(`${API_PREFIX}/list`, { params });
    return response;
};

export {
    getValidationsData
};
