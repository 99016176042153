/* eslint-disable no-new-object */
import styles from "./SafeDischargeAssLivingSlider.module.scss";
import { Card, CardContent, Typography, Grid, Divider } from "@mui/material";
import Slider from "react-slick";
import classNames from "classnames";
import CardItemTooltip from "../../../shared/CardItemTooltip";

const SafeDischargeAssLivingSliderSkeleton = () => {
	const fakeData = [1, 2, 3];

	return (
		<Grid container alignItems="center" justifyContent={"center"} className={`${styles.safeDischargeAssLivingSlider}`}>
			<Grid item xs={12} className={classNames(styles.totalCardContainer, styles.skeleton)}>
				<Card className={classNames(styles.totalCard, styles.skeleton, "skeleton")}>
					<CardContent className={styles.totalCardContent} style={{ width: "100%", height: "100%" }} />
				</Card>
			</Grid>
			<Divider sx={{ width: "90% !important" }} flexItem orientation="horizontal"></Divider>
			<Grid item xs={12} alignItems="center" container className={styles.sliderCardContainer}>
				<Grid item xs={10}>
					<div className={"assLiving-slider"}>
						{fakeData.length > 0 && (
							<Slider arrows={false} infinite={true} swipe={false}>
								{fakeData.map((ele, index) => {
									return (
										<Card key={`card-${index}`} className={classNames(styles.slideCard, styles.skeleton, "skeleton")}>
											<CardItemTooltip item={ele}>
												<CardContent className={styles.slideCardContent}>
													<Grid container direction={"row"} key={"row" + index}>
														<Grid item xs={12}>
															<Typography sx={{ mb: 0.5 }} variant="subtitle1">
																{ele.name}
															</Typography>
															<Typography textAlign={"start"} className={`ffmar-bold fs12 fw700`}></Typography>
														</Grid>
													</Grid>
													<div className={classNames("df fs15 fw500", styles.checkboxSliderButtonContainer)}></div>
												</CardContent>
											</CardItemTooltip>
										</Card>
									);
								})}
							</Slider>
						)}
					</div>
				</Grid>
				<Grid item xs={2} sx={{ pl: "20px" }}>
					<div
						style={{
							height: "110px",
							width: "100%",
							padding: "0px",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<div className="skeleton" style={{ width: "65px", height: "65px", borderRadius: "9999px" }}></div>
					</div>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default SafeDischargeAssLivingSliderSkeleton;
