// types
import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_FILTER } from "../../../data/common.data";

const DEFAULT_ADD_DB_DATA = {
	totalAdmissions: 0,
	totalReAdmissions: 0,
	total: 0,
	censusAverage: null,
};

export const DEFAULT_CARD_FILTER = {
	doctorData: [],
	daysData: [],
	dxData: [],
	insuranceData: [],
	floorsData: [],
	hospitalData: [],
	adtData: [],
	adtAdmitPatientIds: [],
	mainPriorityData: [],
	admissionTotal: 0,
};

// initial state
const initialState = {
	transferType: null,
	mainTotal: 0,
	selectedFilter: null,
	detailsDialog: {
		isOpen: false,
		type: null,
		item: null,
	},
	detailsADTDialog: {
		isOpen: false,
		type: null,
		item: null,
		admissionTotal: 0,
	},
	filter: DEFAULT_FILTER,
	cardFilter: DEFAULT_CARD_FILTER,
	dbData: DEFAULT_ADD_DB_DATA,
	selectedADTTableData: [],
	defaultADTData: [],
	dbSelectedFilters: null,
	isAdtLoading: false,
	filterTotal: null,
	filterTotalWithoutLock: null,
	mainNumPercentage: null,
	filterListData: null,
	filterWithADTFilters: DEFAULT_FILTER,
	patientList: {
		list: [],
		adtList: [],
	},
	lockFilterTotalState: { count: 0, isLocked: false, lockedFilterTotal: 0 },
	returnedSpecialState: { isLocked: false, lockedFilterTotal: 0 },
	facilityPercentage: [],
	lockedByFacility: [],
	lockedTotalBy: null,
	isCensusTotalLocked: false,
	specialNumber: null,
	specialComparisonSet: false,
	lockeByADT: null,
};

// ==============================|| SLICE - ADMISSIONS ||============================== //

const admissionComparison = createSlice({
	name: "admissionComparison",
	initialState,
	reducers: {
		setIsMainCensusPercentage(state, action) {
			state.mainNumPercentage = action.payload;
		},
		setLockTotal(state, action) {
			state.lockedTotal = action.payload;
		},
		setDefaultAdmissionData(state, action) {
			state.transferType = null;
			state.cardFilter = DEFAULT_CARD_FILTER;
			state.mainTotal = 0;
			state.filter = DEFAULT_FILTER;
			state.dbData = DEFAULT_ADD_DB_DATA;
		},
		setIsAdtLoading(state, action) {
			state.isAdtLoading = action.payload;
		},
		setDBSelectedFilters(state, action) {
			state.dbSelectedFilters = action.payload;
		},
		setTransferType(state, action) {
			state.transferType = action.payload;
		},
		setMainTotal(state, action) {
			state.mainTotal = action.payload;
		},
		openADTDetailsDialog(state, action) {
			state.detailsADTDialog = { ...action.payload };
		},
		closeADTDetailsDialog(state, action) {
			state.detailsADTDialog = {
				isOpen: false,
				type: null,
				item: null,
				admissionTotal: 0,
			};
		},
		openDetailsDialog(state, action) {
			state.detailsDialog = { ...action.payload };
		},
		closeDetailsDialog(state, action) {
			state.detailsDialog = {
				isOpen: false,
				type: null,
				item: null,
			};
		},

		setFilterDateRange(state, action) {
			state.filter = { ...state.filter, ...action.payload };
		},

		setFilterDBData(state, action) {
			state.dbData = { ...state.dbData, ...action.payload };
		},

		setCardFilter(state, action) {
			state.cardFilter = {
				...state.cardFilter,
				...action.payload,
			};
		},

		setFilterListData(state, action) {
			state.filterListData = action.payload;
		},

		setSelectedFilter(state, action) {
			if (action.payload) {
				// eslint-disable-next-line eqeqeq
				if (action.payload != "all") {
					const { filter } = action.payload;
					if (filter && filter.cardFilter) {
						state.cardFilter = {
							...DEFAULT_CARD_FILTER,
							...filter.cardFilter,
						};
					}
					if (filter && filter.transferType) {
						state.transferType = filter.transferType;
					} else {
						state.transferType = null;
					}
					if (filter && filter.filter) {
						state.filter = filter.filter;
					}
					state.selectedFilter = action.payload._id;
				} else {
					const existingUsers = JSON.parse(JSON.stringify(state.cardFilter.adtData));
					state.selectedFilter = action.payload;
					state.cardFilter = { ...DEFAULT_CARD_FILTER, adtData: existingUsers };
					state.filter = { ...state.filter };
					state.transferType = null;
					state.selectedADTTableData = [];
					state.dbSelectedFilters = null;
					state.lockedTotal = null;
				}
			}
		},
		setSelectedADT(state, action) {
			state.selectedADT = action.payload;
		},
		setSelectedADTTableData(state, action) {
			state.selectedADTTableData = action.payload;
		},
		setDefaultADTData(state, action) {
			state.defaultADTData = action.payload;
		},
		setFilterTotal(state, action) {
			state.filterTotal = action.payload;
		},
		setFilterTotalWithoutLock(state, action) {
			state.filterTotalWithoutLock = action.payload;
		},
		setAdtFacilityPercentageComparison(state, action) {
			state.adtFacilityPercentage = action.payload;
		},
		setFilterWithADTFilters(state, action) {
			state.filterWithADTFilters = action.payload;
		},
		setPatientList(state, action) {
			state.patientList = action.payload;
		},
		setIsSpecialComparison(state, action) {
			state.specialComparisonSet = action.payload;
		},
		setLockFilterTotalState(state, action) {
			state.lockFilterTotalState = action.payload;
		},
		setReturnedSpecialState(state, action) {
			state.returnedSpecialState = action.payload;
		},
		setFacilityPercentage(state, action) {
			state.facilityPercentage = action.payload;
		},
		setLockedByFacility(state, action) {
			state.lockedByFacility = action.payload;
		},
		setLockedTotalBy(state, action) {
			state.lockedTotalBy = action.payload;
		},
		setLockedlockeByADT(state, action) {
			state.lockeByADT = action.payload;
		},
	},
});

export default admissionComparison.reducer;

export const {
	setIsMainCensusPercentage,
	setLockTotal,
	setFilterTotal,
	setSelectedADT,
	setTransferType,
	openDetailsDialog,
	closeDetailsDialog,
	setFilterDateRange,
	setFilterDBData,
	setMainTotal,
	setCardFilter,
	setDefaultADTData,
	setSelectedFilter,
	setDBSelectedFilters,
	setDefaultAdmissionData,
	setSelectedADTTableData,
	setIsAdtLoading,
	setFilterPatentIds,
	openADTDetailsDialog,
	closeADTDetailsDialog,
	setFilterListData,
	setAdtFacilityPercentageComparison,
	setFilterWithADTFilters,
	setPatientList,
	setIsSpecialComparison,
	setLockFilterTotalState,
	setReturnedSpecialState,
	setFilterTotalWithoutLock,
	setFacilityPercentage,
	setIsCensusTotalLocked,
	setLockedTotalBy,
	setLockedlockeByADT,
} = admissionComparison.actions;
