const DragSVG = (props) => (
  <svg
    width="14"
    height="19"
    viewBox="0 0 14 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.74999 1.87499C3.74999 2.91057 2.91057 3.74999 1.87499 3.74999C0.839422 3.74999 0 2.91057 0 1.87499C0 0.839422 0.839422 0 1.87499 0C2.91057 0 3.74999 0.839422 3.74999 1.87499Z"
      fill="black"
      fillOpacity="0.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.291 1.87499C13.291 2.91057 12.4516 3.74999 11.416 3.74999C10.3804 3.74999 9.54102 2.91057 9.54102 1.87499C9.54102 0.839422 10.3804 0 11.416 0C12.4516 0 13.291 0.839422 13.291 1.87499Z"
      fill="black"
      fillOpacity="0.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.74999 9.49999C3.74999 10.5356 2.91057 11.375 1.87499 11.375C0.839422 11.375 0 10.5356 0 9.49999C0 8.46442 0.839422 7.625 1.87499 7.625C2.91057 7.625 3.74999 8.46442 3.74999 9.49999Z"
      fill="black"
      fillOpacity="0.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.291 9.49999C13.291 10.5356 12.4516 11.375 11.416 11.375C10.3804 11.375 9.54102 10.5356 9.54102 9.49999C9.54102 8.46442 10.3804 7.625 11.416 7.625C12.4516 7.625 13.291 8.46442 13.291 9.49999Z"
      fill="black"
      fillOpacity="0.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.74999 17.125C3.74999 18.1606 2.91057 19 1.87499 19C0.839422 19 0 18.1606 0 17.125C0 16.0894 0.839422 15.25 1.87499 15.25C2.91057 15.25 3.74999 16.0894 3.74999 17.125Z"
      fill="black"
      fillOpacity="0.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.291 17.125C13.291 18.1606 12.4516 19 11.416 19C10.3804 19 9.54102 18.1606 9.54102 17.125C9.54102 16.0894 10.3804 15.25 11.416 15.25C12.4516 15.25 13.291 16.0894 13.291 17.125Z"
      fill="black"
      fillOpacity="0.5"
    />
  </svg>
);

export default DragSVG;
