import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
  credentials: "include",
});

instance.interceptors.request.use(
  function (config) {
    const token = sessionStorage.getItem("x-auth");

    if (token) {
      config.headers["x-auth"] = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (config) {
    return config;
  },
  function (error) {
    if (error.response?.status === 403 || error.response?.status === 503) {
      window.location.pathname = `/login`;
      return error;
    }
  }
);

export default instance;
