import { ListItemText, Checkbox } from "@mui/material";
import styles from "./HospitalizationsList.module.scss";
import { CustomeList, CustomeListItem, PrimaryText } from "./HospitalizationsList";
import HospitalizationsProgressBarSkeleton from "./HospitalizationsProgressBarSkeleton";

const HospitalizationsListSkeleton = () => {
	const fakeData = [{ label: "New Hospitalizations" }, { label: "Re-Hospitalizations" }];
	return (
		<div className={`${styles.hospitalizationsList} `}>
			<CustomeList dense>
				{fakeData.map((item) => {
					return (
						<CustomeListItem
							key={item}
							secondaryAction={
								<>
									<Checkbox disabled size="small" className="skeleton" style={{ borderRadius: "5px", padding: "0" }} />
								</>
							}
						>
							<ListItemText
								//onClick={handleToggleCheckBox(item)}
								primary={<PrimaryText name={item.label} />}
								secondary={
									<HospitalizationsProgressBarSkeleton sx={{ width: "80%", m: "0px" }} progressBarSx={{ mb: "8px" }} />
								}
							/>
						</CustomeListItem>
					);
				})}
			</CustomeList>
		</div>
	);
};

export default HospitalizationsListSkeleton;
