import { Box } from "@mui/system";
import styles from "./AddPatient.module.scss";
import { useCallback, useState } from "react";

const ShowPriorHospitalInput = ({
    handleClose,
    setAllFieldsValue,
    allFields,
    name,
    handleSave
}) => {
    const [error, setError] = useState(false);
    const handleSaveData = useCallback(() => {
        setError(false)
        if (allFields[name] !== null) {
            if (allFields[name] === undefined) {
                setError(true);
            } else {
                handleSave()
            }
        }
    }, [allFields, handleSave, name]);

    return (
        <div className={styles.duplicatePopup + " " + styles.addPatient}>
            <div className={styles.duplicateOverlay}></div>
            <Box className={styles.duplicateContent} sx={{ padding: 4, mt: 1, mb: 2 }}>

                <div className={`m-b-20 inputWrpr`}>
                    <label
                        className={`ffmr fs12`}
                    >
                        Did Resident have a prior hospitalization within 30 days of current transfer?
                    </label>
                    <div className={`df aic ffml fs14 ${styles.toggleWrpr} mt-10`}>
                        {[{ value: true, label: "Yes" }, { value: false, label: "No" }].map(option => (
                            <div
                                className={`${styles.sec} ${allFields[name] === option.value ? styles.selected : ""}`}
                                onClick={() => {
                                    setError(false)
                                    setAllFieldsValue(name, option.value)
                                }}
                            >
                                {option.label}
                            </div>
                        ))}
                    </div>
                    {error && (
                        <p className="mt-6 error ffmr fs12 ">Please select at least one option.</p>
                    )}
                </div>

                <div className={`df`}>
                    <div className={`df aic mla mt-6`}>
                        <button
                            className={`ffmr fs14 m-r-8 ${styles.text}`}
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                        <button
                            className={`df aic ffmr fs14 ttuc ${styles.primary} `}
                            onClick={handleSaveData}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Box>
        </div>
    );
};

export default ShowPriorHospitalInput;
