/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import _ from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { ADT_TABLE_TYPE } from "../../../../../../types/common.type";
import { calcProPercentsBasedOnFilterAndDays, itemPercentage } from "../../../../../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../../../../utilis/percentage-comparison";
import CardItemTooltipADT from "../../../../../shared/CardItemTooltipADT";
import ColorBox from "../../../../../shared/dashboard/ColorBox";
import styles from "./TableADT.module.scss";

const headCells = [
	{
		id: "name",
		numeric: false,
		disablePadding: true,
		label: "Name",
	},
	{
		id: "total",
		numeric: true,
		disablePadding: false,
		label: "# OF TRANSFER",
	},
	{
		id: "percentage",
		numeric: true,
		disablePadding: false,
		label: "%",
	},
];

function EnhancedTableHead(props) {
	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">&nbsp;</TableCell>
				{headCells.map((headCell) => (
					<TableCell key={headCell.id} align={"center"} padding={"normal"}>
						<div className={styles.tableHederCell}>{headCell.label}</div>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

export default function TableADT({
	item,
	itemComparison,
	handleToggleADTTab,
	handleToggleAll,
	dontCompareDiffFacilities,
	filter,
	filterComparison,
	totalAdmissions,
	totalAdmissionsComparison,
	projectionDays,
	priorityNumber,
	transferType,
	isComparingAgainstAvgCensus,
	lockedTotalBy,
	searchValue,
	specificData,
	triggerSelectAll,
	dbData,
}) {
	// const [selected, setSelected] = useState([]);
	const [data, setData] = useState([]);
	const [dataComparison, setDataComparison] = useState([]);
	const [isCheckboxProcess, setIsCheckboxProcess] = useState(false);
	const isFirstItemInPriorityData = priorityNumber === 1;
	const [tryIt, setTryIt] = useState(false);

	// useEffect(() => {
	// 	specificData?.selectedIds &&
	// 		specificData?.selectedIds.length !== selected.length &&
	// 		setSelected(specificData?.selectedIds);
	// }, [specificData?.selectedIds]);

	React.useEffect(() => {
		if (item.tableData && item.tableData.length > 0) {
			setData(item.tableData);
		} else {
			setData([]);
		}

		if (itemComparison?.tableData && itemComparison?.tableData.length > 0) {
			setDataComparison(itemComparison?.tableData);
		} else {
			setDataComparison([]);
		}
	}, [item, itemComparison]);

	const processClickInfo = (row, selectedProps) => {
		let selected = selectedProps ? [...selectedProps] : [...(specificData?.selectedIds || [])];
		try {
			setIsCheckboxProcess(true);
			const { id } = row;
			const selectedIndex = selected.indexOf(id);
			if (!selectedProps) {
				if (selectedIndex === -1) {
					selected.push(id);
				} else {
					selected.splice(selectedIndex, 1);
				}
			}
			let selectedPatientIds = [];
			let selectedTransferIds = [];
			let selectedPatientIdsComparison = [];
			let selectedTransferIdsComparison = [];

			if (row.type === ADT_TABLE_TYPE.DAY_CHART || row.type === ADT_TABLE_TYPE.GROUP) {
				const selectedPatientFilter = _.filter(data, ({ id }) => _.every([_.includes(selected, id)]));
				const latestPatientIds = _.map(selectedPatientFilter, "patientIds");
				selectedPatientIds = latestPatientIds.length > 0 ? latestPatientIds.flat(1) : [];
				selectedTransferIds = selected;

				const selectedPatientFilterComparison = _.filter(dataComparison, ({ id }) =>
					_.every([_.includes(selected, id)])
				);
				const latestPatientIdsComparison = _.map(selectedPatientFilterComparison, "patientIds");

				selectedPatientIdsComparison = latestPatientIdsComparison.length > 0 ? latestPatientIdsComparison.flat() : [];
				selectedTransferIdsComparison = selected; // Same as above or from comparison data?
			}
			if (
				row.type === ADT_TABLE_TYPE.ALL ||
				row.type === ADT_TABLE_TYPE.TOTAL ||
				row.type === ADT_TABLE_TYPE.GROUP_ARRAY
			) {
				const selectedPatientFilter = _.filter(data, ({ id }) => _.every([_.includes(selected, id)]));
				const selectedPatientFilterComparison = _.filter(dataComparison, ({ id }) =>
					_.every([_.includes(selected, id)])
				);

				const latestPatientIds = _.map(selectedPatientFilter, "admissionIds");
				const latestPatientIdsComparison = _.map(selectedPatientFilterComparison, "admissionIds");

				selectedPatientIds = latestPatientIds.length > 0 ? latestPatientIds.flat(1) : [];
				selectedPatientIdsComparison = latestPatientIdsComparison.length > 0 ? latestPatientIdsComparison.flat(1) : [];

				const latestTransferPatientIds = _.map(selectedPatientFilter, "transferIds");
				const latestTransferPatientIdsComparison = _.map(selectedPatientFilterComparison, "transferIds");

				selectedTransferIds = latestTransferPatientIds.length > 0 ? latestTransferPatientIds.flat(1) : [];
				selectedTransferIdsComparison =
					latestTransferPatientIdsComparison.length > 0 ? latestTransferPatientIdsComparison.flat(1) : [];
			}
			if (row.type === ADT_TABLE_TYPE.ANALYSIS_CHART || row.type === ADT_TABLE_TYPE.CHART) {
				const selectedPatientFilter = _.filter(data, ({ id }) => _.every([_.includes(selected, id)]));
				const selectedPatientFilterComparison = _.filter(dataComparison, ({ id }) =>
					_.every([_.includes(selected, id)])
				);

				const latestPatientIds = _.map(selectedPatientFilter, "admissionIds");
				const latestPatientIdsComparison = _.map(selectedPatientFilterComparison, "admissionIds");

				selectedPatientIds = latestPatientIds.length > 0 ? latestPatientIds.flat(1) : [];
				selectedPatientIdsComparison = latestPatientIdsComparison.length > 0 ? latestPatientIdsComparison.flat(1) : [];

				const latestTransferPatientIds = _.map(selectedPatientFilter, "patientIds");
				const latestTransferPatientIdsComparison = _.map(selectedPatientFilterComparison, "patientIds");

				selectedTransferIds = latestTransferPatientIds.length > 0 ? latestTransferPatientIds.flat(1) : [];
				selectedTransferIdsComparison =
					latestTransferPatientIdsComparison.length > 0 ? latestTransferPatientIdsComparison.flat(1) : [];
			}
			setIsCheckboxProcess(false);

			return {
				selectedPatientIds,
				selectedTransferIds,
				selectedPatientIdsComparison,
				selectedTransferIdsComparison,
				newSelected: selected,
			};
		} catch (e) {
			console.log(e);
		}
	};

	const handleClick = (row, rowComparison, isChecked) => {
		if (!isCheckboxProcess) {
			const {
				selectedPatientIds,
				selectedTransferIds,
				selectedPatientIdsComparison,
				selectedTransferIdsComparison,
				newSelected,
			} = processClickInfo(row);

			handleToggleADTTab({
				savedData: selectedPatientIds,
				savedDataComparison: selectedPatientIdsComparison,
				selectedTransferIds,
				selectedTransferIdsComparison,
				item: row,
				itemComparison: rowComparison,
				parentItem: item,
				parentItemComparison: itemComparison,
				total: newSelected.length || 0,
				selectedIds: newSelected,
				isChecked,
				setSpecialNumberFlag: false,
			});
		}
	};

	useEffect(() => {
		if (data && (triggerSelectAll === false || triggerSelectAll === true)) {
			let allSelectedPatientIds = [];
			let allSelectedTransferIds = [];
			let allSelectedPatientIdsComparison = [];
			let allSelectedTransferIdsComparison = [];
			let allNewSelected = triggerSelectAll ? data.map((x) => x.id) : [];

			data.forEach((row) => {
				const { selectedPatientIds, selectedTransferIds, selectedPatientIdsComparison, selectedTransferIdsComparison } =
					processClickInfo(row, allNewSelected);

				if (triggerSelectAll) {
					allSelectedPatientIds = [...allSelectedPatientIds, ...selectedPatientIds];
					allSelectedTransferIds = [...allSelectedTransferIds, ...selectedTransferIds];
					allSelectedPatientIdsComparison = [...allSelectedPatientIdsComparison, ...selectedPatientIdsComparison];
					allSelectedTransferIdsComparison = [...allSelectedTransferIdsComparison, ...selectedTransferIdsComparison];
				} else if (triggerSelectAll === false) {
					allSelectedPatientIds = [];
					allSelectedTransferIds = [];
					allSelectedPatientIdsComparison = [];
					allSelectedTransferIdsComparison = [];
				}
			});

			const parentItem =
				item?.selectedIds === undefined
					? item
					: {
							...item,
							selectedIds: !triggerSelectAll ? [] : item.selectedIds,
					  };

			handleToggleAll({
				savedData: allSelectedPatientIds,
				savedDataComparison: allSelectedPatientIdsComparison,
				selectedTransferIds: allSelectedTransferIds,
				selectedTransferIdsComparison: allSelectedTransferIdsComparison,
				items: data,
				itemsComparison: dataComparison,
				parentItem,
				parentItemComparison: itemComparison,
				total: allNewSelected.length || 0,
				selectedIds: allNewSelected,
				isChecked: triggerSelectAll,
				setSpecialNumberFlag: false,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [triggerSelectAll]);

	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<TableContainer className="tableContainerADT">
					<Table>
						<EnhancedTableHead numSelected={specificData?.selectedIds?.length || 0} rowCount={data.length} />
						<TableBody>
							{data
								.filter((x) => x.label.toLowerCase().includes(searchValue.toLowerCase()))
								.map((row, index) => {
									const { parentId, id } = row;
									const rowComparison = itemComparison?.tableData?.find((x) => x.id === row.id);
									const isItemSelected = !!specificData?.selectedIds?.find((x) => x === row.id);
									const labelId = `enhanced-table-checkbox-${index}`;
									const key = `enhanced-table-checkbox-${index + parentId + id}`;
									const percentage = itemPercentage(row.total, totalAdmissions, "number");
									const percentageComparison = itemPercentage(
										rowComparison?.total,
										totalAdmissionsComparison,
										"number"
									);
									const { percentageDiff, itemTotalComparisonScaled, numberOfDays } = calculateDiffBetweenPercentages(
										percentage,
										percentageComparison,
										id === "returned",
										filter,
										filterComparison,
										rowComparison?.total,
										projectionDays
									);

									const comparisonColor =
										dontCompareDiffFacilities || row.id === "returned" ? null : pickComparisonColor(percentageDiff);

									return (
										<TableRow
											hover
											onClick={() => handleClick(row, rowComparison, isItemSelected)}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={key}
											selected={isItemSelected}
										>
											<TableCell padding="checkbox" sx={{ width: "10%" }}>
												<Checkbox
													color="primary"
													checked={isItemSelected}
													inputProps={{
														"aria-labelledby": labelId,
													}}
												/>
											</TableCell>
											<CardItemTooltipADT item={row}>
												<TableCell id={labelId} align="start" sx={{ width: "50%" }}>
													<Typography variant="subtitle1" sx={{ cursor: "pointer" }}>
														{row.name}
													</Typography>
												</TableCell>
											</CardItemTooltipADT>
											<TableCell align="center">
												{calcProPercentsBasedOnFilterAndDays(row.total, filter, projectionDays)}
											</TableCell>
											<TableCell align="center">
												<ColorBox
													type="button"
													color={comparisonColor}
													sx={{ left: "6px", top: "18px" }}
													comparingAgainst={rowComparison?.total}
													comparingAgainstScaled={itemTotalComparisonScaled}
													numberOfDays={numberOfDays}
												/>
												{calcProPercentsBasedOnFilterAndDays(
													row.percentage,
													filter,
													projectionDays,
													true,
													isComparingAgainstAvgCensus ||
														lockedTotalBy === "census" ||
														(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
												)}
												%
											</TableCell>
										</TableRow>
									);
								})}
							{data.length === 0 && (
								<TableRow
									style={{
										height: 53 * 0,
									}}
								>
									<TableCell colSpan={4} align="center">
										No record found
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Box>
	);
}
