import classNames from "classnames";
import styles from "./SixtyDaysAnalysis.module.scss";
import CheckboxButton from "../../shared/checkboxButton/CheckboxButton";
import CardItemTooltip from "../../../shared/CardItemTooltip";
import { Typography } from "@mui/material";

const SixtyDaysAnalysisSkeleton = ({ style }) => {
	const fakeDataList = [
		{ width: "400px", label: "1 - 7 Days" },
		{ width: "200px", label: "8 - 14 Days" },
		{ width: "300px", label: "15 - 30 Days" },
		{ width: "200px", label: "31 - 60 Days" },
	];
	const radialDataListFake = [
		{ type: "short" },
		{ type: "short" },
		{ type: "long" },
		{ type: "long" },
		{ type: "long" },
	];

	return (
		<div className={`df aic w100 ${styles.sixtyDays}`}>
			<div className={`${styles.leftSec}`}>
				<div className={`df aic`} style={{ height: "calc(100% - 64px)" }}>
					<div className={classNames(styles.sixtyDaysList, styles.skeleton)}>
						{fakeDataList.length > 0 &&
							fakeDataList.map((line, index) => {
								return (
									<div key={`${index}-nineteen`} className={classNames(styles.sixtyDaysLinesWrapper, styles.skeleton)}>
										<div className={classNames("df", "aic", styles.sixtyDaysLine, styles.skeleton)}>
											<CheckboxButton
												className="skeleton"
												size="small"
												disabled
												disableRipple={true}
												sx={{
													ml: "-8px",
													mr: "8px",
													borderRadius: "5px",
													padding: 0,
													cursor: "default",
												}}
												buttonText={
													<CardItemTooltip item={line} key={`tooltip-${index}`}>
														<Typography variant="subtitle4" sx={{ whiteSpace: "nowrap" }}>
															{line.label}
														</Typography>
													</CardItemTooltip>
												}
											/>
										</div>
										<div style={{ width: line.width, height: "32px", borderRadius: "20px" }} className="skeleton"></div>
									</div>
								);
							})}
					</div>
				</div>
			</div>
			<div
				className=""
				style={{
					height: "100%",
					marginBottom: "20px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					width: "37%",
				}}
			>
				<div className={styles.container}>
					<div className={styles.circle}>
						<div className={classNames(styles.wave1, "skeleton-text")}></div>
						<div className={classNames(styles.wave2, "skeleton-text")}></div>
						<div className={classNames(styles.wave3, "skeleton-text")}></div>
						<div className={classNames(styles.wave4, "skeleton-text")}></div>
						<div className={classNames(styles.wave5, "skeleton-text")}></div>
						<div className={classNames(styles.wave6, "skeleton-text")}></div>
					</div>
				</div>
				<div className={`${styles.radialLegend} ${styles.skeleton}`}>
					{radialDataListFake.length > 0 &&
						radialDataListFake.map((line, index) => {
							return (
								<span className={`df aic fs10 fw600 ffint`} key={`line${index}`}>
									<div className={classNames(styles.icon, "skeleton")}></div>
									<div
										className={classNames(styles.percentLabel, styles.skeleton, styles[line.type], "skeleton")}
									></div>
								</span>
							);
						})}
				</div>
			</div>
		</div>
	);
};

export default SixtyDaysAnalysisSkeleton;
