import DeleteIcon from "@mui/icons-material/Delete";
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import { Button, IconButton, InputAdornment, OutlinedInput, Stack, Switch } from "@mui/material";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { removePercentageAgainst, savePercentageAgainst } from "../../../../services/filter.service";
import { setCustomePercentages } from "../../../../store/reducers/common.slice";
import { ADD_NOTIFICATION } from "../../../../store/types";
import CalculatorInput from "../CalculatorInput";

const CustomPercentageInput = ({ percentageBy, handleChangePercentage, item, index, customePercentages }) => {
	const dispatch = useDispatch();
	const [isOpenCalculate, setIsOpenCalculate] = React.useState(false);
	const handleOnsubmitCustomFilter = useCallback(async () => {
		const resData = await savePercentageAgainst({
			customPercentage: item?.customPercentage ? Number(item?.customPercentage) : null,
			label: item?.label,
			_id: item?._id || null,
		});
		if (resData && resData.status === 200) {
			if (!item?._id) {
				const newState = customePercentages.map((obj) => (obj.index === index ? { ...resData.data, index } : obj));
				dispatch(setCustomePercentages(newState));
			}
			dispatch({
				type: ADD_NOTIFICATION,
				payload: {
					type: "success",
					label: `Custom number updated successfully`,
					id: "patientAddedUpdated",
				},
			});
		} else {
			dispatch({
				type: ADD_NOTIFICATION,
				payload: {
					label: resData?.message,
					type: "error",
					id: "hospitalSaveFilterError",
				},
			});
		}
	}, [customePercentages, dispatch, index, item?._id, item?.customPercentage, item?.label]);

	const handleRemove = useCallback(async () => {
		if (item && item._id) {
			await removePercentageAgainst(item._id);
		}
		const updatedCustomePercentages = [...customePercentages];
		updatedCustomePercentages.splice(index, 1);
		const updatedArr = updatedCustomePercentages.map((ele, index) => {
			return { ...ele, index };
		});
		dispatch(setCustomePercentages(updatedArr));
	}, [item, index, customePercentages, dispatch]);

	const handleUpdateValues = useCallback(
		(value, field) => {
			const newState = customePercentages.map((obj) => (obj.index === index ? { ...obj, [field]: value } : obj));

			dispatch(setCustomePercentages(newState));
		},
		[customePercentages, dispatch, index]
	);

	const handleOpenCalculate = useCallback(() => {
		setIsOpenCalculate(true);
	}, []);

	return (
		<>
			<Stack direction={"row"} spacing={2} sx={{ mt: 1 }} key={`${index}_input_custom`}>
				{item?._id && (
					<Switch
						checked={percentageBy === item?.label}
						onChange={(e) => {
							handleChangePercentage(e, item?.label, true);
						}}
						inputProps={{ "aria-label": "updatedCustomNumber" }}
						size="small"
					/>
				)}
				<Stack direction="row">
					<IconButton aria-label="delete" size={"small"} onClick={handleOpenCalculate} sizwe={"small"}>
						<CalculateOutlinedIcon fontSize="small" />
					</IconButton>
					{/* <Typography>#</Typography> */}
					<OutlinedInput
						size="small"
						type={"number"}
						value={Number(item?.customPercentage) || null}
						onChange={(e) => handleUpdateValues(e.target.value, "customPercentage")}
						sx={{ width: "80px", ml: "0px" }}
						startAdornment={<InputAdornment position="start">#</InputAdornment>}
					/>
				</Stack>

				<OutlinedInput
					size="small"
					type={"text"}
					value={item?.label || ""}
					inputProps={{ maxLength: 20 }}
					placeholder="Percentage label"
					onChange={(e) => handleUpdateValues(e.target.value, "label")}
				/>
				<IconButton aria-label="delete" size={"small"} onClick={handleRemove}>
					<DeleteIcon fontSize="small" />
				</IconButton>

				<Button
					disabled={!item?.customPercentage || !item?.label}
					size="small"
					variant="contained"
					onClick={handleOnsubmitCustomFilter}
					sx={{ lineHeight: "11px" }}
				>
					Save
				</Button>
			</Stack>
			{isOpenCalculate && (
				<CalculatorInput
					handleClose={() => setIsOpenCalculate(false)}
					handleSubmit={(value) => {
						handleUpdateValues(value, "customPercentage")
						setIsOpenCalculate(false)
					}}
					title="Maths Calculator"
				/>
			)}
		</>
	);
};

export default CustomPercentageInput;
