import { useEffect, useRef, useState } from "react";
import axios from "../../../../../../axios";
import styles from "./AutoComplete.module.scss";

const AutoComplete = (props) => {
    const warpRef = useRef(null);

    const [autoCompleteList, setAutocompleteList] = useState([]);

    const getPatientAutoComplete = async (field, value) => {
        let re = await axios.get(
            `/api/patient/autocomplete/${localStorage.getItem(
                "facilityId"
            )}?type=${field}&search=${value}`
        );
        return setAutocompleteList(re.data);
    };

    const handleClickOutside = (e) => {
        if (warpRef.current && !warpRef.current.contains(e.target)) {
            setAutocompleteList([]);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);

    return (
        <div className={styles.validation} ref={warpRef}>
            <input
                className={`ffml fs16 ${autoCompleteList.length > 0 ? styles.open : ""
                    }`}
                value={props.value}
                onChange={(e) => {
                    if (e.target.value.length > 0) {
                        const values = e.target.value;
                        const latestVal = values.replace(/[^\w\s]/gi, '');
                        getPatientAutoComplete(props.field, latestVal);
                    } else {
                        setAutocompleteList([]);
                    }
                    return props.setValue(props.field, e.target.value);
                }}
                style={{
                    ...(props.isHighLightInfo ? { border: `1px solid ${props.isHighLightInfo}` } : {})
                }}
            />
            {autoCompleteList.length ? (
                <div className={styles.list}>
                    {autoCompleteList.map((item) => {
                        return (
                            <div
                                className={`ffmr fs14 df aic ${styles.line}`}
                                onClick={() => {
                                    props.setValue(props.field, item._id);
                                    setAutocompleteList([]);
                                }}
                            >
                                {item._id}
                            </div>
                        );
                    })}
                </div>
            ) : undefined}
        </div>
    );
};

export default AutoComplete;
