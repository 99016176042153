import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Box, IconButton, Typography } from "@mui/material";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ChartPopupIconSVG from "../../../../assets/svgs/chart-popup-icon.svg";
import { PAGE_TYPE } from "../../../../types/pages.type";
import { itemPercentage, projectionPerMonth } from "../../../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../../utilis/percentage-comparison";
import InfoIcon from "../../../icon/InfoIcon";
import ColorBox from "../../../shared/dashboard/ColorBox";
import HtmlTooltip from "../../../shared/HtmlTooltip";
import CardTooltipContent from "../../card/CardTooltipContent";
import styles from "./TotalCountCircle.module.scss";

const TotalCountCircle = ({
	dbData,
	dbDataComparison,
	admissionCompareAgainst,
	admissionCompareAgainstComparison,
	total,
	totalComparison,
	totalPercentage = 0,
	totalComparisonPercentage = 0,
	totalProjectionPercentage = 0,
	totalProjectionComparisonPercentage = 0,
	totalTitle,
	projectTotal = 0,
	projectTotalComparison,
	projectTitle,
	handleClickTotal,
	filter,
	filterComparison,
	page,
	projectionBgColor,
	tooltipContent,
	handleOnClickAction,
	selected,
	loading,
	handleOnClickReport,
	comparingAgainstDifferentFacility,
	projectionDays,
}) => {
	const handleClickOnDetails = useCallback(
		(event, totalTitle) => {
			event.stopPropagation();
			handleOnClickAction(totalTitle);
		},
		[handleOnClickAction]
	);
	const [comparingAgainstScaled, setComparingAgainstScaled] = useState(null);
	const [comparingAgainst, setComparingAgainst] = useState("");
	const [, setComparingAgainstProjection] = useState("");
	const percentageThresholds = useSelector((state) => state.comparison.percentageThresholds);
	const specialPages = [PAGE_TYPE.ADMISSION];
	const isSpecialPage = specialPages.find((x) => x === page)?.length > 0;
	const [comparisonDiffColor, setComparisonDiffColor] = useState(null);
	const [numberOfDays, setNumberOfDays] = useState(null);
	const projectTotalComparisonSpecial = projectionPerMonth(totalComparison, filterComparison, projectionDays);

	useEffect(() => {
		const totalSpecialPage = itemPercentage(total, admissionCompareAgainst, "number");
		const totalComparisonSpecialPage = itemPercentage(totalComparison, admissionCompareAgainstComparison, "number");
		const {
			percentageDiff,
			itemTotalComparisonScaled,
			numberOfDays: numberOfDaysTemp,
		} = isSpecialPage
				? calculateDiffBetweenPercentages(
					totalSpecialPage,
					totalComparisonSpecialPage,
					true,
					filter,
					filterComparison,
					totalComparison,
					projectionDays
				)
				: calculateDiffBetweenPercentages(
					totalPercentage,
					totalComparisonPercentage,
					false,
					filter,
					filterComparison,
					totalComparison,
					projectionDays
				);

		setNumberOfDays(numberOfDaysTemp);
		setComparingAgainstScaled(itemTotalComparisonScaled);
		setComparingAgainst(totalComparison);
		setComparingAgainstProjection(projectTotalComparison);

		const percentageChange = pickComparisonColor(percentageDiff, isSpecialPage);
		setComparisonDiffColor(percentageChange);
	}, [
		projectionDays,
		totalPercentage,
		totalProjectionComparisonPercentage,
		totalProjectionPercentage,
		totalComparisonPercentage,
		page,
		projectTotal,
		total,
		projectTotalComparison,
		totalComparison,
		isSpecialPage,
		projectTotalComparisonSpecial,
		filter,
		filterComparison,
		percentageThresholds,
		comparingAgainstDifferentFacility,
		dbData?.bedCapacity,
		dbDataComparison?.bedCapacity,
		admissionCompareAgainst,
		admissionCompareAgainstComparison,
	]);

	return (
		<>
			<div className={classNames("df", "acc", "p-t-95", "p-b-30", styles.totalCountCircle, loading && styles.skeleton)}>
				<div
					className={classNames(
						"df",
						"acc",
						"m-r-20",
						styles.circle,
						styles.totalCircle,
						selected && styles.selected,
						loading && "skeleton",
						!loading && page === "admission" ? styles.totalCircleAdmission : "",
						!loading && page === "hospital" ? styles.totalCircleHospital : "",
						!loading && page === PAGE_TYPE.COMMUNITY_TRANSFER ? styles.communityTransfer : "",
						!loading && page === PAGE_TYPE.DECEASED ? styles.deceased : "",
						!loading && page === PAGE_TYPE.OVERALL ? styles.deceased : ""
					)}
					onClick={handleClickTotal}
				>
					<div className={classNames("df", "ffc", "acc", styles.innerCircle, selected && styles.selected)}>
						{!loading && (
							<div className={styles.htmlTooltip}>
								<HtmlTooltip content={<CardTooltipContent content={tooltipContent} />}>
									<IconButton disableFocusRipple={true} disableRipple={true}>
										<InfoIcon />
									</IconButton>
								</HtmlTooltip>
							</div>
						)}
						{handleOnClickAction && !loading && (
							<div className={`cp ${styles.chartIcon}`} onClick={(e) => handleClickOnDetails(e, totalTitle)}>
								<ChartPopupIconSVG style={{ cursor: "pointer" }} fill="#4879F5" />
							</div>
						)}
						{handleOnClickReport && !loading && (
							<div className={`cp ${styles.reportIcon}`} onClick={(e) => handleClickOnDetails(e, totalTitle)}>
								<IconButton
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										handleOnClickReport(totalTitle);
									}}
									disableFocusRipple={true}
									disableRipple={true}
									size="small"
								>
									<FileUploadOutlinedIcon size="small" style={{ cursor: "pointer" }} fill="#4879F5" />
								</IconButton>
							</div>
						)}
						<Box
							display={"flex"}
							alignItems={"center"}
							justifyContent={"center"}
							className={`ffmar-bold fw700 fs65`}
						// sx={{ width: "65px", height: "73.5px" }}
						>
							{loading ? (
								<span className="skeleton-text">0</span>
							) : (
								Math.round(projectionDays === null ? total : projectTotal)
							)}
							{!loading && (
								<ColorBox
									color={comparisonDiffColor}
									comparingAgainst={comparingAgainst}
									comparingAgainstScaled={comparingAgainstScaled}
									numberOfDays={numberOfDays}
								/>
							)}
						</Box>
						<Typography variant="subtitle1" sx={{ whiteSpace: "pre-wrap", textAlign: "center" }}>
							{totalTitle}
						</Typography>
					</div>
				</div>
				{page !== PAGE_TYPE.OVERALL && (
					<div
						className={classNames(
							"df",
							"acc",
							styles.circle,
							styles.projection,
							selected ? styles.selected : null,
							!loading && styles.projectionColor,
							loading ? "skeleton" : ""
						)}
						{...(projectionBgColor
							? { style: { background: projectionBgColor, alignSelf: "flex-end" } }
							: { style: { alignSelf: "flex-end" } })}
						onClick={handleClickTotal}
					>
						<div className={classNames("df", "ffc", "acc", styles.innerCircle, styles.selected)}>
							<Box
								display={"flex"}
								alignItems={"center"}
								justifyContent={"center"}
								className={`ffmar-bold fw700 fs49`}
							// sx={{ width: "65px", height: "73.5px" }}
							>
								{loading ? (
									<span className="skeleton-text" style={{ fontStyle: "italic" }}>
										0%
									</span>
								) : (
									<span style={{ fontStyle: "italic" }}>{`${projectionDays === null ? totalPercentage : totalProjectionPercentage
										}%`}</span>
								)}
								{/* {!loading && (
								<ColorBox
									color={comparisonDiffColor}
									comparingAgainst={comparingAgainstProjection}
									comparingAgainstScaled={comparingAgainstScaled}
									numberOfDays={numberOfDays}
								/>
							)} */}
							</Box>
							<Typography variant="subtitle2">{projectTitle}</Typography>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default TotalCountCircle;
