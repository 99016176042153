import * as React from 'react';
import { Typography, Box } from '@mui/material';


export default function NoRecordFound({ data }) {

    if (data && data.length === 0) {
        return (
            <Box sx={{ mt: 2, width: "100%" }} display={"flex"} alignItems={"flex-start"} justifyContent={"center"}>
                <Typography variant="subtitle2" textAlign={"center"}>No data found</Typography>
            </Box>
        )
    }
    return null;
}
