const AdmissionsSubSvg = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_876_398)">
        <path
          d="M16.5464 8.08691H13.0147C12.8631 6.41327 11.6409 5.04396 10.0402 4.67159C10.4951 4.25362 10.7808 3.65424 10.7808 2.98926C10.7808 1.72922 9.75564 0.704102 8.49561 0.704102C7.23557 0.704102 6.21045 1.72922 6.21045 2.98926C6.21045 3.6477 6.49071 4.24159 6.9378 4.65897C5.30915 5.0118 4.05963 6.39347 3.90631 8.08691H0.374512C0.180344 8.08691 0.0229492 8.24431 0.0229492 8.43848V10.1963C0.0229492 10.3904 0.180344 10.5479 0.374512 10.5479H2.20264V17.2275C2.20264 17.4217 2.36003 17.5791 2.5542 17.5791H14.437C14.6311 17.5791 14.7886 17.4217 14.7886 17.2275V10.5479H16.5464C16.7405 10.5479 16.8979 10.3904 16.8979 10.1963V8.43848C16.8979 8.24431 16.7405 8.08691 16.5464 8.08691ZM8.49561 1.40723C9.36794 1.40723 10.0776 2.11693 10.0776 2.98926C10.0776 3.86159 9.36794 4.57129 8.49561 4.57129C7.62327 4.57129 6.91357 3.86159 6.91357 2.98926C6.91357 2.11693 7.62327 1.40723 8.49561 1.40723ZM7.75732 5.27441H9.16357C10.7894 5.27441 12.1328 6.5071 12.3082 8.08691H10.9214V7.03223C10.9214 6.83806 10.764 6.68066 10.5698 6.68066C10.3757 6.68066 10.2183 6.83806 10.2183 7.03223V8.08691H6.70264V7.03223C6.70264 6.83806 6.54524 6.68066 6.35107 6.68066C6.15691 6.68066 5.99951 6.83806 5.99951 7.03223V8.08691H4.61267C4.78813 6.5071 6.13149 5.27441 7.75732 5.27441ZM14.0854 16.876H2.90576V10.5479H14.0854V16.876ZM16.1948 9.84473C15.4758 9.84473 1.45247 9.84473 0.726074 9.84473V8.79004H16.1948V9.84473Z"
          fill="currentColor"
        />
        <path
          d="M7.51123 14.6611V15.1182C7.51123 15.3123 7.68037 15.4697 7.8745 15.4697H9.04636C9.2405 15.4697 9.40963 15.3123 9.40963 15.1182V14.6611H9.86666C10.0608 14.6611 10.2182 14.492 10.2182 14.2979V13.126C10.2182 12.9318 10.0608 12.7627 9.86666 12.7627H9.40963V12.3057C9.40963 12.1115 9.2405 11.9541 9.04636 11.9541H7.8745C7.68033 11.9541 7.51123 12.1115 7.51123 12.3057V12.7627H7.0542C6.86003 12.7627 6.70264 12.9318 6.70264 13.126V14.2979C6.70264 14.492 6.86003 14.6611 7.0542 14.6611H7.51123ZM7.40576 13.4658H7.8745C8.06867 13.4658 8.21436 13.3201 8.21436 13.126V12.6572H8.70654V13.126C8.70654 13.3201 8.85227 13.4658 9.0464 13.4658H9.51514V13.958H9.0464C8.85227 13.958 8.70654 14.1037 8.70654 14.2979V14.7666H8.21436V14.2979C8.21436 14.1037 8.06867 13.958 7.8745 13.958H7.40576V13.4658Z"
          fill="currentColor"
        />
        <path
          d="M8.46045 16.5244C10.0113 16.5244 11.2729 15.2627 11.2729 13.7119C11.2729 12.1611 10.0113 10.8994 8.46045 10.8994C6.90964 10.8994 5.64795 12.1611 5.64795 13.7119C5.64795 15.2627 6.90964 16.5244 8.46045 16.5244ZM8.46045 11.6025C9.62356 11.6025 10.5698 12.5488 10.5698 13.7119C10.5698 14.875 9.62356 15.8213 8.46045 15.8213C7.29734 15.8213 6.35107 14.875 6.35107 13.7119C6.35107 12.5488 7.29734 11.6025 8.46045 11.6025Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_876_398">
          <rect width="17" height="18" fill="white" transform="translate(0 0.000976562)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AdmissionsSubSvg;
