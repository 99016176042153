import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { visuallyHidden } from '@mui/utils';

export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

export function EnhancedTableHead(props) {
    const {
        order,
        orderBy,
        onRequestSort,
        type,
        getFacilityHeadLabel
    } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    const headCells = [
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Name",
            isSort: false
        },
        { id: "total", numeric: true, disablePadding: false, label: "Total", isSort: true },
        {
            ...type !== "overall" &&
            {
                id: "facilityPercentage",
                numeric: true,
                disablePadding: false,
                label: "% OF facility",
                isSort: true
            }
        }
    ];
    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        align="center"
                        sx={{ textTransform: "uppercase" }}
                    >
                        {headCell.isSort ?
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell?.id === "facilityPercentage" ? getFacilityHeadLabel() :headCell.label}
                                {orderBy === headCell.id ? (
                                    <span style={visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                            :
                            headCell.label
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}