import { getValidationsData } from "./api/validation.api";

const getValidations = async (data) => {
    try {
        const res = await getValidationsData(data);
        return res;
    } catch (e) {
        console.log(e);
    }
};


export {
    getValidations,
}
