import * as React from "react";
import {
	Backdrop,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	IconButton,
	MenuItem,
	Select,
	Switch,
	ToggleButton,
	ToggleButtonGroup,
	Tooltip,
} from "@mui/material";
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { ADD_NOTIFICATION } from "../../../store/types";
import { createAlertsReport, getAlertsReports } from "../../../services/alert-report.service";
import { LoadingButton } from "@mui/lab";
import { ALERT_TYPE_TYPE } from "../../../types/common.type";
import { HOSPITAL_CARDS_LABELS } from "../../../types/hospital.type";

export const DEFAULT_CARD_FILTER = {
	total: false,
	unplannedHospitalTransfer: false,
	plannedHospitalTransfer: false,
	hospitalizations: false,
	DCERData: false,
	insuranceData: false,
	returnsData: false,
	ninetyDaysData: false,
	doctorData: false,
	nurseData: false,
	floorsData: false,
	daysData: false,
	dxData: false,
	shiftData: false,
	hospitalData: false
};

const FILTER_KEYS = Object.keys(DEFAULT_CARD_FILTER);

export default function AlertReport({
	page,
	activeFacilities,
	type,
	accountFacilities,
	isAccountChecked,
	isChecked,
	selectedAccount,
	isOpenAlertReport,
	dialogType = "default",
	setIsAlertReportOpen,
	selectedSortCut,
	userId
}) {
	const [isOpen, setIsOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [initialValues, setInitialValues] = React.useState({
		filters: FILTER_KEYS.reduce((acc, key) => ({ ...acc, [key]: false }), {}),
		alertType: [ALERT_TYPE_TYPE.WEEKS],
		isRising: false,
		isDropping: false,
		isTransferNeedWork: false,
		selectAll: false,
	});
	const [facilitiesList, setFacilitiesList] = React.useState([]);
	const dispatch = useDispatch();

	const handleClose = React.useCallback(() => {
		setIsOpen(false);
		setIsAlertReportOpen && setIsAlertReportOpen(false);
	}, [setIsAlertReportOpen]);

	React.useEffect(() => {
		if (isOpenAlertReport) {
			setIsOpen(true);
		}
	}, [isOpenAlertReport]);

	React.useMemo(() => {
		if (accountFacilities && type === "userPage") {
			const selectedFacilities = accountFacilities.flatMap((item) => {
				if (isAccountChecked("access", item) && selectedAccount?._id === item._id?._id) {
					return item.facilities?.filter((facility) => isChecked(facility._id, "access")) || [];
				}
				return [];
			});
			setFacilitiesList(selectedFacilities);
		}
	}, [accountFacilities, isAccountChecked, isChecked, type, selectedAccount]);

	const getAlertsReportsDB = React.useCallback(async (facilityId = null) => {
		setLoading(true);
		try {
			let userIdData = type === "userPage" ? userId : null;
			const res = await getAlertsReports({ page, facilityId, userId: userIdData });
			if (res) {
				const databaseFilters = res?.alerts || {};
				const updatedInitialValues = FILTER_KEYS.reduce((acc, key) => {
					acc[key] = databaseFilters[key] !== undefined ? databaseFilters[key] : false;
					return acc;
				}, {});

				setInitialValues({
					filters: updatedInitialValues,
					alertType: res?.type,
					isRising: res?.isRising,
					isDropping: res?.isDropping,
					isTransferNeedWork: res?.isTransferNeedWork,
					type: res?.type,
					selectAll: Object.values(updatedInitialValues).every(value => value === true),
					...type === "userPage" ? { facilityId: [res?.facilityId] } : {},
				});
			} else {
				setInitialValues({
					filters: FILTER_KEYS.reduce((acc, key) => ({ ...acc, [key]: false }), {}),
					alertType: [ALERT_TYPE_TYPE.WEEKS],
					isRising: false,
					isDropping: false,
					isTransferNeedWork: false,
					selectAll: false,
					...type === "userPage" ? { facilityId: [facilityId] } : {},
				});
			}
		} catch (error) {
			dispatch({
				type: ADD_NOTIFICATION,
				payload: {
					type: "error",
					label: "Failed to load alert settings",
					id: "loadSettingsError",
				},
			});
		} finally {
			setLoading(false);
		}
	}, [type, userId, page, dispatch]);

	React.useEffect(() => {
		if (isOpen) {
			getAlertsReportsDB(activeFacilities?.[0]);
		}
	}, [isOpen, getAlertsReportsDB, activeFacilities]);

	const handleSave = React.useCallback(
		async (valuesData) => {
			try {
				if (type === "userPage" && valuesData?.facilityId?.length === 0) {
					dispatch({
						type: ADD_NOTIFICATION,
						payload: {
							type: "error",
							label: "Please select at least one facility",
							id: "alertSettingsSaveError",
						},
					});
					return;
				}
				let userIdData = type === "userPage" ? userId : null;

				const updateData = {
					alerts: valuesData?.filters,
					page,
					facilityIds: type === "userPage" ? valuesData?.facilityId : activeFacilities,
					type: valuesData?.alertType,
					isRising: valuesData?.isRising,
					isDropping: valuesData?.isDropping,
					isTransferNeedWork: valuesData?.isTransferNeedWork,
					...userId && { userId : userIdData }
				};
				const res = await createAlertsReport(updateData);
				if (res) {
					dispatch({
						type: ADD_NOTIFICATION,
						payload: {
							type: "success",
							label: "Alert settings updated successfully",
							id: "alertSettingsUpdated",
						},
					});
					setIsAlertReportOpen && setIsAlertReportOpen(false);
					setIsOpen(false);
				}
			} catch (error) {
				dispatch({
					type: ADD_NOTIFICATION,
					payload: {
						type: "error",
						label: "Failed to save alert settings",
						id: "alertSettingsSaveError",
					},
				});
				setIsAlertReportOpen && setIsAlertReportOpen(false);
				setIsOpen(false);
			}
		},
		[page, type, activeFacilities, dispatch, userId] // eslint-disable-line react-hooks/exhaustive-deps
	);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			...initialValues
		},
		onSubmit: (values, { setSubmitting }) => {
			handleSave(values);
			setSubmitting(false);
		},
	});

	const handleSelectAll = React.useCallback((event) => {
		const isChecked = event.target.checked;
		formik.setFieldValue('selectAll', isChecked);
		FILTER_KEYS.forEach((key) => {
			formik.setFieldValue(`filters.${key}`, isChecked);
		});
	}, [formik]);

	const formatLabel = React.useCallback((key) => {
		return HOSPITAL_CARDS_LABELS[key];
	}, []);

	const handleOnChangeFacility = React.useCallback((e) => {
		if (e.target.value && e.target.value.length === 1) {
			getAlertsReportsDB(e.target.value[0]);
		}
	}, [getAlertsReportsDB]);

	// Extracted form controls for cleaner JSX
	const AlertTypeSelect = ({ formik }) => {
		return (
			<FormControl fullWidth sx={{ mb: 2 }}>
				<FormLabel id="alert-type-label">Alert Type</FormLabel>
				<ToggleButtonGroup
					value={formik.values.alertType}
					onChange={(event, newAlertType) => formik.setFieldValue("alertType", newAlertType)}
					aria-labelledby="alert-type-label"
					color="primary"
					sx={{ display: 'flex' }}
					size="large"
				>
					<ToggleButton sx={{ padding: '8px 16px' }} value={ALERT_TYPE_TYPE.WEEKS}>Weekly</ToggleButton>
					<ToggleButton sx={{ padding: '8px 16px' }} value={ALERT_TYPE_TYPE.BI_WEEKS}>Bi-Weekly</ToggleButton>
					<ToggleButton sx={{ padding: '8px 16px' }} value={ALERT_TYPE_TYPE.MONTHS}>Monthly</ToggleButton>
				</ToggleButtonGroup>
			</FormControl>
		);
	};

	const renderSwitchesGrid = () => (
		<Grid container spacing={2}>
			{FILTER_KEYS.map((key) => (
				<Grid item xs={6} sm={4} md={3} key={key}>
					<FormControlLabel
						control={
							<Switch
								name={`filters.${key}`}
								checked={formik.values.filters[key]}
								onChange={formik.handleChange}
							/>
						}
						label={formatLabel(key)}
					/>
				</Grid>
			))}
		</Grid>
	);

	const renderContent = () => (
		<form onSubmit={formik.handleSubmit}>
			<FormGroup>
				{type === "userPage" && (
					<FormControl fullWidth sx={{ mb: 2 }}>
						<FormLabel id="alert-type-label">Facility</FormLabel>
						<Select
							labelId="alert-type-label"
							name="facilityId"
							value={formik.values.facilityId || []}
							onChange={(e) => {
								const { value } = e.target;
								const allIds = facilitiesList.map((item) => item._id);

								if (value.includes("all")) {
									// If "Select All" is clicked
									formik.setFieldValue(
										"facilityId",
										formik.values?.facilityId?.length === facilitiesList?.length ? [] : allIds
									);
									handleOnChangeFacility(allIds)
								} else {
									// If individual items are clicked
									formik.setFieldValue("facilityId", value);
									handleOnChangeFacility(value)
								}
							}}
							multiple
							renderValue={(selected) =>
								facilitiesList
									.filter((item) => selected.includes(item._id))
									.map((item) => item.name)
									.join(", ")
							}
						>
							{/* Select All Option */}
							<MenuItem value="all">
								<Checkbox
									checked={formik.values?.facilityId?.length === facilitiesList?.length}
									indeterminate={
										formik.values?.facilityId?.length > 0 &&
										formik.values.facilityId?.length < facilitiesList?.length
									}
								/>
								Select All
							</MenuItem>

							{/* Individual Facility Options */}
							{facilitiesList?.map((item) => (
								<MenuItem key={item._id} value={item._id}>
									<Checkbox checked={formik.values?.facilityId?.includes(item._id)} />
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}

				<AlertTypeSelect formik={formik} />

				<Box display="flex" justifyContent="flex-start" alignItems="center" sx={{ mb: 2, ml: 0.2 }}>
					<FormControlLabel
						control={
							<Checkbox
								name="isRising"
								checked={formik.values.isRising}
								onChange={formik.handleChange}
							/>
						}
						label="Are Rising"
					/>
					<FormControlLabel
						control={
							<Checkbox
								name="isDropping"
								checked={formik.values.isDropping}
								onChange={formik.handleChange}
							/>
						}
						label="Are Dropping"
					/>
					<FormControlLabel
						control={
							<Checkbox
								name="isTransferNeedWork"
								checked={formik.values.isTransferNeedWork}
								onChange={formik.handleChange}
							/>
						}
						label="Transfers Need Work"
					/>
				</Box>

				<FormControlLabel
					sx={{ mb: 2 }}
					control={
						<Switch
							checked={formik.values.selectAll}
							onChange={handleSelectAll}
						/>
					}
					label="Select all alerts"
				/>
			</FormGroup>
			{renderSwitchesGrid()}
		</form>
	);


	return (
		<>
			{(selectedSortCut?.isAlertsReport || type === "userPage") && (
				<Box>
					<Tooltip title="Manage Alerts" arrow>
						<IconButton
							onClick={() => setIsOpen(true)}
							sx={{
								width: 30,
								height: 30,
								ml: 1,
								borderRadius: "4px",
								'&:hover': {
									backgroundColor: '#4879f5',
									color: "white",
								},
							}}
						>
							<NotificationsActiveOutlinedIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</Box>
			)}
			{isOpen && (
				<Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
					<DialogTitle>Hospital Alerts</DialogTitle>

					<DialogContent>
						{loading && (
							<Backdrop open={true} style={{
								position: 'absolute', top: 0, left: 0, zIndex: 5,
								backgroundColor: 'rgba(255, 255, 255, 0.2)', // Slightly translucent
								backdropFilter: 'blur(0.5px)', // Applies a blur effect
							}}>
								<CircularProgress color="primary" />
							</Backdrop>
						)}
						{renderContent()}
					</DialogContent>
					<DialogActions>
						<Box flex="1" display="flex" justifyContent="flex-end">
							<Button onClick={handleClose} variant="outlined">Close</Button>
							<LoadingButton
								type="submit"
								variant="contained"
								color="primary"
								onClick={formik.submitForm}
								sx={{ marginLeft: 2 }}
								loading={formik.isSubmitting}
							>
								Save
							</LoadingButton>
						</Box>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
}
