import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";
import { Stack } from '@mui/material';
import { ShowForPermission } from './ShowForPermission';

export default function NoFacilitySelectionDialog({ handleClose }) {
    const navigate = useNavigate();

    return (
        <div>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="overlap-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="overlap-dialog-title">
                    {"No facility chosen"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {("Please select at least  one facility or click `Create new facility` button and create new facility.")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" spacing={2} sx={{ mb: 2, mr: 2 }}>
                        <Button onClick={handleClose}>Cancel</Button>
                        <ShowForPermission type="addFacility">
                            <Button
                                variant="contained"
                                onClick={() => {
                                    handleClose()
                                    navigate("/settings/facilities")
                                }}>
                                Create new facility
                            </Button>
                        </ShowForPermission>
                    </Stack>
                </DialogActions>
            </Dialog>
        </div>
    );
}
