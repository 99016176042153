import * as React from 'react';
import { DialogTitle } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

export default function ChartDialogTitle({ handleClose }) {
    return (
        <DialogTitle sx={{ m: 0, p: 0 }}>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                    cursor: "pointer",
                    zIndex: 1,
                }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    );
}
