import { Typography } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { calcProPercentsBasedOnFilterAndDays, itemPercentage, percentageLabel } from "../../../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../../utilis/percentage-comparison";
import CardItemTooltip from "../../../shared/CardItemTooltip";
import ColorBox from "../../../shared/dashboard/ColorBox";
import NoRecordFound from "../../../shared/NoRecordFound";
import CheckboxButton from "../../shared/checkboxButton/CheckboxButton";
import styles from "./ReturnCardList.module.scss";
import ReturnCardListSkeleton from "./ReturnCardListSkeleton";

const ReturnCardList = ({
	data,
	comparisonData,
	transfers,
	transfersComparison,
	handleToggle,
	selectedItem = [],
	filter,
	filterComparison,
	type,
	page,
	cardTitle,
	loading,
	projectionDays,
	priorityNumber,
	transferType,
	isComparingAgainstAvgCensus,
	lockedTotalBy,
}) => {
	const [chartData, setChartData] = useState([]);
	const [returnedComparisonDiffColor, setReturnedComparisonDiffColor] = useState("");
	const [didntReturnComparisonDiffColor, setDidntReturnComparisonDiffColor] = useState("");
	const [returnedComparingAgainstScaled, setReturnedComparingAgainstScaled] = useState(null);
	const [didntReturnComparingAgainstScaled, setDidntReturnComparingAgainstScaled] = useState(null);
	const [ecItemComparison, setEcItemComparison] = useState(null);
	const [dcItemComparison, setDcItemComparison] = useState(null);
	const [numberOfDaysReturned, setNumberOfDaysReturned] = useState(null);
	const [numberOfDaysDidntReturn, setNumberOfDaysDidntReturn] = useState(null);
	const percentageThresholds = useSelector((state) => state.comparison.percentageThresholds);
	const isFirstItemInPriorityData = priorityNumber === 1;

	const handleToggleModified = (item, type, itemComparison, isChecked) => {
		handleToggle({ item, type, itemComparison, setSpecialNumberFlag: false, isChecked, cardTitle });
	};

	useEffect(() => {
		if (data && data.length > 0) {
			const dcItem = _.find(data, { _id: "Returned" });
			const ecItem = _.find(data, { _id: "Didn't Return" });
			const dcItemComparison = _.find(comparisonData, { _id: "Returned" });
			const ecItemComparison = _.find(comparisonData, { _id: "Didn't Return" });
			setEcItemComparison(ecItemComparison);
			setDcItemComparison(dcItemComparison);

			const chartDetails = [
				{
					id: "Returned",
					label: "Returned",
					value: calcProPercentsBasedOnFilterAndDays(dcItem?.total || 0, filter, projectionDays),
					total: calcProPercentsBasedOnFilterAndDays(dcItem?.total || 0, filter, projectionDays),
					_id: "Returned",
					percentage: calcProPercentsBasedOnFilterAndDays(
						dcItem?.percentage ? dcItem.percentage : itemPercentage(dcItem?.total, null, "number") || 0,
						filter,
						projectionDays,
						true,
						isComparingAgainstAvgCensus ||
							lockedTotalBy === "census" ||
							(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
					),
					...dcItem,
				},
				{
					id: "Didn't Return",
					label: "Didn't Return",
					value: calcProPercentsBasedOnFilterAndDays(ecItem?.total || 0, filter, projectionDays),
					total: calcProPercentsBasedOnFilterAndDays(ecItem?.total || 0, filter, projectionDays),
					_id: "Didn't Return",
					percentage: calcProPercentsBasedOnFilterAndDays(
						ecItem?.percentage ? ecItem.percentage : itemPercentage(ecItem?.total, null, "number") || 0,
						filter,
						projectionDays,
						true,
						isComparingAgainstAvgCensus ||
							lockedTotalBy === "census" ||
							(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
					),
					...ecItem,
				},
			];
			const percentageReturn = itemPercentage(dcItem?.total, transfers, "number");
			const percentageDidntReturn = itemPercentage(ecItem?.total, transfers, "number");

			if (comparisonData) {
				const percentageReturnComparison = itemPercentage(dcItemComparison?.total, transfersComparison, "number");
				const percentageDidntReturnComparison = itemPercentage(ecItemComparison?.total, transfersComparison, "number");

				const {
					percentageDiff: percentageDiffDidntReturn,
					itemTotalComparisonScaled: itemTotalComparisonScaledDidntReturn,
					numberOfDays: numberOfDaysDidntReturnedTemp,
				} = calculateDiffBetweenPercentages(
					percentageDidntReturn,
					percentageDidntReturnComparison,
					false,
					filter,
					filterComparison,
					ecItemComparison?.total,
					projectionDays
				);
				const {
					percentageDiff: percentageDiffReturned,
					itemTotalComparisonScaled: itemTotalComparisonScaledReturned,
					numberOfDays: numberOfDaysReturnedTemp,
				} = calculateDiffBetweenPercentages(
					percentageReturn,
					percentageReturnComparison,
					false,
					filter,
					filterComparison,
					dcItemComparison?.total,
					projectionDays
				);

				const comparisonReturnColor = pickComparisonColor(percentageDiffReturned);
				const comparisonDidntReturnColor = pickComparisonColor(percentageDiffDidntReturn);

				setNumberOfDaysReturned(numberOfDaysReturnedTemp);
				setNumberOfDaysDidntReturn(numberOfDaysDidntReturnedTemp);
				setReturnedComparingAgainstScaled(itemTotalComparisonScaledReturned);
				setDidntReturnComparingAgainstScaled(itemTotalComparisonScaledDidntReturn);
				setReturnedComparisonDiffColor(comparisonReturnColor);
				setDidntReturnComparisonDiffColor(comparisonDidntReturnColor);
			}
			setChartData(chartDetails);
		} else {
			const chartDetails = [
				{
					id: "Returns",
					value: 0,
					total: 0,
					_id: "Returned",
					percentage: 0,
				},
				{
					id: "Didn't Return",
					value: 0,
					total: 0,
					_id: "Didn't Return",
					percentage: 0,
				},
			];
			setChartData(chartDetails);
		}
	}, [
		data,
		comparisonData,
		transfers,
		transfersComparison,
		page,
		percentageThresholds,
		projectionDays,
		filter,
		filterComparison,
		transferType,
		isComparingAgainstAvgCensus,
		lockedTotalBy,
		isFirstItemInPriorityData,
	]);

	return (
		<div className={`w100 ${styles.didNotReturn}`}>
			{loading ? (
				<ReturnCardListSkeleton />
			) : (
				<>
					<div className={`df aic m-t-15 ${styles.box}`}>
						{chartData &&
							chartData.length > 0 &&
							_.orderBy(chartData, "label", "desc").map((item) => {
								const ID = item._id;
								const labelId = `checkbox-list-return-label-${ID}`;
								const selected = selectedItem.indexOf(ID) !== -1;

								return (
									<div
										key={ID}
										className={`df ${styles.sec} cp ${selected ? styles.selected : null}`}
										onClick={() => {
											handleToggleModified(item, type, undefined, selected);
										}}
									>
										<CheckboxButton
											labelId={labelId}
											handleToggle={() => handleToggleModified(item, type, undefined, selected)}
											checked={selected}
											colorBox={
												!loading && (
													<ColorBox
														color={
															ID === "Didn't Return" ? didntReturnComparisonDiffColor : returnedComparisonDiffColor
														}
														type="list"
														sx={{ margin: "5px 2px 0px 2px" }}
														comparingAgainst={
															ID === "Didn't Return" ? ecItemComparison?.total : dcItemComparison?.total
														}
														comparingAgainstScaled={
															ID === "Didn't Return"
																? didntReturnComparingAgainstScaled
																: returnedComparingAgainstScaled
														}
														numberOfDays={ID === "Didn't Return" ? numberOfDaysDidntReturn : numberOfDaysReturned}
													/>
												)
											}
											buttonText={
												<>
													<CardItemTooltip item={item}>
														<Typography
															className={styles.secButtonText}
															variant="subtitle2"
															sx={{ display: "flex", mt: "2px" }}
															component="div"
														>
															<div
																className={styles.labelCircle}
																style={{
																	backgroundColor: item._id === "Returned" ? "#2259C9" : "#00BAEB",
																}}
															/>
															{`${item._id}: ${calcProPercentsBasedOnFilterAndDays(
																item.total,
																filter,
																projectionDays
															)} ${percentageLabel(
																item.percentage
																	? calcProPercentsBasedOnFilterAndDays(
																			item.percentage,
																			filter,
																			projectionDays,
																			true,
																			isComparingAgainstAvgCensus ||
																				lockedTotalBy === "census" ||
																				(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
																	  )
																	: calcProPercentsBasedOnFilterAndDays(
																			itemPercentage(item.total, null, "number", page),
																			filter,
																			projectionDays,
																			true,
																			isComparingAgainstAvgCensus ||
																				lockedTotalBy === "census" ||
																				(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
																	  )
															)}`}
														</Typography>
													</CardItemTooltip>
												</>
											}
											disableRipple={true}
											sx={{ width: "20px" }}
											textWrapColorClass={item.textWrapColor}
										/>
									</div>
								);
							})}
					</div>
					<NoRecordFound data={data} />
					<div className={`m-t-4 m-b-8`} style={{ height: "276px", width: "100%", padding: "0px" }}>
						<ResponsivePie
							colors={["#2259C9", "#00BAEB"]}
							data={chartData}
							margin={{ top: 8, right: 8, bottom: 8, left: 8 }}
							padAngle={0}
							cornerRadius={0}
							activeOuterRadiusOffset={5}
							enableArcLinkLabels={false}
							innerRadius={0}
							arcLabelsTextColor="#fff"
							arcLabel={(e) => {
								return e.value + " (" + e.data?.percentage + "%)";
							}}
						/>
					</div>
				</>
			)}
		</div>
	);
};

export default ReturnCardList;
