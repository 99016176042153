// types
import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_FILTER } from "../../../data/common.data";

const DEFAULT_CO_DB_DATA = {
	totalAMA: 0,
	totalSNF: 0,
	totalSafeDischarge: 0,
	total: 0,
	censusAverage: 0,
};
export const DEFAULT_COMMUNITY_DATA = {
	insuranceData: [],
	sixtyDaysData: [],
	returnsData: [],
	doctorData: [],
	floorsData: [],
	safeDischargeAssLivData: [],
	snfFacilityData: [],
	priorityData: [],
};

// initial state
const initialState = {
	transferType: [],
	mainTotal: 0,
	selectedFilter: null,
	detailsDialog: {
		isOpen: false,
		type: null,
		item: null,
	},
	filter: DEFAULT_FILTER,
	cardFilter: DEFAULT_COMMUNITY_DATA,
	dbData: DEFAULT_CO_DB_DATA,
	isResetFilter: false,
	filterTotal: null,
	lockedTotal: null,
	mainNumPercentage: null,
	filterListData: null,
	patientList: [],
	specialNumber: null,
	specialComparisonSet: false,
	isCensusTotalLocked: false,
	facilityPercentage: [],
	lockedByFacility: [],
	lockedTotalBy: null,
};

// ==============================|| SLICE - ADMISSIONS ||============================== //

const communityTransferComparison = createSlice({
	name: "communityTransferComparison",
	initialState,
	reducers: {
		setIsMainCensusPercentage(state, action) {
			state.mainNumPercentage = action.payload;
		},
		setLockTotal(state, action) {
			state.lockedTotal = action.payload;
		},
		setDefaultCommunityTransferData(state, action) {
			state.transferType = [];
			state.cardFilter = DEFAULT_COMMUNITY_DATA;
			state.mainTotal = 0;
			state.filter = DEFAULT_FILTER;
			state.dbData = DEFAULT_CO_DB_DATA;
		},
		setTransferType(state, action) {
			state.transferType = action.payload;
		},

		setMainTotal(state, action) {
			state.mainTotal = action.payload;
		},

		openDetailsDialog(state, action) {
			state.detailsDialog = { ...action.payload };
		},

		closeDetailsDialog(state, action) {
			state.detailsDialog = {
				isOpen: false,
				type: null,
				item: null,
			};
		},

		setFilterDateRange(state, action) {
			state.filter = { ...state.filter, ...action.payload };
		},

		setFilterDBData(state, action) {
			state.dbData = { ...state.dbData, ...action.payload };
		},

		setCardFilterCommunity(state, action) {
			state.cardFilter = {
				...state.cardFilter,
				...action.payload,
			};
		},
		setResetFilter(state, action) {
			state.isResetFilter = action.payload;
		},
		setFilterListDataCommunity(state, action) {
			state.filterListData = action.payload;
		},
		setSelectedFilter(state, action) {
			if (action.payload) {
				// eslint-disable-next-line eqeqeq
				if (action.payload != "all") {
					const { filter } = action.payload;
					if (filter && filter.cardFilter) {
						state.cardFilter = {
							...DEFAULT_COMMUNITY_DATA,
							...filter.cardFilter,
						};
					}
					if (filter && filter.transferType) {
						state.transferType = filter.transferType;
					} else {
						state.transferType = [];
					}
					if (filter && filter.filter) {
						state.filter = filter.filter;
					}
					state.selectedFilter = action.payload._id;
				} else {
					state.selectedFilter = action.payload;
					state.cardFilter = DEFAULT_COMMUNITY_DATA;
					state.filter = { ...state.filter };
					state.transferType = [];
					state.isResetFilter = true;
					state.lockedTotal = null;
				}
			}
		},
		setFilterTotal(state, action) {
			state.filterTotal = action.payload;
		},
		setPatientList(state, action) {
			state.patientList = action.payload;
		},
		setSpecialNumber(state, action) {
			state.specialNumber = action.payload;
		},
		setIsSpecialComparison(state, action) {
			state.specialComparisonSet = action.payload;
		},
		setIsCensusTotalLocked(state, action) {
			state.isCensusTotalLocked = action.payload;
		},
		setLockedTotalBy(state, action) {
			state.lockedTotalBy = action.payload;
		},
		setLockedByFacility(state, action) {
			state.lockedByFacility = action.payload;
		},
		setFacilityPercentage(state, action) {
			state.facilityPercentage = action.payload;
		},
	},
});

export default communityTransferComparison.reducer;

export const {
	setIsMainCensusPercentage,
	setLockTotal,
	setFilterTotal,
	setResetFilter,
	setTransferType,
	openDetailsDialog,
	closeDetailsDialog,
	setFilterDateRange,
	setFilterDBData,
	setMainTotal,
	setCardFilterCommunity,
	setSelectedFilter,
	setDefaultCommunityTransferData,
	setFilterListDataCommunity,
	setPatientList,
	setSpecialNumber,
	setIsSpecialComparison,
	setIsCensusTotalLocked,
	setLockedTotalBy,
	setLockedByFacility,
	setFacilityPercentage,
} = communityTransferComparison.actions;
