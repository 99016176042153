// @mui
import Popover from '@mui/material/Popover';
import { getPosition } from './utils';
import { StyledArrow } from './styles';

// ----------------------------------------------------------------------

export default function CustomPopover({
	open,
	children,
	arrow = 'top-right',
	hiddenArrow,
	sx,
	...other
}) {
	const { anchorOrigin, transformOrigin } = getPosition(arrow);

	return (
		<Popover
			open={Boolean(open)}
			anchorEl={open}
			anchorOrigin={anchorOrigin}
			transformOrigin={transformOrigin}			
			{...other}
		>
			{!hiddenArrow && <StyledArrow arrow={arrow} />}

			{children}
		</Popover>
	);
}
