import * as React from "react";
import styles from "../ChartDialogCommon.module.scss";
import { Stack } from "@mui/system";
import { getDynamicPercentageBy, itemPercentage } from "../../../../utilis/common";
import { Button } from "@mui/material";
import { CHART_POINT_TYPE } from "../../../../types/chart-dialog.type";
import _ from "lodash";
import { useSelector } from "react-redux";

export default function ChartTotalContainer({
    total,
    censusAverage,
    title,
    subtitle,
    handleChartLabelType,
    isChartTotalButton,
    chartPointType,
    isTotalText = true,
    dbData
}) {
    const { percentageBy } = useSelector((state) => state.common);
    const percentageAgainst = React.useMemo(() => {
		return getDynamicPercentageBy(dbData, percentageBy);
	}, [percentageBy, dbData]);
    return (
        <>
            {isTotalText && (
                <Stack direction={"row"} className={styles.totalContainer}>
                    {isChartTotalButton && (
                        <Stack direction={"row"} spacing={1} justifyContent="flex-end" sx={{ ml: 1 }}>
                            <Button
                                size="small"
                                variant={chartPointType === CHART_POINT_TYPE.TOTAL ? "contained" : "outlined"}
                                onClick={() => handleChartLabelType(CHART_POINT_TYPE.TOTAL)}
                            >
                                Total
                            </Button>
                            <Button
                                size="small"
                                variant={chartPointType === CHART_POINT_TYPE.PERCENTAGE ? "contained" : "outlined"}
                                onClick={() => handleChartLabelType(CHART_POINT_TYPE.PERCENTAGE)}
                            >
                                Percentage
                            </Button>
                        </Stack>
                    )}
                    <Stack direction={"column"} alignItems="center" justifyItems={"center"}>
                        <div className={styles.totalCount}>{total}</div>
                        <div className={styles.totalText}>{title}</div>
                    </Stack>
                    {!_.isEmpty(censusAverage) && censusAverage.length > 0 && (
                        <Stack direction={"column"}>
                            <div className={styles.totalCount}>
                                {itemPercentage(total, percentageAgainst, "percentage")}
                            </div>
                            <div className={styles.totalText}>{percentageBy}</div>
                        </Stack>
                    )}
                </Stack>
            )}
        </>
    );
}
