/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Grid, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeDetailsDialog } from "../../../store/reducers/deceased.slice";
import { cardPatientAndChartData } from "../../../services/deceased.service";
import _ from "lodash";
import CardDetailChart from "../../shared/chart-dialog-common/chart-tab/CardDetailChart";
import {
	chartFilterPermission,
	chartGroupBy,
	chartsData,
	dayDifferent,
	getDeceasedFieldNames,
	isUpdateDefaultFilter,
} from "../../../utilis/charts-common";
import ChartDialogLeftSidebar from "../../shared/chart-dialog-common/ChartDialogLeftSidebar";
import ChartDetailsTab from "../../shared/chart-dialog-common/ChartDetailsTab";
import ChartTabLoader from "../../shared/chart-dialog-common/ChartTabLoader";
import ChartDialogContainer from "../../shared/chart-dialog-common/ChartDialogContainer";
import ChartTableList from "../../shared/chart-dialog-common/chart-table/ChartTableList";
import { DECEASED_CARDS_TYPE } from "../../../types/deceased.type";
import { DEFAULT_CHART_DATA, DEFAULT_CHART_FILTER } from "../../../data/common.data";
import { CHART_FILTER_DAY_OF, DASHBOARD_FILTER_TYPE, FILTER_TYPES } from "../../../types/common.type";
import { CHART_POINT_TYPE, CHART_TAB_BUTTON } from "../../../types/chart-dialog.type";
import ChartBuildingList from "../../shared/chart-dialog-common/chart-building/ChartBuildingList";
import { getAccountQuestions } from "../../../services/api/user.api";
import { getChartFacilityPercentageBy, patientDataOrderBy } from "../../../utilis/common";
import { PAGE_TYPE } from "../../../types/pages.type";
import ChartDetailsSearchBar, { tableDefaultQuestions } from "../../shared/chart-dialog-common/ChartDetailsSearchBar";

export default function DeceasedCardDetailsDialog({ handleOnClickReport, handleGenerateExcelReport }) {
	const [selectedItem, setSelectedItem] = useState([]);
	const [loading, setLoading] = useState(false);
	const [buttonFilterType, setButtonFilterType] = useState(CHART_FILTER_DAY_OF.DAY);
	const [censusAverage, setCensusAverage] = useState([]);
	const [censusByPeriod, setCensusByPeriod] = useState([]);
	const [isShowPercentage, setIsShowPercentage] = useState(false);
	const [queryFilters, setQueryFilters] = useState(DEFAULT_CHART_FILTER);
	const [selectedTab, setSelectedTab] = useState(CHART_TAB_BUTTON.TABLE);
	const [filterData, setFilterData] = useState([]);
	const [chartData, setChartData] = useState(DEFAULT_CHART_DATA);
	const dispatch = useDispatch();
	const [censusByFacility, setCensusByFacility] = useState([]);
	const [selectedFilter, setSelectedFilter] = useState(FILTER_TYPES.DAILY);
	const {
		detailsDialog: { isOpen, title, data, filters, dbData },
	} = useSelector((state) => state.deceased);
	const tableElementRef = useRef();
	const buildingElementRef = useRef();
	const { rangesSet } = useSelector((state) => state.comparison);

	// COMPARISON STATES ---------->
	const {
		detailsDialog: {
			isOpen: isOpenComparison,
			title: titleComparison,
			data: dataComparison,
			filters: filtersComparison,
		},
		detailsDialog: detailsDialogComparison,
	} = useSelector((state) => state.communityTransferComparison);
	const [chartDataComparison, setChartDataComparison] = useState(DEFAULT_CHART_DATA);
	const [censusAverageComparison, setCensusAverageComparison] = useState([]);
	const [censusByPeriodComparison, setCensusByPeriodComparison] = useState([]);
	const [censusByFacilityComparison, setCensusByFacilityComparison] = useState([]);
	const [queryFiltersComparison, setQueryFiltersComparison] = useState(DEFAULT_CHART_FILTER);
	const [defaultPatientDataComparison, setDefaultPatientDataComparison] = useState([]);
	const [filterDataComparison, setFilterDataComparison] = useState([]);
	const [selectedItemComparison, setSelectedItemComparison] = useState([]);
	const { auth } = useSelector(({ auth }) => ({ auth }));
	const [questions, setQuestions] = React.useState([]);

	const activeFacilities = useSelector((state) => state.activeFacilities.facilities);

	const [selectedFacility, setSelectedFacility] = useState(activeFacilities);

	const [orderBy, setOrderBy] = useState('dateOfADT'); // Default sort column
    const [order, setOrder] = useState('asc'); // Default sort direction

	const getQuestions = async (activeFacilitiesData) => {
		const res = await getAccountQuestions({
			facilityid: activeFacilitiesData,
			accountId: auth.accountId,
			pageType: DASHBOARD_FILTER_TYPE.DECEASED
		})
		if (res && res.length > 0) {
			setQuestions(res);
		}
	};

	React.useEffect(() => {
		getQuestions(activeFacilities);
		// eslint-disable-next-line
	}, [activeFacilities]);


	// * added comparison
	const getPatientChartsData = async () => {
		try {
			setLoading(true);
			if (filters.type === DECEASED_CARDS_TYPE.TOTAL) {
				setIsShowPercentage(true);
			}
			const [response, responseComparison] = await Promise.all([
				cardPatientAndChartData({ ...filters }),
				filtersComparison && cardPatientAndChartData({ ...filtersComparison }),
			]);

			let latestButtonFilterType = buttonFilterType;
			const isUpdateFilter = isUpdateDefaultFilter(filters.filter);
			if (isUpdateFilter) {
				setSelectedFilter(FILTER_TYPES.WEEKLY);
				latestButtonFilterType = CHART_FILTER_DAY_OF.WEEK;
				setButtonFilterType(CHART_FILTER_DAY_OF.WEEK);
			}
			const responseData = response?.data?.map((ele) => { return { ...ele, facilityName: ele?.facility?.name } }) ?? []
			if (response && responseData) {
				setCensusAverage(response?.censusAverage || 0);
				setCensusByPeriod(response?.censusByPeriod || []);
				const censusByData = await getChartFacilityPercentageBy(response)
				setCensusByFacility(censusByData);
				
				const chartDataArr = await chartsData(responseData, filters.filter);
				const latestChartData = await chartGroupBy(
					chartDataArr,
					latestButtonFilterType,
					response?.censusAverage,
					response?.censusByPeriod,
					filters.filter
				);
				setChartData({
					mainData: responseData,
					filterPatients: responseData,
					filterData: latestChartData,
				});
			}
			setFilterData(data);
			if (filters.selectedFilterData && filters.selectedFilterData.length > 0) {
				setSelectedItem([...filters.selectedFilterData]);				
				filterOptions(filters.selectedFilterData, responseData, latestButtonFilterType, {
					...filters.filter,
					filterData: data,
				});
			} else {
				const selectedIds = _.map(data, "_id") || [];
				setSelectedItem(selectedIds);
			}

			// * comparison blocks
			if (responseComparison && responseComparison?.data) {
				setCensusAverageComparison(responseComparison?.censusAverage || 0);
				setCensusByPeriodComparison(responseComparison?.censusByPeriod || []);
				if (responseComparison?.censusByFacility) {
					setCensusByFacilityComparison(responseComparison?.censusByFacility || []);
				}
				const responseDataComparison = responseComparison?.data;
				setDefaultPatientDataComparison(responseDataComparison);
				const chartDataArr = await chartsData(responseDataComparison, filtersComparison?.filter);
				const latestChartData = await chartGroupBy(
					chartDataArr,
					latestButtonFilterType,
					responseComparison?.censusAverage,
					responseComparison?.censusByPeriod,
					filtersComparison?.filter
				);
				setChartDataComparison({
					mainData: responseDataComparison,
					filterPatients: responseDataComparison,
					filterData: latestChartData,
				});
			}
			setFilterDataComparison(dataComparison);
			if (filtersComparison?.selectedFilterData && filtersComparison?.selectedFilterData.length > 0) {
				setSelectedItemComparison([...filtersComparison?.selectedFilterData]);
				filterOptions(
					filtersComparison?.selectedFilterData,
					responseComparison?.data,
					latestButtonFilterType,
					{
						...filtersComparison?.filter,
						filterData: dataComparison,
					},
					undefined,
					undefined,
					true
				);
			} else {
				const selectedIds = _.map(dataComparison, "_id") || [];
				setSelectedItemComparison(selectedIds);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	};

	// * added comparison
	const reloadChartData = useCallback(
		async (startDate, endDate, startDateComparison, endDateComparison) => {
			try {
				setLoading(true);
				let filterObj = { ...filters, filter: { startDate, endDate } };
				let filterObjComparison = { ...filtersComparison, filter: { startDateComparison, endDateComparison } };

				const isUpdateFilter = isUpdateDefaultFilter(filterObj.filter);
				let latestButtonFilterType = buttonFilterType;
				if (isUpdateFilter) {
					setSelectedFilter(FILTER_TYPES.WEEKLY);
					latestButtonFilterType = CHART_FILTER_DAY_OF.WEEK;
					setButtonFilterType(CHART_FILTER_DAY_OF.WEEK);
				}
				const [response, responseComparison] = await Promise.all([
					cardPatientAndChartData({ ...filterObj }),
					cardPatientAndChartData({ ...filterObjComparison }),
				]);
				const responseData = response?.data?.map((ele) => { return { ...ele, facilityName: ele?.facility?.name } }) ?? []
				if (response && responseData && responseData.length > 0) {
					setCensusAverage(response.censusAverage);
					setCensusByPeriod(response?.censusByPeriod || []);
					const censusByData = await getChartFacilityPercentageBy(response)
					setCensusByFacility(censusByData);
					const chartDataArr = await chartsData(responseData, filterObj.filter);
					const latestChartData = await chartGroupBy(
						chartDataArr,
						latestButtonFilterType,
						response.censusAverage,
						response?.censusByPeriod,
						filterObj.filter
					);
					setChartData({
						mainData: responseData,
						filterData: latestChartData,
						filterPatients: responseData,
					});
					let originalChartData = [];
					if (response.ninetyDaysData && response.ninetyDaysData.length > 0 && filters.type === "ninetyDaysData") {
						originalChartData = response.ninetyDaysData;
					}
					if (filterData.length > 0 && originalChartData.length > 0) {
						const latestFilterData = filterData
							.map((eleFilter) => {
								const selectedEle = _.find(originalChartData, { _id: eleFilter._id });
								if (selectedEle) {
									return {
										...eleFilter,
										admissionIds: selectedEle.admissionIds,
										ids: selectedEle.ids,
									};
								} else {
									return null;
								}
							})
							.filter((item) => item);
						setFilterData(latestFilterData);
					}
					if (selectedItem.length > 0) {
						await filterOptions(
							selectedItem,
							responseData,
							latestButtonFilterType,
							filterObj.filter,
							"reload",
							response
						);
					}
				}

				// * comparison block
				if (responseComparison && responseComparison?.data && responseComparison?.data.length > 0) {
					setCensusAverageComparison(responseComparison?.censusAverage);
					setCensusByPeriodComparison(responseComparison?.censusByPeriod || []);
					if (responseComparison?.censusByFacility) {
						setCensusByFacilityComparison(responseComparison?.censusByFacility || []);
					}
					setDefaultPatientDataComparison(responseComparison.data);
					const chartDataArrComparison = await chartsData(responseComparison?.data, filterObjComparison?.filter);
					const latestChartDataComparison = await chartGroupBy(
						chartDataArrComparison,
						latestButtonFilterType,
						responseComparison?.censusAverage,
						responseComparison?.censusByPeriod,
						filterObjComparison?.filter
					);
					setChartDataComparison({
						mainData: responseComparison?.data,
						filterData: latestChartDataComparison,
						filterPatients: responseComparison?.data,
					});

					let originalChartDataComparison = [];
					if (
						responseComparison?.ninetyDaysData &&
						responseComparison?.ninetyDaysData.length > 0 &&
						filtersComparison?.type === "ninetyDaysData"
					) {
						originalChartDataComparison = responseComparison?.ninetyDaysData;
					}
					if (filterDataComparison?.length > 0 && originalChartDataComparison?.length > 0) {
						const latestFilterDataComparison = filterDataComparison
							.map((eleFilter) => {
								const selectedEle = _.find(originalChartDataComparison, { _id: eleFilter._id });
								if (selectedEle) {
									return {
										...eleFilter,
										admissionIds: selectedEle.admissionIds,
										ids: selectedEle.ids,
									};
								} else {
									return null;
								}
							})
							.filter((item) => item);
						setFilterDataComparison(latestFilterDataComparison);
					}
					if (selectedItemComparison?.length > 0) {
						await filterOptions(
							selectedItemComparison,
							responseComparison?.data,
							latestButtonFilterType,
							filterObjComparison?.filter,
							"reload",
							responseComparison
						);
					}
				}
			} catch (e) {
				console.log(e);
			} finally {
				setLoading(false);
			}
		},
		[buttonFilterType, selectedItem, filters, selectedItemComparison, filtersComparison]
	);

	// * added comparison
	const reset = () => {
		setSelectedItem([]);
		setFilterData([]);

		setSelectedItemComparison([]);
		setFilterDataComparison([]);
	};

	// * added comparison
	const filterOptions = async (
		newChecked,
		chartMainData = [],
		latestButtonFilterType = null,
		filterObj = null,
		filterType = "initial",
		originalData,
		forComparison
	) => {
		let chartMainDataArr = chartMainData;
		if (activeFacilities.length > 1) {
			const filterPatientsByFacility = _.filter(chartMainDataArr, ({ facilityId }) => _.every([_.includes(selectedFacility, facilityId)]));
			chartMainDataArr = filterPatientsByFacility
		}
		if (filters.type === DECEASED_CARDS_TYPE.NINETY_DAYS_DATA) {
			let ninetyDaysDataIds = [];
			let dataOriginal = filterObj?.filterData
				? filterObj.filterData
				: forComparison
					? filterDataComparison
					: filterData;
			if (filterType === "reload") {
				dataOriginal = originalData.ninetyDaysData;
			}
			let ninetyDaysDataFilter = _.filter(dataOriginal, ({ _id }) => _.every([_.includes(newChecked, _id)]));
			if (ninetyDaysDataFilter && ninetyDaysDataFilter.length > 0) {
				ninetyDaysDataFilter.map((item) => (ninetyDaysDataIds = [...ninetyDaysDataIds, ...item.ids]));
			}
			let newChartFilters = _.filter(chartMainDataArr, ({ _id }) => _.every([_.includes(ninetyDaysDataIds, _id)]));
			await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObj, forComparison);
		} else {
			let newChartFilters = _.filter(chartMainDataArr, ({ filterId }) => {
				return _.every([_.includes(newChecked, filterId)]);
			});
			await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObj, forComparison);
		}
	};

	// * added comparison
	const updateChartArrData = useCallback(
		async (dataArr, filterType = null, type = null, filterObj = null, forComparison) => {
			let filterBy = filterType;
			if (!filterType) {
				filterBy = buttonFilterType;
			}
			let chartDataArr = [];
			if (type == "filter" && filterObj) {
				chartDataArr = await chartsData(dataArr, filterObj);
			} else {
				chartDataArr = await chartsData(dataArr, queryFilters);
			}
			let filtersLatest = filterObj ? filterObj : forComparison ? queryFiltersComparison : queryFilters;
			const latestChartData = await chartGroupBy(
				chartDataArr,
				filterBy,
				forComparison ? censusAverageComparison : censusAverage,
				forComparison ? censusByPeriodComparison : censusByPeriod,
				filtersLatest
			);
			if (type == "filter") {
				forComparison
					? setChartDataComparison((prevState) => ({
						...prevState,
						filterData: latestChartData,
						filterPatients: dataArr,
					}))
					: setChartData((prevState) => ({
						...prevState,
						filterData: latestChartData,
						filterPatients: dataArr,
					}));
			} else {
				forComparison
					? setChartDataComparison((prevState) => ({
						...prevState,
						filterData: latestChartData,
					}))
					: setChartData((prevState) => ({
						...prevState,
						filterData: latestChartData,
					}));
			}
		},
		[buttonFilterType, censusAverage, censusByPeriod, queryFilters]
	);

	// * added comparison
	const handleClickFilter = useCallback(
		async (type, filterType = null) => {
			setSelectedFilter(type);
			setButtonFilterType(filterType);
			await Promise.all([
				updateChartArrData(chartData.filterPatients, filterType),
				updateChartArrData(chartDataComparison?.filterPatients, filterType, undefined, undefined, true),
			]);
			if (selectedItem.length > 0) {
				await Promise.all([
					filterOptions(selectedItem, chartData.mainData, filterType),
					filterOptions(selectedItem, chartDataComparison?.mainData, filterType, undefined, undefined, undefined, true),
				]);
			}
		},
		[data, chartData, selectedItem, filterData, chartDataComparison, filterDataComparison]
	);

	// * added comparison
	const handleToggle = useCallback(
		async (value) => {
			const currentIndex = selectedItem.indexOf(value);
			const newChecked = [...selectedItem];

			if (currentIndex === -1) {
				newChecked.push(value);
			} else {
				newChecked.splice(currentIndex, 1);
			}
			if (newChecked.length > 0) {
				await Promise.all([
					filterOptions(newChecked, chartData.mainData, buttonFilterType),
					filterOptions(
						newChecked,
						chartDataComparison?.mainData,
						buttonFilterType,
						undefined,
						undefined,
						undefined,
						true
					),
				]);
			} else {
				setChartData((prevState) => ({
					...prevState,
					filterData: [],
					filterPatients: [],
				}));
				setChartDataComparison((prevState) => ({
					...prevState,
					filterData: [],
					filterPatients: [],
				}));
			}
			setSelectedItem(newChecked);
		},
		[selectedItem, chartData, chartDataComparison, buttonFilterType]
	);

	const getFieldNames = (row) => {
		return getDeceasedFieldNames(row, filters?.relation, filters?.type, data);
	};

	const handleChartLabelType = useCallback(
		async (type) => {
			setQueryFilters((prevState) => ({
				...prevState,
				chartPointType: type,
			}));
			await updateChartArrData(chartData.mainData, buttonFilterType);
		},
		[setQueryFilters, chartData, buttonFilterType]
	);

	const handleToggleAll = useCallback(
		async (value) => {
			if (value == "all") {
				const ids = filterData.map((a) => a._id);
				await filterOptions(ids, chartData.mainData, buttonFilterType);
				setSelectedItem([...ids]);
			} else {
				setChartData((prevState) => ({
					...prevState,
					filterData: [],
					filterPatients: [],
				}));
				setSelectedItem([]);
			}
		},
		[filterData, chartData, buttonFilterType]
	);

	const onClickReport = useCallback(
		(title, automaticallyReport) => {
			handleOnClickReport({
				selectedTab,
				filters,
				chartData: {
					...chartData,
					filterPatients: patientDataOrderBy(chartData?.filterPatients, order, orderBy)
				},
				selectedFilter,
				tableElementRef,
				buildingElementRef,
				title,
				queryFilters,
				selectedItem,
				filterData,
				automaticallyReport,
				buttonFilterType,
				orderBy,
				order,
				activeFacilitiesCount : activeFacilities?.length
			});
		},
		[selectedTab, filters, chartData, tableElementRef, buildingElementRef, queryFilters, selectedItem, filterData, orderBy, order, activeFacilities]
	);

	const handleClose = useCallback(() => {
		dispatch(closeDetailsDialog());
	}, [dispatch]);

	const handleTabButton = useCallback((type) => {
		setSelectedTab(type);
	}, []);

	useEffect(() => {
		reset();
		if (filters && filters.filter) {
			const daysFilter = chartFilterPermission(filters.filter);
			const daysDiff = dayDifferent(filters.filter);
			setQueryFilters({
				filterButtons: daysFilter,
				days: daysDiff,
				startDate: filters.filter.startDate,
				endDate: filters.filter.endDate,
				chartPointType: CHART_POINT_TYPE.TOTAL,
			});
			getPatientChartsData();
		}
		if (filtersComparison && filtersComparison.filter && rangesSet) {
			const daysFilter = chartFilterPermission(filtersComparison.filter);
			const daysDiff = dayDifferent(filtersComparison.filter);
			setQueryFiltersComparison({
				filterButtons: daysFilter,
				days: daysDiff,
				startDate: filtersComparison.filter.startDate,
				endDate: filtersComparison.filter.endDate,
				chartPointType: CHART_POINT_TYPE.TOTAL,
			});
			getPatientChartsData();
		}
	}, [filters, filtersComparison, rangesSet]);

	const handleExcelReport = useCallback((selectedColumns = []) => {
		handleGenerateExcelReport({
			data: chartData?.filterPatients,
			filters,
			pageType: DASHBOARD_FILTER_TYPE.DECEASED,
			getFieldNames,
			title,
			censusAverage,
			bedCapacity: dbData?.bedCapacity || 0,
			questions,
			selectedColumns,
			orderBy,
			order,
			activeFacilitiesCount : activeFacilities?.length
		});
	}, [chartData?.filterPatients, filters, getFieldNames, handleGenerateExcelReport, title, censusAverage, dbData, questions, orderBy, order, activeFacilities]);

	useEffect(() => {
		filterOptions(selectedItem,
			chartData?.mainData,
			buttonFilterType,
			{
				...filtersComparison?.filter,				
			},
			undefined,
			undefined,
			false
		);
		// setChartData((prevState) => ({
		// 	...prevState,
		// 	filterPatients: filterPatientsByFacility,
		// }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFacility]);


	// below code use for search filter in table
	const [searchTerm, setSearchTerm] = useState('');
	const [totalHighlightedCount, setTotalHighlightedCount] = useState(0);
	const [debounceHighlightedCount, setDebounceHighlightedCount] = useState(0);
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
	const [highlightedCountDefaultValue, setHighlightedCountDefaultValue] = useState(0);
	const [selectedHighlightedColumns, setSelectedHighlightedColumns] = useState([]);

	// useEffect(() => {
	// 	if (questions && questions.length > 0) {
	// 		setSelectedHighlightedColumns([...questions?.map((ele) => ele?.question?.accessor), ...tableDefaultQuestions.map((ele) => ele?.question?.accessor), "selectAll"]);
	// 	}
	// }, [questions]);

	const [filterType, setFilterType] = useState({ type: '', filter: '', operation: '' });

	useEffect(() => {
		const handlerCount = _.debounce(() => setDebounceHighlightedCount(totalHighlightedCount), 200);
		handlerCount();
		return () => handlerCount.cancel();
	}, [totalHighlightedCount]);

	useEffect(() => {
		const handler = _.debounce(() => {
			setDebouncedSearchTerm(searchTerm);
			setFilterType({ type: typeof searchTerm, filter: searchTerm, operation: '' });
		}, 300);
		handler();
		return () => handler.cancel();
	}, [searchTerm]);

	const handleSearch = (event) => {
		const value = event.target.value;
		setSearchTerm(isNaN(value) || !value ? value : Number(value));
	};

	const handleHighlightedCount = useCallback((count) => {
		setTotalHighlightedCount(searchTerm ? count : 0);
	}, [searchTerm]);

	// end of below code use for search filter in table
	return (
		<ChartDialogContainer handleClose={handleClose} isOpen={isOpen}>
			<ChartDialogLeftSidebar
				loading={loading}
				filterData={filterData}
				selectedItem={selectedItem}
				handleToggle={handleToggle}
				title={title}
				handleToggleAll={handleToggleAll}
				selectedTab={selectedTab}
			/>
			<Grid item xs={10.7} style={{ padding: "30px" }}>
				<ChartDetailsTab
					selectedTab={selectedTab}
					handleTabButton={handleTabButton}
					queryFilters={queryFilters}
					handleOnClickReport={onClickReport}
					loading={loading}
					handleExcelReport={handleExcelReport}
					setSelectedFacility={setSelectedFacility}
					selectedFacility={selectedFacility}
					questions={questions}
					pageType={PAGE_TYPE.DECEASED}
					tableFilterTypeSetting={PAGE_TYPE.DECEASED}
				/>
				{selectedTab === CHART_TAB_BUTTON.TABLE &&
					<ChartDetailsSearchBar
						searchTerm={searchTerm}
						handleSearch={handleSearch}
						setSearchTerm={setSearchTerm}
						highlightedCount={debounceHighlightedCount}
						filterType={filterType}
						setFilterType={setFilterType}
						selectedHighlightedColumns={selectedHighlightedColumns}
						setSelectedHighlightedColumns={setSelectedHighlightedColumns}
						selectedQuestions={questions}
					/>
				}
				{!loading && (
					<>
						{selectedTab === CHART_TAB_BUTTON.BUILDING && (
							<Stack direction={"row"} sx={{ mt: 2, height: "99%" }}>
								<ChartBuildingList
									page={PAGE_TYPE.DECEASED}
									data={chartData?.filterPatients || []}
									relation={filters?.relation}
									getFieldNames={getFieldNames}
									chartData={chartData}
									censusByFacility={censusByFacility}
									filterSelected={filters.filterSelected}
									filter={filters}
									buildingElementRef={buildingElementRef}
									dataComparison={chartDataComparison?.filterPatients || []}
									relationComparison={filtersComparison?.relation}
									chartDataComparison={chartDataComparison}
									censusByFacilityComparison={censusByFacilityComparison}
									filterSelectedComparison={filtersComparison?.filterSelected}
									filterComparison={filtersComparison}
									isTotalCard={filters?.isTotalCard}
									filterListData={filters?.filterListData}
									typeCard={filters?.type}

								/>
							</Stack>
						)}
						{selectedTab === CHART_TAB_BUTTON.TABLE && (
							<Stack direction={"row"} sx={{ mt: 2, height: "99%" }}>
								<ChartTableList
									data={chartData?.filterPatients || []}
									relation={filters?.relation}
									getFieldNames={getFieldNames}
									tableElementRef={tableElementRef}
									pageType={DASHBOARD_FILTER_TYPE.DECEASED}
									questions={questions}
									searchTerm={debouncedSearchTerm}
									handleHighlightedCount={handleHighlightedCount}
									highlightedCountDefaultValue={highlightedCountDefaultValue}
									filterType={filterType}
									selectedHighlightedColumns={selectedHighlightedColumns}
									orderData={{
										orderBy,
										setOrderBy,
										order,										
										setOrder
									}}
									activeFacilities={activeFacilities}
								/>
							</Stack>
						)}
						{selectedTab === CHART_TAB_BUTTON.CHART && (
							<Stack direction={"row"} sx={{ mt: 2, height: "90%" }}>
								<CardDetailChart
									totalText="Total Deceased"
									data={chartData?.filterData}
									filters={filters}
									censusAverage={censusAverage}
									total={chartData?.filterPatients?.length || 0}
									setQueryFilters={setQueryFilters}
									queryFilters={queryFilters}
									setQueryFiltersComparison={setQueryFiltersComparison}
									handleClickFilter={handleClickFilter}
									selectedFilter={selectedFilter}
									reloadChartData={reloadChartData}
									isChartTotalButton={isShowPercentage}
									handleChartLabelType={handleChartLabelType}
									selectedFacility={selectedFacility}
									dbData={dbData}
								/>
							</Stack>
						)}
					</>
				)}
				<ChartTabLoader loading={loading} />
			</Grid>
		</ChartDialogContainer>
	);
}
