import axios from "../../../../../axios";
import { useCallback, useEffect, useState } from "react";
import CloseIcon from "../../../../../assets/svgs/close.svg";
import styles from "./AddPatient.module.scss";
import Questions from "./Questions";
import SelectType from "./SelectType";
import moment from "moment";
import ValidationADT from "./ValidationADT";
import { toSaveDate } from "../../../../../utilis/date-formats";
import _ from "lodash";
import AdmissionOrReadmissionSelect from "./AdmissionOrReadmissionSelect";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { ADD_NOTIFICATION } from "../../../../../store/types";

const MissingADT = ({
    initialFields,
    close,
    added,
    disabledTypes,
    patientAdded,
    DBadmissionType,
    isOnlyHospitalDashboard,
    setIsAddOrReaddDialog,
    isAddOrReaddDialog,
    gotToDetails,
    censusDate,
    selectedItem,
    isAskAdmissionDate    
}) => {
    const [questions, setQuestions] = useState([]);
    const [validation, setValidation] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(null);
    const [step, setStep] = useState(1);
    const [allFields, setAllFields] = useState({
        ...initialFields,
        type: undefined,
        transferType: undefined,
    });

    const setAllFieldsValue = (field, value) => {
        setAllFields({ ...allFields, [field]: value });
    };

    useEffect(() => {
        if (allFields && allFields.type === "admissionOrReadmission") {
            if (DBadmissionType) {
                setAllFieldsValue("type", DBadmissionType);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allFields, DBadmissionType]);

    useEffect(() => {
        if (allFields.transferTo !== "assistedLiving") {
            // setShowAddLatestAdmission(true);
            setAllFieldsValue("transferToWhichAssistedLiving", null);
        }
        // eslint-disable-next-line
    }, [allFields.transferTo]);

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const add = async () => {
        const isCensusDateCheck = isOnlyHospitalDashboard ? true : censusDate !== null;
        if (checkQuestionsValidation() && isCensusDateCheck && allFields.dateOfADT && localStorage.getItem("facilityId")) {
            setLoading(true)
            if (allFields.DOB) {
                allFields.DOB = toSaveDate(allFields.DOB);
            }
            if (allFields.dateOfADT) {
                allFields.dateOfADT = toSaveDate(allFields.dateOfADT);
            }
            if (allFields.dateOfLatestAdmission) {

                allFields.dateOfLatestAdmission = allFields.dateOfLatestAdmission
                    ? toSaveDate(allFields.dateOfLatestAdmission)
                    : null;
            }
            if (allFields.firstName) {
                allFields.firstName = allFields.firstName.trim()
            }
            if (allFields.lastName) {
                allFields.lastName = allFields.lastName.trim()
            }
            let d = await axios.post(`/api/patient`, {
                ...allFields,
                facilityId: localStorage.getItem("facilityId"),
            });
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "success",
                    label: `Patient ${allFields?._id ? "updated" : "added"} successfully`,
                    id: "patientAddedUpdated",
                },
            });
            setLoading(false)
            !_.isEmpty(patientAdded) && patientAdded(d.data);
        } else {
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    label: "Please select facility, or refresh page and try again later.",
                    type: "error",
                    id: "facilitySelectionError",
                },
            });
        }
    };

    const resetValiDation = useCallback(() => {
        setValidation(null)
    }, []);

    const checkQuestionsValidation = useCallback(() => {
        let errors = [];
        if (questions && questions.length > 0) {
            questions.map(item => {
                const fieldName = item.question?.accessor || null;
                const type = item.question?.type || null;
                const dependsOn = item.question?.dependsOn || null;
                const dependsOnValues = item.question?.dependsOnValues || [];
                const isRequired = item.question?.isRequired || false;
                if (fieldName && isRequired) {
                    if (type === "toggle") {
                        if (allFields?.type === "transfer" && allFields?.transferType === "hospitalTransfer") {
                            errors.push(`Please select ${item.question.label}`);
                        }
                        if (allFields[fieldName] === undefined || allFields[fieldName] == null) {
                            errors.push(`Please select ${item.question.label}`);
                        }
                    } else if (type === "date") {
                        if (dependsOn === "server") {
                            let isCheckValid;
                            if (selectedItem) {
                                isCheckValid = isAskAdmissionDate || allFields[fieldName] != null ? true : false;
                            } else {
                                isCheckValid = isAskAdmissionDate ? true : false;
                            }
                            if (isCheckValid && !allFields[fieldName]) {
                                errors.push(`Please select ${item.question.label}`);
                            }
                            if (
                                allFields[fieldName] &&
                                !moment(allFields[fieldName], "MM/DD/YYYY", true).isValid()
                            ) {
                                errors.push("Please enter Valid Date");
                            }

                            if (
                                allFields[fieldName] &&
                                moment(allFields[fieldName], "MM/DD/YYYY", true).isValid() &&
                                moment(allFields[fieldName], "MM/DD/YYYY").isAfter(moment())
                            ) {
                                errors.push(`${item.question.label} cannot be in the future date!`);
                            }
                        } else {
                            if (!allFields[fieldName]) {
                                errors.push(`Please select ${item.question.label}`);
                            }
                            if (moment(toSaveDate(allFields[fieldName])).isBefore(censusDate)) {
                                errors.push(`Please enter Date After Census Initial Date.`);
                            }
                            if (
                                allFields[fieldName] &&
                                !moment(allFields[fieldName], "MM/DD/YYYY", true).isValid()
                            ) {
                                errors.push("Please enter Valid Date");
                            }
                            if (
                                allFields[fieldName] &&
                                moment(allFields[fieldName], "MM/DD/YYYY", true).isValid() &&
                                moment(allFields[fieldName], "MM/DD/YYYY").isAfter(moment())
                            ) {
                                errors.push(`${item.question.label} cannot be in the future date!`);
                            }
                        }
                    } else if (dependsOn) {
                        const dependFieldValue = allFields[dependsOn];
                        if (dependsOnValues.length > 0) {
                            // eslint-disable-next-line array-callback-return
                            dependsOnValues.map(ele => {
                                if (ele === dependFieldValue && !allFields[fieldName]) {
                                    errors.push(`Please select ${item.question.label}`);
                                }
                            });
                        }
                    } else if (!allFields[fieldName]) {
                        errors.push(`Please select ${item.question.label}`);
                    }
                }
                return false;
            });
        }

        // if (questions && questions.length > 0) {
        //     questions.map((item) => {
        //         const fieldName = item.question?.accessor || null;
        //         const type = item.question?.type || null;
        //         const dependsOn = item.question?.dependsOn || null;
        //         const dependsOnValues = item.question?.dependsOnValues || [];
        //         const isRequired = item.question?.isRequired || false;
        //         if (fieldName && isRequired) {
        //             if (type === "toggle") {
        //                 if (allFields[fieldName] === undefined || allFields[fieldName] == null) {
        //                     errors.push(`Please select ${item.question.label}`);
        //                 }
        //             } else if (type === "date") {
        //                 if (dependsOn === "server") {
        //                     let isCheckValid = false;
        //                     if (isCheckValid && !allFields[fieldName]) {
        //                         errors.push(`Please select ${item.question.label}`);
        //                     }
        //                     if (allFields[fieldName] && !moment(allFields[fieldName], "MM/DD/YYYY", true).isValid()) {
        //                         errors.push("Please enter Valid Date");
        //                     }
        //                 } else {
        //                     if (!allFields[fieldName]) {
        //                         errors.push(`Please select ${item.question.label}`);
        //                     }
        //                     if (allFields[fieldName] && !moment(allFields[fieldName], "MM/DD/YYYY", true).isValid()) {
        //                         errors.push("Please enter Valid Date");
        //                     }
        //                 }
        //             } else if (dependsOn) {
        //                 const dependFieldValue = allFields[dependsOn];
        //                 if (dependsOnValues.length > 0) {
        //                     // eslint-disable-next-line array-callback-return
        //                     dependsOnValues.map(ele => {
        //                         if (ele === dependFieldValue && !allFields[fieldName]) {
        //                             errors.push(`Please select ${item.question.label}`);
        //                         }
        //                     });
        //                 }
        //             } else if (!allFields[fieldName]) {
        //                 errors.push(`Please select ${item.question.label}`);
        //             }
        //         }
        //         return false;
        //     });
        // }

        if (errors.length > 0) {
            setFormSubmitted("questions");
            setValidation({ type: "questions", errors });
            return false;
        } else {
            setFormSubmitted(null);
            setValidation(null);
            return true;
        }
    }, [questions, allFields, selectedItem, isAskAdmissionDate, censusDate]);


    const checkIsError = useCallback((field) => {
        if (formSubmitted && formSubmitted === field) {
            return true;
        } else {
            return false;
        }
    }, [formSubmitted]);

    const gotToQuestions = () => {
        setStep(2);
    };

    return (
        <div className={styles.missingADT}>
            {validation && validation.type && (
                <ValidationADT
                    selectedItem={validation}
                    close={() => resetValiDation()}
                />
            )}
            <div className={styles.missingOverlay}></div>
            <div className={`${styles.addPatient} ${styles.missingContent}`}>
                <div className={`df aic ${styles.hdr}`}>
                    <p className={`df aic ffmm fs18`}>Add Missing ADT</p>

                    <div className={`mla`}>
                        <div
                            className={`df acc ${styles.closeBtn}`}
                            onClick={() => close()}
                        >
                            <CloseIcon />
                        </div>
                    </div>
                </div>
                {step === 1 ? (
                    <SelectType
                        allFields={allFields}
                        setAllFieldsValue={setAllFieldsValue}
                        gotToDetails={gotToQuestions}
                        // gotToDetails={gotToDetails}
                        disabledTypes={disabledTypes}
                    />
                ) : undefined}
                {step === 2 ? (
                    <div
                        className={`p-t-20 p-r-20 p-b-0 p-l-20 df ffc ${styles.stepThree}`}
                    >
                        {isAddOrReaddDialog && (
                            <AdmissionOrReadmissionSelect
                                allFields={allFields}
                                setAllFieldsValue={setAllFieldsValue}
                                gotToDetails={gotToDetails}
                                handleClose={(type = null) => {
                                    if (type !== "save") {
                                        setStep(2)
                                    }
                                    setIsAddOrReaddDialog(false)
                                }}
                            />
                        )}
                        {!isAddOrReaddDialog && (
                            <Questions
                                setAllFieldsValue={(type, val) => setAllFieldsValue(type, val)}
                                allFieldsData={allFields}
                                allFields={allFields}
                                setQuestions={setQuestions}
                                checkError={checkIsError("questions")}
                                isOnlyHospitalDashboard={isOnlyHospitalDashboard}
                            />
                        )}
                        <div className={`df aic ${styles.popupFtr} `}>
                            <div className={`df aic mla`}>
                                <LoadingButton
                                    loading={loading}
                                    variant='contained'
                                    onClick={async () => {
                                        if (checkQuestionsValidation()) {
                                            await add();
                                            added();
                                            close();
                                        }
                                    }}
                                    disabled={loading}
                                    size="medium"
                                >
                                    Save
                                </LoadingButton>
                            </div>
                        </div>
                    </div>
                ) : undefined}
            </div>
        </div>
    );
};

export default MissingADT;
