import { useEffect, useState } from "react";
import axios from "../../axios";

import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip,
} from "chart.js";
// import { Bar, Line } from "react-chartjs-2";
import { useLocation } from "react-router";
import TimeLogDisplay from "../shared/TimelogDisplay/TimelogDisplay";
ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

const Logs = () => {
	const location = useLocation();
	const state = location.state || {};
	const [logs, setLogs] = useState([]);

	const getLogs = async () => {
		const localLogs = await axios.get(`/api/log/time?userId=${state.userId}`).catch((e) => e);
		setLogs(localLogs.data);
	};

	useEffect(() => {
		getLogs();
		// eslint-disable-next-line
	}, []);

	return <TimeLogDisplay logs={logs} />;
};
export default Logs;
