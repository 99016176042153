/* eslint-disable array-callback-return */
import { Box, Grid } from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../assets/styles/Hospital.module.scss";
import Page from "../components/Page";
import dashboardStyles from "../components/dashboard/Dashboard.module.scss";
import InsuranceCard from "../components/dashboard/InsuranceCard/InsuranceCard";
import Card from "../components/dashboard/card/Card";
import DeceasedCardDetailsDialog from "../components/dashboard/deceased/DeceasedCardDetailsDialog";
import DeceasedHeader from "../components/dashboard/deceased/DeceasedHeader";
import DoctorCard from "../components/dashboard/doctorCard/DoctorCard";
import FloorCardList from "../components/dashboard/hospital/floorCardList/FloorCardList";
import NinetyDaysAnalysis from "../components/dashboard/hospital/ninetyDaysAnalysis/NinetyDaysAnalysis";
import TotalCountCircle from "../components/dashboard/hospital/totalCountCircle/TotalCountCircle";
import TaggedList from "../components/dashboard/shared/taggedList/TaggedList";
import usePdfReport from "../components/hooks/usePdfReport";
import PickProjectionDaysPopup from "../components/shared/PickProjectionDaysPopup/PickProjectionDaysPopup";
import ColorInfoBox from "../components/shared/dashboard/ColorInfoBox";
import { DECEASED_TYPES } from "../data/common.data";
import { getAllDeceasedPatient } from "../services/deceased.service";
import {
	setCardFilterDeceased as setCardFilterComparison,
	setFilterListDataDeceased as setFilterListDataComparison,
	setPatientList as setPatientListComparison,
} from "../store/reducers/comparisonReducers/deceasedComparison.slice";
import {
	DEFAULT_DECEASED_DATA,
	openDetailsDialog,
	setCardFilterDeceased,
	setLockTotal,
	setLockedTotalBy,
	setResetFilter,
	setTransferType,
} from "../store/reducers/deceased.slice";
import { PATIENT_RELATION } from "../types/chart-dialog.type";
import { DECEASED_CARDS_TYPE } from "../types/deceased.type";
import { PAGE_TYPE } from "../types/pages.type";
import {
	getDynamicPercentageBy,
	itemPercentage,
	priorityFilter,
	projectionPerMonth,
	updateChartFilterByPriority,
	updateCurrentFilterListData,
} from "../utilis/common";
import { toSaveDate, toSaveEndDate } from "../utilis/date-formats";
import { updateFilterListData } from "../utilis/deceased-common";
import tooltipsInfo from "../utilis/tooltipContents";
import { setDeceasedCards } from "../store/reducers/customCard.slice";
import AddCardDrawer from "../components/shared/add-filter-card/add-card-drawer";
import useCustomCardFilter from "../components/hooks/useCustomCardFilter";
import { DECEASED_CUSTOM_CARD } from "../types/custom-card-type";
import CardAnimation from "../components/shared/CardAnimation";
import ShadowLineWithText from "../components/shared/ShadowLineWithText";

const DEFAULT_LIST = {
	ninetyDaysData: [],
	list: [],
};

const DeceasedPage = (props) => {
	const dispatch = useDispatch();
	const { downloadPdf, saveAutomaticReport, downloadExcel } = usePdfReport();
	const { saveCustomCardFilter } = useCustomCardFilter();
	const [patientList, setPatientList] = useState(DEFAULT_LIST);
	const [filterListData, setFilterListData] = useState(DEFAULT_DECEASED_DATA);
	// eslint-disable-next-line no-unused-vars
	const {
		filter,
		dbData,
		cardFilter,
		detailsDialog,
		isResetFilter,
		transferType,
		lockedTotal,
		facilityPercentage,
		lockedByFacility,
		isCensusTotalLocked,
		lockedTotalBy,
	} = useSelector((state) => state.deceased);
	const { percentageBy } = useSelector((state) => state.common);
	const { deceasedCards } = useSelector((state) => state.customCard);
	const percentageAgainst = useMemo(() => {
		return getDynamicPercentageBy(dbData, percentageBy);
	}, [percentageBy, dbData]);

	const { lastADTDate, projectionDays } = useSelector((state) => state.common);
	const activeFacilities = useSelector((state) => state.activeFacilities.facilities);
	const activeFacilitiesComparison = useSelector((state) => state.comparison.activeComparisonFacilitiesId);
	const [isDateUpdated, setIsDateUpdated] = useState(false);
	const [projectionPopupOpen, setProjectionPopupOpen] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const { rangesSet, percentageThresholds, comparingAgainstDifferentFacility } = useSelector(
		(state) => state.comparison
	);
	const {
		filterListData: filterListDataComparison,
		dbData: dbDataComparison,
		filter: filterComparison,
		cardFilter: cardFilterComparison,
		patientList: patientListComparison,
	} = useSelector((state) => state.deceasedComparison);
	const activeFacilityLocalStorage = localStorage.getItem("facilityId");

	// loading states
	const [loading, setLoading] = useState(true);
	const [loadingComparison, setLoadingComparison] = useState(false);

	const getAllDataCount = async (filter) => {
		setLoading(true);
		await Promise.all([
			getAllDeceasedPatient(filter).then(async (res) => {
				if (res && res.list.length > 0) {
					setPatientList(res);
					await updateTotalToDBFilter(res);
				} else {
					setPatientList([]);
					setFilterListData(DEFAULT_DECEASED_DATA);
				}
			}),
			rangesSet
				? getAllDeceasedPatient({ ...filterComparison, facilityIds: activeFacilitiesComparison }, true).then(
					async (res) => {
						if (res && res.list.length > 0) {
							dispatch(setPatientListComparison(res));
						} else {
							dispatch(setPatientListComparison([]));
							dispatch(setFilterListDataComparison(DEFAULT_DECEASED_DATA));
						}
					}
				)
				: null,
		])
			.then(() => {
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (isResetFilter) {
			updateTotalToDBFilter(patientList);
			dispatch(setResetFilter(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isResetFilter, projectionDays]);

	useEffect(() => {
		updateTotalToDBFilter(patientList);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lockedTotal, projectionDays, percentageAgainst]);

	useEffect(() => {
		async function fetchMyAPI(filter) {
			dispatch(setLockTotal(null));
			dispatch(setLockedTotalBy(null));
			await getAllDataCount(filter);
		}
		if (
			isDateUpdated &&
			filter &&
			filter.startDate &&
			localStorage.getItem("facilityId") &&
			activeFacilities.length > 0
		) {
			fetchMyAPI({ ...filter, facilityIds: activeFacilities });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		filter,
		filterComparison,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		activeFacilityLocalStorage,
	]);

	const updateTotalToDBFilter = async (res) => {
		const latestListData = await updateFilterListData(cardFilter, res, cardFilter.priorityData, false);

		setFilterListData((prevState) => {
			return { ...prevState, ...latestListData };
		});
	};

	const updateTotalToDBFilterComparison = async (res) => {
		const latestListData = await updateFilterListData(
			cardFilterComparison,
			res,
			cardFilterComparison.priorityData,
			true
		);

		setFilterListDataComparison((prevState) => {
			return { ...prevState, ...latestListData };
		});
	};

	const handleClickTotal = useCallback(
		(total) => {
			dispatch(setTransferType(transferType === DECEASED_TYPES.ALL ? null : DECEASED_TYPES.ALL));
		},
		[dispatch, transferType]
	);

	useEffect(() => {
		updateTotalToDBFilter(patientList);
		rangesSet && updateTotalToDBFilterComparison(patientListComparison);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transferType]);

	const handleToggle = useCallback(
		async ({ item, type, cardTitle }) => {
			if (item) {
				const value = item._id;
				const filtersItem = cardFilter;
				const filterData = filtersItem[type];
				const currentIndex = filterData.indexOf(value);
				const newChecked = [...filterData];

				if (currentIndex === -1) {
					newChecked.push(value);
				} else {
					newChecked.splice(currentIndex, 1);
				}
				const [priorityData, priorityDataComparison] = await Promise.all([
					priorityFilter(cardFilter.priorityData, type, newChecked.length, cardTitle),
					rangesSet
						? priorityFilter(cardFilterComparison?.priorityData, type, newChecked.length, cardTitle)
						: undefined,
				]);
				dispatch(
					setCardFilterDeceased({
						[type]: newChecked,
						priorityData: priorityData,
					})
				);

				rangesSet && dispatch(setCardFilterComparison({ [type]: newChecked, priorityData: priorityDataComparison }));
				const [latestListData, latestListDataComparison] = await Promise.all([
					updateFilterListData({ ...cardFilter, [type]: newChecked }, patientList, priorityData, false),
					rangesSet
						? updateFilterListData(
							{ ...cardFilterComparison, [type]: newChecked },
							patientListComparison,
							priorityDataComparison,
							true
						)
						: undefined,
				]);
				setFilterListData((prevState) => {
					return { ...prevState, ...latestListData };
				});
				if (rangesSet) {
					const updatedFilterListData = updateCurrentFilterListData(filterListDataComparison, latestListDataComparison);
					dispatch(setFilterListDataComparison(updatedFilterListData));
				}
			}
		},
		[
			cardFilter,
			rangesSet,
			cardFilterComparison,
			dispatch,
			patientList,
			patientListComparison,
			filterListDataComparison,
		]
	);

	const handleToggleAll = async ({ items, type, cardTitle = null }) => {
		const filtersItem = cardFilter;
		const filterData = filtersItem[type];
		const itemIds = items.map((x) => x._id);
		const valuesAlreadyInFilterData = itemIds.every((x) => filterData.includes(x));
		let newChecked = [];
		if (valuesAlreadyInFilterData) {
			newChecked = filterData.filter((x) => !itemIds.some((y) => y === x));
		} else {
			newChecked = [...filterData, ...itemIds];
		}

		const [priorityData, priorityDataComparison] = await Promise.all([
			priorityFilter(cardFilter.priorityData, type, newChecked.length, cardTitle),
			rangesSet ? priorityFilter(cardFilterComparison?.priorityData, type, newChecked.length, cardTitle) : undefined,
		]);
		dispatch(
			setCardFilterDeceased({
				[type]: newChecked,
				priorityData: priorityData,
			})
		);

		rangesSet && dispatch(setCardFilterComparison({ [type]: newChecked, priorityData: priorityDataComparison }));
		const [latestListData, latestListDataComparison] = await Promise.all([
			updateFilterListData({ ...cardFilter, [type]: newChecked }, patientList, priorityData, false),
			rangesSet
				? updateFilterListData(
					{ ...cardFilterComparison, [type]: newChecked },
					patientListComparison,
					priorityDataComparison,
					true
				)
				: undefined,
		]);
		setFilterListData((prevState) => {
			return { ...prevState, ...latestListData };
		});
		if (rangesSet) {
			const updatedFilterListData = updateCurrentFilterListData(filterListDataComparison, latestListDataComparison);
			dispatch(setFilterListDataComparison(updatedFilterListData));
		}
	};

	const handleChartDialog = useCallback(
		async (title, type, relation = null, isTotalCard = false) => {
			let latestCardFilter = await updateChartFilterByPriority(cardFilter, type);
			let filterSelected =
				(cardFilter.priorityData.length > 0 && cardFilter.priorityData[0]["type"] !== cardFilter.type) ||
				transferType != null;
			if (type === DECEASED_CARDS_TYPE.TOTAL) {
				filterSelected = false;
			}
			const filters = {
				facilityId: localStorage.getItem("facilityId"),
				facilityIds: activeFacilities,
				cardFilter: latestCardFilter,
				selectedFilterData: cardFilter[type] || [],
				type,
				filter,
				relation,
				filterSelected,
				facilityPercentage,
				lockedByFacility,
				lockedTotal,
				isCensusTotalLocked,
				isTotalCard,
				filterListData
			};
			let data = type === DECEASED_CARDS_TYPE.TOTAL ? [] : filterListData[type];
			dispatch(
				openDetailsDialog({
					isOpen: true,
					title,
					type,
					data,
					filters,
					dbData,
				})
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch, filterListData, cardFilter, filter, facilityPercentage, dbData]
	);

	const getPriorityNumber = useCallback(
		(type) => {
			const idx = cardFilter.priorityData.findIndex((p) => p.type === type);
			return idx != null && idx !== -1 ? idx + 1 : null;
		},
		[cardFilter]
	);

	const handleOnClickReport = async (type, title, isTotalCard = false, automaticallyReport = null) => {
		if (automaticallyReport) {
			await saveAutomaticReport({
				type,
				title,
				cardFilter,
				filter: {
					...filter,
					startDate: toSaveDate(filter.startDate),
					endDate: toSaveEndDate(filter.endDate),
				},
				dbData,
				page: PAGE_TYPE.DECEASED,
				filterListData,
				isTotalCard,
				automaticallyReport: automaticallyReport,
				transferType,
				lockedByFacility,
				lockedTotal,
				lockedTotalBy,
				isCensusTotalLocked,
				lastADTDate,
				percentageBy,
			});
		} else {
			await downloadPdf({
				type,
				title,
				cardFilter,
				filter,
				dbData,
				page: PAGE_TYPE.DECEASED,
				filterListData,
				isTotalCard,
			});
		}
	};

	const handleOnClickReportGraph = async (graphData) => {
		if (graphData && graphData.automaticallyReport) {
			const graphDataUpdated = _.omit(graphData, ["chartData", "filterData", "tableElementRef", "buildingElementRef"]);
			await saveAutomaticReport({
				type: graphData?.filters?.type,
				title: graphData?.automaticallyReport?.title,
				cardFilter,
				filter: {
					...filter,
					startDate: toSaveDate(filter.startDate),
					endDate: toSaveEndDate(filter.endDate),
				},
				dbData,
				page: PAGE_TYPE.DECEASED,
				filterListData,
				automaticallyReport: graphData?.automaticallyReport,
				transferType,
				lockedByFacility,
				lockedTotal,
				lockedTotalBy,
				isCensusTotalLocked,
				lastADTDate,
				isGraphReport: true,
				graphData: { ...graphDataUpdated, detailsDialog },
				percentageBy,
			});
		} else {
			await downloadPdf({
				type: graphData?.filters?.type,
				cardFilter,
				filter,
				dbData,
				page: PAGE_TYPE.DECEASED,
				filterListData,
				isTotalCard: false,
				graphData,
				isGraphReport: true,
			});
		}
	};

	const handleAddCustomCard = useCallback(async (key) => {
		let cards = [...deceasedCards];
		if (key === "selectAll") {
			cards = _.map(DECEASED_CUSTOM_CARD, "value");
		} else if (key === "clearAll") {
			cards = []
		} else {
			if (!cards.includes(key)) {
				cards.push(key);
			} else {
				cards.splice(cards.indexOf(key), 1);
			}
		}
		dispatch(setDeceasedCards(cards));
		await saveCustomCardFilter({ page: PAGE_TYPE.DECEASED, cards })
	}, [deceasedCards, dispatch, saveCustomCardFilter]);

	const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0 });
	const scrollDemoRef = useRef(null);
	const handleScroll = () => {
		if (scrollDemoRef.current) {
			const { scrollTop } = scrollDemoRef.current;
			setScrollPosition({ scrollTop });
		}
	};

	return (
		<Page title="Dashboard: Deceased Page">
			{detailsDialog.isOpen && (
				<DeceasedCardDetailsDialog
					handleOnClickReport={handleOnClickReportGraph}
					handleGenerateExcelReport={downloadExcel}
				/>
			)}
			<div ref={scrollDemoRef} className={dashboardStyles.content} onScroll={handleScroll}>
				<div className={`df aic ${styles.header}`}>
					<div className={`df aic ${styles.aoWrapper}`}>
						<DeceasedHeader
							handleFilterUpdate={() => setIsDateUpdated(true)}
							loading={loading || loadingComparison}
							setLoading={setLoading}
							setLoadingComparison={setLoadingComparison}
							projectionDays={projectionDays}
							isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
							percentageAgainst={percentageAgainst}
							projectionSelectionBlock={<>
								{/* <PickProjectionDaysPopup open={projectionPopupOpen} setOpen={setProjectionPopupOpen} /> */}
								<PickProjectionDaysPopup open={projectionPopupOpen} setOpen={setProjectionPopupOpen} loading={loading} />
							</>}
						/>
					</div>
				</div>

				<Grid container justifyContent={"center"} sx={{ mt: "30px" }}>
					<Grid item xs={12} position={"relative"}>
						{rangesSet && <ColorInfoBox loading={loading || loadingComparison} />}
						<AddCardDrawer
							listData={DECEASED_CUSTOM_CARD}
							selectedCards={deceasedCards}
							handleAddCustomCard={handleAddCustomCard}
							scrollTop={scrollPosition?.scrollTop}
						/>
						<TotalCountCircle
							page={PAGE_TYPE.DECEASED}
							totalTitle="Deceased"
							selected={transferType === DECEASED_TYPES.ALL}
							handleClickTotal={() => handleClickTotal(dbData.total)}
							projectTitle={percentageBy}
							total={dbData.total}
							totalComparison={dbDataComparison?.total}
							filter={filter}
							filterComparison={filterComparison}
							totalPercentage={itemPercentage(dbData.total, percentageAgainst, "number")}
							totalComparisonPercentage={itemPercentage(
								dbDataComparison?.total,
								dbDataComparison?.censusAverage,
								"number"
							)}
							projectTotal={projectionPerMonth(dbData.total, filter, projectionDays)}
							projectTotalComparison={projectionPerMonth(dbDataComparison?.total, filterComparison, projectionDays)}
							totalProjectionComparisonPercentage={itemPercentage(
								projectionPerMonth(dbDataComparison?.total, filterComparison),
								dbDataComparison?.censusAverage,
								"number"
							)}
							totalProjectionPercentage={itemPercentage(
								projectionPerMonth(dbData.total, filter, projectionDays),
								percentageAgainst,
								"number"
							)}
							tooltipContent={tooltipsInfo.deceasedPage.deceased}
							handleOnClickAction={(title) => handleChartDialog(title, DECEASED_CARDS_TYPE.TOTAL, null, true)}
							loading={loading || loadingComparison}
							handleOnClickReport={(title, otherData) =>
								handleOnClickReport(DECEASED_CARDS_TYPE.TOTAL, title, true, otherData)
							}
							projectionDays={projectionDays}
						/>
					</Grid>
				</Grid>

				<Box sx={{ p: 2 }}>
					<Grid
						container
						spacing={2}
						sx={{ mb: "20px" }}
						display="flex"
						direction={"row"}
					>
						<CardAnimation id={`${DECEASED_CARDS_TYPE.INSURANCE_DATA}_scroll`} checked={_.includes(deceasedCards, DECEASED_CARDS_TYPE.INSURANCE_DATA)}>
							<Grid item md={4} xs={12}>
								<Card
									loading={loading || loadingComparison}
									title="Per Insurance"
									itemAlign={"ais"}
									handleOnClickAction={(title) =>
										handleChartDialog(title, DECEASED_CARDS_TYPE.INSURANCE_DATA, PATIENT_RELATION.INSURANCE)
									}
									tooltipContent={tooltipsInfo.deceasedPage.perInsurance}
									priorityNumber={getPriorityNumber(DECEASED_CARDS_TYPE.INSURANCE_DATA)}
									handleOnClickReport={(title, otherData) =>
										handleOnClickReport(DECEASED_CARDS_TYPE.INSURANCE_DATA, title, false, otherData)
									}
									style={{ height: "100%" }}
									selectedItems={cardFilter.insuranceData}
									data={filterListData.insuranceData}
									type={DECEASED_CARDS_TYPE.INSURANCE_DATA}
									handleToggleAll={handleToggleAll}
									canBeSearched={true}
								>
									<InsuranceCard
										cardTitle="Per Insurance"
										height={350}
										data={filterListData.insuranceData}
										handleToggle={handleToggle}
										handleToggleAll={handleToggleAll}
										selectedItem={cardFilter.insuranceData}
										type={DECEASED_CARDS_TYPE.INSURANCE_DATA}
										page={PAGE_TYPE.DECEASED}
										dataComparison={filterListDataComparison?.insuranceData}
										filter={filter}
										filterComparison={filterComparison}
										averageCensus={percentageAgainst}
										averageCensusComparison={dbDataComparison?.censusAverage}
										loading={loading || loadingComparison}
										projectionDays={projectionDays}
										isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
										priorityNumber={getPriorityNumber(DECEASED_CARDS_TYPE.INSURANCE_DATA)}
										transferType={transferType}
										lockedTotalBy={lockedTotalBy}
									/>
								</Card>
							</Grid>
						</CardAnimation>

						<CardAnimation id={`${DECEASED_CARDS_TYPE.DOCTOR_DATA}_scroll`} checked={_.includes(deceasedCards, DECEASED_CARDS_TYPE.DOCTOR_DATA)}>
							<Grid item md={4} xs={12}>
								<Card
									loading={loading || loadingComparison}
									title="Per Doctor"
									handleOnClickAction={(title) =>
										handleChartDialog(title, DECEASED_CARDS_TYPE.DOCTOR_DATA, PATIENT_RELATION.DOCTOR)
									}
									taggedList={
										<TaggedList
											type={DECEASED_CARDS_TYPE.DOCTOR_DATA}
											data={filterListData.doctorData}
											selectedItem={cardFilter.doctorData}
										/>
									}
									style={{ height: "100%" }}
									tooltipContent={tooltipsInfo.deceasedPage.perDoctor}
									priorityNumber={getPriorityNumber(DECEASED_CARDS_TYPE.DOCTOR_DATA)}
									handleOnClickReport={(title, otherData) =>
										handleOnClickReport(DECEASED_CARDS_TYPE.DOCTOR_DATA, title, false, otherData)
									}
									secondaryTitleError={
										comparingAgainstDifferentFacility &&
										rangesSet &&
										"Comparison function disabled when comparing to other facilities"
									}
									secondaryTitleClassName="error"
									selectedItems={cardFilter.doctorData}
									data={filterListData.doctorData}
									type={DECEASED_CARDS_TYPE.DOCTOR_DATA}
									handleToggleAll={handleToggleAll}
									canBeSearched={true}
								>
									<DoctorCard
										cardTitle="Per Doctor"
										data={filterListData.doctorData}
										minimizable={true}
										handleToggle={handleToggle}
										handleToggleAll={handleToggleAll}
										selectedItem={cardFilter.doctorData}
										type={DECEASED_CARDS_TYPE.DOCTOR_DATA}
										page={PAGE_TYPE.DECEASED}
										comparisonData={filterListDataComparison?.doctorData}
										averageCensusComparison={dbDataComparison?.censusAverage}
										averageCensus={percentageAgainst}
										filter={filter}
										filterComparison={filterComparison}
										loading={loading || loadingComparison}
										comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
										projectionDays={projectionDays}
										isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
										priorityNumber={getPriorityNumber(DECEASED_CARDS_TYPE.DOCTOR_DATA)}
										transferType={transferType}
										lockedTotalBy={lockedTotalBy}
									/>
								</Card>
							</Grid>
						</CardAnimation>

						<CardAnimation id={`${DECEASED_CARDS_TYPE.FLOORS_DATA}_scroll`} checked={_.includes(deceasedCards, DECEASED_CARDS_TYPE.FLOORS_DATA)}>
							<Grid item md={4} xs={12}>
								<Card
									loading={loading || loadingComparison}
									title="Per Floor"
									handleOnClickAction={(title) =>
										handleChartDialog(title, DECEASED_CARDS_TYPE.FLOORS_DATA, PATIENT_RELATION.UNIT)
									}
									taggedList={
										<TaggedList
											type={DECEASED_CARDS_TYPE.FLOORS_DATA}
											data={filterListData.floorsData}
											selectedItem={cardFilter.floorsData}
										/>
									}
									style={{ height: "100%" }}
									tooltipContent={tooltipsInfo.deceasedPage.perFloor}
									priorityNumber={getPriorityNumber(DECEASED_CARDS_TYPE.FLOORS_DATA)}
									handleOnClickReport={(title, otherData) =>
										handleOnClickReport(DECEASED_CARDS_TYPE.FLOORS_DATA, title, false, otherData)
									}
									secondaryTitleError={
										comparingAgainstDifferentFacility &&
										rangesSet &&
										"Comparison function disabled when comparing to other facilities"
									}
									secondaryTitleClassName="error"
									selectedItems={cardFilter.floorsData}
									data={filterListData.floorsData}
									type={DECEASED_CARDS_TYPE.FLOORS_DATA}
									handleToggleAll={handleToggleAll}
									canBeSearched={true}
								>
									<FloorCardList
										cardTitle="Per Floor"
										data={filterListData.floorsData}
										handleToggle={handleToggle}
										handleToggleAll={handleToggleAll}
										selectedItem={cardFilter.floorsData}
										type={DECEASED_CARDS_TYPE.FLOORS_DATA}
										page={PAGE_TYPE.DECEASED}
										filter={filter}
										filterComparison={filterComparison}
										dataComparison={filterListDataComparison?.floorsData}
										averageCensusComparison={dbDataComparison?.censusAverage}
										averageCensus={percentageAgainst}
										comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
										loading={loading || loadingComparison}
										projectionDays={projectionDays}
										isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
										priorityNumber={getPriorityNumber(DECEASED_CARDS_TYPE.FLOORS_DATA)}
										transferType={transferType}
										lockedTotalBy={lockedTotalBy}
									/>
								</Card>
							</Grid>
						</CardAnimation>

						<CardAnimation id={`${DECEASED_CARDS_TYPE.NINETY_DAYS_DATA}_scroll`} checked={_.includes(deceasedCards, DECEASED_CARDS_TYPE.NINETY_DAYS_DATA)}>
							<Grid item xs={12}>
								<Card
									loading={loading || loadingComparison}
									title="90 Days Analysis"
									handleOnClickAction={(title) => handleChartDialog(title, DECEASED_CARDS_TYPE.NINETY_DAYS_DATA)}
									tooltipContent={tooltipsInfo.deceasedPage["90DaysAnalysis"]}
									priorityNumber={getPriorityNumber(DECEASED_CARDS_TYPE.NINETY_DAYS_DATA)}
									handleOnClickReport={(title, otherData) =>
										handleOnClickReport(DECEASED_CARDS_TYPE.NINETY_DAYS_DATA, title, false, otherData)
									}
									style={{ height: "100%" }}
								>
									<NinetyDaysAnalysis
										cardTitle="90 Days Analysis"
										height={"360px"}
										handleToggle={handleToggle}
										selectedItem={cardFilter.ninetyDaysData}
										type={DECEASED_CARDS_TYPE.NINETY_DAYS_DATA}
										data={filterListData.ninetyDaysData}
										page={PAGE_TYPE.DECEASED}
										comparisonData={filterListDataComparison?.ninetyDaysData}
										averageCensusComparison={dbDataComparison?.censusAverage}
										averageCensus={percentageAgainst}
										filter={filter}
										loading={loading || loadingComparison}
										filterComparison={filterComparison}
										projectionDays={projectionDays}
										isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
										priorityNumber={getPriorityNumber(DECEASED_CARDS_TYPE.NINETY_DAYS_DATA)}
										transferType={transferType}
										lockedTotalBy={lockedTotalBy}
									/>
								</Card>
							</Grid>
						</CardAnimation>
					</Grid>
				</Box>

				<ShadowLineWithText data={deceasedCards} />
			</div>
		</Page>
	);
};

export default DeceasedPage;
