import { Button, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import _ from "lodash";
import React, { useCallback, useRef } from "react";
import Slider from "react-slick";
import { calcProPercentsBasedOnFilterAndDays, itemPercentage } from "../../../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../../utilis/percentage-comparison";
import CardItemTooltip from "../../../shared/CardItemTooltip";
import ColorBox from "../../../shared/dashboard/ColorBox";
import NoRecordFound from "../../../shared/NoRecordFound";
import CheckboxButton from "../../shared/checkboxButton/CheckboxButton";
import styles from "./SNFFacilitySlider.module.scss";
import SNFFacilitySliderSkeleton from "./SNFFacilitySliderSkeleton";

const SNFFacilitySlider = ({
	data,
	dataComparison,
	handleToggle,
	handleToggleAll,
	selectedItem = [],
	type,
	averageCensus,
	averageCensusComparison,
	filter,
	filterComparison,
	cardTitle,
	comparingAgainstDifferentFacility,
	loading,
	projectionDays,
	isComparingAgainstAvgCensus,
	transferType,
	priorityNumber,
	lockedTotalBy,
	searchValue,
	selectAll,
}) => {
	const sliderRef = useRef();
	const isFirstItemInPriorityData = priorityNumber === 1;

	const settings = {
		infinite: false,
		className: "slider variable-width",
		centerMode: false,
		rows: 1,
	};

	const getChartData = useCallback(() => {
		let dataArray = data.map((item) => {
			return Object.assign({}, item, { id: item.name });
		});
		return dataArray;
	}, [data]);

	return loading ? (
		<SNFFacilitySliderSkeleton />
	) : (
		<Grid container alignItems={"center"}>
			<Grid item xs={10}>
				<div className={"snf-facility-slider"}>
					{data.length > 0 && (
						<Slider ref={sliderRef} {...settings}>
							{data
								.filter((x) => x.label.toLowerCase().includes(searchValue.toLowerCase()))
								.map((ele, index) => {
									const ID = ele._id;
									const eleComparison = _.find(dataComparison, { _id: ID });
									const labelId = `checkbox-list-floor-label-${ID}`;
									const selected = selectedItem.indexOf(ID) !== -1;
									const percentage = ele.percentage || itemPercentage(ele.total, averageCensus, "number");
									const percentageComparison =
										ele.percentage || itemPercentage(eleComparison?.total || 0, averageCensusComparison, "number");
									const { percentageDiff, itemTotalComparisonScaled, numberOfDays } = calculateDiffBetweenPercentages(
										percentage,
										percentageComparison,
										false,
										filter,
										filterComparison,
										eleComparison?.total,
										projectionDays
									);
									let comparisonColor = pickComparisonColor(percentageDiff);

									if (comparingAgainstDifferentFacility) {
										comparisonColor = null;
									}
									return (
										<Card
											key={index}
											style={{ width: "170px" }}
											className={`${styles.snfFacilitySlider} ${selected ? styles.selected : null}`}
										>
											<CardItemTooltip item={ele}>
												<CardContent className={styles.slideCardContent}>
													<Grid
														container
														key={"row" + index}
														direction="column"
														justifyContent="center"
														alignItems="center"
														spacing={2}
													>
														<Grid item xs={12}>
															<div className={styles.checkboxContainer}>
																<div className={`df fs15 fw500 ${styles.checkboxButtonContainer}`}>
																	<CheckboxButton
																		className={styles.checkboxButton}
																		labelId={labelId}
																		handleToggle={() =>
																			handleToggle({
																				item: ele,
																				type,
																				itemComparison: eleComparison,
																				isChecked: selectedItem.indexOf(ID) !== -1,
																				cardTitle,
																			})
																		}
																		checked={selectedItem.indexOf(ID) !== -1}
																	/>
																</div>
																<div className={styles.titleContainer} title={ele.label}>
																	<Typography sx={{ mb: 0 }} variant="subtitle1">
																		{ele.label}
																	</Typography>
																</div>
															</div>
														</Grid>
														<Grid item xs={12}>
															<div className={`df aic ${styles.textWrprReturn}`}>
																<div />
																<p className={`ffmar-bold fs12 fw700`}>
																	&nbsp;{calcProPercentsBasedOnFilterAndDays(ele.total, filter, projectionDays)}
																</p>
															</div>
														</Grid>
														<Grid item xs={12}>
															{!loading && (
																<ColorBox
																	sx={{ mt: 0.5 }}
																	color={comparisonColor}
																	type="button"
																	comparingAgainst={eleComparison?.total || 0}
																	comparingAgainstScaled={itemTotalComparisonScaled}
																	numberOfDays={numberOfDays}
																/>
															)}
															<Button className={styles.facilityButton}>{`${calcProPercentsBasedOnFilterAndDays(
																percentage,
																filter,
																projectionDays,
																true,
																isComparingAgainstAvgCensus ||
																	lockedTotalBy === "census" ||
																	(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
															)}%`}</Button>
														</Grid>
													</Grid>
												</CardContent>
											</CardItemTooltip>
										</Card>
									);
								})}
						</Slider>
					)}
				</div>
				{data.length === 0 && (
					<Stack direction={"row"} justifyContent="center" sx={{ ml: 20, mt: -15 }}>
						{data.length === 0 && <NoRecordFound data={data} />}
					</Stack>
				)}
			</Grid>
			<Grid item xs={2} sx={{ pr: "30px" }}>
				<div style={{ height: "92px", width: "100%", padding: "0px" }}>
					<ResponsivePie
						data={getChartData()}
						margin={{ top: 2, right: 0, bottom: 0, left: 0 }}
						padAngle={0}
						cornerRadius={0}
						activeOuterRadiusOffset={5}
						enableArcLinkLabels={false}
						enableArcLabels={false}
						innerRadius={0}
						colors={{ scheme: "category10" }}
						arcLinkLabel={"name"}
					/>
				</div>
			</Grid>
		</Grid>
	);
};

export default SNFFacilitySlider;
