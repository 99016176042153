import tooltipsInfo from "../utilis/tooltipContents";
import { ADMISSION_CARDS_TYPE } from "./admission.type";
import { DECEASED_CARDS_TYPE } from "./deceased.type";
import { HOSPITAL_CARDS_TYPE } from "./hospital.type";
import { OVERALL_CARDS_TYPE } from "./overall.type";

export const DECEASED_CUSTOM_CARD = [
    {
        value: DECEASED_CARDS_TYPE.INSURANCE_DATA,
        label: "Per Insurance",
        tooltipContent : tooltipsInfo.deceasedPage.perInsurance
    },
    {
        value: DECEASED_CARDS_TYPE.DOCTOR_DATA,
        label: "Per Doctor",
        tooltipContent : tooltipsInfo.deceasedPage.perDoctor
    },
    {
        value: DECEASED_CARDS_TYPE.FLOORS_DATA,
        label: "Per Floor",
        tooltipContent : tooltipsInfo.deceasedPage.perFloor
    },
    {
        value: DECEASED_CARDS_TYPE.NINETY_DAYS_DATA,
        label: "90 Days Analysis",
        tooltipContent : tooltipsInfo.deceasedPage["90DaysAnalysis"]
    },
];

export const OVERALL_CUSTOM_CARD = [
    { value: OVERALL_CARDS_TYPE.DOCTOR_DATA, label: "Per Doctor", tooltipContent : tooltipsInfo.overallPage.perDoctor },
    { value: OVERALL_CARDS_TYPE.FLOORS_DATA, label: "Per Floor", tooltipContent : tooltipsInfo.overallPage.perFloor },
    { value: OVERALL_CARDS_TYPE.INSURANCE_DATA, label: "Per Insurance", tooltipContent : tooltipsInfo.overallPage.perInsurance },
    { value: OVERALL_CARDS_TYPE.NINETY_DAYS_DATA, label: "90 Days Analysis", tooltipContent : tooltipsInfo.overallPage.ninetyDaysAnalisys },
];

export const ADMISSION_CUSTOM_CARD = [
    { value: ADMISSION_CARDS_TYPE.DOCTOR_DATA, label: "Per Doctor", tooltipContent : tooltipsInfo.admissionsPage.perDoctor },
    { value: ADMISSION_CARDS_TYPE.DAYS_DATA, label: "Per Day", tooltipContent : tooltipsInfo.admissionsPage.perDayOfWeek },
    { value: ADMISSION_CARDS_TYPE.DX_DATA, label: "Per Diagnosis", tooltipContent : tooltipsInfo.admissionsPage.perDiagnosis },
    { value: ADMISSION_CARDS_TYPE.INSURANCE_DATA, label: "Per Insurance", tooltipContent : tooltipsInfo.admissionsPage.perInsurance },
    { value: ADMISSION_CARDS_TYPE.FLOORS_DATA, label: "Per Floor", tooltipContent : tooltipsInfo.admissionsPage.perFloor },
    { value: ADMISSION_CARDS_TYPE.HOSPITAL_DATA, label: "Per Hospital", tooltipContent : tooltipsInfo.admissionsPage.perHospital },
    {
        value: ADMISSION_CARDS_TYPE.ADT_TAB,
        label: "View transfer status of Admissions",
    },
];

export const HOSPITAL_CUSTOM_CARD = [
    { value: HOSPITAL_CARDS_TYPE.DAYS_DATA, label: "Per Day", tooltipContent : tooltipsInfo.hospitalPage.perDayOfTheWeek },
    { value: HOSPITAL_CARDS_TYPE.DCER_DATA, label: "DC / ER" , tooltipContent : tooltipsInfo.hospitalPage["dc/er"]},
    { value: HOSPITAL_CARDS_TYPE.DOCTOR_DATA, label: "Per Doctor" , tooltipContent : tooltipsInfo.hospitalPage.perDoctor},
    { value: HOSPITAL_CARDS_TYPE.DX_DATA, label: "Per Diagnosis" , tooltipContent : tooltipsInfo.hospitalPage.perDiagnosis},
    { value: HOSPITAL_CARDS_TYPE.FLOORS_DATA, label: "Per Floor" , tooltipContent : tooltipsInfo.hospitalPage.perFloor},
    { value: HOSPITAL_CARDS_TYPE.SHIFT_DATA, label: "Per Shift", tooltipContent : tooltipsInfo.hospitalPage.perShift },
    {
        value: HOSPITAL_CARDS_TYPE.HOSPITALIZATIONS,
        label: "New Hospitalizations & Re-Hospitalizations",
        tooltipContent : tooltipsInfo.hospitalPage.newHospitalizationANDReHospitalization
    },
    { value: HOSPITAL_CARDS_TYPE.HOSPITAL_DATA, label: "Hospitals residents transferred to", tooltipContent : tooltipsInfo.hospitalPage.hospitalResidentsTransferred },
    { value: HOSPITAL_CARDS_TYPE.INSURANCE_DATA, label: "Per Insurance", tooltipContent : tooltipsInfo.hospitalPage.perInsurance },
    {
        value: HOSPITAL_CARDS_TYPE.NINETY_DAYS_DATA,
        label: "90 Days Analysis",
        tooltipContent : tooltipsInfo.hospitalPage["90DaysAnalysis"]
    },
    { value: HOSPITAL_CARDS_TYPE.NURSE_DATA, label: "Per Nurse", tooltipContent : tooltipsInfo.hospitalPage.perNurse },
    {
        value: HOSPITAL_CARDS_TYPE.RETURNS_DATA,
        label: "Returned / Didn't Return",
        tooltipContent : tooltipsInfo.hospitalPage["returned/didntReturn"]
    },
];

export const COMMUNITY_CUSTOM_CARD = [
    {
        value: "insuranceData",
        label: "Per Insurance",
        tooltipContent : tooltipsInfo.hospitalPage.perInsurance
    },
    {
        value: "doctorData",
        label: "Per Doctor",
        tooltipContent : tooltipsInfo.communityTrasnferPage.perDoctor
    },
    {
        value: "floorsData",
        label: "Per Floor",
        tooltipContent : tooltipsInfo.communityTrasnferPage.perFloor
    },
    {
        value: "sixtyDaysData",
        label: "60 Days Analysis",
        tooltipContent : tooltipsInfo.communityTrasnferPage["60DaysAnalysis"]
    },
    {
        value: "returnsData",
        label: "Returned / Didn't Return",
        tooltipContent : tooltipsInfo.communityTrasnferPage["returned/didntReturn"]
    },
    {
        value: "safeDischargeAssLivData",
        label: "Safe discharges per Assisted Living",
        tooltipContent : tooltipsInfo.communityTrasnferPage.safeDischargesPerAssitedLiving
    },
    {
        value: "snfFacilityData",
        label: "SNF Transfers per Facility",
        tooltipContent : tooltipsInfo.communityTrasnferPage.safeDischarges
    },
];
