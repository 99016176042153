import { CircularProgress } from "@mui/material";
import update from "immutability-helper";
import { cloneDeep } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import CloseIcon from "../../../../../assets/svgs/close.svg";
import DeleteSVG from "../../../../../assets/svgs/delete.svg";
import DragSVG from "../../../../../assets/svgs/drag.svg";
import EditSVG from "../../../../../assets/svgs/edit.svg";
import NewCloseSVG from "../../../../../assets/svgs/new-close.svg";
import axios from "../../../../../axios";
import { getAllQuestions } from "../../../../../services/api/question.api";
import { isOnlyHospitalTabAccess } from "../../../../../utilis/common";
import AlertDialog from "../../../../shared/AlertDialog";
import Button from "../../../../ui/button/Button";
import DropDown from "../../../../ui/drop-down/DropDown";
import styles from "./QuestionSetup.module.scss";
import { updateQuestionLabel } from "../../../../../services/api/data-input.api";

const QuestionSetup = ({ close }) => {
	const isOnlyHospitalDashboard = useMemo(() => {
		return isOnlyHospitalTabAccess();
	}, []);

	const transferOptions = useMemo(() => {
		if (!isOnlyHospitalDashboard) {
			return [
				{
					label: "Hospital Transfer",
					value: "hospitalTransfer",
				},
				{ label: "Safe Discharge", value: "safeDischarge" },
				{ label: "SNF", value: "SNF" },
				{ label: "AMA", value: "AMA" },
				{ label: "Deceased", value: "deceased" },
			];
		} else {
			return [
				{
					label: "Hospital Transfer",
					value: "hospitalTransfer",
				},
			];
		}
	}, [isOnlyHospitalDashboard]);

	const [list, setList] = useState(undefined);
	const [dragDisabled, setDragDisabled] = useState(false);
	const [showAddQ, setShowAddQ] = useState(false);
	const [confirmDialog, setConfirmDialog] = useState(false);
	const [addQVal, setAddQVal] = useState("");
	const [addTableQVal, setAddTableQVal] = useState("");
	const [type, setType] = useState("admission");
	const [transferType, setTransferType] = useState("hospitalTransfer");
	const [editQuestion, setEditQuestion] = useState(undefined);
	const [deleteIdx, setDeleteIdx] = useState(undefined);
	const [loading, setLoading] = useState(false);
	const { auth } = useSelector(({ auth }) => ({ auth }));

	useEffect(() => {
		if (isOnlyHospitalDashboard) {
			setType("transfer");
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onDragEnd = async (droppedItem, b) => {
		if (!droppedItem.destination) return;
		var updatedList = cloneDeep(list);
		const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
		updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

		let modifiedList = await updatedList.map((item, index) => {
			item.order.order = index;
			return item.order;
		});

		setDragDisabled(true);
		await axios.put(`/api/question/set/?id=${list[0]._id}`, modifiedList);
		setDragDisabled(false);
		setList(updatedList);
	};

	const getQuestions = async () => {
		const latestType = isOnlyHospitalDashboard && type !== "return" ? "transfer" : type;
		const questions = await getAllQuestions({
			facilityid: localStorage.getItem("facilityId"),
			forType: latestType,
			...(latestType === "transfer" && { forTransferType: transferType }),
		});
		// const questions = await axios.get(
		//   `/api/question/set/?forType=${type}&facilityid=${localStorage.getItem("facilityId")}${type === "transfer" ? `&forTransferType=${transferType}` : ""
		//   }`
		// );
		setList(questions);
	};

	const saveQuestion = async () => {
		let newQuestion;
		setLoading(true);
		if (editQuestion?._id) {
			const latestType = isOnlyHospitalDashboard && type !== "return" ? "transfer" : type;

			let updatedQ = await updateQuestionLabel(
				{
					label: addQVal,
					tableLabel: addTableQVal,
					forType: latestType,
					...(latestType === "transfer" && { forTransferType: transferType }),
				},
				editQuestion._id
			);

			let indexAAA = list.findIndex(item => item.question._id === editQuestion._id);

			update(list, {
				[indexAAA]: {
					question: {
						$set: updatedQ.data,
					},
				},
			});
			getQuestions();
			setLoading(false);
			setEditQuestion(undefined);
			setAddQVal("");
			setAddTableQVal("");
			setShowAddQ(false);
		} else {
			newQuestion = await axios.post("/api/question/set", {
				facilityId: localStorage.getItem("facilityId"),
				label: addQVal,
				tableLabel: addTableQVal,
				forType: type,
				forTransferType: transferType,
				active: true,
				type: "text",
				accessor: addQVal.replace(/\s/g, ""),
				allowAfter: true,
				isEditable: true,
				accountId: auth.accountId,
			});
			let modifiedList = await list.map((item, index) => {
				item.order.order = index;
				return item.order;
			});

			const newOrder = await axios.put(`/api/question/set/?id=${list[0]._id}`, [
				...modifiedList,
				{ order: list.length, questionId: newQuestion.data._id },
			]);

			getQuestions(newOrder.data);
			setLoading(false);
			setAddQVal("");
			setAddTableQVal("");
			setShowAddQ(false);
		}
	};

	useEffect(() => {
		getQuestions();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		getQuestions();
		// eslint-disable-next-line
	}, [type, transferType]);

	const deleteQuestion = async (id, idx) => {
		await axios.delete(`/api/question/set/?id=${id}`);

		// remove deleted question from list
		let updatedList = update(list, {
			$splice: [[idx, 1]],
		});

		let modifiedList = await updatedList.map((item, index) => {
			item.order.order = index;
			return item.order;
		});

		const newOrder = await axios.put(`/api/question/set/?id=${list[0]._id}`, modifiedList);
		getQuestions(newOrder.data);
	};

	return (
		<>
			{!!confirmDialog && (
				<AlertDialog
					content="New Question will be display for all account facility."
					handleClose={() => setConfirmDialog(false)}
					handleSubmit={() => {
						setConfirmDialog(false);
						saveQuestion(false);
					}}
				/>
			)}
			<div className={styles.qsOverlay}></div>
			<div className={styles.questionSetup}>
				<div className={`df aic ${styles.hdr}`}>
					<p className={`df aic  ffmar fs15 fw700`} style={{ opacity: 0.4 }}>
						Question Setup
					</p>
					<div className={`df aic mla`}>
						<div
							className={`df acc m-l-30 ${styles.close}`}
							onClick={() => {
								close();
								setEditQuestion(undefined);
							}}
						>
							<NewCloseSVG />
						</div>
					</div>
				</div>
				<div className={styles.content}>
					{showAddQ ? (
						<>
							<dov className={styles.addQOverlay} />
							<div className={styles.addQWrpr}>
								<div className={`df aic ${styles.hdr}`}>
									<p className={`df aic ffmar fs15 fw700`} style={{ opacity: 0.4 }}>
										{editQuestion?._id ? "Edit Question" : "Add Question"}
									</p>
									<div className={`df aic mla`}>
										<div
											className={`df acc ${styles.close}`}
											onClick={() => {
												setAddQVal("");
												setAddTableQVal("");
												setEditQuestion(undefined);
												setShowAddQ(false);
											}}
										>
											<CloseIcon />
										</div>
									</div>
								</div>
								<div className={`p-l-20 p-r-20 p-t-20 p-b-20`}>
									<p className={`ffmr fs12 m-b-8 ${styles.label}`}>Question Label</p>
									<input type="text" value={addQVal} onChange={(e) => setAddQVal(e.target.value)} />

									<p className={`ffmr fs12 m-b-6 m-t-12 ${styles.label}`}>Table Question Label</p>
									<input type="text" value={addTableQVal} onChange={(e) => setAddTableQVal(e.target.value)} />
									<div className={`df aic p-t-20 ${styles.ftr}`}>
										<div className={`df aic mla`}>
											<button
												disabled={loading}
												className={`df aic ffmr fs14 ttuc m-r-15 ${styles.text}`}
												onClick={() => {
													setShowAddQ(false);
													setEditQuestion(undefined);
													setAddQVal("");
													setAddTableQVal("");
												}}
											>
												Cancel
											</button>
											<button
												disabled={loading}
												className={`df aic ffmr fs14 ttuc`}
												onClick={() => {
													if (editQuestion?._id) {
														saveQuestion(false);
													} else {
														setConfirmDialog(true);
													}
												}}
											>
												{loading ? (
													<>
														<CircularProgress size={15} /> &nbsp; Saving...
													</>
												) : (
													"Save question"
												)}
											</button>
										</div>
									</div>
								</div>
							</div>
						</>
					) : undefined}
					<div className={`df aic ffmar fs15 fw500 p-r-20 p-l-20 ${styles.tabsWrpr}`}>
						{!isOnlyHospitalDashboard && (
							<>
								<div
									className={`df acc ${styles.tab} ${type === "admission" ? styles.active : ""}`}
									onClick={() => setType("admission")}
								>
									Admissions
								</div>
								{/* <div
									className={`df acc ${styles.tab} ${type === "readmission" ? styles.active : ""}`}
									onClick={() => setType("readmission")}
								>
									Readmissions
								</div> */}
							</>
						)}
						<div
							className={`df acc ${styles.tab} ${type === "return" ? styles.active : ""}`}
							onClick={() => setType("return")}
						>
							Returns
						</div>
						<div
							className={`df acc ${styles.tab} ${type === "transfer" ? styles.active : ""}`}
							onClick={() => setType("transfer")}
						>
							Transfers
						</div>
						{type === "transfer" ? (
							<DropDown
								options={transferOptions}
								selected={transferType}
								setOption={setTransferType}
								width={`275`}
								size={`medium`}
							/>
						) : undefined}

						<div className={`mla`}>
							<Button text="Add Question" buttonStyle="theme" size="medium" action={() => setShowAddQ(!showAddQ)} />
						</div>
					</div>
					<p
						style={{
							padding: "14px 22px 10px",
							fontStyle: "italic",
						}}
					>
						Click and drag questions to adjust the question order
					</p>
					{list ? (
						<div className={styles.questionList}>
							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId="droppable">
									{(provided, snapshot) => (
										<div {...provided.droppableProps} ref={provided.innerRef}>
											{list.map((itm, idx) => (
												<Draggable
													isDragDisabled={dragDisabled}
													draggableId={`id-${idx}`}
													key={`${idx}-${idx}`}
													index={idx}
												>
													{(provided, snapshot) => (
														<p
															className={`ffmsb df aic ${styles.line} ${snapshot.isDragging ? styles.dragging : ""}`}
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
														>
															<span className={styles.iconWrpr}>
																<DragSVG />
															</span>
															<span className={styles.num}>{idx + 1}.</span> {itm.order?.label || itm.question.label}
															{provided.placeholder}
															<div className={`df aic mla`}>
																<button
																	onClick={() => {
																		setEditQuestion(itm.question);
																		setAddQVal(itm.order?.label || itm.question.label);
																		setAddTableQVal(itm.order?.tableLabel || itm.question.tableLabel);
																		setShowAddQ(true);
																	}}
																	className={`m-r-15 df acc ${styles.action}`}
																>
																	<EditSVG />
																</button>
																{itm.question.isEditable && (
																	<>
																		<button
																			className={`df acc ${styles.action}`}
																			onClick={() => {
																				if (!itm.question.isEditable) return;
																				setDeleteIdx(idx);
																			}}
																		>
																			<DeleteSVG />
																		</button>
																		{deleteIdx === idx && (
																			<div className={`df aic ${styles.confirmDelete}`}>
																				<button
																					className={`m-r-10`}
																					onClick={() => {
																						setDeleteIdx(undefined);
																					}}
																				>
																					Cancel
																				</button>
																				<button
																					className={styles.confirmDeleteBtn}
																					onClick={() => {
																						deleteQuestion(itm.question._id, idx);
																						setDeleteIdx(undefined);
																					}}
																				>
																					Confirm
																				</button>
																			</div>
																		)}
																	</>
																)}
															</div>
														</p>
													)}
												</Draggable>
											))}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						</div>
					) : undefined}
				</div>
			</div>
		</>
	);
};

export default QuestionSetup;
