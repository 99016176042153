import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const Item = styled(Button)(({ theme }) => ({
    backgroundColor: "#EEF3F7",
    borderRadius: "3px",
    ...theme.typography.body2,
    padding: 6,
    textAlign: "center",
    height: 23,
    color: "#636578",
    marginLeft: 10,
    fontFamily: "inter",
    fontWeight: 500,
    fontSize: 11,
    lineHeight: 13,
    "&:hover": {
        background: "#EEF3F7",
    },
    "& span": {
        color: "#636578",
        fontWeight: 700,
        fontSize: 11,
        marginLeft: 2,
    },
    "@media (max-width: 1366px)": {
        marginLeft: 2,
    }
}));


export default function HeaderItem({ total, label, type = "filter" }) {
    if (total > 0 || type === "label") {
        return (
            <Item>
                {type === "label" ? label : (
                    <>{label}: <span>{total}</span></>
                )}
            </Item>
        );
    } else {
        return null
    }
}
