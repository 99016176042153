export const BackArrowSVG = (props) => (
  <svg
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.83203 0.568358L6.47852 5.41797C6.60547 5.57031 6.65625 5.72265 6.65625 5.875C6.65625 6.00195 6.60547 6.1543 6.50391 6.28125L1.85742 11.1309C1.62891 11.3848 1.22266 11.3848 0.994142 11.1562C0.740236 10.9277 0.740236 10.5469 0.968751 10.293L5.20899 5.875L0.943361 1.40625C0.714846 1.17773 0.714846 0.771482 0.968752 0.542967C1.19727 0.314451 1.60352 0.314451 1.83203 0.568358Z"
      fill={props.fill || "#84B9E0"}
    />
  </svg>
);
