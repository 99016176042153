import { useCallback, useEffect, useState } from "react";
import Button from "../../../../ui/button/Button";
import styles from "./AddPatient.module.scss";
import NewCloseSVG from "../../../../../assets/svgs/new-close.svg";

const types = [
    { label: "Admission", value: "admission" },
    { label: "Readmission", value: "readmission" }
];

const AdmissionOrReadmissionSelect = ({
    setAllFieldsValue,
    handleClose,
    allFields
}) => {
    const [selectedValue, setSelectedValue] = useState("");
    const [isSelected, setIsSelected] = useState(false);

    const handleOnClickButton = useCallback((type, value) => {
        setIsSelected(true);
        setSelectedValue(value)
        setAllFieldsValue(type, value)
    }, [setAllFieldsValue]);

    useEffect(() => {
        if (allFields.type) {
            if (allFields.type !== "admissionOrReadmission") {
                setIsSelected(true);
            }
            setSelectedValue(allFields.type)
        }
    }, [allFields]);

    return (
        <div className={styles.duplicatePopup}>
            <div className={styles.duplicateOverlay}></div>
            <div className={styles.duplicateContent}>
                <div className={`df aic ${styles.hdr}`}>
                    <p className={`df aic ffmm fs18`}>Please select ADT Type</p>
                    <div className={`mla`}>
                        <div className={`df acc ${styles.closeBtn}`} onClick={() => handleClose()}>
                            <NewCloseSVG />
                        </div>
                    </div>
                </div>


                <div className={`ffmr fs16 p-t-10 p-r-20 p-b-20 p-l-20 df ffc ${styles.messageWrpr} ${styles.stepOne} ${styles.addOrReaddWrap}`}>
                    {types.map((thisType) => (
                        <div
                            key={thisType.value}
                            style={{ userSelect: "none" }}
                            className={`ffmr fs16 tac ${styles.selectionButton} ${selectedValue === thisType.value ? styles.addOrReaddWrapSelected : ""}`}
                            onClick={() => {
                                return handleOnClickButton("type", thisType.value);
                            }}
                            onDoubleClick={() => {
                                if (thisType.value !== "transfer") {
                                    handleOnClickButton("type", thisType.value)
                                    handleClose("save")
                                }
                            }}
                        >
                            {thisType.label}
                        </div>
                    ))}
                </div>

                <div className={`df aic p-b-15 p-l-15 p-r-15`}>
                    <div className={`df aic mla`}>
                        <Button
                            disabled={!isSelected}
                            text={`Next`}
                            buttonStyle="theme"
                            size="medium"
                            action={() => handleClose("save")}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default AdmissionOrReadmissionSelect;
