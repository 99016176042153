const DeceasedSubSvg = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_876_387)">
        <path
          d="M14.7986 4.76092L10.5799 0.542172C10.2456 0.207977 9.78425 0.000976562 9.27537 0.000976562H2.92383C2.77821 0.000976562 2.66016 0.118996 2.66016 0.264648V17.7373C2.66016 17.883 2.77821 18.001 2.92383 18.001H15.0762C15.2218 18.001 15.3399 17.883 15.3399 17.7373V6.06543C15.3398 5.55658 15.1328 5.09515 14.7986 4.76092ZM11.1206 1.82748L13.5133 4.22022C13.5069 4.22015 13.5006 4.21973 13.4941 4.21973H11.1211V1.84668C11.1211 1.84025 11.1206 1.83392 11.1206 1.82748ZM14.8125 17.4736H3.1875V0.52832H9.2754C10.0024 0.52832 10.5938 1.11972 10.5938 1.84668V4.4834C10.5938 4.62905 10.7118 4.74707 10.8574 4.74707H13.4942C14.2211 4.74707 14.8125 5.33847 14.8125 6.06543V17.4736H14.8125Z"
          fill="currentColor"
        />
        <path
          d="M12.9668 6.85645H5.0332C4.88759 6.85645 4.76953 6.97446 4.76953 7.12012V9.22949C4.76953 9.37514 4.88759 9.49316 5.0332 9.49316C5.17882 9.49316 5.29688 9.37514 5.29688 9.22949V7.38379H12.7031V15.3643H5.29688V10.2842C5.29688 10.1385 5.17882 10.0205 5.0332 10.0205C4.88759 10.0205 4.76953 10.1385 4.76953 10.2842V15.6279C4.76953 15.7736 4.88759 15.8916 5.0332 15.8916H12.9668C13.1124 15.8916 13.2305 15.7736 13.2305 15.6279V7.12012C13.2305 6.97446 13.1124 6.85645 12.9668 6.85645Z"
          fill="currentColor"
        />
        <path
          d="M5.56055 3.69238H5.82422V5.53809C5.82422 5.97423 6.17905 6.3291 6.61523 6.3291C7.05142 6.3291 7.40625 5.97423 7.40625 5.53809V3.69238H7.66992C8.10611 3.69238 8.46094 3.33752 8.46094 2.90137C8.46094 2.46522 8.10611 2.11035 7.66992 2.11035H7.40625V1.84668C7.40625 1.41053 7.05142 1.05566 6.61523 1.05566C6.17905 1.05566 5.82422 1.41053 5.82422 1.84668V2.11035H5.56055C5.12436 2.11035 4.76953 2.46522 4.76953 2.90137C4.76953 3.33752 5.12436 3.69238 5.56055 3.69238ZM5.56055 2.6377H6.08789C6.23351 2.6377 6.35156 2.51968 6.35156 2.37402V1.84668C6.35156 1.70127 6.46983 1.58301 6.61523 1.58301C6.76064 1.58301 6.87891 1.70127 6.87891 1.84668V2.37402C6.87891 2.51968 6.99696 2.6377 7.14258 2.6377H7.66992C7.81533 2.6377 7.93359 2.75596 7.93359 2.90137C7.93359 3.04677 7.81533 3.16504 7.66992 3.16504H7.14258C6.99696 3.16504 6.87891 3.28306 6.87891 3.42871V5.53809C6.87891 5.68349 6.76064 5.80176 6.61523 5.80176C6.46983 5.80176 6.35156 5.68349 6.35156 5.53809V3.42871C6.35156 3.28306 6.23351 3.16504 6.08789 3.16504H5.56055C5.41514 3.16504 5.29688 3.04677 5.29688 2.90137C5.29688 2.75596 5.41514 2.6377 5.56055 2.6377Z"
          fill="currentColor"
        />
        <path
          d="M10.834 9.75684C10.834 8.73913 10.006 7.91113 8.98828 7.91113C7.97054 7.91113 7.14258 8.73913 7.14258 9.75684C7.14258 10.7745 7.97054 11.6025 8.98828 11.6025C10.006 11.6025 10.834 10.7745 10.834 9.75684ZM7.66992 9.75684C7.66992 9.02988 8.26132 8.43848 8.98828 8.43848C9.71524 8.43848 10.3066 9.02988 10.3066 9.75684C10.3066 10.4838 9.71524 11.0752 8.98828 11.0752C8.26132 11.0752 7.66992 10.4838 7.66992 9.75684Z"
          fill="currentColor"
        />
        <path
          d="M5.82422 13.4482V14.5732C5.82422 14.7189 5.94227 14.8369 6.08789 14.8369H11.9121C12.0577 14.8369 12.1758 14.7189 12.1758 14.5732V13.4482C12.1758 12.7213 11.5844 12.1299 10.8574 12.1299H7.14258C6.41562 12.1299 5.82422 12.7213 5.82422 13.4482ZM11.6484 13.4482V14.3096H11.1211V13.4482C11.1211 13.3026 11.003 13.1846 10.8574 13.1846C10.7118 13.1846 10.5937 13.3026 10.5937 13.4482V14.3096H7.40625V13.4482C7.40625 13.3026 7.2882 13.1846 7.14258 13.1846C6.99696 13.1846 6.87891 13.3026 6.87891 13.4482V14.3096H6.35156V13.4482C6.35156 13.0121 6.70639 12.6572 7.14258 12.6572H10.8574C11.2936 12.6572 11.6484 13.0121 11.6484 13.4482Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_876_387">
          <rect width="18" height="18" fill="white" transform="translate(0 0.000976562)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DeceasedSubSvg;
