const AddNewSvg = props => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.821 13.532C4.126 16.178 6.852 18 10 18C14.415 18 18 14.415 18 10C18 5.585 14.415 2 10 2C6.852 2 4.126 3.822 2.821 6.468C2.577 6.963 2.781 7.563 3.276 7.807C3.771 8.051 4.371 7.848 4.615 7.353C5.594 5.367 7.638 4 10 4C13.311 4 16 6.689 16 10C16 13.311 13.311 16 10 16C7.638 16 5.594 14.633 4.615 12.647C4.371 12.152 3.771 11.949 3.276 12.193C2.781 12.437 2.577 13.037 2.821 13.532ZM9 9H7C6.448 9 6 9.448 6 10C6 10.552 6.448 11 7 11H9V13C9 13.552 9.448 14 10 14C10.552 14 11 13.552 11 13V11H13C13.552 11 14 10.552 14 10C14 9.448 13.552 9 13 9H11V7C11 6.448 10.552 6 10 6C9.448 6 9 6.448 9 7V9ZM3 9C2.448 9 2 9.448 2 10C2 10.552 2.448 11 3 11C3.552 11 4 10.552 4 10C4 9.448 3.552 9 3 9Z"
      fill="currentColor"
    />
  </svg>
);

export default AddNewSvg;
