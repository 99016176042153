const HideSVG = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 490 490"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M244.998 489.996C180.021 489.996 117.708 464.184 71.758 418.238C25.813 372.289 0 309.968 0 244.998C0 180.028 25.812 117.708 71.758 71.758C117.707 25.813 180.028 0 244.998 0C309.968 0 372.288 25.812 418.238 71.758C464.183 117.707 489.996 180.028 489.996 244.998C489.996 288.006 478.676 330.252 457.172 367.498C435.668 404.744 404.742 435.67 367.496 457.174C330.25 478.678 288.004 489.998 244.996 489.998L244.998 489.996ZM244.998 34.996C189.303 34.996 135.888 57.121 96.508 96.504C57.125 135.887 35 189.301 35 244.994C35 300.687 57.125 354.104 96.508 393.484C135.891 432.867 189.305 454.992 244.998 454.992C300.691 454.992 354.108 432.867 393.488 393.484C432.871 354.101 454.996 300.687 454.996 244.994C454.996 189.301 432.871 135.884 393.488 96.504C354.105 57.121 300.691 34.996 244.998 34.996V34.996ZM402.498 244.996C402.498 240.355 400.654 235.902 397.373 232.621C394.092 229.34 389.639 227.496 384.998 227.496H104.998C98.7441 227.496 92.967 230.832 89.842 236.246C86.717 241.66 86.717 248.332 89.842 253.746C92.967 259.16 98.7443 262.496 104.998 262.496H384.998C389.639 262.496 394.092 260.652 397.373 257.371C400.654 254.09 402.498 249.637 402.498 244.996Z"
      fill="black"
    />
  </svg>
);

export default HideSVG;
