import React from "react";
import styles from "./FloorCardList.module.scss";
import CheckboxButton from "../../shared/checkboxButton/CheckboxButton";

const FloorCardListSkeleton = () => {
	const fakeData = [1, 2];
	return fakeData.map((x) => {
		return (
			<div className={`df aic m-b-10 ${styles.line} ${styles.noShadow} skeleton`} key={x}>
				<div className={`df fs15 fw500 ${styles.sec} ${styles.left} skeleton`} style={{ cursor: "default" }}>
					<CheckboxButton className="skeleton" size="small" disabled style={{ borderRadius: 5, padding: "0" }} />
				</div>
				<div className={`df acc ${styles.sec}`} style={{ cursor: "default" }}></div>
			</div>
		);
	});
};

export default FloorCardListSkeleton;
