import axios from "../../../axios";
import { useEffect, useState } from "react";
import CloseIcon from "../../../assets/svgs/close.svg";
import styles from "./AddAccount.module.scss";
import { useDispatch } from "react-redux";
import { ADD_ACCOUNT, ADD_NOTIFICATION, UPDATE_ACCOUNT } from "../../../store/types";
import classNames from "classnames";
import { Checkbox, FormControlLabel } from "@mui/material";
import { ACCOUNT_PERCENTAGE_BY, PAGE_TYPE } from "../../../types/pages.type";
import { LoadingButton } from "@mui/lab";

const AddAccount = props => {
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [dashboardAccess, setDashboardAccess] = useState(false);
    const [percentageBy, setPercentageBy] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const save = async () => {
        try {
            setIsError(false)
            if (!name) {
                setIsError(true)
                return
            }
            setIsLoading(true);
            if (isEdit) {
                let re = await axios.put("/api/account/" + props.currentEdit._id, {
                    name,
                });
                if (re && props.accountUpdated) {
                    props.accountUpdated(re.data);
                    dispatch({
                        type: UPDATE_ACCOUNT,
                        payload: re.data,
                    });
                    dispatch({
                        type: ADD_NOTIFICATION,
                        payload: {
                            type: "success",
                            label: "Account data updated successfully!",
                            id: "accountDataUpdated",
                        },
                    });
                }
                props.clearCurrentEdit && props.clearCurrentEdit();
            } else {
                const re = await axios.post("/api/account", {
                    name,
                    ...!isEdit && ({ dashboardAccess: dashboardAccess ? [PAGE_TYPE.HOSPITAL] : null }),
                    ...!isEdit && ({ percentageBy: percentageBy ? ACCOUNT_PERCENTAGE_BY.BED : null })
                });

                if (re && props.accountAdded) {
                    props.accountAdded(re.data);
                }
                dispatch({
                    type: ADD_ACCOUNT,
                    payload: re.data,
                });
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "success",
                        label: "Account added successfully!",
                        id: 'accountAdded'
                    },
                });
            }
            setIsLoading(false);
            props.close();
        } catch (e) {
            setIsLoading(false);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "error",
                    label: e?.response?.data?.message || "Error while trying to add account.",
                    id: 'addAccountError'
                },
            });
        }
    };

    useEffect(() => {
        if (props.currentEdit && props.currentEdit._id) {
            setIsEdit(true);
            setName(props.currentEdit.name);
        }
    }, [props.currentEdit]);

    return (
        <>
            <div className={styles.overlay}></div>
            <div className={styles.addPopup}>
                <div className={classNames("df aic", styles.hdr)}>
                    <p className={`ffmm fs18`}>{isEdit ? "Edit" : "Add"} account</p>
                    <div className={`mla`}>
                        <div
                            className={classNames("df acc", styles.closeBtn)}
                            onClick={() => {
                                props.close();
                                props.clearCurrentEdit();
                            }}
                        >
                            <CloseIcon />
                        </div>
                    </div>

                </div>{" "}
                <div className={styles.popupCntnt}>
                    <div className={`m-b-15 inputWrpr`}>
                        <label className={`ffmr fs12 ${isError && !name ? "error" : null}`}>Account Name</label>
                        <input
                            style={{
                                ...(isError && !name && { borderColor: "red" }),
                            }}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    {!isEdit && (
                        <>
                            <div className={`m-b-8 inputWrpr`}>
                                <FormControlLabel
                                    label="Only hospital dashboard access"
                                    className="ffmr fs12"
                                    slotProps={{
                                        typography: {
                                            sx: {
                                                mt: "10px",
                                                fontSize: "13px",
                                                fontFamily: "mont reg"
                                            }
                                        }
                                    }}
                                    control={
                                        <Checkbox
                                            checked={dashboardAccess}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setPercentageBy(true)
                                                } else {
                                                    setPercentageBy(false)
                                                }
                                                setDashboardAccess(e.target.checked)
                                            }}
                                        />
                                    }
                                />
                            </div>

                            {/* <div className={`m-b-6 inputWrpr`}>
                                <FormControlLabel
                                    label="Percentage by bed ?"
                                    className="ffmr fs12"
                                    slotProps={{
                                        typography: {
                                            sx: {
                                                mt: "10px",
                                                fontSize: "13px",
                                                fontFamily: "mont reg"
                                            }
                                        }
                                    }}
                                    control={
                                        <Checkbox
                                            checked={percentageBy}
                                            onChange={(e) => setPercentageBy(e.target.checked)}
                                        />
                                    }
                                />
                            </div> */}
                        </>
                    )}
                </div>
                <div className={`${styles.popupFtr}`}>
                    <div className={classNames("df aic", styles.actionsWrrp)}>
                        <div className={`mla`}>
                            <button
                                className={classNames("ffmr fs14 m-r-8", styles.text)}
                                onClick={() => {
                                    props.close();
                                    props.clearCurrentEdit && props.clearCurrentEdit();
                                }}
                            >
                                Cancel
                            </button>
                            <LoadingButton
                                variant="contained"
                                className={classNames("ffmr fs14")}
                                onClick={save}
                                loading={isLoading}
                            >
                                Save
                            </LoadingButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddAccount;
