import styles from "./DischargeEmergencyList.module.scss";
import { Checkbox } from "@mui/material";
import CardItemTooltip from "../../../shared/CardItemTooltip";
import { TotalTextWithBar } from "./DischargeEmergencyList";

const DischargeEmergencyListSkeleton = () => {
	return (
		<div className={`df aie ${styles.dcEr} ${styles.innerWrpr}`}>
			<div className={`ffmar df jcc ${styles.leftSec}`}>
				<div className={`df ffc aic ${styles.barWrpr}`} id={"DC"}>
					<TotalTextWithBar className="skeleton" />
					<div
						className={`df ffc acc ${styles.bar} skeleton`}
						style={{
							height: `20%`,
						}}
					>
						<TotalTextWithBar className="skeleton" />
					</div>
					<CardItemTooltip>
						<p className={`ffmar-bold fs14 m-t-8 cp`}>DC</p>
					</CardItemTooltip>
					<Checkbox
						sx={{ mr: 0.1 }}
						disabled
						className="skeleton"
						style={{ padding: "0", borderRadius: "5px", margin: "8px" }}
						inputProps={{ "aria-labelledby": "DC" }}
					/>
				</div>

				<div className={`df ffc aic ${styles.barWrpr}`} id={"ER"}>
					<TotalTextWithBar className="skeleton" />

					<div
						className={`df ffc acc ${styles.bar} skeleton`}
						style={{
							height: `30%`,
						}}
					>
						<TotalTextWithBar className="skeleton" />
					</div>
					<CardItemTooltip>
						<p className={`ffmar-bold fs14 m-t-8 cp`}>ER</p>
					</CardItemTooltip>
					<Checkbox
						sx={{ mr: 0.1 }}
						disabled
						className="skeleton"
						style={{ padding: "0", borderRadius: "5px", margin: "10px" }}
						inputProps={{ "aria-labelledby": "ER" }}
					/>
				</div>
			</div>

			<div
				className={`${styles.rightSec}`}
				style={{ height: "150px", display: "flex", justifyContent: "center", alignItems: "center" }}
			>
				<div className="skeleton" style={{ width: "150px", height: "150px", borderRadius: "9999px" }}></div>
			</div>
		</div>
	);
};

export default DischargeEmergencyListSkeleton;
