import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import { Checkbox, ClickAwayListener, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import classNames from "classnames";
import { Children, cloneElement, useEffect, useState } from "react";
import ExpandPopupSVG from "../../../assets/svgs/expand-popup.svg";
import MinimizeSVG from "../../../assets/svgs/minimize.svg";
import PopupPlusSVG from "../../../assets/svgs/popup-plus.svg";
import InfoIcon from "../../icon/InfoIcon";
import HtmlTooltip from "../../shared/HtmlTooltip";
import ReportButton from "../../shared/ReportButton";
import styles from "./Card.module.scss";
import CardTooltipContent from "./CardTooltipContent";

const Card = ({
	children,
	flex,
	title,
	secondaryTitle = "",
	secondaryTitleError = "",
	secondaryTitleClassName = "",
	minimizable,
	itemAlign,
	isDetailsOpen = true,
	isDisabled = false,
	handleOnClickAction,
	taggedList,
	loading = false,
	isSlider = false,
	tooltipContent = undefined,
	isMinimized,
	priorityNumber = null,
	setIsMinimized = () => void 0,
	handleOnClickReport,
	style,
	styleContent,
	canBeSearched,
	data,
	selectedItems,
	handleToggleAll,
	type,
	debug,
}) => {
	const [showDetails, setShowDetails] = useState(false);
	const usePropsMinimizeStatus = isMinimized !== undefined;
	const showStatus = usePropsMinimizeStatus ? isMinimized : showDetails;
	const [showSearch, setShowSearch] = useState(false);
	const [searchValue, setSearchValue] = useState("");

	const numberOfCheckedItems = data?.reduce((a, v) => {
		const itemId = v._id ? v._id : v.id;
		const isInSelectedItems = selectedItems.find((x) => x === itemId);
		if (isInSelectedItems) {
			return a + 1;
		}
		return a;
	}, 0);
	const allItemsChecked = numberOfCheckedItems === data?.length;

	useEffect(() => {
		setShowDetails(isDisabled ? false : isDetailsOpen);
	}, [isDetailsOpen, isDisabled]);

	const childrenWithProps = children
		? Children.map(children, (child) =>
				cloneElement(child, {
					searchValue,
				})
		  )
		: null;

	return (
		<div
			className={`${classNames(styles.card, isDisabled && styles.disabled)} dashboard-card-view `}
			style={{ flex, height: `${showStatus || loading ? "100%" : "fit-content"}`, ...style }}
		>
			<div className={(classNames("ffmar fs15 fw700 "), styles.cardHeaderContainer)}>
				<div className={classNames("df aic ffmar fs15 fw700", styles.cardHeader)}>
					{showSearch && canBeSearched && !loading ? (
						<ClickAwayListener
							mouseEvent="onMouseDown"
							touchEvent="onTouchStart"
							onClickAway={() => {
								showSearch && setShowSearch(false);
							}}
						>
							<TextField
								placeholder="Search"
								type="search"
								variant="outlined"
								fullWidth
								size="small"
								value={searchValue}
								onChange={(e) => {
									setSearchValue(e.target.value);
								}}
								sx={{ height: "29px" }}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
								}}
							/>
						</ClickAwayListener>
					) : (
						<>
							{canBeSearched && ((minimizable && isMinimized) || !minimizable) && (
								<Checkbox
									disableRipple
									edge="end"
									title="Select/Clear all"
									icon={<CheckCircleOutlineIcon />}
									checkedIcon={<CheckCircleIcon />}
									onChange={() => handleToggleAll({ items: data, type, cardTitle: title })}
									checked={allItemsChecked && !loading}
									disabled={loading}
									sx={{ p: 0, mr: "5px", pl: 0, ml: "-7px", borderRadius: "10000px" }}
								/>
							)}

							<div className={styles.cardTooltipIcon}>
								<HtmlTooltip content={!loading && <CardTooltipContent content={tooltipContent} />}>
									<IconButton
										disableFocusRipple={true}
										disableRipple={true}
										sx={{ cursor: loading ? "default" : "pointer" }}
									>
										<InfoIcon />
									</IconButton>
								</HtmlTooltip>
							</div>

							<Typography className={styles.title} variant="subtitle1" component="div">
								{title} &nbsp; &nbsp;<b>{priorityNumber && `#${priorityNumber}`}</b>
								{secondaryTitle && <div className={classNames("fs10", styles.secondaryTitle)}>{secondaryTitle}</div>}
								{secondaryTitleError && (
									<div className={classNames("fs10", styles.secondaryTitle, secondaryTitleClassName)}>
										{secondaryTitleError}
									</div>
								)}
							</Typography>

							<div className={`df aic mla`}>
								{canBeSearched && (
									<IconButton
										disableFocusRipple={true}
										disableRipple={true}
										sx={{
											width: 29,
											height: 29,
											mt: 0,
											mr: 1,
											borderRadius: "7px",
											border: loading ? "none" : "1px solid grey",
											backgroundColor: loading ? "transparent" : searchValue !== "" ? "#4879f5" : "#fff",
											color: loading ? "inherit" : "black",
											...(loading
												? {}
												: {
														":hover": {
															backgroundColor: "grey",
														},
														cursor: "pointer",
												  }),
										}}
										onClick={() => !loading && setShowSearch(true)}
										className={loading && "skeleton"}
									>
										{!loading && <SearchIcon sx={{ width: "20px", height: "20px" }} />}
									</IconButton>
								)}
								{handleOnClickReport &&
									(loading ? (
										<div
											style={{
												width: "29px",
												height: "29px",
												borderRadius: "5px",
												marginRight: "7px",
											}}
											className="skeleton"
										></div>
									) : (
										<ReportButton
											handleOnClickReport={handleOnClickReport}
											title={title}
											sx={{
												height: 28.5,
											}}
										/>
									))}
								{!isDisabled && (
									<div className={!loading && "cp"} onClick={() => handleOnClickAction(title)}>
										{loading ? (
											<div style={{ width: "29px", height: "29px", borderRadius: "5px" }} className="skeleton"></div>
										) : (
											<div style={{ width: "29px", height: "29px", borderRadius: "5px", marginTop: "0px" }}>
												<ExpandPopupSVG style={{ cursor: "pointer" }} fill="#4879F5" />
											</div>
										)}
									</div>
								)}
								{minimizable && (
									<div
										className="p-l-9 p-r-5 p-t-5 p-b-5 m-l-15"
										style={{ cursor: "pointer" }}
										onClick={() =>
											usePropsMinimizeStatus ? setIsMinimized(!isMinimized) : setShowDetails(!showDetails)
										}
									>
										{showStatus ? <MinimizeSVG /> : <PopupPlusSVG />}
									</div>
								)}
							</div>
						</>
					)}
				</div>
				{taggedList && taggedList}
			</div>
			{!isSlider && showStatus && isDisabled === false && (
				<div
					className={classNames("df", styles.cardContent, itemAlign ? itemAlign : "aic")}
					{...(styleContent && { style: styleContent })}
				>
					{childrenWithProps}
				</div>
			)}
			{isSlider && showStatus && isDisabled === false && childrenWithProps}
		</div>
	);
};

export default Card;
