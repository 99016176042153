const tooltipsInfo = {
  overallPage: {
    "+/-": `The number displayed represents the difference in the number of residents at the beginning of the selected time period compared to the end of the selected time period. The "projection" shows the difference in number we would have if we maintained the same rate over a 30-day period.`,
    ninetyDaysAnalisys: `The 90-day analysis presents data on the number of residents who were transferred out of the facility within the first 90 days of their latest admission. The 90-day period is divided into five intervals: 1-7 days, 8-14 days, 15-30 days, 31-60 days, and 61-90 days. The percentages next to each interval represents the proportion of residents who were transferred out of the facility that occurred within that interval, out of the total number of residents transferred out from the facility. This information helps to understand the distribution of residents who are transferred out over the first 90 days of a resident's latest admission to the facility.`,
    totalOutgoing: `The displayed number represents the total number of outgoing residents that the facility has had during the selected time period. This includes transfers to hospitals, community transfers, and residents who passed away. The percentage reflects the total number of outgoing residents against the average census of the time period. For example, if there were 25 outgoing residents and the average census was 100, the percentage would be 25%. The "projection" shows the number of outgoing residents we would have if we maintained the same rate over a 30-day period.`,
    totalIncoming: `The displayed number represents the total number of incoming residents that the facility has had during the selected time period. This includes Admissions, Readmissions, and Residents that returned from the hospital. The percentage reflects the total number of incoming residents against the average census of the time period. For example, if there were 50 incoming residents and the average census was 100, the percentage would be 50%. The "projection" shows the number of incoming residents we would have if we maintained the same rate over a 30-day period.`,
    perDoctor: `The number displayed here represents either the difference in the number of residents assigned to each doctor at the beginning and end of the selected time period, or the total number of outgoing or incoming transfers assigned to each doctor, depending on the selected view. The percentages next to each doctor indicate the proportion of either outgoing or incoming residents assigned to that doctor, depending on the selected view, compared to the total number of outgoing or incoming residents.`,
    perFloor: `The number displayed here represents either the difference in the number of residents assigned to each doctor at the beginning and end of the selected time period, or the total number of outgoing or incoming transfers assigned to each doctor, depending on the selected view. The percentages next to each doctor indicate the proportion of either outgoing or incoming residents assigned to that doctor, depending on the selected view, compared to the total number of outgoing or incoming residents.`,
    perInsurance: `The number displayed here represents either the difference in the number of residents assigned to each insurance at the beginning and end of the selected time period, or the total number of outgoing or incoming transfers assigned to each insurance, depending on the selected view. The percentages next to each insurance indicate the proportion of either outgoing or incoming residents assigned to that insurance, depending on the selected view, compared to the total number of outgoing or incoming residents.`,
  },
  hospitalPage: {
    totalHospitalization: `The Total Hospital Transfers number reflects the total number of residents transferred to the hospital during the selected time frame. The projection per month shows the number of transfers we would have if we maintained the same rate of Hospital Transfers as in the selected time period over a 30-day period. The percentage reflects the total number of hospitalizations against the average census of the time period. For example, if there were 10 hospital transfers and the average census was 100, the percentage would be 10%.`,
    unplannedHosspitalTransfers: `The Total Hospital Transfers reflect the number of unplanned hospitalizations during the selected time period. The projection per month shows the number of transfers we would have if we maintained the same rate of unplanned transfers as in the selected time period over a 30-day period.The percentage reflects the total number of Unplanned hospitalizations against the average census of the time period. For example, if there were 10 Unplanned hospital transfers and the average census was 100, the percentage would be 10%.`,
    plannedHospitalTransfers: `The Total Hospital Transfers reflect the number of Planned hospitalizations during the selected time period. The projection per month shows the number of transfers we would have if we maintained the same rate of Planned transfers as in the selected time period over a 30-day period.The percentage reflects the total number of Planned hospitalizations against the average census of the time period. For example, if there were 10 Planned hospital transfers and the average census was 100, the percentage would be 10%.`,
    newHospitalizationANDReHospitalization: `NEW HOSPITALIZATION refers to a resident who has been transferred to the hospital during the chosen dates and has not had a prior hospital transfer within the previous 30 days. The percentage of new hospitalizations is calculated by dividing the number of new hospitalization transfers by the total number of hospital transfers REHOSPITALIZATION is when a resident transfers to the hospital during the chosen dates and has had a prior hospitalization within the past 30 days. The percentage of Re-hospitalizations is calculated by dividing the number of Re-hospitalization transfers by the total number of hospital transfers`,
    "dc/er": `DC (discharge) is when a resident is admitted to the hospital during a transfer. The percentage of DCs is calculated by dividing the number of residents who were discharged by the total number of hospital transfers. ER (emergency room) is when a resident does not get admitted to the hospital during a transfer and only goes to the emergency room. The percentage of ER visits is calculated by dividing the number of residents who visited the emergency room but were not admitted to the hospital by the total number of hospital transfers.`,
    transfersPerInsurance: `This data segment displays the number of hospital transfers separated by insurance type. The percentage is calculated by dividing the number of hospital transfers covered by each insurance type by the total number of hospital transfers.`,
    "returned/didntReturn": `Returned refers to the number of residents who had a hospital transfer during the selected time frame and returned to the facility (even if the return occurred after the selected time frame). The percentage of returned residents is calculated by dividing the number of residents who returned by the total number of hospital transfers.                                                                                         Didnt Return refers to the number of residents who had a hospital transfer during the selected time frame and DID NOT (yet) return to the facility. The percentage is calculated by dividing the number of residents who didnt return by the total number of hospital transfers.`,
    "90DaysAnalysis": `The 90-day analysis presents data on the number of residents who were transferred to the hospital from the facility within the first 90 days of their latest admission. The 90-day period is divided into five intervals: 1-7 days, 8-14 days, 15-30 days, 31-60 days, and 61-90 days. The percentages next to each interval represents the proportion of hospital transfers that occurred within that interval, out of the total number of hospital transfers from the facility. This information helps to understand the distribution of transfers over the first 90 days of a resident's latest admission to the facility.`,
    perFloor: `This data segment provides information on the number of hospital transfers that occurred for each floor or unit within the facility during the selected time period. The percentages next to each floor or unit represents the proportion of transfers that occurred on that floor or unit, out of the total number of transfers from the facility during the same time period`,
    perShift: `This data segment shows the number of hospital transfers that occurred for each shift within the facility during a certain time period. The shifts are divided into three periods: 7:00 am to 2:59 pm, 3:00 pm to 10:59 pm, and 11:00 pm to 6:59 am. The percentages next to each shift represents the proportion of transfers that occurred during that shift, out of the total number of transfers from the facility during the same time period.`,
    perDayOfTheWeek: `This data segment presents information on the number of hospital transfers that occurred on each day of the week during the selected time period. The percentages next to each day represents the proportion of hospital transfers that occurred on that day, out of the total number of hospital transfers from the facility during the same time period`,
    perDiagnosis: `This data segment provides information on the number of hospital transfers that occurred from the facility during a the selected time period, organized by diagnosis. The percentages next to each diagnosis represents the proportion of transfers that were related to that diagnosis, out of the total number of hospital transfers from the facility during the same time period.`,
    perDoctor: `This data segment provides information on the number of hospital transfers that occurred from the facility during a the selected time period, organized by Doctor. The percentages next to each Doctor represents the proportion of transfers that were under the care of that Doctor, out of the total number of hospital transfers from the facility during the same time period.`,
    perNurse: `This data segment shows the number of hospital transfers that were recorded in the electronic medical record (EMR) by nurses during a certain time period. The percentages next to each nurse's name represents the proportion of transfers that were recorded by that nurse, out of the total number of transfers from the facility during the same time period`,
    perInsurance: `This data segment displays the number of hospital transfers separated by insurance type. The percentage is calculated by dividing the number of hospital transfers covered by each insurance type by the total number of hospital transfers.`,
    hospitalResidentsTransferred: `This data segment displays what hsopitals our resident were transferred to dudinrg the selected time frame. It also shows the percentage of transferred patients who did not return to the skilled nursing facility after being transferred to each hospital. The "total" percentage reflects the proportion of all hospital transfers during the selected time frame that involved each of the listed hospitals, while the didnt return" percentage indicates the proportion of residents who did not come back after being sent to a particular hospital, out of the total number of residents who were sent there.`,
  },
  communityTrasnferPage: {
    totalCommunityTransfers: `The number shown indicates the total number of residents who were either safely discharged to a lower level of care (Safe dicharge), transferred to the same level of care at a different facility (SNF), or left the facility Against Medical Advice (AMA). The percentage represents the total number of community transfers as a proportion of the average census during the selected time period. For example, if there were 10 Community Transfers and the average census was 100, the percentage would be 10%.  The projection per month shows the number of transfers we would have if we maintained the same rate of Community transfers as in the selected time period over a 30-day period`,
    safeDischarges: `The number shown indicates the total number of residents who were safely discharged to a lower level of care during the specified time period. The percentage represents the number of residents who were safely discharged to a lower level of care against the average census during the specified time period. For example, if there were 10 Safe Discharges and the average census was 100, the percentage would be 10%. The projection per month shows the number of transfers we would have if we maintained the same rate of Safe Discharges as in the selected time period over a 30-day period`,
    snf: `The number shown indicates the total number of residents who were transferred to the same level of care (another skilled nursing facility) during the specified time period. The percentage represents the number of residents who were transferred to the same level of care (another skilled nursing facility) against the average census during the specified time period. For example, if there were 10 SNF transfers and the average census was 100, the percentage would be 10%. The projection per month shows the number of transfers we would have if we maintained the same rate of SNF transfers as in the selected time period over a 30-day period`,
    ama: `The number shown indicates the total number of residents who transferred Against Medical Advice during the specified time period. The percentage represents the number of residents who transferred Against Medical Advice against the average census during the specified time period. For example, if there were 10 AMA transfers and the average census was 100, the percentage would be 10%. The projection per month shows the number of transfers we would have if we maintained the same rate of AMA transfers as in the selected time period over a 30-day period`,
    transfersPerInsurance: `This data segment displays the number of Community transfers separated by insurance type. The percentage is calculated by dividing the number of Community transfers covered by each insurance type by the total number of Community transfers.`,
    "60DaysAnalysis": `The 60-day analysis presents data on the number of residents who were transferred to the Community from the facility within the first 60 days of their latest admission. The 60-day period is divided into four intervals: 1-7 days, 8-14 days, 15-30 days, and 31-60 days. The percentages next to each interval represents the proportion of Community transfers that occurred within that interval, out of the total number of Community transfers from the facility. This information helps to understand the distribution of transfers over the first 60 days of a resident's latest admission to the facility.`,
    "returned/didntReturn": `Returned refers to the number of residents who had a Community transfer during the selected time frame and returned to the facility (even if the return occurred after the selected time frame). The percentage of returned residents is calculated by dividing the number of residents who returned by the total number of Community transfers.                                                                                         Didnt Return refers to the number of residents who had a Community transfer during the selected time frame and DID NOT (yet) return to the facility. The percentage is calculated by dividing the number of residents who didnt return by the total number of Community transfers.`,
    floor: `This data segment provides information on the number of Community transfers that occurred for each floor or unit within the facility during the selected time period. The percentages next to each floor or unit represents the proportion of transfers that occurred on that floor or unit, out of the total number of Community transfers from the facility during the same time period`,
    perDoctor: `This data segment provides information on the number of Community transfers that occurred from the facility during a the selected time period, organized by Doctor. The percentages next to each Doctor represents the proportion of transfers that were under the care of that Doctor, out of the total number of Community transfers from the facility during the same time period.`,
    safeDischargesPerAssitedLiving: `This data segment displays the number of safe discharges that were transferred to assisted living facilities and identifies which assisted living facilities these safe discharges were transferred to. The percentage next to the "Total number of Assisted Living Transfers" represents the proportion of residents who were transferred to assisted living facilities out of the total number of safe discharges during the selected time frame. The percentage next to each assisted living facility indicates the proportion of residents who were transferred to that specific assisted living facility out of the total number of residents transferred to assisted living facilities.`,
  },
  admissionsPage: {
    totalAdmissions: `The number shown indicates the total number of residents who were Admitted to the facility including New Admisisons and Re-Admisisons. The percentage is derived by taking the total admission number and dividing it by the average census. For example, if the average census is 100 and we received 10 total admissions, the percentage would be 10%. The projection per month shows the number of Total Admisisons we would have if we maintained the same rate of Total Admisisons as in the selected time period over a 30-day period`,
    newAdmissions: `The number displayed here represents the new admissions to the facility during the selected time frame. This includes residents who were admitted for the first time, as well as those who transferred to the hospital over 6 months ago but are now returning to the facility. It also includes residents who have transferred to a lower level of care, such as an assisted living facility, or the same level of care and are returning to the facility.The percentage is derived by taking the new admissions number and dividing it by the average census. For example, if the average census is 100 and we received 10 new admissions, the percentage would be 10%. The projection per month shows the number of new admisisons we would have if we maintained the same rate of new admissions as in the selected time period over a 30-day period`,
    reAdmissions: `The number displayed here represents the Re-admissions to the facility during the selected time frame. This refers to residents who were who transferred to the hospital less than 6 months and are now returning to the facility. The percentage is derived by taking the re-admissions number and dividing it by the average census. For example, if the average census is 100 and we received 10 re-admissions, the percentage would be 10%. The projection per month shows the number of re-admisisons we would have if we maintained the same rate of new admissions as in the selected time period over a 30-day period`,
    perDoctor: `This data segment provides information on the number of admissions that took place during a the selected time period, organized by Doctor. The percentages next to each Doctor representss the proportion of admissions that were assigned to that Doctor, out of the total number of admissions from the facility during the same time period.`,
    perDayOfWeek: `This data segment presents information on the number of admissions that took place on each day of the week during the selected time period. The percentages next to each day representss the proportion of admissions that took place on that day, out of the total number of admissions from the facility during the same time period`,
    perDiagnosis: `This data segment provides information on the number of admissions that the facility recieved during a the selected time period, organized by diagnosis. The percentages next to each diagnosis representss the proportion of admissions that were related to that diagnosis, out of the total number of admissions from the facility during the same time period.`,
    perInsurance: `This data segment displays the number of admissions separated by insurance type. The percentage is calculated by dividing the number of admissions covered by each insurance type by the total number of admissions.`,
    perFloor: `This data segment displayes the number of admissions that each floor or unit recieved during the selected time period. The percentages next to each floor or unit represents the proportion of admssions that those floor or unit recieved, out of the total number of admisisons that the facility recieved during the same time period`,
    perHospital: `This data segment displays the number of admissions to the facility from each hospital (or skilled nursing facility) during the selected time frame. The percentages next to each number reflect the proportion of residents the facility received from that particular hospital, compared to the total number of admissions. For example, if the percentage next to a certain hospital's name is 50%, it means that the facility received half of its total admissions from that hospital.`,
  },
  deceasedPage: {
    deceased: `The number shown indicates the total number of residents who passed away during the selected time frame. The percentage is derived by taking the number of residents, that passed away, and dividing it by the average census. For example, if the average census is 100 and we received 10 residents passed away, the percentage would be 10%. The projection per month shows the number if we were to utilize the same pace over a 30 day period`,
    perInsurance: `This data segment displays the number of residents, that passed away, during the selected time period, organized by insurance type. The percentages next to each insurance represents the proportion of  those residents that were covered by that insurance, out of the total number of residents, that passed away, from the facility during the same time period.`,
    perDoctor: `This data segment provides information on the number of residents that passed away during the selected time period, organized by Doctor. The percentages next to each Doctor represents the proportion of residents that were assigned to that Doctor, out of the total number of residents, that passed away, during the same time period.`,
    perFloor: `This data segment displays the number of residents, that passed away, seperated by floor or unit. The percentages next to each floor or unit represents the proportion of residents, that passed away, from each unit, out of the total number of residents, that passed away, during the selected time period`,
    "90DaysAnalysis": `The 90-day analysis presents data on the number of residents who passed away from the facility within the first 90 days of their latest admission. The 90-day period is divided into five intervals: 1-7 days, 8-14 days, 15-30 days, 31-60 days, and 61-90 days. The percentages next to each interval represents the proportion of residents, that passed away, that occurred within that interval, out of the total number of residents that passed away.`,
  },
};

export default tooltipsInfo;
