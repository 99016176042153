import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, ListItemText, MenuItem, Select, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { getSettings } from "../../../services/settings.service";
import { PAGE_TYPE } from "../../../types/pages.type";
import _ from "lodash";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const CustomExcelReportColumnDialog = ({
    handleClose,
    handleSave,
    selectedQuestions,
    pageType,
    selectedTab,
    subPageType
}) => {
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [loading, setLoading] = useState(false);

    const getSettingsData = async (filter) => {
        setLoading(true)
        const res = await getSettings(filter);
        setLoading(false);
        if (res && res.length > 0) {
            setSelectedColumns(res[0]?.selectedColumns);
        }
    }

    useEffect(() => {
        getSettingsData({
            pageType: subPageType === PAGE_TYPE.ADT ? subPageType : pageType,
            selectedTab,
            subPageType
        })
        // eslint-disable-next-line
    }, [selectedQuestions, pageType, selectedTab, subPageType]);

    const handleChange = (event) => {
        const value = event.target.value;
        if (value && value.length === 1 && value[0] === "selectAll") {
            console.log(value, "value");
            // setSelectedColumns(["selectAll"]);
        } else {
            setSelectedColumns(
                typeof value === 'string' ? value.split(',') : value,
            );
        }
    }

    const handleChangeSelectAll = async (event) => {
        const value = event.target.checked;
        if (value) {
            const values = selectedQuestions?.filter((ele) => ele?.question?.accessor !== "transferType")?.map((ele) => ele?.question?.accessor);
            setSelectedColumns([...values, "selectAll"]);
        } else {
            setSelectedColumns([]);
        }
    }

    return (
        <Dialog open={true} onClose={handleClose} maxWidth="sm" fullWidth={true}>
            <DialogTitle>Excel column selection</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Select the column you want to show in the excel report
                </DialogContentText>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl sx={{ m: 1 }} fullWidth>
                            {/* <InputLabel id="column-selector-label">Select Columns</InputLabel> */}
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <Select
                                    labelId="column-selector-label"
                                    id="column-selector"
                                    multiple
                                    value={selectedColumns?.filter(item => item !== "selectAll")}
                                    onChange={handleChange}
                                    renderValue={(selected) => {
                                        if (selected.length > 0) {
                                            return selectedQuestions?.filter((ele) => _.includes(selected, ele?.question?.accessor) && ele?.question?.accessor !== "transferType").map((column) => column?.question?.label).join(', ')
                                        }
                                        return ""
                                    }}
                                    MenuProps={MenuProps}
                                    disabled={loading}                                    
                                >
                                    <MenuItem key={"selectAll"} value={"selectAll"}>
                                        <Checkbox onChange={handleChangeSelectAll} checked={selectedColumns.indexOf("selectAll") > -1} />
                                        <ListItemText primary={"Select All"} />
                                    </MenuItem>
                                    {selectedQuestions && selectedQuestions?.length > 0 && selectedQuestions?.filter((ele) => ele?.question?.accessor !== "transferType").map((column) => (
                                        <MenuItem key={column?.question?._id} value={column?.question?.accessor}>
                                            <Checkbox checked={selectedColumns.indexOf(column?.question?.accessor) > -1} />
                                            <ListItemText primary={column?.question?.label} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={2}>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        autoFocus
                        onClick={() => handleSave(selectedColumns)}
                        disabled={loading}
                    >
                        Generate excel report
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}

export default CustomExcelReportColumnDialog;   