import { Box, Chip, Divider, IconButton, InputAdornment, ListItemText, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import classNames from "classnames";
import _ from "lodash";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import AddNewSvg from "../../assets/svgs/AddNewSvg.svg";
import { removeAllActiveFacilities } from "../../store/reducers/activeFacilities.slice";
import { setDefaultAdmissionData } from "../../store/reducers/admission.slice";
import { setEndDateOfADT, setFacilityLoaded, setFilterDateUpdate, setSelectedAccount } from "../../store/reducers/common.slice";
import { setDefaultCommunityTransferData } from "../../store/reducers/community-transfer.slice";
import { setResetComparison } from "../../store/reducers/comparisonReducers/comparison.slice";
import { setDefaultDeceasedData } from "../../store/reducers/deceased.slice";
import { setDefaultHospitalData } from "../../store/reducers/hospital.slice";
import { setDefaultOverAllData } from "../../store/reducers/overall.slice";
import { SET_ACCOUNTS } from "../../store/types";
import CustomPopover from "../shared/custom-popover";
import Scrollbar from "../shared/scrollbar";
import styles from "./Header.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import useSetParamToURL from "../hooks/useSetParamToURL";

const AccountSelection = (props) => {
	const [searchText, setSearchText] = useState('');
	const {
		account = [],
		expanded,
		accountRef = null,
		setAccountAnchorEl,
		accountAnchorEl,
	} = props;

	const dispatch = useDispatch();
	const { setParamToURL, getAccountId } = useSetParamToURL();

	const selectedAccountId = useMemo(() => {
		return getAccountId() || localStorage.getItem("accountId");
	}, [getAccountId]);

	const getAccountName = () => {
		if (!Array.isArray(account)) return;
		const sAccount = account.find((acc) => {
			return acc._id === selectedAccountId;
		});
		return sAccount ? sAccount.name : "Select Account";
	};

	return (
		account?.length > 0 && (
			<div className={`m-b-20 ${styles.selectWrpr} ${!expanded ? styles.expandedAccount : ""}`}>
				{expanded ? (
					<p className={`${styles.selectOpener}`} ref={accountRef}>
						{getAccountName()}
						<span className={classNames(accountAnchorEl && styles.active, styles.selectMenuIcon)}>
							<AddNewSvg />
						</span>
					</p>) : (
					<>
						<Tooltip title="Accounts">
							<IconButton
								ref={accountRef}
								size="small"
								style={{ backgroundColor: 'transparent' }}
							>
								<ManageAccountsIcon sx={{ width: 30, height: 30 }} />
							</IconButton>
						</Tooltip>
						<Tooltip title={getAccountName()}>
							<Chip
								label={<Typography
									textAlign={"center"}
									sx={{
										fontFamily: "Manrope",
										fontStyle: "normal",
										fontWeight: 600,
										fontSize: "10px",
										lineHeight: "14px",
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										maxWidth: "40px",
									}}
								>
									{getAccountName()}
								</Typography>
								}
								size="small"
								sx={{ ml: "-1px" }}
							/>
						</Tooltip>
					</>
				)}
				<CustomPopover
					anchorEl={accountAnchorEl}
					open={accountAnchorEl !== null}
					arrow="left-center"
					onClose={() => {
						setAccountAnchorEl(null);
					}}
					PaperProps={{
						style: { width: '300px' },
					}}
				>
					<Typography variant="h6" sx={{ p: 1.5 }}>
						Accounts <Typography component="span">({account?.length || 0})</Typography>
					</Typography>
					<TextField
						fullWidth
						sx={{ px: 1.5, pb: 1.5 }}
						placeholder="Search account"
						size="small"
						onChange={(e) => setSearchText(e.target.value)}
						value={searchText}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/>
					<Divider />
					<Scrollbar sx={{ height: 250 }}>
						{account?.length &&
							account.length > 0 &&
							Array.isArray(account) &&
							_.orderBy(account, "name", "ASC")
								.filter(ele => ele.name?.toLowerCase().includes(searchText?.toLowerCase()))
								.map((acc, index) => (
									<MenuItem
										selected={selectedAccountId === acc._id}
										key={acc._id}
										sx={{
											p: 1.5,
											"&.Mui-selected": {
												background: "#2259c9",
												".MuiTypography-root": {
													color: "#fff !important",
												},
											},
										}}
									>
										<ListItemText
											primary={
												<Box display="flex" alignItems="center">
													<Box
														sx={{
															backgroundColor: "#d9d9d9",
															width: "6px",
															height: "6px",
															borderRadius: "9999px",
															marginRight: "10px",
														}}
													/>
													{acc?.name}
												</Box>
											}
											primaryTypographyProps={{
												typography: "subtitle1",
												className: "ffmar fs15",
											}}
											onClick={async () => {
												setAccountAnchorEl(null);
												dispatch(setFilterDateUpdate(true));
												dispatch(setFacilityLoaded(false));
												dispatch(setDefaultHospitalData());
												dispatch(setDefaultAdmissionData());
												dispatch(setDefaultCommunityTransferData());
												dispatch(setDefaultDeceasedData());
												dispatch(setDefaultOverAllData());
												dispatch(removeAllActiveFacilities());
												localStorage.removeItem("facilityId");
												dispatch(setResetComparison(true));
												dispatch(setEndDateOfADT(null));
												await dispatch(setSelectedAccount(acc));
												await setParamToURL([{ key: "accountId", value: acc._id }]);
												// localStorage.setItem("accountId", acc._id);
												dispatch({ type: SET_ACCOUNTS, payload: "refreshing" });
											}}
										/>
									</MenuItem>
								))}
						{Array.isArray(account) && (account?.filter(ele => ele.name?.toLowerCase().includes(searchText?.toLowerCase())).length === 0 || account?.length === 0) &&
							<Typography sx={{ px: 1, py: 2, alignItems: "center" }}>No account found</Typography>
						}
					</Scrollbar>
				</CustomPopover>
			</div >
		)
	);
};

export default AccountSelection;
