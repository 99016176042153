import api from './api';

const API_PREFIX = "api/question";

const getAllQuestions = async (params) => {
    const response = await api.get(`${API_PREFIX}/set`, { params });
    return response;
};

export {
    getAllQuestions
};
