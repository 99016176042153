import { Grid } from "@mui/material";

const HospitalRowContainer = ({ children, spacing = 1.2, sx }) => {
    return (
        <Grid
            container
            spacing={spacing}
            sx={{ mb: "20px", ...sx }}
            display="flex"
            direction={"row"}
        >
            {children}
        </Grid>
    )
}

export default HospitalRowContainer