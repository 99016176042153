import * as React from "react";
import { styled } from "@mui/material/styles";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Badge, IconButton } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { round } from "lodash";

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        right: 24,
        color: "#fff",
        fontSize: "14px",
        top: 32,
        borderRadius: "5px",
        marginRight: "1.6px",
        height: "30px",
        width: "65%"
    },
}));


const LockIconButton = ({ children, handleClick }) => {
    return (
        <IconButton
            disableFocusRipple={true}
            sx={{
                height: "60px",
            }}
            onClick={handleClick}
        >
            {children}
        </IconButton>
    )
}
export default function IconWithCount({
    count = 0,
    isLockedTotal,
    handleChange,
    background = "-webkit-linear-gradient(45deg, #14AAE9 0%, #8B47DA 100%)",
}) {
    const [isOpen, setIsOpen] = React.useState(false);
    React.useEffect(() => {
        setIsOpen(isLockedTotal);
    }, [isLockedTotal]);

    if (count > 0) {
        if (!isOpen) {
            return (
                <LockIconButton
                    handleClick={handleChange}
                >
                    <StyledBadge
                        sx={{
                            ".MuiBadge-badge": {
                                background,                                
                                ...(count && round(count).toString().length === 5 && {
                                    width: "80% !important",
                                    fontSize: "13.5px",
                                }),
                                ...(count && round(count).toString().length >= 6 && {
                                    width: "84% !important",
                                    fontSize: "12px",
                                })
                            },
                        }}
                        badgeContent={round(count)}
                        color="success"
                        max={9999999}
                    >
                        <LockOpenIcon
                            sx={{
                                fontSize: 51,
                                color: "#fff",
                            }}
                            color="primary"
                        />
                    </StyledBadge>
                </LockIconButton>
            );
        } else {
            return (
                <LockIconButton
                    handleClick={handleChange}
                >
                    <StyledBadge
                        sx={{
                            ".MuiBadge-badge": {
                                background,
                                ...(count && round(count).toString().length === 5 && {
                                    width: "80% !important",
                                    fontSize: "13.5px",
                                }),
                                ...(count && round(count).toString().length >= 6 && {
                                    width: "84% !important",
                                    fontSize: "12px",
                                })
                            },
                        }}
                        badgeContent={round(count)}
                        color="success"
                        max={9999999}
                    >
                        <LockOutlinedIcon
                            sx={{
                                fontSize: 51,
                                color: "#E7B400",
                                animation: "background-fade 2s forwards",
                                "@keyframes background-fade": {
                                    "99.9%": {
                                        color: "#E7B400"
                                    },
                                }
                            }}
                            color="primary"
                        />
                    </StyledBadge>
                </LockIconButton>
            );
        }
    }
    return null;
}
