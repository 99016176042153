/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import _ from "lodash";
import moment from "moment";
import { ADMISSION_CARDS_TYPE } from "../types/admission.type";
import { ADT_TABLE_TYPE, CHART_FILTER_DAY_OF, FILTER_TYPES } from "../types/common.type";
import { CO_TRANSFER_CARDS_TYPE } from "../types/community-transfer.type";
import { DECEASED_CARDS_TYPE } from "../types/deceased.type";
import { HOSPITAL_CARDS_TYPE } from "../types/hospital.type";
import { OVERALL_CARDS_TYPE } from "../types/overall.type";
import { itemPercentageChart } from "./common";
import { toDisplayTime } from "./date-formats";

export async function getQuestionAnswer(question, row) {
	const { accessor, type, validationType, multiple } = question;
	if (type === "text") {
		return row[accessor] ? row[accessor] : null;
	} else if (type === "toggle") {
		// eslint-disable-next-line eqeqeq
		return row[accessor] && row[accessor] != "" ? "Yes" : "No";
	} else if (type === "date") {
		return row[accessor] ? toDisplayTime(row[accessor], "MM/DD/YYYY") : "-";
	} else if (type === "time") {
		return row[accessor] ? row[accessor] : null;
	} else if (type === "validation") {
		if (multiple) {
			return row[validationType].map((prodData) => prodData.label).join(", ");
		} else {
			const labelAccessor = accessor === "payerSourceInsurance" && validationType === "insurance" ? "payerSourceInsurance" : validationType;
			return row[labelAccessor] ? row[labelAccessor]?.label : "N/A";
		}
	}
	return null;
}

export async function chartsOverallData(data, filters = null) {
	if (filters) {
		var a = moment(filters.startDate);
		var b = moment(filters.endDate).add(1, "days");
		let chartsData1 = [];
		for (var m = moment(a); m.isBefore(b); m.add(1, "days")) {
			const date = m.format("YYYY-MM-DD");
			const allOverallByDate = _.filter(data, { dateOfADT: date });
			if (allOverallByDate.length > 0) {
				const allOverAll = _.groupBy(allOverallByDate, "isOutgoing");
				if (allOverAll) {
					for (const [key, value] of Object.entries(allOverAll)) {
						const total = value.length;
						let totalIN = 0;
						let totalOut = 0;

						if (key !== undefined) {
							if (key == "true") {
								totalOut = total;
							} else {
								totalIN = total;
							}
						}
						let facilityIds = _.map(value, "facilityId");
						let facilityWiseData = _.groupBy(value, "facilityId");
						chartsData1.push({
							x: date,
							y: totalIN - totalOut,
							totalIncoming: totalIN,
							totalOutgoing: totalOut,
							total: totalIN - totalOut,
							facilityIds,
							facilityWiseData: facilityWiseData,
						});
					}
				}
			} else {
				chartsData1.push({
					x: date,
					y: 0,
					totalIncoming: 0,
					totalOutgoing: 0,
					total: 0,
					facilityIds: [],
					facilityWiseData: null,
				});
			}
		}
		if (chartsData1.length > 0) {
			return _.sortBy(chartsData1, "x");
		} else {
			return chartsData1;
		}
	}
}

export async function chartsData(data, queryFilters = null) {
	let chartsData = [];
	if (queryFilters) {
		const start = queryFilters.startDate;
		const end = queryFilters.endDate;
		let loop = new Date(start);
		while (loop <= end) {
			let currDate = moment(loop).format("YYYY-MM-DD");
			let dateOfADTExist = _.filter(data, { dateOfADT: currDate });

			if (dateOfADTExist && dateOfADTExist.length > 0) {
				let facilityIds = _.map(dateOfADTExist, "facilityId");
				let facilityWiseData = _.groupBy(dateOfADTExist, "facilityId");
				chartsData.push({
					x: currDate,
					y: dateOfADTExist.length,
					total: dateOfADTExist.length,
					facilityIds,
					facilityWiseData: facilityWiseData,
				});
			} else {
				chartsData.push({
					x: currDate,
					y: 0,
					total: 0,
					facilityIds: [],
					facilityWiseData: null,
				});
			}
			let newDate = loop.setDate(loop.getDate() + 1);
			loop = new Date(newDate);
		}
	} else {
		if (data && data.length > 0) {
			data.map((ele) => {
				chartsData.push({
					x: ele.dateOfADT,
					y: 1,
					total: 1,
					facilityIds: [ele?.facilityId],
					facilityWiseData: { [ele?.facilityId]: ele },
				});
			});
		}
	}

	if (chartsData.length > 0) {
		return _.sortBy(chartsData, "x");
	} else {
		return chartsData;
	}
}

async function get30DaysFromDates(startDateA, endDateB, days = 29) {
	var dateStart = moment(startDateA);
	var dateEnd = moment(endDateB);

	let betweenMonthsLatest = [];
	let interimEndDate = dateEnd.clone();

	while (dateStart <= interimEndDate) {
		if (betweenMonthsLatest.length == 0) {
			betweenMonthsLatest.push(dateEnd.format("YYYY-MM-DD"));
		} else {
			betweenMonthsLatest.push(interimEndDate.subtract(1, "day").format("YYYY-MM-DD"));
		}
		interimEndDate.subtract(days, "days");
	}
	return betweenMonthsLatest;
}

function customizeMerge(obj, src) {
	if (_.isArray(obj)) {
		return obj.concat(src);
	}
}

export async function chartGroupBy(data, duration, admissionTotal = 0, censusByPeriod = [], queryFilters) {
	let formatted = [];
	let dates = [];
	let uniqueDates = [];

	if (duration === CHART_FILTER_DAY_OF.THIRTY || duration === CHART_FILTER_DAY_OF.SEVEN) {
		formatted = data.map((elem) => {
			return {
				x: moment(elem.x).format("YYYY-MM-DD"),
				y: elem.y,
				total: elem.total,
				facilityIds: elem?.facilityIds,
				facilityWiseData: elem?.facilityWiseData,
			};
		});
		const thirtyDaysDates = await get30DaysFromDates(
			queryFilters.startDate,
			queryFilters.endDate,
			duration === CHART_FILTER_DAY_OF.THIRTY ? 29 : 6
		);
		dates = formatted.map((elem) => elem.x);
		uniqueDates = thirtyDaysDates;
	} else {
		formatted = data.map((elem) => {
			return {
				x: moment(elem.x).startOf(duration).format("YYYY-MM-DD"),
				y: elem.y,
				total: elem.total,
				facilityIds: elem?.facilityIds,
				facilityWiseData: elem?.facilityWiseData,
			};
		});
		dates = formatted.map((elem) => elem.x);
		uniqueDates = dates.filter((x, index) => dates.indexOf(x) === index);
	}

	let isBroken = false;

	let latestData = uniqueDates.map((x, index) => {
		if (!isBroken) {
			let y = formatted.filter((elem) => elem.x === x).reduce((y, elem) => y + elem.y, 0);

			let facilityIds = formatted.filter((elem) => elem.x === x).reduce((y, elem) => y.concat(elem.facilityIds), []);

			let facilityWiseData = formatted
				.filter((elem) => elem.x === x)
				.reduce((y, elem) => {
					return _.mergeWith(y, elem?.facilityWiseData, customizeMerge);
				}, {});

			const percentage = itemPercentageChart(y, admissionTotal, "percentage") || 0;

			if (duration === CHART_FILTER_DAY_OF.MONTH) {
				let startOfMonth = moment(x).startOf("month").format("YYYY-MM-DD");
				let endOfMonth = moment(x).endOf("month").format("YYYY-MM-DD");
				if (uniqueDates.length - 1 == index) {
					let fullMonthRes = isFullMonth(x, moment(queryFilters.endDate).format("YYYY-MM-DD"));
					isBroken = fullMonthRes ? false : true;
				}
				if (index == 0) {
					let fullMonthRes = isFullMonth(moment(queryFilters.startDate).format("YYYY-MM-DD"), endOfMonth);
					isBroken = fullMonthRes ? false : true;
				}
				if (!isBroken) {
					if (censusByPeriod && censusByPeriod.monthly && censusByPeriod.monthly.length > 0) {
						let selectedCensus = _.filter(censusByPeriod.monthly, { startDate: startOfMonth });
						let percentageByMonth = 0;
						if (selectedCensus && selectedCensus.length > 0) {
							let censusInfo = selectedCensus[0];
							percentageByMonth = itemPercentageChart(y, censusInfo.censusAverage, "percentage") || 0;
						} else {
							percentageByMonth = itemPercentageChart(y, admissionTotal, "percentage") || 0;
						}
						let monthLabel = moment(x).format("MMMM")
						if (uniqueDates.length > 12) {
							monthLabel = moment(x).format("MMM-YYYY")
						}
						return {
							z: monthLabel,
							x: moment(x).format("MM-DD-YYYY"),
							y,
							percentage: percentageByMonth,
							facilityIds,
							facilityWiseData,
						};
					} else {
						let monthLabel = moment(x).format("MMMM")
						if (uniqueDates.length > 12) {
							monthLabel = moment(x).format("MMM-YYYY")
						}
						return {
							z: monthLabel,
							x: moment(x).format("MM-DD-YYYY"),
							y,
							percentage,
							facilityIds,
							facilityWiseData,
						};
					}
				}
				isBroken = false;
			} else if (duration === CHART_FILTER_DAY_OF.THIRTY || duration === CHART_FILTER_DAY_OF.SEVEN) {
				let days = duration === CHART_FILTER_DAY_OF.SEVEN ? 6 : 29;
				let endDateLabel = moment(x).format("MM-DD");
				let startDateLabel =
					uniqueDates.length - 1 === index
						? moment(queryFilters.startDate).format("MM-DD")
						: moment(x).subtract(days, "days").format("MM-DD");

				let endOfMDate = moment(x).format("YYYY-MM-DD");
				let startOfMDate = moment(x).subtract(days, "days").format("YYYY-MM-DD");

				if (uniqueDates.length - 1 == index) {
					let fullMonthRes = is30Days(moment(queryFilters.startDate).format("YYYY-MM-DD"), x, days + 1);
					isBroken = fullMonthRes ? false : true;
				}
				if (index == 0) {
					let startOfMDateFirst = moment(x).subtract(days, "days").format("YYYY-MM-DD");
					let fullMonthRes = is30Days(startOfMDateFirst, x, days);
					isBroken = fullMonthRes ? false : true;
				}
				if (!isBroken) {
					let yData = formatted.reduce((acc, obj) => {
						if (obj.x >= startOfMDate && obj.x <= endOfMDate) {
							acc += parseInt(obj.y);
						}
						return acc;
					}, []);

					let facilityIdY = formatted.reduce((facilityId, obj) => {
						if (obj.x >= startOfMDate && obj.x <= endOfMDate) {
							facilityId = facilityId?.concat(obj?.facilityIds || []);
						}
						return facilityId;
					}, []);

					let facilityWiseDataY = formatted.reduce((facilityWiseData, obj) => {
						if (obj.x >= startOfMDate && obj.x <= endOfMDate) {
							facilityWiseData = _.mergeWith(facilityWiseData, obj?.facilityWiseData, customizeMerge);
						}
						return facilityWiseData;
					}, {});

					const percentageData = itemPercentageChart(yData, admissionTotal, "percentage") || 0;

					if (censusByPeriod && censusByPeriod.thirtyDays && censusByPeriod.thirtyDays.length > 0) {
						let selectedCensus = _.filter(censusByPeriod.thirtyDays, { endDate: endOfMDate });
						let percentageByMonth = 0;
						if (selectedCensus && selectedCensus.length > 0) {
							let censusInfo = selectedCensus[0];
							percentageByMonth = itemPercentageChart(yData, censusInfo.censusAverage, "percentage") || 0;
						} else {
							percentageByMonth = itemPercentageChart(yData, admissionTotal, "percentage") || 0;
						}
						return {
							z: `(${startDateLabel})-(${endDateLabel})`,
							x: moment(x).format("MM-DD-YYYY"),
							y: yData,
							percentage: percentageByMonth,
							endDate: endOfMDate,
							facilityIds: facilityIdY,
							facilityWiseData: facilityWiseDataY,
						};
					} else {
						return {
							z: `(${startDateLabel})-(${endDateLabel})`,
							x: moment(x).format("MM-DD-YYYY"),
							y: yData,
							percentage: percentageData,
							endDate: endOfMDate,
							facilityIds: facilityIdY,
							facilityWiseData: facilityWiseDataY,
						};
					}
				}
				isBroken = false;
			} else if (duration === CHART_FILTER_DAY_OF.YEAR) {
				let startOfYear = moment(x).startOf("year").format("YYYY");
				let endOfYear = moment(x).endOf("year").format("YYY-MM-DD");
				if (uniqueDates.length - 1 == index) {
					let fullMonthRes = isFullYear(x, moment(queryFilters.endDate).format("YYYY-MM-DD"));
					isBroken = fullMonthRes ? false : true;
				}
				if (index == 0) {
					let fullMonthRes = isFullYear(moment(queryFilters.startDate).format("YYYY-MM-DD"), endOfYear);
					isBroken = fullMonthRes ? false : true;
				}
				if (!isBroken) {
					if (censusByPeriod && censusByPeriod.yearly && censusByPeriod.yearly.length > 0) {
						let selectedCensus = _.filter(censusByPeriod.yearly, { startDate: startOfYear });
						let percentageByYear = 0;
						if (selectedCensus && selectedCensus.length > 0) {
							let censusInfo = selectedCensus[0];
							percentageByYear = itemPercentageChart(y, censusInfo.censusAverage, "percentage") || 0;
						} else {
							percentageByYear = itemPercentageChart(y, admissionTotal, "percentage") || 0;
						}
						return {
							z: moment(x).format("YYYY"),
							x: moment(x).format("MM-DD-YYYY"),
							y,
							percentage: percentageByYear,
							facilityIds,
							facilityWiseData,
						};
					} else {
						return {
							z: moment(x).format("YYYY"),
							x: moment(x).format("MM-DD-YYYY"),
							y,
							percentage,
							facilityIds,
							facilityWiseData,
						};
					}
				}
				isBroken = false;
			} else if (duration === CHART_FILTER_DAY_OF.WEEK) {
				let endOfWeek = moment(x).endOf("week").format("YYY-MM-DD");
				if (uniqueDates.length - 1 == index) {
					let fullMonthRes = isFullWeek(x, moment(queryFilters.endDate).format("YYYY-MM-DD"));
					isBroken = fullMonthRes ? false : true;
				}
				if (index == 0) {
					let fullMonthRes = isFullWeek(moment(queryFilters.startDate).format("YYYY-MM-DD"), endOfWeek);
					isBroken = fullMonthRes ? false : true;
				}
				if (!isBroken) {
					return {
						z: `(${moment(x).startOf("week").format("MM-DD")})-(${moment(x).endOf("week").format("MM-DD")})`,
						x: moment(x).format("MM-DD-YYYY"),
						y,
						percentage,
						facilityIds,
						facilityWiseData,
					};
				}
				isBroken = false;
			} else {
				return {
					z: moment(x).format("MM-DD-YYYY"),
					x: moment(x).format("MM-DD-YYYY"),
					y,
					percentage,
					facilityIds,
					facilityWiseData,
				};
			}
		}
	});
	if (latestData.length) latestData = latestData.filter((item) => item);

	if (duration === CHART_FILTER_DAY_OF.THIRTY || duration === CHART_FILTER_DAY_OF.SEVEN) {
		return _.sortBy(latestData, "endDate");
	} else {
		return latestData;
	}
}

export async function selectedCardIds(data, type) {
	let resArr = [];
	if (
		type === HOSPITAL_CARDS_TYPE.FLOORS_DATA ||
		type === HOSPITAL_CARDS_TYPE.DOCTOR_DATA ||
		type === HOSPITAL_CARDS_TYPE.FLOORS_DATA ||
		type === HOSPITAL_CARDS_TYPE.NURSE_DATA ||
		type === HOSPITAL_CARDS_TYPE.DX_DATA ||
		type === HOSPITAL_CARDS_TYPE.INSURANCE_DATA
	) {
		return _.map(data, "id");
	} else if (
		type === HOSPITAL_CARDS_TYPE.SHIFT_DATA ||
		type === HOSPITAL_CARDS_TYPE.DCER_DATA ||
		type === HOSPITAL_CARDS_TYPE.RETURNS_DATA ||
		type === HOSPITAL_CARDS_TYPE.TOTAL
	) {
		return [];
	} else if (type === HOSPITAL_CARDS_TYPE.DAYS_DATA || type === HOSPITAL_CARDS_TYPE.HOSPITAL_DATA) {
		return _.map(data, "_id");
	} else if (type === HOSPITAL_CARDS_TYPE.NINETY_DAYS_DATA) {
		return _.map(data, "ids");
	} else {
		data?.map((item) => {
			if (item.ids.length > 0) {
				resArr = [...resArr, ...item.ids];
			}
			return true;
		});
		return resArr;
	}
}

export async function selectedCardForDeceasedIds(data, type) {
	let resArr = [];
	if (
		type === HOSPITAL_CARDS_TYPE.FLOORS_DATA ||
		type === HOSPITAL_CARDS_TYPE.DOCTOR_DATA ||
		type === HOSPITAL_CARDS_TYPE.FLOORS_DATA ||
		type === HOSPITAL_CARDS_TYPE.NURSE_DATA ||
		type === HOSPITAL_CARDS_TYPE.DX_DATA ||
		type === HOSPITAL_CARDS_TYPE.INSURANCE_DATA
	) {
		return _.map(data, "id");
	} else if (
		type === HOSPITAL_CARDS_TYPE.SHIFT_DATA ||
		type === HOSPITAL_CARDS_TYPE.DCER_DATA ||
		type === HOSPITAL_CARDS_TYPE.RETURNS_DATA ||
		type === HOSPITAL_CARDS_TYPE.TOTAL
	) {
		return [];
	} else if (type === HOSPITAL_CARDS_TYPE.DAYS_DATA || type === HOSPITAL_CARDS_TYPE.HOSPITAL_DATA) {
		return _.map(data, "_id");
	} else if (type === HOSPITAL_CARDS_TYPE.NINETY_DAYS_DATA) {
		return _.map(data, "ids");
	} else {
		data?.map((item) => {
			if (item.ids.length > 0) {
				resArr = [...resArr, ...item.ids];
			}
			return true;
		});
		return resArr;
	}
}
export function isUpdateDefaultFilter(filter) {
	const { startDate, endDate } = filter;
	if (startDate && endDate) {
		var dateA = moment(endDate);
		var dateB = moment(startDate);
		const dayDiff = dateA.diff(dateB, "days");
		var diffDuration = moment.duration(dateA.diff(dateB));
		if (dayDiff >= 30 && dayDiff < 90) {
			if (diffDuration.months() > 0) {
				return true;
			}
		}
	}
	return false;
}

export function is30Days(startDate, endDate, daysCheck = 29) {
	const momentStartDate = moment(startDate);
	const momentEndDate = moment(endDate);
	const dayDiff = momentEndDate.diff(momentStartDate, "days");
	if (dayDiff == daysCheck) {
		return true;
	}
	return false;
}

export function isFullWeek(startDate, endDate) {
	const momentStartDate = moment(startDate);
	const momentEndDate = moment(endDate);

	const startOfWeek = moment(momentStartDate).startOf("week").format("YYYY-MM-DD");
	const endOfWeek = moment(momentEndDate).endOf("week").format("YYYY-MM-DD");
	return momentStartDate.isSame(startOfWeek) && momentEndDate.isSame(endOfWeek);
}

export function isFullMonth(startDate, endDate) {
	const momentStartDate = moment(startDate);
	const momentEndDate = moment(endDate);
	const startOfMonth = moment(momentStartDate).startOf("month").format("YYYY-MM-DD");
	const endOfMonth = moment(momentEndDate).endOf("month").format("YYYY-MM-DD");

	return momentStartDate.isSame(startOfMonth) && momentEndDate.isSame(endOfMonth);
}

export function isFullYear(startDate, endDate) {
	const momentStartDate = moment(startDate);
	const momentEndDate = moment(endDate);

	const startOfYear = moment(momentStartDate).startOf("year").format("YYYY-MM-DD");
	const endOfYear = moment(momentEndDate).endOf("year").format("YYYY-MM-DD");

	return momentStartDate.isSame(startOfYear) && momentEndDate.isSame(endOfYear);
}

export function chartFilterPermission(filter) {
	const { startDate, endDate } = filter;
	if (startDate && endDate) {
		var dateA = moment(endDate);
		var dateB = moment(startDate);
		const dayDiff = dateA.diff(dateB, "days");
		var diffDuration = moment.duration(dateA.diff(dateB));

		if (dayDiff < 7) {
			if (dayDiff == 6) {
				return [FILTER_TYPES.DAILY, FILTER_TYPES.SEVEN_DAYS];
			} else {
				return [FILTER_TYPES.DAILY];
			}
		} else if (dayDiff >= 7 && dayDiff < 30) {
			return [FILTER_TYPES.DAILY, FILTER_TYPES.WEEKLY, FILTER_TYPES.SEVEN_DAYS];
		} else if (dayDiff >= 30 && dayDiff < 90) {
			if (diffDuration.months() > 0) {
				return [FILTER_TYPES.WEEKLY, FILTER_TYPES.MONTHLY, FILTER_TYPES.THIRTY_DAYS, FILTER_TYPES.SEVEN_DAYS];
			} else {
				return [FILTER_TYPES.DAILY, FILTER_TYPES.WEEKLY, FILTER_TYPES.SEVEN_DAYS];
			}
		} else {
			if (diffDuration.years() && diffDuration.years() > 0) {
				return [
					FILTER_TYPES.DAILY,
					FILTER_TYPES.WEEKLY,
					FILTER_TYPES.SEVEN_DAYS,
					FILTER_TYPES.MONTHLY,
					FILTER_TYPES.YEARLY,
					FILTER_TYPES.THIRTY_DAYS,
				];
			} else {
				return [
					FILTER_TYPES.SEVEN_DAYS,
					FILTER_TYPES.DAILY,
					FILTER_TYPES.WEEKLY,
					FILTER_TYPES.MONTHLY,
					FILTER_TYPES.THIRTY_DAYS,
				];
			}
		}
	}
}

export function dayDifferent(filter) {
	const { startDate, endDate } = filter;
	if (startDate && endDate) {
		var dateA = moment(endDate);
		var dateB = moment(startDate);
		const dayDiff = dateA.diff(dateB, "days");
		return dayDiff === 0 ? 1 : dayDiff;
	}
	return 1;
}

export function getHospitalFieldNames(defaultData, row, relation, type) {
	if (relation && type === HOSPITAL_CARDS_TYPE.DX_DATA) {
		if (row[relation] && row[relation].length > 0) {
			return _.map(row[relation], "label").join(", ");
		}
		return "-";
	} else if (type === HOSPITAL_CARDS_TYPE.DAYS_DATA) {
		return row.day;
	} else if (type === HOSPITAL_CARDS_TYPE.DCER_DATA) {
		return row.wasAdmitted ? "DC" : "ER";
	} else if (type === HOSPITAL_CARDS_TYPE.NINETY_DAYS_DATA) {
		let labelName = null;
		_.filter(defaultData, ({ ids, label }) => {
			if (_.includes(ids, row._id)) {
				labelName = label;
			}
		});
		return labelName;
	} else if (type === HOSPITAL_CARDS_TYPE.HOSPITALIZATIONS) {
		return !row.reHospitalization ? "New Hospitalizations" : "Re-Hospitalizations";
	} else if (type === HOSPITAL_CARDS_TYPE.RETURNS_DATA) {
		return row.wasReturned ? "Returned" : "Didn't Return";
	} else if (type === HOSPITAL_CARDS_TYPE.SHIFT_DATA) {
		return row.shiftName;
	} else if (
		type === HOSPITAL_CARDS_TYPE.UNPLANNED ||
		type === HOSPITAL_CARDS_TYPE.PLANNED ||
		type === HOSPITAL_CARDS_TYPE.TOTAL
	) {
		return row.transferType;
	} else if (relation && row[relation]) {
		return row[relation].label;
	}
	return "-";
}
export function getCommunicationFieldNames(row, relation, type, defaultData) {
	if (type === CO_TRANSFER_CARDS_TYPE.SIXTY_DAYS_DATA) {
		let labelName = null;
		_.filter(defaultData, ({ ids, label }) => {
			if (_.includes(ids, row._id)) {
				labelName = label;
			}
		});
		return labelName;
	} else if (type === CO_TRANSFER_CARDS_TYPE.RETURNS_DATA) {
		return row.wasReturned ? "Returned" : "Didn't Return";
	} else if (
		type === CO_TRANSFER_CARDS_TYPE.SNF ||
		type === CO_TRANSFER_CARDS_TYPE.AMA ||
		type === CO_TRANSFER_CARDS_TYPE.SAFE_DISCHARGE ||
		type === CO_TRANSFER_CARDS_TYPE.TOTAL
	) {
		return row.transferType;
	} else if (relation && row[relation]) {
		return row[relation].label;
	}
	return "-";
}

export function getAdmissionFieldNames(row, relation, type) {
	if (relation && type === ADMISSION_CARDS_TYPE.DX_DATA) {
		if (row[relation] && row[relation].length > 0) {
			return _.map(row[relation], "label").join(", ");
		}
		return "-";
	} else if (type === ADMISSION_CARDS_TYPE.DAYS_DATA) {
		return row.day;
	} else if (
		type === ADMISSION_CARDS_TYPE.ADMISSION ||
		type === ADMISSION_CARDS_TYPE.READMISSION ||
		type === ADMISSION_CARDS_TYPE.TOTAL
	) {
		return row.type;
	} else if (relation && row[relation]) {
		return row[relation].label;
	}
	return "-";
}

export function getOverallFieldNames(row, relation, type, defaultData) {
	if (type === OVERALL_CARDS_TYPE.NINETY_DAYS_DATA) {
		let labelName = null;
		_.filter(defaultData, ({ ids, label }) => {
			if (_.includes(ids, row._id)) {
				labelName = label;
			}
		});
		return labelName;
	} else if (
		type === OVERALL_CARDS_TYPE.TOTAL ||
		type === OVERALL_CARDS_TYPE.TOTAL_INCOMING ||
		type === OVERALL_CARDS_TYPE.TOTAL_OUTGOING
	) {
		return row.type;
	} else if (relation && row[relation]) {
		return row[relation].label;
	}
	return "-";
}

export function getDeceasedFieldNames(row, relation, type, defaultData) {
	if (type === HOSPITAL_CARDS_TYPE.NINETY_DAYS_DATA) {
		let labelName = null;
		_.filter(defaultData, ({ ids, label }) => {
			if (_.includes(ids, row._id)) {
				labelName = label;
			}
		});
		return labelName;
	} else if (type === DECEASED_CARDS_TYPE.TOTAL) {
		return row.transferType;
	} else if (relation && row[relation]) {
		return row[relation].label;
	}
	return "-";
}

export function getAdmissionADTFieldNames(defaultData, row, relation, selectedCardItem) {
	const { type, childId } = selectedCardItem;
	if (relation && type === ADT_TABLE_TYPE.GROUP_ARRAY) {
		if (row[relation] && row[relation].length > 0) {
			return _.map(row[relation], "label").join(", ");
		}
		return "-";
	}
	if (relation && type === ADT_TABLE_TYPE.GROUP) {
		return row[relation]?.label || "-";
	}
	if (childId === "wasAdmitted") {
		return row.wasAdmitted ? "DC" : "ER";
	}
	if (childId === "reHospitalization") {
		return !row.reHospitalization ? "New Hospitalizations" : "Re-Hospitalizations";
	}
	if (childId === "wasReturned") {
		return row.wasReturned ? "Returned" : "Didn't Return";
	}
	if (childId === "shiftName") {
		return row.shiftName;
	}
	if (childId === "day") {
		return row.day;
	}
	if (type === ADT_TABLE_TYPE.TOTAL || type === ADT_TABLE_TYPE.ALL) {
		return row.transferType;
	}
	if (childId === "90Days" || childId === "60Days" || childId === "90DaysDeceased" || childId === "90DaysOverall") {
		let labelName = null;
		_.filter(defaultData, ({ ids, label }) => {
			if (_.includes(ids, row._id)) {
				labelName = label;
			}
		});
		return labelName;
	}
}

export const getRelationLabel = (row, relation) => {
	if (row && relation && row[relation]) {
		return row[relation]?.label;
	} else {
		return "N/A";
	}
};
