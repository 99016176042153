import { Outlet } from "react-router";
import styles from "./Dashboard.module.scss";

const Dashboard = (props) => {
  return (
    <div className={styles.dashboardPage}>
      <Outlet />
    </div>
  );
};

export default Dashboard;
