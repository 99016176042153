// ----------------------------------------------------------------------

export default function Typography(theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: theme.palette.fontFamily.secondary,
          color: theme.palette.typography.main
        },
        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
        subtitle1: {
          fontFamily: theme.palette.fontFamily.manropeBold,
          fontWeight: 700,
          fontSize: 15,
          // lineHeight: 20,

        },
        subtitle2: {
          fontFamily: theme.palette.fontFamily.manropeReg,
          fontWeight: 400,
          fontSize: 12,
          // lineHeight: 16,
        },
        subtitle3: {
          fontFamily: theme.palette.fontFamily.manropeSBold,
          fontSize: "12px",
          lineHeight: "16px",
          color: "#444652",
        },
        subtitle4: {
          fontFamily: theme.palette.fontFamily.manropeMed,
          fontSize: "14px",
          lineHeight: "19px",
          // lineHeight: 16,
        },
      },
    },
  };
}
