import { useEffect, useState } from "react";
import styles from "./AddEditRoleDialog.module.scss";
import CloseIcon from "../../assets/svgs/close.svg";
import { createRole, updateRole } from "../../services/role.service";
import {
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Button,
} from "@mui/material";

const AddEditRoleDialog = (props) => {
  const [name, setName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  useEffect(() => {
    if (props.currentEdit && props.currentEdit._id) {
      setIsEdit(true);
      if (
        props.currentEdit &&
        props.currentEdit.permissionIds &&
        props.currentEdit.permissionIds.length > 0
      ) {
        setSelectedPermissions(props.currentEdit.permissionIds);
      }
      setName(props.currentEdit.name);
    }
  }, [props.currentEdit]);

  const save = async () => {
    setLoading(true);
    if (isEdit) {
      await updateRole(props.currentEdit._id, {
        name,
        permissions: selectedPermissions,
      });
    } else {
      await createRole({ name, permissions: selectedPermissions });
    }
    setLoading(false);
    props.close("reload");
  };

  const handleToggle = (value) => () => {
    const currentIndex = selectedPermissions.indexOf(value);
    const newChecked = [...selectedPermissions];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedPermissions(newChecked);
  };

  return (
    <>
      <div className={styles.overlay}></div>
      <div className={styles.addPopup}>
        <div className={`df aic ${styles.hdr}`}>
          <p className={`ffmm fs18`}>{isEdit ? "Edit" : "Add"} Role</p>
          <div className={`mla`}>
            <div
              className={`df acc ${styles.closeBtn}`}
              onClick={() => props.close()}
            >
              <CloseIcon />
            </div>
          </div>
        </div>{" "}
        <div className={styles.popupCntnt}>
          <div className={`m-b-15 inputWrpr`}>
            <label className={`ffmr fs12`}>Role Name</label>
            <input value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className={`m-b-15 inputWrpr`}>
            <label className={`ffmr fs12`}>Permissions</label>
            <Grid container direction="row">
              {props.permissions &&
                props.permissions.length > 0 &&
                props.permissions.map((item, index) => {
                  return (
                    <Grid item xs={4} key={index}>
                      <FormGroup className={styles.checkboxGroup}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disableRipple
                              checked={
                                selectedPermissions.indexOf(item._id) !== -1
                              }
                              onChange={handleToggle(item._id)}
                            />
                          }
                          label={
                            <Typography
                              variant="subtitle2"
                              component={"span"}
                              sx={{ fontFamily: "mont reg" }}
                              fontSize={14}
                            >
                              {item.name}
                            </Typography>
                          }
                        />
                      </FormGroup>
                    </Grid>
                  );
                })}
            </Grid>
          </div>
        </div>
        <div className={`${styles.popupFtr}`}>
          <div className={`df aic ${styles.actionsWrrp}`}>
            <div className={`mla`}>
              <button
                className={`ffmr fs14 m-r-8 ${styles.text}`}
                onClick={() => props.close()}
              >
                Cancel
              </button>
              <Button
                disabled={loading || !name}
                variant="contained"
                color="primary"
                className={`ffmr fs14`}
                onClick={save}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEditRoleDialog;
