import * as React from "react";
import styles from "../doctorCard/DoctorCard.module.scss";
import UserList from "../userItemList/UserList";

const DoctorCard = ({
	data,
	comparisonData,
	totalAdmissions,
	totalAdmissionsComparison,
	handleToggle,
	handleToggleAll,
	selectedItem = [],
	type,
	page,
	admissionCompareAgainst,
	admissionCompareAgainstComparison,
	isPercentageShow = true,
	isCompareColor = false,
	averageCensusComparison,
	averageCensus,
	filter,
	filterComparison,
	cardTitle,
	reverseColors,
	reverseColorsAdmissionPage,
	comparingAgainstDifferentFacility,
	loading,
	projectionDays,
	filterTotal,
	priorityNumber,
	transferType,
	isComparingAgainstAvgCensus,
	lockedTotalBy,
	searchValue,
}) => {
	return (
		<div className={`${styles.doctor} `}>
			<UserList
				averageCensusComparison={averageCensusComparison}
				averageCensus={averageCensus}
				isCompareColor={isCompareColor}
				handleToggle={handleToggle}
				cardTitle={cardTitle}
				totalAdmissions={totalAdmissions}
				totalAdmissionsComparison={totalAdmissionsComparison}
				selectedItem={selectedItem}
				data={data}
				comparisonData={comparisonData}
				type={type}
				page={page}
				admissionCompareAgainst={admissionCompareAgainst}
				admissionCompareAgainstComparison={admissionCompareAgainstComparison}
				isPercentageShow={isPercentageShow}
				filter={filter}
				filterComparison={filterComparison}
				reverseColors={reverseColors}
				reverseColorsAdmissionPage={reverseColorsAdmissionPage}
				comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
				loading={loading}
				projectionDays={projectionDays}
				filterTotal={filterTotal}
				priorityNumber={priorityNumber}
				transferType={transferType}
				isComparingAgainstAvgCensus={isComparingAgainstAvgCensus}
				lockedTotalBy={lockedTotalBy}
				searchValue={searchValue}
				handleToggleAll={handleToggleAll}
			/>
		</div>
	);
};

export default DoctorCard;
