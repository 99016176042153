import { store } from "..";
import { setFilterDBData, setMainTotal } from "../store/reducers/hospital.slice";
import {
	setFilterDBData as setFilterDBDataComparison,
	setMainTotal as setMainTotalComparison,
} from "../store/reducers/comparisonReducers/hospitalComparison.slice";
import { getCardPatientAndChartData, getCardPatientChartData, getAllCount } from "./api/hospital.api";


const cardPatientAndChartData = async (data) => {
	try {
		const res = await getCardPatientAndChartData(data);
		return res;
	} catch (e) {
		console.log(e);
	}
};

const cardPatientChartData = async (data = null) => {
	try {
		const res = await getCardPatientChartData(data);
		return res;
	} catch (e) {
		console.log(e);
	}
};

const getAllPatientCount = async (data = null, forComparison = null) => {
	try {
		const res = await getAllCount(data);
		store.dispatch(forComparison ? setFilterDBDataComparison(res?.totals) : setFilterDBData(res?.totals));
		store.dispatch(forComparison ? setMainTotalComparison(res?.totals?.total) : setMainTotal(res?.totals?.total));
		return res;
	} catch (e) {
		console.log(e);
	}
};

export { cardPatientAndChartData, cardPatientChartData, getAllPatientCount };
