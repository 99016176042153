import { store } from "..";
import { COLORS_BOX_CODE } from "../types/common.type";
import { getDaysBetweenDates } from "./common";

export const calculateDiffBetweenPercentages = (
	firstPercentage = 0,
	secondPercentage = 0,
	forAdmission = false,
	filter,
	filterComparison,
	itemTotalComparison,
	projectionDays,
	debug
) => {
	const firstPercentageNumber = Number(firstPercentage);
	let percentageChange = 0;
	let secondPercentageNumber = Number(secondPercentage);
	let scalingFactor;
	let itemTotalComparisonScaled;
	let numberOfDays;

	if (filter && filterComparison) {
		numberOfDays = getDaysBetweenDates(filter);
		const numberOfDaysComparison = getDaysBetweenDates(filterComparison);
		scalingFactor = numberOfDays / numberOfDaysComparison;
		secondPercentageNumber = scalingFactor * secondPercentage;
		itemTotalComparisonScaled = Math.round(itemTotalComparison * scalingFactor);
	}

	if (secondPercentageNumber === 0) {
		percentageChange = firstPercentageNumber === 0 ? 0 : 100;
	} else {
		percentageChange = ((secondPercentageNumber - firstPercentageNumber) / Math.abs(firstPercentageNumber)) * 100;
	}

	if (firstPercentageNumber < secondPercentageNumber) {
		percentageChange = Math.abs(percentageChange); // First number less, so outcome is positive
	} else {
		percentageChange = -Math.abs(percentageChange); // First number more, so outcome is negative
	}

	if (forAdmission) {
		percentageChange = -percentageChange;
	}

	if (projectionDays) {
		const { numberOfDaysProjection, itemTotalComparisonProjectionScaled } = calculateProjectionDiffBetweenPercentages({
			projectionDays,
			filter,
			filterComparison,
			itemTotalComparison,
		});

		// for display purposes only.
		itemTotalComparisonScaled = itemTotalComparisonProjectionScaled;
		numberOfDays = numberOfDaysProjection;
	}

	return {
		percentageDiff: Math.round(percentageChange),
		itemTotalComparisonScaled,
		numberOfDays,
	};
};

const calculateProjectionDiffBetweenPercentages = ({
	projectionDays,
	filter,
	filterComparison,
	itemTotalComparison,
}) => {
	const originalDiffBetweenDaysAndProjectedDaysComparison = projectionDays / getDaysBetweenDates(filterComparison);
	const numberOfDaysProjection = projectionDays === null ? getDaysBetweenDates(filter) : projectionDays;
	const numberOfDaysProjectionComparison =
		projectionDays === null ? getDaysBetweenDates(filterComparison) : projectionDays;
	let projectionsForProjection = (numberOfDaysProjection * itemTotalComparison) / numberOfDaysProjectionComparison;
	const itemTotalComparisonProjectionScaled = Math.round(
		projectionsForProjection * originalDiffBetweenDaysAndProjectedDaysComparison
	);

	return { numberOfDaysProjection, itemTotalComparisonProjectionScaled };
};

export const pickComparisonColor = (percentageDiff, debug) => {
	const percentage = Number(percentageDiff);
	const { rangesSet, percentageThresholds } = store.getState().comparison;

	if (!rangesSet) return null;

	let pickedColor = null;

	if (percentage >= 0 && percentage <= percentageThresholds.littleGood.value) {
		pickedColor = null;
	} else if (percentage > percentageThresholds.littleGood.value && percentage <= percentageThresholds.good.value) {
		pickedColor = percentageThresholds.littleGood.showColor ? COLORS_BOX_CODE.LIGHT_GREEN : null;
	} else if (percentage > percentageThresholds.good.value && percentage <= percentageThresholds.veryGood.value) {
		pickedColor = percentageThresholds.good.showColor ? COLORS_BOX_CODE.GREEN : null;
	} else if (percentage > percentageThresholds.veryGood.value) {
		pickedColor = percentageThresholds.veryGood.showColor ? COLORS_BOX_CODE.BLUE : null;
	} else if (percentage <= 0 && percentage >= -percentageThresholds.littleBad.value) {
		pickedColor = null;
	} else if (percentage < -percentageThresholds.littleBad.value && percentage >= -percentageThresholds.bad.value) {
		pickedColor = percentageThresholds.littleBad.showColor ? COLORS_BOX_CODE.YELLOW : null;
	} else if (percentage < -percentageThresholds.bad.value && percentage >= -percentageThresholds.veryBad.value) {
		pickedColor = percentageThresholds.bad.showColor ? COLORS_BOX_CODE.ORANGE : null;
	} else if (percentage < -percentageThresholds.veryBad.value) {
		pickedColor = percentageThresholds.veryBad.showColor ? COLORS_BOX_CODE.RED : null;
	}

	return pickedColor;
};

export const pickComparisonColorTotalOverall = (totalComparison, totalWithOutSign) => {
	if (totalComparison > totalWithOutSign) {
		return COLORS_BOX_CODE.RED;
	}
	if (totalComparison < totalWithOutSign) {
		return COLORS_BOX_CODE.BLUE;
	}
};
