import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { REMOVE_NOTIFICATION } from "../../../store/types";
import styles from "./Notification.module.scss";

const Notification = ({ notification }) => {
    const [reducing, setReducing] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            setReducing(true);
        }, 1000);

        setTimeout(() => {
            dispatch({
                type: REMOVE_NOTIFICATION,
                payload: notification.id,
            });
        }, 6000);
        // eslint-disable-next-line
    }, []);
    return (
        <div className={`${styles.notification} ${styles[notification.type]}`}>
            {notification.label}
            <div className={styles.meter}>
                <div
                    style={{ width: reducing ? "0%" : "100%" }}
                    className={styles.lever}
                ></div>
            </div>
        </div>
    );
};

export default Notification;
