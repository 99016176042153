import React from 'react';
import { Typography, Tooltip } from '@mui/material';

const EllipsisTooltipTypography = ({ label }) => {
    const isTooltip = label.length > 13;
    return (
        <Tooltip disableHoverListener={!isTooltip} title={label}>
            <Typography
                noWrap
                variant="subtitle3"
                sx={{
                    marginRight: "10px",
                    maxWidth: { xs: "20px", xl: "150px", },
                    minWidth: { xs: "20px", xl: "150px", },
                    width: { xs: "20px", xl: "150px", },

                    ...isTooltip && {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }
                }}
            >
                {label}
            </Typography>
        </Tooltip>

    );
};

export default EllipsisTooltipTypography;
