import React from "react";
import { ResponsiveRadialBar } from "@nivo/radial-bar";

const AsstLivingRedialChart = ({ data }) => {
	return (
		<div style={{ height: "92px", width: "100%", padding: "0px" }}>
			{data?.length > 0 && (
				<ResponsiveRadialBar
					data={data}
					padding={0.5}
					cornerRadius={50}
					margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
					radialAxisStart={null}
					endAngle={360}
					enableTracks={false}
					enableRadialGrid={false}
					enableCircularGrid={false}
					axisLeft={null}
					axisBottom={null}
					circularAxisOuter={null}
					enableArcLabels={true}
					enableArcLinkLabels={true}
					colors={(d) => d.data.color}
					//colors={{ "scheme": "paired" }}
					label={"name"}
					tooltip={({ bar }) => {
						return (
							<div
								style={{
									background: "white",
									padding: "0px 6px",
									border: "1px solid #ccc",
								}}
							>
								<div>
									{bar?.data.x}: {bar?.value}
								</div>
							</div>
						);
					}}
				/>
			)}
		</div>
	);
};

export default AsstLivingRedialChart;
