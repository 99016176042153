export const TRANSFER_TYPE = {
    SAFE_DISCHARGE: 'safeDischarge',
    SNF: 'SNF',
    AMA: 'AMA',
    ALL: 'all',
}

export const CO_TRANSFER_CARDS_TYPE = {
    INSURANCE_DATA: 'insuranceData',
    SIXTY_DAYS_DATA: 'sixtyDaysData',
    RETURNS_DATA: 'returnsData',
    DOCTOR_DATA: 'doctorData',
    FLOORS_DATA: 'floorsData',
    SAFE_DISCHARGE_ASS_LIV_DATA: 'safeDischargeAssLivData',
    SNF_FACILITY_DATA: 'snfFacilityData',
    TOTAL: "total",  
    SAFE_DISCHARGE: 'safeDischarge',
    SNF: 'SNF',
    AMA: 'AMA',  
}
