import { IconButton, Stack } from "@mui/material";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DescriptionIcon from '@mui/icons-material/Description';
import { useMemo } from "react";
import Tooltip from '@mui/material/Tooltip';

const QuestionLabel = ({ children, handleAddHighlight, accessor, allFields }) => {
    const description = useMemo(() => {
        if (allFields && allFields?.highlighter?.length > 0) {
            const descriptionData = allFields?.highlighter?.find(item => item.fieldName === accessor)?.description;
            return descriptionData;
        } else {
            return null;
        }
        // eslint-disable-next-line
    }, [allFields]);

    return (
        <Stack direction="row" alignItems={"center"} display={"flex"}>
            <Stack>
                <IconButton onClick={() => handleAddHighlight(accessor)} size="small">
                    <BorderColorIcon sx={{ fontSize: "14px" }} />
                </IconButton>
            </Stack>
            <Stack sx={{ marginTop: "7px" }}>
                {children}
            </Stack>
            {!!description && (
                <Stack key={description + accessor + "stack"}>
                    <Tooltip title={description} arrow placement="right" key={description + accessor + "tooltip"}>
                        <IconButton size="small" key={description + accessor}>
                            <DescriptionIcon sx={{ fontSize: "14px" }} />
                        </IconButton>
                    </Tooltip>
                </Stack>
            )}
        </Stack>
    )

}

export default QuestionLabel;
