import { toDisplayTime } from "../../../../../utilis/date-formats";
import styles from "./AddPatient.module.scss";

const ShowDuplicateADT = ({
    allFields,
    mostRecentADT,
    setShowDuplicateADT,
    setShowAddMissingADT,
    setIsAddOrReaddDialog
}) => {

    return (
        <div className={styles.duplicatePopup}>
            <div className={styles.duplicateOverlay}></div>
            <div className={styles.duplicateContent}>
                <div className={`df aic ${styles.hdr}`}>
                    <p className={`df aic ffmm fs18`}>Duplicate ADT Found</p>
                </div>

                <div className={`ffmr fs16 p-t-10 p-r-20 p-b-20 p-l-20 df ffc ${styles.messageWrpr}`}>
                    You are attempting to enter a new {allFields?.type} for {allFields.lastName}{" "}
                    {allFields.firstName} however our system shows that there is a prior{" "}
                    {mostRecentADT?.type} activity on {toDisplayTime(mostRecentADT?.dateOfADT)}{" "}
                    without a corresponding{" "}
                    {["admission", "readmission", "return"].includes(mostRecentADT?.type)
                        ? "Transfer"
                        : "Admission, Readmission or Return"}{" "}
                    activity
                </div>
                <div className={`df aic p-b-15 p-l-15 p-r-15`}>
                    <div>
                        <button
                            className={`ffmr fs14 m-r-8 ${styles.text}`}
                            onClick={() => setShowDuplicateADT(false)}
                        >
                            Cancel
                        </button>
                    </div>
                    <div className={`df aic mla`}>
                        <button
                            className={`df aic ffmr fs14 ttuc ${styles.primary} `}
                            onClick={async () => {
                                if(!["admission", "readmission", "return"].includes(mostRecentADT?.type || "")){
                                    await setIsAddOrReaddDialog(true);
                                }
                                setShowAddMissingADT(true)
                            }}
                        >
                            Add missing{" "}
                            {["admission", "readmission", "return"].includes(mostRecentADT?.type || "")
                                ? "Transfer"
                                : "ADT"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShowDuplicateADT;
