import api from './api';

const API_PREFIX = "api/facility-manually-end-date-adt";

const fetchSettings = async (path, method, body = {}, params = {}) => {
    const url = path ? `${API_PREFIX}/${path}` : `${API_PREFIX}`;
    const response = await api[method](url, body, { params });
    return response;
};

const transformRequestOptions = (params) => {
    // Build an array of key=value pairs
    const options = Object.entries(params).reduce((acc, [key, value]) => {
        if (typeof value !== 'object' && value !== undefined && value !== null) {
            acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        } else if (Array.isArray(value) && value.length) {
            value.forEach((el) => {
                acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(el)}`);
            });
        }
        return acc;
    }, []);

    // Join the array with '&' and return the result
    return options.length > 0 ? options.join('&') : '';
};

const getEndDateOfADTData = async (params) => {
    return await api.get(`${API_PREFIX}`, {
        params: { 'facilityIds': params.facilityIds },
        paramsSerializer: params => transformRequestOptions(params)
    });

};
const saveEndDateOfADTSetting = async (body) => fetchSettings('', 'post', body);

export {
    getEndDateOfADTData,
    saveEndDateOfADTSetting
};
