const ArrowSVG = (props) => (
  <svg
    width="5"
    height="9"
    viewBox="0 0 5 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.622644 8.99999C0.704933 9.00048 0.786507 8.9842 0.862688 8.95207C0.938869 8.91994 1.00816 8.8726 1.06658 8.81276L4.81818 4.93915C4.93463 4.81819 5 4.65456 5 4.484C5 4.31344 4.93463 4.14981 4.81818 4.02885L1.06658 0.15524C0.946969 0.0494729 0.793106 -0.00579452 0.635741 0.000481478C0.478377 0.00675748 0.329102 0.0741144 0.217745 0.189093C0.106389 0.304071 0.041153 0.458201 0.0350747 0.620684C0.0289965 0.783167 0.0825229 0.942034 0.184958 1.06554L3.49261 4.48077L0.184958 7.89601C0.0971575 7.98592 0.0371499 8.1007 0.0125237 8.22583C-0.0121026 8.35095 -0.00024128 8.48081 0.046607 8.59898C0.0934553 8.71714 0.173187 8.81831 0.27572 8.88969C0.378253 8.96107 0.498983 8.99945 0.622644 8.99999Z"
      fill={props.fill || "#636578"}
    />
  </svg>
);

export default ArrowSVG;
