import api from './api';

const API_PREFIX = "api/custom-card";

const getCustomeCardFiltersData = async (params) => {
    const response = await api.get(`${API_PREFIX}`, { params });
    return response;
};

const saveCustomeCardFilterData = async (body) => {
    const response = await api.post(`${API_PREFIX}`, body);
    return response;
};


export {
    getCustomeCardFiltersData,
    saveCustomeCardFilterData
};
