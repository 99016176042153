import { useCallback, useState } from "react";
import CloseIcon from "../../../assets/svgs/close.svg";
import styles from "./EditCensusDialog.module.scss";
import { useDispatch } from "react-redux";
import Cleave from "cleave.js/react";
import moment from "moment";
import _ from "lodash";
import { getCensusFirstDate, updateCensus } from "../../../services/data-input.service";
import { errorMessage } from "../../../services/helpers/toastMessages";
import { useEffect } from "react";
import { toDisplayTime, toSaveDate } from "../../../utilis/date-formats";
import { ADD_NOTIFICATION } from "../../../store/types";
import { LoadingButton } from "@mui/lab";

const defaultDateFormat = "MM-DD-YYYY";

const EditCensusDialog = (props) => {
    //const dispatch = useDispatch();    
    const defaultErr = { date: null, count: null };
    const [errors, setErrors] = useState(defaultErr);
    const [censusData, setCensusData] = useState({ date: null, count: null });
    const [lastCountRecord, setLastCountRecord] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const getCensusData = () => {
        const facilityId = localStorage.getItem("facilityId")
        getCensusFirstDate(facilityId).then((res) => {
            if (res) {
                setLastCountRecord(res);
            }
        });
    }

    useEffect(() => {
        getCensusData()
    }, [])

    const save = async () => {
        let errorData = {};
        if (!censusData.date) {
            errorData.date = "Date is required";
        } else if (!moment(censusData.date, defaultDateFormat, true).isValid()) {
            errorData.date = "Date is not valid";
        } else if (!(moment(moment(censusData.date).format(defaultDateFormat)).isBetween(moment(lastCountRecord.date).format(defaultDateFormat), moment().format(defaultDateFormat)))) {
            errorData.date = "Enter new date less then to current date or greater then to initial date";
        }
        if (!censusData.count) {
            errorData.count = "Count is required";
        }
        if (censusData.count === "0") {
            errorData.count = "Enter valid count.";
        }
        if (!_.isEmpty(errorData)) {
            setErrors(errorData);
        } else {
            setErrors(defaultErr);
            const facilityId = localStorage.getItem("facilityId")
            if (facilityId) {
                setLoading(true);
                await updateCensus({ ...censusData, date: toSaveDate(censusData.date) }, facilityId)
                setLoading(false);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        label: "Census information updated successfully!",
                        type: "success",
                        id: 'censusUpdateSuccess'
                    },
                });
                props.close();
            } else {
                errorMessage("Please select facility.")
            }
        }
    };

    const handleOnChange = useCallback(
        (field, value) => {
            setCensusData((state) => ({
                ...state,
                [field]: value,
            }));
            setErrors((state) => ({
                ...state,
                [field]: null,
            }));
        },
        [setCensusData]
    );

    const handleClose = useCallback(() => {
        props.close();
    }, [props]);

    return (
        <>
            <div className={styles.overlay}></div>
            <div className={styles.addPopup}>
                <div className={`df aic ${styles.hdr}`}>
                    <p className={`ffmm fs18`}>Update census information</p>
                    <div className={`mla`}>
                        <div className={`df acc ${styles.closeBtn}`} onClick={handleClose}>
                            <CloseIcon />
                        </div>
                    </div>
                </div>{" "}
                <div className={styles.popupCntnt}>
                    {lastCountRecord && (
                        <div className={`m-b-20 inputWrpr`}>
                            Your initial date for census is :  <b>{toDisplayTime(lastCountRecord?.date, defaultDateFormat)}</b>,
                            Please add new date to greater then of initial date and less then to current date.
                        </div>
                    )}
                    <div className={`m-b-20 inputWrpr`}>
                        <label className={`ffmr fs12`}>Date</label>
                        <Cleave
                            style={{ fontSize: "16px", fontFamily: "mont light" }}
                            options={{
                                date: true,
                                delimiter: "-",
                                datePattern: ["m", "d", "Y"],
                            }}
                            placeholder={defaultDateFormat}
                            value={censusData.date}
                            onChange={(e) => handleOnChange("date", e.target.value)}
                        />
                        {errors.date && (
                            <div className="error fs12 mt-1">{errors.date}</div>
                        )}
                    </div>
                    <div className={`m-b-20 inputWrpr`}>
                        <label className={`ffmr fs12`}>New Count</label>
                        <Cleave
                            style={{ fontSize: "16px", fontFamily: "mont light" }}
                            options={{ numericOnly: true }}
                            value={censusData.count}
                            onChange={(e) => handleOnChange("count", e.target.value)}
                        />
                        {errors.count && (
                            <div className="error fs12 mt-1">{errors.count}</div>
                        )}
                    </div>
                </div>
                <div className={`${styles.popupFtr}`}>
                    <div className={`df aic ${styles.actionsWrrp}`}>
                        <div className={`mla`}>
                            <button
                                className={`ffmr fs14 m-r-8 ${styles.text}`}
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <LoadingButton
                                variant="contained"
                                onClick={save}
                                loading={loading}
                            >
                                Update
                            </LoadingButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditCensusDialog;
