import { createSlice } from "@reduxjs/toolkit";

const persistedThresholds = localStorage.getItem("percentageThresholds");
const initialPercentageThresholds = persistedThresholds
	? JSON.parse(persistedThresholds)
	: {
			veryBad: { value: 20, showColor: true },
			bad: { value: 10, showColor: true },
			littleBad: { value: 5, showColor: true },
			littleGood: { value: 5, showColor: true },
			good: { value: 10, showColor: true },
			veryGood: { value: 20, showColor: true },
	  };

const initialState = {
	comparisonRanges: [
		{
			startDate: new Date(),
			endDate: new Date(),
			key: "selection",
		},
	],
	rangesSet: false,
	percentageThresholds: initialPercentageThresholds,
	activeComparisonFacilitiesId: [],
	activeComparisonAccountId: null,
	comparisonFacilities: [],
	comparingAgainstDifferentFacility: false,
	shouldReverseOverallColors: true,
	resetComparison: false,
	refetchComparison: false,
};

const comparisonReducer = createSlice({
	name: "comparison",
	initialState,
	reducers: {
		setRefetchComparison(state, action) {
			state.refetchComparison = action.payload;
		},
		setComparisonRanges(state, action) {
			state.comparisonRanges = action.payload;
			state.rangesSet = true;
		},
		setRemoveCompareFilter(state, action) {
			state.comparisonRanges = initialState.comparisonRanges;
			state.rangesSet = false;
		},
		setPercentageThresholds(state, action) {
			const updatedThresholds = action.payload;

			Object.keys(updatedThresholds).forEach((key) => {
				if (state.percentageThresholds[key]) {
					state.percentageThresholds[key] = {
						...state.percentageThresholds[key],
						...updatedThresholds[key],
					};
				}
			});
			localStorage.setItem("percentageThresholds", JSON.stringify(state.percentageThresholds));
		},
		setActiveComparisonFacilitiesId(state, action) {
			state.activeComparisonFacilitiesId = action.payload;
		},
		setActiveComparisonAccountId(state, action) {
			state.activeComparisonAccountId = action.payload;
		},
		setComparisonFacilities(state, action) {
			state.comparisonFacilities = action.payload;
		},
		setcomparingAgainstDifferentFacility(state, action) {
			state.comparingAgainstDifferentFacility = action.payload;
		},
		setShouldReverseOverallColors(state, action) {
			state.shouldReverseOverallColors = action.payload;
		},
		setResetComparison(state, action) {
			state.resetComparison = action.payload;
		},
	},
});

export default comparisonReducer.reducer;

export const {
	setRefetchComparison,
	setComparisonRanges,
	setRemoveCompareFilter,
	setPercentageThresholds,
	setActiveComparisonFacilitiesId,
	setActiveComparisonAccountId,
	setComparisonFacilities,
	setcomparingAgainstDifferentFacility,
	setShouldReverseOverallColors,
	setResetComparison,
} = comparisonReducer.actions;
