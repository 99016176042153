import * as React from 'react';
import { Typography, Box } from '@mui/material';


export default function ShadowLineWithText({ data, sx }) {
    if (data && data.length === 0) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", width: "100%" }}>
                <Box sx={{ boxShadow: 3, width: "100%", height: "2px", mt: 8 }} />
                <Typography variant="subtitle1" sx={{ mt: 1, ...sx }} textAlign={"center"}>Additional data tabs shown below</Typography>
            </Box>
        )
    }
    return null;
}
