import api from './api';

const API_PREFIX = "api/settings";

const fetchSettings = async (path, method, body = {}, params = {}) => {
    const url = `${API_PREFIX}/${path}`;
    const response = await api[method](url, body, { params });
    return response;
};

const getAllSettings = async (params) => fetchSettings('all', 'get', {}, params);
const saveSetting = async (body) => fetchSettings('', 'post', body);
const updateSettingData = async (id, body) => fetchSettings(id, 'put', body);
const getOnlySettingsAll = async (params) => fetchSettings('select/options', 'get', {}, params);
const getEndDateOfADTData = async (params) => fetchSettings('adt/end-date-of-adt', 'get', {}, params);
const saveEndDateOfADTSetting = async (body) => fetchSettings('adt/end-date-of-adt', 'post', body);

const saveShortcutSettings = async (body) => fetchSettings('shortcut', 'post', body);
const getShortcutSettingData = async (params) => fetchSettings('shortcut/data', 'get', {}, params);


export {
    getAllSettings,
    saveSetting,
    updateSettingData,
    getOnlySettingsAll,
    getEndDateOfADTData,
    saveEndDateOfADTSetting,
    saveShortcutSettings,
    getShortcutSettingData
};
