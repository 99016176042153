import styles from "./HospitalList.module.scss";
import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import classNames from "classnames";
import { Stack } from "@mui/material";

const HospitalListSkeleton = () => {
	const fakeData = [1, 2, 3];
	return fakeData.map((x) => {
		return (
			<ListItem sx={{ padding: "5px" }} key={x} disablePadding className={classNames(styles.listItem)}>
				<ListItemButton
					sx={{
						borderRadius: "8px",
						cursor: "default",
					}}
					role={undefined}
					dense
					className={classNames("skeleton")}
				>
					<Stack
						direction={"row"}
						sx={{
							width: "100%",
							paddingTop: "15px",
							paddingBottom: "10px",
						}}
					>
						<Stack alignItems={"flex-start"} sx={{ position: "absolute", left: "-10px", top: "-10px" }}></Stack>
						<Stack sx={{ width: "100%" }} direction="row" alignItems={"center"} justifyContent="space-between">
							<div className={`${styles.rightContainer}`}></div>
						</Stack>
					</Stack>
				</ListItemButton>
			</ListItem>
		);
	});
};

export default HospitalListSkeleton;
