import styles from "./DiagnosticsCardList.module.scss";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

const DiagnosticsCardSkeleton = () => {
	const fakeData = [1, 2, 3, 4, 5];

	return (
		<div className={`${styles.diagnosticsCardList} `}>
			<List
				sx={{
					width: "100%",
					bgcolor: "background.paper",
				}}
				dense={true}
				disablePadding={true}
			>
				{fakeData.length > 0 &&
					fakeData.map((item) => {
						return (
							<ListItem
								sx={{ padding: "5px" }}
								key={item}
								dense={true}
								disablePadding={true}
								className={`${styles.listItem}`}
							>
								<ListItemButton
									sx={{
										borderRadius: "8px",
										paddingLeft: 2,
										height: "50px",
										paddingRight: { md: 1, lg: 2 },
										cursor: "default",
									}}
									role={undefined}
									dense
									className={`${styles.listItemButton} ${styles.skeleton} skeleton`}
								></ListItemButton>
							</ListItem>
						);
					})}
			</List>
		</div>
	);
};

export default DiagnosticsCardSkeleton;
