import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import _ from 'lodash';
import { Divider, FormControlLabel, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";


export default function FacilityList({ activeFacilities, facilities, selectedFacility, setSelectedFacility }) {
    const [searchText, setSearchText] = React.useState('');
    const [selectAll, setSelectAll] = React.useState(true);

    const handleToggle = (value) => () => {
        const currentIndex = selectedFacility.indexOf(value);
        const newChecked = [...selectedFacility];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setSelectAll(false)
        setSelectedFacility(newChecked);
    };

    const facilityList = React.useMemo(() => {
        const mappedFacilities = facilities.filter((x) => _.includes(activeFacilities, x._id));
        return mappedFacilities;
    }, [activeFacilities, facilities]);


    const toggleAll = async (e) => {
        setSelectedFacility(e.target.checked ? activeFacilities : [])
        setSelectAll(e.target.checked);
    };

    return (
        <>
            <TextField
                fullWidth
                sx={{ px: 1.5, pb: 1.5, mt: 2 }}
                placeholder="Search facility"
                size="small"
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <Stack direction={"row"} sx={{ ml: 2 }}>
                <FormControlLabel
                    size="small"
                    control={
                        <Checkbox
                            size="small"
                            onChange={toggleAll}
                            checked={selectAll}
                        />}
                    label={<Typography fontSize={14}>Select / Clear All</Typography>}
                />
            </Stack>
            <Divider />
            <List
                dense
                sx={{
                    width: '100%',
                    maxWidth: 500,
                    minWidth: 200,
                    bgcolor: 'background.paper',
                    borderRadius: "7px",
                    maxHeight: "300px",
                    overflow: "auto"
                }}
                component="div"
            >
                {facilityList && facilityList.length > 0 && _.orderBy(facilityList, "name", "ASC")?.filter(ele => ele.name?.toLowerCase().includes(searchText?.toLowerCase())).map((facility) => {
                    const labelId = `checkbox-list-label-${facility?._id}`;
                    return (
                        <ListItem
                            key={facility?._id}
                            disablePadding
                        >
                            <ListItemButton size="small" role={undefined} onClick={handleToggle(facility?._id)} dense>
                                <Checkbox
                                    edge="start"
                                    checked={selectedFacility.indexOf(facility?._id) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    size="small"
                                />
                                <ListItemText id={labelId} primary={facility?.name} />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
                {Array.isArray(facilityList) && (facilityList?.length === 0 || facilityList?.filter(ele => ele.name?.toLowerCase().includes(searchText?.toLowerCase())).length === 0) &&
                    <Typography sx={{ px: 1, py: 1, alignItems: "center" }}>No record found</Typography>
                }
            </List>
        </>
    );
}
