import { useEffect, useMemo, useState } from "react";
import { useSortBy, useTable } from "react-table";
import NewCloseSVG from "../../../../assets/svgs/new-close.svg";
import axios from "../../../../axios";
import { toDisplayTime } from "../../../../utilis/date-formats";
import styles from "./ResidentDetails.module.scss";

function Table({ columns, data, setSelectedResident }) {
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setSortBy } = useTable(
		{
			columns,
			data,
		},
		useSortBy
	);

	return (
		<div className={styles.tableWrpr}>
			<table {...getTableProps()}>
				<thead className={`ffmar fs12 ttuc`}>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th
									{...column.getHeaderProps(column.getSortByToggleProps())}
									{...(column.isSortable
										? {
												onClick: () => {
													//set sort desc, aesc or none?
													const desc =
														column.isSortedDesc === true ? undefined : column.isSortedDesc === false ? true : false;
													setSortBy([
														{ id: column.id, desc },
														{ id: "createdAt", desc },
													]);
												},
										  }
										: {})}
								>
									{column.render("Header")}
									<span>
										{column.isSorted
											? column.isSortedDesc
												? // ? " 🔽"
												  // : " 🔼"
												  " 🔽"
												: " 🔼"
											: ""}
									</span>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row, i) => {
						prepareRow(row);
						return (
							<tr className={`ffmar fs15`} {...row.getRowProps()} onClick={() => setSelectedResident(row.original)}>
								{row.cells.map((cell) => {
									return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}

const ResidentDetails = ({ details, close, tableColumns }) => {
	// eslint-disable-next-line
	const columns = useMemo(() => [
		{
			Header: "Last Name",
			accessor: "lastName",
			disableSortBy: true,
			// Cell: ({ value }) => <div>{dayjs(value).format("MMM-YYYY")}</div>,
		},
		{
			Header: "First Name",
			accessor: "firstName",
			disableSortBy: true,
			// Cell: ({ value }) => <div>{dayjs(value).format("MMM-YYYY")}</div>,
		},

		{
			Header: "MIddle Initial",
			accessor: "middleInitial",
			disableSortBy: true,
			// Cell: ({ value }) => <div>{dayjs(value).format("MMM-YYYY")}</div>,
		},

		{
			Header: "suffix",
			accessor: "suffix",
			disableSortBy: true,
		},
		{
			Header: "DOB",
			accessor: "DOB",
			disableSortBy: true,
			Cell: ({ value }) => <div>{toDisplayTime(value)}</div>,
		},

		{
			Header: "Type",
			accessor: "type",
			disableSortBy: true,
			Cell: ({ row }) => (
				<div>
					{row.original.type === "transfer"
						? row.original.transferType
								.replace(/([A-Z])/g, " $1")
								// uppercase the first character
								.replace(/^./, function (str) {
									return str.toUpperCase();
								})
						: row.original.type.replace(/^./, function (str) {
								return str.toUpperCase();
						  })}
				</div>
			),
		},

		// {
		//   Header: "Date",
		//   accessor: "dateOfADT",
		//   disableSortBy: false,
		//   isSortable: true,
		//   Cell: ({ row }) => (
		//     <div>{toDisplayTime(row.original.dateOfADT)}</div>
		//   ),
		// },
		// {
		//   Header: "Time of transfer",
		//   accessor: "transferTime",
		//   disableSortBy: true,
		//   Cell: ({ row }) =>
		//     row.original.transferTime ? row.original.transferTime : "N/A",
		// },

		// {
		//   Header: "Unit",
		//   accessor: "unit",
		//   disableSortBy: true,
		//   Cell: ({ row }) => <ValidationCell id={row.original.unit} />,
		// },

		// {
		//   Header: "Doctor",
		//   accessor: "doctor",
		//   disableSortBy: true,
		//   Cell: ({ row }) => <ValidationCell id={row.original.doctor} />,
		// },

		// {
		//   Header: "DX",
		//   accessor: "dx",
		//   disableSortBy: true,
		//   Cell: ({ row }) => {
		//     return row.original.dx.map((dx, i) => {
		//       return (
		//         <>
		//           <ValidationCell id={dx} />
		//           {i + 1 < row.original.dx.length ? `, ` : ""}
		//         </>
		//       );
		//     });
		//   },
		// },

		// {
		//   Header: "Hospital",
		//   accessor: "hospital",
		//   disableSortBy: true,
		//   Cell: ({ row }) => <ValidationCell id={row.original.hospital} />,
		// },

		// {
		//   Header: "Payer Source/Insurance",
		//   accessor: "payerSourceInsurance",
		//   disableSortBy: true,
		//   Cell: ({ row }) => {
		//     if (row.original.type === ADT_TYPES.ADMISSIONS || row.original.type === ADT_TYPES.READMISSIONS || row.original.type === ADT_TYPES.RETURN) {
		//       return <ValidationCell id={row.original.payerSourceInsurance} />
		//     } else {
		//       return <ValidationCell id={row.original.insurance} />
		//     }
		//   },
		// },

		// {
		//   Header: "Nurse",
		//   accessor: "nurse",
		//   disableSortBy: true,
		//   Cell: ({ row }) => <ValidationCell id={row.original.nurse} />,
		// },

		// {
		//   Header: "Was admitted to hospital",
		//   accessor: "wasAdmitted",
		//   disableSortBy: true,
		//   Cell: ({ row }) =>
		//     row.original.wasAdmitted === undefined
		//       ? ""
		//       : row.original.wasAdmitted === true
		//         ? "Yes"
		//         : "No",
		// },

		// {
		//   Header: "Returned from hospital",
		//   accessor: "wasReturned",
		//   disableSortBy: true,
		//   Cell: ({ row }) =>
		//     row.original.wasReturned === undefined
		//       ? ""
		//       : row.original.wasReturned === true
		//         ? "Yes"
		//         : "No",
		// },

		// {
		//   Header: "Notes",
		//   accessor: "notes",
		//   disableSortBy: true,
		// },

		// {
		//   Header: "Assisted living transferred to",
		//   accessor: "transferToWhichAssistedLiving",
		//   disableSortBy: true,
		//   Cell: ({ row }) => (
		//     <ValidationCell id={row.original.transferToWhichAssistedLiving} />
		//   ),
		// },
		// {
		//   Header: "Created At",
		//   accessor: "createdAt",
		//   disableSortBy: true,
		//   sortMethod: (a, b) => {
		//     var a1 = new Date(a).getTime();
		//     var b1 = new Date(b).getTime();
		//     if (a1 < b1)
		//       return 1;
		//     else if (a1 > b1)
		//       return -1;
		//     else
		//       return 0;
		//   }
		// },
		//   wasReturned
	]);

	const [list, setList] = useState([]);

	const getUser = async () => {
		let data = await axios.post(
			"/api/patient/details-relations",
			{
				...details,
			},
			{ headers: { "x-auth": `${localStorage.getItem("x-auth")}` } }
		);

		setList(data.data || []);
	};

	useEffect(() => {
		getUser();
		// eslint-disable-next-line
	}, []);

	return (
		<div className={styles.residentDetailsWrpr}>
			<div className={`${styles.residentDetailsContent}`}>
				<div className={`df aic ${styles.hdr}`}>
					<p className={`df aic ffmar fs15 fw700`}>
						Resident details {details.lastName} {details.firstName} {details.middleInitial} {details.suffix}
					</p>
					<div className={`df acc mla ${styles.closeBtn}`} onClick={close}>
						<NewCloseSVG />
					</div>
				</div>
				<div className={`${styles.tableSec}`}>
					<Table columns={tableColumns ? tableColumns : columns} data={list} />
					{/* {list.map((item, i) => {
          return <div className={styles.residentDetailsLine}>Helo</div>;
        })} */}
				</div>
			</div>
		</div>
	);
};

export default ResidentDetails;
