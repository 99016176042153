/* eslint-disable array-callback-return */
import _ from "lodash";
import { store } from "..";
import {
	setFacilityPercentage,
	setFilterTotal,
	setIsCensusTotalLocked,
	setLockedByFacility,
	setLockedTotalBy,
	setLockTotal,
} from "../store/reducers/community-transfer.slice";
import {
	setFacilityPercentage as setFacilityPercentageComparison,
	setFilterTotal as setFilterTotalComparison,
	setIsCensusTotalLocked as setIsCensusTotalLockedComparison,
	setLockedByFacility as setLockedByFacilityComparison,
	setLockedTotalBy as setLockedTotalByComparison,
	setLockTotal as setLockTotalComparison,
} from "../store/reducers/comparisonReducers/communityTransferComparison.slice";
import { TOTAL_TYPE } from "../types/common.type";
import { CO_TRANSFER_CARDS_TYPE, TRANSFER_TYPE } from "../types/community-transfer.type";
import { filterListDataItems, getDynamicPercentageBy, itemPercentage, updateListTotalValue } from "./common";

// * added comparison
export async function updateFacilityPercentageTotal(data, forComparison) {
	let facilityWiseTotal = _(data)
		.groupBy("facilityId")
		.sortBy((group) => data.indexOf(group[0]))
		.map((product) => {
			return {
				id: product[0]?.facilityId,
				total: product.length || 0,
			};
		})
		.value();
	forComparison
		? store.dispatch(setFacilityPercentageComparison(facilityWiseTotal))
		: store.dispatch(setFacilityPercentage(facilityWiseTotal));
}

export async function plannedFilter(patientData, oldFilter, type = null) {
	patientData = _.filter(patientData, ({ floorId, doctorId, snfFacilityId, insuranceId, assistantLivId }) => {
		let filterArr = [];

		if (oldFilter.insuranceData.length > 0 && type !== "insuranceData") {
			filterArr.push(_.includes(oldFilter.insuranceData, insuranceId));
		}
		if (oldFilter.doctorData.length > 0 && type !== "doctorData") {
			filterArr.push(_.includes(oldFilter.doctorData, doctorId));
		}
		if (oldFilter.floorsData.length > 0 && type !== "floorsData") {
			filterArr.push(_.includes(oldFilter.floorsData, floorId));
		}
		if (oldFilter.snfFacilityData.length > 0 && type !== "snfFacilityData") {
			filterArr.push(_.includes(oldFilter.snfFacilityData, snfFacilityId));
		}
		if (oldFilter.safeDischargeAssLivData.length > 0 && type !== "safeDischargeAssLivData") {
			if (!_.includes(oldFilter.safeDischargeAssLivData, "all")) {
				filterArr.push(_.includes(oldFilter.safeDischargeAssLivData, assistantLivId));
			}
		}
		return _.every(filterArr);
	});
	return patientData;
}

function returnsDataFilter(oldFilter) {
	if (oldFilter.returnsData[0] === "Returned") {
		return true;
	}
	if (oldFilter.returnsData[0] === "Didn't Return") {
		return false;
	}
}

// * added comparison
export async function updateFilterListData(cardFilter, patientList, priorityData = [], transferType, forComparison) {
	const { list, sixtyDaysData } = patientList;
	const {
		dbData,
		lockedTotal,
		lockedTotalBy
	} = forComparison ? store.getState().communityTransferComparison : store.getState().communityTransfer;

	let censusAverage = dbData?.censusAverage;

	if (!forComparison) {
		censusAverage = await getDynamicPercentageBy(dbData);
	}

	let objectCustom = Object();
	let newSavedFilters = [];
	let patientFilterData = list;
	let filter1 = Object();
	let mainNumPercentage = censusAverage;
	let isComparingAgainstAvgCensus = true;

	let totalFilterData = {
		originalData: patientFilterData,
		totalType: !forComparison ? transferType.length > 0 || priorityData?.length > 0 ? TOTAL_TYPE.FILTER : TOTAL_TYPE.MAIN : TOTAL_TYPE,		
		totalForPercentage: censusAverage,
	};
	let lockedTotalModified = lockedTotal;

	if (!lockedTotalModified) {
		forComparison ? store.dispatch(setLockedTotalByComparison(null)) : store.dispatch(setLockedTotalBy(null));
	}
	if (transferType.length > 0) {
		mainNumPercentage = null;
		if (!_.includes(transferType, TRANSFER_TYPE.ALL)) {
			patientFilterData = patientFilterData
				.map((item) => {
					if (_.includes(transferType, item.transferType)) {
						return item;
					}
				})
				.filter((item) => item);
		}
		isComparingAgainstAvgCensus = false;
		totalFilterData.totalForPercentage = patientFilterData.length;
	}

	if (lockedTotalModified) {
		// if (priorityData.length > 1) {
		//   mainNumPercentage = null;
		// }
		totalFilterData.totalForPercentage = lockedTotalModified;
	}

	if (lockedTotalBy) {
		if (lockedTotalBy === "transferType" || lockedTotalBy === "census") {
			if (lockedTotalModified && lockedTotalBy !== "census") {
				forComparison ? store.dispatch(setLockedTotalByComparison(null)) : store.dispatch(setLockedTotalBy(null));
				forComparison ? store.dispatch(setLockTotalComparison(null)) : store.dispatch(setLockTotal(null));
				lockedTotalModified = null;
			}
		} else {
			const lockedFilterRemoved = _.find(priorityData, { type: lockedTotalBy });
			if (!lockedFilterRemoved) {
				forComparison ? store.dispatch(setLockedTotalByComparison(null)) : store.dispatch(setLockedTotalBy(null));
				forComparison ? store.dispatch(setLockTotalComparison(null)) : store.dispatch(setLockTotal(null));
				lockedTotalModified = null;
			}
		}
	}
	forComparison ? store.dispatch(setFacilityPercentageComparison([])) : store.dispatch(setFacilityPercentage([]));
	if (!lockedTotalModified) {
		forComparison
			? store.dispatch(setIsCensusTotalLockedComparison(false))
			: store.dispatch(setIsCensusTotalLocked(false));
	}

	if (priorityData.length > 0) {
		isComparingAgainstAvgCensus = false;
	}

	if (
		(priorityData?.length > 0 && (!lockedTotalBy || !lockedTotal)) ||
		(priorityData?.length > 0 && lockedTotalBy !== "census" && lockedTotalBy !== "transferType")
	) {
		totalFilterData.totalForPercentage = null;
	}

	if (priorityData.length > 0) {
		let i = 0;
		for await (const ele of priorityData) {
			i++;
			if (priorityData.length === i && lockedTotalModified && !lockedTotalBy) {
				forComparison
					? store.dispatch(setLockedTotalByComparison(ele.type))
					: store.dispatch(setLockedTotalBy(ele.type));
			}
			const totalCountData = mainNumPercentage && i === 1 ? mainNumPercentage : totalFilterData.totalForPercentage;
			if (ele.type === CO_TRANSFER_CARDS_TYPE.INSURANCE_DATA) {
				objectCustom.insuranceData = await insuranceData(patientFilterData, {
					...totalFilterData,
					totalForPercentage: totalCountData,
				});

				patientFilterData = _.filter(patientFilterData, ({ insuranceId }) =>
					_.every([_.includes(cardFilter.insuranceData, insuranceId)])
				);
			}

			if (ele.type === CO_TRANSFER_CARDS_TYPE.SIXTY_DAYS_DATA) {
				objectCustom.sixtyDaysData = await sixtyDaysDataList(sixtyDaysData, patientFilterData, {
					...totalFilterData,
					totalForPercentage: totalCountData,
				});

				if (i === priorityData.length) {
					updateFacilityPercentageTotal(patientFilterData, forComparison);
				}
				patientFilterData = await sixtyDaysDataFilter(cardFilter.sixtyDaysData, patientFilterData, sixtyDaysData);
			}

			if (ele.type === CO_TRANSFER_CARDS_TYPE.RETURNS_DATA) {
				objectCustom.returnsData = await returnsData(patientFilterData, {
					...totalFilterData,
					totalForPercentage: totalCountData,
				});

				if (cardFilter.returnsData.length === 1) {
					filter1.wasReturned = returnsDataFilter(cardFilter);
					if (!_.isEmpty(filter1)) {
						patientFilterData = await _.filter(patientFilterData, filter1);
					}
				}
			}

			if (ele.type === CO_TRANSFER_CARDS_TYPE.DOCTOR_DATA) {
				objectCustom.doctorData = await doctorData(patientFilterData, {
					...totalFilterData,
					totalForPercentage: totalCountData,
				});
				patientFilterData = _.filter(patientFilterData, ({ doctorId }) =>
					_.every([_.includes(cardFilter.doctorData, doctorId)])
				);
			}

			if (ele.type === CO_TRANSFER_CARDS_TYPE.FLOORS_DATA) {
				objectCustom.floorsData = await floorsData(patientFilterData, {
					...totalFilterData,
					totalForPercentage: totalCountData,
				});
				patientFilterData = _.filter(patientFilterData, ({ floorId }) =>
					_.every([_.includes(cardFilter.floorsData, floorId)])
				);
			}

			if (ele.type === CO_TRANSFER_CARDS_TYPE.SAFE_DISCHARGE_ASS_LIV_DATA) {
				objectCustom.safeDischargeAssLivData = await safeDischargeAssLivData(patientFilterData, {
					...totalFilterData,
					totalForPercentage: totalCountData,
				});

				patientFilterData = _.filter(patientFilterData, ({ assistantLivId }) =>
					_.every([_.includes(cardFilter.safeDischargeAssLivData, assistantLivId)])
				);
			}

			if (ele.type === CO_TRANSFER_CARDS_TYPE.SNF_FACILITY_DATA) {
				objectCustom.snfFacilityData = await snfFacilityData(patientFilterData, {
					...totalFilterData,
					totalForPercentage: totalCountData,
				});

				patientFilterData = _.filter(patientFilterData, ({ snfFacilityId }) =>
					_.every([_.includes(cardFilter.snfFacilityData, snfFacilityId)])
				);
			}

			newSavedFilters = [...newSavedFilters, ele.type];
		}
		//store.dispatch(setFilterTotal(patientFilterData.length));
		const totalPercentageCount = lockedTotalModified ? lockedTotalModified : patientFilterData.length; //transferType ? patientFilterData?.length : censusAverage
		forComparison
			? store.dispatch(setFilterTotalComparison(totalPercentageCount))
			: store.dispatch(setFilterTotal(totalPercentageCount));
		// Update array data

		if (!_.includes(newSavedFilters, CO_TRANSFER_CARDS_TYPE.INSURANCE_DATA)) {
			objectCustom.insuranceData = await insuranceData(patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}
		if (!_.includes(newSavedFilters, CO_TRANSFER_CARDS_TYPE.SIXTY_DAYS_DATA)) {
			objectCustom.sixtyDaysData = await sixtyDaysDataList(sixtyDaysData, patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}
		if (!_.includes(newSavedFilters, CO_TRANSFER_CARDS_TYPE.RETURNS_DATA)) {
			objectCustom.returnsData = await returnsData(patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}
		if (!_.includes(newSavedFilters, CO_TRANSFER_CARDS_TYPE.DOCTOR_DATA)) {
			objectCustom.doctorData = await doctorData(patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}
		if (!_.includes(newSavedFilters, CO_TRANSFER_CARDS_TYPE.FLOORS_DATA)) {
			objectCustom.floorsData = await floorsData(patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}
		if (!_.includes(newSavedFilters, CO_TRANSFER_CARDS_TYPE.SAFE_DISCHARGE_ASS_LIV_DATA)) {
			objectCustom.safeDischargeAssLivData = await safeDischargeAssLivData(patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}
		if (!_.includes(newSavedFilters, CO_TRANSFER_CARDS_TYPE.SNF_FACILITY_DATA)) {
			objectCustom.snfFacilityData = await snfFacilityData(patientFilterData, {
				...totalFilterData,
				totalType: TOTAL_TYPE.FILTER,
				totalForPercentage: totalPercentageCount,
			});
		}
		if (!lockedTotalModified) {
			forComparison
				? store.dispatch(setLockedByFacilityComparison(patientFilterData))
				: store.dispatch(setLockedByFacility(patientFilterData));
		}
	} else {
		const totalCount = mainNumPercentage ? mainNumPercentage : transferType ? patientFilterData.length : censusAverage;

		forComparison
			? store.dispatch(setFilterTotalComparison(lockedTotalModified ? lockedTotalModified : totalCount))
			: store.dispatch(setFilterTotal(lockedTotalModified ? lockedTotalModified : totalCount));

		if (transferType.length === 0 && lockedTotalModified) {
			forComparison
				? store.dispatch(setIsCensusTotalLockedComparison(true))
				: store.dispatch(setIsCensusTotalLocked(true));
		}
		if (!lockedTotalModified) {
			forComparison
				? store.dispatch(setLockedByFacilityComparison(patientFilterData))
				: store.dispatch(setLockedByFacility(patientFilterData));
		}
		if (lockedTotalModified && !lockedTotalBy) {
			if (transferType.length > 0) {
				forComparison
					? store.dispatch(setLockedTotalByComparison("transferType"))
					: store.dispatch(setLockedTotalBy("transferType"));
			} else {
				forComparison
					? store.dispatch(setLockedTotalByComparison("census"))
					: store.dispatch(setLockedTotalBy("census"));
			}
		}
		updateFacilityPercentageTotal(patientFilterData);
		[
			objectCustom.insuranceData,
			objectCustom.sixtyDaysData,
			objectCustom.returnsData,
			objectCustom.doctorData,
			objectCustom.floorsData,
			objectCustom.safeDischargeAssLivData,
			objectCustom.snfFacilityData,
		] = await Promise.all([
			insuranceData(patientFilterData, totalFilterData),
			sixtyDaysDataList(sixtyDaysData, patientFilterData, totalFilterData),
			returnsData(patientFilterData, totalFilterData),
			doctorData(patientFilterData, totalFilterData),
			floorsData(patientFilterData, totalFilterData),
			safeDischargeAssLivData(patientFilterData, totalFilterData),
			snfFacilityData(patientFilterData, totalFilterData),
		]);
	}
	objectCustom.isComparingAgainstAvgCensus = isComparingAgainstAvgCensus;

	return objectCustom;
}

async function sixtyDaysDataFilter(cardFilter, patientData, ninetyDaysData) {
	let sixtyDaysDataIds = [];
	let sixtyDaysDataFilters = _.filter(ninetyDaysData, ({ _id }) => _.every([_.includes(cardFilter, _id)]));
	if (sixtyDaysDataFilters && sixtyDaysDataFilters?.length > 0) {
		sixtyDaysDataFilters.map((item) => (sixtyDaysDataIds = [...sixtyDaysDataIds, ...item.ids]));
	}
	patientData = _.filter(patientData, ({ _id }) => _.every([_.includes(sixtyDaysDataIds, _id)]));
	return patientData;
}

async function sixtyDaysDataList(data, patients, totalFilter) {
	const patientIds = patients?.length > 0 ? patients.map((item) => item.id) : [];
	let percentageTotal = totalFilter?.totalForPercentage ? totalFilter.totalForPercentage : patients?.length;

	const res = await updateListTotalValue(data, patientIds, "value", percentageTotal, totalFilter);
	return res;
}

async function insuranceData(data, totalFilter) {
	//insuranceData Cal
	let insuranceDBDataGroup = [];
	if (data && data.length > 0) {
		const insuranceDBData = _.groupBy(data, "insuranceId");
		const originalData = _.groupBy(totalFilter.originalData, "insuranceId");
		let percentageTotal = totalFilter?.totalForPercentage ? totalFilter.totalForPercentage : data?.length;

		if (insuranceDBData) {
			insuranceDBDataGroup = await filterListDataItems(insuranceDBData, "insurance", percentageTotal, {
				...totalFilter,
				originalData,
			});
		}
	}

	return insuranceDBDataGroup;
}

async function doctorData(data, totalFilter) {
	//Doctor list update Cal
	let doctorDBDataGroup = [];
	if (data && data.length > 0) {
		const doctorDBData = _.groupBy(data, "doctorId");
		const originalData = _.groupBy(totalFilter.originalData, "doctorId");
		let percentageTotal = totalFilter?.totalForPercentage ? totalFilter.totalForPercentage : data?.length;

		if (doctorDBData) {
			doctorDBDataGroup = await filterListDataItems(doctorDBData, "doctor", percentageTotal, {
				...totalFilter,
				originalData,
			});
		}
	}
	return doctorDBDataGroup;
}

async function returnsData(data, totalFilter) {
	//DCERResultData Cal
	const dintReturnResult = _.countBy(data, "wasReturned");
	const { originalData = [], totalType = null, totalForPercentage = null } = totalFilter;
	let percentageTotal = totalForPercentage ? totalForPercentage : data?.length;

	const originalDataGroupBy = _.countBy(originalData, "wasReturned");

	const returnDidNotDetails = [
		{
			id: "Returned",
			label: "Returned",
			_id: "Returned",
			total: dintReturnResult.true || 0,
			originalTotal: originalDataGroupBy?.true || 0,
			isTooltip: totalType && totalType === TOTAL_TYPE.MAIN ? false : true,
			percentage: itemPercentage(dintReturnResult.true, percentageTotal, "number"),
		},
		{
			id: "Didn't Return",
			label: "Didn't Return",
			_id: "Didn't Return",
			total: dintReturnResult.false || 0,
			originalTotal: originalDataGroupBy?.false || 0,
			isTooltip: totalType && totalType === TOTAL_TYPE.MAIN ? false : true,
			percentage: itemPercentage(dintReturnResult.false, percentageTotal, "number"),
		},
	];
	return returnDidNotDetails;
}

async function floorsData(data, totalFilter) {
	//FloorsData Cal
	let unitDBDataGroup = [];
	if (data && data.length > 0) {
		const floorDBData = _.groupBy(data, "floorId");
		const originalData = _.groupBy(totalFilter.originalData, "floorId");
		let percentageTotal = totalFilter?.totalForPercentage ? totalFilter.totalForPercentage : data?.length;

		if (floorDBData) {
			unitDBDataGroup = await filterListDataItems(floorDBData, "unit", percentageTotal, {
				...totalFilter,
				originalData,
			});
		}
	}
	return unitDBDataGroup;
}

async function snfFacilityData(data, totalFilter) {
	//SNF Facility Data Cal
	let snfFacilityDBDataGroup = [];
	if (data && data.length > 0) {
		// const totalData = _.filter(data, (item) => item.snfFacilityId);
		const snfFacilityDBData = _.groupBy(data, "snfFacilityId");
		const originalData = _.groupBy(totalFilter.originalData, "snfFacilityId");
		let percentageTotal = totalFilter?.totalForPercentage ? totalFilter.totalForPercentage : data?.length;

		if (snfFacilityDBData) {
			snfFacilityDBDataGroup = await filterListDataItems(snfFacilityDBData, "snf", percentageTotal, {
				...totalFilter,
				originalData,
			});
		}
	}
	return snfFacilityDBDataGroup;
}

async function safeDischargeAssLivData(data, totalFilter) {
	//SNF Facility Data Cal
	let safeDischargeAssLivDBDataGroup = [];
	if (data && data.length > 0) {
		const safeDischargeAssLivDBData = _.groupBy(data, "assistantLivId");
		const originalData = _.groupBy(totalFilter.originalData, "assistantLivId");
		let percentageTotal = totalFilter?.totalForPercentage ? totalFilter.totalForPercentage : data?.length;

		if (safeDischargeAssLivDBData) {
			safeDischargeAssLivDBDataGroup = await filterListDataItems(
				safeDischargeAssLivDBData,
				"transferToWhichAssistedLiving",
				percentageTotal,
				{ ...totalFilter, originalData }
			);
		}
	}
	return safeDischargeAssLivDBDataGroup;
}
