import { store } from "..";
import { setFilterDBData } from "../store/reducers/overall.slice";
import { getCardPatientData, getCardPatientChartData, getAllOverallCount } from "./api/overall.api";
import {
	setFilterDBData as setFilterDBDataComparison,
	setCardFilterOverall,
} from "../store/reducers/comparisonReducers/overallComparison.slice";

const cardPatientData = async (data) => {
	try {
		const res = await getCardPatientData(data);
		return res;
	} catch (e) {
		console.log(e);
	}
};

const cardPatientAndChartData = async (data = null) => {
	try {
		const res = await getCardPatientChartData(data);
		return res;
	} catch (e) {
		console.log(e);
	}
};

const getAllOverallPatient = async (data = null, forComparison) => {
	try {
		const res = await getAllOverallCount(data);
		if (res && forComparison) {
			store.dispatch(setCardFilterOverall({ listData: res }));
		}
		store.dispatch(forComparison ? setFilterDBDataComparison(res.totals) : setFilterDBData(res.totals));
		return res.data;
	} catch (e) {}
};

export { cardPatientData, cardPatientAndChartData, getAllOverallPatient };
