import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hospitalPage: {
    floor: false,
    doctor: false,
    day: false,
    diagnosis: false,
    shift: false,
    nurse: false,
    hospitalResidentTransferredTo: false,
    viewThroughAdt: false,
  },
  communityPage: {
    doctor: false,
    floor: false,
    safeDischarges: false,
    transfers: false,
    viewThroughAdt: false,
  },
};

const dataCardsExpand = createSlice({
  name: "dataCardsExpandState",
  initialState,
  reducers: {
    setCardExpandState(state, action) {
      const page = action.payload.page;
      const card = action.payload.card;

      if (page === "hospitalPage" || page === "communityPage") {
        if (state[page][card] !== undefined) {
          state[page][card] = !!action.payload.state;
        }
      }
    },
  },
});

export default dataCardsExpand.reducer;

export const { setCardExpandState } = dataCardsExpand.actions;
