import { ADD_ACCOUNT, DELETE_ACCOUNT, SET_ACCOUNTS, UPDATE_ACCOUNT } from "../types";

const reducer = (state = null, action) => {
  switch (action.type) {
    case SET_ACCOUNTS:
      return action.payload;
    case ADD_ACCOUNT:
      return [...state, action.payload];
    case UPDATE_ACCOUNT:
      const newAccounts = state.map(p =>
        p._id === action.payload._id
          ? action.payload
          : p
      );
      return newAccounts;
    case DELETE_ACCOUNT:
      return state.filter((account) => account._id !== action.payload);
    default:
      return state;
  }
};

export default reducer;
