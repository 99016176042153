import { store } from "..";
import { ADD_NOTIFICATION } from "../store/types";
import {
	changeUserPassword,
	forgotPassword,
	getAccountFacilityData,
	getFacilityOptionData,
	getManageFacilitiesData,
	saveNewPassword,
	saveUser,
	updateFirstPassword,
	updateUser,
	updateUserProfile,
	userLogin,
} from "./api/user.api";

const getAccountFacility = async (filter) => {
	try {
		return await getAccountFacilityData(filter);
	} catch (e) {
		console.log("error error 1");
	}
};

const getFacilityOption = async () => {
	try {
		return await getFacilityOptionData();
	} catch (e) {
		console.log("error error 1");
	}
};

const getManageFacilities = async (params) => {
	try {
		return await getManageFacilitiesData(params);
	} catch (e) {
		console.log("error error 1");
	}
};

const createUser = async (body) => {
	try {
		const res = await saveUser(body);
		if (res && res?._id) {
			store.dispatch({
				type: ADD_NOTIFICATION,
				payload: {
					type: "success",
					label: "User added successfully",
					id: "addUserError",
				},
			});
		}
		return res;
	} catch (e) {
		store.dispatch({
			type: ADD_NOTIFICATION,
			payload: {
				type: "error",
				label: e?.response?.data?.message || "Error while create new user.",
				id: "createErrorUser",
			},
		});
	}
};

const updateOneUser = async (id, body) => {
	try {
		const res = await updateUser(id, body);
		if (res) {
			store.dispatch({
				type: ADD_NOTIFICATION,
				payload: {
					type: "success",
					label: "User saved successfully",
					id: "updateUserError",
				},
			});
		}
		return res;
	} catch (e) {
		console.log(e);
		store.dispatch({
			type: ADD_NOTIFICATION,
			payload: {
				type: "error",
				label: e?.response?.data?.message || "Error while update new user.",
				id: "updateUserError",
			},
		});
	}
};

const saveUserProfile = async (body) => {
	try {
		const res = await updateUserProfile(body);
		if (res && res?.data) {
			store.dispatch({
				type: ADD_NOTIFICATION,
				payload: {
					type: "success",
					label: "User profile updated successfully",
					id: "updateUserError",
				},
			});
		}
		return res;
	} catch (e) {
		store.dispatch({
			type: ADD_NOTIFICATION,
			payload: {
				type: "error",
				label: e?.response?.data?.message || "Error while update new user.",
				id: "updateUserError",
			},
		});
	}
};

const changePassword = async (id, body) => {
	try {
		const res = await changeUserPassword(id, body);
		if (res) {
			store.dispatch({
				type: ADD_NOTIFICATION,
				payload: {
					type: "success",
					label: "Password changed successfully.",
					id: "updateUserError",
				},
			});
		}
		return res;
	} catch (e) {
		store.dispatch({
			type: ADD_NOTIFICATION,
			payload: {
				type: "error",
				label: e?.response?.data?.message || "Error while change password.",
				id: "updateUserError",
			},
		});
	}
};

const forgotPasswordLink = async (body) => {
	try {
		const res = await forgotPassword(body);
		if (res) {
			store.dispatch({
				type: ADD_NOTIFICATION,
				payload: {
					type: "success",
					label: `Reset password link sent successfully to your registered email ${body.email}.`,
					id: "updateUserError",
				},
			});
		}
		return res;
	} catch (e) {
		store.dispatch({
			type: ADD_NOTIFICATION,
			payload: {
				type: "error",
				label: e?.response?.data?.message || "Error while change password.",
				id: "updateUserError",
			},
		});
	}
};

const resetUserPassword = async (body) => {
	try {
		const res = await saveNewPassword(body);
		if (res) {
			store.dispatch({
				type: ADD_NOTIFICATION,
				payload: {
					type: "success",
					label: "Your password updated successfully.",
					id: "updateUserError",
				},
			});
		}
		return res;
	} catch (e) {
		store.dispatch({
			type: ADD_NOTIFICATION,
			payload: {
				type: "error",
				label: e?.response?.data?.message || "Error while update password.",
				id: "updateUserError",
			},
		});
	}
};

const updateFirstUserPassword = async (userId, body) => {
	try {
		const res = await updateFirstPassword(userId, body);
		if (res) {
			store.dispatch({
				type: ADD_NOTIFICATION,
				payload: {
					type: "success",
					label: "Your password updated successfully.",
					id: "updateUserError",
				},
			});
		}
		return res;
	} catch (e) {
		store.dispatch({
			type: ADD_NOTIFICATION,
			payload: {
				type: "error",
				label: e?.response?.data?.message || "Error while update password.",
				id: "updateUserError",
			},
		});
	}
};

const authLogin = async (body) => {
	let attempts = 0;
	const maxAttempts = 5;
	while (attempts < maxAttempts) {
		try {
			const res = await userLogin(body);
			if (res) {
				store.dispatch({
					type: ADD_NOTIFICATION,
					payload: {
						type: "success",
						label: "User logged successfully.",
						id: "updateUserError",
					},
				});
				return res;
			}
		} catch (e) {
			if (e?.response?.data?.message === "Username or Password are not correct") {
				store.dispatch({
					type: ADD_NOTIFICATION,
					payload: {
						type: "error",
						label: e?.response?.data?.message || "Error while login user",
						id: "updateUserError",					
					},
				});
				return;
			} else {
				store.dispatch({
					type: ADD_NOTIFICATION,
					payload: {
						type: "error",
						label: "Retrying login",
						id: "updateUserErrorRetry",
					},
				});
				attempts++;
				if (attempts === maxAttempts) {
					store.dispatch({
						type: ADD_NOTIFICATION,
						payload: {
							type: "error",
							label: "Maximum login attempts reached.",
							id: "updateUserErrorMaxAttempts",
						},
					});
					return;
				}
			}
		}
	}
};

export {
	authLogin,
	changePassword,
	createUser,
	forgotPasswordLink,
	getAccountFacility,
	getFacilityOption,
	getManageFacilities,
	resetUserPassword,
	saveUserProfile,
	updateFirstUserPassword,
	updateOneUser
};

