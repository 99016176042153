import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import NoRecordFound from "../../../shared/NoRecordFound";
import { toDisplayTime } from "../../../../utilis/date-formats";
import { DASHBOARD_FILTER_TYPE } from "../../../../types/common.type";
import _ from "lodash";
import { Box, IconButton, Stack, TableSortLabel, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import ResidentDetails from "../../../landing/facility/data/residentDetails";
import { expandedColumnsDefault } from "../../../../types/pages.type";
import HtmlTooltip from "../../HtmlTooltip";
import EditSVG from "../../../../assets/svgs/edit.svg";
import AddPatient from "../../../landing/facility/data/add-patient/AddPatient";
import { getCensusFirstDate } from "../../../../services/data-input.service";
import { useSelector } from "react-redux";
import ActivityLogTooltip from "../../ActivityLogTooltip";
import InfoIcon from "../../../icon/InfoIcon";
import CustomTooltipContentWithAction from "../../CustomTooltipContentWithAction";
import { patientDataOrderBy } from "../../../../utilis/common";

export default function ChartTableList({
    data,
    getFieldNames,
    relation,
    pageType = null,
    tableElementRef,
    isOnlyHospitalDashboard = false,
    questions = [],
    searchTerm,
    handleHighlightedCount,
    filterType,
    selectedHighlightedColumns,
    orderData,
    active,
    activeFacilities
}) {
    const { orderBy, setOrderBy, order, setOrder } = orderData
    let [highlightedCountDefaultValue, setHighlightedCountDefaultValue] = React.useState(0);

    React.useEffect(() => {
        setHighlightedCountDefaultValue(0);
    }, [filterType?.operation]);

    const [selectedResident, setSelectedResident] = React.useState(undefined);
    const [chartData, setChartData] = React.useState(data);
    const { permission } = useSelector(({ permission }) => ({ permission }));
    const isWriteAccess = React.useMemo(
        () => permission?.facilityAccess?.write ?? false,
        [permission]
    );

    const [previousColor, setPreviousColor] = React.useState(null);

    React.useEffect(() => {
        setChartData(data);
    }, [data]);

    const highlightText = (text, accessor, setCount, toolTipContent) => {
        if (!searchTerm) return text;
        let isHighlighted = true;
        if (selectedHighlightedColumns && selectedHighlightedColumns.length > 0) {
            isHighlighted = accessor && Array.isArray(selectedHighlightedColumns) && selectedHighlightedColumns.includes(accessor) ? true : false;
        }
        const regex = new RegExp(`(${searchTerm})`, filterType.type === 'number' && filterType.operation ? 'g' : 'gi');
        if (text !== null && isHighlighted) {
            let parts;
            if (filterType.type === 'number' && filterType.operation) {
                let isNumberToolTip;
                let textValueToolTip;
                let isMatchToolTip;
                if (toolTipContent) {
                    isNumberToolTip = !isNaN(toolTipContent) && toolTipContent !== null;
                    textValueToolTip = isNumberToolTip ? Number(toolTipContent) : toolTipContent;
                }
                const isNumber = !isNaN(text) && text !== null;
                const textValue = isNumber ? Number(text) : text;
                if (isNumber) {
                    isMatchToolTip = filterType.operation === 'lessThan' ? textValueToolTip <= searchTerm : textValueToolTip >= searchTerm;
                    const isMatch = filterType.operation === 'lessThan' ? textValue <= searchTerm : textValue >= searchTerm;
                    if (isMatch) {
                        if (isMatchToolTip) {
                            parts = <span style={{ backgroundColor: 'yellow' }}>{text.substring(0, 3)}</span>;
                        } else {
                            parts = <span style={{ backgroundColor: 'yellow' }}>{text}</span>;
                        }
                        highlightedCountDefaultValue++
                    } else {
                        parts = text;
                    }
                } else {
                    parts = text;
                }
                setCount(highlightedCountDefaultValue);
                return parts;
            } else {
                let partsTooltip;
                let isMatchToolTip = 0;
                if (toolTipContent) {
                    partsTooltip = toolTipContent?.split(regex);
                    partsTooltip?.forEach(part => {
                        if (regex.test(part)) {
                            isMatchToolTip++;
                        }
                    });
                }
                parts = text?.split(regex);
                parts.forEach(part => {
                    if (regex.test(part)) {
                        highlightedCountDefaultValue++;
                    }
                });
                setCount(highlightedCountDefaultValue);
                parts = parts.map((part, index) => {
                    if (isMatchToolTip > 0 && part.includes('...')) {
                        const parts = part.split('...');
                        return (
                            <span key={index}>
                                {parts.map((subPart, subIndex) => (
                                    <React.Fragment key={subIndex}>
                                        {subPart}
                                        {subIndex < parts.length - 1 && (
                                            <span style={{ backgroundColor: 'yellow' }}>...</span>
                                        )}
                                    </React.Fragment>
                                ))}
                            </span>
                        );
                    }
                    return regex.test(part) ? <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> : part
                });
                return parts;
            }
        } else {
            return text;
        }
    };


    const getTooltipText = (text, accessor = null, isHighlighted = true) => {
        const count = 25, variant = "body2", isLightTooltip = false;
        if (!text) return null;
        const tooltipContent = text.length > count ? `${text.substring(0, count)}...` : text;
        const tooltipProps = {
            title: <CustomTooltipContentWithAction text={text.length > count ? highlightText(text, accessor, handleHighlightedCount) : text} />,
            arrow: true,
            sx: { background: "#fff", color: "#000" },
        };

        return (
            <Typography
                variant={variant}
                sx={{
                    ...!isHighlighted && {
                        textTransform: "uppercase",
                        fontFamily: '"manrope-bold"',
                        fontSize: "12px",
                        lineHeight: 1.4,
                        color: "#000",
                    }
                }}
            >
                {text.length > count ? (
                    isLightTooltip ? (
                        <HtmlTooltip PopperProps={{ style: { zIndex: 1000 } }} {...tooltipProps} key={text + "tooltip_html"} disableFocusListener>
                            <span style={{ cursor: "pointer" }}>
                                {isHighlighted ? highlightText(tooltipContent, accessor, handleHighlightedCount) : tooltipContent}
                            </span>
                        </HtmlTooltip>
                    ) : (
                        <Tooltip
                            {...tooltipProps}
                            PopperProps={{
                                stopPropagation: true,
                                onClick: (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                },
                            }}
                            key={text + "tooltip"}
                            disableFocusListener
                        >
                            <span style={{ cursor: "pointer" }}>
                                {isHighlighted ? highlightText(tooltipContent, accessor, handleHighlightedCount, text.substring(count)) : tooltipContent}
                            </span>
                        </Tooltip>
                    )
                ) : (
                    <span style={{ cursor: "pointer" }}>{isHighlighted ? highlightText(text || "", accessor, handleHighlightedCount) : text}</span>
                )}
            </Typography>
        );
    };

    const getQuestionAnswer = (question, row) => {
        const { accessor, type, validationType, multiple } = question;
        switch (type) {
            case "text":
                return row[accessor] ? getTooltipText(row[accessor], accessor) : null;
            case "toggle":
                if (accessor === "transferType") {
                    return highlightText(row[accessor], accessor, handleHighlightedCount);
                } else {
                    return highlightText(row[accessor] ? "Yes" : "No", accessor, handleHighlightedCount);
                }
            case "date":
                return row[accessor] ? highlightText(toDisplayTime(row[accessor], "MM/DD/YYYY"), accessor, handleHighlightedCount) : "-";
            case "time":
                return row[accessor] ? highlightText(row[accessor], accessor, handleHighlightedCount) : null;
            case "validation":
                if (multiple) {
                    const dxAnswer = row[validationType]
                        .map((prodData) => prodData.label)
                        .join(", ");
                    return dxAnswer ? getTooltipText(dxAnswer, accessor) : "N/A";
                } else {
                    const labelAccessor = accessor === "payerSourceInsurance" && validationType === "insurance" ? "payerSourceInsurance" : validationType;
                    return row[labelAccessor]
                        ? getTooltipText(row[labelAccessor]?.label, accessor)
                        : "N/A";
                }
            default:
                return row[accessor] ? highlightText(row[accessor], accessor, handleHighlightedCount) : null;
        }
    };

    function getTableColumnsForDetails() {
        const dynamicColumns = questions.map(question => ({
            Header: question.order?.tableLabel || question.order?.label || question.question?.tableLabel || question.question?.label,
            accessor: question.question?.accessor,
            Cell: ({ row }) => row ? getQuestionAnswer(question.question, row.original) || "N/A" : "N/A",
        }));
        return [...expandedColumnsDefault, ...dynamicColumns];
    }

    const [showAdd, setShowAdd] = React.useState(false);
    const [editResident, setEditResident] = React.useState(undefined);
    const toggleAdd = () => setShowAdd(!showAdd);
    const [initialDate, setInitialDate] = React.useState(null);
    const getInitialCensusDate = async () => {
        const facilityId = localStorage.getItem("facilityId");
        await getCensusFirstDate(facilityId).then((res) => {
            if (res) {
                setInitialDate(res?.date);
            }
        });
    };

    React.useEffect(() => {
        getInitialCensusDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem("facilityId")]);

    const patientAdded = (patient) => {
        if (patient) {
            setChartData((prev) =>
                prev.map((userScore) => {
                    if (userScore._id === patient?._id) {
                        return {
                            ...userScore,
                            ...patient,
                        };
                    } else {
                        return userScore;
                    }
                })
            );
        }
        // if (type && type.toLowerCase() === patient?.type.toLowerCase()) {
        // 	const newData = update(patients, {
        // 		$push: [patient],
        // 	});
        // 	setPatients(newData);
        // 	setPatientsOriginal(newData);
        // }
    };

    const getHighLightColor = React.useCallback((fieldName, row) => {
        if (row && row?.highlighter && row?.highlighter.length > 0) {
            const color = row?.highlighter.find(
                (ele) => ele?.fieldName === fieldName
            )?.color;
            return color;
        } else {
            return null;
        }
    }, []);

    const handleScrollTo = (fieldName, color) => {
        if (previousColor && fieldName === previousColor?.fieldName) {
            const elementPrevious = document.getElementById(previousColor?.fieldName);
            elementPrevious.style.border = "";
            elementPrevious.style.borderBottom = "1px solid " + previousColor?.color;
            setPreviousColor(null);
        } else {
            const element = document.getElementById(fieldName);
            if (previousColor) {
                const elementPrevious = document.getElementById(previousColor?.fieldName);
                elementPrevious.style.border = "";
                elementPrevious.style.borderBottom = "1px solid " + previousColor?.color;
            }
            if (element) {
                element.style.border = "1px solid " + color;
                // element.scrollIntoView({
                //     behavior: "smooth",
                //     block: "center",
                //     inline: "start",
                // });
                // Scroll parent container to the left              

                const parentContainer = element.closest(".scrollable-container"); // Replace with your actual scrollable container class                
                if (parentContainer) {
                    parentContainer.scrollLeft =
                        element.offsetLeft - parentContainer.offsetLeft;
                }
                setPreviousColor({ fieldName, color });
            }
        }
    }



    // Handle sorting logic
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // Function to sort the data
    const sortedData = patientDataOrderBy(chartData, order, orderBy);

    const TableSortLabelHeader = (props) => {
        return (
            <TableSortLabel
                active={orderBy === props.name}
                direction={orderBy === props.name ? order : 'asc'}
                onClick={() => handleRequestSort(props.name)}
            >
                {props.label}
            </TableSortLabel>
        );
    }

    return (
        <>
            <TableContainer
                component={Paper}
                className="scrollable-container"
                sx={{ maxHeight: "calc(100vh - 220px)", overflow: "auto" }}
                ref={tableElementRef}
                id="chart-table-list-id"
            >
                <Table
                    className="print-table"
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a table"
                    stickyHeader
                >
                    <TableHead>
                        <TableRow
                            sx={{
                                "& th": {
                                    textTransform: "uppercase",
                                    fontFamily: '"manrope-bold"',
                                    fontSize: "12px",
                                    lineHeight: 1.4,
                                    color: "#000",
                                    padding: "2px 8px",
                                },
                            }}
                        >

                            <TableCell
                                style={{
                                    position: "sticky",
                                    left: 0,
                                    top: 0,
                                    minWidth: "100px",
                                    zIndex: showAdd ? 1 : 4,
                                }}
                            >
                                Edit
                            </TableCell>
                            {activeFacilities?.length > 1 && (
                                <TableCell
                                    style={{
                                        position: "sticky",
                                        left: "100px",
                                        top: 0,
                                        minWidth: "120px",
                                        zIndex: showAdd ? 1 : 4,
                                    }}
                                    sortDirection={orderBy === 'facilityName' ? order : false}
                                >
                                    <TableSortLabelHeader
                                        name="facilityName"
                                        label="facility"
                                        handleRequestSort={handleRequestSort}
                                    />
                                </TableCell>
                            )}
                            <TableCell
                                style={{
                                    position: "sticky",
                                    left: activeFacilities?.length > 1 ? "220px" : "100px",
                                    top: 0,
                                    minWidth: "120px",
                                    zIndex: showAdd ? 1 : 4,
                                }}
                                sortDirection={orderBy === 'lastName' ? order : false}
                            >
                                <TableSortLabelHeader
                                    name="lastName"
                                    label="Last Name"
                                    handleRequestSort={handleRequestSort}
                                />
                            </TableCell>
                            <TableCell
                                style={{
                                    position: "sticky",
                                    left: activeFacilities?.length > 1 ? "340px" : "220px",
                                    top: 0,
                                    minWidth: "120px",
                                    zIndex: showAdd ? 1 : 4,
                                }}
                                sortDirection={orderBy === 'firstName' ? order : false}
                            >
                                <TableSortLabelHeader
                                    name="firstName"
                                    label="First Name"
                                    handleRequestSort={handleRequestSort}
                                />
                            </TableCell>
                            <TableCell align="center" style={{ zIndex: 1 }} sortDirection={orderBy === 'DOB' ? order : false}>
                                <TableSortLabelHeader
                                    name="DOB"
                                    label="DATE OF BIRTH"
                                    handleRequestSort={handleRequestSort}
                                />
                            </TableCell>
                            <TableCell
                                align="center"
                                style={{ minWidth: "200px", zIndex: 1 }}
                                sortDirection={orderBy === 'type' ? order : false}
                            >
                                <TableSortLabelHeader
                                    name="type"
                                    label={relation || "Type"}
                                    handleRequestSort={handleRequestSort}
                                />
                            </TableCell>
                            <TableCell align="center" style={{ zIndex: 1 }} sortDirection={orderBy === 'dateOfADT' ? order : false}>
                                <TableSortLabelHeader
                                    name="dateOfADT"
                                    label="Date Of Transfer"
                                    handleRequestSort={handleRequestSort}
                                />
                            </TableCell>
                            {questions &&
                                questions.length > 0 &&
                                questions.filter(question => question?.question?.accessor !== 'dateOfADT').map((ele) => (
                                    <TableCell align="center" width={"12%"} style={{ zIndex: 1 }} sortDirection={orderBy === ele?.question?.accessor ? order : false}>
                                        <TableSortLabelHeader
                                            name={ele?.question?.accessor}
                                            label={getTooltipText((ele?.order?.tableLabel || ele?.order?.label || ele?.question?.tableLabel || ele?.question?.label),
                                                ele?.question?.accessor,
                                                false
                                            )}
                                            handleRequestSort={handleRequestSort}
                                        />
                                    </TableCell>
                                ))}

                            {pageType &&
                                pageType === DASHBOARD_FILTER_TYPE.HOSPITAL &&
                                !isOnlyHospitalDashboard && (
                                    <TableCell align="center" width={"12%"} style={{ zIndex: 1 }} sortDirection={orderBy === 'hospitalDays' ? order : false}>
                                        <TableSortLabelHeader
                                            name="hospitalDays"
                                            label="# of days in hospital"
                                            handleRequestSort={handleRequestSort}
                                            sortDirection={orderBy === 'hospitalDays' ? order : false}
                                        />
                                    </TableCell>
                                )}
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ fontFamily: "manrope", fontSize: "12px" }}>
                        {chartData.length > 0 && (
                            <>
                                {sortedData?.map((row, index) => {
                                    return (
                                        <TableRow
                                            key={index}
                                            onClick={() => {
                                                setSelectedResident(row);
                                            }}
                                            sx={{ cursor: "pointer" }}
                                            hover={true}
                                        >
                                            <TableCell
                                                style={{
                                                    position: "sticky",
                                                    left: 0,
                                                    minWidth: "100px",
                                                    zIndex: showAdd ? 1 : 3,
                                                    backgroundColor: "#fff",
                                                }}
                                                component="th"
                                                scope="row"
                                            >
                                                <Stack direction={"row"} spacing={1}>
                                                    {isWriteAccess && (
                                                        <>
                                                            <Stack style={{ display: "flex", alignItems: "center", justifyContent: "center" }} item sx={{
                                                                "div": {
                                                                    marginTop: "6px",
                                                                    width: "18px",
                                                                    height: "20px",
                                                                    display: "flex",
                                                                    marginLeft: "-6px",
                                                                    marginRight: "-5px",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    "svg": {
                                                                        top: "8px",
                                                                        left: "7px",
                                                                        width: "26px",
                                                                        height: "26px",
                                                                    }
                                                                },
                                                            }}>
                                                                <HtmlTooltip
                                                                    content={<ActivityLogTooltip logs={row.logs ?? []} />}
                                                                    placement="right"
                                                                    sx={{
                                                                        "& .MuiTooltip-tooltip": {
                                                                            width: "310px",
                                                                            maxWidth: "310px",
                                                                        },
                                                                    }}
                                                                >
                                                                    <IconButton size="small">
                                                                        <InfoIcon />
                                                                    </IconButton>
                                                                </HtmlTooltip>
                                                            </Stack>
                                                            <Stack item style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <IconButton onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    setEditResident(row);
                                                                    toggleAdd();
                                                                }}>
                                                                    <EditSVG />
                                                                </IconButton>
                                                            </Stack>
                                                        </>
                                                    )}
                                                    <Stack item style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        {row &&
                                                            row?.highlighter &&
                                                            row?.highlighter.length > 0 &&
                                                            row?.highlighter.filter((ele) => !_.includes(["suffix", "middleInitial"], ele?.fieldName))?.map((ele, jIndex) => (
                                                                <Box
                                                                    sx={{ mt: "5px" }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleScrollTo(
                                                                            ele?.fieldName + "_cell_" + index,
                                                                            ele?.color
                                                                        );
                                                                    }}
                                                                >
                                                                    <Tooltip
                                                                        disableHoverListener={!ele?.description}
                                                                        title={ele?.description}
                                                                        key={jIndex + "tooltip"}
                                                                        arrow={true}
                                                                        placement="right"
                                                                    >
                                                                        <div>
                                                                            <Box
                                                                                sx={{
                                                                                    width: 10,
                                                                                    height: 10,
                                                                                    backgroundColor: ele?.color,
                                                                                    borderRadius: "50%",
                                                                                    marginBottom: 0.5,
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Tooltip>
                                                                </Box>
                                                            ))}
                                                    </Stack>
                                                </Stack>
                                            </TableCell>
                                            {activeFacilities?.length > 1 && (
                                                <TableCell
                                                    align="left"
                                                    style={{
                                                        position: "sticky",
                                                        left: "100px",
                                                        minWidth: "120px",
                                                        zIndex: showAdd ? 1 : 3,
                                                        backgroundColor: "#fff",
                                                        borderBottom: `1px solid ${getHighLightColor("facilityName", row)}`,
                                                    }}
                                                    component="th"
                                                    scope="row"
                                                    id={"facilityName_cell_" + index}
                                                >
                                                    {highlightText(`${row?.facilityName}`, "facilityName", handleHighlightedCount, row?.facilityName)}
                                                </TableCell>
                                            )}
                                            <TableCell
                                                align="left"
                                                style={{
                                                    position: "sticky",
                                                    left: activeFacilities?.length > 1 ? "220px" : "100px",
                                                    minWidth: "120px",                                                    
                                                    zIndex: showAdd ? 1 : 3,
                                                    backgroundColor: "#fff",
                                                    borderBottom: `1px solid ${getHighLightColor("lastName", row)}`,
                                                }}
                                                component="th"
                                                scope="row"
                                                id={"lastName_cell_" + index}
                                            >
                                                {highlightText(`${row.lastName}`, "lastName", handleHighlightedCount)}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                style={{
                                                    position: "sticky",
                                                    left: activeFacilities?.length > 1 ? "340px" : "220px",
                                                    minWidth: "120px",                                                    
                                                    zIndex: showAdd ? 1 : 3,
                                                    backgroundColor: "#fff",
                                                    borderBottom: `1px solid ${getHighLightColor("firstName", row)}`,
                                                }}
                                                component="th"
                                                scope="row"
                                                id={"firstName_cell_" + index}
                                            >
                                                {highlightText(`${row.firstName}`, "firstName", handleHighlightedCount)}
                                            </TableCell>

                                            <TableCell
                                                align="center"
                                                style={{ borderBottom: `1px solid ${getHighLightColor("DOB", row)}` }}
                                                id={"DOB_cell_" + index}
                                            >
                                                {highlightText(toDisplayTime(row.DOB, "MM/DD/YYYY"), "DOB", handleHighlightedCount)}
                                            </TableCell>

                                            <TableCell
                                                align="center"
                                                style={{ borderBottom: `1px solid ${getHighLightColor("type", row)}` }}
                                            >
                                                {highlightText(getFieldNames(row), null, handleHighlightedCount)}
                                            </TableCell>

                                            <TableCell
                                                align="center"
                                                style={{ borderBottom: `1px solid ${getHighLightColor("dateOfADT", row)}` }}
                                                id={"dateOfADT_cell_" + index}
                                            >
                                                {highlightText(toDisplayTime(row.dateOfADT, "MM/DD/YYYY"), "dateOfADT", handleHighlightedCount)}
                                            </TableCell>

                                            {questions &&
                                                questions.length > 0 &&
                                                questions.filter(question => question?.question?.accessor !== 'dateOfADT').map((ele) => (
                                                    <TableCell
                                                        align="center"
                                                        style={{
                                                            borderBottom: `1px solid ${getHighLightColor(ele?.question?.accessor, row)}`,
                                                        }}
                                                        id={`${ele?.question?.accessor}_cell_${index}`}
                                                    >
                                                        {getQuestionAnswer(ele?.question, row)}
                                                    </TableCell>
                                                ))}
                                            {pageType &&
                                                pageType === DASHBOARD_FILTER_TYPE.HOSPITAL &&
                                                !isOnlyHospitalDashboard && (
                                                    <TableCell component="th" scope="row" align="center">
                                                        {highlightText(`${row.hospitalDays}`, "hospitalDays", handleHighlightedCount)}
                                                    </TableCell>
                                                )}
                                        </TableRow>
                                    );
                                })}
                                {pageType && pageType === DASHBOARD_FILTER_TYPE.HOSPITAL && (
                                    <>
                                        <TableRow>
                                            <TableCell
                                                colSpan={
                                                    pageType === DASHBOARD_FILTER_TYPE.HOSPITAL ||
                                                        pageType === DASHBOARD_FILTER_TYPE.DECEASED ||
                                                        pageType === DASHBOARD_FILTER_TYPE.OVERALL ||
                                                        pageType === DASHBOARD_FILTER_TYPE.COMMUNITY_TRANSFER
                                                        ? pageType ===
                                                            DASHBOARD_FILTER_TYPE.COMMUNITY_TRANSFER
                                                            ? 18
                                                            : (!isOnlyHospitalDashboard ? 5 : 3) +
                                                            questions.length
                                                        : 15
                                                }
                                            >
                                                &nbsp;
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="button" component={"span"}>
                                                    Total :{" "}
                                                </Typography>
                                                <Typography variant="body2" component={"span"}>
                                                    {_.sumBy(chartData, "hospitalDays")}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                colSpan={
                                                    pageType === DASHBOARD_FILTER_TYPE.HOSPITAL ||
                                                        pageType === DASHBOARD_FILTER_TYPE.DECEASED ||
                                                        pageType === DASHBOARD_FILTER_TYPE.OVERALL ||
                                                        pageType === DASHBOARD_FILTER_TYPE.COMMUNITY_TRANSFER
                                                        ? pageType ===
                                                            DASHBOARD_FILTER_TYPE.COMMUNITY_TRANSFER
                                                            ? 18
                                                            : (!isOnlyHospitalDashboard ? 5 : 3) +
                                                            questions.length
                                                        : 15
                                                }
                                            >
                                                &nbsp;
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="button" component={"span"}>
                                                    Avg. :{" "}
                                                </Typography>
                                                <Typography variant="body2" component={"span"}>
                                                    {_.round(
                                                        _.meanBy(chartData, (p) => p?.hospitalDays) || 0,
                                                        2
                                                    ).toFixed(2)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                )}
                            </>
                        )}
                        {chartData.length === 0 && <NoRecordFound data={chartData} />}
                    </TableBody>
                </Table>
            </TableContainer>
            {showAdd ? (
                <AddPatient
                    initialDate={initialDate}
                    close={toggleAdd}
                    patientAdded={patientAdded}
                    selectedResident={editResident}
                    patientUpdated={patientAdded}
                    patientId={editResident?._id}
                    step={2}
                    type="quickEdit"
                    isMultipleFacility={permission?.isMultipleFacility}
                />
            ) : undefined}
            {selectedResident ? (
                <ResidentDetails
                    details={selectedResident}
                    close={() => setSelectedResident(undefined)}
                    tableColumns={getTableColumnsForDetails()}
                />
            ) : undefined}
        </>
    );
}
