import { Checkbox, List, ListItem, ListItemText, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import { calcProPercentsBasedOnFilterAndDays, itemPercentage, percentageLabel } from "../../../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../../utilis/percentage-comparison";
import NoRecordFound from "../../../shared/NoRecordFound";
import styles from "./HospitalizationsList.module.scss";
import HospitalizationsListSkeleton from "./HospitalizationsListSkeleton";
import HospitalizationsProgressBar from "./HospitalizationsProgressBar";

export const CustomeList = styled(List)(({ theme }) => ({
	color: theme.palette.typography.main,
	padding: 0,
}));

export const CustomeListItem = styled(ListItem)(({ theme }) => ({
	color: theme.palette.typography.main,
	border: "1px solid #E8E8EB",
	margin: "0px 0px 12px 0px",
	borderRadius: 8,
	"& .MuiListItemSecondaryAction-root": {
		top: "30%",
	},
	"&.Mui-selected, .Mui-focusVisible": {
		backgroundColor: "#fff",
		border: "1px solid #4879F5",
		borderRadius: "10px",
		"&:before": {
			content: `""`,
			display: "block",
			height: "16px",
			width: "16px",
			backgroundColor: "#4879f5",
			position: "absolute",
			left: "-4.5%",
			transform: "translateX(-100%)",
			borderRadius: "50%",
			transition: "all 0.2s",
		},
	},
}));

const HospitalizationsList = ({
	data,
	dataComparison,
	averageCensus,
	averageCensusComparison,
	handleToggle,
	selectedItem = [],
	filter,
	filterComparison,
	cardTitle,
	loading,
	projectionDays,
	isComparingAgainstAvgCensus,
	transferType,
	priorityNumber,
	lockedTotalBy,
}) => {
	const handleToggleCheckBox = (value, comparisonValue, isChecked) => {
		handleToggle({ item: value, type: "hospitalizations", itemComparison: comparisonValue, isChecked, cardTitle });
	};
	const averageCensusNumber = Number(averageCensus);
	const averageCensusComparisonNumber = Number(averageCensusComparison);
	const isFirstItemInPriorityData = priorityNumber === 1;

	return (
		<>
			{loading ? (
				<HospitalizationsListSkeleton />
			) : (
				data &&
				data.length > 0 && (
					<div className={`${styles.hospitalizationsList} `}>
						<CustomeList dense>
							{data.map((item, i) => {
								const ID = item._id;
								const labelId = `checkbox-list-secondary-label-${ID}`;
								const itemComparison = _.find(dataComparison, { _id: ID });
								const percentage = itemPercentage(item.total, averageCensusNumber, "number");
								const percentageComparison = itemPercentage(
									itemComparison?.total || 0,
									averageCensusComparisonNumber,
									"number"
								);

								const valueToShow = calcProPercentsBasedOnFilterAndDays(item.total, filter, projectionDays);
								const percentageToShow = calcProPercentsBasedOnFilterAndDays(
									item.percentage,
									filter,
									projectionDays,
									true,
									isComparingAgainstAvgCensus ||
										lockedTotalBy === "census" ||
										(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
								);

								const { percentageDiff, itemTotalComparisonScaled, numberOfDays } = calculateDiffBetweenPercentages(
									percentage,
									percentageComparison,
									false,
									filter,
									filterComparison,
									itemComparison?.total,
									projectionDays
								);
								const comparisonColor = pickComparisonColor(percentageDiff);

								return (
									<CustomeListItem
										key={ID}
										selected={selectedItem.indexOf(ID) !== -1}
										secondaryAction={
											<>
												<Checkbox
													edge="end"
													onChange={() => handleToggleCheckBox(item, itemComparison, selectedItem.indexOf(ID) !== -1)}
													checked={selectedItem.indexOf(ID) !== -1}
													inputProps={{ "aria-labelledby": labelId }}
												/>
											</>
										}
									>
										<ListItemText
											//onClick={handleToggleCheckBox(item)}
											sx={{ cursor: "pointer" }}
											id={labelId}
											key={labelId}
											primary={<PrimaryText name={item.label} />}
											secondary={
												<HospitalizationsProgressBar
													btnBgColor={item.color}
													item={item}
													progressColor={item.color}
													progressHeight={percentageToShow}
													total={valueToShow}
													percentage={percentageLabel(percentageToShow)}
													className={styles.progressContainer}
													{...Object.assign({}, item, {
														total: valueToShow,
														percentage: percentageLabel(percentageToShow),
													})}
													comparisonColor={comparisonColor}
													numberOfDays={numberOfDays}
													comparingAgainstScaled={itemTotalComparisonScaled}
													comparingAgainst={itemComparison?.total}
													loading={loading}
												/>
											}
										/>
									</CustomeListItem>
								);
							})}
						</CustomeList>
					</div>
				)
			)}
			{!loading && <NoRecordFound data={data} />}
		</>
	);
};

export default HospitalizationsList;

export const PrimaryText = ({ name }) => {
	return (
		<Typography
			sx={{
				mb: 1,
				fontFamily: "manrope-med",
				fontWeight: 500,
				fontSize: 12,
				color: "#636578",
				lineHeight: "16px",
			}}
		>
			{name}
		</Typography>
	);
};
