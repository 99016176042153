import {
    saveCustomeCardFilterData,
    getCustomeCardFiltersData,
} from "./api/custom-card-filter.api";


const getCustomeCardFilters = async (type) => {
    try {
        const res = await getCustomeCardFiltersData(type);
        return res;
    } catch (e) {
        console.log(e);
    }
};

const saveCustomeCardFilter = async (body) => {
    try {
        const res = await saveCustomeCardFilterData(body);
        return res;
    } catch (e) {
        console.log(e.message);
    }
};


export {
    getCustomeCardFilters,
    saveCustomeCardFilter
};
