const DoctorIcon = (props) => {
    return (
        <>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_110_3028)">
                    <path d="M18 35.9998C27.9411 35.9998 35.9999 27.941 35.9999 17.9999C35.9999 8.05883 27.9411 0 18 0C8.05895 0 0.00012207 8.05883 0.00012207 17.9999C0.00012207 27.941 8.05895 35.9998 18 35.9998Z" fill="#92C7FF" />
                    <path d="M15.4911 17.538C17.1635 17.538 18.8359 17.538 20.5081 17.538L20.5355 17.8809L18.0837 21.5978L15.45 17.8442L15.491 17.5382L15.4911 17.538Z" fill="#B3B3B3" />
                    <path d="M15.5235 16.6057L15.4801 18.7023L11.923 20.6475V27.7775H17.9111H23.8984V20.6474L20.5926 18.5926L20.411 16.4963L15.5236 16.6056H15.5235V16.6057Z" fill="#F0BA7D" />
                    <path d="M13.8798 10.4915C13.8798 10.4915 13.6759 10.0434 13.1283 10.2655C12.5805 10.4873 13.1529 12.7019 13.3622 13.0108C13.5713 13.3196 13.908 13.1235 14.0307 13.1113C14.205 14.4315 14.2206 15.3414 14.749 15.8981C15.2496 16.4251 16.3955 17.4993 16.9993 17.8739C17.4081 17.9603 17.7131 18.011 18.0001 18.0262C18.2869 18.011 18.5922 17.9601 19.0009 17.8739C19.6045 17.4995 20.7507 16.4251 21.2511 15.8981C21.7795 15.3414 21.7951 14.4317 21.9695 13.1113C22.0921 13.1236 22.4289 13.3196 22.638 13.0108C22.8472 12.7019 23.4196 10.4872 22.8719 10.2655C22.3242 10.0433 22.1203 10.4915 22.1203 10.4915C22.1203 10.4915 22.6617 8.75249 21.6994 7.04073C21.04 5.86749 19.5361 5.259 18.0002 5.21436C16.4642 5.259 14.9603 5.86749 14.3009 7.04073C13.3386 8.75256 13.88 10.4915 13.88 10.4915H13.8798Z" fill="#FED298" />
                    <path d="M13.7356 19.3191L14.5875 18.9835L15.2739 18.3667L17.6978 20.205H18.3014L20.726 18.3645L22.264 19.3193L24.9366 23.8622L22.82 35.3458C21.2857 35.7714 19.6694 35.9998 17.9995 35.9998C16.6376 35.9998 15.3115 35.8475 14.0361 35.561L10.2424 24.2251L13.7354 19.3191L13.7356 19.3191Z" fill="#15BDB2" />
                    <path d="M21.4867 18.7447L20.5081 17.538L18.3016 20.2051L20.1956 22.3095L21.4867 18.7447Z" fill="#21D0C3" />
                    <path d="M14.5114 18.7234L15.4912 17.538L17.6978 20.2051L15.7286 22.2879L14.5114 18.7234Z" fill="#21D0C3" />
                    <path d="M14.5115 18.7235L8.0256 20.8327C7.76186 20.9183 7.5962 21.1425 7.54248 21.4148L5.63525 31.0798C6.91965 32.2945 8.38307 33.3213 9.98149 34.118L10.9297 31.0237C10.9407 30.9881 10.9744 30.966 11.0114 30.9704C11.0481 30.9748 11.0761 31.0042 11.0782 31.0414L11.2151 34.6758C13.309 35.529 15.5994 35.9998 18.0002 35.9998V34.7931L14.5117 18.7236L14.5115 18.7235Z" fill="white" />
                    <path d="M14.5116 18.7235L13.7687 18.9517L11.8007 22.3098L14.2852 22.3876L12.7599 23.2561L18.0003 34.7933L14.5118 18.7237L14.5116 18.7235Z" fill="#EEF3F7" />
                    <path d="M21.4867 18.7448L27.9746 20.8327C28.2387 20.9177 28.4038 21.1426 28.4575 21.4148L30.3648 31.08C29.0803 32.2946 27.6168 33.3215 26.0183 34.1183L25.07 31.0238C25.0591 30.9883 25.025 30.9661 24.9883 30.9706C24.9515 30.9749 24.9238 31.0045 24.9216 31.0416L24.7851 34.6759C22.6911 35.5292 20.4008 36 18.0001 36H18V34.7933L21.4866 18.745L21.4867 18.7448Z" fill="#EEF3F7" />
                    <path d="M21.4867 18.7448L21.899 18.8702L24.1978 22.331L21.7133 22.409L23.2387 23.2775L18 34.7933L21.4866 18.745V18.7448H21.4867V18.7448Z" fill="white" />
                    <path d="M18.7352 34.7177C18.5164 34.7177 18.3391 34.8946 18.3391 35.1125C18.3391 35.3308 18.5166 35.5077 18.7352 35.5077C18.9538 35.5077 19.1313 35.3308 19.1313 35.1125C19.1313 34.8944 18.9538 34.7177 18.7352 34.7177Z" fill="#D9DADA" />
                    <path d="M21.6476 11.0093L22.0293 10.996L22.1521 10.5604L22.5116 10.2017C22.7491 8.4138 22.819 5.63118 21.0444 5.71148C21.0444 5.71148 20.8844 3.68254 17.6834 4.08022C14.4822 4.47805 13.0359 6.54454 13.2082 8.26861C13.3804 9.99267 13.311 10.2015 13.311 10.2015L13.6705 10.5604L13.7247 11.0558L14.0387 11.036C14.0387 11.036 14.1371 10.3729 14.2356 10.0812C14.3342 9.78961 14.3033 9.19927 14.3033 9.19927C14.3033 9.19927 18.0843 9.44016 19.1061 8.14043C19.7371 7.33781 21.1864 5.9471 21.2594 7.8731C21.2811 8.44425 21.0687 9.3003 21.4751 9.92335C21.8815 10.5465 21.6475 11.0092 21.6475 11.0092H21.6476V11.0093Z" fill="#666666" />
                    <path d="M18.0001 34.7931L19.0221 30.0889L18.3276 20.8161L18.706 20.6546L18.3015 20.2052H17.6979L17.283 20.6441L17.6724 20.8161L16.9952 30.164L18.0001 34.7932L18.0001 34.7931Z" fill="#0F7986" />
                    <path d="M18.6999 34.7177C18.5007 34.7177 18.339 34.8787 18.339 35.0773C18.339 35.2761 18.5006 35.4373 18.6999 35.4373C18.899 35.4373 19.0608 35.2762 19.0608 35.0773C19.0608 34.8787 18.899 34.7177 18.6999 34.7177Z" fill="white" />
                    <path d="M24.3559 28.7626C23.6535 28.6413 23.1824 27.9734 23.3035 27.2712C23.4248 26.5689 24.0927 26.0975 24.7949 26.2187C25.4973 26.34 25.9684 27.008 25.8472 27.7104C25.7259 28.4126 25.0581 28.8839 24.3558 28.7626L24.3559 28.7626Z" fill="#FEFEFE" />
                    <path d="M24.3687 28.6884C23.7072 28.5742 23.2635 27.9456 23.3778 27.2839C23.4921 26.6223 24.1209 26.1786 24.7825 26.2927C25.444 26.4069 25.8877 27.0359 25.7734 27.6974C25.6592 28.3591 25.0304 28.8026 24.3687 28.6883V28.6884Z" fill="#0F7986" />
                    <path d="M24.4611 28.1536C24.0952 28.0905 23.8496 27.7422 23.9127 27.3762C23.9758 27.0102 24.324 26.7648 24.6899 26.8279C25.0559 26.8911 25.3015 27.2389 25.2382 27.605C25.1752 27.9709 24.8271 28.2165 24.4611 28.1534V28.1536Z" fill="#EBECEC" />
                    <path d="M24.5402 26.9L24.8357 26.9511L24.9946 26.0307L24.6991 25.9796L24.5402 26.9Z" fill="#FEFEFE" />
                    <path d="M14.6419 29.0179L15.1957 28.908C15.4311 28.8611 15.6259 28.7227 15.7503 28.5368C15.8743 28.3509 15.9282 28.1179 15.8814 27.8825L15.2301 24.6012C14.9842 23.363 14.0156 22.7316 12.9087 22.5916C12.4846 22.538 12.04 22.5575 11.6092 22.6428C11.178 22.7281 10.7601 22.8797 10.3887 23.0911C9.42166 23.642 8.76993 24.5943 9.01609 25.8345L9.6674 29.1159C9.71423 29.3512 9.85296 29.5459 10.0387 29.6703L10.0392 29.6701C10.2251 29.7945 10.4578 29.8481 10.6931 29.8014L11.2466 29.6915L11.2013 29.4633L10.6477 29.5732C10.4752 29.6074 10.3042 29.5679 10.168 29.4771L10.1682 29.4769C10.0319 29.3857 9.93009 29.2429 9.89578 29.0705L9.24447 25.789C9.02109 24.6637 9.61825 23.7963 10.5031 23.2927C10.8529 23.0933 11.2473 22.9504 11.6544 22.8696C12.0615 22.7889 12.4807 22.7707 12.8802 22.8212C13.8933 22.9492 14.7793 23.523 15.0021 24.6462L15.6535 27.9276C15.6876 28.1 15.6481 28.2708 15.5571 28.4073C15.4659 28.5434 15.3231 28.6452 15.1504 28.6793L14.5967 28.7892L14.6419 29.0174V29.0178V29.0179Z" fill="#21D0C3" />
                    <path d="M15.4058 25.0875L15.3063 24.5863C15.053 23.3097 14.0563 22.659 12.9184 22.515C12.4861 22.4604 12.0332 22.4799 11.5944 22.5671C11.1555 22.6539 10.7294 22.8086 10.3511 23.0239C9.35644 23.5903 8.6865 24.5709 8.94033 25.8497L9.03996 26.3509L9.42007 26.2753L9.32058 25.7741C9.10493 24.6872 9.68396 23.848 10.5413 23.3598C10.884 23.1648 11.2704 23.0248 11.6696 22.9456C12.0686 22.8664 12.4796 22.8486 12.871 22.898C13.8527 23.0221 14.7109 23.5765 14.9264 24.6616L15.0259 25.1628L15.4061 25.0873L15.4058 25.0875Z" fill="#4D4D4D" />
                    <path d="M10.8073 29.8291L11.1036 29.7702C11.178 29.8537 11.2931 29.8965 11.4104 29.8733C11.5866 29.8383 11.7012 29.6671 11.6662 29.4907C11.6313 29.3146 11.46 29.2 11.2837 29.2351C11.1662 29.2583 11.0763 29.342 11.0396 29.4475L10.7435 29.5062L10.8075 29.8289L10.8073 29.8291Z" fill="#076673" />
                    <path d="M14.8772 29.0213L14.5813 29.0801C14.5445 29.1855 14.4546 29.2691 14.3373 29.2925C14.161 29.3274 13.9899 29.213 13.9549 29.0366C13.92 28.8605 14.0344 28.6892 14.2105 28.6543C14.3279 28.6311 14.4431 28.6741 14.5172 28.7574L14.8132 28.6985L14.8772 29.0213Z" fill="#076673" />
                    <path d="M11.7744 22.5375L11.6614 21.9683L11.4368 22.0129L11.2124 22.0574L11.3267 22.6323C11.4122 22.6073 11.4989 22.5861 11.5863 22.5687C11.6487 22.5563 11.7113 22.5461 11.7744 22.5375Z" fill="#666666" />
                    <path d="M11.2898 29.5122C11.3431 29.5017 11.3778 29.4497 11.3672 29.3966C11.3566 29.3432 11.305 29.3085 11.2515 29.319C11.1981 29.3296 11.1635 29.3815 11.1742 29.4347C11.1848 29.4879 11.2365 29.5228 11.2898 29.512V29.5122Z" fill="#0F7986" />
                    <path d="M14.2185 28.9298C14.2718 28.9193 14.3063 28.8674 14.2958 28.8143C14.2852 28.7608 14.2336 28.7262 14.1801 28.7368C14.1267 28.7473 14.0921 28.7992 14.1027 28.8526C14.1132 28.9059 14.165 28.9405 14.2185 28.9298Z" fill="#0F7986" />
                    <path d="M24.4252 28.1474C24.079 28.0877 23.8469 27.7586 23.9066 27.4122C23.9663 27.0662 24.2952 26.834 24.6414 26.8938C24.9875 26.9535 25.2198 27.2826 25.16 27.6286C25.1002 27.9748 24.7711 28.207 24.425 28.1474H24.4252Z" fill="#FEFEFE" />
                    <path d="M14.5147 18.8942C14.5047 18.8943 11.0089 18.9233 11.5994 21.9817L11.2742 22.0439C10.6086 18.5959 14.4998 18.5641 14.5108 18.564L14.5147 18.8942Z" fill="#4D4D4D" />
                    <path d="M21.4906 18.5796C22.0609 18.595 23.1634 18.7504 24.0121 19.7827C24.8426 20.7929 25.4248 22.6481 24.9895 26.0495L24.6617 26.0081C25.0831 22.7159 24.5386 20.9434 23.7572 19.9926C22.9936 19.0637 21.9981 18.9237 21.483 18.9098L21.4908 18.5796H21.4906Z" fill="#4D4D4D" />
                </g>
                <defs>
                    <clipPath id="clip0_110_3028">
                        <rect width="36" height="36" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </>
    );
}

export default DoctorIcon;
