import { useCallback, useEffect, useState } from "react";
import CloseIcon from "../../../assets/svgs/close.svg";
import styles from "../edit-census-dialog/EditCensusDialog.module.scss";
import { useDispatch } from "react-redux";
import Cleave from "cleave.js/react";
import _ from "lodash";
import { updateBedCapacity } from "../../../services/data-input.service";
import { errorMessage } from "../../../services/helpers/toastMessages";
import { ADD_NOTIFICATION } from "../../../store/types";
import { LoadingButton } from "@mui/lab";


const EditBedCapacityDialog = (props) => {
    //const dispatch = useDispatch();    
    const defaultErr = { date: null, count: null };
    const [errors, setErrors] = useState(defaultErr);
    const [bedCapacityData, setBedCapacityData] = useState({ bedCapacity: props?.bedCapacity });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setBedCapacityData((prevState) => ({
            ...prevState,
            bedCapacity: props?.bedCapacity,
        }));
    }, [props?.bedCapacity]);

    const save = async () => {
        let errorData = {};
        if (!bedCapacityData.bedCapacity) {
            errorData.bedCapacity = "Bed Capacity is required";
        }
        if (!_.isEmpty(errorData)) {
            setErrors(errorData);
        } else {
            setErrors(defaultErr);
            const facilityId = localStorage.getItem("facilityId")
            if (facilityId) {
                setLoading(true);
                await updateBedCapacity({ bedCapacity: Number(bedCapacityData.bedCapacity), facilityId })
                props?.setDefaultBedCapacity(bedCapacityData.bedCapacity)
                setLoading(false);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        label: "Bed capacity information updated successfully!",
                        type: "success",
                        id: 'bedUpdateSuccess'
                    },
                });
                props.close();
            } else {
                errorMessage("Please select facility.")
            }
        }
    };

    const handleOnChange = useCallback(
        (field, value) => {
            setBedCapacityData((state) => ({
                ...state,
                [field]: value,
            }));
            setErrors((state) => ({
                ...state,
                [field]: null,
            }));
        },
        [setBedCapacityData]
    );

    const handleClose = useCallback(() => {
        props.close();
    }, [props]);

    return (
        <>
            <div className={styles.overlay}></div>
            <div className={styles.addPopup}>
                <div className={`df aic ${styles.hdr}`}>
                    <p className={`ffmm fs18`}>Update bed capacity information</p>
                    <div className={`mla`}>
                        <div className={`df acc ${styles.closeBtn}`} onClick={handleClose}>
                            <CloseIcon />
                        </div>
                    </div>
                </div>{" "}
                <div className={styles.popupCntnt}>
                    <div className={`m-b-20 inputWrpr`}>
                        <label className={`ffmr fs12`}>Bed capacity</label>
                        <Cleave
                            style={{ fontSize: "16px", fontFamily: "mont light" }}
                            options={{ numericOnly: true }}
                            value={bedCapacityData.bedCapacity}
                            onChange={(e) => handleOnChange("bedCapacity", e.target.value)}
                        />
                        {errors.bedCapacity && (
                            <div className="error fs12 mt-1">{errors.bedCapacity}</div>
                        )}
                    </div>
                </div>
                <div className={`${styles.popupFtr}`}>
                    <div className={`df aic ${styles.actionsWrrp}`}>
                        <div className={`mla`}>
                            <button
                                className={`ffmr fs14 m-r-8 ${styles.text}`}
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <LoadingButton
                                variant="contained"
                                onClick={save}
                                loading={loading}
                            >
                                Update
                            </LoadingButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditBedCapacityDialog;
