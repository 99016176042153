const CollapseSVG = (props) => (
  <svg
    width={props.width || "64"}
    height={props.height || "45"}
    viewBox="0 0 64 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.022855 20.2836V25.6657C0.022855 26.2613 0.530178 26.7686 1.12574 26.7686H12.0958H13.6252L12.5223 25.6657V32.2021V33.1433C12.5223 33.9006 13.4928 34.6211 14.1839 34.0991L18.6837 30.6581C21.0733 28.8347 23.4629 27.0038 25.8525 25.1804C26.3966 24.7613 26.948 24.3422 27.4921 23.9305C28.1906 23.3937 28.1906 22.5629 27.4921 22.0261L22.9922 18.5851C20.6027 16.7617 18.213 14.9308 15.8235 13.1074C15.2794 12.6883 14.728 12.2692 14.1839 11.8575C13.4928 11.3281 12.5223 12.0487 12.5223 12.8133V19.3498V20.2909C12.8899 19.9233 13.2576 19.5556 13.6252 19.188H2.65508H1.12574C0.544886 19.1807 -0.00656211 19.688 0.022855 20.2835C0.0522721 20.8791 0.508129 21.3864 1.12574 21.3864H12.0958H13.6252C14.2207 21.3864 14.7281 20.8791 14.7281 20.2835V13.7471V12.806C14.1766 13.1222 13.6252 13.4383 13.0664 13.7618L17.5662 17.2029C19.9558 19.0262 22.3454 20.8572 24.735 22.6805C25.2791 23.0996 25.8305 23.5187 26.3746 23.9305V22.0262L21.8747 25.4672C19.4852 27.2906 17.0955 29.1215 14.706 30.9449C14.1619 31.364 13.6105 31.7831 13.0664 32.1948C13.6179 32.511 14.1693 32.8271 14.7281 33.1506V26.6142V25.6731C14.7281 25.0775 14.2207 24.5702 13.6252 24.5702H2.65508H1.12574C1.49337 24.9378 1.86101 25.3055 2.22863 25.6731V20.291C2.22863 19.7175 1.7213 19.1587 1.12574 19.1881C0.522824 19.2028 0.022855 19.6661 0.022855 20.2837V20.2836Z"
      fill={props.fill || "black"}
    />
    <path
      d="M25.8299 2.07946V6.25567V16.2111V28.3208V38.7247C25.8299 40.4158 25.7858 42.1069 25.8299 43.7979V43.8715C25.8299 44.445 26.3372 45.0038 26.9328 44.9744C27.5284 44.9449 28.0357 44.4891 28.0357 43.8715V39.6953V29.7398V17.6302V7.22623C28.0357 5.53515 28.0798 3.84407 28.0357 2.15299V2.07946C28.0357 1.50596 27.5284 0.947159 26.9328 0.976576C26.3372 1.00599 25.8299 1.46185 25.8299 2.07946V2.07946Z"
      fill={props.fill || "black"}
    />
    <path
      d="M61.1965 20.2836V25.6657L62.2994 24.5628H51.3293H49.7999C49.2044 24.5628 48.6971 25.0701 48.6971 25.6657V32.2021V33.1432C49.2485 32.8271 49.7999 32.5109 50.3587 32.1874L45.8589 28.7463C43.4693 26.923 41.0797 25.0921 38.6901 23.2687C38.146 22.8496 37.5946 22.4305 37.0506 22.0188V23.9231L41.5504 20.482C43.9399 18.6586 46.3296 16.8277 48.7191 15.0044C49.2632 14.5853 49.8146 14.1662 50.3587 13.7544C49.8072 13.4383 49.2558 13.1221 48.6971 12.7986V19.335V20.2761C48.6971 20.8717 49.2044 21.379 49.7999 21.379H60.77H62.2994C62.8729 21.379 63.4317 20.8717 63.4023 20.2761C63.3729 19.6806 62.917 19.1733 62.2994 19.1733H51.3293H49.7999C50.1676 19.5409 50.5352 19.9085 50.9028 20.2761V13.7397V12.7986C50.9028 12.0413 49.9323 11.3207 49.2412 11.8427L44.7414 15.2838C42.3518 17.1072 39.9622 18.9381 37.5726 20.7614C37.0285 21.1805 36.4771 21.5996 35.9331 22.0114C35.2346 22.5481 35.2346 23.379 35.9331 23.9157L40.4329 27.3567C42.8224 29.1801 45.2121 31.011 47.6016 32.8344C48.1457 33.2535 48.6971 33.6726 49.2412 34.0843C49.9323 34.6137 50.9028 33.8932 50.9028 33.1285V26.5921V25.6509L49.7999 26.7538H60.77H62.2994C62.8949 26.7538 63.4023 26.2465 63.4023 25.6509V20.2689C63.4023 19.6954 62.8949 19.1366 62.2994 19.166C61.7038 19.2028 61.1965 19.666 61.1965 20.2836V20.2836Z"
      fill={props.fill || "black"}
    />
    <path
      d="M35.388 2.07946V6.25567V16.2111V28.3208V38.7247C35.388 40.4158 35.3439 42.1069 35.388 43.7979V43.8715C35.388 44.445 35.8953 45.0038 36.4909 44.9744C37.0865 44.9449 37.5938 44.4891 37.5938 43.8715V39.6953V29.7398V17.6302V7.22623C37.5938 5.53515 37.6379 3.84407 37.5938 2.15299V2.07946C37.5938 1.50596 37.0865 0.947159 36.4909 0.976576C35.888 1.00599 35.388 1.46185 35.388 2.07946V2.07946Z"
      fill={props.fill || "black"}
    />
  </svg>
);

export default CollapseSVG;
