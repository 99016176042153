/* eslint-disable no-new-object */
import { Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import classNames from "classnames";
import _, { sumBy } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { calcProPercentsBasedOnFilterAndDays, itemPercentage, percentageLabel } from "../../../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../../utilis/percentage-comparison";
import CardItemTooltip from "../../../shared/CardItemTooltip";
import ColorBox from "../../../shared/dashboard/ColorBox";
import NoRecordFound from "../../../shared/NoRecordFound";
import CheckboxButton from "../../shared/checkboxButton/CheckboxButton";
import AsstLivingRedialChart from "./AsstLivingRedialChart";
import styles from "./SafeDischargeAssLivingSlider.module.scss";
import SafeDischargeAssLivingSliderSkeleton from "./SafeDischargeAssLivingSliderSkeleton";

const SafeDischargeAssLivingSlider = ({
	data,
	dataComparison,
	handleToggle,
	handleToggleAll,
	selectedItem = [],
	type,
	totalSafeDischarge,
	totalSafeDischargeComparison,
	page,
	filter,
	filterComparison,
	averageCensus,
	averageCensusComparison,
	cardTitle,
	loading,
	projectionDays,
	isComparingAgainstAvgCensus,
	transferType,
	priorityNumber,
	lockedTotalBy,
	searchValue,
	selectAll,
}) => {
	const sliderRef = useRef();
	const [slidData, setSlidData] = useState([]);
	const [chartData, setChartData] = useState([]);
	const [totalAssistance, setTotalAssistance] = useState(0);
	const [totalAssistanceComparison, setTotalAssistanceComparison] = useState(0);
	const totalSafeDischargePercent = itemPercentage(totalSafeDischarge, averageCensus, "number");
	const totalAssistedLivingPercent = itemPercentage(totalAssistance, averageCensus, "number");
	const totalSafeDischargeComparisonPercent = itemPercentage(
		totalSafeDischargeComparison,
		averageCensusComparison,
		"number"
	);
	const totalAssistedLivingComparisonPercent = itemPercentage(
		totalAssistanceComparison,
		averageCensusComparison,
		"number"
	);
	const {
		percentageDiff: percentageDiffSafe,
		itemTotalComparisonScaled: itemTotalComparisonScaledSafe,
		numberOfDays: numberOfDaysSafe,
	} = calculateDiffBetweenPercentages(
		totalSafeDischargePercent,
		totalSafeDischargeComparisonPercent,
		false,
		filter,
		filterComparison,
		totalSafeDischargeComparison,
		projectionDays
	);
	const safeDischargeComparisonColor = pickComparisonColor(percentageDiffSafe);
	const isFirstItemInPriorityData = priorityNumber === 1;

	const {
		percentageDiff: percentageDiffAssisted,
		itemTotalComparisonScaled: itemTotalComparisonScaledAssisted,
		numberOfDays: numberOfDaysAssisted,
	} = calculateDiffBetweenPercentages(
		totalAssistedLivingPercent,
		totalAssistedLivingComparisonPercent,
		false,
		filter,
		filterComparison,
		totalAssistanceComparison,
		projectionDays
	);
	const assistedLivingComparisonColor = pickComparisonColor(percentageDiffAssisted);

	useEffect(() => {
		let chartArrData = [];
		let objTotal = new Object();
		objTotal.id = "Total";
		objTotal.data = [
			{
				x: "Total",
				y: totalSafeDischarge || 0,
				color: "#15BDB2",
			},
		];
		chartArrData.push(objTotal);
		if (dataComparison && dataComparison.length > 0) {
			const totalAss = sumBy(dataComparison, "total");
			setTotalAssistanceComparison(totalAss);
		}
		if (data && data.length > 0) {
			let totalAss = sumBy(data, "total");
			setTotalAssistance(totalAss);
			setSlidData(data);
			let obj = new Object();
			obj.id = "Assisted Living";
			obj.data = [{ x: "Assisted Living", y: totalAss, color: "#076673" }];
			chartArrData.push(obj);
		} else {
			setSlidData([]);
			setTotalAssistance(0);
		}
		setChartData(chartArrData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, totalSafeDischarge]);

	const settings = {
		infinite: false,
		rows: 1,
	};

	const getChartData = useCallback(() => {
		let dataArray = data.map((item) => {
			return Object.assign({}, item, { id: item.name });
		});
		return dataArray;
	}, [data]);

	return loading ? (
		<SafeDischargeAssLivingSliderSkeleton />
	) : (
		<Grid container alignItems="center" justifyContent={"center"} className={`${styles.safeDischargeAssLivingSlider}`}>
			<Grid item xs={12} className={styles.totalCardContainer}>
				<Card className={styles.totalCard}>
					<CardContent className={styles.totalCardContent}>
						<div className={classNames("df fs15 fw500", styles.checkboxButtonContainer)}>
							<CheckboxButton
								className={styles.checkboxButton}
								handleToggle={() =>
									handleToggle({
										item: { _id: "all", id: "all" },
										type,
										slidData,
										isChecked: _.includes(selectedItem, "all"),
										cardTitle,
									})
								}
								checked={_.includes(selectedItem, "all")}
							/>
						</div>
						<Grid container direction={"row"} className={styles.totalCardContentGrid}>
							<Grid item className={styles.totalCardLTitle} xs={4}>
								<Typography variant="subtitle2" sx={{ fontSize: 14 }}>
									Total number of assisted living transfers
								</Typography>
							</Grid>
							<Grid item className={styles.totalCardLabelNumber} xs={5}>
								<div>
									<div className={classNames("df aic", styles.textWrprReturn)}>
										<div className={styles.labelCircle} style={{ backgroundColor: "#15BDB2" }} />
										<p className="ffmar-bold fs12 fw700 df aic">
											{!loading && (
												<ColorBox
													type="list"
													sx={{ height: "15px", mr: 0.5, ml: 0.5 }}
													color={safeDischargeComparisonColor}
													comparingAgainst={totalSafeDischargeComparison}
													comparingAgainstScaled={itemTotalComparisonScaledSafe}
													numberOfDays={numberOfDaysSafe}
												/>
											)}
											Total: {calcProPercentsBasedOnFilterAndDays(totalSafeDischarge, filter, projectionDays)}{" "}
											{percentageLabel(
												calcProPercentsBasedOnFilterAndDays(
													totalSafeDischargePercent,
													filter,
													projectionDays,
													true,
													isComparingAgainstAvgCensus ||
														lockedTotalBy === "census" ||
														(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
												)
											)}
										</p>
									</div>
									<div className={classNames("df aic", styles.textWrprReturn)}>
										<div className={styles.labelCircle} style={{ backgroundColor: "#076673" }} />
										<p className={`ffmar-bold fs12 fw700 df aic`}>
											{!loading && (
												<ColorBox
													type="list"
													sx={{ height: "15px", mr: 0.5, ml: 0.5 }}
													color={assistedLivingComparisonColor}
													comparingAgainst={totalAssistanceComparison}
													comparingAgainstScaled={itemTotalComparisonScaledAssisted}
													numberOfDays={numberOfDaysAssisted}
												/>
											)}
											Assisted Living: {calcProPercentsBasedOnFilterAndDays(totalAssistance, filter, projectionDays)}{" "}
											{percentageLabel(
												calcProPercentsBasedOnFilterAndDays(
													totalAssistedLivingPercent,
													filter,
													projectionDays,
													true,
													isComparingAgainstAvgCensus ||
														lockedTotalBy === "census" ||
														(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
												)
											)}
										</p>
									</div>
								</div>
							</Grid>
							<Grid item className={styles.totalCardChart} xs={3}>
								<AsstLivingRedialChart data={chartData} />
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
			<Divider sx={{ width: "90% !important" }} flexItem orientation="horizontal"></Divider>
			<Grid item xs={12} alignItems="center" container className={styles.sliderCardContainer}>
				<Grid item xs={10}>
					<div className={"assLiving-slider"}>
						{slidData.length > 0 && (
							<Slider ref={sliderRef} {...settings}>
								{slidData
									.filter((x) => x.label.toLowerCase().includes(searchValue.toLowerCase()))
									.map((ele, index) => {
										const ID = ele._id;
										const labelId = `checkbox-list-floor-label-${ID}`;
										const selected = selectedItem.indexOf(ID) !== -1;
										const eleComparison = _.find(dataComparison, { _id: ele._id });
										const percentage = itemPercentage(ele.total, averageCensus, "number");
										const percentageComparison = itemPercentage(
											eleComparison?.total,
											averageCensusComparison,
											"number"
										);
										const { percentageDiff, itemTotalComparisonScaled, numberOfDays } = calculateDiffBetweenPercentages(
											percentage,
											percentageComparison,
											false,
											filter,
											filterComparison,
											eleComparison?.total,
											projectionDays
										);
										const comparisonColor = pickComparisonColor(percentageDiff);

										return (
											<Card key={`card-${index}`} className={classNames(styles.slideCard, selected && styles.selected)}>
												<CardItemTooltip item={ele}>
													<CardContent className={styles.slideCardContent}>
														<Grid container direction={"row"} key={"row" + index}>
															<Grid item xs={12}>
																<Typography sx={{ mb: 0.5 }} variant="subtitle1">
																	{ele.name}
																</Typography>
																<Typography textAlign={"start"} className={`ffmar-bold fs12 fw700`}>
																	{!loading && (
																		<ColorBox
																			sx={{ height: "15px", mr: 0.5, ml: 0.5 }}
																			color={comparisonColor}
																			comparingAgainst={eleComparison?.total}
																			comparingAgainstScaled={itemTotalComparisonScaled}
																			numberOfDays={numberOfDays}
																		/>
																	)}
																	&nbsp;{calcProPercentsBasedOnFilterAndDays(ele.value, filter, projectionDays)}{" "}
																	{percentageLabel(itemPercentage(ele.value, totalAssistance, "number"))}
																</Typography>
															</Grid>
														</Grid>
														<div className={classNames("df fs15 fw500", styles.checkboxSliderButtonContainer)}>
															<CheckboxButton
																className={styles.checkboxButton}
																labelId={labelId}
																handleToggle={() =>
																	handleToggle({
																		item: ele,
																		type,
																		itemComparison: eleComparison,
																		isChecked: selectedItem.indexOf(ID) !== -1,
																		cardTitle,
																	})
																}
																checked={selectedItem.indexOf(ID) !== -1}
															/>
														</div>
													</CardContent>
												</CardItemTooltip>
											</Card>
										);
									})}
							</Slider>
						)}
						{slidData.length === 0 && (
							<div className={styles.noDataFound}>
								<NoRecordFound data={data} />
							</div>
						)}
					</div>
				</Grid>
				<Grid item xs={2} sx={{ pl: "20px" }}>
					<div style={{ height: "110px", width: "100%", padding: "0px" }}>
						<ResponsivePie
							data={getChartData()}
							margin={{ top: 8, right: 8, bottom: 8, left: 8 }}
							padAngle={0}
							cornerRadius={0}
							activeOuterRadiusOffset={5}
							enableArcLinkLabels={false}
							enableArcLabels={false}
							innerRadius={0}
							colors={{ scheme: "category10" }}
							arcLinkLabel={"name"}
						/>
					</div>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default SafeDischargeAssLivingSlider;
