import SvgIcon from '@mui/material/SvgIcon';

const InfoIcon = (props) => {
    return (
        <SvgIcon {...props}>            
                <g clipPath="url(#clip0_187_5037)">
                    <path d="M5.5 0C2.4673 0 0 2.46733 0 5.50003C0 8.53273 2.4673 11 5.5 11C8.5327 11 11 8.53273 11 5.50003C11 2.46733 8.5327 0 5.5 0ZM5.5 10C3.01867 10 1 7.98133 1 5.50003C1 3.01873 3.01867 1 5.5 1C7.98133 1 10 3.01873 10 5.50003C10 7.98133 7.9813 10 5.5 10Z" fill="#B3B3B3" />
                    <path d="M5.49991 2.33334C5.13237 2.33334 4.83337 2.63254 4.83337 3.00031C4.83337 3.36774 5.13237 3.66668 5.49991 3.66668C5.86744 3.66668 6.16644 3.36774 6.16644 3.00031C6.16644 2.63254 5.86744 2.33334 5.49991 2.33334Z" fill="#B3B3B3" />
                    <path d="M5.5 4.66666C5.22387 4.66666 5 4.89052 5 5.16666V8.16666C5 8.44279 5.22387 8.66666 5.5 8.66666C5.77613 8.66666 6 8.44279 6 8.16666V5.16666C6 4.89052 5.77613 4.66666 5.5 4.66666Z" fill="#B3B3B3" />
                </g>
                <defs>
                    <clipPath id="clip0_187_5037">
                        <rect width="11" height="11" fill="white" />
                    </clipPath>
                </defs>            
        </SvgIcon>
    );
}

export default InfoIcon;
