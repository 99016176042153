/* eslint-disable no-unused-vars */
import { useSelector } from "react-redux";
import { updateFilterListData as updateFilterListDataCommunity } from "../../utilis/community-common";
import { updateFilterListData as updateFilterListDataDeceased } from "../../utilis/deceased-common";
import { updateFilterListData as updateFilterListDataOverall } from "../../utilis/overall-common";
import {
	DEFAULT_COMMUNITY_DATA,
	setFilterDateRange as setFilterDateRangeCommunityTransfer,
	setFilterListDataCommunity,
} from "../../store/reducers/comparisonReducers/communityTransferComparison.slice";
import { getAllAdmissionsPatient as getAllAdmissionsPatientCommunityTransfer } from "../../services/community-transfer.service";
import { useDispatch } from "react-redux";
import {
	setFilterListDataHospital,
	setPatientList as setPatientListHospital,
	setFilterDateRange as setFilterDateRangeHospital,
} from "../../store/reducers/comparisonReducers/hospitalComparison.slice";
import {
	setPatientList as setPatientListDeceased,
	setFilterListDataDeceased,
	setFilterDateRange as setFilterDateRangeDeceased,
} from "../../store/reducers/comparisonReducers/deceasedComparison.slice";
import {
	setFilterListDataOverall,
	setFilterDateRange as setFilterDateRangeOverall,
	setPatientList as setPatientListOverall,
} from "../../store/reducers/comparisonReducers/overallComparison.slice";
import { updateFilterListData as updateFilterListDataHospital } from "../../utilis/hospital-common";
import {
	getADTDataDefault,
	updateFilterListDataBoth as updateFilterListDataAdmissions,
} from "../../utilis/admission-common";
import { DEFAULT_CARD_FILTER } from "../../store/reducers/hospital.slice";
import { DEFAULT_DECEASED_DATA } from "../../store/reducers/deceased.slice";
import { DEFAULT_OVERALL_DATA } from "../../store/reducers/overall.slice";
import { getAllPatientCount } from "../../services/hospital.service";
import { getAllDeceasedPatient } from "../../services/deceased.service";
import { getAllOverallPatient } from "../../services/overall.service";
import {
	setDefaultADTData,
	setFilterDateRange as setFilterDateRangeAdmission,
	setFilterListData,
	setSelectedADTTableData as setSelectedADTTableDataComparison,
	setPatientList as setPatientListAdmission,
} from "../../store/reducers/comparisonReducers/admissionComparison.slice";
import { DEFAULT_CARD_FILTER as DEFAULT_CARD_FILTER_ADMISSION } from "../../store/reducers/admission.slice";
import { getAllAdmissionsPatient as getAllAdmissionsPatientAdmission } from "../../services/admission.service";

const stateType = {
	communityTransfer: "communityTransferComparison",
	hospital: "hospitalComparison",
	deceased: "deceasedComparison",
	overall: "overallComparison",
	admission: "admissionComparison",
};

const defaultFilterListDataMapper = {
	communityTransfer: DEFAULT_COMMUNITY_DATA,
	hospital: DEFAULT_CARD_FILTER,
	deceased: DEFAULT_DECEASED_DATA,
	overall: DEFAULT_OVERALL_DATA,
	admission: DEFAULT_CARD_FILTER_ADMISSION,
};

const useComparisonFunctions = ({ forPage, setLoadingComparison }) => {
	const defaultFilterListData = forPage ? defaultFilterListDataMapper[forPage] : null;
	const defaultStateType = forPage ? stateType[forPage] : null;
	const { transferType, cardFilter, defaultADTData } = useSelector((state) =>
		defaultStateType ? state[defaultStateType] : {}
	);
	const activeComparisonFacilitiesId = useSelector((state) => state.comparison.activeComparisonFacilitiesId);
	const dispatch = useDispatch();

	const updateTotalToDBFilter = async (res, defaultADTDataRes, transferTypeOverride = null) => {
		if (forPage === "communityTransfer") {
			const latestListData = await updateFilterListDataCommunity(
				cardFilter,
				res,
				cardFilter?.priorityData,
				transferType,
				true
			);
			dispatch(setFilterListDataCommunity(latestListData));
		} else if (forPage === "hospital") {
			const latestListData = await updateFilterListDataHospital(
				cardFilter,
				transferType,
				res,
				cardFilter?.priorityData,
				true
			);
			dispatch(setFilterListDataHospital(latestListData));
		} else if (forPage === "deceased") {
			const latestListData = await updateFilterListDataDeceased(cardFilter, res, cardFilter?.priorityData, true);
			dispatch(setFilterListDataDeceased(latestListData));
		} else if (forPage === "admission") {
			let defaultADTDataResData = defaultADTDataRes ? defaultADTDataRes : defaultADTData;

			const dataFilters = await updateFilterListDataAdmissions(
				res,
				cardFilter,
				transferType,
				cardFilter?.mainPriorityData,
				defaultADTDataResData,
				true
			);

			dispatch(setSelectedADTTableDataComparison(dataFilters.adtList));
			dispatch(setFilterListData(dataFilters.patientList));
		} else if (forPage === "overall") {
			let transferTypeData = transferTypeOverride ? transferTypeOverride : transferType;
			const latestListData = await updateFilterListDataOverall(
				cardFilter,
				res,
				cardFilter?.priorityData,
				transferTypeData,
				true
			);
			dispatch(setFilterListDataOverall(latestListData));
		}
	};

	const getAllDataCount = async (filter, updatedPatientListNew) => {
		try {
			setLoadingComparison(true);
			const filterWithActiveFacilities = {
				...filter,
				facilityIds: activeComparisonFacilitiesId,
			};
			if (forPage === "communityTransfer") {
				dispatch(setFilterDateRangeCommunityTransfer(filter));
				const res = await getAllAdmissionsPatientCommunityTransfer(filterWithActiveFacilities, true);
				if (res && res.list.length > 0) {
					await updateTotalToDBFilter(res);
				} else {
					dispatch(setFilterListDataCommunity(defaultFilterListData));
				}
			} else if (forPage === "hospital") {
				dispatch(setFilterDateRangeHospital(filter));
				const res = await getAllPatientCount(filterWithActiveFacilities, true);
				if (res && res.list) {
					dispatch(setPatientListHospital(res));
					await updateTotalToDBFilter(res);
				} else {
					dispatch(setFilterListDataHospital(defaultFilterListData));
				}
			} else if (forPage === "deceased") {
				dispatch(setFilterDateRangeDeceased(filter));
				const res = await getAllDeceasedPatient(filterWithActiveFacilities, true);
				if (res && res.list) {
					dispatch(setPatientListDeceased(res));
					await updateTotalToDBFilter(res);
				} else {
					dispatch(setFilterListDataDeceased(defaultFilterListData));
				}
			} else if (forPage === "overall") {
				dispatch(setFilterDateRangeOverall(filter));
				const res = await getAllOverallPatient(filterWithActiveFacilities, true);
				if (res && res.list) {
					dispatch(setPatientListOverall(res));
					await updateTotalToDBFilter(res);
				} else {
					dispatch(setFilterListDataOverall(defaultFilterListData));
				}
			} else if (forPage === "admission") {
				dispatch(setFilterDateRangeAdmission({ ...filterWithActiveFacilities }));
				// const res = await getAllAdmissionsPatientAdmission(filterWithActiveFacilities, true);
				// if (res && res.list.length > 0) {
				// 	dispatch(setFilterDateRangeAdmission({ ...filterWithActiveFacilities }));
				// 	dispatch(setPatientListAdmission(res));
				// 	const defaultListFilterData = await getADTDataDefault(res, cardFilter.adtData, true);
				// 	dispatch(setDefaultADTData(defaultListFilterData));

				// 	const updatedPatientListLatest = {
				// 		...updatedPatientListNew,
				// 		adtList: res,
				// 	};

				// 	await updateTotalToDBFilter(
				// 		{
				// 			...updatedPatientListLatest,
				// 			list: res.list,
				// 		},
				// 		defaultListFilterData
				// 	);
				// } else {
				// 	dispatch(setFilterListData(DEFAULT_CARD_FILTER));
				// }
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoadingComparison(false);
		}
	};

	return {
		getAllDataCount,
		updateTotalToDBFilter,
	};
};

export default useComparisonFunctions;
