export const AUTOMATICALLY_REPORT_TYPE = {
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: "monthly",
    QUARTERLY: "Quarterly",
    YEARLY: "yearly",
}

export const REPORT_FILE_TYPE = {
    PDF: 'pdf',
    EXCEL: 'excel',    
}