import { useTheme } from "@mui/material/styles";
import AppBarStyled from "./AppBarStyled";


const DashboardHeader = ({ children }) => {
    const theme = useTheme();

    const appBar = {
        position: "fixed",
        color: "inherit",
        elevation: 0,
        sx: {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
    };

    return (
        <AppBarStyled open={true} {...appBar}>
            {children}
        </AppBarStyled>
    );
};

export default DashboardHeader;
