import { saveEndDateOfADTSetting, getEndDateOfADTData } from "./api/facility-manually-end-date-adt";
import { getAllSettings, getOnlySettingsAll, getShortcutSettingData, saveSetting, saveShortcutSettings, updateSettingData } from "./api/settings.api";

const handleAsyncOperation = async (operation, ...args) => {
    try {
        const res = await operation(...args);
        return res;
    } catch (e) {
        console.error(e);
    }
};

const getSettings = async (type) => handleAsyncOperation(getAllSettings, type);
const getOnlySettings = async (type) => handleAsyncOperation(getOnlySettingsAll, type);
const createSetting = async (body) => handleAsyncOperation(saveSetting, body);
const updateSetting = async (id, body) => handleAsyncOperation(updateSettingData, id, body);
const getEndDateOfADT = async (params) => handleAsyncOperation(getEndDateOfADTData, params, true);
const createEndDateOfADT = async (endDateOfADT) => handleAsyncOperation(saveEndDateOfADTSetting, endDateOfADT);


const createShortcutSetting = async (body) => handleAsyncOperation(saveShortcutSettings, body);
const getShortcutSetting = async (params) => handleAsyncOperation(getShortcutSettingData, params, true);


export {
    getSettings,
    createSetting,
    updateSetting,
    getOnlySettings,
    getEndDateOfADT,
    createEndDateOfADT,
    createShortcutSetting,
    getShortcutSetting
}
