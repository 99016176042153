import axios from "axios";
//import { signOut } from '../auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.request.use(
  async config => {
    const idToken = sessionStorage.getItem("x-auth");
    let accountId = localStorage.getItem("accountId");
    let facilityId = localStorage.getItem("facilityId");

    if (idToken) {
      config.headers["x-auth"] = idToken;
      config.headers["accountId"] = accountId;
      config.headers["facilityId"] = facilityId;
      config.withCredentials = true;
      config.credentials = true;
    }

    return config;
  },
  error => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => response.data,
  async error => {
    const statusCode = error.response?.status;
    if (statusCode === 401) {
      window.location.reload(); 
    }
    return Promise.reject(error);
  }
);

export default api;
