/* eslint-disable no-unused-vars */
import { Stack } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { Box } from "@mui/system";
import _ from "lodash";
import { calcProPercentsBasedOnFilterAndDays, itemPercentage, percentageLabel } from "../../../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../../utilis/percentage-comparison";
import CardItemTooltip from "../../../shared/CardItemTooltip";
import ColorBox from "../../../shared/dashboard/ColorBox";
import NoRecordFound from "../../../shared/NoRecordFound";
import TotalCountPercentage from "../../totalCount/TotalCountPercentage";
import styles from "./DiagnosticsCardList.module.scss";
import DiagnosticsCardSkeleton from "./DiagnosticsCardSkeleton";

const DiagnosticsCardList = ({
	data,
	dataComparison,
	handleToggle,
	handleToggleAll,
	selectedItem = [],
	type,
	page,
	filter,
	filterComparison,
	averageCensusComparison,
	averageCensus,
	cardTitle,
	loading,
	projectionDays,
	priorityData,
	priorityNumber,
	transferType,
	isComparingAgainstAvgCensus,
	lockedTotalBy,
	searchValue,
}) => {
	const isFirstItemInPriorityData = priorityNumber === 1;

	return loading ? (
		<DiagnosticsCardSkeleton />
	) : (
		<div className={`${styles.diagnosticsCardList} `}>
			<List
				sx={{
					width: "100%",
					bgcolor: "background.paper",
				}}
				dense={true}
				disablePadding={true}
			>
				{data.length > 0 &&
					data
						.filter((x) => x.label.toLowerCase().includes(searchValue.toLowerCase()))
						.map((item, index) => {
							const ID = item._id;
							const itemComparison = _.find(dataComparison, { _id: ID });
							const labelId = `checkbox-list-label-${ID}`;
							const selected = selectedItem.indexOf(ID) !== -1;
							const percentageOfAverageCensus = itemPercentage(item.total, averageCensus, "number");
							const percentageOfAverageCensusComparison = itemPercentage(
								itemComparison?.total || 0,
								averageCensusComparison,
								"number"
							);
							const { percentageDiff, itemTotalComparisonScaled, numberOfDays } = calculateDiffBetweenPercentages(
								percentageOfAverageCensus,
								percentageOfAverageCensusComparison,
								false,
								filter,
								filterComparison,
								itemComparison?.total,
								projectionDays
							);
							const comparisonColor = pickComparisonColor(percentageDiff);
							// const projectionChartData = item.chartData?.map((x) => {
							// 	return {
							// 		...x,
							// 		total: calcProPercentsBasedOnFilterAndDays(x.total, filter, projectionDays),
							// 		percentage: calcProPercentsBasedOnFilterAndDays(
							// 			x.percentage,
							// 			filter,
							// 			projectionDays,
							// 			true,
							// 			isComparingAgainstAvgCensus ||
							// 				lockedTotalBy === "census" ||
							// 				(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
							// 		),
							// 	};
							// });

							return (
								<ListItem
									sx={{ padding: "5px" }}
									key={ID}
									dense={true}
									disablePadding={true}
									className={`${styles.listItem} ${selected ? styles.selected : null}`}
								>
									<ListItemButton
										sx={{
											borderRadius: "8px",
											paddingLeft: 2,
											"@media screen and (max-width: 1424px)": {
												paddingLeft: 0.8,
											},
											paddingRight: { md: 1, lg: 2 },
										}}
										role={undefined}
										onClick={() => handleToggle({ item, type, isChecked: selected, cardTitle })}
										dense
										className={`${styles.listItemButton}`}
									>
										<Stack direction={"row"} sx={{ width: "100%" }}>
											<Stack
												sx={{ width: "100%" }}
												direction="row"
												alignItems={"center"}
												justifyContent="space-between"
											>
												<div className={`${styles.title}`} style={{ display: "flex", alignItems: "center" }}>
													{!loading && (
														<ColorBox
															sx={{ mr: "10px" }}
															color={comparisonColor}
															comparingAgainst={itemComparison?.total}
															comparingAgainstScaled={itemTotalComparisonScaled}
															numberOfDays={numberOfDays}
														/>
													)}
													<Checkbox
														edge="start"
														checked={selected}
														tabIndex={-1}
														disableRipple
														inputProps={{ "aria-labelledby": labelId }}
														sx={{ paddingRight: { md: 0.2, lg: 0.5 } }}
													/>
													<CardItemTooltip item={item}>
														<span>{item.label} &nbsp;</span>
													</CardItemTooltip>
													<Box
														component={"span"}
														sx={{
															display: "none",
															"@media screen and (max-width: 1600px)": {
																display: "inline",
															},
														}}
													>
														<span className="ffmar-bold">
															{calcProPercentsBasedOnFilterAndDays(item.total, filter, projectionDays)}{" "}
														</span>
														<span className="ffmar-reg">
															{percentageLabel(
																calcProPercentsBasedOnFilterAndDays(
																	item.percentage ? item.percentage : itemPercentage(item.total, null, null, page),
																	filter,
																	projectionDays,
																	true,
																	isComparingAgainstAvgCensus ||
																		lockedTotalBy === "census" ||
																		(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
																)
															)}
														</span>
													</Box>
												</div>
												<Box
													component={"span"}
													sx={{
														display: "inline",
														"@media screen and (max-width: 1601px)": {
															display: "none",
														},
													}}
												>
													<div className={`${styles.totalCount}`}>
														<span className="ffmar-bold">
															{calcProPercentsBasedOnFilterAndDays(item.total, filter, projectionDays)}{" "}
														</span>
														<span className="ffmar-reg">
															&nbsp;
															{percentageLabel(
																calcProPercentsBasedOnFilterAndDays(
																	item.percentage ? item.percentage : itemPercentage(item.total, null, null, page),
																	filter,
																	projectionDays,
																	true,
																	isComparingAgainstAvgCensus ||
																		lockedTotalBy === "census" ||
																		(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
																)
															)}
														</span>
													</div>
												</Box>
												{/* <Stack direction={"row"} sx={{ height: "50px" }}>
												<DiagnosticsBarChart
													chartData={projectionDays === null ? item.chartData : projectionChartData}
													total={calcProPercentsBasedOnFilterAndDays(item.total, filter, projectionDays)}
													page={page}
													percentage={calcProPercentsBasedOnFilterAndDays(
														item.percentage,
														filter,
														projectionDays,
														filterTotal,
														averageCensus
													)}
												/>
											</Stack> */}
											</Stack>
										</Stack>
									</ListItemButton>
								</ListItem>
							);
						})}
				{!loading && <NoRecordFound data={data} />}
			</List>
		</div>
	);
};

export default DiagnosticsCardList;

const DiagnosticsBarChart = ({ chartData, total, page, percentage }) => {
	return (
		<>
			{chartData &&
				chartData.length > 0 &&
				chartData.map((item) => {
					return (
						<div className={`df ffc aic ${styles.barWrpr}`} key={item.label}>
							<div
								className={`df ffc acc ${styles.bar}`}
								style={{
									height: `45%`,
									background: `${item.color}`,
								}}
							>
								<TotalCountPercentage
									value={item.total}
									isPercentageShow={true}
									percentage={itemPercentage(item.total, total)}
								/>
							</div>
							<p className={`ffmar-bold fs14`}>{item.label}</p>
						</div>
					);
				})}
		</>
	);
};
