import {
  ADD_FACILITY,
  DELETE_FACILITY,
  SET_FACILITIES,
  UPDATE_FACILITY,
  GET_ACTIVE_FACILITIES
} from "../types";

const reducer = (state = null, action) => {
  switch (action.type) {
    case SET_FACILITIES:
      return action.payload;
    case ADD_FACILITY:
      return [...state, action.payload];
    case UPDATE_FACILITY:
      return state.map(facility =>
        facility._id === action.payload._id ? action.payload : facility
      );
    case DELETE_FACILITY:
      return state.filter(facility => facility._id !== action.payload);
    case GET_ACTIVE_FACILITIES:
      return state
    default:
      return state;
  }
};

export default reducer;
