import { store } from "..";
import { setFilterDBData, setMainTotal } from "../store/reducers/deceased.slice";
import { getCardPatientData, getCardPatientChartData, getAllDeceasedCount } from "./api/deceased.api";
import {
	setFilterDBData as setFilterDBDataComparison,
	setMainTotal as setMainTotalComparison,
} from "../store/reducers/comparisonReducers/deceasedComparison.slice";

const cardPatientData = async (data) => {
	try {
		const res = await getCardPatientData(data);
		return res;
	} catch (e) {
		console.log(e);
	}
};

const cardPatientAndChartData = async (data = null) => {
	try {
		const res = await getCardPatientChartData(data);
		return res;
	} catch (e) {
		console.log(e);
	}
};

const getAllDeceasedPatient = async (data = null, forComparison) => {
	try {
		const res = await getAllDeceasedCount(data);
		store.dispatch(forComparison ? setFilterDBDataComparison(res.totals) : setFilterDBData(res.totals));
		store.dispatch(forComparison ? setMainTotalComparison(res.totals.total) : setMainTotal(res.totals.total));
		return res.data;
	} catch (e) {
		console.log(e);
	}
};

export { cardPatientData, cardPatientAndChartData, getAllDeceasedPatient };
