import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { LoadingButton } from "@mui/lab";

const PopupDialog = props => {
  return (
    <div>
      <Dialog open={props.state} onClose={props.onClose}>
        <DialogTitle>{props.dialogMessage || "Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogActions>
            <Button onClick={props.onClose} variant="outlined">
              Cancel
            </Button>
            {props.confirmBtnText && (
              <LoadingButton
                onClick={props.onConfirm}
                loading={props.isLoading}
                color={`${props.isDestructiveAction ? "error" : "primary"}`}
                variant="contained"
              >
                {props.confirmBtnText || "Confirm"}
              </LoadingButton>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

PopupDialog.propTypes = {
  state: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isDestructiveAction: PropTypes.bool,
  dialogMessage: PropTypes.string,
  confirmBtnText: PropTypes.string,
};

export default PopupDialog;
