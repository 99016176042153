import { IconButton, TextField, Typography, Stack, Tooltip, SvgIcon, Box, Select, MenuItem, Checkbox, ListItemText, Chip, FormControl, InputLabel } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";

export const tableDefaultQuestions = [
    { question: { tableLabel: "Last Name", label: "Last Name", accessor: "lastName", _id: "lastName" } },
    { question: { tableLabel: "First Name", label: "First Name", accessor: "firstName", _id: "firstName" } },
    { question: { tableLabel: "Date Of Birth", label: "Date Of Birth", accessor: "DOB", _id: "DOB" } },
    { question: { tableLabel: "Type", label: "Type", accessor: "type", _id: "type" } },
    { question: { tableLabel: "Hospital Days", label: "Hospital Days", accessor: "hospitalDays", _id: "hospitalDays" } },
]

export default function ChartDetailsSearchBar({
    handleSearch,
    searchTerm,
    highlightedCount,
    filterType,
    setFilterType,
    setSelectedHighlightedColumns: setSelectedColumns,
    selectedHighlightedColumns: selectedColumns,
    selectedQuestions: selectedQuestionsData
}) {
    const [selectedQuestions, setSelectedQuestions] = React.useState([]);

    useEffect(() => {
        if (selectedQuestionsData && selectedQuestionsData.length > 0) {
            setSelectedQuestions([...selectedQuestionsData]);
        }
    }, [selectedQuestionsData]);

    const isLessThan = filterType.operation === 'lessThan';
    const isGreaterThan = filterType.operation === 'greaterThan';
    const [defaultQuestions, setDefaultQuestions] = React.useState([]);

    const questionData = useMemo(() => {
        if (selectedQuestions && selectedQuestions.length > 0) {
            return [...tableDefaultQuestions, ...selectedQuestions];
        }
        return [];
    }, [selectedQuestions]);

    useEffect(() => {
        setDefaultQuestions(questionData);
    }, [questionData]);


    const handleChange = (event) => {
        const value = event.target.value;
        if (value && value.length === 1 && value[0] === "selectAll") {
            console.log(value, "value");
            // setSelectedColumns(["selectAll"]);
        } else {
            setSelectedColumns(
                typeof value === 'string' ? value.split(',') : value,
            );
        }
    }

    const handleChangeSelectAll = async (event) => {
        const value = event.target.checked;
        if (value) {
            const values = defaultQuestions?.map((ele) => ele?.question?.accessor);
            setSelectedColumns([...values, "selectAll"]);
        } else {
            setSelectedColumns([]);
        }
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 2 }}>
            <TextField
                label="Search"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearch}
                sx={{ width: "250px" }}
            />

            {filterType.type === 'number' && (
                <Stack direction="column" alignItems="center">
                    <Tooltip title="and greater" placement="top" arrow>
                        <IconButton
                            onClick={() => setFilterType(prev => ({ ...prev, operation: prev.operation === 'greaterThan' ? '' : 'greaterThan' }))}
                            aria-label="Less than"
                            size="small"
                            disableRipple
                            sx={{ width: "20px", height: "20px", mb: 0.2, border: '2px solid grey', borderRadius: '50%', ...isGreaterThan && { borderColor: '#008000' } }}
                        >
                            <SvgIcon
                                alt="Greater than"
                                sx={{ width: "14px", height: "14px" }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="m7.25 15l7.5-5l-7.5-5a.901.901 0 1 1 1-1.5l8.502 5.668a1 1 0 0 1 0 1.664L8.25 16.5a.901.901 0 1 1-1-1.5" /><path fill="currentColor" d="M17 20.998H7a1 1 0 0 1 0-2h10a1 1 0 0 1 0 2" /></svg>
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="and lower" placement="bottom" arrow>
                        <IconButton
                            onClick={() => setFilterType(prev => ({ ...prev, operation: prev.operation === 'lessThan' ? '' : 'lessThan' }))}
                            aria-label="Less than"
                            size="small"
                            disableRipple
                            sx={{ width: "20px", height: "20px", mt: 0.2, border: '2px solid grey', borderRadius: '50%', ...isLessThan && { borderColor: '#008000' } }}
                        >
                            <SvgIcon
                                alt="Less than"
                                sx={{ width: "14px", height: "14px" }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="m16.75 15l-7.5-5l7.5-5a.901.901 0 1 0-1-1.5L7.248 9.168a1 1 0 0 0 0 1.664L15.75 16.5a.901.901 0 1 0 1-1.5" /><path fill="currentColor" d="M17 20.998H7a1 1 0 0 1 0-2h10a1 1 0 0 1 0 2" /></svg>
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>

                </Stack>
            )}
            {searchTerm && (
                <Typography variant="body2" color="text.secondary">
                    {highlightedCount || 0} matches
                </Typography>
            )}
            {selectedQuestions && selectedQuestions.length > 0 && (
                <FormControl>
                    <InputLabel sx={{ mt: "-4px" }} id="column-selector-search-label">Select column</InputLabel>

                    <Select
                        labelId="column-selector-search-label"
                        label="Select column"
                        id="column-selector-search"
                        multiple
                        placeholder="Select column"
                        value={selectedColumns}
                        onChange={handleChange}
                        variant="outlined"
                        selectedItem={2}
                        renderValue={(selected) => {
                            if (selected.length > 0) {

                                if (selected.includes("selectAll")) {
                                    return <Box sx={{ display: 'flex', gap: 0.5 }}>
                                        <Chip
                                            label="Selected All"
                                            size="small"
                                            sx={{
                                                fontFamily: 'manrope-med',
                                                fontWeight: 500,
                                                fontSize: 12,
                                                lineHeight: 1.333,
                                            }}
                                        />
                                    </Box>;
                                } else {
                                    if (selected.length > 3) {
                                        return (
                                            <Box sx={{ display: 'flex', gap: 0.5 }}>
                                                {defaultQuestions?.filter((ele) => _.includes(selected, ele?.question?.accessor))?.slice(0, 3).map((column) => (
                                                    <Chip
                                                        key={column?.question?.label}
                                                        label={column?.question?.label}
                                                        size="small"
                                                        sx={{
                                                            fontFamily: 'manrope-med',
                                                            fontWeight: 500,
                                                            fontSize: 12,
                                                            lineHeight: 1.333,
                                                        }}
                                                    />
                                                ))}
                                                <span> ... </span>
                                            </Box>
                                        );
                                    } else {
                                        return (
                                            <Box sx={{ display: 'flex', gap: 0.5 }}>
                                                {defaultQuestions?.filter((ele) => _.includes(selected, ele?.question?.accessor)).map((column) => (
                                                    <Chip
                                                        key={column?.question?.label}
                                                        label={column?.question?.label}
                                                        size="small"
                                                        sx={{
                                                            fontFamily: 'manrope-med',
                                                            fontWeight: 500,
                                                            fontSize: 12,
                                                            lineHeight: 1.333,
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        );
                                    }
                                }
                            }
                            return <Typography color="text.secondary">Select column</Typography>;
                        }}
                        sx={{
                            width: 500,
                            minWidth: 500,
                            height: 40,
                        }}
                        MenuProps={{ variant: 'menu' }}
                    >
                        <MenuItem key={"selectAll"} value={"selectAll"}>
                            <Checkbox onChange={handleChangeSelectAll} checked={selectedColumns.indexOf("selectAll") > -1} />
                            <ListItemText primary={"Select All"} />
                        </MenuItem>
                        {defaultQuestions && defaultQuestions?.length > 0 && defaultQuestions?.map((column) => (
                            <MenuItem key={column?.question?._id} value={column?.question?.accessor}>
                                <Checkbox checked={selectedColumns.indexOf(column?.question?.accessor) > -1} />
                                <ListItemText primary={column?.question?.label} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </Stack>
    );
}