import { format, getMonth, getYear, parse } from "date-fns";
import React, { useState } from "react";
import styles from "./TimelogDisplay.module.scss";
import { Grid, Typography } from "@mui/material";

function TimeLogDisplay({ logs }) {
	const [expandedMonth, setExpandedMonth] = useState(null);
	const [expandedDate, setExpandedDate] = useState(null);

	const formatMinutes = (minutes) => {
		if (minutes < 60) {
			return `${minutes} minute${minutes !== 1 ? "s" : ""}`;
		}
		const hours = Math.floor(minutes / 60);
		const remainingMinutes = minutes % 60;
		if (remainingMinutes === 0) {
			return `${hours} hour${hours !== 1 ? "s" : ""}`;
		}
		return `${hours} hour${hours !== 1 ? "s" : ""} ${remainingMinutes} minute${remainingMinutes !== 1 ? "s" : ""}`;
	};

	const groupedDates = logs[0]?.dates?.reduce((acc, dateLog) => {
		const date = parse(dateLog.date, "MM/dd/yyyy", new Date());
		const monthKey = `${getYear(date)}-${getMonth(date)}`;
		if (!acc[monthKey]) {
			acc[monthKey] = [];
		}
		acc[monthKey].push(dateLog);
		return acc;
	}, {});

	const toggleMonth = (monthKey) => {
		setExpandedMonth(expandedMonth === monthKey ? null : monthKey);
		setExpandedDate(null);
	};

	const toggleDate = (dateKey) => {
		setExpandedDate(expandedDate === dateKey ? null : dateKey);
	};

	return (
		<div className={styles.container}>
			{Object.entries(groupedDates || {}).map(([monthKey, monthDates]) => (
				<div key={monthKey} className={styles.card}>
					<div onClick={() => toggleMonth(monthKey)} className={styles.monthHeader}>
						<h2 className={styles.monthTitle}>
							{format(parse(monthDates[0].date, "MM/dd/yyyy", new Date()), "MMMM yyyy")}
						</h2>
						<span className={styles.dayCount}>{monthDates.length} days logged</span>
					</div>
					{expandedMonth === monthKey && (
						<div className={styles.dateList}>
							{monthDates.map((dateLog) => (
								<div key={dateLog.date}>
									<div onClick={() => toggleDate(dateLog.date)} className={styles.dateItem}>
										<span className={styles.dateLabel}>
											{format(parse(dateLog.date, "MM/dd/yyyy", new Date()), "dd MMM yyyy")}
										</span>
										<span className={styles.timeCount}>{formatMinutes(dateLog.times.length)} worked</span>
									</div>
									{expandedDate === dateLog.date && (
										<div className={styles.timeList}>
											{dateLog.times.map((time, index) => (
												<div key={index} className={styles.timeItem}>
													{time}
												</div>
											))}
										</div>
									)}
								</div>
							))}
						</div>
					)}
				</div>
			))}
			{!groupedDates && (
				<Grid container sx={{ mt: 4, ml: 2 }}>
					<Grid item>
						<Typography variant="h6" component="div">No time logs found</Typography>
					</Grid>
				</Grid>)}
		</div>
	);
}

export default TimeLogDisplay;
