import ClearIcon from "@mui/icons-material/Clear";
import { Badge, Button, IconButton, Input, Popover, Tooltip, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PickProjectionDaysSVG from "../../../assets/svgs/PickProjectionDaysSVG.svg";
import { setProjectionDays } from "../../../store/reducers/common.slice";
import { ADD_NOTIFICATION } from "../../../store/types";
import styles from "./PickProjectionDaysPopup.module.scss";
import HtmlTooltip from "../HtmlTooltip";

const PickProjectionDaysPopup = ({
	loading,
	style,
	type = "default",
	isOpenPickProjectionDays,
	setIsOpenPickProjectionDays
}) => {
	const [open, setOpen] = useState(false);
	const { projectionDays } = useSelector((state) => state.common);
	const [value, setValue] = useState(null);
	const dispatch = useDispatch();
	const anchorRef = useRef(null);
	const initialOpenedFlag = useRef(false);
	const firstSetvalueFlag = useRef(false);

	useEffect(() => {
		if (isOpenPickProjectionDays) {
			setOpen(true);
		}
	}, [isOpenPickProjectionDays]);

	const onApplyValue = () => {
		const number = Number(value);

		if (value === null || !isNaN(number)) {
			dispatch(setProjectionDays(value === null ? null : number));
		} else {
			dispatch({
				type: ADD_NOTIFICATION,
				payload: {
					label: "Invalid number for projection days",
					type: "error",
					id: "deceasedSaveFilterError",
				},
			});
		}
		setOpen(false);
		setIsOpenPickProjectionDays && setIsOpenPickProjectionDays(false);
	};

	const onChangeValue = (value) => {
		setValue(value);

		if (!firstSetvalueFlag.current) {
			firstSetvalueFlag.current = true;
		}
	};

	useEffect(() => {
		if (!initialOpenedFlag.current) {
			initialOpenedFlag.current = true;
		}
	}, []);

	return (
		<>
			{type === "default" && (
				<Tooltip title="Time Frame Converter" arrow>
					{!open && projectionDays !== null ? (
						<Badge
							color={!loading ? "error" : undefined}
							sx={{
								cursor: loading ? "default" : "pointer",
								"& .MuiBadge-badge": {
									minWidth: "12px",          // Smaller width for the badge
									height: "12px",            // Smaller height for the badge
									borderRadius: "50%",       // Make the badge round
									transform: "translate(50%, -50%) scale(1.2)",  // Position and scale down
									padding: 0,                // Remove padding for a snug fit
								}
							}}

							badgeContent={
								!loading ? (
									<HtmlTooltip content="Remove time frame converter" placement="bottom-start">
										<ClearIcon
											onClick={(e) => {
												e.stopPropagation();
												dispatch(setProjectionDays(null));
												setValue(null);
											}}
											sx={{
												fontSize: 12,
												color: "white",
											}}
										/>
									</HtmlTooltip>
								) : (
									<div className="skeleton" style={{ width: "16px", height: "16px", borderRadius: "10px" }}></div>
								)
							}
						>
							<IconButton
								aria-label="time frame converter"
								size="small"
								onClick={() => setOpen(true)}
								ref={anchorRef}
								sx={{
									width: 30,
									height: 30,
									ml: 1,
									backgroundColor: '#4879f5',
									borderRadius: "4px",
									'&:hover': {
										backgroundColor: 'rgba(0, 0, 0, 0.12)',
									},
									color: '#fff',
								}}
							>
								<PickProjectionDaysSVG width={18} height={18} />
							</IconButton>
						</Badge>
					) : (
						<IconButton
							aria-label="Compare & Highlight"
							size="small"
							onClick={() => setOpen(true)}
							ref={anchorRef}
							sx={{
								width: 30,
								height: 30,
								ml: 1,
								borderRadius: "4px",
								'&:hover': {
									backgroundColor: '#4879f5',
									color:"white",
								},
							}}
						>
							<PickProjectionDaysSVG width={18} height={18} />
						</IconButton>
					)}
					{/* <div
						className={classNames(
							styles.projectionDaysSVGWrapper,
							projectionDays !== null && styles.active,
							loading && "skeleton"
						)}
						onClick={() => setOpen(true)}
						ref={anchorRef}
						style={{ ...style, cursor: loading ? "default" : "pointer" }}
					>
						{!open && projectionDays !== null && (
							<div className={classNames(styles.clearWrapper, loading && styles.skeleton, loading && "skeleton")}>
								<ClearIcon
									onClick={(e) => {
										e.stopPropagation();
										dispatch(setProjectionDays(null));
										setValue(null);
									}}
									sx={{
										fontSize: 12,
										color: "white",
									}}
								/>
							</div>
						)}
						{type === "default" && loading ? <div style={{ width: "22px", height: "22px" }} /> :

							<PickProjectionDaysSVG width={18} height={18} />
						}
					</div> */}
				</Tooltip>
			)}

			{/* {projectionDays !== null && (
				<div className={styles.projectionInfoFootnote}>
					proj. <span style={{ fontWeight: "bold" }}>{projectionDays}</span>
				</div>
			)} */}
			<Popover
				id="pick-projection-days-popup"
				open={open}
				sx={{ mt: "-2px", ml: "-20px" }}
				anchorEl={anchorRef.current}
				onClose={() => {
					setOpen(false)
					setIsOpenPickProjectionDays && setIsOpenPickProjectionDays(false);
				}}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<div
					style={{
						width: "360px",
						height: "214px",
						position: "relative",
						padding: "10px 13px",
						paddingBottom: "15px",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
					}}
				>
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
						<div>
							<Typography variant="subtitle1">Choose a period of data: </Typography>
						</div>
						<div className={styles.secondSVGWrapper}>
							<PickProjectionDaysSVG />
						</div>
					</div>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div>
							<Button
								variant={
									value === null || (projectionDays === null && initialOpenedFlag.current === false)
										? "contained"
										: "outlined"
								}
								onClick={() => onChangeValue(null)}
							>
								All
							</Button>
						</div>
						<div>
							<Button
								variant={
									value === 30 || (projectionDays === 30 && initialOpenedFlag.current === false)
										? "contained"
										: "outlined"
								}
								onClick={() => onChangeValue(30)}
							>
								30 Days
							</Button>
						</div>
						<div>
							<Button
								variant={
									value === 60 || (projectionDays === 60 && initialOpenedFlag.current === false)
										? "contained"
										: "outlined"
								}
								onClick={() => onChangeValue(60)}
							>
								60 Days
							</Button>
						</div>
						<div>
							<Button
								variant={
									value === 90 || (projectionDays === 90 && initialOpenedFlag.current === false)
										? "contained"
										: "outlined"
								}
								onClick={() => onChangeValue(90)}
							>
								90 Days
							</Button>
						</div>
					</div>
					<Typography variant="subtitle1">or</Typography>
					<div style={{ display: "flex", height: "36px" }}>
						<Input
							sx={{ borderRadius: "5px", paddingLeft: "5px", minWidth: "60%", height: "100%" }}
							placeholder="Enter custom number"
							value={value || ""}
							onChange={(e) => setValue(e.target.value)}
						></Input>
						<Button
							variant={firstSetvalueFlag.current === false || value === projectionDays ? "outlined" : "contained"}
							fullWidth={true}
							sx={{ ml: "10px" }}
							onClick={onApplyValue}
						>
							Apply
						</Button>
					</div>
				</div>
			</Popover>
		</>
	);
};

export default PickProjectionDaysPopup;
