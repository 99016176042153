/* eslint-disable react-hooks/exhaustive-deps */
import { createRef, useEffect, useState } from "react";
import ArrowSVG from "../../../assets/svgs/arrow.svg";
import styles from "./DropDown.module.scss";
import { useCallback } from "react";
import classNames from "classnames";

const DropDown = ({ options, selected, setOption, width, size, style = {}, className }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = createRef();

  // Add useEffect to close dropdown when clicked outside of it
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
  }, [showDropdown]);

  const isOptionSelected = useCallback(
    option => options.find(el => el.value === selected)?.value === option.value,
    [options, selected]
  );

  return (
    <div
      className={classNames(
        "df aic m-l-15",
        styles.dropDown,
        showDropdown && styles.open,
        showDropdown && "dropdown-active",
        styles[size],
        className
      )}
      ref={ref}
      style={{ width: `${width || 200}px`, ...style }}
    >
      <p
        className={classNames("df aic ffmar fs13", styles.placeholder)}
        onClick={() => setShowDropdown(current => !current)}
      >
        {options.find(el => el.value === selected)?.label || "Please select"}

        <span className={classNames("mla", styles.menuOpener)}>
          <ArrowSVG />
        </span>
      </p>
      {showDropdown && (
        <div className={classNames(styles.menu)}>
          {options.map(option => (
            <p
              className={classNames(
                "ffmar fs14",
                styles.line,
                isOptionSelected(option) && styles.selected,
                option.subOption && styles.subOption,
                option.subOption === false && styles.mainOption
              )}
              onClick={() => {
                setShowDropdown(current => !current);
                setOption(option.value);
              }}
              key={option.value}
            >
              {option.label}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDown;
