import { Stack } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import classNames from "classnames";
import React from "react";
import { PAGE_TYPE } from "../../../../types/pages.type";
import { calcProPercentsBasedOnFilterAndDays, itemPercentage } from "../../../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../../utilis/percentage-comparison";
import CardItemTooltip from "../../../shared/CardItemTooltip";
import ColorBox from "../../../shared/dashboard/ColorBox";
import NoRecordFound from "../../../shared/NoRecordFound";
import styles from "./DxCardList.module.scss";
import DxCardListSkeleton from "./DxCardListSkeleton";

const DxCardList = ({
	data,
	dataComparison,
	filter,
	filterComparison,
	handleToggle,
	handleToggleAll,
	selectedItem = [],
	type,
	page,
	cardTitle,
	admissionCompareAgainst,
	admissionCompareAgainstComparison,
	comparingAgainstDifferentFacility,
	reverseColorsAdmissionPage,
	loading,
	projectionDays,
	isComparingAgainstAvgCensus,
	transferType,
	priorityNumber,
	lockedTotalBy,
	selectAll,
	searchValue
}) => {
	const specialPages = [PAGE_TYPE.ADMISSION];
	const isSpecialPage = specialPages.find((x) => x === page)?.length > 0;
	const isFirstItemInPriorityData = priorityNumber === 1;
	
	return (
		<div className={styles.DxCardList}>
			<List
				sx={{
					width: "100%",
					bgcolor: "background.paper",
				}}
				dense={true}
				disablePadding={true}
			>
				{loading ? (
					<DxCardListSkeleton />
				) : (
					data.length > 0 &&
					data?.filter((x) => x.label.toLowerCase().includes(searchValue.toLowerCase())).map((item, index) => {
						const ID = item._id;
						const labelId = `checkbox-list-label-${ID}`;
						const selected = selectedItem.indexOf(ID) !== -1;
						const itemComparison = dataComparison?.find((x) => x._id === item._id);
						let comparisonColor;
						let comparingAgainst;
						let comparingAgainstScaled;
						let numberOfDays;

						if (page === PAGE_TYPE.ADMISSION) {
							const percentage = itemPercentage(item.total, admissionCompareAgainst, "number", page);

							const percentageComparison = itemPercentage(
								itemComparison?.total,
								admissionCompareAgainstComparison,
								"number",
								page
							);

							const {
								percentageDiff,
								itemTotalComparisonScaled,
								numberOfDays: numberOfDaysTemp,
							} = calculateDiffBetweenPercentages(
								percentage,
								percentageComparison,
								reverseColorsAdmissionPage,
								filter,
								filterComparison,
								itemComparison?.total,
								projectionDays
							);

							numberOfDays = numberOfDaysTemp;
							comparingAgainstScaled = itemTotalComparisonScaled;
							comparisonColor = pickComparisonColor(percentageDiff, isSpecialPage);
							comparingAgainst = itemComparison?.total;
						} else {
							const percentage = itemPercentage(item.total, data.censusAverage, "number", page);

							const percentageComparison = itemPercentage(
								itemComparison?.total,
								dataComparison?.censusAverage,
								"number",
								page
							);
							const {
								percentageDiff,
								itemTotalComparisonScaled,
								numberOfDays: numberOfDaysTemp,
							} = calculateDiffBetweenPercentages(
								percentage,
								percentageComparison,
								false,
								filter,
								filterComparison,
								itemComparison?.total,
								projectionDays
							);

							numberOfDays = numberOfDaysTemp;
							comparingAgainstScaled = itemTotalComparisonScaled;
							comparisonColor = pickComparisonColor(percentageDiff, isSpecialPage);
							comparingAgainst = percentageComparison;
						}

						if (comparingAgainstDifferentFacility) {
							comparisonColor = null;
						}

						return (
							<ListItem
								sx={{ padding: "5px" }}
								key={ID}
								disablePadding
								className={classNames(styles.listItem, selected && styles.selected)}
							>
								<ListItemButton
									sx={{
										boxShadow: "0px 0px 14px 0px rgb(0 0 0 / 8%)",
										borderRadius: "8px",
									}}
									role={undefined}
									onClick={() => handleToggle({ item, itemComparison, type, cardTitle, isChecked: selected })}
									dense
									className={styles.listItemButton}
								>
									<Stack direction={"row"} sx={{ width: "100%" }}>
										<Stack sx={{ width: "100%" }} direction="row" alignItems={"center"} justifyContent="space-between">
											<div className={`${styles.title}`}>
												<Checkbox
													edge="start"
													checked={selected}
													tabIndex={-1}
													disableRipple
													inputProps={{ "aria-labelledby": labelId }}
													className={styles.dayCardCheckbox}
												/>
											</div>

											<Stack
												sx={{ width: "100%" }}
												direction="row"
												alignItems={"center"}
												justifyContent="space-between"
											>
												<CardItemTooltip item={item}>
													<div className={styles.title}>{item.label}</div>
												</CardItemTooltip>
												<div className={styles.buttonContainer}>
													{!loading && (
														<ColorBox
															sx={{ marginRight: "20px" }}
															color={comparisonColor}
															type="button"
															comparingAgainst={comparingAgainst}
															comparingAgainstScaled={comparingAgainstScaled}
															numberOfDays={numberOfDays}
														/>
													)}
													<span className={`ffmar-bold fs16 ${styles.buttonContainerTotal}`}>
														{calcProPercentsBasedOnFilterAndDays(item.total, filter, projectionDays)}&nbsp;
													</span>
													<span className="ffmar-reg fs16">
														{item.percentage
															? `(${calcProPercentsBasedOnFilterAndDays(
																	item.percentage,
																	filter,
																	projectionDays,
																	true,
																	isComparingAgainstAvgCensus ||
																		lockedTotalBy === "census" ||
																		(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
															  )}%)`
															: calcProPercentsBasedOnFilterAndDays(
																	itemPercentage(item.total, null, null, page),
																	filter,
																	projectionDays,
																	true,
																	isComparingAgainstAvgCensus ||
																		lockedTotalBy === "census" ||
																		(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
															  )}
													</span>
												</div>
											</Stack>
										</Stack>
									</Stack>
								</ListItemButton>
							</ListItem>
						);
					})
				)}
				<NoRecordFound data={data} />
			</List>
		</div>
	);
};

export default DxCardList;
