import * as React from "react";
import { Chip } from "@mui/material";
import styles from "./TaggedList.module.scss";
import _ from "lodash";
import { Box } from "@mui/system";

const TaggedList = ({ type, data, selectedItem, labelName = "label" }) => {
    return (
        <div>
            {(type === "floorsData" ||
                type === "shiftData" ||
                type === "daysData" ||
                type === "dxData" ||
                type === "doctorData" ||
                type === "hospitalData" ||
                type === "nurseData") &&
                selectedItem.length > 0 && (
                    <Box sx={{ width: "100%"}}>
                        {data.map((item, index) =>
                            _.includes(selectedItem, item._id) ? (
                                <Chip
                                    key={index}
                                    className={`${styles.taggedList}`}
                                    size="small"
                                    label={item[labelName]}
                                />
                            ) : null
                        )}
                    </Box>
                )}
        </div>
    );
};

export default TaggedList;
