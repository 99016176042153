import { useSelector } from "react-redux";
import { Outlet } from "react-router";
import Header from "../header/Header";

const Home = (props) => {
  const { account, facility } = useSelector(({ account, facility }) => ({
    account,
    facility,
  }));

  return (
    <div className={`df`} style={{ height: "100%" }}>
      <Header />
      {facility !== "refreshing" && account !== "refreshing" ? (
        <Outlet />
      ) : undefined}
    </div>
  );
};

export default Home;
