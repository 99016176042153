const CompareIconClose = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 251">
        <rect
          id="Rectangle 1080"
          width="30"
          height="30"
          rx="6"
          fill="#636578"
        />
        <path
          id="Vector"
          d="M20.058 18.2604L16.6867 14.8891L20.058 11.5178C20.4302 11.1456 20.4302 10.5415 20.058 10.1693L19.6085 9.71982C19.2363 9.34763 18.6322 9.34762 18.26 9.71982L14.8887 13.0911L11.5174 9.71982C11.1452 9.34762 10.541 9.34763 10.1689 9.71982L9.71938 10.1693C9.34718 10.5415 9.34718 11.1456 9.71937 11.5178L13.0907 14.8891L9.71938 18.2604C9.34718 18.6326 9.34718 19.2367 9.71938 19.6089L10.1689 20.0584C10.541 20.4306 11.1452 20.4306 11.5174 20.0584L14.8887 16.6871L18.26 20.0584C18.6322 20.4306 19.2363 20.4306 19.6085 20.0584L20.058 19.6089C20.4302 19.2367 20.4302 18.6326 20.058 18.2604Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default CompareIconClose;
