import { Checkbox, Stack } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { calcProPercentsBasedOnFilterAndDays, itemPercentage, percentageLabel } from "../../../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../../utilis/percentage-comparison";
import CardItemTooltip from "../../../shared/CardItemTooltip";
import ColorBox from "../../../shared/dashboard/ColorBox";
import NoRecordFound from "../../../shared/NoRecordFound";
import styles from "./DischargeEmergencyList.module.scss";
import DischargeEmergencyListSkeleton from "./DischargeEmergencyListSkeleton";

export const TotalTextWithBar = ({
	total,
	percentage,
	colorCompare = null,
	comparingAgainst,
	comparingAgainstScaled,
	numberOfDays,
	loading,
}) => {
	return (
		<Stack direction={"row"} display={"flex"} alignItems={"center"}>
			<Stack>
				{!loading && (
					<ColorBox
						color={colorCompare}
						sx={{ height: "25px", width: "5px", mr: 0.1 }}
						comparingAgainst={comparingAgainst}
						comparingAgainstScaled={comparingAgainstScaled}
						numberOfDays={numberOfDays}
					/>
				)}
			</Stack>
			<Stack justifyContent={"center"} display={"flex"} alignItems={"center"}>
				<p className={`fs15 fw700 ${styles.totalText}`}>{total}</p>
				<p className={`fs12 fw400 ${styles.percentageText}`}>{percentage}</p>
			</Stack>
		</Stack>
	);
};

const DischargeEmergencyList = ({
	data,
	dataComparison,
	averageCensus,
	averageCensusComparison,
	handleToggle,
	filter,
	filterComparison,
	selectedItem = [],
	type,
	cardTitle,
	loading,
	projectionDays,
	filterTotal,
	isComparingAgainstAvgCensus,
	priorityData,
	priorityNumber,
	transferType,
	lockedTotalBy,
}) => {
	const [chartData, setChartData] = useState([]);
	const [item, setItem] = useState({ dcItem: null, ecItem: null });
	const [itemComparison, setItemComparison] = useState({ dcItem: null, ecItem: null });
	const [comparingAgainstScaledEc, setComparingAgainstScaledEc] = useState();
	const [comparingAgainstScaledDc, setComparingAgainstScaledDc] = useState();
	const [numberOfDaysEc, setNumberOfDaysEc] = useState(null);
	const [numberOfDaysDc, setNumberOfDaysDc] = useState(null);
	const percentageThresholds = useSelector((state) => state.comparison.percentageThresholds);
	const itemInPriorityData = priorityData?.find((x) => x.type === "DCERData");
	const isFirstItemInPriorityData = priorityNumber === 1;

	const handleToggleInner = (item, type, itemComparison, isChecked) => {
		handleToggle({ item, type, itemComparison, isChecked, cardTitle });
	};

	useEffect(() => {
		if (data && data.length > 0) {
			const dcItem = _.find(data, { _id: "DC" });
			const ecItem = _.find(data, { _id: "ER" });
			const dcItemComparison = _.find(dataComparison, { _id: "DC" });
			const ecItemComparison = _.find(dataComparison, { _id: "ER" });
			const dcItemPercentage = itemPercentage(dcItem.total, averageCensus, "number");
			const ecItemPercentage = itemPercentage(ecItem.total, averageCensus, "number");
			const ecItemPercentageComparison = itemPercentage(ecItemComparison?.total, averageCensusComparison, "number");
			const dcItemPercentageComparison = itemPercentage(dcItemComparison?.total, averageCensusComparison, "number");
			const {
				percentageDiff: percentageDiffDc,
				itemTotalComparisonScaled: itemTotalComparisonScaledDc,
				numberOfDays: numberOfDaysDcTemp,
			} = calculateDiffBetweenPercentages(
				dcItemPercentage,
				dcItemPercentageComparison,
				false,
				filter,
				filterComparison,
				dcItemComparison?.total,
				projectionDays
			);
			const {
				percentageDiff: percentageDiffEc,
				itemTotalComparisonScaled: itemTotalComparisonScaledEc,
				numberOfDays: numberOfDaysEcTemp,
			} = calculateDiffBetweenPercentages(
				ecItemPercentage,
				ecItemPercentageComparison,
				false,
				filter,
				filterComparison,
				ecItemComparison?.total,
				projectionDays
			);
			const dcItemComparisonColor = pickComparisonColor(percentageDiffDc);
			const ecItemComparisonColor = pickComparisonColor(percentageDiffEc);

			setNumberOfDaysDc(numberOfDaysDcTemp);
			setNumberOfDaysEc(numberOfDaysEcTemp);
			setComparingAgainstScaledEc(itemTotalComparisonScaledEc);
			setComparingAgainstScaledDc(itemTotalComparisonScaledDc);

			setItem({
				dcItem: {
					...dcItem,
					total: calcProPercentsBasedOnFilterAndDays(dcItem.total, filter, projectionDays),
					percentage: calcProPercentsBasedOnFilterAndDays(
						dcItem.percentage,
						filter,
						projectionDays,
						true,
						isComparingAgainstAvgCensus ||
							lockedTotalBy === "census" ||
							(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
					),
					colorCompare: dcItemComparisonColor,
				},
				ecItem: {
					...ecItem,
					total: calcProPercentsBasedOnFilterAndDays(ecItem.total, filter, projectionDays),
					percentage: calcProPercentsBasedOnFilterAndDays(
						ecItem.percentage,
						filter,
						projectionDays,
						true,
						isComparingAgainstAvgCensus ||
							lockedTotalBy === "census" ||
							(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
					),
					colorCompare: ecItemComparisonColor,
				},
			});
			setItemComparison({
				dcItem: { ...dcItemComparison, colorCompare: dcItemComparisonColor },
				ecItem: { ...ecItemComparison, colorCompare: ecItemComparisonColor },
			});
			const chartDetails = [
				{
					id: "DC",
					value: calcProPercentsBasedOnFilterAndDays(dcItem.total || 0, filter, projectionDays),
					percentage: calcProPercentsBasedOnFilterAndDays(
						dcItem.percentage,
						filter,
						projectionDays,
						true,
						isComparingAgainstAvgCensus ||
							lockedTotalBy === "census" ||
							(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
					),
					colorCompare: dcItemComparisonColor,
				},
				{
					id: "ER",
					value: calcProPercentsBasedOnFilterAndDays(ecItem.total || 0, filter, projectionDays),
					percentage: calcProPercentsBasedOnFilterAndDays(
						ecItem.percentage,
						filter,
						projectionDays,
						true,
						isComparingAgainstAvgCensus ||
							lockedTotalBy === "census" ||
							(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
					),
					colorCompare: ecItemComparisonColor,
				},
			];
			setChartData(chartDetails);
		} else {
			setItem({ dcItem: { _id: "DC", total: 0 }, ecItem: { _id: "ER", total: 0 } });
			setItemComparison({ dcItem: { _id: "DC", total: 0 }, ecItem: { _id: "ER", total: 0 } });
			const chartDetails = [
				{ id: "DC", value: 0 },
				{ id: "ER", value: 0 },
			];
			setChartData(chartDetails);
		}
	}, [
		averageCensus,
		averageCensusComparison,
		data,
		dataComparison,
		filter,
		filterComparison,
		filterTotal,
		isComparingAgainstAvgCensus,
		isFirstItemInPriorityData,
		item?.percentage,
		itemInPriorityData,
		lockedTotalBy,
		percentageThresholds,
		projectionDays,
		selectedItem.length,
		transferType,
	]);

	return loading ? (
		<DischargeEmergencyListSkeleton />
	) : (
		<>
			{data && data.length > 0 && (
				<div className={`df aie ${styles.dcEr} ${styles.innerWrpr}`}>
					<div className={`ffmar df jcc ${styles.leftSec}`}>
						<div
							className={`df ffc aic ${styles.barWrpr} ${selectedItem.indexOf("DC") !== -1 ? styles.selectedItem : ""}`}
							id={"DC"}
						>
							{item?.dcItem?.percentage <= 20 && (
								<TotalTextWithBar
									total={item?.dcItem?.total || 0}
									percentage={percentageLabel(item?.dcItem?.percentage)}
									colorCompare={item?.dcItem?.colorCompare}
									comparingAgainst={itemComparison?.dcItem?.total || 0}
									comparingAgainstScaled={comparingAgainstScaledDc}
									numberOfDays={numberOfDaysDc}
									loading={loading}
								/>
							)}
							<div
								className={`df ffc acc ${styles.bar}`}
								style={{
									height: `${item?.dcItem?.percentage}%`,
									background: "#606BDF",
								}}
							>
								{item?.dcItem?.percentage > 20 && (
									<TotalTextWithBar
										total={item?.dcItem?.total || 0}
										percentage={percentageLabel(item?.dcItem?.percentage)}
										colorCompare={item?.dcItem?.colorCompare}
										comparingAgainst={itemComparison?.dcItem?.total || 0}
										comparingAgainstScaled={comparingAgainstScaledDc}
										numberOfDays={numberOfDaysDc}
										loading={loading}
									/>
								)}
							</div>
							<CardItemTooltip item={item?.dcItem}>
								<p className={`ffmar-bold fs14 m-t-8 cp`}>DC</p>
							</CardItemTooltip>
							<Checkbox
								sx={{ mr: 0.1 }}
								onChange={() =>
									handleToggleInner(
										item?.dcItem ? item?.dcItem : { _id: "DC" },
										type,
										itemComparison?.dcItem,
										selectedItem.indexOf("DC") !== -1
									)
								}
								checked={selectedItem.indexOf("DC") !== -1}
								inputProps={{ "aria-labelledby": "DC" }}
							/>
						</div>

						<div
							className={`df ffc aic ${styles.barWrpr} ${
								selectedItem.indexOf("ER") !== -1 ? styles.selectedItemEr : ""
							}`}
							id={"ER"}
						>
							{item?.ecItem?.percentage <= 20 && (
								<TotalTextWithBar
									total={item?.ecItem?.total || 0}
									percentage={percentageLabel(item?.ecItem?.percentage)}
									colorCompare={item?.ecItem?.colorCompare}
									comparingAgainst={itemComparison?.ecItem?.total || 0}
									comparingAgainstScaled={comparingAgainstScaledEc}
									numberOfDays={numberOfDaysEc}
								/>
							)}
							<div
								className={`df ffc acc ${styles.bar}`}
								style={{
									height: `${item?.ecItem?.percentage}%`,
									background: "#87BCFE",
								}}
							>
								{item?.ecItem?.percentage > 20 && (
									<TotalTextWithBar
										total={item?.ecItem?.total || 0}
										percentage={percentageLabel(item?.ecItem?.percentage)}
										colorCompare={item?.ecItem?.colorCompare}
										comparingAgainst={itemComparison?.ecItem?.total || 0}
										comparingAgainstScaled={comparingAgainstScaledEc}
										numberOfDays={numberOfDaysEc}
									/>
								)}
							</div>
							<CardItemTooltip item={item?.ecItem}>
								<p className={`ffmar-bold fs14 m-t-8 cp`}>ER</p>
							</CardItemTooltip>
							<Checkbox
								sx={{ mr: 0.1 }}
								onChange={() =>
									handleToggleInner(
										item?.ecItem ? item?.ecItem : { _id: "ER" },
										type,
										itemComparison?.ecItem,
										selectedItem.indexOf("ER") !== -1
									)
								}
								checked={selectedItem.indexOf("ER") !== -1}
								inputProps={{ "aria-labelledby": "ER" }}
							/>
						</div>
					</div>

					<div className={`${styles.rightSec}`} style={{ height: "150px" }}>
						<ResponsivePie
							colors={["#606BDF", "#87BCFE"]}
							data={chartData}
							padAngle={0}
							cornerRadius={0}
							activeOuterRadiusOffset={5}
							enableArcLinkLabels={false}
							arcLabelsTextColor="#fff"
							innerRadius={0}
						/>
					</div>
				</div>
			)}
			{!loading && <NoRecordFound data={data} />}
		</>
	);
};

export default DischargeEmergencyList;
