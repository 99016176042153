import axios from "../../axios";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Button from "../../components/ui/button/Button";
import DeleteSVG from "../../assets/svgs/delete.svg";
import EditSVG from "../../assets/svgs/edit.svg";
import AddAccount from "../../components/accounts-list/add-account/AddAccount";
import { useDispatch } from "react-redux";
import { DELETE_ACCOUNT } from "../../store/types";
import { ShowForPermission } from "../../components/shared/ShowForPermission";
import PopupDialog from "../../components/popupDialog/PopupDialog";
import styles from "../../assets/styles/AccountsPage.module.scss";
import useSetParamToURL from "../../components/hooks/useSetParamToURL";

const AccountsPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [currentEdit, setCurrentEdit] = useState({});
  const [deleteAccountState, setDeleteAccountState] = useState({ state: false, accountId: null });
  const [deleteAccountLoading, setDeleteAccountLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getAccountId } = useSetParamToURL();

  const selectedAccountId = useMemo(() => {
    return getAccountId() || localStorage.getItem("accountId");
  }, [getAccountId]);

  const getAccounts = async () => {
    setLoading(true);
    let re = await axios.get(`/api/account/all`).catch(e => e);
    setAccounts(re.data || []);
    setLoading(false);
  };

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    // eslint-disable-next-line
    setShowAdd(search.get("showAdd") === "true");
    getAccounts();
    // eslint-disable-next-line
  }, []);

  const toggleShowAdd = () => setShowAdd(!showAdd);

  const deleteAccount = async id => {
    await axios.delete(`/api/account/${id}`);
    const newData = accounts.filter(u => u._id !== id);
    setAccounts(newData);
    dispatch({
      type: DELETE_ACCOUNT,
      payload: id,
    });
  };

  const accountAdded = account => {
    setAccounts([...accounts, account]);
    navigate(`/settings/accounts?showAdd=false`);
    setShowAdd(false);
    setCurrentEdit({});
  };

  const accountUpdated = account => {
    const newData = accounts.map(u => {
      if (u._id === account._id) {
        return account;
      }
      return u;
    });
    setAccounts(newData);
    setCurrentEdit({});
  };

  return (
    <div className={styles.accountUsers}>
      <div className={`df ${styles.hdr}`}>
        <ShowForPermission type="addAccount">
          <div className={`mla`}>
            <Button action={toggleShowAdd} buttonStyle="theme" text="Add Account" size="medium" />
            {showAdd ? (
              <AddAccount
                accountUpdated={accountUpdated}
                currentEdit={currentEdit}
                close={toggleShowAdd}
                accountAdded={accountAdded}
                clearCurrentEdit={() => setCurrentEdit({})}
              />
            ) : undefined}
          </div>
        </ShowForPermission>
      </div>
      <div className={styles.listWrpr}>
        {accounts.map(account => (
          <div key={account._id} className={`df aic ffmm fs14 ${styles.line}`}>
            <h2 style={{ width: "20%" }}>{account.name}</h2>

            <div className={`mla df aic`}>
              <ShowForPermission type="editAccount">
                <div
                  className={`m-r-10 c-pointer`}
                  onClick={() => {
                    setCurrentEdit(account);
                    toggleShowAdd();
                  }}
                >
                  <EditSVG fill="#4879f5" />
                </div>
              </ShowForPermission>
              <ShowForPermission type="deleteAccount">
                <div
                  className={`m-l-10 c-pointer`}
                  onClick={() => {
                    setDeleteAccountState({ state: true, accountId: account._id });
                  }}
                >
                  <DeleteSVG fill="#F93B5F" />
                </div>
              </ShowForPermission>
            </div>
          </div>
        ))}
        {accounts.length === 0 && !loading && <div key={"no-data-user"} className={`df aic ffmm fs14 ${styles.line}`}>No data found</div>}
      </div>
      <PopupDialog
        state={deleteAccountState.state}
        isDestructiveAction={true}
        isLoading={deleteAccountLoading}
        onClose={() => setDeleteAccountState({ state: false, accountId: null })}
        onConfirm={async () => {
          setDeleteAccountLoading(true);
          await deleteAccount(deleteAccountState.accountId);
          setDeleteAccountLoading(false);
          setDeleteAccountState({ state: false, accountId: null });
        }}
        dialogMessage={selectedAccountId === deleteAccountState.accountId ? "You cannot delete this account while you are currently using it. Please switch to a different account before attempting to delete this account." : "Are you sure you want to delete this account?"}
        {...(selectedAccountId !== deleteAccountState.accountId && { confirmBtnText: "Delete" })}
      />
    </div>
  );
};

export default AccountsPage;
