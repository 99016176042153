import styles from "./SNFFacilitySlider.module.scss";
import { Card, CardContent, Grid } from "@mui/material";
import Slider from "react-slick";
import CardItemTooltip from "../../../shared/CardItemTooltip";

const SNFFacilitySliderSkeleton = () => {
	const fakeData = [1, 2, 3];

	return (
		<Grid container alignItems={"center"}>
			<Grid item xs={10}>
				<div className={"snf-facility-slider"}>
					{fakeData.length > 0 && (
						<Slider arrows={false} infinite={true} swipe={false}>
							{fakeData.map((ele, index) => {
								return (
									<Card
										key={index}
										style={{ width: "170px", height: "140px" }}
										className={`${styles.snfFacilitySlider} ${styles.skeleton} skeleton`}
									>
										<CardItemTooltip item={ele}>
											<CardContent className={styles.slideCardContent} style={{ height: "140px" }}>
												<Grid
													container
													key={"row" + index}
													direction="column"
													justifyContent="center"
													alignItems="center"
													spacing={2}
												>
													<Grid item xs={12}>
														<div className={styles.checkboxContainer}>
															<div className={`df fs15 fw500 ${styles.checkboxButtonContainer}`}></div>
														</div>
													</Grid>
												</Grid>
											</CardContent>
										</CardItemTooltip>
									</Card>
								);
							})}
						</Slider>
					)}
				</div>
			</Grid>
			<Grid item xs={2} sx={{ pr: "30px" }}>
				<div
					style={{
						height: "110px",
						width: "100%",
						padding: "0px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<div className="skeleton" style={{ width: "90px", height: "90px", borderRadius: "9999px" }}></div>
				</div>
			</Grid>
		</Grid>
	);
};

export default SNFFacilitySliderSkeleton;
