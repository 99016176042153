import { useCallback, useEffect, useState } from "react";
import CloseIcon from "../../../assets/svgs/close.svg";
import styles from "../edit-census-dialog/EditCensusDialog.module.scss";
import { useDispatch } from "react-redux";
import Cleave from "cleave.js/react";
import _ from "lodash";
import { ADD_NOTIFICATION } from "../../../store/types";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { toDisplayTime, toSaveDate } from "../../../utilis/date-formats";
import { createEndDateOfADT } from "../../../services/settings.service";
import { setEndDateOfADT } from "../../../store/reducers/common.slice";
import { getEndDateOfADTData } from "../../../services/api/facility-manually-end-date-adt";

const defaultDateFormat = "MM-DD-YYYY";

const EditEndDateInputDialog = (props) => {
    const dispatch = useDispatch();
    const defaultErr = { endDateOfADT: null };
    const [errors, setErrors] = useState(defaultErr);
    const [endDateADTData, setEndDateADTData] = useState({ endDateOfADT: null });
    const [loading, setLoading] = useState(false);
    // const [earliestDate, setEarliestDate] = useState(null);
    const [dbLoading, setDbLoading] = useState(false);
    const [manuallyEndDateOfADT, setManuallyEndDateOfADT] = useState([]);
    const [facilityLatestEndDateOfADT, setFacilityLatestEndDateOfADT] = useState([]);
    const { activeFacilities } = props;
    // console.log(props?.firstAndLastData, 'props.firstAndLastData');
    // console.log(earliestDate, 'earliestDate');

    useEffect(() => {
        async function fetchMyAPI() {
            setTimeout(() => {
                setDbLoading(true);
            }, 50);
            const res = await getEndDateOfADTData({ facilityIds: activeFacilities });
            if (res && res.latestEndDateOfADTManually.length > 0) {
                if (activeFacilities.length > 1) {
                    setManuallyEndDateOfADT(res.latestEndDateOfADTManually);
                } else {
                    const latestUpdatedEndOfADTArr = [...res?.latestEndDateOfADTManually ?? [], ...res?.facilityLatestEndDateOfADT ?? []];
                    setManuallyEndDateOfADT(latestUpdatedEndOfADTArr);
                }
            }
            if (res && res.facilityLatestEndDateOfADT.length > 0) {
                setFacilityLatestEndDateOfADT(res.facilityLatestEndDateOfADT);
            }
            setDbLoading(false);
        }
        fetchMyAPI()
    }, [activeFacilities, setDbLoading])

    useEffect(() => {
        if (manuallyEndDateOfADT.length > 0) {
            let earliestDateData;
            if (activeFacilities.length > 1) {
                earliestDateData = manuallyEndDateOfADT?.reduce((earliest, current) => {
                    return moment(earliest).isBefore(moment(current.endDateOfADT)) ? earliest : current.endDateOfADT;
                }, manuallyEndDateOfADT[0]?.endDateOfADT);
            } else {
                earliestDateData = manuallyEndDateOfADT?.reduce((earliest, current) => {
                    return moment(earliest).isAfter(moment(current.endDateOfADT)) ? earliest : current.endDateOfADT;
                }, manuallyEndDateOfADT[0]?.endDateOfADT);
            }
            // setEarliestDate(earliestDateData);
            setEndDateADTData((prevState) => ({
                ...prevState,
                endDateOfADT: toDisplayTime(earliestDateData, defaultDateFormat),
            }));
        }
        setDbLoading(false);
    }, [activeFacilities.length, manuallyEndDateOfADT]);

    // useEffect(() => {
    //     setEndDateADTData((prevState) => ({
    //         ...prevState,
    //         endDateOfADT: props?.endDateOfADT ? toDisplayTime(props?.endDateOfADT, defaultDateFormat) : null,
    //     }));
    // }, [props.endDateADT, props?.endDateOfADT]);

    const save = async () => {
        setLoading(true);
        let errorData = await validateEndDateADT(endDateADTData.endDateOfADT, facilityLatestEndDateOfADT);
        if (!_.isEmpty(errorData)) {
            setErrors(errorData);
            setLoading(false);
            return;
        }
        setErrors(defaultErr);

        await createEndDateOfADT({ endDateOfADT: toSaveDate(endDateADTData.endDateOfADT), facilityIds: activeFacilities });
        dispatch(setEndDateOfADT(endDateADTData.endDateOfADT));

        dispatch({
            type: ADD_NOTIFICATION,
            payload: {
                label: "End Date ADT updated successfully!",
                type: "success",
                id: 'endDateUpdateSuccess'
            },
        });
        props.close();

        setLoading(false);
    };

    const handleOnChange = useCallback(
        (field, value) => {
            setEndDateADTData((state) => ({
                ...state,
                [field]: value,
            }));
            setErrors((state) => ({
                ...state,
                [field]: null,
            }));
        },
        [setEndDateADTData]
    );

    const handleClose = useCallback(() => {
        props.close();
    }, [props]);

    return (
        <>
            <div className={styles.overlay}></div>
            <div className={styles.addPopup}>
                <div className={`df aic ${styles.hdr}`}>
                    <p className={`ffmm fs18`}>Update end date ADT information</p>
                    <div className={`mla`}>
                        <div className={`df acc ${styles.closeBtn}`} onClick={handleClose}>
                            <CloseIcon />
                        </div>
                    </div>
                </div>{" "}
                <div className={styles.popupCntnt}>
                    {/* {dbLoading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '85px' }}>
                        <CircularProgress />
                    </div>} */}
                    {/* {!dbLoading && ( */}
                    <div className={`m-b-20 inputWrpr`}>
                        <label className={`ffmr fs12`}>End Date ADT</label>
                        <Cleave
                            style={{ fontSize: "16px", fontFamily: "mont light" }}
                            options={{
                                date: true,
                                delimiter: "-",
                                datePattern: ["m", "d", "Y"],
                            }}
                            placeholder={defaultDateFormat}
                            value={endDateADTData.endDateOfADT}
                            onChange={(e) => handleOnChange("endDateOfADT", e.target.value)}
                            disabled={dbLoading}
                        />
                        {errors.endDateOfADT !== null && (
                            <div className="error fs12 mt-1">{errors.endDateOfADT}</div>
                        )}
                    </div>
                    {/* )} */}
                </div>
                <div className={`${styles.popupFtr}`}>
                    <div className={`df aic ${styles.actionsWrrp}`}>
                        <div className={`mla`}>
                            <button
                                className={`ffmr fs14 m-r-8 ${styles.text}`}
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <LoadingButton
                                variant="contained"
                                onClick={save}
                                loading={loading}
                                disabled={!endDateADTData.endDateOfADT || dbLoading}
                            >
                                Update
                            </LoadingButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditEndDateInputDialog;

// Function to validate end date ADT
function validateEndDateADT(date, facilityLatestEndDateOfADT) {
    const facilityLatestDate = facilityLatestEndDateOfADT?.reduce((latest, item) => {
        const currentDate = moment(item.endDateOfADT);
        return currentDate.isAfter(latest) ? currentDate : latest;
    }, moment(facilityLatestEndDateOfADT[0]?.endDateOfADT));
    let errorData = {};
    const dateWithFormat = toSaveDate(date);
    if (!date) {
        errorData.endDateOfADT = "End Date ADT is required";
    } else if (!moment(date, defaultDateFormat, true).isValid()) {
        errorData.endDateOfADT = "Date is not valid";
    } else if (moment(dateWithFormat).isAfter(moment(), 'day') || moment(dateWithFormat).isSame(moment(), 'day')) {
        errorData.endDateOfADT = "Canot enter todays date or later";
    } else if (moment(dateWithFormat).isBefore(moment(facilityLatestDate), 'day')) {
        errorData.endDateOfADT = "Canot enter earlier than the end of ADT date";
    }
    return errorData;
}
