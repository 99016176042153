import {
    getFiltersData,
    removeFilterData,
    saveFilterData,
    savePercentageAgainstData,
    getPercentageAgainstData,
    removePercentageAgainstData
} from "./api/filter.api";

const getFilters = async (type) => {
    try {
        const res = await getFiltersData(type);
        return res;
    } catch (e) {
        console.log(e);
    }
};

const getPercentageAgainstAll = async (type) => {
    try {
        const res = await getPercentageAgainstData(type);
        return res;
    } catch (e) {
        console.log(e);
    }
};

const saveFilter = async (body) => {
    try {
        const res = await saveFilterData(body);
        return res;
    } catch (e) {
        console.log(e.message);
    }
};

const savePercentageAgainst = async (body) => {
    try {
        const res = await savePercentageAgainstData(body);
        return res;
    } catch (e) {
        console.log(e.message);
        return e?.response?.data
    }
};

const removeFilter = async (id) => {
    try {
        const res = await removeFilterData(id);
        return res;
    } catch (e) {
        console.log(e);
    }
};

const removePercentageAgainst = async (id) => {
    try {
        const res = await removePercentageAgainstData(id);
        return res;
    } catch (e) {
        console.log(e);
    }
};

export {
    removePercentageAgainst,
    getFilters,
    removeFilter,
    saveFilter,
    savePercentageAgainst,
    getPercentageAgainstAll,
};
