import { Badge, IconButton, ListItemIcon, ListItemText, MenuItem, MenuList, Switch } from "@mui/material";
import Settings from '@mui/icons-material/Settings';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { PAGE_TYPE } from "../../../types/pages.type";
import PickProjectionDaysSVG from "../../../assets/svgs/PickProjectionDaysSVG.svg";
import CompareSideIcon from "../../../assets/svgs/CompareSideIcon.svg";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { setRemoveCompareFilter } from "../../../store/reducers/comparisonReducers/comparison.slice";
import HtmlTooltip from "../HtmlTooltip";
import ClearIcon from "@mui/icons-material/Clear";
import { setProjectionDays } from "../../../store/reducers/common.slice";

const TooltipBadge = ({ content, onClick, children }) => (
    <Badge
        color="error"
        sx={{
            cursor: "pointer",
            "& .MuiBadge-badge": {
                minWidth: "14px",          // Smaller width for the badge
                height: "14px",            // Smaller height for the badge
                borderRadius: "50%",       // Make the badge round
                transform: "translate(50%, -50%) scale(1.2)",  // Position and scale down
                padding: 0,                // Remove padding for a snug fit
            },
        }}
        badgeContent={
            <HtmlTooltip content={content} placement="bottom-start">
                <ClearIcon onClick={onClick} sx={{ fontSize: 10 }} />
            </HtmlTooltip>
        }
    >
        {children}
    </Badge>
);

const MenuSwitchItem = ({
    icon,
    label,
    onClick,
    hasSwitch = true,
    handleClose,
    checked = false,
    handleSaveShortcut,
    name
}) => {
    // Function to handle the Switch toggle without triggering MenuItem's onClick
    const handleSwitchToggle = (e) => {
        e.stopPropagation(); // Prevents bubbling up to MenuItem
        handleSaveShortcut(e);
    };

    return (
        <MenuItem>
            <ListItemIcon
                onClick={() => {
                    onClick();
                    handleClose(); // Only close the menu when the icon is clicked
                }}
            >
                {icon}
            </ListItemIcon>
            <ListItemText
                onClick={() => {
                    onClick();
                    handleClose(); // Only close the menu when the text is clicked
                }}
            >
                {label}
            </ListItemText>
            {hasSwitch && (
                <Switch
                    size="small"
                    name={name}
                    checked={checked}
                    onChange={handleSwitchToggle} // Separate handler for the Switch
                />
            )}
        </MenuItem>
    );
};

export default function HeaderMenuItem({
    handleClose,
    page,
    handleOnclickTimeFrameConvert,
    handleOnclickCompare,
    handleOnclickOpenAutoReport,
    handleOnclickOpenAlertReport,
    rangesSet,
    projectionDays,
    handleSaveShortcut,
    selectedSortCut
}) {

    const dispatch = useDispatch();

    const handleCloseDateRange = useCallback((e) => {
        e.preventDefault();
        handleClose();
        dispatch(setRemoveCompareFilter(false));
    }, [dispatch, handleClose]);

    return (
        <MenuList>
            {page === PAGE_TYPE.HOSPITAL && (
                <MenuSwitchItem
                    icon={<NotificationsActiveOutlinedIcon />}
                    label="Manage Alerts"
                    onClick={handleOnclickOpenAlertReport}
                    handleClose={handleClose}
                    handleSaveShortcut={handleSaveShortcut}
                    checked={selectedSortCut.isAlertsReport}
                    name="isAlertsReport"
                />
            )}
            <MenuSwitchItem
                icon={
                    projectionDays !== null ? (
                        <TooltipBadge
                            content="Remove Projection Filter"
                            onClick={(e) => {
                                handleClose();
                                e.stopPropagation();
                                dispatch(setProjectionDays(null));
                            }}
                        >
                            <IconButton
                                sx={{
                                    width: 30,
                                    height: 30,
                                    ml:"-5px",
                                    backgroundColor: '#4879f5',
                                    borderRadius: "4px",
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                                    },
                                    color: '#fff',
                                }}
                            >
                                <PickProjectionDaysSVG />
                            </IconButton>
                        </TooltipBadge>
                    ) : (
                        <PickProjectionDaysSVG />
                    )
                }
                label="Time Frame Convert"
                onClick={handleOnclickTimeFrameConvert}
                handleClose={handleClose}
                handleSaveShortcut={handleSaveShortcut}
                checked={selectedSortCut.isPickProjectionDays}
                name="isPickProjectionDays"
            />
            <MenuSwitchItem
                icon={
                    rangesSet ? (
                        <TooltipBadge
                            content="Remove Compare Filter"
                            onClick={handleCloseDateRange}
                        >
                            <IconButton
                                sx={{
                                    width: 30,
                                    height: 30,
                                    ml:"-5px",
                                    backgroundColor: '#4879f5',
                                    borderRadius: "4px",
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                                    },
                                    color: '#fff',
                                }}
                            >
                                <CompareSideIcon width={18} height={18} />
                            </IconButton>
                        </TooltipBadge>
                    ) : (
                        <CompareSideIcon />
                    )
                }
                label="Compare & Highlight"
                onClick={handleOnclickCompare}
                handleClose={handleClose}
                handleSaveShortcut={handleSaveShortcut}
                checked={selectedSortCut.isCompare}
                name="isCompare"
            />
            <MenuSwitchItem
                icon={<Settings />}
                label="Automatic Reports"
                onClick={handleOnclickOpenAutoReport}
                handleClose={handleClose}
                handleSaveShortcut={handleSaveShortcut}
                checked={selectedSortCut.isAutoReport}
                name="isAutoReport"
            />
        </MenuList>
    );
}
