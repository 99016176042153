import { Box, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import styles from "./ColorBoxes.module.scss";

const TooltipTitle = ({ comparingAgainst, comparingAgainstScaled, numberOfDays }) => {
	return (
		<div className={styles.tooltipWrapper}>
			<div>
				<div>
					Compared to: <span className={styles.comparingAgainstSpan}>{comparingAgainst || 0}</span>
				</div>
			</div>
			{numberOfDays ?? comparingAgainstScaled ? (
				<div>
					<div>
						{numberOfDays} days projection:{" "}
						<span className={styles.comparingAgainstSpan}>({comparingAgainstScaled || 0})</span>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default function ColorBox({
	color,
	type = "div",
	barWidth,
	bar = null,
	sx,
	comparingAgainst,
	comparingAgainstScaled,
	numberOfDays,
}) {
	const { rangesSet } = useSelector((state) => state.comparison);
	if (color && rangesSet) {
		switch (type) {
			case "button":
				return (
					<Tooltip
						title={
							<TooltipTitle
								comparingAgainst={comparingAgainst}
								comparingAgainstScaled={comparingAgainstScaled}
								numberOfDays={numberOfDays}
							/>
						}
						arrow
					>
						<Box
							component={"div"}
							sx={{
								background: color,
								width: "9px",
								height: "18px",
								borderRadius: "2px",
								position: "absolute",
								left: "3px",
								...sx,
							}}
						/>
					</Tooltip>
				);
			case "div":
				return (
					<Tooltip
						title={
							<TooltipTitle
								comparingAgainst={comparingAgainst}
								comparingAgainstScaled={comparingAgainstScaled}
								numberOfDays={numberOfDays}
							/>
						}
						arrow
					>
						<Box
							component={"div"}
							sx={{
								display: "inline-block",
								background: color,
								width: "11px",
								height: "27px",
								borderRadius: "2px",
								ml: 0.3,
								...sx,
							}}
						></Box>
					</Tooltip>
				);

			case "bar":
				return (
					<Tooltip
						title={
							<TooltipTitle
								comparingAgainst={comparingAgainst}
								comparingAgainstScaled={comparingAgainstScaled}
								numberOfDays={numberOfDays}
							/>
						}
						arrow
					>
						<rect
							width="9px"
							height="18px"
							fill={color}
							y={7}
							x={barWidth / 2 - 35}
							//transform={`translate(${barWidthCount},7)`}
						/>
					</Tooltip>
				);

			case "dayBar":
				return (
					<Tooltip
						title={
							<TooltipTitle
								comparingAgainst={comparingAgainst}
								comparingAgainstScaled={comparingAgainstScaled}
								numberOfDays={numberOfDays}
							/>
						}
						arrow
					>
						<rect width="24px" height="9px" x={bar.width / 4} y={bar.height / 2 + 10} fill={color} />
					</Tooltip>
				);
			case "list":
				return (
					<Tooltip
						title={
							<TooltipTitle
								comparingAgainst={comparingAgainst}
								comparingAgainstScaled={comparingAgainstScaled}
								numberOfDays={numberOfDays}
							/>
						}
						arrow
					>
						<Box
							component={"div"}
							sx={{
								display: "inline-block",
								background: color,
								width: "9px",
								height: "18px",
								borderRadius: "2px",
								...sx,
							}}
						/>
					</Tooltip>
				);

			default:
				return null;
		}
	}
	return null;
}
