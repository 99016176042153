import { SET_PERMISSIONS } from "../types";

const reducer = (state = ["DELETE"], action) => {
  switch (action.type) {
    case SET_PERMISSIONS:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;









































