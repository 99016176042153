import { LoadingButton } from "@mui/lab";
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, FormLabel, FormControl, Typography, Grid } from '@mui/material';
import { forgotPasswordLink } from "../../services/user.service";
import { ForgotPasswordSchema } from "../../validations/ForgotPasswordSchema";
import { Container } from "@mui/system";
import TempLogo from "../../assets/svgs/temp-logo.svg";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
    const formIk = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: ForgotPasswordSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true)
            await forgotPasswordLink(values).then(() => {
                setSubmitting(false);
                resetForm()
            }).catch((err) => {
                setSubmitting(false)
            })
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formIk;

    return (
        <Container maxWidth="sm">
            <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '80vh' }}
            >
                <Grid sx={{ padding: 5 }}>
                    <TempLogo />
                </Grid>
                <Grid>
                    <Typography variant="h5">Forgot password</Typography>
                </Grid>
                <Grid sx={{ mt: 4 }} width="80%">
                    <FormikProvider value={formIk}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Stack spacing={2} maxWidth="sm">
                                <Stack spacing={3}>
                                    <FormControl>
                                        <FormLabel>Enter Your Registered Email</FormLabel>
                                        <TextField
                                            {...getFieldProps('email')}
                                            fullWidth
                                            autoComplete="off"
                                            type={"text"}
                                            error={Boolean(touched.email && errors.email)}
                                            helperText={(touched.email && errors.email)}
                                        />
                                    </FormControl>
                                </Stack>
                                <Stack>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting}
                                        loadingIndicator="Loading..."
                                        size="large"
                                    >
                                        Send Email
                                    </LoadingButton>
                                </Stack>
                            </Stack>
                        </Form>
                    </FormikProvider>
                </Grid>
                <Grid sx={{ mt: 2 }}>
                    <Link
                        to="/login"
                        className={`df aic ffmar fs15 `}
                    >
                        Login
                    </Link>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ForgotPassword;
