import * as React from 'react';
import { Box, Grid, TextareaAutosize, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Form, useFormik, FormikProvider } from 'formik';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/system';


export default function AddQuestionHighlighter({
    handleClose,
    title = '',
    handleOnSubmit,
    selectedValue,
    handleDelete
}) {
    const defaultValues = {
        color: selectedValue?.color || '',
        fieldName: selectedValue?.fieldName,
        description: selectedValue?.description || '',
    };

    const formik = useFormik({
        initialValues: defaultValues,
        onSubmit: async (values, { setSubmitting }) => {
            if (values?.color) {
                handleOnSubmit(values);
                setSubmitting(false);
            }
        }
    });

    const { isSubmitting, handleSubmit, setFieldValue, values, submitCount } = formik;
    const isFormSubmitted = submitCount > 0;

    const handleChange = React.useCallback((newValue) => {
        setFieldValue("color", newValue);
    }, [setFieldValue]);

    return (
        <Dialog
            sx={{ zIndex: 2000 }}
            maxWidth="sm"
            fullWidth={true}
            open={true}
            hideBackdrop={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    {title && (
                        <DialogTitle id="alert-dialog-title">
                            {title}
                        </DialogTitle>
                    )}
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Grid container direction={'row'} spacing={2}>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={2}>
                                        <Typography sx={{ mr: 2 }}>Select color:</Typography>
                                    </Stack>
                                    <Grid container spacing={1} sx={{ mt: 1, mb: 1 }}>

                                        {['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF', '#00FFFF', '#FFA500', '#800080', '#008000', '#000080', '#800000', '#808080'].map((color, index) => (
                                            <Grid item xs={1} key={index}>
                                                <Box
                                                    sx={{
                                                        width: 30,
                                                        height: 30,
                                                        backgroundColor: color,
                                                        borderRadius: '50%',
                                                        border: values?.color === color ? '2px solid blue' : '1px solid #ccc',
                                                        cursor: 'pointer',
                                                        marginRight: 1,
                                                        marginBottom: 1
                                                    }}
                                                    onClick={() => handleChange(color)}
                                                />
                                            </Grid>
                                        ))}
                                        {(!values?.color && isFormSubmitted) && <Typography sx={{ ml: 1, mt: 1 }} color="error">Please select color</Typography>}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="standard-basic-textarea1"
                                        multiline
                                        rows={4}
                                        label="Highlighter note"
                                        fullWidth={true}
                                        name="description"
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                height: "100%",
                                                borderRadius: "4px",
                                            },
                                        }}
                                        InputProps={{
                                            inputComponent: TextareaAutosize,
                                        }}
                                        value={values?.description}
                                        onChange={(e) => setFieldValue("description", e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ px: 2, py: 2 }}>
                        {selectedValue?.isEdit && (
                            <Button
                                type="button"
                                onClick={() => handleDelete(selectedValue)}
                                color="error"
                                variant='contained'
                                sx={{ mr: 2 }}
                            >
                                Delete
                            </Button>
                        )}
                        <Button
                            type="button"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>

                        <LoadingButton
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                            loadingIndicator="Loading..."
                        >
                            Save
                        </LoadingButton>
                    </DialogActions>
                </Form>
            </FormikProvider >
        </Dialog >
    );
}
