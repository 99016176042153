import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, TextField } from '@mui/material';
/* eslint-disable no-useless-escape */

export default function CalculatorInput({
    handleClose,
    title = 'Maths Calculator',
    handleSubmit,
}) {
    const [value, setValue] = React.useState('');
    const [result, setResult] = React.useState(null);

    const handleValueChange = (e) => {
        const value = e.target.value;
        if (!value) {
            setResult(null);
            setValue('');
            return;
        }
        const sanitizedValue = value.replace(/[^0-9+\-\*\/\.\(\)\s]/g, '');
        setValue(sanitizedValue);
        try {
            /* eslint-disable no-eval */
            const result = sanitizedValue ? eval(sanitizedValue) : null;
            setResult(result);
        } catch (error) {
            console.error('Invalid formula');
            setResult(null);
        }
    };

    return (
        <Dialog
            sx={{ zIndex: 9999 }}
            open={true}
            maxWidth={"md"}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {title && (
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
            )}
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Grid container spacing={2} mt={0.1} alignItems={"center"}>
                        <Grid item xs={8}>
                            <TextField
                                label="Enter a mathematical formula"
                                variant="outlined"
                                fullWidth
                                value={value}
                                onChange={handleValueChange}
                            />
                        </Grid>
                        <Grid item xs={0.5}>=</Grid>
                        <Grid item xs={3.5}>
                            <TextField
                                fullWidth
                                label="Result"
                                variant="outlined"
                                value={result ? result : ''}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant='contained' onClick={() => handleSubmit(result)}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
