import { Fragment } from "react";
import { Divider, Typography } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import TotalCountPercentage from "../totalCount/TotalCountPercentage";
import CardItemTooltip from "../../shared/CardItemTooltip";
import { CustomeListItem } from "./UserList";

const UserListSkeleton = () => {
	const dataForSkeleton = [1, 2, 3, 4];

	return dataForSkeleton.map((x) => {
		return (
			<Fragment key={x}>
				<CustomeListItem
					selected={false}
					secondaryAction={
						<Fragment>
							<TotalCountPercentage loading={true} />
							<Checkbox
								disableRipple
								onChange={(e) => undefined}
								checked={false}
								disabled
								size="small"
								className="skeleton"
								style={{ borderRadius: 5, marginLeft: "8px", marginRight: "-22px", padding: "0" }}
							/>
						</Fragment>
					}
				>
					<ListItemButton disableRipple style={{ cursor: "default" }}>
						<ListItemAvatar sx={{ height: "36px" }}>
							<div className="skeleton" style={{ width: "36px", height: "36px", borderRadius: "999px" }}></div>
						</ListItemAvatar>
						<ListItemText
							primary={
								<CardItemTooltip>
									<Typography variant="subtitle1" className="skeleton" style={{ borderRadius: 3, width: "90%" }}>
										<div style={{ visibility: "hidden" }}>a</div>
									</Typography>
								</CardItemTooltip>
							}
						/>
					</ListItemButton>
				</CustomeListItem>
				<Divider variant="middle" component="li" />
			</Fragment>
		);
	});
};

export default UserListSkeleton;
