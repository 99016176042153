import axios from "../../axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import styles from "./Settings.module.scss";
import { Outlet } from "react-router-dom";
const SettingsPage = (props) => {
  const { auth } = useSelector(({ auth }) => ({ auth }));

  // const navigate = useNavigate();

  const [accounts, setAccounts] = useState([]);

  const getAccount = async () => {
    let re = await axios.get(
      `/api/account${
        auth?.type?.toLowerCase().includes("super") ? "" : `/${auth?.accountId}`
      }`
    );
    if (auth?.type?.toLowerCase().includes("super")) {
      setAccounts(re.data);
    } else {
      setAccounts([re.data]);
    }
  };

  useEffect(() => {
    if (!accounts.length) return;
    // if (accounts.length === 1) {
    //   navigate(`/${accounts[0]._id}`);
    // }
    // eslint-disable-next-line
  }, [accounts]);

  useEffect(() => {
    if (!auth?.accountId) return;
    getAccount();
    // eslint-disable-next-line
  }, [auth]);

  return (
    <div className={styles.settingsPage}>
      <Outlet />
    </div>
  );
};

export default SettingsPage;
