import { Button, LinearProgress, Stack } from "@mui/material";
import * as React from "react";
import CardItemTooltip from "../../../shared/CardItemTooltip";
import ColorBox from "../../../shared/dashboard/ColorBox";

const HospitalizationsProgressBar = ({
	total,
	percentage,
	btnBgColor,
	comparingAgainstScaled,
	progressColor,
	progressHeight = 12,
	className,
	selected,
	isPercentageShow = true,
	isCompareColor = true,
	comparisonColor,
	comparingAgainst,
	numberOfDays,
	item,
	loading,
}) => {
	return (
		<Stack direction={"row"} sx={{ width: "80%" }} className={className}>
			<Stack direction={"column"}>
				<Button
					variant="contained"
					size="small"
					sx={{
						background: btnBgColor,
						minWidth: "70px",
					}}
					className={`${selected ? "selected" : ""}`}
				>
					{!loading && (
						<ColorBox
							sx={{ left: "-13px" }}
							color={comparisonColor}
							type="button"
							comparingAgainst={comparingAgainst}
							comparingAgainstScaled={comparingAgainstScaled}
							numberOfDays={numberOfDays}
						/>
					)}
					<CardItemTooltip item={item} key={item?._id + "_total"}>
						<p>
							{total} {isPercentageShow && percentage}
						</p>
					</CardItemTooltip>
				</Button>
			</Stack>
			<Stack
				direction={"column"}
				sx={{ width: "100%", ml: 2, mb: 0.7 }}
				justifyContent="flex-end"
				className="progress-box"
			>
				<CardItemTooltip item={item} key={item?._id}>
					<LinearProgress
						value={+progressHeight}
						sx={{
							height: 10,
							borderRadius: "0px 6px 6px 0px",
							"& .MuiLinearProgress-bar": {
								backgroundColor: !selected ? progressColor : "#4879F5",
							},
						}}
						variant="determinate"
					/>
				</CardItemTooltip>
			</Stack>
		</Stack>
	);
};

export default HospitalizationsProgressBar;
