export const ADMISSION_CARDS_TYPE = {
    DOCTOR_DATA: 'doctorData',
    DAYS_DATA: 'daysData',
    DX_DATA: 'dxData',
    INSURANCE_DATA: 'insuranceData',
    FLOORS_DATA: 'floorsData',
    HOSPITAL_DATA: 'hospitalData',
    SIXTY_DAYS_DATA: 'sixtyDaysData',
    TOTAL: "total",
    ADMISSION: "admission",
    READMISSION: "readmission",
    TOTAL_ADMISSIONS: "totalAdmissions",
    TOTAL_READMISSIONS: "totalReAdmissions",
    ADT_TAB: "adt"
}

export const ADMISSION_FILTER_TYPE = {
    ADT: "ADT",
    ADMISSION: "Admission",
    MANUAL: "Manual"
}
