const NewCloseSVG = (props) => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.998535" width="30" height="30" rx="6" fill="#E7B400" />
    <path
      d="M21.057 18.2604L17.6857 14.8891L21.057 11.5178C21.4292 11.1456 21.4292 10.5415 21.057 10.1693L20.6075 9.71982C20.2353 9.34763 19.6312 9.34762 19.259 9.71982L15.8877 13.0911L12.5164 9.71982C12.1442 9.34762 11.5401 9.34763 11.1679 9.71982L10.7184 10.1693C10.3462 10.5415 10.3462 11.1456 10.7184 11.5178L14.0897 14.8891L10.7184 18.2604C10.3462 18.6326 10.3462 19.2367 10.7184 19.6089L11.1679 20.0584C11.5401 20.4306 12.1442 20.4306 12.5164 20.0584L15.8877 16.6871L19.259 20.0584C19.6312 20.4306 20.2353 20.4306 20.6075 20.0584L21.057 19.6089C21.4292 19.2367 21.4292 18.6326 21.057 18.2604Z"
      fill="white"
    />
  </svg>
);

export default NewCloseSVG;
