import styles from "./HospitalSlider.module.scss";
import { Card, CardContent, Grid } from "@mui/material";
import Slider from "react-slick";
import AsstLivingRedialChart from "../../community-transfer/safeDischargeAssLivingSlider/AsstLivingRedialChart";
import classNames from "classnames";

const HospitalSliderSkeleton = () => {
	const fakeData = [1, 2, 3, 4, 5];

	return (
		<div className={"no-return-slider"}>
			<Slider arrows={false} infinite={true} swipe={false}>
				{fakeData.map((ele, index) => {
					return (
						<Card className={classNames(styles.slideCard, styles.skeleton)} key={"row" + index}>
							<CardContent className={classNames(styles.slideCardContent, "skeleton")}>
								<Grid container direction={"row"}>
									<Grid item xs={8}></Grid>
									<Grid item xs={4}>
										<AsstLivingRedialChart data={ele.graphData} />
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					);
				})}
			</Slider>
		</div>
	);
};

export default HospitalSliderSkeleton;
