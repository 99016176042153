import * as React from 'react';
import { CircularProgress, Stack } from '@mui/material';

export default function ChartTabLoader({ loading }) {
    if (loading) {
        return (
            <Stack
                direction={"row"}
                sx={{
                    alignItems: "start",
                    justifyContent: "center",
                    mt: 5,
                    width: "100%",
                }}
            >
                <CircularProgress />
            </Stack>
        )
    } else {
        return null
    }
}
