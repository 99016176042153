import { toDisplayTime } from "../utilis/date-formats"

export const PAGE_TYPE = {
    COMMUNITY_TRANSFER: 'communityTransfer',
    ADMISSION: 'admission',
    HOSPITAL: 'hospital',
    DECEASED: 'deceased',
    OVERALL: 'overall',
    ADT: 'adt',
}

export const ACCOUNT_PERCENTAGE_BY = {
    CENSUS: 'census',
    BED: 'bed',    
}

export const OVERALL_PAGE_SUB_TYPE = {
    TOTAL: 'total',
    TOTAL_INCOMING: 'totalIncoming',
    TOTAL_OUTGOING: 'totalOutgoing',
}

export const expandedColumnsDefault = [
    {
        Header: "Last Name",
        accessor: "lastName",
        isDefault: true
    },
    {
        Header: "First Name",
        accessor: "firstName",
        isDefault: true
    },

    {
        Header: "MIddle Initial",
        accessor: "middleInitial",
        isDefault: true
    },

    {
        Header: "suffix",
        accessor: "suffix",
        isDefault: true
    },
    {
        Header: "DOB",
        accessor: "DOB",
        Cell: ({ value }) => <div>{toDisplayTime(value)}</div>,
        isDefault: true
    },
    {
        Header: "Type",
        accessor: "type",
        disableSortBy: true,
        Cell: ({ row }) => (
            <div>
                {row.original.type === "transfer"
                    ? row.original.transferType
                        .replace(/([A-Z])/g, " $1")
                        // uppercase the first character
                        .replace(/^./, function (str) {
                            return str.toUpperCase();
                        })
                    : row.original.type.replace(/^./, function (str) {
                        return str.toUpperCase();
                    })}
            </div>
        ),
    }
]