import { saveCustomeCardFilter, getCustomeCardFilters } from "../../services/custom-card.service";

const useCustomCardFilter = () => {

    const getCustomCardFilters = async (props) => {
        return await getCustomeCardFilters();
    }

    const saveCustomCardFilter = async (props) => {
        return await saveCustomeCardFilter({ ...props });
    }

    return {
        getCustomCardFilters,
        saveCustomCardFilter,
    };
}

export default useCustomCardFilter;
