const ExpandSVG = props => (
  <svg
    width={props.width || "67"}
    height={props.height || "66"}
    viewBox="0 0 67 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_883_5526)">
      <rect x="8" y="5" width="51" height="50" rx="8" fill="white" />
    </g>
    <g clipPath="url(#clip0_883_5526)">
      <path
        d="M36.0432 25.9229H30.9572C29.8337 25.9229 28.9229 26.8337 28.9229 27.9572V33.0432C28.9229 34.1667 29.8337 35.0775 30.9572 35.0775H36.0432C37.1667 35.0775 38.0775 34.1667 38.0775 33.0432V27.9572C38.0775 26.8337 37.1667 25.9229 36.0432 25.9229Z"
        fill="#4879F5"
      />
      <path
        d="M44.1636 17.9781L41.3646 20.7771L40.5527 18.6609C40.2991 17.8755 39.4496 18.1275 39.3745 18.7254C39.235 19.8348 39.1124 22.8766 39.062 24.2591C39.0587 24.3487 39.0739 24.4381 39.1067 24.5216C39.1395 24.6051 39.1892 24.681 39.2527 24.7444C39.3161 24.8079 39.3919 24.8575 39.4754 24.8903C39.5589 24.9231 39.6483 24.9384 39.738 24.9351C41.1205 24.8847 44.1622 24.7621 45.2717 24.6226C45.8844 24.5456 46.1069 23.6932 45.3361 23.4444L43.22 22.6325L46.019 19.8334C46.0795 19.7729 46.1276 19.701 46.1603 19.6219C46.1931 19.5428 46.21 19.4581 46.21 19.3724C46.21 19.2868 46.1931 19.2021 46.1603 19.123C46.1276 19.0439 46.0795 18.972 46.019 18.9115L45.0856 17.9781C45.0251 17.9175 44.9532 17.8695 44.8741 17.8367C44.795 17.804 44.7102 17.7871 44.6246 17.7871C44.539 17.7871 44.4542 17.804 44.3751 17.8367C44.296 17.8695 44.2242 17.9175 44.1636 17.9781Z"
        fill="#4879F5"
      />
      <path
        d="M20.9781 19.8364L23.7771 22.6354L21.6609 23.4473C20.8755 23.7009 21.1275 24.5504 21.7254 24.6255C22.8348 24.765 25.8766 24.8876 27.2591 24.938C27.3487 24.9413 27.4381 24.9261 27.5216 24.8933C27.6051 24.8605 27.681 24.8108 27.7444 24.7473C27.8079 24.6839 27.8575 24.6081 27.8903 24.5246C27.9232 24.441 27.9384 24.3517 27.9351 24.262C27.8847 22.8795 27.7621 19.8378 27.6226 18.7283C27.5456 18.1156 26.6932 17.8931 26.4444 18.6638L25.6325 20.78L22.8334 17.981C22.7729 17.9205 22.701 17.8724 22.6219 17.8397C22.5428 17.8069 22.4581 17.79 22.3724 17.79C22.2868 17.79 22.2021 17.8069 22.123 17.8397C22.0439 17.8724 21.972 17.9205 21.9114 17.981L20.9781 18.9144C20.9175 18.9749 20.8695 19.0468 20.8367 19.1259C20.804 19.205 20.7871 19.2898 20.7871 19.3754C20.7871 19.461 20.804 19.5458 20.8367 19.6249C20.8695 19.704 20.9175 19.7758 20.9781 19.8364Z"
        fill="#4879F5"
      />
      <path
        d="M22.8364 43.0219L25.6354 40.2229L26.4473 42.3391C26.7009 43.1245 27.5504 42.8725 27.6255 42.2746C27.765 41.1652 27.8876 38.1234 27.938 36.7409C27.9413 36.6513 27.9261 36.5619 27.8933 36.4784C27.8605 36.3949 27.8108 36.319 27.7473 36.2556C27.6839 36.1921 27.6081 36.1425 27.5246 36.1097C27.441 36.0768 27.3517 36.0616 27.262 36.0649C25.8795 36.1153 22.8378 36.2379 21.7283 36.3774C21.1156 36.4544 20.8931 37.3068 21.6638 37.5556L23.78 38.3675L20.981 41.1666C20.9205 41.2271 20.8724 41.299 20.8397 41.3781C20.8069 41.4572 20.79 41.5419 20.79 41.6275C20.79 41.7132 20.8069 41.7979 20.8397 41.877C20.8724 41.9561 20.9205 42.028 20.981 42.0885L21.9144 43.0219C22.0366 43.1442 22.2025 43.2129 22.3754 43.2129C22.5483 43.2129 22.7141 43.1442 22.8364 43.0219Z"
        fill="#4879F5"
      />
      <path
        d="M46.0219 41.1636L43.2229 38.3646L45.3391 37.5527C46.1245 37.2991 45.8725 36.4496 45.2746 36.3745C44.1652 36.235 41.1234 36.1123 39.7409 36.062C39.6513 36.0587 39.5619 36.0739 39.4784 36.1067C39.3949 36.1395 39.319 36.1892 39.2556 36.2527C39.1921 36.3161 39.1425 36.3919 39.1097 36.4754C39.0768 36.5589 39.0616 36.6483 39.0649 36.738C39.1153 38.1205 39.2379 41.1622 39.3774 42.2717C39.4544 42.8844 40.3068 43.1069 40.5556 42.3361L41.3675 40.22L44.1666 43.019C44.2271 43.0795 44.299 43.1276 44.3781 43.1603C44.4572 43.1931 44.5419 43.21 44.6275 43.21C44.7132 43.21 44.7979 43.1931 44.877 43.1603C44.9561 43.1276 45.028 43.0795 45.0885 43.019L46.0219 42.0856C46.1442 41.9633 46.2129 41.7975 46.2129 41.6246C46.2129 41.4517 46.1442 41.2859 46.0219 41.1636Z"
        fill="#4879F5"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_883_5526"
        x="0"
        y="0"
        width="67"
        height="66"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_883_5526" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_883_5526"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_883_5526">
        <rect width="27" height="27" fill="white" transform="translate(20 17)" />
      </clipPath>
    </defs>
  </svg>
);

export default ExpandSVG;
