// types
import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_FILTER } from "../../../data/common.data";

const DEFAULT_CARD_FILTER = {
	hospitalizations: [],
	DCERData: [],
	insuranceData: [],
	returnsData: [],
	ninetyDaysData: [],
	doctorData: [],
	nurseData: [],
	floorsData: [],
	daysData: [],
	dxData: [],
	shiftData: [],
	permission: [],
	hospitalData: [],
	priorityData: [],
};

export const DEFAULT_HOSPITAL_LIST = {
	ninetyDaysData: [],
	list: [],
	hospitalData: [],
};

export const DEFAULT_FILTER_PAGE = {
	hospitalizations: [
		{
			_id: "newHospitalizations",
			label: "New Hospitalizations",
			color: "#00BAEB",
			total: 0,
		},
		{
			_id: "reHospitalizations",
			label: "Re-Hospitalizations",
			color: "#5195DD",
			total: 0,
		},
	],
	...DEFAULT_CARD_FILTER,
};

const DEFAULT_HOSPITAL_DB_DATA = {
	plannedHospitalTransfer: 0,
	unplannedHospitalTransfer: 0,
	total: 0,
	censusAverage: 0,
};

// initial state
const initialState = {
	transferType: null,
	mainTotal: 0,
	defaultTotal: 0,
	lockedTotal: null,
	filterTotal: null,
	selectedFilter: null,
	mainNumPercentage: null,
	detailsDialog: {
		isOpen: false,
		type: null,
		item: null,
	},
	filter: DEFAULT_FILTER,
	cardFilter: DEFAULT_CARD_FILTER,
	dbData: DEFAULT_HOSPITAL_DB_DATA,
	isResetFilter: false,
	filterListData: null,
	isCensusTotalLocked: false,
	patientList: [],
	specialNumber: null,
	specialComparisonSet: false,
	facilityPercentage: [],
	lockedByFacility: [],
	lockedTotalBy: null,
};

// ==============================|| SLICE - HOSPITAL ||============================== //

const hospitalComparison = createSlice({
	name: "hospitalComparison",
	initialState,
	reducers: {
		setDefaultHospitalData(state, action) {
			state.transferType = null;
			state.cardFilter = DEFAULT_CARD_FILTER;
			state.mainTotal = 0;
			state.filter = DEFAULT_FILTER;
			state.dbData = DEFAULT_HOSPITAL_DB_DATA;
		},

		setLockTotal(state, action) {
			state.lockedTotal = action.payload;
		},
		setTransferType(state, action) {
			state.transferType = action.payload;
		},
		setFilterTotal(state, action) {
			state.filterTotal = action.payload;
		},
		setMainTotal(state, action) {
			state.mainTotal = action.payload;
		},

		openDetailsDialog(state, action) {
			state.detailsDialog = { ...action.payload };
		},

		closeDetailsDialog(state, action) {
			state.detailsDialog = {
				isOpen: false,
				type: null,
				item: null,
			};
		},

		setFilterDateRange(state, action) {
			state.filter = { ...state.filter, ...action.payload };
		},

		setFilterDBData(state, action) {
			state.filterTotal = action.payload?.censusAverage;
			state.dbData = { ...state.dbData, ...action.payload };
		},
		setCardFilter(state, action) {
			state.cardFilter = {
				...state.cardFilter,
				...action.payload,
			};
		},

		setResetFilter(state, action) {
			state.isResetFilter = action.payload;
		},
		setIsMainCensusPercentage(state, action) {
			state.mainNumPercentage = action.payload;
		},
		setFilterListDataHospital(state, action) {
			state.filterListData = action.payload;
		},
		setSelectedFilter(state, action) {
			if (action.payload) {
				// eslint-disable-next-line eqeqeq
				if (action.payload != "all") {
					const { filter } = action.payload;
					if (filter && filter.cardFilter) {
						state.cardFilter = {
							...DEFAULT_CARD_FILTER,
							...filter.cardFilter,
						};
					}
					if (filter && filter.transferType) {
						state.transferType = filter.transferType;
					} else {
						state.transferType = null;
					}
					if (filter && filter.filter) {
						state.filter = filter.filter;
					}
					state.selectedFilter = action.payload._id;
				} else {
					state.selectedFilter = action.payload;
					state.cardFilter = DEFAULT_CARD_FILTER;
					state.filter = { ...state.filter };
					state.isResetFilter = true;
					state.transferType = null;
					state.lockedTotal = null;
				}
			}
		},
		setPatientList(state, action) {
			state.patientList = action.payload;
		},
		setSpecialNumber(state, action) {
			state.specialNumber = action.payload;
		},
		setIsSpecialComparison(state, action) {
			state.specialComparisonSet = action.payload;
		},
		setIsCensusTotalLocked(state, action) {
			state.isCensusTotalLocked = action.payload;
		},
		setLockedTotalBy(state, action) {
			state.lockedTotalBy = action.payload;
		},
		setLockedByFacility(state, action) {
			state.lockedByFacility = action.payload;
		},
		setFacilityPercentage(state, action) {
			state.facilityPercentage = action.payload;
		},
	},
});

export default hospitalComparison.reducer;

export const {
	setFilterTotal,
	setResetFilter,
	setTransferType,
	openDetailsDialog,
	closeDetailsDialog,
	setFilterDateRange,
	setFilterDBData,
	setMainTotal,
	setCardFilter,
	setSelectedFilter,
	setDefaultHospitalData,
	setLockTotal,
	setIsMainCensusPercentage,
	setFilterListDataHospital,
	setPatientList,
	setSpecialNumber,
	setIsSpecialComparison,
	setIsCensusTotalLocked,
	setLockedTotalBy,
	setLockedByFacility,
	setFacilityPercentage,
} = hospitalComparison.actions;
