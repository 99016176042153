import * as React from 'react';
import { TextField, TextareaAutosize } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default function AddPatientTextPopup({
    handleClose,
    content = '',
    title = '',
    handleSubmit,
    isConfirm = true
}) {
    const [contentValue, setContentValue] = React.useState(content);

    const handleChange = React.useCallback((e) => {
        setContentValue(e.target.value);
    }, []);

    return (
        <Dialog
            sx={{ zIndex: 9999 }}
            maxWidth="sm"
            fullWidth={true}
            open={true}
            hideBackdrop={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {title && (
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
            )}
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <TextField
                        id="standard-basic-textarea"
                        multiline                        
                        rows={4}
                        fullWidth={true}
                        value={contentValue}
                        onChange={handleChange}                        
                        sx={{
                            "& .MuiInputBase-root": {
                                height: "100%",
                                borderRadius: "4px",
                            },
                        }}
                        InputProps={{
                            inputComponent: TextareaAutosize,
                        }}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ px: 2, py: 2 }}>
                <Button onClick={handleClose}>Cancel</Button>

                <Button onClick={() => handleSubmit(contentValue)} autoFocus variant='contained'>
                    Update
                </Button>

            </DialogActions>
        </Dialog>
    );
}
