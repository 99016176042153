/* eslint-disable no-unused-vars */
import * as React from 'react';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import { Button, Checkbox, FormControlLabel, Dialog, DialogActions, DialogContent, DialogTitle, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Stack, TextField, FormControl, Select, ListItemText, InputLabel, Typography, CircularProgress } from '@mui/material';

import { Dropdown, DropdownMenuItem, DropdownNestedMenuItem } from './dropdown';
import ArrowRight from "@mui/icons-material/ArrowRight";
import { AUTOMATICALLY_REPORT_TYPE, REPORT_FILE_TYPE } from '../../types/report.type';
import _ from "lodash";
import { CHART_TAB_BUTTON } from '../../types/chart-dialog.type';
import { useSelector } from "react-redux";
import { createSetting, getSettings } from '../../services/settings.service';
import { PAGE_TYPE } from '../../types/pages.type';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function ReportButton({
    handleOnClickReport,
    handleExcelReport,
    loading = false,
    title,
    sx,
    selectedTab,
    selectedTabSetting,
    handleExcelReportCustom,
    tableFilterType,
    selectedQuestions,
    pageType,
    subPageType,
    handleSaveExcelColumn
}) {
    const [open, setOpen] = React.useState(false);
    const [reportOptions, setReportOptions] = React.useState();
    const anchorRef = React.useRef(null);
    const [loading2, setLoading2] = React.useState(false);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleGenerateReport = React.useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        handleOnClickReport(title)
    }, [title, handleOnClickReport]);

    const [selectedColumns, setSelectedColumns] = React.useState([]);

    const handleOnClickReportCustomExcel = React.useCallback((e) => {
        if (reportOptions?.reportFileType === "excel" && tableFilterType !== "all") {
            createSetting({
                selectedColumns,
                selectedTab: selectedTabSetting,
                pageType: subPageType === PAGE_TYPE.ADT ? subPageType : pageType,
                ...subPageType === PAGE_TYPE.ADT && { subPageType }
            });
        }

    }, [pageType, reportOptions?.reportFileType, selectedColumns, selectedTabSetting, subPageType, tableFilterType]);

    const handleSaveReportOption = React.useCallback((e) => {
        handleOnClickReport(title, { ...reportOptions, selectedColumns: selectedColumns });
        handleOnClickReportCustomExcel();
        setReportOptions(null)
    }, [handleOnClickReport, title, reportOptions, selectedColumns, handleOnClickReportCustomExcel]);

    const activeFacilities = useSelector((state) => state.activeFacilities.facilities);

    const handleChange = (event) => {
        const value = event.target.value;
        if (value && value.length === 1 && value[0] === "selectAll") {
            console.log(value, "value");
            // setSelectedColumns(["selectAll"]);
        } else {
            setSelectedColumns(
                typeof value === 'string' ? value.split(',') : value,
            );
        }
    }

    const handleChangeSelectAll = async (event) => {
        const value = event.target.checked;
        if (value) {
            const values = selectedQuestions?.filter((ele) => ele?.question?.accessor !== "transferType")?.map((ele) => ele?.question?.accessor);
            setSelectedColumns([...values, "selectAll"]);
        } else {
            setSelectedColumns([]);
        }
    }


    const getSettingsData = async (filter) => {
        setLoading2(true)
        const res = await getSettings(filter);
        setLoading2(false);
        if (res && res.length > 0) {
            setSelectedColumns(res[0]?.selectedColumns);
        }
    }

    React.useEffect(() => {
        if (reportOptions?.reportFileType === "excel") {
            getSettingsData({
                pageType: subPageType === PAGE_TYPE.ADT ? subPageType : pageType,
                selectedTab: selectedTabSetting,
                ...subPageType === PAGE_TYPE.ADT && { subPageType }
            })
        }
        // eslint-disable-next-line
    }, [selectedQuestions, pageType, selectedTabSetting, subPageType, reportOptions?.reportFileType]);

    const SIMPLE_LIST_MENU = [
        <DropdownMenuItem
            onClick={handleGenerateReport}
            children={"Generate Report"}
        />,
        <DropdownNestedMenuItem
            label="Send Report"
            rightIcon={<ArrowRight />}
            menu={[
                <DropdownMenuItem
                    onClick={() =>
                        setReportOptions(prevState => ({
                            ...prevState,
                            type: AUTOMATICALLY_REPORT_TYPE.DAILY
                        }))}
                >
                    Daily
                </DropdownMenuItem>,
                <DropdownMenuItem
                    onClick={() =>
                        setReportOptions(prevState => ({
                            ...prevState,
                            type: AUTOMATICALLY_REPORT_TYPE.WEEKLY
                        }))}
                >
                    Weekly
                </DropdownMenuItem>,
                <DropdownMenuItem
                    onClick={() =>
                        setReportOptions(prevState => ({
                            ...prevState,
                            type: AUTOMATICALLY_REPORT_TYPE.MONTHLY
                        }))}
                >
                    Monthly
                </DropdownMenuItem>,
                <DropdownMenuItem
                    onClick={() =>
                        setReportOptions(prevState => ({
                            ...prevState,
                            type: AUTOMATICALLY_REPORT_TYPE.QUARTERLY
                        }))}
                >
                    Quarterly
                </DropdownMenuItem>,
                <DropdownMenuItem
                    onClick={() =>
                        setReportOptions(prevState => ({
                            ...prevState,
                            type: AUTOMATICALLY_REPORT_TYPE.YEARLY
                        }))}
                >
                    Yearly
                </DropdownMenuItem>
            ]}
        />
    ];

    const TABLE_LIST_MENU = [
        <DropdownNestedMenuItem
            label="Generate Report"
            rightIcon={<ArrowRight />}
            menu={[
                <DropdownMenuItem
                    onClick={handleGenerateReport}
                >
                    Pdf
                </DropdownMenuItem>,
                <DropdownMenuItem
                    onClick={() => {
                        if (tableFilterType === "all") {
                            handleExcelReport()
                        } else {
                            handleExcelReportCustom()
                        }
                    }}
                >
                    Excel
                </DropdownMenuItem>
            ]} />,
        <DropdownNestedMenuItem
            label="Send Report"
            rightIcon={<ArrowRight />}
            menu={[
                <DropdownNestedMenuItem
                    label="Pdf"
                    rightIcon={<ArrowRight />}
                    menu={[
                        <DropdownMenuItem
                            onClick={() =>
                                setReportOptions(prevState => ({
                                    ...prevState,
                                    reportFileType: REPORT_FILE_TYPE.PDF,
                                    type: AUTOMATICALLY_REPORT_TYPE.DAILY
                                }))}
                        >
                            Daily
                        </DropdownMenuItem>,
                        <DropdownMenuItem
                            onClick={() =>
                                setReportOptions(prevState => ({
                                    ...prevState,
                                    reportFileType: REPORT_FILE_TYPE.PDF,
                                    type: AUTOMATICALLY_REPORT_TYPE.WEEKLY
                                }))}
                        >
                            Weekly
                        </DropdownMenuItem>,
                        <DropdownMenuItem
                            onClick={() =>
                                setReportOptions(prevState => ({
                                    ...prevState,
                                    reportFileType: REPORT_FILE_TYPE.PDF,
                                    type: AUTOMATICALLY_REPORT_TYPE.MONTHLY
                                }))}
                        >
                            Monthly
                        </DropdownMenuItem>,
                        <DropdownMenuItem
                            onClick={() =>
                                setReportOptions(prevState => ({
                                    ...prevState,
                                    reportFileType: REPORT_FILE_TYPE.PDF,
                                    type: AUTOMATICALLY_REPORT_TYPE.QUARTERLY
                                }))}
                        >
                            Quarterly
                        </DropdownMenuItem>,
                        <DropdownMenuItem
                            onClick={() =>
                                setReportOptions(prevState => ({
                                    ...prevState,
                                    reportFileType: REPORT_FILE_TYPE.PDF,
                                    type: AUTOMATICALLY_REPORT_TYPE.YEARLY
                                }))}
                        >
                            Yearly
                        </DropdownMenuItem>
                    ]} />,
                <DropdownNestedMenuItem
                    label="Excel"
                    rightIcon={<ArrowRight />}
                    menu={[
                        <DropdownMenuItem
                            onClick={() =>
                                setReportOptions(prevState => ({
                                    ...prevState,
                                    reportFileType: REPORT_FILE_TYPE.EXCEL,
                                    type: AUTOMATICALLY_REPORT_TYPE.DAILY
                                }))}
                        >
                            Daily
                        </DropdownMenuItem>,
                        <DropdownMenuItem
                            onClick={() =>
                                setReportOptions(prevState => ({
                                    ...prevState,
                                    reportFileType: REPORT_FILE_TYPE.EXCEL,
                                    type: AUTOMATICALLY_REPORT_TYPE.WEEKLY
                                }))}
                        >
                            Weekly
                        </DropdownMenuItem>,
                        <DropdownMenuItem
                            onClick={() =>
                                setReportOptions(prevState => ({
                                    ...prevState,
                                    reportFileType: REPORT_FILE_TYPE.EXCEL,
                                    type: AUTOMATICALLY_REPORT_TYPE.MONTHLY
                                }))}
                        >
                            Monthly
                        </DropdownMenuItem>,
                        <DropdownMenuItem
                            onClick={() =>
                                setReportOptions(prevState => ({
                                    ...prevState,
                                    reportFileType: REPORT_FILE_TYPE.EXCEL,
                                    type: AUTOMATICALLY_REPORT_TYPE.QUARTERLY
                                }))}
                        >
                            Quarterly
                        </DropdownMenuItem>,
                        <DropdownMenuItem
                            onClick={() =>
                                setReportOptions(prevState => ({
                                    ...prevState,
                                    reportFileType: REPORT_FILE_TYPE.EXCEL,
                                    type: AUTOMATICALLY_REPORT_TYPE.YEARLY
                                }))}
                        >
                            Yearly
                        </DropdownMenuItem>
                    ]} />,
            ]}
        />
    ];

    if (handleOnClickReport && !loading) {
        return (
            <>
                <Stack direction="row" spacing={2}>
                    <div>
                        <Dropdown
                            trigger={
                                <IconButton
                                    sx={{
                                        width: 29,
                                        height: 29,
                                        mt: 0,
                                        mr: 1,
                                        borderRadius: 0.8,
                                        border: "1px solid grey",
                                        backgroundColor: "#fff",
                                        color: "primary.contrastText",
                                        ":hover": { backgroundColor: "grey" },
                                        ...sx
                                    }}
                                    disableFocusRipple={true}
                                    disableRipple={false}
                                    ref={anchorRef}
                                    id="composition-button"
                                    aria-controls={open ? 'composition-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggle}
                                >
                                    <DriveFileMoveOutlinedIcon sx={{
                                        fontSize: 18,
                                        color: "#000"
                                    }} />
                                </IconButton>
                            }
                            menu={selectedTab === CHART_TAB_BUTTON.TABLE ? TABLE_LIST_MENU : SIMPLE_LIST_MENU}
                        />
                    </div>
                </Stack>
                {!!reportOptions && (
                    <Dialog open={true} fullWidth maxWidth="sm">
                        <DialogTitle>Send Automatic {_.capitalize(reportOptions?.type)} Report</DialogTitle>
                        <DialogContent sx={{ mt: 1 }}>
                            <Stack>
                                <TextField
                                    fullWidth
                                    label="Report Name"
                                    variant="standard"
                                    value={reportOptions?.name || null}
                                    onChange={(e) =>
                                        setReportOptions(prevState => ({
                                            ...prevState,
                                            name: e.target.value
                                        }))}
                                />
                                {reportOptions?.reportFileType === "excel" && tableFilterType !== "all" && (<>
                                    <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                                        <Typography variant="subtitle2">Select the column you want to show in the excel report</Typography>
                                        {loading2 ? (
                                            <CircularProgress />
                                        ) : (
                                            <Select
                                                labelId="column-selector-label"
                                                id="column-selector"
                                                multiple
                                                fullWidth
                                                value={selectedColumns?.filter(item => item !== "selectAll")}
                                                onChange={handleChange}                                                
                                                MenuProps={MenuProps}
                                                renderValue={(selected) => {
                                                    if (selected.length > 0) {
                                                        return selectedQuestions?.filter((ele) => _.includes(selected, ele?.question?.accessor) && ele?.question?.accessor !== "transferType").map((column) => column?.question?.label).join(', ')
                                                    }
                                                    return ""
                                                }}
                                            >
                                                <MenuItem key={"selectAll"} value={"selectAll"}>
                                                    <Checkbox onChange={handleChangeSelectAll} checked={selectedColumns.indexOf("selectAll") > -1} />
                                                    <ListItemText primary={"Select All"} />
                                                </MenuItem>
                                                {selectedQuestions && selectedQuestions?.length > 0 && selectedQuestions?.filter((ele) => ele?.question?.accessor !== "transferType").map((column) => (
                                                    <MenuItem key={column?.question?._id} value={column?.question?.accessor}>
                                                        <Checkbox checked={selectedColumns.indexOf(column?.question?.accessor) > -1} />
                                                        <ListItemText primary={column?.question?.label} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    </FormControl>
                                </>
                                )}
                                {activeFacilities && activeFacilities.length > 1 && (
                                    <div className={`m-t-10 inputWrpr`}>
                                        <FormControlLabel
                                            label="Send report separate by facility"
                                            className="ffmr fs12"
                                            slotProps={{
                                                typography: {
                                                    sx: {
                                                        mt: "10px",
                                                        fontSize: "13px",
                                                        fontFamily: "mont reg"
                                                    }
                                                }
                                            }}
                                            control={
                                                <Checkbox
                                                    checked={reportOptions?.isSendReportSeparate}
                                                    onChange={(e) => setReportOptions(prevState => ({
                                                        ...prevState,
                                                        isSendReportSeparate: e.target.checked
                                                    }))}
                                                />
                                            }
                                        />
                                    </div>
                                )}
                            </Stack>
                        </DialogContent>
                        <DialogActions sx={{ mb: 1, mr: 1.5 }}>
                            <Button onClick={() => setReportOptions(null)}>Cancel</Button>
                            <Button variant="contained" onClick={handleSaveReportOption}>Save</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </>
        )
    }
    return null;
}
