import api from './api';

const API_PREFIX = "api/role";

const getAllRoles = async (params) => {
    const response = await api.get(`${API_PREFIX}/all`, { params });
    return response;
};

const saveRole = async (body) => {
    const response = await api.post(`${API_PREFIX}`, body);
    return response;
};

const updateRoleData = async (id, body) => {
    const response = await api.put(`${API_PREFIX}/${id}`, body);
    return response;
};

const getOnlyRolesAll = async (params) => {
    const response = await api.get(`${API_PREFIX}/select/options`, { params });
    return response;
};

export {
    getAllRoles,
    saveRole,
    updateRoleData,
    getOnlyRolesAll
};
