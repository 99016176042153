export const VIEW_ADT_DATA = [
    {
        value: "Admissions",
        label: "Admissions",
        adts: [
            {
                value: "Total",
                label: "Total",
                buttons: [
                    {
                        value: "Total",
                        label: "Total"
                    }, {
                        value: "Per Dr.",
                        label: "Per Dr."
                    }, {
                        value: "Per DX.",
                        label: "Per DX."
                    }, {
                        value: "Per Day of the week",
                        label: "Per Day of the week"
                    }, {
                        value: "Per Hospital",
                        label: "Per Hospital"
                    }, {
                        value: "Per Insurance",
                        label: "Per Insurance"
                    }
                ]
            },
            {
                value: "New Admissions:",
                label: "New Admissions:",
                buttons: [
                    {
                        value: "Total",
                        label: "Total"
                    }, {
                        value: "Per Dr.",
                        label: "Per Dr."
                    }, {
                        value: "Per DX.",
                        label: "Per DX."
                    }, {
                        value: "Per Day of the week",
                        label: "Per Day of the week"
                    }, {
                        value: "Per Hospital",
                        label: "Per Hospital"
                    }, {
                        value: "Per Insurance",
                        label: "Per Insurance"
                    }
                ]
            },
            {
                value: "Re-Admissions",
                label: "Re-Admissions",
                buttons: [
                    {
                        value: "Total",
                        label: "Total"
                    }, {
                        value: "Per Dr.",
                        label: "Per Dr."
                    }, {
                        value: "Per DX.",
                        label: "Per DX."
                    }, {
                        value: "Per Day of the week",
                        label: "Per Day of the week"
                    }, {
                        value: "Per Hospital",
                        label: "Per Hospital"
                    }, {
                        value: "Per Insurance",
                        label: "Per Insurance"
                    }
                ]
            },
        ]
    }, {

        value: "Community Transfers",
        label: "Community Transfers",
        adts: [
            {
                value: "Community Transfers",
                label: "Community Transfers",
                buttons: [
                    {
                        value: "Total",
                        label: "Total"
                    },
                    {
                        value: "Per DR.",
                        label: "Per DR."
                    },
                    {
                        value: "Per Unit",
                        label: "Per Unit"
                    },
                    {
                        value: "Resident that were hospitalized during facility stay",
                        label: "Resident that were hospitalized during facility stay"
                    },
                    {
                        value: "Per 60 days (7,14,30,60)",
                        label: "Per 60 days (7,14,30,60)"
                    },
                    {
                        value: "Didn’t return",
                        label: "Didn’t return"
                    },
                ]
            },
            {
                value: "Safe Discharges",
                label: "Safe Discharges",
                buttons: [
                    {
                        value: "Total",
                        label: "Total"
                    },
                    {
                        value: "Per DR.",
                        label: "Per DR."
                    },
                    {
                        value: "Per Unit",
                        label: "Per Unit"
                    },
                    {
                        value: "Resident that were hospitalized during facility stay",
                        label: "Resident that were hospitalized during facility stay"
                    },
                    {
                        value: "Per 60 days (7,14,30,60)",
                        label: "Per 60 days (7,14,30,60)"
                    },
                    {
                        value: "Per Assisted Living",
                        label: "Per Assisted Living"
                    },
                    {
                        value: "Didn’t return",
                        label: "Didn’t return"
                    },
                ]
            },
            {
                value: "SNF Transfers",
                label: "SNF Transfers",
                buttons: [
                    {
                        value: "Total",
                        label: "Total"
                    },
                    {
                        value: "Per Facility",
                        label: "Per Facility"
                    },
                    {
                        value: "Per DR.",
                        label: "Per DR."
                    },
                    {
                        value: "Per Unit",
                        label: "Per Unit"
                    },
                    {
                        value: "Per 60 days (7,14,30,60)",
                        label: "Per 60 days (7,14,30,60)"
                    },
                    {
                        value: "Resident that were hospitalized during facility stay",
                        label: "Resident that were hospitalized during facility stay"
                    },
                    {
                        value: "Didn’t return",
                        label: "Didn’t return"
                    },
                ]
            },
            {
                value: "AMA'S",
                label: "AMA'S",
                buttons: [
                    {
                        value: "Total",
                        label: "Total"
                    },
                    {
                        value: "Per Facility",
                        label: "Per Facility"
                    },
                    {
                        value: "Per DR.",
                        label: "Per DR."
                    },
                    {
                        value: "Per Unit",
                        label: "Per Unit"
                    },
                    {
                        value: "Per 60 days (7,14,30,60)",
                        label: "Per 60 days (7,14,30,60)"
                    },
                    {
                        value: "Resident that were hospitalized during facility stay",
                        label: "Resident that were hospitalized during facility stay"
                    },
                    {
                        value: "Didn’t return",
                        label: "Didn’t return"
                    },
                ]
            }
        ]
    }

]

export const TYPES = {
    UNPLANNED: "unplannedHospitalTransfer",
    PLANNED: "plannedHospitalTransfer",
    ALL: "all",
}

export const ResStatusEnum = {
    error: 'error',
    success: 'success',
}

export const VALIDATION_TYPES = {
    insurance: 'insurance',
    hospital: 'hospital',
    unit: 'unit',
    doctor: 'doctor',
    dx: 'dx',
    nurse: 'nurse',
}