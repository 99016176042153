import { ResponsiveBar } from "@nivo/bar";
import classNames from "classnames";
import _ from "lodash";
import { Fragment } from "react";
import { PAGE_TYPE } from "../../../../types/pages.type";
import { calcProPercentsBasedOnFilterAndDays, itemPercentage } from "../../../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../../utilis/percentage-comparison";
import CardItemTooltip from "../../../shared/CardItemTooltip";
import ColorBox from "../../../shared/dashboard/ColorBox";
import NoRecordFound from "../../../shared/NoRecordFound";
import CheckboxButton from "../../shared/checkboxButton/CheckboxButton";
import styles from "./DayCardList.module.scss";
import DayCardSkeletonList from "./skeleton/DayCardSkeletonList";

const DayCardList = ({
	data,
	dataComparison,
	filter,
	filterComparison,
	handleToggle,
	selectedItem = [],
	type,
	page,
	averageCensusComparison,
	averageCensus,
	cardTitle,
	admissionCompareAgainst,
	admissionCompareAgainstComparison,
	reverseColorsAdmissionPage,
	loading,
	projectionDays,
}) => {
	const specialPages = [PAGE_TYPE.ADMISSION];
	const isSpecialPage = specialPages.find((x) => x === page)?.length > 0;

	data = data.map((day, index) => {
		let comparisonColor;
		let comparingAgainstScaled;
		let numberOfDays;
		const itemComparison = _.find(dataComparison, { _id: day._id });

		if (page === PAGE_TYPE.ADMISSION) {
			const valueForAdmission = itemPercentage(day.value, admissionCompareAgainst, "number");
			const valueForAdmissionComparison = itemPercentage(
				itemComparison?.value,
				admissionCompareAgainstComparison,
				"number"
			);
			const {
				percentageDiff,
				itemTotalComparisonScaled,
				numberOfDays: numberOfDaysTemp,
			} = calculateDiffBetweenPercentages(
				valueForAdmission,
				valueForAdmissionComparison,
				reverseColorsAdmissionPage,
				filter,
				filterComparison,
				itemComparison?.value,
				projectionDays
			);

			numberOfDays = numberOfDaysTemp;
			comparingAgainstScaled = itemTotalComparisonScaled;
			comparisonColor = pickComparisonColor(percentageDiff, isSpecialPage);
		} else {
			const percentageOfAverageCensus = itemPercentage(day.value, averageCensus, "number");
			const percentageOfAverageCensusComparison = itemPercentage(
				itemComparison?.value || 0,
				averageCensusComparison,
				"number"
			);
			const {
				percentageDiff,
				itemTotalComparisonScaled,
				numberOfDays: numberOfDaysTemp,
			} = calculateDiffBetweenPercentages(
				percentageOfAverageCensus,
				percentageOfAverageCensusComparison,
				false,
				filter,
				filterComparison,
				itemComparison?.value,
				projectionDays
			);

			numberOfDays = numberOfDaysTemp;
			comparisonColor = pickComparisonColor(percentageDiff, isSpecialPage);
			comparingAgainstScaled = itemTotalComparisonScaled;
		}

		return {
			...day,
			comparisonColor,
			comparingAgainst: itemComparison?.value,
			dayComparison: itemComparison,
			comparingAgainstScaled,
			numberOfDays,
		};
	});

	return (
		<div className={`w100 ${styles.dayCardList}`}>
			<div style={{ height: "245px", width: "100%", paddingTop: "40px" }}>
				{loading ? (
					<DayCardSkeletonList />
				) : (
					data &&
					data.length > 0 && (
						<ResponsiveBar
							data={data}
							keys={["value"]}
							indexBy="label"
							margin={{ top: 0, right: 0, bottom: 40, left: 0 }}
							padding={0.3}
							valueScale={{ type: "linear" }}
							indexScale={{ type: "band", round: true }}
							colors={({ data }) => {
								const ID = data?._id || null;
								const selected = selectedItem.indexOf(ID) !== -1;
								return !selected ? data.color : "#FFC700";
							}}
							enableArcLabels={false}
							enableArcLinkLabels={false}
							enableGridY={false}
							enableGridX={false}
							borderRadius="4"
							label={(_label) => {
								return _label.data.percentage
									? `${_label.data.percentage}%`
									: `${itemPercentage(_label.value, null, "number", page)}%`;
							}}
							barComponent={({ bar }) => {
								return (
									<g transform={`translate(${bar.x},${bar.y})`}>
										<rect
											//style={{ borderRadius: "25% 10%" }}
											width={bar.width}
											height={bar.height}
											fill={bar.data.data.color}
											strokeWidth="0"
											stroke={bar.data.data.color}
											focusable="false"
											rx={4}
											ry={4}
										></rect>
										<text
											x={bar.width / 2}
											y={bar.height / 2}
											textAnchor="middle"
											dominantBaseline="central"
											style={{
												fontFamily: "manrope",
												fontSize: "11px",
												pointerEvents: "none",
											}}
										>
											{Math.round(bar.data.data.percentage)}%
										</text>
									</g>
								);
							}}
						/>
					)
				)}
			</div>
			<NoRecordFound data={data} />
			<div className={`${styles.footerWrapper}`}>
				<div className={`ffmar fs14 fw500  ${styles.itemsWrpr}`}>
					{data?.length > 0 &&
						data.map((item, index) => {
							const ID = item._id;
							const labelId = `checkbox-list-return-label-${ID}`;
							const selected = selectedItem.indexOf(ID) !== -1;
							const itemComparison = item.dayComparison
								? { ...item.dayComparison, total: item.dayComparison.value }
								: null;
							return (
								<Fragment key={`${index}dayList`}>
									<div
										className={classNames("df", "aic", styles.sec, selected && styles.selected, loading && `skeleton`)}
									>
										{loading ? null : (
											<>
												<CardItemTooltip item={item}>
													<div style={{textAlign:"center"}} className={`${Number(item.value) <= 10 ? styles.valueLabel : ""}`}>
														{calcProPercentsBasedOnFilterAndDays(item.value, filter, projectionDays)}
													</div>

													<div className={`${styles.dayListCheckbox}`}>
														<CheckboxButton
															labelId={labelId}
															className={`${styles.dayListLabel}`}
															handleToggle={() =>
																handleToggle({
																	item: { ...item, total: item.value },
																	itemComparison,
																	type,
																	isChecked: selected,
																	cardTitle,
																})
															}
															checked={selected}
														/>

														<ColorBox
															type="button"
															color={item.comparisonColor}
															comparingAgainst={item.comparingAgainst}
															comparingAgainstScaled={item.comparingAgainstScaled}
															numberOfDays={item.numberOfDays}
															sx={{ marginTop: "10px" }}
														/>
													</div>
												</CardItemTooltip>
											</>
										)}
									</div>
								</Fragment>
							);
						})}
				</div>
			</div>
		</div>
	);
};

export default DayCardList;
