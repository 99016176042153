import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import { useSortBy, useTable } from "react-table";
import EditSVG from "../../../assets/svgs/edit.svg";
import DeleteSVG from "../../../assets/svgs/delete.svg";
import AddPatient from "../../landing/facility/data/add-patient/AddPatient";
// import { ShowForPermission } from "../../shared/ShowForPermission";

import styles from "./Table.module.scss";
import HtmlTooltip from "../../shared/HtmlTooltip";
import InfoIcon from "../../icon/InfoIcon";
import ActivityLogTooltip from "../../shared/ActivityLogTooltip";

function Table({ columns, data, setSelectedResident, patientUpdated, loading, handleDelete, initialDate, isMultipleFacility }) {
  const [showAdd, setShowAdd] = useState(false);
  const [editResident, setEditResident] = useState(undefined);
  const toggleAdd = () => setShowAdd(!showAdd);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <div className={styles.tableWrpr}>
      <table {...getTableProps()}>
        <thead className={`ffmar fs12 ttuc`}>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`${i}-header-row`}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}

                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? // ? " 🔽"
                        // : " 🔼"
                        " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {!loading &&
            rows.length > 0 &&
            rows.map((row, i) => {
              prepareRow(row);
              return (
                <>
                  <tr
                    className={`ffmar fs15`}
                    {...row.getRowProps()}
                    onClick={() => {
                      setSelectedResident(row.original);
                    }}
                    key={`${i}-${row.id}`}
                  >
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                    })}
                    {/* <ShowForPermission type="editAdt"> */}
                    <td
                      className={`df acc c-pointer ${styles.editBtn}`}
                      style={{ paddingTop: "22px" }}
                    >
                      <div
                        onClick={e => {
                          e.stopPropagation();
                          setEditResident(row.original);
                          toggleAdd();
                        }}
                      >
                        <EditSVG fill={row?.original?.highlighter?.length > 0 ? "red" : ""} />
                      </div>
                      {isMultipleFacility ? (
                        <Tooltip title="this feature is disabled when viewing multiple facilities">
                          <div
                            style={{ marginLeft: "12px", opacity: "0.5" }}
                          >
                            <DeleteSVG fill="#F93B5F" />
                          </div>
                        </Tooltip>
                      ) : (
                        <div
                          onClick={e => {
                            e.stopPropagation();
                            handleDelete(row.original._id)
                          }}
                          style={{ marginLeft: "12px" }}
                        >
                          <DeleteSVG fill="#F93B5F" />
                        </div>
                      )}
                      <div
                        style={{ marginTop: "14px", marginLeft: "2px" }}
                      >
                        <HtmlTooltip
                          content={<ActivityLogTooltip logs={row.original?.logs ?? []} />}
                          placement="left"
                          sx={{
                            "& .MuiTooltip-tooltip": {
                              width: "310px",
                              maxWidth: "310px",
                            },                            
                          }}
                        >
                          <IconButton disableFocusRipple={true} disableRipple={true} sx={{
                            "svg": {
                              top:"-1px",
                              width:"30px",
                              height:"30px",
                            }
                          }}>
                            <InfoIcon />
                          </IconButton>
                        </HtmlTooltip>
                      </div>
                    </td>
                    {/* </ShowForPermission> */}
                  </tr>
                  <tr className={styles.separatorTr} key={"separator " + i}></tr>
                </>
              );
            })}
          {!loading && rows.length === 0 && (
            <tr>
              <td colSpan={columns.length} align="center">
                <div className={`ffmar ttuc fs15`}>No record found</div>
              </td>
            </tr>
          )}
          {loading && (
            <tr>
              <td colSpan={columns.length} align="center">
                <div className="df acc">
                  <CircularProgress />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {
        showAdd ? (
          <AddPatient
            selectedResident={editResident}
            close={toggleAdd}
            initialDate={initialDate}
            patientUpdated={patientUpdated}
            patientId={editResident?._id}
            step={3}
            isMultipleFacility={isMultipleFacility}
          />
        ) : undefined
      }
    </div >
  );
}

export default Table;
