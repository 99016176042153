import { Fade } from "@mui/material";
import { useSelector } from "react-redux";

const CardAnimation = ({
    children,
    checked,
    id
}) => {
    const { isScrollHightLight } = useSelector((state) => state.common);
    return (
        <Fade
            id={id}
            in={checked}
            timeout={1500}
            easing={{
                enter: "cubic-bezier(0.25, 0.1, 0.25, 1)",
            }}
            style={{
                ...isScrollHightLight && { borderRadius: 10 },
                // ...isScrollHightLight && { border: "1px solid #92c7ff" },
                // background: isScrollHightLight === id ? "#92c7ff" : "inherit",
                // border: isScrollHightLight === id ? "1px solid #2259c9" : "inherit",
            }}
            className={isScrollHightLight === id ? `scrollView` : null}            
            unmountOnExit={true}
        >
            {children}
        </Fade>
    )
}

export default CardAnimation