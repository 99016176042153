import { store } from "..";
import { setFilterDBData, setMainTotal } from "../store/reducers/admission.slice";
import {
	setFilterDBData as setFilterDBDataComparison,
	setMainTotal as setMainTotalComparison,
} from "../store/reducers/comparisonReducers/admissionComparison.slice";
import {
	getAllAdmissionCount,
	getAllADTData,
	getCardADTPatientChartData,
	getCardPatientAndChartData,
	getCardPatientData,
} from "./api/admission.api";

const cardPatientData = async (data) => {
	try {
		const res = await getCardPatientData(data);
		return res;
	} catch (e) {
		console.log(e);
	}
};

const cardPatientAndChartData = async (data) => {
	try {
		const res = await getCardPatientAndChartData(data);
		return res;
	} catch (e) {
		console.log(e);
	}
};

const cardADTPatientChartData = async (data) => {
	try {
		const res = await getCardADTPatientChartData(data);
		return res;
	} catch (e) {
		console.log(e);
	}
};

const getAllAdmissionsPatient = async (data = null, forComparison) => {
	try {
		const res = await getAllAdmissionCount(data);
		store.dispatch(forComparison ? setFilterDBDataComparison(res.totals) : setFilterDBData(res.totals));
		store.dispatch(forComparison ? setMainTotalComparison(res.totals.total) : setMainTotal(res.totals.total));
		return res.data;
	} catch (e) {
		console.log(e);
	}
};

const allADTData = async (data) => {
	try {
		return await getAllADTData(data);
	} catch (e) {
		console.log(e);
	}
};

export { allADTData, cardADTPatientChartData, cardPatientAndChartData, cardPatientData, getAllAdmissionsPatient };

