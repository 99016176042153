const TotalAdmissionSVG = (props) => (
    <svg width="47" height="43" viewBox="0 0 47 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.848633" width="47" height="41.303" rx="5" fill="#736AD1" />
        <g clipPath="url(#clip0_187_5213)">
            <path d="M33.3014 11.7809C33.3014 11.124 33.0451 10.5056 32.5804 10.041L30.2605 7.721C29.8023 7.26277 29.1519 7 28.5046 7H28.3636V11.9219H33.3015L33.3014 11.7809Z" fill="white" />
            <path d="M26.9809 23.9278C27.1379 23.4511 27.4119 23.0074 27.7692 22.6477L33.3234 17.1159V13.5625H27.5593C27.1059 13.5625 26.7656 13.1956 26.7656 12.7422V7H14.4609C13.1039 7 12 8.10392 12 9.46094V32.5391C12 33.8961 13.1039 35 14.4609 35H30.8406C32.1976 35 33.3234 33.8961 33.3234 32.5391V26.4167L32.4219 27.2963C32.0566 27.6584 31.6289 27.922 31.1466 28.083L28.5383 28.9538L28.1409 28.9755C27.484 28.9755 26.8624 28.6558 26.4763 28.1207C26.0934 27.588 25.9892 26.8974 26.1975 26.2734L26.9809 23.9278ZM16.1016 21.7656H24.2781C24.7315 21.7656 25.0984 22.1325 25.0984 22.5859C25.0984 23.0394 24.7315 23.4062 24.2781 23.4062H16.1016C15.6481 23.4062 15.2812 23.0394 15.2812 22.5859C15.2812 22.1325 15.6481 21.7656 16.1016 21.7656ZM15.2812 19.3047C15.2812 18.8513 15.6481 18.4844 16.1016 18.4844H24.2781C24.7315 18.4844 25.0984 18.8513 25.0984 19.3047C25.0984 19.7581 24.7315 20.125 24.2781 20.125H16.1016C15.6481 20.125 15.2812 19.7581 15.2812 19.3047ZM15.2812 25.8672C15.2812 25.4138 15.6481 25.0469 16.1016 25.0469H24.2781C24.7315 25.0469 25.0984 25.4138 25.0984 25.8672C25.0984 26.3206 24.7315 26.6875 24.2781 26.6875H16.1016C15.6481 26.6875 15.2812 26.3206 15.2812 25.8672ZM27.5593 29.9688C28.0127 29.9688 28.3796 30.3356 28.3796 30.7891C28.3796 31.2425 28.0128 31.6094 27.5593 31.6094H22.6374C22.184 31.6094 21.8171 31.2425 21.8171 30.7891C21.8171 30.3356 22.184 29.9688 22.6374 29.9688H27.5593ZM16.1016 16.8438C15.6481 16.8438 15.2812 16.4769 15.2812 16.0234C15.2812 15.57 15.6481 15.2031 16.1016 15.2031H27.5593C28.0127 15.2031 28.3796 15.57 28.3796 16.0234C28.3796 16.4769 28.0127 16.8438 27.5593 16.8438H16.1016Z" fill="white" />
            <path d="M28.467 24.6533L27.7533 26.7944C27.6464 27.1151 27.9516 27.4202 28.2723 27.3131L30.4121 26.5984L28.467 24.6533Z" fill="white" />
            <path d="M34.8379 17.8994L29.4354 23.3019L31.7654 25.6319C32.9864 24.4106 35.3623 22.0344 37.1676 20.2291L34.8379 17.8994Z" fill="white" />
            <path d="M39.5179 15.5466C39.2296 15.2566 38.805 15.0908 38.3547 15.0908C37.9045 15.0908 37.48 15.2566 37.1899 15.5475L35.9979 16.7395L38.3275 19.0691C38.9801 18.4164 39.4312 17.9654 39.5196 17.8769C40.1604 17.2352 40.1604 16.1899 39.5179 15.5466Z" fill="white" />
            <path d="M39.5195 17.8786C39.5196 17.8786 39.5196 17.8785 39.5197 17.8784C39.5225 17.8756 39.5237 17.8744 39.526 17.8721C39.5237 17.8744 39.5225 17.8757 39.5195 17.8786Z" fill="white" />
            <path d="M39.5244 17.8725C39.5272 17.8697 39.5305 17.8664 39.5322 17.8647C39.5304 17.8665 39.5272 17.8697 39.5244 17.8725Z" fill="white" />
            <path d="M39.5316 17.8665C39.5391 17.859 39.5393 17.8589 39.5316 17.8665V17.8665Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_187_5213">
                <rect width="28" height="28" fill="white" transform="translate(12 7)" />
            </clipPath>
        </defs>
    </svg>
);

export default TotalAdmissionSVG;
