import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from "../types";

const reducer = (state = [], action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      const existingNotif = state.find(x => x.id === action.payload.id);

      return existingNotif
        ? state
        : [
            ...state,
            {
              id:
                Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15),
              ...action.payload,
            },
          ];
    case REMOVE_NOTIFICATION:
      return state.filter(notification => notification.id !== action.payload);
    default:
      return state;
  }
};

export default reducer;
