import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { itemPercentage } from "../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../utilis/percentage-comparison";

const useDaysCard = ({
	data,
	comparisonData,
	defaultRadialData,
	page,
	filterListData,
	filterListDataComparison,
	filter,
	filterComparison,
	averageCensusComparison,
	averageCensus,
	reverseColors,
}) => {
	const compareAgainstComparison = averageCensusComparison;
	const [dataList, setDataList] = useState([]);
	const [dataListBarChart, setDataListBarChart] = useState([]);
	const [dataListComparisonBarChart, setDataListComparisonBarChart] = useState([]);
	const percentageThresholds = useSelector((state) => state.comparison.percentageThresholds);

	const calculateLatestRedials = (ele, index, data, comparisonNumber = null) => {
		if (data && data.length > 0) {
			if (ele.parentId === "total") {
				const total = _.sumBy(data, "value");
				const percentageTotal = comparisonNumber
					? itemPercentage(total, comparisonNumber, "number")
					: itemPercentage(total, null, "number", page);
				const object = Object.assign({}, data[0], {
					y: total,
					percentage: ele.percentage ? ele.percentage : percentageTotal,
					color: ele.color,
				});
				return { ...ele, data: [object] };
			} else {
				const parentData = _.find(data, { key: ele.parentId });
				const percentageByValue = comparisonNumber
					? itemPercentage(parentData.value, comparisonNumber, "number", page)
					: itemPercentage(parentData.value, null, "number", page);
				const object = Object.assign({}, data[0], {
					y: parentData.value,
					percentage: ele.percentage ? ele.percentage : percentageByValue,
					color: ele.color,
				});
				return { ...ele, data: [object] };
			}
		} else {
			return defaultRadialData[index];
		}
	};

	const mainData = useMemo(() => {
		return data
			? data.map((item) => {
				return {
					...item,
					percentage: item?.percentage ? item.percentage : itemPercentage(item.value, null, "number", page), //itemPercentage(item.value, compareAgainst, "number", page),
				};
			})
			: [];
	}, [data, page]);

	const [radialDataList, setRadialDataList] = useState(
		mainData
			? defaultRadialData.map((ele, index) => calculateLatestRedials(ele, index, mainData, averageCensus))
			: defaultRadialData
	);
	const [radialDataComparisonList, setRadialDataComparisonList] = useState(defaultRadialData);

	useEffect(() => {
		const sortedData = _.orderBy(mainData, "_id", "desc");

		setDataList(mainData);
		setDataListBarChart(sortedData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, page, percentageThresholds]);

	useEffect(() => {
		let calculationData;

		const radialDataListMain = mainData
			? defaultRadialData.map((ele, index) => calculateLatestRedials(ele, index, mainData, averageCensus))
			: defaultRadialData;
		setRadialDataList(radialDataListMain);

		if (comparisonData && comparisonData.length > 0) {
			const mainComparisonData = comparisonData.map((item) => ({
				...item,
				percentage: itemPercentage(item.value, compareAgainstComparison, "number", page),
			}));

			const latestRedialsComparison = radialDataComparisonList.map((ele, index) =>
				calculateLatestRedials(ele, index, mainComparisonData)
			);

			const radialDataListCompare = mainData
				? defaultRadialData.map((ele, index) => calculateLatestRedials(ele, index, mainData, compareAgainstComparison))
				: defaultRadialData;
			const comparisonColors = radialDataListCompare.map((x, i) => {
				const comparisonItem = latestRedialsComparison.find((y) => y.id === x.id);
				const percentage = itemPercentage(x.data[0].y, averageCensus, "number");
				const percentageComparison = itemPercentage(comparisonItem.data[0].y, averageCensusComparison, "number");
				calculationData = calculateDiffBetweenPercentages(
					percentage,
					percentageComparison,
					reverseColors,
					filter,
					filterComparison,
					comparisonItem.data[0].y
				);

				return {
					comparisonColor: pickComparisonColor(calculationData.percentageDiff),
					comparingAgainstScaled: calculationData.itemTotalComparisonScaled,
				};
			});

			const updatedRadialsList = radialDataListCompare.map((x, i) => ({
				...x,
				data: [
					{
						...x.data[0],
						comparisonColor: comparisonColors[i]?.comparisonColor,
						comparingAgainstScaled: comparisonColors[i].comparingAgainstScaled,
						numberOfDays: calculationData.numberOfDays,
					},
				],
			}));

			const updatedRedialDataComparisonList = latestRedialsComparison.map((x, i) => ({
				...x,
				data: [
					{
						...x.data[0],
						comparisonColor: comparisonColors[i]?.comparisonColor,
						comparingAgainstScaled: comparisonColors[i].comparingAgainstScaled,
						numberOfDays: calculationData.numberOfDays,
					},
				],
			}));

			const mappedComparisonData = comparisonData.map((x, i) => ({
				...x,
				comparisonColor: comparisonColors[i + 1]?.comparisonColor,
				comparingAgainstScaled: comparisonColors[i + 1]?.comparingAgainstScaled,
				numberOfDays: calculationData.numberOfDays,
			}));
			setDataListComparisonBarChart(_.orderBy(mappedComparisonData, "_id", "desc"));
			setRadialDataList(updatedRadialsList);
			setRadialDataComparisonList(updatedRedialDataComparisonList);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [comparisonData, page, mainData, averageCensus, averageCensusComparison, percentageThresholds]);

	return {
		dataList,
		dataListBarChart,
		dataListComparisonBarChart,
		radialDataList,
		radialDataComparisonList,
	};
};

export default useDaysCard;
