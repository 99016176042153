import axios from "../../../../../../axios";
import { useCallback, useEffect, useState } from "react";
import update from "immutability-helper";
import { useDispatch } from "react-redux";
import styles from "./AddFacility.module.scss";
import {
  ADD_FACILITY,
  ADD_NOTIFICATION,
  UPDATE_FACILITY,
} from "../../../../../../store/types";
import _ from "lodash";
import {
  addFacility,
  updateFacility,
} from "../../../../../../services/facility.service";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import NewCloseSVG from "../../../../../../assets/svgs/new-close.svg";
import { LoadingButton } from "@mui/lab";

const AddFacility = (props) => {
  const dispatch = useDispatch();
  const [name, setName] = useState(
    props.currentEdit?.name ? props.currentEdit.name : ""
  );
  // eslint-disable-next-line
  const [email, setEmail] = useState("");
  const [showAssignUsers, setShowAssignUsers] = useState(false);

  const [users, setUsers] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [facilityId, setFacilityId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [disableAssign, setDisableAssign] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  
  const updateUserPermission = (data) => {
    const usersPerData = [];
    // eslint-disable-next-line array-callback-return
    data.map((item) => {
      const facilityIdProps = props.currentEdit._id;
      const facilities = item.facilities || [];
      const facility = _.find(facilities, { facilityId: facilityIdProps });
      let obj = {
        userId: item._id,
        p: {
          facilityId: facilityIdProps,
          access: facility?.access || false,
          read: facility?.read || false,
          write: facility?.write || false,
          delete: facility?.delete || false,
        },
      };
      usersPerData.push(obj);
    });
    setUserPermissions(usersPerData);
  };

  useEffect(() => {
    if (props.currentEdit._id) {
      setIsEdit(true);
      setShowAssignUsers(true);
      setFacilityId(props.currentEdit._id);
    }
  }, [props]);

  const getUsers = async () => {
    let re = await axios.get(`/api/user/${localStorage.getItem("accountId")}`);
    if (props.currentEdit._id) {
      updateUserPermission(re.data);
    }
    setUsers(re.data || []);
  };

  const updatePermissions = async (value, field, userId) => {
    let idx = userPermissions.findIndex((elem) => elem.userId === userId);

    if (idx === -1) {
      setUserPermissions([
        ...userPermissions,
        {
          userId,
          p: {
            facilityId: facilityId,
            read: false,
            access: false,
            write: false,
            delete: false,
            [field]: value,
          },
        },
      ]);
    } else {
      if (field === "access") {
        let newData = update(userPermissions, {
          [idx]: {
            p: {
              [field]: { $set: value },
              read: { $set: value },
              write: { $set: value },
            },
          },
        });
        setUserPermissions(newData);
      } else {
        let newData = update(userPermissions, {
          [idx]: {
            p: {
              [field]: { $set: value },
            },
          },
        });
        setUserPermissions(newData);
      }
    }
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, [localStorage.getItem("accountId")]);

  const save = async (e, assignUsers, showNotif) => {
    if (!name) {
      setIsError(true);
      return false;
    }
    setIsLoading(true);
    let re;

    if (props.currentEdit._id) {
      re = await updateFacility(props.currentEdit._id, {
        name,
        email,
      });
    } else {
      re = await addFacility({
        name,
        email,
        accountId: localStorage.getItem("accountId"),
      });
    }
    if (re) {
      setFacilityId(re._id);
      if (re && re._id) {
        if (
          localStorage.getItem("facilityId") === undefined ||
          localStorage.getItem("facilityId") == null
        ) {
          localStorage.setItem("facilityId", re._id);
        }
      }

      if (!props.currentEdit._id) {
        props.facilityAdded(re);
        setIsLoading(false);
        dispatch({
          type: ADD_FACILITY,
          payload: re,
        });
        if (assignUsers) {
          setShowAssignUsers(true);
        } else {
          props.close();
        }
      } else {
        props.facilityUpdated(re);
        setIsLoading(false);
        dispatch({
          type: UPDATE_FACILITY,
          payload: re,
        });
        props.clearCurrentEdit();
        props.close();
      }
    }
    setIsLoading(false);
  };

  const isChecked = (userId, type) => {
    return (
      userPermissions.find((user) => user.userId === userId)?.p?.[type] || false
    );
  };

  const savePermissions = async () => {
    if (isEdit) {
      const finishedSuccessfully = await save();
      setIsLoading(true);
      if (finishedSuccessfully === false) {
        return;
      }
      setDisableAssign(true);
      userPermissions.forEach(async (item) => {
        let thisUsersPermissions = users.find(
          (elem) => elem._id === item.userId
        ).facilities;
        const facility = _.find(thisUsersPermissions, { facilityId });
        if (facility) {
          thisUsersPermissions.map((ele) => {
            if (ele.facilityId === item?.p.facilityId) {
              return Object.assign(ele, item?.p);
            } else {
              return ele;
            }
          });
        } else {
          thisUsersPermissions.push({
            ...item?.p,
            facilityId,
          });
        }
        await axios.put(`/api/user/${item.userId}`, {
          facilities: [...thisUsersPermissions],
        });
      });
      setDisableAssign(false);
    } else {
      setDisableAssign(true);
      userPermissions.forEach(async (item) => {
        let thisUsersPermissions = users.find(
          (elem) => elem._id === item.userId
        ).facilities;
        const facility = _.find(thisUsersPermissions, { facilityId });
        if (facility) {
          thisUsersPermissions.map((ele) => {
            if (ele.facilityId === item?.p.facilityId) {
              return Object.assign(ele, item?.p);
            } else {
              return ele;
            }
          });
        } else {
          thisUsersPermissions.push({
            ...item?.p,
            facilityId,
          });
        }
        await axios.put(`/api/user/${item.userId}`, {
          facilities: [...thisUsersPermissions],
        });
      });
      setIsLoading(true);
      setDisableAssign(false);
      setShowAssignUsers(false);
      dispatch({
        type: ADD_NOTIFICATION,
        payload: {
          label: "Facility and users added successfully!",
          type: "success",
          id: "facilityAndUsersAdded",
        },
      });
    }
    props.close();
  };

  const handleClose = useCallback(() => {
    props.close();
  }, [props]);

  return (
    <>
      <Dialog
        open={true}
        onClose={handleClose}
        scroll={"paper"}
        maxWidth="xs"
        fullWidth={true}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {!showAssignUsers &&
            (props.currentEdit?._id ? "Edit facility" : "Add facility")}
          {showAssignUsers && (isEdit ? "Edit Facility" : "Assign users")}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <NewCloseSVG />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          {!showAssignUsers && (
            <div className={`m-b-15 inputWrpr`}>
              <label
                className={`ffmr fs12 ${isError && !name ? "error" : null}`}
              >
                Facility Name
              </label>
              <input
                style={{ ...(isError && !name && { borderColor: "red" }) }}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          )}
          {showAssignUsers && (
            <>
              <div className={styles.assignUsersPopup} style={{ zIndex: 102 }}>
                <div className={`${styles.popupCntnt}`}>
                  {isEdit ? (
                    <div className={`m-b-15 inputWrpr`}>
                      <label
                        className={`ffmr fs12 ${isError && !name ? "error" : null
                          }`}
                      >
                        Facility Name
                      </label>
                      <input
                        style={{
                          ...(isError && !name && { borderColor: "red" }),
                        }}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  ) : (
                    <h3 className={`tac ffmm m-b-10 fs14`}>{name}</h3>
                  )}
                  <input
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    type="text"
                    placeholder="Search users"
                    style={{
                      width: "100%",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      padding: "10px",
                      height: "40px",
                      marginBottom: "10px",
                    }}
                  ></input>
                  {users.length > 0 &&
                    users
                      .filter((user) =>
                        user.fullName
                          ?.toLowerCase()
                          .includes(searchValue?.toLowerCase())
                      )
                      .map((user) => {
                        return (
                          <div
                            className={`df aic ffmr fs14 ${styles.line}`}
                            key={user._id}
                          >
                            {user.fullName}
                            <div className={`df aic mla`}>
                              {user && user.role?.slug !== "user" ? (
                                <div
                                  className={`df acc m-r-10 ffmr fs10 ${styles.sec}`}
                                >
                                  <label
                                    htmlFor={`access_${user._id}`}
                                    className={`${isChecked(user._id, "access")
                                        ? styles.checked
                                        : ""
                                      }`}
                                  >
                                    Access
                                  </label>

                                  <input
                                    checked={isChecked(user._id, "access")}
                                    type="checkbox"
                                    id={`access_${user._id}`}
                                    value={`access-${user._id}`}
                                    onChange={(e) =>
                                      updatePermissions(
                                        e.target.checked,
                                        "access",
                                        user._id
                                      )
                                    }
                                  />
                                </div>
                              ) : (
                                <>
                                  <div
                                    className={`df acc m-r-10 ffmr fs10 ${styles.sec}`}
                                  >
                                    <label
                                      htmlFor={`read_${user._id}`}
                                      className={
                                        isChecked(user._id, "read")
                                          ? styles.checked
                                          : ""
                                      }
                                    >
                                      Read
                                    </label>

                                    <input
                                      checked={isChecked(user._id, "read")}
                                      type="checkbox"
                                      id={`read_${user._id}`}
                                      value={`read-${user._id}`}
                                      onChange={(e) =>
                                        updatePermissions(
                                          e.target.checked,
                                          "read",
                                          user._id
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    className={`df acc m-r-10 ffmr fs10 ${styles.sec}`}
                                  >
                                    <label
                                      htmlFor={`write_${user._id}`}
                                      className={
                                        isChecked(user._id, "write")
                                          ? styles.checked
                                          : ""
                                      }
                                    >
                                      Write
                                    </label>
                                    <input
                                      type="checkbox"
                                      id={`write_${user._id}`}
                                      value={`write_-${user._id}`}
                                      onChange={(e) =>
                                        updatePermissions(
                                          e.target.checked,
                                          "write",
                                          user._id
                                        )
                                      }
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {!showAssignUsers && (
            <>
              <Button onClick={handleClose} variant="outlined">
                Cancel
              </Button>
              <LoadingButton
                onClick={(e) => save(e, false, true)}
                variant="contained"
                loading={isLoading}
              >
                Save
              </LoadingButton>
              {!props.currentEdit._id && (
                <Button
                  onClick={(e) => save(e, true, false)}
                  variant="outlined"
                  disabled={!name}
                  loading={isLoading}
                >
                  Save and assign users
                </Button>
              )}
            </>
          )}
          {showAssignUsers && (
            <>
              <div className={`mla`}>
                <Button
                  variant="outlined"
                  className={`ffmr fs14 m-r-8 ${styles.text}`}
                  onClick={() => {
                    setShowAssignUsers(false);
                    props.close();
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  disabled={disableAssign}
                  className={`ffmr fs14`}
                  onClick={() => savePermissions()}
                  loading={isLoading}
                >
                  Save
                </LoadingButton>
              </div>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddFacility;
