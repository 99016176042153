import { Divider, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import classNames from "classnames";
import { calcProPercentsBasedOnFilterAndDays, itemPercentage, percentageLabel } from "../../../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../../utilis/percentage-comparison";
import ColorBox from "../../../shared/dashboard/ColorBox";
import styles from "./TotalOverallCard.module.scss";

const TotalOverallIncomingCard = ({
	total,
	totalComparison,
	page,
	isProjection = false,
	totalProjection = 0,
	totalProjectionPercentage,
	incomingDetailsTotal,
	subTitleText = "Avg. Cencus",
	isCompareColor,
	averageCensus,
	filter,
	filterComparison,
	averageCensusComparison,
	loading,
	projectionDays,
	filterTotal,
}) => {
	const percentage = itemPercentage(total, averageCensus, "number", page, true);
	const percentageComparison = itemPercentage(totalComparison, averageCensusComparison, "number", page, true);
	const { percentageDiff, itemTotalComparisonScaled, numberOfDays } = calculateDiffBetweenPercentages(
		percentage,
		percentageComparison,
		true,
		filter,
		filterComparison,
		totalComparison,
		projectionDays
	);
	const comparisonDiffColor = pickComparisonColor(percentageDiff);

	return (
		<div className={`df aic p-t-20 p-b-25 ${styles.totalOverall} ${styles.withSubTotalCardMain}`}>
			<Grid container direction={"row"}>
				<Grid item xs={6}>
					<Stack direction={"column"} justifyContent={"center"} alignItems={"center"}>
						<Stack item direction={"column"} alignContent={"center"}>
							<div
								className={classNames(
									"ffmar-bold",
									"df",
									"aic",
									"fw700",
									"fs49",
									styles.gradientTextPrimary,
									styles.withSubTotalCard,
									loading && styles.skeleton
								)}
							>
								{loading ? (
									<span className="skeleton-text">0</span>
								) : (
									calcProPercentsBasedOnFilterAndDays(total, filter, projectionDays)
								)}
								{isCompareColor && !loading && (
									<ColorBox
										color={comparisonDiffColor}
										comparingAgainst={totalComparison}
										comparingAgainstScaled={itemTotalComparisonScaled}
										numberOfDays={numberOfDays}
									/>
								)}
							</div>
							{percentage && (
								<div className={`${styles.textNumberContainer} ${styles.textNumberSubContainer}`}>
									<div
										className={classNames(
											styles.gradientTextSecondary,
											loading && styles.skeleton,
											loading && "skeleton-text"
										)}
									>{`(${
										loading
											? 0
											: calcProPercentsBasedOnFilterAndDays(
													percentage,
													filter,
													projectionDays,
													filterTotal,
													averageCensus
											  )
									})%`}</div>
									<div className={classNames(styles.gradientTextSubtitle, loading && "skeleton-text")}>
										{loading ? (
											<span className="skeleton-text" style={{ visibility: "hidden" }}>
												{subTitleText}
											</span>
										) : (
											subTitleText
										)}
									</div>
								</div>
							)}
						</Stack>
					</Stack>
				</Grid>
				<Divider orientation="vertical" flexItem />
				<Grid item xs={5}>
					<Stack
						direction={"column"}
						spacing={1}
						justifyContent={"center"}
						alignContent={"center"}
						alignItems={"center"}
						sx={{ height: "100%" }}
					>
						<SubTotalCardItem
							title={"Admission"}
							loading={loading}
							total={calcProPercentsBasedOnFilterAndDays(
								incomingDetailsTotal?.totalAdmission || 0,
								filter,
								projectionDays
							)}
							percentage={percentageLabel(
								calcProPercentsBasedOnFilterAndDays(
									itemPercentage(incomingDetailsTotal?.totalAdmission || 0, total, "number"),
									filter,
									projectionDays,
									filterTotal,
									averageCensus
								)
							)}
						/>
						<SubTotalCardItem
							title={"Readmission"}
							loading={loading}
							total={calcProPercentsBasedOnFilterAndDays(
								incomingDetailsTotal?.totalReAdmission || 0,
								filter,
								projectionDays
							)}
							percentage={percentageLabel(
								calcProPercentsBasedOnFilterAndDays(
									itemPercentage(incomingDetailsTotal?.totalReAdmission || 0, total, "number"),
									filter,
									projectionDays,
									filterTotal,
									averageCensus
								)
							)}
						/>
						<SubTotalCardItem
							title={"Hospital Return"}
							loading={loading}
							total={calcProPercentsBasedOnFilterAndDays(
								incomingDetailsTotal?.totalHospitalReturn || 0,
								filter,
								projectionDays
							)}
							percentage={percentageLabel(
								calcProPercentsBasedOnFilterAndDays(
									itemPercentage(incomingDetailsTotal?.totalHospitalReturn || 0, total, "number"),
									filter,
									projectionDays,
									filterTotal,
									averageCensus
								)
							)}
						/>
					</Stack>
				</Grid>
			</Grid>
		</div>
	);
};

export default TotalOverallIncomingCard;

const SubTotalCardItem = ({ title, total, percentage, loading }) => {
	return (
		<Stack item sx={{ width: "90%", borderRadius: "5px" }} className={loading && "skeleton"}>
			<div className={classNames(styles.projectionContainer, styles.withSubTotalCard, loading && styles.skeleton)}>
				<div className={`${styles.projectionTitle}`}>
					{loading ? (
						<span className="skeleton-text" style={{ visibility: "hidden" }}>
							{title}
						</span>
					) : (
						title
					)}
				</div>
				<div className={`${styles.projectionSubTitle}`}>
					{loading ? (
						<span className="skeleton-text" style={{ visibility: "hidden" }}>
							{total} {percentage}
						</span>
					) : (
						`${total} ${percentage}`
					)}
				</div>
			</div>
		</Stack>
	);
};
