import CloseIcon from "../../../../../assets/svgs/close.svg";
import styles from "./AddPatient.module.scss";

const ValidationADT = ({ selectedItem, close, hideHeader }) => {
    const { errors } = selectedItem;
    return (
        <div className={styles.validationADT}>
            <div className={styles.validationADTOverlay}></div>
            <div className={styles.validationADTContent}>
                <div className={`df aic ${styles.hdr}`}>
                    <p className={`df aic ffmm fs16`}>Validation Error</p>
                    <div className={`mla`}>
                        <div className={`df acc ${styles.closeBtn}`} onClick={() => close()}>
                            <CloseIcon />
                        </div>
                    </div>
                </div>
                <div className={`p-t-8 p-r-20 p-b-20 p-l-20 df ffc ${styles.stepThree}`}>
                    {!hideHeader && <b>Please fill following required fields.</b>}
                    <ui className={`m-t-10 ${styles.errorList}`}>
                        {errors &&
                            errors.length > 0 &&
                            errors.map((item, index) => (
                                <li key={index}>
                                    <span>{item}</span>
                                </li>
                            ))}
                    </ui>
                </div>
            </div>
        </div>
    );
};

export default ValidationADT;
