import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from "lodash";
import { AUTH_ROLES } from '../../types/auth.type';

const USER_ROLES = ["editAdt"];
const ADMIN_PERMISSIONS = ["addUser", "editUser", "deleteUser"];
const OWNER_PERMISSIONS = ["addFacility", "editFacility", "deleteFacility", "addUser", "editUser", "deleteUser", "editAdt"];

const ShowForPermissionComponent = (props) => {
    const { permission = null, children, type } = props;
    if (permission.role === AUTH_ROLES.SUPER) {
        return children;
    }
    if (permission.role === AUTH_ROLES.OWNER || permission.role === AUTH_ROLES.TOTAL) {
        if (_.includes(OWNER_PERMISSIONS, type)) {
            return children;
        } else {
            return null;
        }
    }

    if (permission.role === AUTH_ROLES.ADMIN || permission.role === AUTH_ROLES.REGIONAL) {
        if (_.includes(ADMIN_PERMISSIONS, type)) {
            return children;
        } else {
            return null;
        }
    }

    if (permission.role === AUTH_ROLES.USER) {
        if (_.includes(USER_ROLES, type)) {
            return children;
        } else {
            return null;
        }
    }
    return null
};

ShowForPermissionComponent.propTypes = {
    permission: PropTypes.object
};


const mapStateToProps = state => ({
    permission: state.permission
});

export const ShowForPermission = connect(mapStateToProps)(ShowForPermissionComponent);