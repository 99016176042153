import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddFacility from "../../components/settings/accounts/account-landing/facilities/add-facility/AddFacility";
import EditSVG from "../../assets/svgs/edit.svg";
import DeleteSVG from "../../assets/svgs/delete.svg";
import Button from "../../components/ui/button/Button";
import { DELETE_FACILITY } from "../../store/types";
import { useLocation } from "react-router";
import { ShowForPermission } from "../../components/shared/ShowForPermission";
import { getManageFacilities } from "../../services/user.service";
import PopupDialog from "../../components/popupDialog/PopupDialog";
import styles from "../../assets/styles/Facilities.module.scss";
import { removeActiveFacility } from "../../store/reducers/activeFacilities.slice";
import axios from "../../axios";
import { AUTH_ROLES } from "../../types/auth.type";
import _ from "lodash";

const AccountFacilities = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [deleteFacilityState, setDeleteFacilityState] = useState({
    state: false,
    facilityId: null,
  });
  const { auth, permission } = useSelector(({ auth, permission }) => ({ auth, permission }));
  const [deleteFacilityLoading, setDeleteFacilityLoading] = useState(false);

  const [facilities, setFacilities] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [currentEdit, setCurrentEdit] = useState({});
  const activeFacilities = useSelector((state) => state.activeFacilities.facilities);
  
  const getFacilities = async () => {
    const resData = await getManageFacilities({ accountId: localStorage.getItem("accountId") });
    setFacilities(resData);
  };

  useEffect(() => {
    getFacilities();
    const search = new URLSearchParams(location.search);
    setShowAdd(search.get("showAdd") === "true");
    // eslint-disable-next-line
  }, [auth]);

  const toggleShowAdd = () => setShowAdd(!showAdd);

  const facilityAdded = facility => {
    const newData = [...facilities, facility];

    setFacilities(newData);
  };

  const facilityUpdated = facility => {
    const newData = facilities.map(f => {
      if (f._id === facility._id) {
        return facility;
      }
      return f;
    });

    setFacilities(newData);
  };

  const deleteFacility = async id => {
    await axios.delete(`/api/facility/${id}`);
    const newData = facilities.filter(f => f._id !== id);
    dispatch({
      type: DELETE_FACILITY,
      payload: id,
    });
    dispatch(removeActiveFacility(id))

    setFacilities(newData);
  };

  return (
    <div className={styles.accountFacilities}>
      <div className={`df aic ${styles.pageHdr}`}>
        {/* <h1 className={`df aic ffmsb fs16`}>
          {" "}
          <p onClick={() => navigate("../")} className={`ffmr m-r-20`}>
            Back
          </p>
          Account Facilities
        </h1> */}
        <ShowForPermission type="addFacility">
          <div className={`mla`}>
            <Button action={toggleShowAdd} buttonStyle="theme" text="Add Facility" size="medium" />
          </div>
        </ShowForPermission>
      </div>

      {showAdd && permission.role !== AUTH_ROLES.USER && (
        <AddFacility
          facilityUpdated={facilityUpdated}
          currentEdit={currentEdit}
          clearCurrentEdit={() => setCurrentEdit({})}
          close={()=>{
            setCurrentEdit({})
            toggleShowAdd()
          }}
          facilityAdded={facilityAdded}
        />
      )}
      <div className={styles.listWrpr}>
        {facilities.map(facility => (
          <div className={`ffmm fs14 df aic ${styles.line}`}>
            <h2 className={`ffmm fs14`}>{facility.name}</h2>
            <h3>{facility.type}</h3>

            <div className={`mla df aic`}>
              <ShowForPermission type="editFacility">
                <div
                  className="m-r-8 cp"
                  onClick={() => {
                    setCurrentEdit(facility);
                    setShowAdd(true);
                  }}
                >
                  <EditSVG fill="#4879f5" />
                </div>
              </ShowForPermission>
              
              <ShowForPermission type="deleteFacility">
                <div
                  className={`m-l-10 cp`}
                  onClick={() => { setDeleteFacilityState({ state: true, facilityId: facility._id }); }}
                >
                  <DeleteSVG fill="#F93B5F" />
                </div>
              </ShowForPermission>
            </div>
          </div>
        ))}
      </div>
      <PopupDialog
        state={deleteFacilityState.state}
        isDestructiveAction={true}
        isLoading={deleteFacilityLoading}
        onClose={() => setDeleteFacilityState({ state: false, facilityId: null })}
        onConfirm={async () => {
          setDeleteFacilityLoading(true);
          await deleteFacility(deleteFacilityState.facilityId);
          setDeleteFacilityLoading(false);
          setDeleteFacilityState({ state: false, facilityId: null });
        }}
        dialogMessage={_.includes(activeFacilities, deleteFacilityState.facilityId) ? "You cannot delete this facility while you are viewing it. Please switch to a different facility before attempting to delete this account." : "Are you sure you want to delete this facility?"}
        {...(!_.includes(activeFacilities, deleteFacilityState.facilityId)) && { confirmBtnText: "Delete" }}
      />
    </div>
  );
};

export default AccountFacilities;
