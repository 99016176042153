import React from "react";
import styled from "styled-components";
import { dateFormat } from "../../utilis/date-formats";
import { Typography } from "@mui/material";
import GraphReport from "./GraphReport";
import { isOnlyHospitalTabAccess } from "../../utilis/common";

const Styles = styled.div`
  table {
    border: 0.2em solid black;
  }
  td,
  th {
    padding: 7px;
    border: 0.00001em solid #bfb8b8;
  }

  th {
    font-weight: 700;
  }

  table {
    text-align: center;
  }
  .print-table-ref table {    
    border-top : 0px !important;    
  }
`;

export const TitleHeader = (props) => {
    const { title, ...others } = props;
    return (
        <th align="center" textAlign="center" {...others}>
            <b>{title}</b>
        </th>
    );
};

const MainHeaderTable = (props) => {
    const {
        facilityNames = null,
        filter,
        dbData,
        isLockedTotalByCensus = false,
        isGraphReport = false,
        percentageBy, 
        percentageAgainst
    } = props;
    return (
        <>
            <table style={{ width: "70%" }}>
                <tr>
                    <TitleHeader title="Facility" />
                    <td colSpan={2}>{facilityNames}</td>
                </tr>
                <tr>
                    <TitleHeader title="Date" />
                    <td>
                        {dateFormat(filter?.startDate, "MM/DD/YYYY")}
                        {/* {toDisplayTime(filter?.startDate)} */}
                    </td>
                    <td>
                        {dateFormat(filter?.endDate, "MM/DD/YYYY")}
                        {/* {toDisplayTime(filter?.endDate)} */}
                    </td>
                </tr>
                <tr>
                    <TitleHeader title="Bed cap" />
                    <td align="center" colSpan={2}>
                        {dbData?.bedCapacity}
                    </td>
                </tr>
                {!isOnlyHospitalTabAccess() && (
                    <tr>
                        <TitleHeader title="Census as of" />
                        <td align="center" colSpan={2}>
                            {dbData?.censusAsOfNow}
                        </td>
                    </tr>
                )}
            </table>
            <br />
            <br />
            {!isGraphReport && (
                <table style={{ width: "100%" }}>
                    <tr textAlign="center">
                        <TitleHeader
                            title={`${percentageBy} ${isLockedTotalByCensus ? "(Locked)" : ""}`}
                        />
                    </tr>
                    <tr>
                        <th>{(!isOnlyHospitalTabAccess() ? percentageAgainst : dbData?.bedCapacity) || 0}</th>
                    </tr>
                </table>
            )}
        </>
    );
};

const ReportTable = function ReportTable(props, ref) {
    const {
        cardContentData = [],
        isPercentage,
        graphHtml,
        graphData,
        graphCardData = [],
        isGraphReport = false,
        alreadyChecked
    } = props;

    return (
        <html ref={ref}>
            <Typography
                sx={{ mb: 4, mt: 0 }}
                align="center"
                textAlign={"center"}
                fontSize={"24px"}
                variant="h5"
            >
                Report
            </Typography>
            <Styles>
                <MainHeaderTable {...props} />
                {!isGraphReport && (
                    <>
                        <br />
                        <br />
                        {cardContentData.length > 0 &&
                            cardContentData.map((ele) => (
                                <>
                                    <table style={{ width: "100%" }}>
                                        <tr>
                                            <TitleHeader
                                                colSpan={ele.data.reduce(
                                                    (a, obj) => a + Object.keys(obj).length,
                                                    0
                                                )}
                                                title={ele?.title}
                                            />
                                        </tr>
                                        {ele?.data &&
                                            ele.data.map((obj) => (
                                                <tr>
                                                    {obj?.label && <td align="center">{obj?.label}</td>}
                                                    <td>{obj?.total || obj?.value}</td>
                                                    {isPercentage && <td>{obj?.percentage}</td>}
                                                    <td>{obj?.percentageAgainst}</td>
                                                    {obj?.filterItem && <td>{obj?.filterItem}</td>}
                                                </tr>
                                            ))}
                                    </table>
                                    <br />
                                    <br />
                                </>
                            ))}
                    </>
                )}

                {isGraphReport && (
                    <>

                        {graphCardData.length > 0 &&
                            <>
                                <table style={{ width: "100%" }}>
                                    <tr>
                                        <TitleHeader title={"Filters"} />
                                    </tr>
                                    {graphCardData.map((ele) => (
                                        <>
                                            <tr>
                                                <TitleHeader title={ele?.title} />
                                            </tr>
                                            <tr>
                                                {ele?.filterItemGraph &&
                                                    ele?.filterItemGraph.length > 0 && (
                                                        <td>{ele?.filterItemGraph.join(" | ")}</td>
                                                    )}
                                            </tr>
                                        </>
                                    ))
                                    }
                                </table>
                                <br />
                                <br />
                            </>
                        }
                        {graphData && (
                            <GraphReport
                                graphData={graphData}
                                graphHtml={graphHtml}
                                alreadyChecked={alreadyChecked}
                            />
                        )}
                    </>
                )}
            </Styles>
        </html>
    );
};

export default React.forwardRef(ReportTable);
