import * as React from "react";
import { Stack } from "@mui/material";
import SelectedDateContainer from "./SelectedDateContainer";
import DetailsCardTab from "./DetailsCardTab";
import ReportButton from "../ReportButton";
import CustomExcelReportColumnDialog from "./CustomExcelReportColumnDialog";
import { createSetting } from "../../../services/settings.service";
import { PAGE_TYPE } from "../../../types/pages.type";

export default function ChartDetailsTab({
    queryFilters,
    selectedTab,
    handleTabButton,
    handleOnClickReport,
    loading,
    handleSendAutomaticallyReport,
    handleExcelReport,
    tableFilterTypeOptions,
    setSelectedFacility,
    selectedFacility,
    questions,
    tableFilterType,
    tableFilterTypeSetting,
    pageType,
    subPageType
}) {
    const [isCustomerColumn, setIsCustomerColumn] = React.useState(false);
    const [selectedQuestions, setSelectedQuestions] = React.useState([]);

    React.useEffect(() => {
        if (questions && questions.length > 0) {
            setSelectedQuestions(questions);
        }
        // eslint-disable-next-line
    }, [questions, pageType]);

    const handleSaveExcelColumn = React.useCallback((selectedColumns) => {
        createSetting({
            selectedColumns,
            selectedTab: tableFilterType || tableFilterTypeSetting,
            pageType: subPageType === PAGE_TYPE.ADT ? subPageType : pageType,
            ...subPageType === PAGE_TYPE.ADT && { subPageType }
        });
    }, [tableFilterType, pageType, tableFilterTypeSetting, subPageType]);

    const handleExcelReportCustom = React.useCallback((e) => {
        handleExcelReport(e);
        handleSaveExcelColumn(e)
    }, [handleExcelReport, handleSaveExcelColumn]);

    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}
            >
                <Stack item direction={"row"} spacing={1}>
                    <DetailsCardTab
                        selectedTab={selectedTab}
                        handleTabButton={handleTabButton}
                        tableFilterTypeOptions={tableFilterTypeOptions}
                        setSelectedFacility={setSelectedFacility}
                        selectedFacility={selectedFacility}
                    />
                </Stack>
                <Stack>
                    <Stack direction={"row"}>
                        <ReportButton
                            handleSaveExcelColumn={handleSaveExcelColumn}
                            handleOnClickReport={handleOnClickReport}
                            handleSendAutomaticallyReport={handleSendAutomaticallyReport}
                            loading={loading}
                            title={"report"}
                            sx={{ mt: "-7px" }}
                            selectedTab={selectedTab}
                            selectedTabSetting={tableFilterType || tableFilterTypeSetting}
                            handleExcelReport={handleExcelReport}
                            handleExcelReportCustom={() => { setIsCustomerColumn(true) }}
                            tableFilterType={tableFilterType}
                            selectedQuestions={selectedQuestions}
                            pageType={pageType}
                            subPageType={subPageType}
                        />
                        <SelectedDateContainer queryFilters={queryFilters} />
                    </Stack>
                </Stack>
            </Stack>
            {isCustomerColumn &&
                <CustomExcelReportColumnDialog
                    pageType={pageType}
                    subPageType={subPageType}
                    selectedTab={tableFilterType || tableFilterTypeSetting}
                    selectedQuestions={selectedQuestions}
                    handleClose={() => setIsCustomerColumn(false)}
                    handleSave={(e) => {
                        setIsCustomerColumn(false);
                        handleExcelReportCustom(e)
                    }}
                />
            }
        </>
    );
}
