import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router";
import { signOut } from "../../utilis/signout";

const InactivityLogout = () => {
	const navigate = useNavigate();
	const handleSignOut = () => signOut(navigate);

	useIdleTimer({
		onIdle: handleSignOut,
		timeout: 1800000,
	});

	return null;
};

export default InactivityLogout;
