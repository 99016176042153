import { getAllRoles, saveRole, updateRoleData, getOnlyRolesAll } from "./api/role.api";

const getRoles = async (type) => {
    try {
        const res = await getAllRoles(type);
        return res;
    } catch (e) {
        console.log(e);
    }
};

const getOnlyRoles = async (type) => {
    try {
        const res = await getOnlyRolesAll(type);
        return res;
    } catch (e) {
        console.log(e);
    }
};


const createRole = async (body) => {
    try {
        const res = await saveRole(body);
        return res;
    } catch (e) {
        console.log(e);
    }
};

const updateRole = async (id, body) => {
    try {
        const res = await updateRoleData(id, body);
        return res;
    } catch (e) {
        console.log(e);
    }
};


export {
    getRoles,
    createRole,
    updateRole,
    getOnlyRoles
}
