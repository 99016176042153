const OfAvgCensusSVG = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="47" height="42" viewBox="0 0 47 42" fill="none">
		<path
			d="M0 5.08437C0 2.27635 2.23858 0 5 0H42C44.7614 0 47 2.27635 47 5.08437V36.9156C47 39.7237 44.7614 42 42 42H5C2.23858 42 0 39.7236 0 36.9156V5.08437Z"
			fill="#444652"
		/>
		<path
			d="M35.0752 30.6464H13.8647V9.07794C13.8647 8.66779 13.5383 8.33591 13.135 8.33591C12.7316 8.33591 12.4053 8.66779 12.4053 9.07794V12.8376H11.6269C11.2235 12.8376 10.8972 13.1695 10.8972 13.5796C10.8972 13.9898 11.2235 14.3217 11.6269 14.3217H12.4053V17.2898H11.6269C11.2235 17.2898 10.8972 17.6217 10.8972 18.0318C10.8972 18.442 11.2235 18.7739 11.6269 18.7739H12.4053V21.742H11.6269C11.2235 21.742 10.8972 22.0739 10.8972 22.484C10.8972 22.8942 11.2235 23.2261 11.6269 23.2261H12.4053V26.1942H11.6269C11.2235 26.1942 10.8972 26.5261 10.8972 26.9362C10.8972 27.3464 11.2235 27.6783 11.6269 27.6783H12.4053V30.6464H11.6269C11.2235 30.6464 10.8972 30.9783 10.8972 31.3884C10.8972 31.7986 11.2235 32.1305 11.6269 32.1305H12.4053V32.922C12.4053 33.3321 12.7316 33.664 13.135 33.664C13.5383 33.664 13.8647 33.3321 13.8647 32.922V32.1305H16.7836V32.922C16.7836 33.3321 17.11 33.664 17.5133 33.664C17.9166 33.664 18.243 33.3321 18.243 32.922V32.1305H21.1619V32.922C21.1619 33.3321 21.4883 33.664 21.8916 33.664C22.295 33.664 22.6213 33.3321 22.6213 32.922V32.1305H25.5402V32.922C25.5402 33.3321 25.8666 33.664 26.2699 33.664C26.6733 33.664 26.9997 33.3321 26.9997 32.922V32.1305H29.9186V32.922C29.9186 33.3321 30.2449 33.664 30.6483 33.664C31.0516 33.664 31.378 33.3321 31.378 32.922V32.1305H35.0752C35.4786 32.1305 35.805 31.7986 35.805 31.3884C35.805 30.9783 35.4786 30.6464 35.0752 30.6464Z"
			fill="#E7B400"
		/>
		<path
			d="M34.2969 16.4983C34.2969 16.0881 33.9705 15.7563 33.5672 15.7563C32.097 15.7563 30.9761 14.1091 29.7889 12.3656C28.4398 10.3845 27.0453 8.33591 24.8105 8.33591C22.5757 8.33591 21.1812 10.3845 19.8322 12.3656C18.645 14.1091 17.524 15.7563 16.0539 15.7563C15.6505 15.7563 15.3241 16.0881 15.3241 16.4983C15.3241 16.9084 15.6505 17.2403 16.0539 17.2403C18.2887 17.2403 19.6832 15.1918 21.0322 13.2106C22.2194 11.4671 23.3404 9.81998 24.8105 9.81998C26.2807 9.81998 27.4016 11.4671 28.5888 13.2106C29.9378 15.1918 31.3324 17.2403 33.5672 17.2403C33.9705 17.2403 34.2969 16.9084 34.2969 16.4983ZM20.4322 18.7244H17.5133C17.11 18.7244 16.7836 19.0563 16.7836 19.4664V28.4203C16.7836 28.8305 17.11 29.1623 17.5133 29.1623H20.4322C20.8355 29.1623 21.1619 28.8305 21.1619 28.4203V19.4664C21.1619 19.0563 20.8355 18.7244 20.4322 18.7244Z"
			fill="#E7B400"
		/>
		<path
			d="M26.2699 14.2722H23.3511C22.9477 14.2722 22.6213 14.6041 22.6213 15.0142V28.4203C22.6213 28.8305 22.9477 29.1623 23.3511 29.1623H26.2699C26.6733 29.1623 26.9997 28.8305 26.9997 28.4203V15.0142C26.9997 14.6041 26.6733 14.2722 26.2699 14.2722ZM29.1888 18.7244C28.7855 18.7244 28.4591 19.0563 28.4591 19.4664V28.4203C28.4591 28.8305 28.7855 29.1623 29.1888 29.1623H32.1077C32.5111 29.1623 32.8374 28.8305 32.8374 28.4203V19.4664C32.8374 19.0563 32.5111 18.7244 32.1077 18.7244H29.1888Z"
			fill="#E7B400"
		/>
	</svg>
);

export default OfAvgCensusSVG;
