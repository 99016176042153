// types
import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_FILTER } from "../../data/common.data";
import { OVERALL_PAGE_SUB_TYPE } from "../../types/pages.type";

export const DEFAULT_OVERALL_DATA = {
	insuranceData: [],
	doctorData: [],
	floorsData: [],
	ninetyDaysData: [],
	priorityData: [],
};

const DEFAULT_DB_DATA = {
	total: 0,
	censusAverage: 0,
	totalIncoming: 0,
	totalOutgoing: 0,
};
// initial state
const initialState = {
	selectedFilter: null,
	transferType: OVERALL_PAGE_SUB_TYPE.TOTAL,
	mainTotal: 0,

	detailsDialog: {
		isOpen: false,
		type: null,
		item: null,
	},
	filter: DEFAULT_FILTER,
	cardFilter: DEFAULT_OVERALL_DATA,
	dbData: DEFAULT_DB_DATA,
	adtType: {
		type: "Admissions",
		buttons: [],
	},
	dbSelectedFilters: null,
	isResetFilter: false,
	filterTotal: null,
	lockedTotal: null,
	mainNumPercentage: null,
	loading: false,
	facilityPercentage: [],
	lockedByFacility: [],
	isCensusTotalLocked: false,
	lockedTotalBy: null	
};

// ==============================|| SLICE - OVERALL PAGE ||============================== //

const admission = createSlice({
	name: "overall",
	initialState,
	reducers: {
		setLockedByFacility(state, action) {
			state.lockedByFacility = action.payload;
		},
		setIsCensusTotalLocked(state, action) {
			state.isCensusTotalLocked = action.payload;
		},
		setFacilityPercentage(state, action) {
			state.facilityPercentage = action.payload;
		},
		setLockTotal(state, action) {
			state.lockedTotal = action.payload;
		},
		setDefaultData(state, action) {
			state.dbData = DEFAULT_DB_DATA;
		},
		setTransferType(state, action) {
			state.transferType = action.payload;
		},
		setLoading(state, action) {
			state.loading = action.payload;
		},
		setDefaultOverAllData(state, action) {
			state.transferType = OVERALL_PAGE_SUB_TYPE.TOTAL;
			state.cardFilter = DEFAULT_OVERALL_DATA;
			state.filter = DEFAULT_FILTER;
		},

		setDBSelectedFilters(state, action) {
			state.dbSelectedFilters = action.payload;
		},

		setResetFilter(state, action) {
			state.isResetFilter = action.payload;
		},

		setMainTotal(state, action) {
			state.mainTotal = action.payload;
		},

		openDetailsDialog(state, action) {
			state.detailsDialog = { ...action.payload };
		},

		closeDetailsDialog(state, action) {
			state.detailsDialog = {
				isOpen: false,
				type: null,
				item: null,
			};
		},

		setFilterDateRange(state, action) {
			state.filter = { ...state.filter, ...action.payload };
		},

		setFilterDBData(state, action) {			
			state.dbData = { ...state.dbData, ...action.payload };
		},

		setCardFilterOverall(state, action) {
			state.cardFilter = {
				...state.cardFilter,
				...action.payload,
			};
		},

		setSelectedFilter(state, action) {
			if (action.payload) {
				// eslint-disable-next-line eqeqeq
				if (action.payload != "all") {
					const { filter } = action.payload;
					if (filter && filter.cardFilter) {
						state.cardFilter = {
							...DEFAULT_OVERALL_DATA,
							...filter.cardFilter,
						};
					}
					if (filter && filter.transferType) {
						state.transferType = filter.transferType;
					} else {
						state.transferType = OVERALL_PAGE_SUB_TYPE.TOTAL;
					}
					if (filter && filter.filter) {
						state.filter = filter.filter;
					}
					state.selectedFilter = action.payload._id;
				} else {
					state.selectedFilter = action.payload;
					state.cardFilter = DEFAULT_OVERALL_DATA;
					state.filter = { ...state.filter };
					state.transferType = OVERALL_PAGE_SUB_TYPE.TOTAL;
					state.isResetFilter = true;
					state.lockedTotal = null;
				}
			}
		},
		setFilterTotal(state, action) {
			state.filterTotal = action.payload;
		},
		setIsMainCensusPercentage(state, action) {
			state.mainNumPercentage = action.payload;
		},
		setLockedTotalBy(state, action) {
			state.lockedTotalBy = action.payload;
		}		
	},
});

export default admission.reducer;

export const {
	setLockedTotalBy,
	setMainTotal,
	setFilterTotal,
	openDetailsDialog,
	closeDetailsDialog,
	setFilterDateRange,
	setFilterDBData,
	setCardFilterOverall,
	setSelectedFilter,
	setDBSelectedFilters,
	setDefaultOverAllData,
	setTransferType,
	setResetFilter,
	setDefaultData,
	setLockTotal,
	setIsMainCensusPercentage,
	setLoading,
	setLockedByFacility,
	setIsCensusTotalLocked,
	setFacilityPercentage	
} = admission.actions;
