import styles from "./ReturnCardList.module.scss";
import React from "react";
import CheckboxButton from "../../shared/checkboxButton/CheckboxButton";
import { Typography } from "@mui/material";
import CardItemTooltip from "../../../shared/CardItemTooltip";
import classNames from "classnames";

const ReturnCardListSkeleton = () => {
	const fakeData = [1, 2];
	return (
		<>
			<div className={`df aic m-t-15 ${styles.box}`}>
				{fakeData.map((item) => {
					return (
						<div key={item} className={`df ${styles.sec}`} style={{ display: "flex", alignItems: "center" }}>
							<CheckboxButton
								disabled
								size="small"
								className="skeleton"
								buttonText={
									<>
										<CardItemTooltip item={item}>
											<Typography
												className={styles.secButtonText}
												variant="subtitle2"
												sx={{ display: "flex", mt: "2px", alignItems: "center" }}
												component="div"
											>
												<div className={classNames(styles.labelCircle, "skeleton")} style={{ marginBottom: "2px" }} />
												<div className={classNames(styles.skeletonLabelText, "skeleton")}></div>
											</Typography>
										</CardItemTooltip>
									</>
								}
								sx={{ width: "20px", height: "20px", borderRadius: "5px", padding: "0" }}
								textWrapColorClass={item.textWrapColor}
							/>
						</div>
					);
				})}
			</div>
			<div className={classNames(`m-t-4`, `m-b-8`, styles.pieSkeletonWrapper)}>
				<div className={classNames(styles.pieSkeleton, "skeleton")}></div>
			</div>
		</>
	);
};

export default ReturnCardListSkeleton;
