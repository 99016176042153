const EditSVG = props => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_916_27)">
      <path
        d="M12.9692 6.93666C12.7103 6.93666 12.5004 7.14654 12.5004 7.40542V14.2806C12.5004 14.7114 12.1499 15.0619 11.7191 15.0619H1.7188C1.28801 15.0619 0.937529 14.7114 0.937529 14.2806V4.28033C0.937529 3.84953 1.28801 3.49905 1.7188 3.49905H8.59402C8.8529 3.49905 9.06278 3.28917 9.06278 3.03029C9.06278 2.7714 8.8529 2.56152 8.59402 2.56152H1.7188C0.771055 2.56152 0 3.33258 0 4.28033V14.2806C0 15.2284 0.771055 15.9994 1.7188 15.9994H11.7191C12.6669 15.9994 13.4379 15.2284 13.4379 14.2806V7.40542C13.4379 7.14654 13.228 6.93666 12.9692 6.93666Z"
        fill={props?.fill || "#444652"}
      />
      <path
        d="M15.68 1.20377L14.796 0.319838C14.3696 -0.106613 13.6757 -0.106613 13.2492 0.319838L6.17794 7.39112C6.1125 7.45656 6.06791 7.53991 6.04972 7.63066L5.60774 9.84045C5.57702 9.99414 5.62511 10.153 5.73596 10.2638C5.82474 10.3526 5.94434 10.4012 6.06741 10.4012C6.098 10.4012 6.12875 10.3982 6.15932 10.3921L8.3691 9.95008C8.45986 9.93192 8.5432 9.88729 8.60864 9.82185L15.68 2.7506C15.68 2.7506 15.68 2.7506 15.68 2.75057C16.1064 2.32415 16.1064 1.63025 15.68 1.20377ZM8.04609 9.05858L6.66499 9.33484L6.94125 7.95373L12.6967 2.19815L13.8016 3.30306L8.04609 9.05858ZM15.017 2.08767L14.4646 2.64013L13.3597 1.53522L13.9121 0.982796C13.973 0.921857 14.0722 0.921826 14.1331 0.982765L15.017 1.8667C15.078 1.92761 15.078 2.02677 15.017 2.08767Z"
        fill={props?.fill || "#444652"}
      />
    </g>
    <defs>
      <clipPath id="clip0_916_27">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EditSVG;
