import api from './api';
import { filterBodyDate, toSaveDate, toSaveEndDate } from '../../utilis/date-formats';
const API_PREFIX = "api/deceased";

const getDateWiseData = async (params) => {
    params.startDate = toSaveDate(params.startDate);
    params.endDate = toSaveEndDate(params.endDate);
    const response = await api.get(`${API_PREFIX}/dashboard-data`, { params });
    return response;
};

const getValidations = async () => {
    const response = await api.get(`api/validation/all`);
    return response;
};

const getCardPatientData = async (body) => {
    const response = await api.post(`${API_PREFIX}/card-patient-data`, body);
    return response;
};

const getCardPatientChartData = async (body = null) => {
    body = filterBodyDate(body);
    if (body.transferType === "all") {
        delete body["transferType"];
    }
    const response = await api.post(`${API_PREFIX}/card-patient-chart-data`, body);
    return response;
};

const getAllDeceasedCount = async (params) => {
    const apiParams = { ...params };
    apiParams.startDate = toSaveDate(params.startDate);
    apiParams.endDate = toSaveEndDate(params.endDate);
    const response = await api.get(`${API_PREFIX}/get-all-count`, { params: apiParams });
    return response;
};

export {
    getDateWiseData,
    getValidations,
    getCardPatientData,
    getCardPatientChartData,
    getAllDeceasedCount
};
