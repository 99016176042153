/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import { Grid, Stack } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	DEFAULT_CHART_DATA,
	DEFAULT_CHART_FILTER
} from "../../../data/common.data";
import { cardADTPatientChartData } from "../../../services/admission.service";
import { getAccountQuestions } from "../../../services/api/user.api";
import { closeADTDetailsDialog } from "../../../store/reducers/admission.slice";
import { CHART_POINT_TYPE, CHART_TAB_BUTTON } from "../../../types/chart-dialog.type";
import { ADT_TABLE_TYPE, CHART_FILTER_DAY_OF, DASHBOARD_FILTER_TYPE, FILTER_TYPES } from "../../../types/common.type";
import {
	chartFilterPermission,
	chartGroupBy,
	chartsData,
	dayDifferent,
	getAdmissionADTFieldNames,
	isUpdateDefaultFilter
} from "../../../utilis/charts-common";
import ChartDetailsTab from "../../shared/chart-dialog-common/ChartDetailsTab";
import ChartDialogContainer from "../../shared/chart-dialog-common/ChartDialogContainer";
import ChartDialogLeftSidebar from "../../shared/chart-dialog-common/ChartDialogLeftSidebar";
import ChartTabLoader from "../../shared/chart-dialog-common/ChartTabLoader";
import ChartBuildingList from "../../shared/chart-dialog-common/chart-building/ChartBuildingList";
import CardDetailChart from "../../shared/chart-dialog-common/chart-tab/CardDetailChart";
import ChartTableList from "../../shared/chart-dialog-common/chart-table/ChartTableList";
import AdtTransferViewFilter from "./viewTransferStatusOfAdmission/AdtTransferViewFilter";
import { PAGE_TYPE } from "../../../types/pages.type";
import ChartDetailsSearchBar from "../../shared/chart-dialog-common/ChartDetailsSearchBar";
import { patientDataOrderBy } from "../../../utilis/common";

const defaultFilter = {
	days: null,
	startDate: null,
	endDate: null,
	filterButtons: [],
	chartPointType: CHART_POINT_TYPE.TOTAL,
};

export default function AdmissionADTDetailsDialog({ handleOnClickReport, handleGenerateExcelReport }) {
	const tableElementRef = useRef();
	const buildingElementRef = useRef();
	const [selectedItem, setSelectedItem] = useState([]);
	const [loading, setLoading] = useState(false);
	const [buttonFilterType, setButtonFilterType] = useState(CHART_FILTER_DAY_OF.DAY);
	const [selectedCheckBoxItem, setSelectedCheckBoxItem] = useState([]);
	const [patientData, setPatientData] = useState([]);
	const [defaultChartData, setDefaultChartData] = useState([]);
	const [queryFilters, setQueryFilters] = useState(defaultFilter);
	const [selectedTab, setSelectedTab] = useState(CHART_TAB_BUTTON.TABLE);
	const [defaultPatientData, setDefaultPatientData] = useState([]);
	const [filterData, setFilterData] = useState([]);
	const [chartData, setChartData] = useState([]);
	const [chartMainData, setChartMainData] = useState([]);
	const [dbAdmissionTotal, setDbAdmissionTotal] = useState(0);
	const dispatch = useDispatch();
	const [selectedFilter, setSelectedFilter] = useState(FILTER_TYPES.DAILY);
	const {
		filterTotal,
		detailsADTDialog: { isOpen, data, filters, admissionTotal, dbData },
	} = useSelector((state) => state.admission);
	const [censusByFacility, setCensusByFacility] = useState([]);
	const [bedCapacityByFacility, setBedCapacityByFacility] = useState([]);

	// COMPARISON STATES ---------->
	const {
		detailsADTDialog: { data: dataComparison, filters: filtersComparison },
	} = useSelector((state) => state.admissionComparison);
	const [chartDataComparison, setChartDataComparison] = useState(DEFAULT_CHART_DATA);
	const [chartMainDataComparison, setChartMainDataComparison] = useState([]);
	const [dbAdmissionTotalComparison, setDbAdmissionTotalComparison] = useState(0);
	const [selectedCheckBoxItemComparison, setSelectedCheckBoxItemComparison] = useState([]);
	const [patientDataComparison, setPatientDataComparison] = useState([]);
	const [defaultChartDataComparison, setDefaultChartDataComparison] = useState([]);
	const [censusByFacilityComparison, setCensusByFacilityComparison] = useState([]);
	const [bedCapacityByFacilityComparison, setBedCapacityByFacilityComparison] = useState([]);
	const [queryFiltersComparison, setQueryFiltersComparison] = useState(DEFAULT_CHART_FILTER);
	const [defaultPatientDataComparison, setDefaultPatientDataComparison] = useState([]);
	const [filterDataComparison, setFilterDataComparison] = useState([]);
	const [selectedItemComparison, setSelectedItemComparison] = useState([]);
	const { auth } = useSelector(({ auth }) => ({ auth }));
	const [tableFilterType, setTableFilterType] = React.useState("all");
	const [defaultQuestions, setDefaultQuestions] = React.useState([]);
	const [questions, setQuestions] = React.useState([]);

	const [orderBy, setOrderBy] = useState('dateOfADT'); // Default sort column
	const [order, setOrder] = useState('asc'); // Default sort direction

	const activeFacilities = useSelector((state) => state.activeFacilities.facilities);

	const [selectedFacility, setSelectedFacility] = useState(activeFacilities);

	const getQuestions = async (activeFacilitiesData, pageType) => {
		const res = await getAccountQuestions({
			facilityid: activeFacilitiesData,
			accountId: auth.accountId,
			pageType,
		});
		if (res && res.length > 0) {
			setDefaultQuestions(res);
		}
	};

	React.useEffect(() => {
		if (filters.type.parentId === "communityTransfer" || filters.type.parentId === "overall") {
			setQuestions(tableFilterType === "all" ? [] : _.filter(defaultQuestions, { forTransferType: tableFilterType }));
		} else {
			setQuestions(defaultQuestions);
		}
		// eslint-disable-next-line
	}, [tableFilterType, defaultQuestions]);

	React.useEffect(() => {
		getQuestions(activeFacilities, filters.type.parentId);
		// eslint-disable-next-line
	}, [activeFacilities]);

	// * added comparison
	const updateChartArrData = useCallback(
		async (dataArr, filterType = null, type = null, filterObj, forComparison) => {
			let filterBy = filterType;
			if (!filterBy) {
				filterBy = buttonFilterType;
			}
			let chartDataArr = [];
			if (type === "filter" && filterObj) {
				chartDataArr = await chartsData(dataArr, filterObj);
			} else {
				chartDataArr = await chartsData(dataArr, queryFilters);
			}
			let filtersLatest = filterObj ? filterObj : forComparison ? queryFiltersComparison : queryFilters;
			const latestChartData = await chartGroupBy(
				chartDataArr,
				filterBy,
				forComparison ? dbAdmissionTotalComparison : dbAdmissionTotal,
				[],
				filtersLatest
			);
			if (!forComparison) {
				setChartData(latestChartData);
			} else {
				setChartDataComparison(latestChartData);
			}
		},
		[buttonFilterType, dbAdmissionTotal, dbAdmissionTotalComparison, queryFilters, queryFiltersComparison]
	);

	// * added comparison
	const getPatientChartsData = async () => {
		try {
			setLoading(true);
			const [response, responseComparison] = await Promise.all([
				cardADTPatientChartData({ ...filters }),
				filtersComparison && cardADTPatientChartData({ ...filtersComparison }),
			]);

			let responseData = [];
			let responseDataComparison = [];

			responseData = response?.data?.list?.map((ele) => { return { ...ele, facilityName: ele?.facility?.name } }) ?? []				
			if (response && response.data && response.data && responseData.length > 0) {
				setPatientData(responseData);
				setDefaultPatientData(responseData);
				setDefaultChartData(responseData);
				if (response.censusByFacility) {
					setCensusByFacility(response?.censusByFacility || []);
				}
				if (response.bedCapacityByFacility) {
					setBedCapacityByFacility(response.bedCapacityByFacility);
				}
				const chartDataArrData = await chartsData(responseData, filters.filter);
				const latestChartData = await chartGroupBy(
					chartDataArrData,
					buttonFilterType,
					dbAdmissionTotal,
					[],
					queryFilters
				);
				setSelectedCheckBoxItem(responseData);
				setChartMainData(response.data);
				setChartData(latestChartData);
			}

			if (filters.selectedFilterData && filters.selectedFilterData.length > 0) {
				setSelectedItem([...filters.selectedFilterData]);
				filterOptions(filters.selectedFilterData, responseData, responseData, buttonFilterType, {
					...filters.filter,
					filterData: data,
				});
			} else {
				const selectedIds = _.map(data, "_id") || [];
				setSelectedItem(selectedIds);
			}
			setFilterData(data);

			// COMPARISON BLOCK
			if (
				responseComparison &&
				responseComparison.data &&
				responseComparison.data &&
				responseComparison.data.list &&
				responseComparison.data.list.length > 0
			) {
				responseDataComparison = responseComparison.data.list;
				setPatientDataComparison(responseDataComparison);
				setDefaultPatientDataComparison(responseDataComparison);
				setDefaultChartDataComparison(responseDataComparison);
				if (responseComparison.censusByFacility) {
					setCensusByFacilityComparison(responseComparison?.censusByFacility || []);
				}
				if (responseComparison.bedCapacityByFacility) {
					setBedCapacityByFacilityComparison(responseComparison.bedCapacityByFacility);
				}
				const chartDataArrDataComparison = await chartsData(responseDataComparison, filtersComparison?.filter);
				const latestChartDataComparison = await chartGroupBy(
					chartDataArrDataComparison,
					buttonFilterType,
					dbAdmissionTotalComparison,
					[],
					queryFiltersComparison
				);
				setSelectedCheckBoxItemComparison(responseDataComparison);
				setChartMainDataComparison(responseComparison.data);
				setChartDataComparison(latestChartDataComparison);
			}

			if (filtersComparison?.selectedFilterData && filtersComparison?.selectedFilterData.length > 0) {
				setSelectedItemComparison([...filtersComparison?.selectedFilterData]);
				///sasa
				filterOptions(
					filtersComparison?.selectedFilterData,
					responseComparison.data.list,
					responseComparison.data.list,
					buttonFilterType,
					{
						...filtersComparison?.filter,
						filterData: dataComparison,
					},
					undefined,
					undefined,
					true
				);
			} else {
				const selectedIdsComparison = _.map(dataComparison, "_id") || [];
				setSelectedItemComparison(selectedIdsComparison);
			}
			setFilterDataComparison(dataComparison);
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	};

	// * added comparison
	const reset = () => {
		setSelectedItem([]);
		setPatientData([]);
		setDefaultPatientData([]);
		setFilterData([]);

		setSelectedItemComparison([]);
		setPatientDataComparison([]);
		setDefaultPatientDataComparison([]);
		setFilterDataComparison([]);
	};

	// * added comparison
	const filterByCheckbox = useCallback(
		async (filter = null, isAllChecked = false, dataFilters, forComparison) => {
			if (forComparison) {
				// COMPARISON BLOCK
				const {
					arrData: arrDataComparison,
					chartData: chartDataComparison,
					latestButtonFilterType: latestButtonFilterTypeComparison,
					filterObj: filterObjComparison,
				} = dataFilters;
				if (isAllChecked) {
					setPatientDataComparison(arrDataComparison);
					setSelectedCheckBoxItemComparison(chartDataComparison);
					await updateChartArrData(
						chartDataComparison,
						latestButtonFilterTypeComparison,
						"filter",
						filterObjComparison,
						forComparison
					);
				} else {
					let newFiltersComparison = _.filter(arrDataComparison, filter);
					setPatientDataComparison(newFiltersComparison);
					let newChartFiltersComparison = _.filter(chartDataComparison, filter);
					setSelectedCheckBoxItemComparison(newChartFiltersComparison);
					await updateChartArrData(
						newChartFiltersComparison,
						latestButtonFilterTypeComparison,
						"filter",
						filterObjComparison,
						forComparison
					);
				}
			} else {
				const { arrData, chartData, latestButtonFilterType, filterObj } = dataFilters;
				if (isAllChecked) {
					setPatientData(arrData);
					setSelectedCheckBoxItem(chartData);
					await updateChartArrData(chartData, latestButtonFilterType, "filter", filterObj);
				} else {
					let newFilters = _.filter(arrData, filter);
					setPatientData(newFilters);
					let newChartFilters = _.filter(chartData, filter);
					setSelectedCheckBoxItem(newChartFilters);
					await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObj);
				}
			}
		},
		[updateChartArrData]
	);

	// * added comparison - but if anything wrong, check here first.
	const filterOptions = async (
		newChecked,
		mainDataArr = [],
		chartData = [],
		latestButtonFilterType = null,
		filterObj,
		filterType = "initial",
		originalData,
		forComparison
	) => {
		const { childId, type } = filters.selectedCardItem;
		let arrData = mainDataArr;

		if (activeFacilities.length > 1) {
			const filterPatientsByFacility = _.filter(arrData, ({ facilityId }) => _.every([_.includes(selectedFacility, facilityId)]));
			arrData = filterPatientsByFacility;
		}
		if (childId === "reHospitalization") {
			if (newChecked.length === 1) {
				let filterDataObj = Object();
				filterDataObj.reHospitalization = newChecked[0] == "newHospitalizations" ? false : true;
				await filterByCheckbox(
					filterDataObj,
					false,
					{ arrData, chartData, latestButtonFilterType, filterObj },
					forComparison
				);
			} else {
				await filterByCheckbox(null, true, { arrData, chartData, latestButtonFilterType, filterObj }, forComparison);
			}
		}
		if (childId === "wasAdmitted") {
			if (newChecked.length === 1) {
				let filterDataObj = Object();
				filterDataObj.wasAdmitted = newChecked[0] === "DC" ? true : false;
				await filterByCheckbox(
					filterDataObj,
					false,
					{ arrData, chartData, latestButtonFilterType, filterObj },
					forComparison
				);
			} else {
				await filterByCheckbox(null, true, { arrData, chartData, latestButtonFilterType, filterObj }, forComparison);
			}
		}
		if (childId === "wasReturned") {
			if (newChecked.length === 1) {
				let filterDataObj = Object();
				filterDataObj.wasReturned = newChecked[0] === "returned" ? true : false;
				await filterByCheckbox(
					filterDataObj,
					false,
					{ arrData, chartData, latestButtonFilterType, filterObj },
					forComparison
				);
			} else {
				await filterByCheckbox(null, true, { arrData, chartData, latestButtonFilterType, filterObj }, forComparison);
			}
		}
		if (childId === "day") {
			let newFilters = _.filter(arrData, ({ day }) => {
				return _.every([_.includes(newChecked, day)]);
			});
			forComparison ? setPatientDataComparison(newFilters) : setPatientData(newFilters);
			let newChartFilters = _.filter(chartData, ({ day }) => {
				return _.every([_.includes(newChecked, day)]);
			});
			forComparison ? setSelectedCheckBoxItemComparison(newChartFilters) : setSelectedCheckBoxItem(newChartFilters);
			await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObj, forComparison);
		}
		if (childId === "shiftName") {
			let newFilters = _.filter(arrData, ({ shiftName }) => {
				return _.every([_.includes(newChecked, shiftName)]);
			});
			forComparison ? setPatientDataComparison(newFilters) : setPatientData(newFilters);
			let newChartFilters = _.filter(chartData, ({ shiftName }) => {
				return _.every([_.includes(newChecked, shiftName)]);
			});
			forComparison ? setSelectedCheckBoxItemComparison(newChartFilters) : setSelectedCheckBoxItem(newChartFilters);
			await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObj, forComparison);
		}
		if (childId === "90Days" || childId === "60Days" || childId === "90DaysDeceased" || childId === "90DaysOverall") {
			let ninetyDaysDataIds = [];
			let filterDataObj = filterData;
			if (filterDataObj.length == 0) {
				if (childId === "90Days") {
					filterDataObj = chartMainData.ninetyDaysData;
				}
				if (childId === "60Days") {
					filterDataObj = chartMainData.sixtyDaysData;
				}
				if (childId === "90DaysDeceased") {
					filterDataObj = chartMainData.deceasedNinetyDaysChartCountData;
				}
				if (childId === "90DaysOverall") {
					filterDataObj = chartMainData.overAllNinetyDaysChartCountData;
				}
			}
			let dataOriginal = filterObj?.filterData ? filterObj.filterData : filterDataObj;
			if (filterType === "reload") {
				if (childId === "90Days") {
					dataOriginal = originalData.ninetyDaysData;
				}
				if (childId === "60Days") {
					dataOriginal = originalData.sixtyDaysData;
				}
				if (childId === "90DaysDeceased") {
					dataOriginal = originalData.deceasedNinetyDaysChartCountData;
				}
				if (childId === "90DaysOverall") {
					dataOriginal = originalData.overAllNinetyDaysChartCountData;
				}
			}
			let ninetyDaysDataFilter = _.filter(dataOriginal, ({ _id }) => _.every([_.includes(newChecked, _id)]));

			if (ninetyDaysDataFilter && ninetyDaysDataFilter.length > 0) {
				ninetyDaysDataFilter.map((item) => (ninetyDaysDataIds = [...ninetyDaysDataIds, ...item.ids]));
			}

			let newFilters = _.filter(arrData, ({ _id }) => _.every([_.includes(ninetyDaysDataIds, _id)]));
			forComparison ? setPatientDataComparison(newFilters) : setPatientData(newFilters);
			let newChartFilters = _.filter(chartData, ({ _id }) => _.every([_.includes(ninetyDaysDataIds, _id)]));
			forComparison ? setSelectedCheckBoxItemComparison(newChartFilters) : setSelectedCheckBoxItem(newChartFilters);
			await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObj, forComparison);
		}
		if (type === ADT_TABLE_TYPE.GROUP) {
			let newFilters = _.filter(arrData, ({ filterId }) => {
				return _.every([_.includes(newChecked, filterId)]);
			});
			forComparison ? setPatientDataComparison(newFilters) : setPatientData(newFilters);
			let newChartFilters = _.filter(chartData, ({ filterId }) => {
				return _.every([_.includes(newChecked, filterId)]);
			});
			forComparison ? setSelectedCheckBoxItemComparison(newChartFilters) : setSelectedCheckBoxItem(newChartFilters);
			await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObj, forComparison);
		}
		if (type === ADT_TABLE_TYPE.GROUP_ARRAY) {
			let newFilters = await _.filter(arrData, ({ dxIds }) => {
				const matchedIds = _.intersection(newChecked, dxIds);
				if (matchedIds.length > 0) {
					return true;
				} else {
					return false;
				}
			});
			forComparison ? setPatientDataComparison(newFilters) : setPatientData(newFilters);
			let newChartFilters = await _.filter(chartData, ({ dxIds }) => {
				const matchedIds = _.intersection(newChecked, dxIds);
				if (matchedIds.length > 0) {
					return true;
				} else {
					return false;
				}
			});
			forComparison ? setSelectedCheckBoxItemComparison(newChartFilters) : setSelectedCheckBoxItem(newChartFilters);
			await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObj, forComparison);
		}
	};

	useEffect(() => {
		filterOptions(selectedItem,
			defaultPatientData,
			defaultChartData,
			buttonFilterType,
			{
				...filtersComparison?.filter,
			},
			undefined,
			undefined,
			false
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFacility]);

	// * added comparison
	const handleToggleAll = useCallback(
		async (value) => {
			if (value == "all") {
				const ids = filterData.map((a) => a._id);
				const idsComparison = filterDataComparison.map((a) => a._id);

				await Promise.all([
					filterOptions(ids, defaultPatientData, defaultChartData, buttonFilterType, queryFilters),
					filterOptions(
						idsComparison,
						defaultPatientDataComparison,
						defaultChartDataComparison,
						buttonFilterType,
						queryFiltersComparison,
						undefined,
						undefined,
						true
					),
				]);
				setSelectedItem([...ids]);
				setSelectedItemComparison([...idsComparison]);
			} else {
				setPatientData([]);
				setChartData([]);
				setSelectedCheckBoxItem([]);
				setSelectedItem([]);

				setPatientDataComparison([]);
				setChartDataComparison([]);
				setSelectedCheckBoxItemComparison([]);
				setSelectedItemComparison([]);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			filterData,
			filterDataComparison,
			defaultPatientData,
			defaultChartData,
			buttonFilterType,
			queryFilters,
			defaultPatientDataComparison,
			defaultChartDataComparison,
			buttonFilterType,
			queryFiltersComparison,
		]
	);

	// * added comparison
	const handleToggle = useCallback(
		async (value) => {
			const currentIndex = selectedItem.indexOf(value);
			const newChecked = [...selectedItem];

			if (currentIndex === -1) {
				newChecked.push(value);
			} else {
				newChecked.splice(currentIndex, 1);
			}

			if (newChecked.length > 0) {
				await Promise.all([
					filterOptions(newChecked, defaultPatientData, defaultChartData, buttonFilterType, queryFilters),
					filterOptions(
						newChecked,
						defaultPatientDataComparison,
						defaultChartDataComparison,
						buttonFilterType,
						queryFiltersComparison,
						undefined,
						undefined,
						true
					),
				]);
			} else {
				setPatientData([]);
				setChartData([]);
				setSelectedCheckBoxItem([]);

				setPatientDataComparison([]);
				setChartDataComparison([]);
				setSelectedCheckBoxItemComparison([]);
			}
			setSelectedItem(newChecked);
			setSelectedItemComparison(newChecked);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			selectedItem,
			defaultPatientData,
			defaultChartData,
			buttonFilterType,
			queryFilters,
			defaultPatientDataComparison,
			defaultChartDataComparison,
			buttonFilterType,
			queryFiltersComparison,
		]
	);

	// * added comparison
	const reloadChartData = useCallback(
		async (startDate, endDate, startDateComparison, endDateComparison) => {
			try {
				setLoading(true);
				let filterObj = { ...filters, filter: { startDate, endDate } };
				let filterObjComparison = {
					...filtersComparison,
					filter: { startDate: startDateComparison, endDate: endDateComparison },
				};

				let latestButtonFilterType = buttonFilterType;
				const isUpdateFilter = isUpdateDefaultFilter(filterObj.filter);
				if (isUpdateFilter) {
					setSelectedFilter(FILTER_TYPES.WEEKLY);
					latestButtonFilterType = CHART_FILTER_DAY_OF.WEEK;
					setButtonFilterType(CHART_FILTER_DAY_OF.WEEK);
				}
				const [response, responseComparison] = await Promise.all([
					cardADTPatientChartData({ ...filterObj }),
					filterObjComparison && cardADTPatientChartData({ ...filterObjComparison }),
				]);

				if (response && response.data && response.data && response.data.list.length > 0) {
					const listData = response.data.list?.map((ele) => { return { ...ele, facilityName: ele?.facility?.name } }) ?? []					
					setDbAdmissionTotal(response.admissionTotal);
					setDefaultChartData(listData);
					setDefaultPatientData(listData);
					if (response.censusByFacility) {
						setCensusByFacility(response?.censusByFacility || []);
					}
					if (response.bedCapacityByFacility) {
						setBedCapacityByFacility(response.bedCapacityByFacility);
					}
					const chartDataArr = await chartsData(listData, filterObj.filter);
					const latestChartData = await chartGroupBy(
						chartDataArr,
						latestButtonFilterType,
						response.data.admissionTotal,
						[],
						queryFilters
					);

					if (
						filters.selectedCardItem.childId == "90Days" ||
						filters.selectedCardItem.childId === "60Days" ||
						filters.selectedCardItem.childId === "90DaysDeceased" ||
						filters.selectedCardItem.childId === "90DaysOverall"
					) {
						let originalChartData = [];
						if (response.data && response.data.ninetyDaysData && response.data.ninetyDaysData.length > 0) {
							originalChartData = response.data.ninetyDaysData;
						}
						if (
							response.data &&
							response.data.deceasedNinetyDaysChartCountData &&
							response.data.deceasedNinetyDaysChartCountData.length > 0
						) {
							originalChartData = response.data.deceasedNinetyDaysChartCountData;
						}
						if (
							response.data &&
							response.data.overAllNinetyDaysChartCountData &&
							response.data.overAllNinetyDaysChartCountData.length > 0
						) {
							originalChartData = response.data.overAllNinetyDaysChartCountData;
						}
						if (response.data && response.data.sixtyDaysData && response.data.sixtyDaysData.length > 0) {
							originalChartData = response.data.sixtyDaysData;
						}
						if (filterData.length > 0 && originalChartData.length > 0) {
							const latestFilterData = filterData
								.map((eleFilter) => {
									const selectedEle = _.find(originalChartData, { _id: eleFilter._id });
									if (selectedEle) {
										return {
											...eleFilter,
											admissionIds: selectedEle.admissionIds,
											ids: selectedEle.ids,
											patientIds: selectedEle.ids,
										};
									} else {
										return null;
									}
								})
								.filter((item) => item);
							setFilterData(latestFilterData);
						} else {
							setFilterData(filterData);
						}
					}
					setSelectedCheckBoxItem(listData);
					setChartData(latestChartData);
					if (selectedItem.length > 0) {
						await filterOptions(
							selectedItem,
							listData,
							listData,
							latestButtonFilterType,
							filterObj.filter,
							"reload",
							response.data
						);
					}
				}

				if (
					responseComparison &&
					responseComparison.data &&
					responseComparison.data &&
					responseComparison.data.list.length > 0
				) {
					let listDataComparison = responseComparison.data.list;
					setDbAdmissionTotalComparison(responseComparison.admissionTotal);
					setDefaultChartDataComparison(listDataComparison);
					setDefaultPatientDataComparison(listDataComparison);
					if (responseComparison.censusByFacility) {
						setCensusByFacilityComparison(responseComparison?.censusByFacility || []);
					}
					if (responseComparison.bedCapacityByFacility) {
						setBedCapacityByFacilityComparison(responseComparison.bedCapacityByFacility);
					}
					const chartDataArrComparison = await chartsData(listDataComparison, filterObjComparison.filter);
					const latestChartDataComparison = await chartGroupBy(
						chartDataArrComparison,
						latestButtonFilterType,
						responseComparison.data.admissionTotal,
						[],
						queryFiltersComparison
					);

					if (
						filtersComparison?.selectedCardItem.childId == "90Days" ||
						filtersComparison?.selectedCardItem.childId === "60Days" ||
						filtersComparison?.selectedCardItem.childId === "90DaysDeceased" ||
						filtersComparison?.selectedCardItem.childId === "90DaysOverall"
					) {
						let originalChartDataComparison = [];
						if (
							responseComparison.data &&
							responseComparison.data.ninetyDaysData &&
							responseComparison.data.ninetyDaysData.length > 0
						) {
							originalChartDataComparison = responseComparison.data.ninetyDaysData;
						}
						if (
							responseComparison.data &&
							responseComparison.data.deceasedNinetyDaysChartCountData &&
							responseComparison.data.deceasedNinetyDaysChartCountData.length > 0
						) {
							originalChartDataComparison = responseComparison.data.deceasedNinetyDaysChartCountData;
						}
						if (
							responseComparison.data &&
							responseComparison.data.overAllNinetyDaysChartCountData &&
							responseComparison.data.overAllNinetyDaysChartCountData.length > 0
						) {
							originalChartDataComparison = responseComparison.data.overAllNinetyDaysChartCountData;
						}
						if (
							responseComparison.data &&
							responseComparison.data.sixtyDaysData &&
							responseComparison.data.sixtyDaysData.length > 0
						) {
							originalChartDataComparison = responseComparison.data.sixtyDaysData;
						}
						if (filterDataComparison.length > 0 && originalChartDataComparison.length > 0) {
							const latestFilterDataComparison = filterDataComparison
								.map((eleFilter) => {
									const selectedEle = _.find(originalChartDataComparison, { _id: eleFilter._id });
									if (selectedEle) {
										return {
											...eleFilter,
											admissionIds: selectedEle.admissionIds,
											ids: selectedEle.ids,
											patientIds: selectedEle.ids,
										};
									} else {
										return null;
									}
								})
								.filter((item) => item);
							setFilterDataComparison(latestFilterDataComparison);
						} else {
							setFilterDataComparison(filterDataComparison);
						}
					}
					setSelectedCheckBoxItemComparison(listDataComparison);
					setChartDataComparison(latestChartDataComparison);
					if (selectedItem.length > 0) {
						await filterOptions(
							selectedItem,
							listDataComparison,
							listDataComparison,
							latestButtonFilterType,
							filterObjComparison.filter,
							"reload",
							responseComparison.data
						);
					}
				}
			} catch (e) {
				console.log(e);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			filters,
			filtersComparison,
			buttonFilterType,
			queryFilters,
			selectedItem,
			filterData,
			queryFiltersComparison,
			filterDataComparison,
		]
	);

	const handleClose = useCallback(() => {
		dispatch(closeADTDetailsDialog());
	}, [dispatch]);

	const handleTabButton = useCallback((type) => {
		setSelectedTab(type);
	}, []);

	const handleClickFilter = useCallback(
		async (type, filterType = null) => {
			setButtonFilterType(filterType);
			setSelectedFilter(type);
			await updateChartArrData(selectedCheckBoxItem, filterType);
		},
		[updateChartArrData, selectedCheckBoxItem]
	);

	const getFieldNames = useCallback(
		(row) => {
			return getAdmissionADTFieldNames(data, row, filters?.relation, filters?.selectedCardItem);
		},
		[data, filters?.relation, filters?.selectedCardItem]
	);

	const handleChartLabelType = useCallback(
		async (type) => {
			let latestQueryFilter = { ...queryFilters, chartPointType: type };
			setQueryFilters((prevState) => ({
				...prevState,
				chartPointType: type,
			}));
			let chartDataArr = await chartsData(selectedCheckBoxItem, latestQueryFilter);
			const latestChartData = await chartGroupBy(
				chartDataArr,
				buttonFilterType,
				dbAdmissionTotal,
				[],
				latestQueryFilter
			);
			setChartData(latestChartData);
		},
		[queryFilters, selectedCheckBoxItem, buttonFilterType, dbAdmissionTotal]
	);

	const onClickReport = useCallback(
		(title, automaticallyReport) => {
			handleOnClickReport({
				selectedTab,
				filters,
				chartData: {
					filterData: chartData, filterPatients: patientDataOrderBy(patientData, order, orderBy)
				},
				selectedFilter,
				tableElementRef,
				buildingElementRef,
				title: filters?.selectedCardItem?.title + " " + filters?.selectedCardItem?.subTitle,
				queryFilters,
				selectedItem,
				filterData,
				automaticallyReport,
				buttonFilterType,
				orderBy,
				order,
				activeFacilitiesCount : activeFacilities?.length
			});
		},
		[
			patientData,
			handleOnClickReport,
			selectedTab,
			filters,
			chartData,
			selectedFilter,
			queryFilters,
			selectedItem,
			filterData,
			buttonFilterType,
			orderBy,
			order,
			activeFacilities
		]
	);

	// * added comparison
	useEffect(() => {
		reset();
		if (filters && filters.filter) {
			const daysFilter = chartFilterPermission(filters.filter);
			const daysDiff = dayDifferent(filters.filter);

			setQueryFilters({
				filterButtons: daysFilter,
				days: daysDiff,
				startDate: filters.filter.startDate,
				endDate: filters.filter.endDate,
				chartPointType: CHART_POINT_TYPE.TOTAL,
			});
			getPatientChartsData();
		}
		if (filtersComparison && filtersComparison?.filter) {
			const daysFilter = chartFilterPermission(filtersComparison?.filter);
			const daysDiff = dayDifferent(filtersComparison?.filter);

			setQueryFiltersComparison({
				filterButtons: daysFilter,
				days: daysDiff,
				startDate: filtersComparison?.filter.startDate,
				endDate: filtersComparison?.filter.endDate,
				chartPointType: CHART_POINT_TYPE.TOTAL,
			});
			getPatientChartsData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, filtersComparison]);

	const handleExcelReport = useCallback((selectedColumns = []) => {
		handleGenerateExcelReport({
			data: patientData,
			filters,
			pageType: DASHBOARD_FILTER_TYPE.ADMISSION,
			getFieldNames,
			title: filters?.selectedCardItem?.subTitle,
			subtitle: filters?.selectedCardItem?.title,
			censusAverage: dbData?.censusAverage || 0,
			bedCapacity: dbData?.bedCapacity || 0,
			questions,
			selectedColumns,
			orderBy,
			order,
			activeFacilitiesCount : activeFacilities?.length
		});
	}, [patientData, filters, getFieldNames, handleGenerateExcelReport, dbData, questions, orderBy, order, activeFacilities]);

	const dropDownComponent = () => (
		<AdtTransferViewFilter filters={filters} setOption={setTableFilterType} tableFilterType={tableFilterType} />
	);

	// below code use for search filter in table
	const [searchTerm, setSearchTerm] = useState('');
	const [totalHighlightedCount, setTotalHighlightedCount] = useState(0);
	const [debounceHighlightedCount, setDebounceHighlightedCount] = useState(0);
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
	const [highlightedCountDefaultValue, setHighlightedCountDefaultValue] = useState(0);
	const [selectedHighlightedColumns, setSelectedHighlightedColumns] = useState([]);

	// useEffect(() => {
	// 	if (questions && questions.length > 0) {
	// 		setSelectedHighlightedColumns([...questions?.map((ele) => ele?.question?.accessor), ...tableDefaultQuestions.map((ele) => ele?.question?.accessor), "selectAll"]);
	// 	}
	// }, [questions]);

	const [filterType, setFilterType] = useState({ type: '', filter: '', operation: '' });

	useEffect(() => {
		const handlerCount = _.debounce(() => setDebounceHighlightedCount(totalHighlightedCount), 200);
		handlerCount();
		return () => handlerCount.cancel();
	}, [totalHighlightedCount]);

	useEffect(() => {
		const handler = _.debounce(() => {
			setDebouncedSearchTerm(searchTerm);
			setFilterType({ type: typeof searchTerm, filter: searchTerm, operation: '' });
		}, 300);
		handler();
		return () => handler.cancel();
	}, [searchTerm]);

	const handleSearch = (event) => {
		const value = event.target.value;
		setSearchTerm(isNaN(value) || !value ? value : Number(value));
	};

	const handleHighlightedCount = useCallback((count) => {
		setTotalHighlightedCount(searchTerm ? count : 0);
	}, [searchTerm]);

	// end of below code use for search filter in table

	return (
		<ChartDialogContainer handleClose={handleClose} isOpen={isOpen}>
			<ChartDialogLeftSidebar
				loading={loading}
				filterData={filterData}
				selectedItem={selectedItem}
				handleToggle={handleToggle}
				subtitle={filters?.selectedCardItem?.title}
				title={filters?.selectedCardItem?.subTitle}
				handleToggleAll={handleToggleAll}
				selectedTab={selectedTab}
			/>
			<Grid item xs={10.7} style={{ padding: "30px" }}>
				<ChartDetailsTab
					selectedTab={selectedTab}
					handleTabButton={handleTabButton}
					queryFilters={queryFilters}
					handleOnClickReport={onClickReport}
					loading={loading}
					handleExcelReport={handleExcelReport}
					{...(selectedTab === CHART_TAB_BUTTON.TABLE && { tableFilterTypeOptions: dropDownComponent() })}
					setSelectedFacility={setSelectedFacility}
					selectedFacility={selectedFacility}
					questions={questions}
					pageType={filters?.selectedCardItem?.parentId}
					subPageType={PAGE_TYPE.ADT}
					tableFilterType={filters?.type?.parentId === "hospital" ? filters.type.parentId : tableFilterType}
				/>

				{selectedTab === CHART_TAB_BUTTON.TABLE &&
					<ChartDetailsSearchBar
						searchTerm={searchTerm}
						handleSearch={handleSearch}
						setSearchTerm={setSearchTerm}
						highlightedCount={debounceHighlightedCount}
						filterType={filterType}
						setFilterType={setFilterType}
						selectedHighlightedColumns={selectedHighlightedColumns}
						setSelectedHighlightedColumns={setSelectedHighlightedColumns}
						selectedQuestions={questions}
					/>
				}

				{!loading && (
					<>
						{selectedTab === CHART_TAB_BUTTON.BUILDING && (
							<Stack direction={"row"} sx={{ mt: 2, height: "99%" }}>
								<ChartBuildingList
									data={patientData}
									page={filters?.type?.parentId}
									relation={filters?.relation}
									getFieldNames={getFieldNames}
									chartData={{ mainData: patientData }}
									censusByFacility={filters.filterTotalByTransferType}
									bedCapacityByFacility={bedCapacityByFacility}
									facilityPerText="Default % is against total admissions and not Avg. Occupancy"
									// filterSelected={filters.filterSelected || filters.transferType !== null}
									filterSelected={false}
									adtFacilityPercentage={filters.adtFacilityPercentage}
									filter={filters}
									buildingElementRef={buildingElementRef}
									dataComparison={patientDataComparison}
									relationComparison={filtersComparison?.relation}
									chartDataComparison={{ mainData: patientDataComparison }}
									censusByFacilityComparison={filtersComparison?.filterTotalByTransferType}
									bedCapacityByFacilityComparison={bedCapacityByFacilityComparison}
									filterSelectedComparison={filtersComparison?.filterSelected}
									filterComparison={filtersComparison}
									isAdt={true}
									filterListData={filters?.filterListData}
									selectedADTTableData={filters?.selectedADTTableData}
									typeCard={filters?.type}
								/>
							</Stack>
						)}
						{selectedTab === CHART_TAB_BUTTON.TABLE && (
							<Stack direction={"row"} sx={{ mt: 2, height: "99%" }}>
								<ChartTableList
									data={patientData}
									relation={filters?.relation}
									getFieldNames={getFieldNames}
									pageType={filters.selectedCardItem.parentId == "hospital" ? DASHBOARD_FILTER_TYPE.HOSPITAL : null}
									tableElementRef={tableElementRef}
									questions={questions}
									searchTerm={debouncedSearchTerm}
									handleHighlightedCount={handleHighlightedCount}
									highlightedCountDefaultValue={highlightedCountDefaultValue}
									filterType={filterType}
									selectedHighlightedColumns={selectedHighlightedColumns}
									orderData={{
										orderBy,
										setOrderBy,
										order,
										setOrder
									}}
									activeFacilities={activeFacilities}
								/>
							</Stack>
						)}
						{selectedTab === CHART_TAB_BUTTON.CHART && (
							<Stack direction={"row"} sx={{ mt: 2, height: "90%" }}>
								<CardDetailChart
									totalText="Total Admissions Transfer"
									data={chartData}
									filters={filters}
									censusAverage={null}
									total={patientData.length || 0}
									setQueryFilters={setQueryFilters}
									queryFilters={queryFilters}
									handleClickFilter={handleClickFilter}
									selectedFilter={selectedFilter}
									reloadChartData={reloadChartData}
									isChartTotalButton={true}
									handleChartLabelType={handleChartLabelType}
									selectedFacility={selectedFacility}
									dbData={dbData}
								/>
							</Stack>
						)}
					</>
				)}
				<ChartTabLoader loading={loading} />
			</Grid>
		</ChartDialogContainer>
	);
}
