export const LOGIN = "login";
export const LOGOUT = "logout";
export const UPDATE_PASSWORD_RESET_STATUS = "UPDATE_PASSWORD_RESET_STATUS";
export const ADD_NOTIFICATION = "addNotification";
export const REMOVE_NOTIFICATION = "removeNotification";
export const SET_ACCOUNTS = "setAccounts";
export const ADD_ACCOUNT = "addAccount";
export const DELETE_ACCOUNT = "deleteAccount";
export const UPDATE_ACCOUNT = "updateAccount";
export const SET_FACILITIES = "setFacilities";
export const ADD_FACILITY = "addFacility";
export const UPDATE_FACILITY = "updateFaciltity";
export const DELETE_FACILITY = "deleteFacility";
export const GET_ACTIVE_FACILITIES = 'getActiveFacilities'
export const SET_PERMISSIONS = "setPermissions";
