/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { Box, Grid } from "@mui/material";
import update from "immutability-helper";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../assets/styles/Hospital.module.scss";
import Page from "../components/Page";
import dashboardStyles from "../components/dashboard/Dashboard.module.scss";
import InsuranceCard from "../components/dashboard/InsuranceCard/InsuranceCard";
import Card from "../components/dashboard/card/Card";
import CommunityTransferCardDetailsDialog from "../components/dashboard/community-transfer/CommunityTransferCardDetailsDialog";
import CommunityTransferHeader from "../components/dashboard/community-transfer/CommunityTransferHeader";
import SafeDischargeAssLivingSlider from "../components/dashboard/community-transfer/safeDischargeAssLivingSlider/SafeDischargeAssLivingSlider";
import SelectableCardCoTransfer from "../components/dashboard/community-transfer/selectableCardCoTransfer/SelectableCardCoTransfer";
import SixtyDaysAnalysis from "../components/dashboard/community-transfer/sixtyDaysAnalysis/SixtyDaysAnalysis";
import SNFFacilitySlider from "../components/dashboard/community-transfer/snfFacilitySlider/SNFFacilitySlider";
import TotalCoTransferCard from "../components/dashboard/community-transfer/totalCoTransferCard/TotalCoTransferCard";
import DoctorCard from "../components/dashboard/doctorCard/DoctorCard";
import HospitalRowContainer from "../components/dashboard/hospital/HospitalRowContainer";
import FloorCardList from "../components/dashboard/hospital/floorCardList/FloorCardList";
import ReturnCardList from "../components/dashboard/hospital/returnCardList/ReturnCardList";
import TotalCountCircle from "../components/dashboard/hospital/totalCountCircle/TotalCountCircle";
import TaggedList from "../components/dashboard/shared/taggedList/TaggedList";
import usePdfReport from "../components/hooks/usePdfReport";
import PickProjectionDaysPopup from "../components/shared/PickProjectionDaysPopup/PickProjectionDaysPopup";
import ColorInfoBox from "../components/shared/dashboard/ColorInfoBox";
import { getAllAdmissionsPatient } from "../services/community-transfer.service";
import {
	DEFAULT_COMMUNITY_DATA,
	openDetailsDialog,
	setCardFilterCommunity,
	setIsSpecialComparison,
	setLockTotal,
	setLockedTotalBy,
	setMainTotal,
	setResetFilter,
	setTransferType,
} from "../store/reducers/community-transfer.slice";
import {
	openDetailsDialog as openDetailsDialogComparison,
	setCardFilterCommunity as setCardFilterComparison,
	setFilterListDataCommunity as setFilterListDataComparison,
	setMainTotal as setMainTotalComparison,
	setPatientList as setPatientListComparison,
	setTransferType as setTransferTypeComparison,
} from "../store/reducers/comparisonReducers/communityTransferComparison.slice";
import { setCardExpandState } from "../store/reducers/dataCardsExpand.slice";
import { PATIENT_RELATION } from "../types/chart-dialog.type";
import { CO_TRANSFER_CARDS_TYPE, TRANSFER_TYPE } from "../types/community-transfer.type";
import { PAGE_TYPE } from "../types/pages.type";
import {
	calcProPercentsBasedOnFilterAndDays,
	getDynamicPercentageBy,
	itemPercentage,
	priorityFilter,
	projectionPerMonth,
	updateChartFilterByPriority,
	updateCurrentFilterListData,
} from "../utilis/common";
import { updateFilterListData } from "../utilis/community-common";
import { toSaveDate, toSaveEndDate } from "../utilis/date-formats";
import tooltipsInfo from "../utilis/tooltipContents";
import useCustomCardFilter from "../components/hooks/useCustomCardFilter";
import { setCommunityTransferCards } from "../store/reducers/customCard.slice";
import AddCardDrawer from "../components/shared/add-filter-card/add-card-drawer";
import { COMMUNITY_CUSTOM_CARD } from "../types/custom-card-type";
import CardAnimation from "../components/shared/CardAnimation";
import ShadowLineWithText from "../components/shared/ShadowLineWithText";

const DEFAULT_LIST = {
	sixtyDaysData: [],
	list: [],
};

const CommunityTransferPage = (props) => {
	const dispatch = useDispatch();
	const { downloadPdf, saveAutomaticReport, downloadExcel } = usePdfReport();
	const { saveCustomCardFilter } = useCustomCardFilter();
	const [patientList, setPatientList] = useState(DEFAULT_LIST);
	const [filterListData, setFilterListData] = useState(DEFAULT_COMMUNITY_DATA);
	const { lastADTDate, projectionDays } = useSelector((state) => state.common);
	const { rangesSet, percentageThresholds, comparingAgainstDifferentFacility } = useSelector((state) => state.comparison);
	const { communityTransferCards } = useSelector((state) => state.customCard);
	const {
		filterListData: filterListDataComparison,
		dbData: dbDataComparison,
		filter: filterComparison,
		cardFilter: cardFilterComparison,
		transferType: transferTypeComparison,
		patientList: patientListComparison,
		filterTotal: filterTotalComparison,
		facilityPercentage: facilityPercentageComparison,
		lockedTotal: lockedTotalComparison,
		lockedByFacility: lockedByFacilityComparison,
		isCensusTotalLocked: isCensusTotalLockedComparison,
	} = useSelector((state) => state.communityTransferComparison);
	const {
		filter,
		dbData,
		transferType,
		cardFilter,
		detailsDialog,
		isResetFilter,
		lockedTotal,
		facilityPercentage,
		lockedByFacility,
		isCensusTotalLocked,
		specialComparisonSet,
		filterTotal,
		lockedTotalBy,
	} = useSelector((state) => state.communityTransfer);
	const { percentageBy } = useSelector((state) => state.common);

	const percentageAgainst = useMemo(() => {
		return getDynamicPercentageBy(dbData, percentageBy);
	}, [percentageBy, dbData]);

	const { communityPage } = useSelector((state) => state.dataCardsExpand);
	const activeFacilities = useSelector((state) => state.activeFacilities.facilities);
	const activeFacilitiesComparison = useSelector((state) => state.comparison.activeComparisonFacilitiesId);
	const [isDateUpdated, setIsDateUpdated] = useState(false);
	const activeFacilityLocalStorage = localStorage.getItem("facilityId");
	const [projectionPopupOpen, setProjectionPopupOpen] = useState(false);

	const [loading, setLoading] = useState(true);
	const [loadingComparison, setLoadingComparison] = useState(false);

	useEffect(() => {
		if (isDateUpdated && filter && filter.startDate && activeFacilityLocalStorage && activeFacilities.length > 0) {
			dispatch(setLockTotal(null));
			dispatch(setLockedTotalBy(null));
			getAllDataCount({ ...filter, facilityIds: activeFacilities });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter, filterComparison, activeFacilityLocalStorage]);

	useEffect(() => {
		updateTotalToDBFilter(patientList);
		rangesSet && updateTotalToDBFilterComparison(patientListComparison);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lockedTotal, projectionDays, percentageAgainst]);

	useEffect(() => {
		updateTotalToDBFilter(patientList);
		rangesSet && updateTotalToDBFilterComparison(patientListComparison);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transferType, projectionDays]);

	useEffect(() => {
		if (isResetFilter) {
			updateTotalToDBFilter(patientList);
			dispatch(setResetFilter(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isResetFilter]);

	const getAllDataCount = useCallback(
		async (filter) => {
			setLoading(true);
			await Promise.all([
				getAllAdmissionsPatient(filter).then(async (res) => {
					if (res && res.list.length > 0) {
						setPatientList(res);
						await updateTotalToDBFilter(res);
					} else {
						setPatientList([]);
						setFilterListData(DEFAULT_COMMUNITY_DATA);
					}
				}),
				rangesSet &&
				getAllAdmissionsPatient({ ...filterComparison, facilityIds: activeFacilitiesComparison }, true).then(
					async (res) => {
						if (res && res.list.length > 0) {
							dispatch(setPatientListComparison(res));
						} else {
							dispatch(setPatientListComparison([]));
							dispatch(setFilterListDataComparison(DEFAULT_COMMUNITY_DATA));
						}
					}
				),
			])
				.then(() => {
					setLoading(false);
				})
				.catch(() => {
					setLoading(false);
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[activeFacilitiesComparison, dispatch, filterComparison, rangesSet]
	);

	const updateTotalToDBFilter = async (res) => {
		const latestListData = await updateFilterListData(cardFilter, res, cardFilter.priorityData, transferType, false);
		setFilterListData((prevState) => ({
			...prevState,
			...latestListData,
		}));
	};

	const updateTotalToDBFilterComparison = async (res) => {
		const latestListData = await updateFilterListData(
			cardFilterComparison,
			res,
			cardFilterComparison?.priorityData,
			transferType,
			true
		);
		dispatch(setFilterListDataComparison({ ...filterListDataComparison, ...latestListData }));
	};

	const handleToggle = useCallback(
		async ({
			item,
			type,
			itemComparison = null,
			setSpecialNumberFlag = true,
			isChecked,
			slidData = [],
			cardTitle = null,
			debug,
		}) => {
			if (item) {
				const value = item._id;
				const filtersItem = cardFilter;
				const filterData = filtersItem[type];
				const currentIndex = filterData.indexOf(value);
				let newChecked = [...filterData];

				if (type === "safeDischargeAssLivData") {
					if (item._id === "all") {
						if (filterData.indexOf("all") !== -1) {
							//newChecked.splice(filterData.indexOf("all"), 1);
							newChecked = [];
						} else {
							let ids = _.map(slidData, "_id");
							newChecked = [...ids, "all"];
						}
					} else {
						if (filterData.indexOf("all") !== -1) {
							newChecked.splice(filterData.indexOf("all"), 1);
						}
						if (currentIndex === -1) {
							newChecked.push(value);
						} else {
							newChecked.splice(currentIndex, 1);
						}
					}
				} else {
					if (currentIndex === -1) {
						newChecked.push(value);
					} else {
						newChecked.splice(currentIndex, 1);
					}
				}
				const [priorityData, priorityDataComparison] = await Promise.all([
					priorityFilter(cardFilter.priorityData, type, newChecked.length, cardTitle),
					rangesSet
						? priorityFilter(cardFilterComparison?.priorityData, type, newChecked.length, cardTitle)
						: undefined,
				]);
				dispatch(setCardFilterCommunity({ [type]: newChecked, priorityData: priorityData }));
				rangesSet && dispatch(setCardFilterComparison({ [type]: newChecked, priorityData: priorityDataComparison }));

				if (specialComparisonSet && !setSpecialNumberFlag) {
					dispatch(setIsSpecialComparison(false));
				} else if (!specialComparisonSet && !setSpecialNumberFlag) {
					dispatch(setIsSpecialComparison(true));
				}

				const [latestListData, latestListDataComparison] = await Promise.all([
					updateFilterListData({ ...cardFilter, [type]: newChecked }, patientList, priorityData, transferType, false),
					rangesSet
						? updateFilterListData(
							{ ...cardFilterComparison, [type]: newChecked },
							patientListComparison,
							priorityDataComparison,
							transferTypeComparison,
							true
						)
						: undefined,
				]);
				setFilterListData((prevState) => ({
					...prevState,
					...latestListData,
				}));

				if (rangesSet) {
					const updatedFilterListData = updateCurrentFilterListData(filterListDataComparison, latestListDataComparison);
					dispatch(setFilterListDataComparison(updatedFilterListData));
				}
			}
		},
		[
			cardFilter,
			rangesSet,
			cardFilterComparison,
			dispatch,
			specialComparisonSet,
			patientList,
			transferType,
			patientListComparison,
			transferTypeComparison,
			filterListDataComparison,
		]
	);

	const handleToggleAll = async ({ items, type, cardTitle = null }) => {
		const filtersItem = cardFilter;
		const filterData = filtersItem[type];
		const itemIds = items.map((x) => x._id);
		const valuesAlreadyInFilterData = itemIds.every((x) => filterData.includes(x));
		let newChecked = [];
		if (valuesAlreadyInFilterData) {
			newChecked = filterData.filter((x) => !itemIds.some((y) => y === x));
		} else {
			newChecked = [...filterData, ...itemIds];
		}

		const [priorityData, priorityDataComparison] = await Promise.all([
			priorityFilter(cardFilter.priorityData, type, newChecked.length, cardTitle),
			rangesSet ? priorityFilter(cardFilterComparison?.priorityData, type, newChecked.length, cardTitle) : undefined,
		]);
		dispatch(setCardFilterCommunity({ [type]: newChecked, priorityData: priorityData }));
		rangesSet && dispatch(setCardFilterComparison({ [type]: newChecked, priorityData: priorityDataComparison }));

		const [latestListData, latestListDataComparison] = await Promise.all([
			updateFilterListData({ ...cardFilter, [type]: newChecked }, patientList, priorityData, transferType, false),
			rangesSet
				? updateFilterListData(
					{ ...cardFilterComparison, [type]: newChecked },
					patientListComparison,
					priorityDataComparison,
					transferTypeComparison,
					true
				)
				: undefined,
		]);
		setFilterListData((prevState) => ({
			...prevState,
			...latestListData,
		}));

		if (rangesSet) {
			const updatedFilterListData = updateCurrentFilterListData(filterListDataComparison, latestListDataComparison);
			dispatch(setFilterListDataComparison(updatedFilterListData));
		}
	};

	const handleClickTotal = useCallback(
		(total) => {
			dispatch(setTransferType(_.includes(transferType, TRANSFER_TYPE.ALL) ? [] : [TRANSFER_TYPE.ALL]));
			dispatch(setMainTotal(total));
		},
		[dispatch, transferType]
	);

	const handleClickCardTransferType = useCallback(
		(checked, type) => {
			if (type) {
				const totalSelected = transferType.length || 0;
				let newArray = [];
				if (totalSelected >= 2 && checked) {
					newArray = update(transferType, {
						$splice: [[totalSelected - 1, 1]],
					});
					newArray = update(newArray, { $push: [type] });
				} else {
					if (transferType.includes(type)) {
						newArray = update(transferType, {
							$splice: [[transferType.indexOf(type), 1]],
						});
					} else {
						newArray = update(transferType, { $push: [type] });
					}
				}
				const index = newArray.indexOf(TRANSFER_TYPE.ALL);
				if (index > -1) {
					newArray.splice(index, 1);
				}
				dispatch(setTransferType(newArray));
				dispatch(setTransferTypeComparison(newArray));
				let totalUpdated = 0;
				let totalUpdatedComparison = 0;
				if (newArray.length > 0) {
					newArray.map((ele) => {
						if (ele === "safeDischarge") {
							totalUpdated = totalUpdated + dbData.totalSafeDischarge || 0;
							totalUpdatedComparison = totalUpdated + dbDataComparison.totalSafeDischarge || 0;
						} else {
							totalUpdated = totalUpdated + dbData["total" + ele] || 0;
							totalUpdatedComparison = totalUpdatedComparison + dbDataComparison["total" + ele] || 0;
						}
					});
					dispatch(setMainTotal(totalUpdated));
					dispatch(setMainTotalComparison(totalUpdatedComparison));
				} else {
					dispatch(setMainTotal(dbData.total));
					dispatch(setMainTotalComparison(dbDataComparison?.total));
				}
			}
		},
		[transferType, dispatch, dbData, dbDataComparison]
	);

	const handleChartDialog = useCallback(
		async (title, type, relation = null, isTotalCard = false) => {
			let latestCardFilter = await updateChartFilterByPriority(cardFilter, type);
			let filterSelected =
				(cardFilter.priorityData.length > 0 && cardFilter.priorityData[0]["type"] !== cardFilter.type) ||
				transferType.length > 0;
			let data = [];
			if (
				type === CO_TRANSFER_CARDS_TYPE.TOTAL ||
				type === CO_TRANSFER_CARDS_TYPE.AMA ||
				type === CO_TRANSFER_CARDS_TYPE.SAFE_DISCHARGE ||
				type === CO_TRANSFER_CARDS_TYPE.SNF
			) {
				data = [];
				filterSelected = false;
			} else {
				data = filterListData[type];
			}
			const filters = {
				facilityId: localStorage.getItem("facilityId"),
				facilityIds: activeFacilities,
				cardFilter: latestCardFilter,
				type,
				selectedFilterData: cardFilter[type] || [],
				filter,
				relation,
				transferType,
				filterSelected,
				facilityPercentage,
				lockedByFacility,
				lockedTotal,
				isCensusTotalLocked,
				isTotalCard,
				filterListData
			};

			dispatch(
				openDetailsDialog({
					isOpen: true,
					title,
					type,
					data,
					filters,
				})
			);

			// COMPARISON ANALOG ------>
			let latestCardFilterComparison = await updateChartFilterByPriority(cardFilterComparison, type);
			let filterSelectedComparison =
				(cardFilterComparison?.priorityData.length > 0 &&
					cardFilterComparison?.priorityData[0]["type"] !== cardFilterComparison?.type) ||
				transferType != null;
			let dataComparison = [];
			if (
				type === CO_TRANSFER_CARDS_TYPE.UNPLANNED ||
				type === CO_TRANSFER_CARDS_TYPE.PLANNED ||
				type === CO_TRANSFER_CARDS_TYPE.TOTAL
			) {
				filterSelectedComparison = false;
				dataComparison = [];
			} else {
				dataComparison = filterListDataComparison[type];
			}
			const filtersComparison = {
				facilityId: activeFacilitiesComparison[0],
				facilityIds: activeFacilitiesComparison,
				cardFilter: latestCardFilterComparison,
				selectedFilterData: cardFilterComparison?.[type] || [],
				type,
				filter: filterComparison,
				relation,
				transferType: transferTypeComparison,
				filterSelected: filterSelectedComparison,
				facilityPercentage: facilityPercentageComparison,
				lockedByFacility: lockedByFacilityComparison,
				lockedTotal: lockedTotalComparison,
				isCensusTotalLocked: isCensusTotalLockedComparison,
			};
			dispatch(
				openDetailsDialogComparison({
					isOpen: true,
					title,
					type,
					data: dataComparison,
					filters: filtersComparison,
				})
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			dispatch,
			filterListData,
			cardFilter,
			filter,
			filterListDataComparison,
			cardFilterComparison,
			filterComparison,
			filterListDataComparison,
		]
	);

	const getPriorityNumber = useCallback(
		(type) => {
			const idx = cardFilter.priorityData.findIndex((p) => p.type === type);
			return idx != null && idx !== -1 ? idx + 1 : null;
		},
		[cardFilter]
	);

	const handleOnClickReport = async (type, title, isTotalCard = false, automaticallyReport = null) => {
		if (automaticallyReport) {
			await saveAutomaticReport({
				type,
				title,
				cardFilter,
				filter: {
					...filter,
					startDate: toSaveDate(filter.startDate),
					endDate: toSaveEndDate(filter.endDate),
				},
				dbData,
				page: PAGE_TYPE.COMMUNITY_TRANSFER,
				filterListData,
				isTotalCard,
				automaticallyReport: automaticallyReport,
				transferTypes: transferType,
				lockedByFacility,
				lockedTotal,
				lockedTotalBy,
				isCensusTotalLocked,
				lastADTDate,
				percentageBy,
			});
		} else {
			await downloadPdf({
				type,
				title,
				cardFilter,
				filter,
				dbData,
				page: PAGE_TYPE.COMMUNITY_TRANSFER,
				filterListData,
				isTotalCard,
			});
		}
	};

	const handleOnClickReportGraph = async (graphData) => {
		if (graphData && graphData.automaticallyReport) {
			const graphDataUpdated = _.omit(graphData, ["chartData", "filterData", "tableElementRef", "buildingElementRef"]);
			await saveAutomaticReport({
				type: graphData?.filters?.type,
				title: graphData?.automaticallyReport?.title,
				cardFilter,
				filter: {
					...filter,
					startDate: toSaveDate(filter.startDate),
					endDate: toSaveEndDate(filter.endDate),
				},
				dbData,
				page: PAGE_TYPE.COMMUNITY_TRANSFER,
				filterListData,
				automaticallyReport: graphData?.automaticallyReport,
				transferTypes: transferType,
				lockedByFacility,
				lockedTotal,
				lockedTotalBy,
				isCensusTotalLocked,
				lastADTDate,
				isGraphReport: true,
				graphData: { ...graphDataUpdated, detailsDialog },
				tableFilterType: graphData?.tableFilterType,
				percentageBy,
			});
		} else {
			await downloadPdf({
				type: graphData?.filters?.type,
				cardFilter,
				filter,
				dbData,
				page: PAGE_TYPE.COMMUNITY_TRANSFER,
				filterListData,
				isTotalCard: false,
				graphData,
				isGraphReport: true,
			});
		}
	};

	const handleAddCustomCard = useCallback(async (key) => {
		let cards = [...communityTransferCards];
		if (key === "selectAll") {
			cards = _.map(COMMUNITY_CUSTOM_CARD, "value");
		} else if (key === "clearAll") {
			cards = []
		} else {
			if (!cards.includes(key)) {
				cards.push(key);
			} else {
				cards.splice(cards.indexOf(key), 1);
			}
		}
		dispatch(setCommunityTransferCards(cards));
		await saveCustomCardFilter({ page: PAGE_TYPE.COMMUNITY_TRANSFER, cards })
	}, [communityTransferCards, dispatch, saveCustomCardFilter]);

	const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0 });
	const scrollDemoRef = useRef(null);
	const handleScroll = () => {
		if (scrollDemoRef.current) {
			const { scrollTop } = scrollDemoRef.current;
			setScrollPosition({ scrollTop });
		}
	};

	return (
		<Page title="Dashboard: Community Transfer">
			{detailsDialog.isOpen && (
				<CommunityTransferCardDetailsDialog
					handleOnClickReport={handleOnClickReportGraph}
					handleGenerateExcelReport={downloadExcel}
				/>
			)}
			<div ref={scrollDemoRef} className={dashboardStyles.content} onScroll={handleScroll}>
				<div className={`df aic ${styles.header}`}>
					<div className={`df aic ${styles.aoWrapper}`}>
						<CommunityTransferHeader
							handleFilterUpdate={() => setIsDateUpdated(true)}
							loading={loading || loadingComparison}
							setLoading={setLoading}
							setLoadingComparison={setLoadingComparison}
							projectionDays={projectionDays}
							isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
							percentageAgainst={percentageAgainst}
							projectionSelectionBlock={<>
								{/* <PickProjectionDaysPopup open={projectionPopupOpen} setOpen={setProjectionPopupOpen} /> */}
								<PickProjectionDaysPopup open={projectionPopupOpen} setOpen={setProjectionPopupOpen} loading={loading} />
							</>}
						/>
					</div>
				</div>
				<Grid container justifyContent={"center"} sx={{ mt: "30px" }}>
					<Grid item xs={12} position={"relative"}>
						{rangesSet && <ColorInfoBox style={{ zIndex: 100 }} loading={loading || loadingComparison} />}
						<AddCardDrawer
							listData={COMMUNITY_CUSTOM_CARD}
							selectedCards={communityTransferCards}
							handleAddCustomCard={handleAddCustomCard}
							scrollTop={scrollPosition?.scrollTop}
						/>
						<TotalCountCircle
							loading={loading || loadingComparison}
							page={PAGE_TYPE.COMMUNITY_TRANSFER}
							totalTitle="Total Community Transfers"
							projectTitle={percentageBy}
							selected={_.includes(transferType, TRANSFER_TYPE.ALL)}
							handleClickTotal={() =>
								handleClickTotal(calcProPercentsBasedOnFilterAndDays(dbData.total, filter, projectionDays))
							}
							total={dbData.total}
							totalComparison={dbDataComparison?.total}
							filter={filter}
							filterComparison={filterComparison}
							totalPercentage={itemPercentage(dbData.total, percentageAgainst, "number")}
							totalComparisonPercentage={itemPercentage(
								dbDataComparison?.total,
								dbDataComparison?.censusAverage,
								"number"
							)}
							projectTotal={projectionPerMonth(dbData.total, filter, projectionDays)}
							projectTotalComparison={projectionPerMonth(dbDataComparison?.total, filterComparison, projectionDays)}
							totalProjectionPercentage={itemPercentage(
								projectionPerMonth(dbData.total, filter, projectionDays),
								percentageAgainst,
								"number"
							)}
							totalProjectionComparisonPercentage={itemPercentage(
								projectionPerMonth(dbDataComparison?.total, filterComparison, true),
								dbDataComparison?.censusAverage,
								"number"
							)}
							tooltipContent={tooltipsInfo.communityTrasnferPage.totalCommunityTransfers}
							handleOnClickAction={(title) => handleChartDialog(title, CO_TRANSFER_CARDS_TYPE.TOTAL, null, true)}
							handleOnClickReport={(title, otherData) =>
								handleOnClickReport(CO_TRANSFER_CARDS_TYPE.TOTAL, title, true, otherData)
							}
							projectionDays={projectionDays}
						/>
					</Grid>
				</Grid>
				
				<Box sx={{ p: 2 }}>
					<HospitalRowContainer>
						<Grid item md={4} xs={12}>
							<SelectableCardCoTransfer
								title="Safe Discharges"
								loading={loading || loadingComparison}
								selected={_.includes(transferType, TRANSFER_TYPE.SAFE_DISCHARGE)}
								handleOnclickCard={(e) => handleClickCardTransferType(e, TRANSFER_TYPE.SAFE_DISCHARGE)}
								tooltipContent={tooltipsInfo.communityTrasnferPage.safeDischarges}
								handleOnClickAction={(title) => handleChartDialog(title, CO_TRANSFER_CARDS_TYPE.SAFE_DISCHARGE, null, true)}
								handleOnClickReport={(title, otherData) =>
									handleOnClickReport("totalSafeDischarge", title, true, otherData)
								}
							>
								<TotalCoTransferCard
									loading={loading || loadingComparison}
									selected={_.includes(transferType, TRANSFER_TYPE.SAFE_DISCHARGE)}
									type={TRANSFER_TYPE.SAFE_DISCHARGE}
									handleSelectCard={handleClickCardTransferType}
									page={PAGE_TYPE.COMMUNITY_TRANSFER}
									title={"Safe Discharges"}
									dbData={dbData}
									dbDataComparison={dbDataComparison}
									total={dbData.totalSafeDischarge}
									totalComparison={dbDataComparison?.totalSafeDischarge}
									totalComparisonPercentage={itemPercentage(
										dbDataComparison?.totalSafeDischarge,
										dbDataComparison?.censusAverage,
										"number"
									)}
									filter={filter}
									filterComparison={filterComparison}
									totalPercentage={itemPercentage(dbData.totalSafeDischarge, percentageAgainst, "number")}
									totalProjection={projectionPerMonth(dbData.totalSafeDischarge, filter, projectionDays)}
									totalProjectionComparison={projectionPerMonth(
										dbDataComparison?.totalSafeDischarge,
										filterComparison,
										projectionDays
									)}
									totalProjectionPercentage={itemPercentage(
										projectionPerMonth(dbData.totalSafeDischarge, filter, projectionDays),
										percentageAgainst,
										"number"
									)}
									totalProjectionComparisonPercentage={itemPercentage(
										projectionPerMonth(dbDataComparison?.totalSafeDischarge, filterComparison),
										dbDataComparison?.censusAverage,
										"number"
									)}
									secondaryTitle={percentageBy}
									secondaryText={percentageBy}
									projectionDays={projectionDays}
								/>
							</SelectableCardCoTransfer>
						</Grid>
						<Grid item md={4} xs={12}>
							<SelectableCardCoTransfer
								flex="1"
								loading={loading || loadingComparison}
								title="SNF Transfers"
								selected={_.includes(transferType, TRANSFER_TYPE.SNF)}
								handleOnclickCard={(e) => handleClickCardTransferType(e, TRANSFER_TYPE.SNF)}
								tooltipContent={tooltipsInfo.communityTrasnferPage.snf}
								handleOnClickAction={(title) => handleChartDialog(title, CO_TRANSFER_CARDS_TYPE.SNF, null, true)}
								handleOnClickReport={(title, otherData) => handleOnClickReport("totalSNF", title, true, otherData)}
							>
								<TotalCoTransferCard
									loading={loading || loadingComparison}
									selected={_.includes(transferType, TRANSFER_TYPE.SNF)}
									type={TRANSFER_TYPE.SNF}
									handleSelectCard={handleClickCardTransferType}
									page={PAGE_TYPE.COMMUNITY_TRANSFER}
									title={"Total re-Admissions"}
									total={dbData.totalSNF}
									totalComparison={dbDataComparison?.totalSNF}
									filter={filter}
									filterComparison={filterComparison}
									totalComparisonPercentage={itemPercentage(
										dbDataComparison?.totalSNF,
										dbDataComparison?.censusAverage,
										"number"
									)}
									totalPercentage={itemPercentage(dbData.totalSNF, percentageAgainst, "number")}
									totalProjection={projectionPerMonth(dbData.totalSNF, filter, projectionDays)}
									totalProjectionComparison={projectionPerMonth(
										dbDataComparison?.totalSNF,
										filterComparison,
										projectionDays
									)}
									totalProjectionPercentage={itemPercentage(
										projectionPerMonth(dbData.totalSNF, filter, projectionDays),
										percentageAgainst,
										"number"
									)}
									totalProjectionComparisonPercentage={itemPercentage(
										projectionPerMonth(dbDataComparison?.totalSNF, filterComparison),
										dbDataComparison?.censusAverage,
										"number"
									)}
									secondaryTitle={percentageBy}
									secondaryText={percentageBy}
									projectionDays={projectionDays}
								/>
							</SelectableCardCoTransfer>
						</Grid>
						<Grid item md={4} xs={12}>
							<SelectableCardCoTransfer
								flex="1"
								title="AMA"
								loading={loading || loadingComparison}
								selected={_.includes(transferType, TRANSFER_TYPE.AMA)}
								handleOnclickCard={(e) => handleClickCardTransferType(e, TRANSFER_TYPE.AMA)}
								tooltipContent={tooltipsInfo.communityTrasnferPage.ama}
								handleOnClickAction={(title) => handleChartDialog(title, CO_TRANSFER_CARDS_TYPE.AMA, null, true)}
								handleOnClickReport={(title, otherData) => handleOnClickReport("totalAMA", title, true, otherData)}
							>
								<TotalCoTransferCard
									loading={loading || loadingComparison}
									selected={_.includes(transferType, TRANSFER_TYPE.AMA)}
									type={TRANSFER_TYPE.AMA}
									handleSelectCard={handleClickCardTransferType}
									page={PAGE_TYPE.COMMUNITY_TRANSFER}
									title={"Total re-Admissions"}
									total={dbData.totalAMA}
									totalComparison={dbDataComparison?.totalAMA}
									filter={filter}
									filterComparison={filterComparison}
									totalPercentage={itemPercentage(dbData.totalAMA, percentageAgainst, "number")}
									totalComparisonPercentage={itemPercentage(
										dbDataComparison?.totalAMA,
										dbDataComparison?.censusAverage,
										"number"
									)}
									totalProjection={projectionPerMonth(dbData.totalAMA, filter, projectionDays)}
									totalProjectionComparison={projectionPerMonth(
										dbDataComparison?.totalAMA,
										filterComparison,
										projectionDays
									)}
									totalProjectionPercentage={itemPercentage(
										projectionPerMonth(dbData.totalAMA, filter, projectionDays),
										percentageAgainst,
										"number"
									)}
									totalProjectionComparisonPercentage={itemPercentage(
										projectionPerMonth(dbDataComparison?.totalAMA, filterComparison),
										dbDataComparison?.censusAverage,
										"number"
									)}
									secondaryTitle={percentageBy}
									secondaryText={percentageBy}
									projectionDays={projectionDays}
								/>
							</SelectableCardCoTransfer>
						</Grid>
					</HospitalRowContainer>
					{/* End of Transfer Type Selection */}

					<HospitalRowContainer>
						<CardAnimation id={`${CO_TRANSFER_CARDS_TYPE.INSURANCE_DATA}_scroll`} checked={_.includes(communityTransferCards, CO_TRANSFER_CARDS_TYPE.INSURANCE_DATA)}>
							<Grid item md={4} xs={12}>
								<Card
									loading={loading || loadingComparison}
									title="Per Insurance"
									itemAlign={"ais"}
									priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.INSURANCE_DATA)}
									tooltipContent={tooltipsInfo.communityTrasnferPage.transfersPerInsurance}
									handleOnClickAction={(title) =>
										handleChartDialog(title, CO_TRANSFER_CARDS_TYPE.INSURANCE_DATA, PATIENT_RELATION.INSURANCE)
									}
									handleOnClickReport={(title, otherData) =>
										handleOnClickReport(CO_TRANSFER_CARDS_TYPE.INSURANCE_DATA, title, false, otherData)
									}
									selectedItems={cardFilter.insuranceData}
									data={filterListData.insuranceData}
									type={CO_TRANSFER_CARDS_TYPE.INSURANCE_DATA}
									handleToggleAll={handleToggleAll}
									canBeSearched={true}
								>
									<InsuranceCard
										cardTitle="Per Insurance"
										height={350}
										data={filterListData.insuranceData}
										dataComparison={filterListDataComparison?.insuranceData}
										handleToggle={handleToggle}
										handleToggleAll={handleToggleAll}
										selectedItem={cardFilter.insuranceData}
										averageCensus={specialComparisonSet ? filterTotal : percentageAgainst}
										averageCensusComparison={
											specialComparisonSet ? filterTotalComparison : dbDataComparison.censusAverage
										}
										type={"insuranceData"}
										loading={loading || loadingComparison}
										page={PAGE_TYPE.COMMUNITY_TRANSFER}
										filter={filter}
										filterComparison={filterComparison}
										projectionDays={projectionDays}
										isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
										priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.INSURANCE_DATA)}
										transferType={transferType}
										lockedTotalBy={lockedTotalBy}
									/>
								</Card>
							</Grid>
						</CardAnimation>

						<CardAnimation id={`${CO_TRANSFER_CARDS_TYPE.SIXTY_DAYS_DATA}_scroll`} checked={_.includes(communityTransferCards, CO_TRANSFER_CARDS_TYPE.SIXTY_DAYS_DATA)}>
							<Grid item xs={8}>
								<Card
									loading={loading || loadingComparison}
									title="60 Days Analysis"
									priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.SIXTY_DAYS_DATA)}
									tooltipContent={tooltipsInfo.communityTrasnferPage["60DaysAnalysis"]}
									handleOnClickAction={(title) => handleChartDialog(title, CO_TRANSFER_CARDS_TYPE.SIXTY_DAYS_DATA)}
									handleOnClickReport={(title, otherData) =>
										handleOnClickReport(CO_TRANSFER_CARDS_TYPE.SIXTY_DAYS_DATA, title, false, otherData)
									}
								>
									<SixtyDaysAnalysis
										cardTitle="60 Days Analysis"
										handleToggle={handleToggle}
										selectedItem={cardFilter.sixtyDaysData}
										type={"sixtyDaysData"}
										loading={loading || loadingComparison}
										data={filterListData.sixtyDaysData}
										comparisonData={filterListDataComparison?.sixtyDaysData}
										page={PAGE_TYPE.COMMUNITY_TRANSFER}
										averageCensus={specialComparisonSet ? filterTotal : percentageAgainst}
										averageCensusComparison={
											specialComparisonSet ? filterTotalComparison : dbDataComparison.censusAverage
										}
										filter={filter}
										filterComparison={filterComparison}
										projectionDays={projectionDays}
										filterTotal={filterTotal}
										isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
										priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.SIXTY_DAYS_DATA)}
										transferType={transferType}
										lockedTotalBy={lockedTotalBy}
									/>
								</Card>
							</Grid>
						</CardAnimation>

						<CardAnimation id={`${CO_TRANSFER_CARDS_TYPE.RETURNS_DATA}_scroll`} checked={_.includes(communityTransferCards, CO_TRANSFER_CARDS_TYPE.RETURNS_DATA)}>
							<Grid item xs={4}>
								<Card
									loading={loading || loadingComparison}
									title="Returned / Didn't Return"
									priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.RETURNS_DATA)}
									handleOnClickAction={(title) => handleChartDialog(title, CO_TRANSFER_CARDS_TYPE.RETURNS_DATA)}
									tooltipContent={tooltipsInfo.communityTrasnferPage["returned/didntReturn"]}
									handleOnClickReport={(title, otherData) =>
										handleOnClickReport(CO_TRANSFER_CARDS_TYPE.RETURNS_DATA, title, false, otherData)
									}
								>
									<ReturnCardList
										cardTitle="Returned / Didn't Return"
										handleToggle={handleToggle}
										transfers={dbData.total}
										transfersComparison={dbDataComparison?.total}
										selectedItem={cardFilter.returnsData}
										type={"returnsData"}
										data={filterListData.returnsData}
										comparisonData={filterListDataComparison?.returnsData}
										filter={filter}
										filterComparison={filterComparison}
										page={PAGE_TYPE.COMMUNITY_TRANSFER}
										averageCensus={percentageAgainst}
										averageCensusComparison={dbDataComparison?.censusAverage}
										loading={loading || loadingComparison}
										projectionDays={projectionDays}
										isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
										priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.RETURNS_DATA)}
										transferType={transferType}
										lockedTotalBy={lockedTotalBy}
									/>
								</Card>
							</Grid>
						</CardAnimation>

						<CardAnimation id={`${CO_TRANSFER_CARDS_TYPE.DOCTOR_DATA}_scroll`} checked={_.includes(communityTransferCards, CO_TRANSFER_CARDS_TYPE.DOCTOR_DATA)}>
							<Grid item md={4} xs={12}>
								<Card
									loading={loading || loadingComparison}
									title="Per Doctor"
									priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.DOCTOR_DATA)}
									handleOnClickAction={(title) =>
										handleChartDialog(title, CO_TRANSFER_CARDS_TYPE.DOCTOR_DATA, PATIENT_RELATION.DOCTOR)
									}
									taggedList={
										<TaggedList
											type={"doctorData"}
											data={filterListData.doctorData}
											selectedItem={cardFilter.doctorData}
										/>
									}
									tooltipContent={tooltipsInfo.communityTrasnferPage.perDoctor}
									secondaryTitleError={
										comparingAgainstDifferentFacility &&
										rangesSet &&
										"Comparison function disabled when comparing to other facilities"
									}
									secondaryTitleClassName="error"
									handleOnClickReport={(title, otherData) =>
										handleOnClickReport(CO_TRANSFER_CARDS_TYPE.DOCTOR_DATA, title, false, otherData)
									}
									selectedItems={cardFilter.doctorData}
									data={filterListData.doctorData}
									type={CO_TRANSFER_CARDS_TYPE.DOCTOR_DATA}
									handleToggleAll={handleToggleAll}
									canBeSearched={true}
								>
									<DoctorCard
										cardTitle="Per Doctor"
										data={filterListData.doctorData}
										minimizable={true}
										handleToggle={handleToggle}
										handleToggleAll={handleToggleAll}
										selectedItem={cardFilter.doctorData}
										type={"doctorData"}
										page={PAGE_TYPE.COMMUNITY_TRANSFER}
										comparisonData={filterListDataComparison?.doctorData}
										averageCensus={specialComparisonSet ? filterTotal : percentageAgainst}
										averageCensusComparison={
											specialComparisonSet ? filterTotalComparison : dbDataComparison.censusAverage
										}
										filter={filter}
										loading={loading || loadingComparison}
										filterComparison={filterComparison}
										comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
										projectionDays={projectionDays}
										isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
										priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.DOCTOR_DATA)}
										transferType={transferType}
										lockedTotalBy={lockedTotalBy}
									/>
								</Card>
							</Grid>
						</CardAnimation>

						<CardAnimation id={`${CO_TRANSFER_CARDS_TYPE.FLOORS_DATA}_scroll`} checked={_.includes(communityTransferCards, CO_TRANSFER_CARDS_TYPE.FLOORS_DATA)}>
							<Grid item md={4} xs={12}>
								<Card
									loading={loading || loadingComparison}
									title="Per Floor"
									priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.FLOORS_DATA)}
									handleOnClickAction={(title) =>
										handleChartDialog(title, CO_TRANSFER_CARDS_TYPE.FLOORS_DATA, PATIENT_RELATION.UNIT)
									}
									taggedList={
										<TaggedList
											type={"floorsData"}
											data={filterListData.floorsData}
											selectedItem={cardFilter.floorsData}
										/>
									}
									tooltipContent={tooltipsInfo.communityTrasnferPage.floor}
									secondaryTitleError={
										comparingAgainstDifferentFacility &&
										rangesSet &&
										"Comparison function disabled when comparing to other facilities"
									}
									secondaryTitleClassName="error"
									handleOnClickReport={(title, otherData) =>
										handleOnClickReport(CO_TRANSFER_CARDS_TYPE.FLOORS_DATA, title, false, otherData)
									}
									selectedItems={cardFilter.floorsData}
									data={filterListData.floorsData}
									type={CO_TRANSFER_CARDS_TYPE.FLOORS_DATA}
									handleToggleAll={handleToggleAll}
									canBeSearched={true}
								>
									<FloorCardList
										cardTitle="Per Floor"
										data={filterListData.floorsData}
										handleToggle={handleToggle}
										handleToggleAll={handleToggleAll}
										selectedItem={cardFilter.floorsData}
										type={"floorsData"}
										filter={filter}
										loading={loading || loadingComparison}
										filterComparison={filterComparison}
										page={PAGE_TYPE.COMMUNITY_TRANSFER}
										dataComparison={filterListDataComparison?.floorsData}
										averageCensus={specialComparisonSet ? filterTotal : percentageAgainst}
										averageCensusComparison={
											specialComparisonSet ? filterTotalComparison : dbDataComparison.censusAverage
										}
										comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
										projectionDays={projectionDays}
										isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
										priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.FLOORS_DATA)}
										transferType={transferType}
										lockedTotalBy={lockedTotalBy}
									/>
								</Card>
							</Grid>
						</CardAnimation>

						<CardAnimation id={`${CO_TRANSFER_CARDS_TYPE.SAFE_DISCHARGE_ASS_LIV_DATA}_scroll`} checked={_.includes(communityTransferCards, CO_TRANSFER_CARDS_TYPE.SAFE_DISCHARGE_ASS_LIV_DATA)}>
							<Grid item xs={6}>
								<Card
									loading={loading || loadingComparison}
									itemAlign="aif"
									title="Safe discharges per Assisted Living"
									priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.SAFE_DISCHARGE_ASS_LIV_DATA)}
									isDisabled={transferType.length !== 0 && !_.includes(transferType, TRANSFER_TYPE.SAFE_DISCHARGE)}
									handleOnClickAction={(title) =>
										handleChartDialog(
											title,
											CO_TRANSFER_CARDS_TYPE.SAFE_DISCHARGE_ASS_LIV_DATA,
											PATIENT_RELATION.TRANSFER_TO_WHICH_ASSISTED_LIVING
										)
									}
									taggedList={
										<TaggedList
											type={"safeDischargeAssLivData"}
											data={filterListData.safeDischargeAssLivData}
											selectedItem={cardFilter.safeDischargeAssLivData}
										/>
									}
									tooltipContent={tooltipsInfo.communityTrasnferPage.safeDischargesPerAssitedLiving}
									isMinimized={true}
									setIsMinimized={(newState) =>
										dispatch(
											setCardExpandState({
												page: "communityPage",
												card: "safeDischarges",
												state: newState,
											})
										)
									}
									selectedItems={cardFilter.safeDischargeAssLivData}
									data={filterListData.safeDischargeAssLivData}
									type={CO_TRANSFER_CARDS_TYPE.SAFE_DISCHARGE_ASS_LIV_DATA}
									handleToggleAll={handleToggleAll}
									canBeSearched={true}
								>
									<SafeDischargeAssLivingSlider
										cardTitle="Safe discharges per Assisted Living"
										page={PAGE_TYPE.COMMUNITY_TRANSFER}
										totalSafeDischarge={dbData.totalSafeDischarge}
										totalSafeDischargeComparison={dbDataComparison?.totalSafeDischarge}
										data={filterListData.safeDischargeAssLivData}
										dataComparison={filterListDataComparison?.safeDischargeAssLivData}
										handleToggle={handleToggle}
										handleToggleAll={handleToggleAll}
										selectedItem={cardFilter.safeDischargeAssLivData}
										type={"safeDischargeAssLivData"}
										isDetailsOpen={true}
										filter={filter}
										loading={loading || loadingComparison}
										filterComparison={filterComparison}
										averageCensus={specialComparisonSet ? filterTotal : percentageAgainst}
										averageCensusComparison={
											specialComparisonSet ? filterTotalComparison : dbDataComparison.censusAverage
										}
										projectionDays={projectionDays}
										isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
										priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.SAFE_DISCHARGE_ASS_LIV_DATA)}
										transferType={transferType}
										lockedTotalBy={lockedTotalBy}
									/>
								</Card>
							</Grid>
						</CardAnimation>

						<CardAnimation id={`${CO_TRANSFER_CARDS_TYPE.SNF_FACILITY_DATA}_scroll`} checked={_.includes(communityTransferCards, CO_TRANSFER_CARDS_TYPE.SNF_FACILITY_DATA)}>
							<Grid item xs={6}>
								<Card
									loading={loading || loadingComparison}
									title="SNF Transfers per Facility"
									priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.SNF_FACILITY_DATA)}
									isDisabled={transferType.length !== 0 && !_.includes(transferType, TRANSFER_TYPE.SNF)}
									handleOnClickAction={(title) =>
										handleChartDialog(title, CO_TRANSFER_CARDS_TYPE.SNF_FACILITY_DATA, PATIENT_RELATION.SNF)
									}
									taggedList={
										<TaggedList
											type={"snfFacilityData"}
											data={filterListData.snfFacilityData}
											selectedItem={cardFilter.snfFacilityData}
										/>
									}
									secondaryTitleError={
										comparingAgainstDifferentFacility &&
										rangesSet &&
										"Comparison function disabled when comparing to other facilities"
									}
									secondaryTitleClassName="error"
									tooltipContent={tooltipsInfo.communityTrasnferPage.snf}
									isMinimized={true}
									setIsMinimized={(newState) =>
										dispatch(
											setCardExpandState({
												page: "communityPage",
												card: "transfers",
												state: newState,
											})
										)
									}
									selectedItems={cardFilter.snfFacilityData}
									data={filterListData.snfFacilityData}
									type={CO_TRANSFER_CARDS_TYPE.SNF_FACILITY_DATA}
									handleToggleAll={handleToggleAll}
									canBeSearched={true}
								>
									<SNFFacilitySlider
										// page={PAGE_TYPE.COMMUNITY_TRANSFER}
										// totalSNF={dbData.totalSNF}
										// totalSNFComparison={dbDataComparison?.totalSNF}
										cardTitle="SNF Transfers per Facility"
										data={filterListData.snfFacilityData}
										handleToggle={handleToggle}
										handleToggleAll={handleToggleAll}
										selectedItem={cardFilter.snfFacilityData}
										type={"snfFacilityData"}
										isDetailsOpen={true}
										filter={filter}
										loading={loading || loadingComparison}
										filterComparison={filterComparison}
										dataComparison={filterListDataComparison?.snfFacilityData}
										averageCensus={specialComparisonSet ? filterTotal : percentageAgainst}
										averageCensusComparison={
											specialComparisonSet ? filterTotalComparison : dbDataComparison.censusAverage
										}
										comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
										projectionDays={projectionDays}
										priorityData={cardFilter.priorityData}
										isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
										priorityNumber={getPriorityNumber(CO_TRANSFER_CARDS_TYPE.SNF_FACILITY_DATA)}
										transferType={transferType}
										lockedTotalBy={lockedTotalBy}
									/>
								</Card>
							</Grid>
						</CardAnimation>
					</HospitalRowContainer>
				</Box>
				<ShadowLineWithText data={communityTransferCards} />
			</div>
		</Page>
	);
};

export default CommunityTransferPage;
