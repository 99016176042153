const TotalSVG = (props) => (
  <svg
    width="47"
    height="43"
    viewBox="0 0 47 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.848511" width="47" height="41.303" rx="5" fill="#444652" />
    <g clipPath="url(#clip0_110_199)">
      <path
        d="M23.4998 5.84851C16.6815 5.84851 11.1343 11.3957 11.1343 18.2141C11.1343 19.8109 11.6872 21.7672 12.7777 24.0287C13.6835 25.9072 14.9614 27.9849 16.473 30.037C17.7929 31.8285 19.2297 33.5185 20.5193 34.7951C22.5934 36.8485 23.2758 36.8485 23.4998 36.8485C23.7239 36.8485 24.4063 36.8485 26.4803 34.7951C27.77 33.5183 29.207 31.8283 30.5267 30.037C32.0383 27.9849 33.3161 25.9072 34.222 24.0287C35.3125 21.7672 35.8654 19.8109 35.8654 18.2141C35.8654 11.3957 30.3182 5.84851 23.4998 5.84851V5.84851ZM23.4998 10.581C25.0095 10.581 26.4853 11.0286 27.7405 11.8674C28.9958 12.7061 29.9741 13.8982 30.5519 15.293C31.1296 16.6878 31.2808 18.2225 30.9862 19.7032C30.6917 21.1839 29.9647 22.544 28.8972 23.6115C27.8297 24.679 26.4696 25.406 24.989 25.7005C23.5083 25.995 21.9735 25.8438 20.5788 25.2661C19.184 24.6884 17.9919 23.71 17.1531 22.4548C16.3144 21.1995 15.8667 19.7237 15.8667 18.2141C15.869 16.1903 16.6739 14.2502 18.1049 12.8192C19.5359 11.3882 21.4761 10.5833 23.4998 10.581ZM18.3308 19.2513V17.1768C18.3311 16.9123 18.4364 16.6586 18.6234 16.4716C18.8105 16.2845 19.0641 16.1793 19.3287 16.179H21.4649V14.0429C21.4652 13.7784 21.5704 13.5247 21.7575 13.3377C21.9445 13.1506 22.1982 13.0454 22.4627 13.0451H24.5369C24.8015 13.0454 25.0551 13.1506 25.2422 13.3377C25.4293 13.5247 25.5345 13.7784 25.5348 14.0429V16.1791H27.671C27.9355 16.1794 28.1892 16.2846 28.3762 16.4717C28.5633 16.6587 28.6685 16.9123 28.6689 17.1768V19.2512C28.6686 19.5157 28.5634 19.7694 28.3763 19.9564C28.1892 20.1435 27.9356 20.2487 27.671 20.249H25.5348V22.3853C25.5344 22.6498 25.4292 22.9033 25.2421 23.0904C25.055 23.2774 24.8015 23.3826 24.5369 23.3829H22.4627C22.1982 23.3826 21.9445 23.2773 21.7575 23.0903C21.5704 22.9032 21.4652 22.6496 21.4649 22.385V20.249H19.3287C19.0641 20.2487 18.8105 20.1435 18.6234 19.9564C18.4364 19.7694 18.3311 19.5157 18.3308 19.2512V19.2513Z"
        fill="#92C7FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_110_199">
        <rect
          width="31"
          height="31"
          fill="white"
          transform="translate(8 5.84851)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default TotalSVG;
