import { useCallback, useEffect, useState } from "react";
import styles from "../assets/styles/Role.module.scss";
import EditSVG from "../assets/svgs/edit.svg";
import { getRoles } from "../services/role.service";
import AddEditRoleDialog from "../components/role/AddEditRoleDialog";
import { Box, CircularProgress, Typography } from "@mui/material";
import _ from "lodash";


const RolePage = () => {
    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [showAdd, setShowAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentEdit, setCurrentEdit] = useState({});

    const getRolesData = async () => {
        setLoading(true);
        await getRoles().then((res) => {
            if (res) {
                setPermissions(res.allPermissions);
                if (res.data && res.data.length > 0) {
                    const roleData = _.orderBy(res.data, "name", "asc");
                    setRoles(roleData);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setRoles([]);
                }
            }
        });
    };
    useEffect(() => {
        getRolesData();
    }, []);

    const handleCloseDialog = useCallback((type = null) => {
        setShowAdd(false);
        if (type) {
            getRolesData();
        }
    }, []);

    return (
        <div className={styles.rolePage}>
            <div className={`df ${styles.hdr}`}>
                <div className={``}>
                    <Typography variant="h5">Manage roles</Typography>
                </div>
               
                {showAdd ? (
                    <AddEditRoleDialog
                        currentEdit={currentEdit}
                        close={handleCloseDialog}
                        clearCurrentEdit={() => setCurrentEdit({})}
                        permissions={permissions}
                    />
                ) : undefined}
            </div>
            <div className={styles.listWrpr}>
                {roles.length > 0 &&
                    roles.map((role, index) => {
                        return (
                            <div key={index} className={`df aic ffmm fs14 ${styles.line}`}>
                                <h2 style={{ width: "20%" }}>{role.name}</h2>
                                <div className={`mla df aic`}>
                                    <div
                                        className={`m-r-10 c-pointer`}
                                        onClick={() => {
                                            setCurrentEdit(role);
                                            setShowAdd(true);
                                        }}
                                    >
                                        <EditSVG fill="#4879f5" />
                                    </div>
                                   
                                </div>
                            </div>
                        );
                    })}
                {roles.length === 0 && !loading && (
                    <div className={`df aic ffmm fs14 ${styles.line}`}>
                        NO record found
                    </div>
                )}
                {loading && (
                    <Box sx={{ display: "flex", top: "10%" }} justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
            </div>
        </div>
    );
};

export default RolePage;
