import React from "react";

const TimesheetSVG = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		data-name="Layer 1"
		viewBox="0 0 128 160"
		x="0px"
		y="0px"
		width="20"
		height="20"
	>
		<title>solid</title>
		<path d="M53,81a2,2,0,0,0,2,2H97a2,2,0,0,0,0-4H55A2,2,0,0,0,53,81Z" />
		<path d="M97,87H55a2,2,0,0,0,0,4H97a2,2,0,0,0,0-4Z" />
		<path d="M45,75H33a4,4,0,0,0-4,4V91a4,4,0,0,0,4,4H45a4,4,0,0,0,4-4V79A4,4,0,0,0,45,75Zm0,16H33V79H45Z" />
		<path d="M35.94,87.68a2,2,0,0,0,2.73.47l4.44-3a2,2,0,0,0-2.24-3.32L38,83.77l-.42-.57a2,2,0,1,0-3.22,2.37Z" />
		<path d="M97,104H55a2,2,0,0,0,0,4H97a2,2,0,0,0,0-4Z" />
		<path d="M97,112H55a2,2,0,0,0,0,4H97a2,2,0,0,0,0-4Z" />
		<path d="M45,100H33a4,4,0,0,0-4,4v12a4,4,0,0,0,4,4H45a4,4,0,0,0,4-4V104A4,4,0,0,0,45,100Zm0,16H33V104H45Z" />
		<path d="M35.94,112.68a2,2,0,0,0,2.73.47l4.44-3a2,2,0,0,0-2.24-3.32L38,108.77l-.42-.57a2,2,0,1,0-3.22,2.37Z" />
		<path d="M41,4V8H27a6,6,0,0,0-6,6V122a6,6,0,0,0,6,6h74a6,6,0,0,0,6-6V14a6,6,0,0,0-6-6H87V4a4,4,0,0,0-4-4H45A4,4,0,0,0,41,4ZM87,16V12h14a2,2,0,0,1,2,2V122a2,2,0,0,1-2,2H27a2,2,0,0,1-2-2V14a2,2,0,0,1,2-2H41v4a4,4,0,0,0,4,4H83A4,4,0,0,0,87,16ZM83,8H72a2,2,0,0,0,0,4H83v4H45V12H56a2,2,0,0,0,0-4H45V4H83Z" />
		<path d="M64,26A22,22,0,1,0,86,48,22,22,0,0,0,64,26ZM81.88,50A17.91,17.91,0,0,1,78,59.23,2,2,0,0,0,75.23,62,17.9,17.9,0,0,1,66,65.88a2,2,0,0,0-4,0A17.9,17.9,0,0,1,52.77,62,2,2,0,0,0,50,59.23,17.9,17.9,0,0,1,46.12,50a2,2,0,0,0,0-4A17.9,17.9,0,0,1,50,36.77,2,2,0,0,0,52.77,34,17.9,17.9,0,0,1,62,30.12a2,2,0,0,0,4,0A17.9,17.9,0,0,1,75.23,34,2,2,0,0,0,78,36.77,17.9,17.9,0,0,1,81.88,46a2,2,0,0,0,0,4Z" />
		<path d="M70.13,42.83l-4.13,2V38a2,2,0,0,0-4,0V48a2,2,0,0,0,2.87,1.8l7-3.36a2,2,0,0,0-1.73-3.61Z" />
		<circle cx="64" cy="10" r="2" />
		<text
			x="0"
			y="143"
			fill="#000000"
			fontSize="5px"
			fontWeight="bold"
			fontFamily="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"
		>
			Created by WEBTECHOPS LLP
		</text>
		<text
			x="0"
			y="148"
			fill="#000000"
			fontSize="5px"
			fontWeight="bold"
			fontFamily="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"
		>
			from the Noun Project
		</text>
	</svg>
);

export default TimesheetSVG;
