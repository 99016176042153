import React from "react";
import styled from "styled-components";

const Bar = styled.div`
	width: 100%;
	height: ${(props) => props.$height}px;
	border-radius: 5px;
`;

const BarWrapper = styled.div`
	width: 9%;
	height: 100%;
	margin-bottom: 17px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
`;

const BarLabel = styled.div`
	font-size: 11px;
	margin-top: 7px;
`;

const DayCardSkeletonList = () => {
	return (
		<div
			style={{ width: "100%", height: "100%", display: "flex", justifyContent: "space-evenly", alignItems: "flex-end" }}
		>
			<BarWrapper>
				<Bar $height={13} className="skeleton" />
				<BarLabel>Mon</BarLabel>
			</BarWrapper>
			<BarWrapper>
				<Bar $height={114} className="skeleton" />
				<BarLabel>Tue</BarLabel>
			</BarWrapper>
			<BarWrapper>
				<Bar $height={165} className="skeleton" />
				<BarLabel>Wed</BarLabel>
			</BarWrapper>
			<BarWrapper>
				<Bar $height={102} className="skeleton" />
				<BarLabel>Thu</BarLabel>
			</BarWrapper>
			<BarWrapper>
				<Bar $height={140} className="skeleton" />
				<BarLabel>Fri</BarLabel>
			</BarWrapper>
			<BarWrapper>
				<Bar $height={76} className="skeleton" />
				<BarLabel>Sat</BarLabel>
			</BarWrapper>
			<BarWrapper>
				<Bar $height={127} className="skeleton" />
				<BarLabel>Sun</BarLabel>
			</BarWrapper>
		</div>
	);
};

export default DayCardSkeletonList;
