import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const TotalTypography = styled(Typography)(({ theme }) => ({
	color: theme.palette.typography.main,
	fontFamily: "manrope",
	fontStyle: "normal",
	fontWeight: 700,
	fontSize: { md: 12, lg: 16 },
	// lineHeight: 19, //making compared to color box not appear in lists.
	marginLeft: 2,
	"& span": {
		fontWeight: 400,
		fontSize: 12,
		marginLeft: 2,
		// lineHeight: 16,
	},
}));

const TotalCountPercentage = ({ percentage, value, isPercentageShow, style, loading }) => {
	return loading ? (
		<div style={{ width: "40px", height: "20px", marginRight: "10px", borderRadius: 5 }} className="skeleton"></div>
	) : (
		<TotalTypography style={style} textAlign={"center"} variant={"caption"}>
			{value}
			{isPercentageShow && <span>{percentage}</span>}
		</TotalTypography>
	);
};

export default TotalCountPercentage;
