import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const HtmlTooltipContainer = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		color: "rgba(0, 0, 0, 0.87)",
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: "1px solid #dadde9",
		background: "#FFFFFF",
		boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.13)",
		borderRadius: "8px",
	},
}));

export default function HtmlTooltip({
	children,
	content,
	key,
	...props
}) {
	return (
		<div {...key && { key }}>
			<HtmlTooltipContainer title={content} {...props} >
				{children}
			</HtmlTooltipContainer>
		</div>
	);
}
