// types
import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_FILTER } from "../../../data/common.data";

const DEFAULT_DECEASED_DB_DATA = {
	total: 0,
	censusAverage: 0,
};

export const DEFAULT_DECEASED_DATA = {
	insuranceData: [],
	doctorData: [],
	floorsData: [],
	ninetyDaysData: [],
	priorityData: [],
};

// initial state
const initialState = {
	mainTotal: 0,
	transferType: null,
	selectedFilter: null,
	detailsDialog: {
		isOpen: false,
		type: null,
		item: null,
	},
	filter: DEFAULT_FILTER,
	cardFilter: DEFAULT_DECEASED_DATA,
	dbData: DEFAULT_DECEASED_DB_DATA,
	isResetFilter: false,
	filterTotal: null,
	lockedTotal: null,
	mainNumPercentage: null,
	filterListData: null,
	patientList: [],
	isCensusTotalLocked: false,
	facilityPercentage: [],
	lockedByFacility: [],
	lockedTotalBy: null,
};

// ==============================|| SLICE - ADMISSIONS ||============================== //

const deceasedComparison = createSlice({
	name: "deceasedComparison",
	initialState,
	reducers: {
		setTransferType(state, action) {
			state.transferType = action.payload;
		},
		setDefaultDeceasedData(state, action) {
			state.transferType = null;
			state.cardFilter = DEFAULT_DECEASED_DATA;
			state.mainTotal = 0;
			state.filter = DEFAULT_FILTER;
			state.dbData = DEFAULT_DECEASED_DB_DATA;
		},

		setMainTotal(state, action) {
			state.mainTotal = action.payload;
		},

		setLockTotal(state, action) {
			state.lockedTotal = action.payload;
		},
		openDetailsDialog(state, action) {
			state.detailsDialog = { ...action.payload };
		},
		setFilterListDataDeceased(state, action) {
			state.filterListData = action.payload;
		},
		closeDetailsDialog(state, action) {
			state.detailsDialog = {
				isOpen: false,
				type: null,
				item: null,
			};
		},

		setFilterDateRange(state, action) {
			state.filter = { ...state.filter, ...action.payload };
		},

		setFilterDBData(state, action) {
			state.dbData = { ...state.dbData, ...action.payload };
		},

		setCardFilterDeceased(state, action) {
			state.cardFilter = {
				...state.cardFilter,
				...action.payload,
			};
		},

		setResetFilter(state, action) {
			state.isResetFilter = action.payload;
		},

		setSelectedFilter(state, action) {
			if (action.payload) {
				// eslint-disable-next-line eqeqeq
				if (action.payload != "all") {
					const { filter } = action.payload;
					if (filter && filter.cardFilter) {
						state.cardFilter = {
							...DEFAULT_DECEASED_DATA,
							...filter.cardFilter,
						};
					}
					if (filter && filter.transferType) {
						state.transferType = filter.transferType;
					} else {
						state.transferType = null;
					}
					if (filter && filter.filter) {
						state.filter = filter.filter;
					}
					state.selectedFilter = action.payload._id;
				} else {
					state.selectedFilter = action.payload;
					state.cardFilter = DEFAULT_DECEASED_DATA;
					state.filter = { ...state.filter };
					state.isResetFilter = true;
					state.transferType = null;
					state.lockedTotal = null;
				}
			}
		},
		setFilterTotal(state, action) {
			state.filterTotal = action.payload;
		},
		setIsMainCensusPercentage(state, action) {
			state.mainNumPercentage = action.payload;
		},
		setPatientList(state, action) {
			state.patientList = action.payload;
		},
		setIsCensusTotalLocked(state, action) {
			state.isCensusTotalLocked = action.payload;
		},
		setLockedTotalBy(state, action) {
			state.lockedTotalBy = action.payload;
		},
		setLockedByFacility(state, action) {
			state.lockedByFacility = action.payload;
		},
		setFacilityPercentage(state, action) {
			state.facilityPercentage = action.payload;
		},
	},
});

export default deceasedComparison.reducer;

export const {
	setIsMainCensusPercentage,
	setFilterTotal,
	setResetFilter,
	openDetailsDialog,
	closeDetailsDialog,
	setFilterDateRange,
	setFilterDBData,
	setMainTotal,
	setCardFilterDeceased,
	setSelectedFilter,
	setDefaultDeceasedData,
	setLockTotal,
	setTransferType,
	setFilterListDataDeceased,
	setPatientList,
	setIsCensusTotalLocked,
	setLockedTotalBy,
	setLockedByFacility,
	setFacilityPercentage,
} = deceasedComparison.actions;
